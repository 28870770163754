<div class="sch-pay-top">
    <div class="sch-pay-top-header">
        <div class="schedule-pay-header-section">
            <div class="sch-pay-title">Scheduled Payments</div>
            <div style="width: 1px; height: 103px; background: #7D8C95"></div>
            <div class="sch-pay-count">{{schPayCount}}</div>
        </div>
        <div class="sch-pay-link-section">
            <div class="sch-pay-link"><span >Go back to </span><span class="all_inv_link"><a routerLink="/financial-dashboard/all-invoices">All Invoices</a></span></div>
        </div>
    </div>
</div> 

