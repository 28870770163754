<div
  class="materials_backbtn col-xs-12"
  *ngIf="showback"
  (click)="backtolanding()"
>
  <div class="global_blackarrow materialarrow"></div>
  Back to Materials Available to Order
</div>

<section class="col-xs-12 col-sm-12 materials_titlesection">
  <div class="col-xs-12 col-sm-12 col-md-9 materials_headerparent">
    <div class="materials_header">Materials</div>
    <div class="materials_subheader">
      As part of our ongoing commitment to support you in the fight against flu,
      we are pleased to offer a range of clinic and disease awareness materials
      available to view, download and deliver upon request.
    </div>
  </div>
</section>
<div class="PibannerUk">
  <a class="pibannerlink" href="/prescribinginformation"
    >Prescribing Information
    <img
      _ngcontent-ng-c1865303012=""
      alt="Next screen"
      src="assets/images/arrow-right-white.svg"
  /></a>
</div>

<section
  class="col-xs-12 col-md-12 materials_finalisesection"
  [hidden]="!showmateriallanding"
>
  <div class="col-xs-12 materials_availableheadersection">
    <div class="col-xs-8 materials_availableheader">Available Materials</div>
  </div>
  <div class="col-xs-8 materials_availablesubheader"></div>

  <div class="col-xs-12 col-md-12 materials_tablecontainer tools_container">
    <div class="materialssubheader">
      MATERIALS FOR GENERAL PUBLIC (DISEASE AWARENESS)
    </div>
    <div id="toolfilterTable_wrapper" class="dataTables_wrapper no-footer materialtables">
      <table
        mat-table
        [dataSource]="materialsGeneralList"
        matSort
        id="toolfilterTable"
        class="mat-elevation-z8 materialtable"
      >
        <ng-container matColumnDef="combined">
          <th class="odertableheader" mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <div class="materials_img">
              <img
                alt="imageAlttext"
                [src]="getImage(element.media)"
              />
            </div>
            <div class="materials_title">{{ element.h2content }}</div>
            <div class="materials_text">{{ element.content }}</div>
            <div class="materials_accessparent">
              <a [href]="getpdf(element.picture2.url)" target="_blank" class="materials_accesstext"
                ><div class="materials_accesssection">
                  <a>{{ element.paragraphcontent }}</a>
                  <img
                    alt="arrow"
                    class="materials_arrow"
                    src="assets/images/blackarrow.png"
                    width="19"
                    height="19"
                  /></div
              ></a>
              <div class="materials_Addlist col-xs-12" (focusout)="onfocusout(element)">
                <span [hidden]="element.displayAdd">Add to Printed Materials List</span>
                <span [hidden]="!element.displayAdd">Items on Printed Materials List</span>
                <img
                  alt="arrow"
                  (click)="toggleinput(element)"
                  class="materials_plus"
                  src="assets/images/plussign.png"
                  width="19"
                  height="19"
                  [hidden]="element.displayAdd"
                /><input
                  step="1"
                  type="number"
                  class="materials_volume"
                  name="materials_volume"
                  id="materials_volume"
                  min="0"
                  max="999"
                  [value]="element.quantity"
                  [hidden]="!element.displayAdd"
                  (keyup)="onQuantityChange(element, $event.target)"
                /><img class="quantityErrorIcon" [hidden]="!element.quantityInvalid" alt="erro"
                src="/assets/images/exclamation-mark.png">
              </div>
              <div class="materialitempack">{{ element.itemPerPack }}</div>
              <span class="materials_ordererror" [ngClass]="element.quantityInvalid ? 'errorColor' : ''" >
                Please enter maximum of 10
              </span>
            </div>
          </td>
        </ng-container>

        <!-- Header and Row Declarations -->
        
        <tr
          mat-row
          (click)="getRecord(row)"
          id="{{ row.ordernum }}"
          *matRowDef="let row; columns: displayedColumnsmain"
        ></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell orders no-matching">
            No data available in table
          </td>
        </tr>
      </table>
    </div>
  </div>
<!-- HCP Section Start -->
<div class="col-xs-12 col-md-12 materials_tablecontainer tools_container hcp-section">
  <div class="materialssubheader">
    MATERIALS FOR HCP USE
  </div>
  <div id="toolfilterTable_wrapper" class="dataTables_wrapper no-footer materialtables">
    <table
      mat-table
      [dataSource]="materialsHCPList"
      matSort
      id="toolfilterTable"
      class="mat-elevation-z8 materialtable"
    >
      <ng-container matColumnDef="combined">
        <th class="odertableheader" mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <div class="materials_img">
            <img
              alt="imageAlttext"
              [src]="getImage(element.media)"
            />
          </div>
          <div class="materials_title">{{ element.h2content }}</div>
          <div class="materials_text">{{ element.content }}</div>
          <div class="materials_accessparent">
            <a [href]="getpdf(element.picture2.url)" target="_blank" class="materials_accesstext"
              ><div class="materials_accesssection">
                <a>{{ element.paragraphcontent }}</a>
                <img
                  alt="arrow"
                  class="materials_arrow"
                  src="assets/images/blackarrow.png"
                  width="19"
                  height="19"
                /></div
            ></a>
            <div class="materials_Addlist col-xs-12" (focusout)="onfocusout(element)">
              <span [hidden]="element.displayAdd">Add to Printed Materials List</span>
              <span [hidden]="!element.displayAdd">Items on Printed Materials List</span>
              <img
                alt="arrow"
                (click)="toggleinput(element)"
                class="materials_plus"
                src="assets/images/plussign.png"
                width="19"
                height="19"
                [hidden]="element.displayAdd"
              /><input
                step="1"
                type="number"
                class="materials_volume"
                name="materials_volume"
                id="materials_volume"
                min="0"
                max="999"
                [value]="element.quantity"
                [hidden]="!element.displayAdd"
                (keyup)="onQuantityChange(element, $event.target)"
              /><i class="materials_erroricon"></i>
              <img class="quantityErrorIcon" [hidden]="!element.quantityInvalid" alt="error"
                src="/assets/images/exclamation-mark.png">
            </div>
            <div class="materialitempack">{{ element.itemPerPack }}</div>
            <span class="materials_ordererror" [ngClass]="element.quantityInvalid ? 'errorColor' : ''" >
              Please enter maximum of 10
            </span>
          </div>
        </td>
      </ng-container>

      <!-- Header and Row Declarations -->
      <tr mat-header-row *matHeaderRowDef="displayedColumnsmain"></tr>
      <tr
        mat-row
        id="{{ row.ordernum }}"
        *matRowDef="let row; columns: displayedColumnsmain"
      ></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell orders no-matching">
          No data available in table
        </td>
      </tr>
    </table>
  </div>
</div>
<!-- HCP Section End-->

<!-- Welsh Section Start -->
<div class="col-xs-12 col-md-12 materials_tablecontainer tools_container welsh-section">
  <div class="materialssubheader">
    MATERIALS FOR GENERAL PUBLIC (DISEASE AWARENESS) - WELSH
  </div>
  <div id="toolfilterTable_wrapper" class="dataTables_wrapper no-footer materialtables">
    <table
      mat-table
      [dataSource]="materialsWelshList"
      matSort
      id="toolfilterTable"
      class="mat-elevation-z8 materialtable"
    >
      <ng-container matColumnDef="combined">
        <th class="odertableheader" mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <div class="materials_img">
            <img
              alt="imageAlttext"
              [src]="getImage(element.media)"
            />
          </div>
          <div class="materials_title">{{ element.h2content }}</div>
          <div class="materials_text">{{ element.content }}</div>
          <div class="materials_accessparent">
            <a [href]="getpdf(element.picture2.url)" target="_blank" class="materials_accesstext"
              ><div class="materials_accesssection">
                <a>{{ element.paragraphcontent }}</a>
                <img
                  alt="arrow"
                  class="materials_arrow"
                  src="assets/images/blackarrow.png"
                  width="19"
                  height="19"
                /></div
            ></a>
            <div class="materials_Addlist col-xs-12" (focusout)="onfocusout(element)">
              <span [hidden]="element.displayAdd">Add to Printed Materials List</span>
              <span [hidden]="!element.displayAdd">Items on Printed Materials List</span>
              <img
                alt="arrow"
                (click)="toggleinput(element)"
                class="materials_plus"
                src="assets/images/plussign.png"
                width="19"
                height="19"
                [hidden]="element.displayAdd"
              /><input
                step="1"
                type="number"
                class="materials_volume"
                name="materials_volume"
                id="materials_volume"
                min="0"
                max="999"
                [value]="element.quantity"
                [hidden]="!element.displayAdd"
                (keyup)="onQuantityChange(element, $event.target)"
              /><img class="quantityErrorIcon" [hidden]="!element.quantityInvalid" alt="error"
              src="/assets/images/exclamation-mark.png">
            </div>
            <div class="materialitempack">{{ element.itemPerPack }}</div>
            <span class="materials_ordererror" [ngClass]="element.quantityInvalid ? 'errorColor' : ''" >
              Please enter maximum of 10
            </span>
          </div>
        </td>
      </ng-container>

      <!-- Header and Row Declarations -->
      <tr mat-header-row *matHeaderRowDef="displayedColumnsmain"></tr>
      <tr
        mat-row
        id="{{ row.ordernum }}"
        *matRowDef="let row; columns: displayedColumnsmain"
      ></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell orders no-matching">
          No data available in table
        </td>
      </tr>
    </table>
  </div>
</div>
<!--Welsh section end-->
<button
    class="materials_finalisebtn"
    (click)="movetoreview()"
    [disabled]="showbtnenabled"
  >
    Finalise Request for Printed Materials
  </button>
</section>
<section
  class="col-xs-12 col-md-12 materials_availablesection"
  *ngIf="showreview"
>
  <div>
    <div class="row">
      <h2 class="materials_finaliseheader col-xs-8">
        Finalise your request for printed materials
      </h2>
      <p class="col-xs-8 materials_finalisesubheader">
        Review and adjust the quantities of your materials and choose your
        delivery address (only one allowed per order)
      </p>
      <p class="col-xs-8 materials_finalisecontent">
        We will send you a pack containing your list of materials below within 7
        days of your request. Please check that the information here is correct
        before submitting.
      </p>
    </div>

    <div class="row materials_reviewsection">
      <span class="materials_reviewheader col-xs-10">Materials</span>
      <a class="materials_removeallbtn col-xs-2" (click)="removeMaterials()"
        >Remove all</a
      >
      <p class="materials_reviewcontent col-xs-12" *ngIf="materialReviewList.length > 0">
        Enter or adjust the quantity of each material.
      </p>
      <p class="materials_reviewcontent col-xs-12" *ngIf="materialReviewList.length == 0">
        Your list is now empty.
      </p>

      <div class="materials_review col-xs-12" *ngIf="displayMaterials">
        <div *ngFor="let material of materialReviewList">
          <div class="col-xs-12 materials_reviewcontainer" id="materialsreview_1">
            <div class="row col-xs-12" style="display: flex">
              <div class="materials_revmaxlimit">10</div>
              <div class="col-xs-11 materials_reviewlist">
                <img
                  width="140"
                  height="58"
                  class="materials_reviewimg col-xs-3"
                  [src]="getImage(material.image)"
                  alt="altText"
                />
                <div class="materials_reviewitem col-xs-9">
                  {{material.title}}
                </div>
                <div style="float: left; position: relative">                 
                  <input
                    type="hidden"
                    value="GBR-FLU-24-0021"
                    name="productDetails[0].materialID"
                  /><input
                    type="number"
                    class="col-xs-2 materials_reviewval"
                    [value]="material.quantity"
                    name="productDetails[0].qty"
                    (keyup)="reviewQuantityChange(material, $event.target)"
                  />                
                </div>
                <img class="quantityReviewErrorIcon" [ngClass]="material.quantityInvalid ? 'op-1' : ''"
                  alt="error"
                  src="/assets/images/exclamation-mark.png">
                <span>Quantity</span>
              </div>
              <div class="col-xs-1 materials_delete" (click)="deleteMaterial(material)"></div>
            </div>
            <span class="col-xs-12 materials_reviewerror" [ngClass]="material.quantityInvalid ? 'op-1' : ''" >
              Please enter maximum of 10
            </span>
          </div>
        </div>
      </div>

      <div class="materials_addmaterialsbtn col-xs-3" (click)="backtolanding()">
        Add More Materials
        <div class="global_blackarrow"></div>
      </div>
    </div>
    <div class="row materials_locationsubsection">
      <div>
        <span class="col-xs-12 materials_locationheader"> Location </span>
        <p class="col-xs-9 materials_locationsubheader">
          Please select or add a new location for the materials you have chosen.
          If you would like to send materials to multiple locations, you can
          simply enter a new request for printed materials.
        </p>
      </div>
      <div class="col-xs-12 materials_locationlist">
        <table mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="checkbox">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" class="col-xs-1">
              <div class="materials_selection">
                <label class="checkbox-button"
                  ><input
                    type="checkbox"
                    class="material_checkbox"
                    name="checkbox"
                    disabled="true"
                    [(ngModel)]="element.checked"
                  /><span class="checkbox-button__control"></span>
                </label>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="shippingOrganizationName">
            <th mat-header-cell *matHeaderCellDef></th>
            <td
              mat-cell
              *matCellDef="let element"
              [ngClass]="element.checked ? 'disbleRow' : ''"
              class="col-xs-3"
            >
              {{ element.shippingOrganizationName }}
            </td>
          </ng-container>
          <ng-container matColumnDef="shippingAddressLine1">
            <th mat-header-cell *matHeaderCellDef></th>
            <td
              mat-cell
              *matCellDef="let element"
              [ngClass]="element.checked ? 'disbleRow' : ''"
              class="col-xs-3"
            >
              {{ element.shippingAddressLine1 }}
            </td>
          </ng-container>
          <ng-container matColumnDef="col4">
            <th mat-header-cell *matHeaderCellDef></th>
            <td
              mat-cell
              *matCellDef="let element"
              [ngClass]="element.checked ? 'disbleRow' : ''"
              class="col-xs-5"
            >
              {{ element.town }}, {{element.postalCode}}
            </td>
          </ng-container>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            (click)="rowSelected(row)"
            [ngClass]="
              row.selected ? 'materials_unselected' : 'materials_selected'
            "
            class="col-xs-12 materials_locations"
          ></tr>
        </table>
        <a
          class="col-xs-12 materials_addlocation"
          (click)="displayAddress()"
          *ngIf="!displayAddressForm"
          >Add a Different Location <span class="plussign-round"></span>
        </a>
      </div>
    </div>

    <div
      class="col-xs-12 materials_locationnew"
      style="display: block"
      *ngIf="displayAddressForm"
    >
      <div class="row materials_locationsection">
        <div class="col-xs-6 materials_locheader">Add New Location</div>
        <div class="col-xs-6 materials_locremove" (click)="displayAddress()">
          Remove
        </div>
        <div class="col-xs-6 materials_locheader">Delivery Address</div>
        <form
          class="materials_addform col-xs-12"
          id="materials_addform"
          autocomplete="off"
          novalidate="novalidate"
          [formGroup]="addressForm"
          (ngSubmit)="onSubmit()"
        >
          <div>
            <div class="row">
              <div class="col-xs-12 col-md-6 form-group pl-0">
                <label for="mat_orgname">Organisation Name</label
                ><input
                  type="text"
                  id="mat_orgname"
                  name="mat_orgname"
                  class="form-control"
                  formControlName="organizationName"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12 col-md-12 form-group pl-0">
                <label for="address">Address Lookup</label
                ><input
                  type="text"
                  id="address"
                  name="mat_addlookup"
                  class="form-control"
                  modified-id=""
                  formControlName="address"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12 col-md-6 form-group pl-0">
                <label for="line1">Address Line 1<sup>*</sup></label
                ><input
                  type="text"
                  id="line1"
                  name="mat_add1"
                  class="form-control"
                  formControlName="line1"
                  style="margin-bottom:5px"
                />
                <ng-container
                  *ngIf="
                    (getFormControl('line1')?.touched &&
                      getFormControl('line1')?.hasError('required')) ||
                    (getFormControl('line1')?.untouched && isSubmitted)
                  "
                >
                <img class="lineIcon"
                src="/assets/images/exclamation-mark.png" alt="error">
                  <label
                    id="mat_add1-error"
                    class="error help-block"
                    for="mat_add1"
                    >Please Enter A Valid Address</label
                  >
                </ng-container>
              </div>
              <div class="col-xs-12 col-md-6 form-group">
                <label for="city">City<sup>*</sup></label
                ><input
                  type="text"
                  id="city"
                  name="mat_city"
                  class="form-control"
                  formControlName="city"
                  style="margin-bottom:5px"
                />
                <ng-container
                  *ngIf="
                    (getFormControl('city')?.touched &&
                      getFormControl('city')?.hasError('required')) ||
                    (getFormControl('city')?.untouched && isSubmitted)
                  "
                >
                <img class="cityIcon"
                src="/assets/images/exclamation-mark.png" alt="error">
                  <label
                    id="mat_add1-error"
                    class="error help-block"
                    for="mat_add1"
                    >Please Enter City</label
                  >
                </ng-container>
                <ng-container
                  *ngIf="
                    getFormControl('city')?.touched &&
                    getFormControl('city')?.hasError('pattern')
                  "
                >
                <img class="cityIcon"
                src="/assets/images/exclamation-mark.png" alt="error">
                  <label
                    id="mat_add1-error"
                    class="error help-block"
                    for="mat_add1"
                    >Please Enter Only Letters</label
                  >
                </ng-container>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-7 col-md-3 form-group pl-0">
                <label for="code">Post Code<sup>*</sup></label
                ><input
                  type="text"
                  id="code"
                  name="mat_post"
                  class="form-control"
                  formControlName="code"
                  style="margin-bottom:5px"
                />
                <ng-container
                  *ngIf="
                    (getFormControl('code')?.touched &&
                      getFormControl('code')?.invalid) ||
                    (getFormControl('code')?.untouched && isSubmitted)
                  "
                >
                <img class="codeIcon"
                src="/assets/images/exclamation-mark.png" alt="error">
                  <label
                    id="mat_add1-error"
                    class="error help-block"
                    for="mat_add1"
                    >Please Enter A Valid Postcode</label
                  >
                </ng-container>
              </div>
              <div class="col-xs-5 col-md-3 form-group">
                <label for="country">Country<sup>*</sup></label
                ><input
                  type="text"
                  id="country"
                  name="mat_country"
                  class="form-control"
                  formControlName="country"
                  style="margin-bottom:5px"
                />
                <ng-container
                  *ngIf="
                    (getFormControl('country')?.touched &&
                      getFormControl('country')?.hasError('required')) ||
                    (getFormControl('country')?.untouched && isSubmitted)
                  "
                >
                <img class="countryIcon"
                src="/assets/images/exclamation-mark.png" alt="error">
                  <label
                    id="mat_add1-error"
                    class="error help-block"
                    for="mat_add1"
                    >Please Enter Country</label
                  >
                </ng-container>
                <ng-container
                  *ngIf="
                    getFormControl('country')?.touched &&
                    getFormControl('country')?.hasError('pattern')
                  "
                >
                <img class="countryIcon"
                src="/assets/images/exclamation-mark.png" alt="error">
                  <label
                    id="mat_add1-error"
                    class="error help-block"
                    for="mat_add1"
                    >Please Enter Only Letters</label
                  >
                </ng-container>
              </div>
            </div>
          </div>
          <button type="submit" class="mat_addloc">Save</button>
        </form>
      </div>
    </div>
  </div>

  <button class="materials_reviewsubmitbtn" [disabled]="!enableSubmit || invalidValue" (click)="submitMaterials()">
    Submit Request for Printed Materials
  </button>
</section>
<section
  *ngIf="showthankyou"
  class="col-xs-12 col-md-12 materials_availablesection"
>
  <div class="submitted">
    <h2 class="materials_finaliseheader col-xs-8">Thank You</h2>
    <p class="col-xs-8 materials_finalisesubheader">
      Materials Request #{{requestNumber | async}} has been submitted
    </p>
    <p class="col-xs-8 materials_finalisecontent">
      You will receive a confirmation email regarding your request. You should
      receive your materials within a week.
    </p>
    <p class="col-xs-8 materials_finalisecontent">
      If you need additional assistance, please contact our Customer Service
      team directly at <a class="support" [routerLink]="'/support'">flu360 | Support.</a>
    </p>

    <div class="materials_review col-xs-12 materials_thankyoumaterials">
      <span class="materials_reviewheader col-xs-10">Materials</span>
      <div *ngFor="let material of materialReviewList">
        <div class="col-xs-12 materials_reviewcontainer" id="materialsreview_1">
          <div class="row col-xs-12" style="display: flex">
            <div class="materials_revmaxlimit">10</div>
            <div class="col-xs-11 materials_reviewlist">
              <img
                width="140"
                height="58"
                class="materials_reviewimg col-xs-3"
                [src]="getImage(material.image)"
                alt="altText"
              />
              <div class="materials_reviewitem col-xs-9">
                {{material.title}}
              </div>
              <div style="float: left; position: relative">
                <input
                  type="hidden"
                  value="GBR-FLU-24-0021"
                  name="productDetails[0].materialID"
                /><input
                  type="number"
                  class="col-xs-2 materials_reviewval"
                  [value]="material.quantity"
                  name="productDetails[0].qty"
                  disabled="true"
                /><i class="materials_rewviewerroricon"></i>
              </div>
              <span>Quantity</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xs-12 materials_selection materials_thankyoulocations">
      <span class="col-xs-12 materials_locationheader">Location</span>
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="checkbox">
          <th mat-header-cell *matHeaderCellDef></th>
          <td
            mat-cell
            *matCellDef="let element"
            [ngClass]="element.checked ? 'disbleRow' : ''"
            class="col-xs-1"
          >
            <div class="materials_selection">
              <label class="checkbox-button"
                ><input
                  type="checkbox"
                  class="material_checkbox"
                  name="checkbox"
                  [(ngModel)]="element.checked"
                /><span class="checkbox-button__control"></span>
              </label>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="shippingOrganizationName">
          <th mat-header-cell *matHeaderCellDef></th>
          <td
            mat-cell
            *matCellDef="let element"
            [ngClass]="element.checked ? 'disbleRow' : ''"
            class="col-xs-3"
          >
            {{ element.shippingOrganizationName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="shippingAddressLine1">
          <th mat-header-cell *matHeaderCellDef></th>
          <td
            mat-cell
            *matCellDef="let element"
            [ngClass]="element.checked ? 'disbleRow' : ''"
            class="col-xs-3"
          >
            {{ element.shippingAddressLine1 }}
          </td>
        </ng-container>
        <ng-container matColumnDef="col4">
          <th mat-header-cell *matHeaderCellDef></th>
          <td
            mat-cell
            *matCellDef="let element"
            [ngClass]="element.checked ? 'disbleRow' : ''"
            class="col-xs-5"
          >
          {{ element.town }}, {{element.postalCode}}
          </td>
        </ng-container>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          (click)="(!row.checked)"
          [ngClass]="selection.isSelected(row) ? 'selected' : ''"
          class="col-xs-12 materials_finalloc"
        ></tr>
      </table>
    </div>
  </div>
</section>
