import { CmsConfig, ConfigModule } from '@spartacus/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomPipComponent } from '../custom-pip/custom-pip.component';

@NgModule({
    declarations: [
        CustomPipComponent
    ],
    imports: [
      CommonModule,
      ConfigModule.withConfig({
        cmsComponents: {
          StacticMediaFlexComponent: {
              component: CustomPipComponent
          }
        }
      } as CmsConfig)
    ]
  })

export class CustomPipModule { }
  