
<div *ngIf="enablePage.showukOrdersBanner">
  <div *ngIf="isLoading" class="loading">
    <div class="loading_txt">
    <div class="load_txt">Loading</div>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
    </div>
</div>

<ng-container *ngIf="seasonData as item">

  <div class="col-12" id="orders_topcontent" *ngIf="currentSite != 'seqirusb2b-uk'">
    <div class="row">
    <div class="col-9 col-md-3 col-lg-3 right_border" id="orders_topcontentleftcontent">
      <h1 class="col-xs-12 orders_titeltop modified-h1">My Orders</h1>
    </div>
    <div class="col-3  col-md-4 col-lg-5  no-dis" id="orders_topcontentleftcontent">
      <div class="col-xs-12 orders_titeltop orders_titeltopNo" >{{item.orderCount}}</div>
    </div>
   
    <div class="col-12 col-md-5 col-lg-4" id="orders_topcontentleftcontent">
      
      
      <div class="row seasonDropdown" >
<!--         <div class="d-none">{{setseason(item.seqirusSeasonList[0])}}</div>
 -->        <select  (change)="selectedSeason($event)" class="current_sesson_dropdown" name="session" id="select_season">
          <!-- <option value="item.inSeason" selected>Current Season: {{item.inSeason}}</option> -->
          <!-- <option selected="selected" value="2024-2025">Current Season: 2024-2025</option> -->
        <option *ngFor="let obj of item.seqirusSeasonList; let i= index" [value]="obj" [selected]="parameterobj.season == obj"> {{(obj == item.inSeason)?'Current Season':(obj == item.preSeason)?'Pre-Book Season': 'Previous Season'}} : {{obj}} </option>
<!--                             
                              <option  value="2023-2024">Previous Season: 2023-2024</option>
                              <option value="2022-2023">Previous Season: 2022-2023</option>
                              <option value="2021-2022">Previous Season: 2021-2022</option>
                              <option value="2020-2021">Previous Season: 2020-2021</option>
                              <option value="2019-2020">Previous Season: 2019-2020</option> -->
                              </select>
        
                            </div>
      </div>
    </div>
  </div>

  <div class="col-12 floatleft" id="orders_topcontent" *ngIf="currentSite == 'seqirusb2b-uk'">
   
      <div class="col-xs-10 col-md-10 col-lg-10 orders_topcontentleftcontentUK" id="orders_topcontentleftcontent">
        <h1 class="col-12 orders_titeltop orders_titeltopUK modified-h1">My Orders</h1>
     
      
      <!-- {{currentSite}} -->
      <div class="col-12 " id="orders_topcontentukselect">
        
        
        
          <select  (change)="selectedSeason($event)" class="current_sesson_dropdown current_sesson_dropdownUK" name="session" id="select_season" >
            <!-- <option value="item.inSeason" selected>Current Season: {{item.inSeason}}</option> -->
            <!-- <option selected="selected" value="2024-2025">Current Season: 2024-2025</option> -->
          <option *ngFor="let obj of item.seqirusSeasonList; let i= index" [value]="obj" [selected]="parameterobj.season == obj"> {{(obj == item.inSeason)?'Select Season': 'Select Season'}} {{obj.replace('-20','/')}} </option>
          <!--                             
                                <option  value="2023-2024">Previous Season: 2023-2024</option>
                                <option value="2022-2023">Previous Season: 2022-2023</option>
                                <option value="2021-2022">Previous Season: 2021-2022</option>
                                <option value="2020-2021">Previous Season: 2020-2021</option>
                                <option value="2019-2020">Previous Season: 2019-2020</option> -->
                                </select>
          
                              
        </div>
      </div>
        <div *ngIf="seasonData.orderCount > 0 " class="col-xs-2 col-md-2 col-lg-2 no-dis orders_topcontentleftcontentUK" id="orders_topcontentleftcontent">
          <div class="col-xs-12 orders_titeltop orders_titeltopNo" ><span>{{seasonData.orderCount}}</span>Orders</div>
          
        </div>

  
     
  </div>
  <div *ngIf="currentSite == 'seqirusb2b-uk'" class=" col-12 PibannerUk floatleft"><a class="pibannerlink" href="/prescribinginformation">Prescribing Information <img alt="Next screen" src="/assets/images/arrow-right-white.svg"></a></div>

  
 
  </ng-container>
</div>
<div *ngIf="enablePage.showukCartBanner">
  <app-orders-cartbanner></app-orders-cartbanner>
</div>
   
  