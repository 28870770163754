import { ChangeDetectorRef, Component } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';
import { Observable } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ContentService } from '../../spartacus/services/content.service';

@Component({
  selector: 'app-jobbagcode-uk',
  templateUrl: './jobbagcode-uk.component.html',
  styleUrl: './jobbagcode-uk.component.scss'
})
export class JobbagcodeUkComponent {
  paracontent:string="";
  currentPage: string | undefined;

  constructor(public component: CmsComponentData<any>,protected contentService:ContentService,private cdr:ChangeDetectorRef,private sanitizer:DomSanitizer,protected config: OccConfig,private cmsService:CmsService) {  
    this.cmsService.getCurrentPage().subscribe((page)=>{
      this.currentPage = page?.pageId;
    })
  }

  ngOnInit(){
    
    this.component.data$.subscribe((data) =>{
      
      this.paracontent = data?.jobBagCode;

    })
  }
}
