import { ChangeDetectorRef, Component, Renderer2 } from '@angular/core';
import { CmsBannerComponent, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-first-comp',
  templateUrl: './first-comp.component.html',
  styleUrl: './first-comp.component.scss'
})
export class FirstCompComponent {
  topheadline: Observable<any> = this.bannerComponent.data$;
  
  getImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.url;
  }

  constructor(
      public bannerComponent: CmsComponentData<CmsBannerComponent>,
      protected config: OccConfig,
      private userInfoService:UserInfoService,
    private renderer:Renderer2,
     private cd:ChangeDetectorRef,private router:Router) {}
  
      userLoggedIn: boolean = false;
      userInfo: any;
      webEnabled: any;
      ngOnInit(){
        this.userInfoService.userLoggedIn$.subscribe(res=>{
          this.userLoggedIn = res;
          if(this.userLoggedIn){
          // this.userInfoService.getUserData().subscribe(res=>{
          //   console.log(res);
          // if(res){
          //   this.userInfo=res;

          //   this.cd.detectChanges();
          //   }
          // });
          this.webEnabled =  localStorage.getItem('webEnabled');
        }
        })
        if(this.userInfo?.webEnabled == false){
          this.renderer.setStyle(document.querySelector('.SideContent'),'display','none');
         }
        this.cd.detectChanges();
      }

      saveUrlAndRedirect(url: string, params: String) {
        localStorage.setItem('redirectProductUrl', url);
        this.router.navigate(['login'], { queryParams: { src: params } });
    }

}
