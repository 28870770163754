import { Component,AfterViewInit,OnInit,ViewChild, ChangeDetectorRef, ElementRef, HostListener, Renderer2, ComponentFactoryResolver, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { CustomfilterPipe } from '../../cms-common/Pipes/customfilter.pipe';
import { FormControl } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CmsComponentData } from '@spartacus/storefront';
import { CmsBannerComponent, CmsService, OccEndpointsService } from '@spartacus/core';
import { ActivatedRoute } from '@angular/router';
import { AppliedCreditDetails } from '../../financial-dashboard-uk/credits-section/credits-section.component';
import { DatePipe } from '@angular/common';
import { AllInvoiceCreditComponent } from '../../global/all-invoice-credit/all-invoice-credit.component';
import { MatDateFormats } from '@angular/material/core';
import { CustomDateAdapter } from '../../shared/custom-date-adapter';
export interface PeriodicElement1 {
  loc: string;
  ordernum: number;
  prodname: string;
  season:string;
  status:string;
  unit:number;

}
export const CUSTOM_DATE_FORMATS:MatDateFormats = {
  parse:{
    dateInput:'MM/DD/YYYY',
  },
  display:{
    dateInput:'MM/DD/YYYY',
    monthYearLabel:'MMM YYYY',
    dateA11yLabel:'LL',
    monthYearA11yLabel:'MMMM YYYY',
  }
}

@Component({
  selector: 'app-all-invoices-left-section',
  templateUrl: './all-invoices-left-section.component.html',
  styleUrl: './all-invoices-left-section.component.scss'
})

export class AllInvoicesLeftSectionComponent implements AfterViewInit {
  loadedFromSummary: any;
  valformsummary: any;
  isSavedCardsOpen: boolean = false;
  isIframeOpen: boolean = false;
  storedScheduledPayData: any;
  formattedScheduledDate: any;
  schedulePaySavedCardDetail: any;
  isSchedulePay: boolean=false;
  filteredData1: any;

  @HostListener('document:click', ['$event'])
  onMessage(event: MessageEvent)
   {}

  togglefilterView:boolean=false;
  togglesortView:boolean=false;

  selectedOption:string='option1';
  dateInput: any;
  today = new Date();

  displayedColumns: string[] = ['combined'];
 //dataSource = new MatTableDataSource<any>();
 dataSource:any=[];
 dataSource1= new MatTableDataSource<any>();
 displayedColumns1: string[] = ['fullProductName','totalOrderdQty','totalInvoiceAmount','totalShippedQty','latestStatus'];
displayproduct:string[]=['quantity','shipmentStatus'];
 dataSourceWithPageSize = new MatTableDataSource<PeriodicElement1>;
  @ViewChild('paginator', {static: false}) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
    @ViewChild(AllInvoiceCreditComponent) creditDataTable!: AllInvoiceCreditComponent;
currentDivCredit = false;
currentDivAch = false;
isCreditSameAmount = false;
showCardSection = false;
cardImages = ["../../assets/images/Visa_icon.png", "../../assets/images/amex_icon.png", "../../assets/images/master_icon.png", "../../assets/images/Discover_icon.png"];
  iframeUrl: any;
  iframeUrlTerms:any;
  iframredirect = false;
  creditsSection: any;
  availableCreditsSection: any;
  creditOnToal :any= [];
  showApply = true;
  showRemove = false;

  substractCredit= false;
  totalAfterCredits:number = 0;
  finalCreditList: any;
  topRemoveClicked = false;
  errorCode: any;
  selectedSort: string | null = null;
  showSuccessOrError: boolean = false;
  rightisLoading: boolean =false;
  showSuccessBanner: boolean =false;
  successOrErrorScreenLoaded :boolean = false;
  payMessage: any;
  payErrorMsg1: any;
  payErrorMsg2: any;
  payErrorMsg3: any;
  disableNext: boolean = false;
  isNegative: boolean = false;
  checkOutId: any;
  isCancel: any;
  subjectStatus: boolean =false;
  sortedData: any[] = [];
  emailId = 'customerservice.us@seqirus.com';
  showDiv = false;
filteropen(){
  this.togglefilterView=!this.togglefilterView;
  this.togglesortView=false;
}
sortopen(){
  this.togglefilterView=false;
  this.togglesortView=!this.togglesortView;

}

iclicked(){

}

resetFilter() {
  this.filterValues.filterbystatus = [''];
  this.selectedstatus = [];
  this.filterValues.filterbyproduct = [];
  this.getstatus.forEach((item:any) => item.isChecked = false);
  this.applyFilter()
}

resetSort() {
  // this.sort= MatSort;
  //this.sort._stateChanges.next();
  this.sort.sort({
    id: 'column-name', // The column name specified for matColumnDef attribute
    start: 'asc', // Can be 'asc', 'desc' or null,
    disableClear: false
  })
  this.selectedSort = null;
}

handleOnLinkClick(event: any) {
  event.preventDefault();
  window.open(this.iframeUrlTerms, '_blank');
  // this.router.navigate(['/orders/my-orders']);
}
  // @ViewChild('paymentIframe')
  // iframe!: ElementRef;
dropdownValue:any;
getfilterdata:any;
apiresponse:any;
rightsectionresponse:any;
savedCardsData:any
count=0;
getstatus:any
selectedproducts:any[]=[];
selectedstatus:any[]=[];
isLoading = false;
showingLabel: string = "Showing";
  pageSize: number = 6;
  pageSizeControl = new FormControl(6);
  totalLength = 0;
  currentPage: number = 1;
  pageSizeOptions: number[] = [5, 10, 15];
firstvalue:string='';
cardPOInputValue:string = "";
hasCardPoInputError:boolean = false;
filterValues = {
  search: '',
  filterbyproduct:[],
  filterbystatus:[''],

};

   constructor(private cmsService: CmsService,private dateAdapter:CustomDateAdapter,private datePipe:DatePipe,private userInfoService: UserInfoService,private cd:ChangeDetectorRef, private sanitizer:DomSanitizer, private renderer: Renderer2, private occ: OccEndpointsService,private activatedRoute: ActivatedRoute) {
    this.today.setDate(this.today.getDate()+1);
   }
   

applyFilterNew(event: Event){
  const filterValue = (event.target as HTMLInputElement).value;
  this.creditDataTable.applyFilter(filterValue);
}
//getStatus(){
  // if(localStorage.getItem('isCancelled') == 'true'){
  //   this.iframredirect = false;

  // }
  // this.cd.detectChanges();
 // this.isCancel = localStorage.getItem('isCancelled')
  //this.cd.detectChanges();
  //return this.isCancel;

//}
getStatus(){

  this.userInfoService.isCancelled$.subscribe(res=>{
   // this.iframredirect = false;
   this.subjectStatus =res;
   if(this.subjectStatus == true){
    this.iframredirect = false;
    this.cd.detectChanges()
   }
   })
   return this.iframredirect;
}
  ngOnInit(): void {
   // setCancelStatus
  //  localStorage.setItem('iframeInvoiceid',this.rightsectionresponse.invoiceNumber)
  //     localStorage.setItem('iframeSeason',this.dropdownValue)

     this.userInfoService.isCancelled$.subscribe(res=>{
      this.iframredirect = false;
     })
    // this.userInfoService.getCancelStatus().subscribe(res=>{
    //   this.iframredirect = false;
    // })
    this.cd.detectChanges()
    // this.isCancel = localStorage.getItem('isCancelled')
    // localStorage.removeItem('isCancelled');
    this.isLoading = true;
    this.cmsService.getComponentData('SeqirusAllInvoiceMessageComponent').subscribe(data => {
     // console.log('banner', data);
      this.payMessage = data
   })
   this.cmsService.getComponentData('SeqirusInvoicePaymentError1').subscribe(data => {
    //console.log('banner', data);
    this.payErrorMsg1 = data
 })
 this.cmsService.getComponentData('SeqirusInvoicePaymentError2').subscribe(data => {
  //console.log('banner', data);
  this.payErrorMsg2 = data
})
this.cmsService.getComponentData('SeqirusInvoicePaymentError3').subscribe(data => {
  //console.log('banner', data);
  this.payErrorMsg3 = data;
 // console.log('banner', this.payErrorMsg3);
})
   //SeqirusInvoicePaymentError1

   this.getstatus=[
    { list: 'OPEN', isChecked: false },
    { list: 'DUE SOON', isChecked: false },
    { list: 'OVERDUE', isChecked: false },
    { list: 'PAID', isChecked: false }
  ]
    this.userInfoService.getDropdownValue().subscribe(value => {
      this.dropdownValue = value;
      this.rightisLoading = true;
      this.cd.detectChanges();
    this.userInfoService.getAllInvoices(this.dropdownValue).subscribe(value=>{
        this.apiresponse=value?.invoiceList?.invoices;
        this.userInfoService.setInvoiceCount(value.invoiceList.openInvoiceCount + value.invoiceList.paidInvoiceCount);;
        this.userInfoService.setScheduledPaymentCount(value.scheduledPaymentCount);
        this.dataSource = new MatTableDataSource(this.apiresponse);
        this.dataSourceWithPageSize = new MatTableDataSource(this.apiresponse);
        this.isLoading = false;
        this.rightisLoading=false;
        this.cd.detectChanges();
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.totalLength = this.apiresponse.length;
        this.updateTableData();
        this.dataSource.sortingDataAccessor = (item:any,property:any) => {
          switch(property){
          case 'invoiceDate':
            return new Date(item.invoiceDate).getTime();
          default:return item[property];
          
        }
        };
        this.firstvalue=this.apiresponse[0].invoiceNumber;
        let iframeId :any = localStorage.getItem('iframeInvoiceid')
        //let finalSeason:any =localStorage.getItem('iframeSeason')
     if(localStorage.getItem('iframeInvoiceid')){
        this.firstvalue = iframeId;
        let iframecredit:any =  localStorage.getItem('creditsApplied')
        if(iframecredit){
        this.creditOnToal = JSON.parse(iframecredit)
        }
        if(localStorage.getItem('isCancelTransaction') != 'true' && localStorage.getItem('iframeErrorCode') != '200'){
        setTimeout(() => {
          this.showCards();
         // this.creditOnToal = []
        }, 2000);
      }

        //this.userInfoService.sendDropdownValue(finalSeason)
        
        localStorage.removeItem('iframeInvoiceid')
        //localStorage.removeItem('creditsApplied')
        localStorage.removeItem('iframeObj')
     }
        this.activatedRoute.queryParams.subscribe(params => {
          this.loadedFromSummary = params;
          if(this.loadedFromSummary.invoice != undefined && this.loadedFromSummary.season !=undefined) {

         // this.userInfoService.sendDropdownValue(this.loadedFromSummary.season)
          //this.filterValues.search = this.loadedFromSummary?.invoice;
          //this.applyFilter();
          this.firstvalue = this.loadedFromSummary.invoice;
          this.valformsummary = this.loadedFromSummary.invoice
          }
        })
        if(this.firstvalue && this.firstvalue != 'N/A'){
        let selectedInvoice = this.apiresponse.find((inv:any) => inv.invoiceNumber == this.firstvalue)
        this.openrightsectio(this.firstvalue,selectedInvoice.status);
        }
   });
  });

    this.storedScheduledPayData = JSON.parse(localStorage.getItem('iframeObj') || '{}');
    this.storedScheduledPayData.scheduledDate?this.selectedOption='option2':'';
    this.dateInput= new Date(this.storedScheduledPayData.scheduledDate);
    
  // this.apiresponse=this.invoiceData.invoices;
  //       this.dataSource = new MatTableDataSource(this.apiresponse);
  //       this.dataSourceWithPageSize = new MatTableDataSource(this.apiresponse);
  //       console.log(this.dataSourceWithPageSize);
  //       this.isLoading = false;
  //       this.cd.detectChanges();
  //       this.dataSource.paginator = this.paginator;
  //       console.log(this.dataSource.paginator);
  //       this.dataSource.sort = this.sort;
  //       this.totalLength = this.apiresponse.length;
  //       this.updateTableData()
  //       this.firstvalue=this.apiresponse[0].ordernum;
  //       if(this.firstvalue){
  //       this.openrightsectio(this.firstvalue);
  //       }}
  const body=<HTMLElement>document.querySelector("body");
  body.classList.add("all-invoice-us");
}  
ngAfterViewInit(){
  }
  updateDataSource() {

  const sortDirection = this.sort.direction;
  const activeColumn = this.sort.active;

  // Create a copy of the original data array
  this.sortedData = [...this.apiresponse];

  //if filtered data present,apply sort to filtered data
  if(this.filteredData1?.length > 0){
    this.sortedData = [...this.filteredData1];
  }

  // Sort the data based on the current sort direction and active column
  if (sortDirection && activeColumn) {
    this.sortedData.sort((a, b) => {
      const isAsc = sortDirection === 'asc';
      return this.compare(a[activeColumn], b[activeColumn], isAsc);
    });
  }
  this.currentPage=1;
   const startIndex = (this.currentPage - 1) * this.pageSize;
   const endIndex = startIndex + this.pageSize;
  const paginatedData = this.sortedData.slice(startIndex, endIndex); 

  // Update the dataSource with the sorted data
  this.dataSource.data = paginatedData;
  //this.goToFirstPage();
 

  }

  // Helper function for sorting
  compare(a: string | number, b: string | number, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  isCardExpired(date: string): boolean {
    const [month, year] = date.split('/');
    const today = new Date();
    const cardExpiry = new Date(parseInt(year), parseInt(month), 0); // 0 gets the last day of the previous month
    return today > cardExpiry;
  }
  getElementStatus(val: string): string {
    if(val.toLowerCase() === 'due soon'){
      return 'duesoon'
    } else {
      return val;
    }
  }
  getExpiryClass(expiryDate: string): string {
    const [month, year] = expiryDate.split('/');
    const today = new Date();
    const cardExpiry = new Date(parseInt(year), parseInt(month), 0); // 0 gets the last day of the previous month

    if (cardExpiry < today ||
      (cardExpiry.getFullYear() === today.getFullYear() &&
      (cardExpiry.getMonth() === today.getMonth() || cardExpiry.getMonth() === today.getMonth() +1))
    ) {
      return 'expired_card';
    } else {
      return '';
    }

  }


  inputsearch(event:Event){
    const filterValue = (event.target as HTMLInputElement).value;
    this.filterValues.search =filterValue.trim().toLowerCase();
    this.dataSource.filter = filterValue || ''.trim().toLowerCase();
   
    this.dataSource.paginator = this.paginator;
    if(this.dataSource.paginator){
      this.dataSource.paginator.firstPage()
    }
  }

  incrementcountstatus(event:any,i:number){

    if (event.target.checked) {

      this.count++;
      this.selectedstatus.push(event.target.value);
      this.filterValues.filterbystatus=this.selectedstatus;


    } else {
      var index = this.selectedstatus.indexOf(event.target.value);
      this.selectedstatus.splice(index, 1);
      this.filterValues.filterbystatus=this.selectedstatus;
     this.count--;
    }
  }

  sortTest(val:any){
    let newdir :string = 'desc'
    if(val == '1to9' || val == '9to1'){
    const sortState: Sort = {active: 'invoiceNumber', direction: val == '1to9'?'asc': 'desc'};
    this.sort.active = sortState.active;
    this.sort.direction = sortState.direction;
    this.sort.sortChange.emit(sortState);

    }
    else if(val == 'AtoZ' || val == 'ZtoA'){
      const sortState: Sort = {active: 'location', direction: val == 'AtoZ'?'desc': 'asc'};
      this.sort.active = sortState.active;
      this.sort.direction = sortState.direction;
      this.sort.sortChange.emit(sortState);
    }
    else if(val == 'LeastRecent' || val == 'MostRecent'){
      const sortState: Sort = {active: 'invoiceDate', direction: val == 'LeastRecent'?'asc': 'desc'};
      this.sort.active = sortState.active;
      this.sort.direction = sortState.direction;
      this.sort.sortChange.emit(sortState);
    }
    this.updateDataSource()
  }
  applyFilter() {
    this.filteredData1 = new CustomfilterPipe().transform(this.apiresponse, this.filterValues);
    this.dataSource = new MatTableDataSource(this.filteredData1);
    this.cd.detectChanges();
    this.dataSourceWithPageSize = new MatTableDataSource(this.apiresponse);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    this.totalLength = this.filteredData1.length;
    this.currentPage=1;
    const starti = (this.currentPage - 1) * this.pageSize;
    const endi = starti + this.pageSize;
    this.dataSource.data = this.filteredData1.slice(starti, endi);
  }
  updateTableData() {
    const starti = (this.currentPage - 1) * this.pageSize;
    const endi = starti + this.pageSize;
    if(this.sortedData.length) {
     this.dataSource.data = this.sortedData.slice(starti, endi);
    } else {
      this.dataSource.data = this.apiresponse.slice(starti, endi);
    }
  }
  onPageSizeChange(event: any) {
    this.pageSize = parseInt(event.target.value, 10);
    this.currentPage = 1;
    this.updateTableData();
  }
  goToLastPage() {
    const totalPages = Math.ceil(this.totalLength / this.pageSize);
    this.currentPage = totalPages;
    this.updateTableData();
  }
  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
   }

   goToFirstPage() {
    this.currentPage = 1;
    this.updateTableData();
  }

  getPages(): any {
    const totalPages = Math.ceil(this.totalLength / this.pageSize);
    const pages: any = [];
    const startPage = Math.max(1, this.currentPage - 1);
    const endPage = Math.min(totalPages, startPage + 2);

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);


    }
    if (endPage < totalPages - 1) {
      pages.push('...');
    }
    if (endPage < totalPages) {
      pages.push(totalPages);
    }
    return pages;
  }
   goToPage(page: number) {
    this.currentPage = page;
    const starti = (page - 1) * this.pageSize;
    if(this.sortedData.length) {
      this.dataSource.data = this.sortedData.slice(starti, starti + this.pageSize);
    //this.dataSource.data = this.dataSource.data.slice(starti, starti + this.pageSize);

    } else {
      this.dataSource.data = this.apiresponse.slice(starti, starti + this.pageSize);
    }


  }
   previousPage() {
    if (this.currentPage > 1) {
      this.goToPage(this.currentPage - 1);
    }
  }
     nextPage() {
    const totlpages = Math.ceil(this.totalLength / this.pageSize);
    if (this.currentPage < totlpages) {
      this.goToPage(this.currentPage + 1);
    }
  }
  isFirstPage(): boolean {
    return this.currentPage == 1;
  }
  isLastPage(): boolean {
    return this.currentPage == Math.ceil(this.totalLength / this.pageSize);
  }
  isCurrentPage(page: number): boolean {
    return this.currentPage == page;
  }
  ShowCredit(){
    //showCardSection
    this.dateInput=null;
    this.cardPOInputValue="";
      this.selectedOption='option1';
    this.currentDivAch = false;

    //this.creditOnToal =[];isIframeOpen
    if(this.isIframeOpen == false && this.isSavedCardsOpen == false && this.successOrErrorScreenLoaded == false){
      this.currentDivCredit = ! this.currentDivCredit;
      this.showCardSection  = false;
      this.iframredirect = false;
      this.showSuccessOrError = false;
    }
    else if(this.isSavedCardsOpen == true && this.isIframeOpen == false && this.successOrErrorScreenLoaded == false){
      this.currentDivCredit = false;
      this.showCardSection = !this.showCardSection;
      this.iframredirect = false
      this.showSuccessOrError = false;
    }
    else if(this.isIframeOpen == true && this.isSavedCardsOpen == true && this.successOrErrorScreenLoaded == false){
      this.currentDivCredit = false;
      this.showCardSection  = false;
      this.iframredirect = !this.iframredirect;
      this.showSuccessOrError = false;
    }
    else if(this.isIframeOpen == false && this.isSavedCardsOpen == true && this.successOrErrorScreenLoaded == true){
      this.currentDivCredit = false;
      this.showCardSection  = false;
      this.iframredirect = false;
      this.showSuccessOrError = !this.showSuccessOrError;
    }
    else if(this.isIframeOpen == true && this.isSavedCardsOpen == true && this.successOrErrorScreenLoaded == false){
      this.currentDivCredit = false;
      this.showCardSection  = false;
      this.iframredirect = !this.iframredirect;
      this.showSuccessOrError = false;
    }
    this.cd.detectChanges();
  }
  ShowAch(){
    this.currentDivCredit = false;
    this.showCardSection = false;
    this.showSuccessOrError = false;
    this.iframredirect = false;
    this.currentDivAch = ! this.currentDivAch;


  }
  hideCredit(){
    this.currentDivCredit = false
  }
  showCards(){
    if(this.isCreditSameAmount) {
      const appliedCreditDetails:AppliedCreditDetails={
        invoiceNumber: this.rightsectionresponse.invoiceNumber,
        creditNotes: [ ...this.creditOnToal],
        paymentFlag: 'X'
      }
      this.userInfoService.updateAppliedCreditDetails(appliedCreditDetails).subscribe((data :any)=>{
      })
      this.showSuccessOrError = true;
      this.errorCode = 200;
      this.finalCreditList = null;
      this.isCreditSameAmount = false;
      this.cd.detectChanges();
      return;
    }
    this.rightisLoading = true;
    this.userInfoService.fetchSavedCards().subscribe((data :any)=>{
      this.savedCardsData = data.cardsData;
      this.showCardSection = true;
      this.isSavedCardsOpen = true;
      this.iframredirect = false;
      this.currentDivCredit = false;
      this.rightisLoading = false;
      
      this.cd.detectChanges();

    })

  }
  retryClicked(){
    this.showCardSection = true;
    this.showSuccessOrError = false;
    this.successOrErrorScreenLoaded = false;

  }
  cancelTransactionClicked(){
    this.showSuccessOrError = false;
    this.currentDivCredit = true;
    //this.isSavedCardsOpen == true && this.isIframeOpen == false && this.successOrErrorScreenLoaded == false
    this.successOrErrorScreenLoaded = false;
    this.isSavedCardsOpen = false;
    this.creditOnToal = [];
  }
  savedCardClicked(data:any){

    this.rightisLoading = true;
    let input :any= this.getFinalCreditAmmount()
    let obj1:any ={
      invoiceAmt : parseFloat(input).toFixed(2),
      invoiceNumber: this.rightsectionresponse.invoiceNumber,
      tokenId: data.token,
      poNumber: this.cardPOInputValue,
      creditsAppliedFlag: "applied",
       appliedCredits: {
         creditNotes: [...this.creditOnToal],
        paymentFlag: "",
        invoiceNumber: this.rightsectionresponse.invoiceNumber
      }
    }
    let obj2:any ={
      invoiceAmt : this.rightsectionresponse.amountDue,
      invoiceNumber: this.rightsectionresponse.invoiceNumber,
      tokenId: data.token,
      poNumber:  this.cardPOInputValue,
      appliedCredits: {
        creditNotes: [...this.creditOnToal],
       paymentFlag: "",
       invoiceNumber: this.rightsectionresponse.invoiceNumber
     }


    }
    let FinalObj = this.creditOnToal.length >0? obj1 :obj2

    if(this.selectedOption =='option2'){
      this.isSchedulePay=true;
      if(this.dateInput && this.dateInput > new Date() && this.dateInput < new Date(this.rightsectionresponse.paymentDueDate)){
      this.formattedScheduledDate = this.getScheduledDateFormatForApi(this.dateInput);
      FinalObj.scheduledDate=this.formattedScheduledDate;
      FinalObj.dueDate = this.rightsectionresponse.paymentDueDate;

      this.userInfoService.schedulePayWithSavedCard(FinalObj).subscribe((res)=>{


        this.showCardSection = false;
        this.currentDivCredit = false;
        this.rightisLoading = false;
       // this.isIframeOpen = false;
       this.cd.detectChanges();
       this.errorCode = res.errorCode;
       this.schedulePaySavedCardDetail = res.paymentDetails;
       this.schedulePaySavedCardDetail.currentDate =this.formattedScheduledDate;
       if(this.errorCode && this.errorCode == 200){
        this.showSuccessBanner = true;
        this.currentDivCredit =false;
       }
       this.showSuccessOrError = true;
       this.successOrErrorScreenLoaded = true;
       this.cd.detectChanges();
      })
      }
      else{
        this.rightisLoading = false;
      }
    }
    else{
      this.userInfoService.savedCardClicked(FinalObj).subscribe((res)=>{


        this.showCardSection = false;
        this.currentDivCredit = false;
        this.rightisLoading = false;
       // this.isIframeOpen = false;
       this.cd.detectChanges();
       this.errorCode = res.errorCode;
       if(this.errorCode && this.errorCode == 200){
        this.showSuccessBanner = true;
        this.currentDivCredit =false;
       }
       this.showSuccessOrError = true;
       this.successOrErrorScreenLoaded = true;
       this.cd.detectChanges();
      })
    }
    // {
    //   "invoiceAmt": "string",
    //   "invoiceNumber": "string",
    //   "tokenId": "string",
    //   "poNumber": "string",
    //   "creditsAppliedFlag": "string",
    //   "schemeTransactionId": "string",
    //   "appliedCredits": {
    //     "invoiceNumber": "string",
    //     "creditNotes": [
    //       {
    //         "creditNoteNumber": "string",
    //         "creditAmount": "string"
    //       }
    //     ],
    //     "paymentFlag": "string"
    //   }
    // }
  }
  iframClicked(){
    //schedule payment changes
    if(this.selectedOption =='option2'){
      this.isSchedulePay=true;
      if(this.dateInput && this.dateInput > new Date() && this.dateInput < new Date(this.rightsectionresponse.paymentDueDate)){
      this.formattedScheduledDate = this.getScheduledDateFormatForApi(this.dateInput);
      let obj:any ={
        /* invoiceAmt : this.rightsectionresponse.total,
        invoiceNumber: this.rightsectionresponse.invoiceNumber,
        poNumber: this.rightsectionresponse.poNumber,
        creditsAppliedFlag: "string",
        scheduledDate:this.dateInput, */
        varaint:'110',
        returnURL :"https://dev5.flu360.com/payment-status"
        //returnURL :"http://local.flu360:4200/payment-status"
  
      }
      this.userInfoService.getIframeForSchedulePayment(obj).subscribe((response:any) => {
       
       // this.checkOutId = response.hostedCheckoutId;
      this.showCardSection = false
      this.userInfoService.setcheckOutId(response.hostedCheckoutId)
      let input :any= this.getFinalCreditAmmount();
      let obj1:any ={
        invoiceAmt :parseFloat(input).toFixed(2),
        invoiceNumber: this.rightsectionresponse.invoiceNumber,
        tokenId: response.hostedCheckoutId,
        poNumber:  this.cardPOInputValue,
        creditsAppliedFlag: "applied",
         appliedCredits: {
           creditNotes: [...this.creditOnToal],
          paymentFlag: "",
          invoiceNumber: this.rightsectionresponse.invoiceNumber,
        }
        
      }
      let obj2:any ={
        invoiceAmt : this.rightsectionresponse.amountDue,
        invoiceNumber: this.rightsectionresponse.invoiceNumber,
        tokenId: response.hostedCheckoutId,
        poNumber:  this.cardPOInputValue,
        appliedCredits: {
          creditNotes: [...this.creditOnToal],
         paymentFlag: "",
         invoiceNumber: this.rightsectionresponse.invoiceNumber,
       }


      }
      let FinalObj = this.creditOnToal.length >0? obj1 :obj2;
      FinalObj.scheduledDate = this.formattedScheduledDate;
      FinalObj.dueDate = this.rightsectionresponse.paymentDueDate;
      localStorage.setItem('scheduledDate', this.formattedScheduledDate)
      localStorage.setItem('iframeObj', JSON.stringify(FinalObj))
      localStorage.setItem('iframeInvoiceid',this.rightsectionresponse.invoiceNumber)
      localStorage.setItem('iframeSeason',this.dropdownValue)
      this.isIframeOpen = true
      this.iframeUrl= response.partialRedirectUrl;
      this.iframredirect = true;
      this.cd.detectChanges();
      })
    }
  }
    else{
      this.getIframeUrl();
    }
    //this.showCardSection = false
    
  }
  getScheduledDateFormatForApi(dateInput: Date): any {
    return (dateInput.getMonth()+1).toString().padStart(2,'0')+'/'+dateInput.getDate().toString().padStart(2,'0')+'/'+dateInput.getFullYear();
  }

  getIframeUrl() {
    let obj:any ={
      invoiceAmt : this.rightsectionresponse.amountDue,
      invoiceNumber: this.rightsectionresponse.invoiceNumber,
      poNumber:  this.cardPOInputValue,
      creditsAppliedFlag: "string",
      //returnUrl :this.occ.buildUrl('/payment-status')
     returnUrl :"https://dev5.flu360.com/payment-status"
      //returnUrl :"http://local.flu360:4200/payment-status"

    }
    this.userInfoService.redirectIframeUrl(obj).subscribe(res=>{
      this.checkOutId = res.hostedCheckoutId;
      this.showCardSection = false
      this.userInfoService.setcheckOutId(res.hostedCheckoutId)
      let input :any= this.getFinalCreditAmmount();
      let obj1:any ={
        invoiceAmt :parseFloat(input).toFixed(2),
        invoiceNumber: this.rightsectionresponse.invoiceNumber,
        tokenId: res.hostedCheckoutId,
        poNumber:  this.cardPOInputValue,
        creditsAppliedFlag: "applied",
         appliedCredits: {
           creditNotes: [...this.creditOnToal],
          paymentFlag: "",
          invoiceNumber: this.rightsectionresponse.invoiceNumber,
        }
      }
      let obj2:any ={
        invoiceAmt : this.rightsectionresponse.amountDue,
        sessionStorage,
        tokenId: res.hostedCheckoutId,
        poNumber:  this.cardPOInputValue,
        appliedCredits: {
          creditNotes: [...this.creditOnToal],
         paymentFlag: "",
         invoiceNumber: this.rightsectionresponse.invoiceNumber,
       }


      }
      let FinalObj = this.creditOnToal.length >0? obj1 :obj2

      localStorage.setItem('iframeObj', JSON.stringify(FinalObj))
      localStorage.setItem('iframeInvoiceid',this.rightsectionresponse.invoiceNumber)
      localStorage.setItem('iframeSeason',this.dropdownValue)
      //this.userInfoService.setPaymentObj(FinalObj)
      this.isIframeOpen = true
      this.iframeUrl= res.partialRedirectUrl;
      this.iframredirect = true;
    this.cd.detectChanges();
    })


    // const iframeUrl = this.occEndPointsService.buildUrl(`/SeqirusPDF/terms-conditions-sales-2024-2025?fields=DEFAULT`);
    // this.http.get(iframeUrl,   { responseType: 'text' }).subscribe(data => {
    //   let url = this.occEndPointsService.buildUrl(`${data}`); //&embedded=true
    //   url = url.replace("occ/v2/seqirusb2b-flu360/","");
    //   this.iframeUrl.next(url);
    // })
  }
  cleanURL(): SafeResourceUrl {
    //this.iframredirect = true;
    //this.getIframeUrl();
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.iframeUrl);
  }


  downloadInvoice(invoicenum : string) {
   this.rightisLoading = true;

    this.userInfoService.downloadInvoiceApi(invoicenum).
    subscribe((data: ArrayBuffer) => {

         // this.cd.detectChanges()
          // Create a Blob from the ArrayBuffer
          const file = new Blob([data], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          //window.open(fileURL); // Open in a new tab

          const a = document.createElement('a');
          a.href = fileURL;
          a.target = '_blank';
          a.download = invoicenum;
          document.body.appendChild(a);
          a.click();
         // this.rightisLoading = false;
         this.rightisLoading = false;
         this.cd.detectChanges()
        },

        (error) => {
          this.rightisLoading = false;
          this.cd.detectChanges()
        }

      );



  }
  makeRemovetoggle(){
    this.topRemoveClicked = true;
  }
  getFinalCreditAmmount(){
    let newAmount = this.rightsectionresponse.amountDue- this.getFullCredit();
    if(newAmount < 0){
      this.isNegative = true;
      //this.cd.detectChanges()
    }else if(newAmount >= 0){
      this.isNegative = false
      //this.cd.detectChanges()
    }
    return this.rightsectionresponse.amountDue- this.getFullCredit();
    //$(".invoice_creditnote-sumAmt").text().replace(/[$,]/g, '')
  }
  trackByCreditNum(index: number, item: any): any {
    return item.creditNoteNumber; // Assuming each item in creditOnToal has a unique id property
  }

  creditAppliedEvent(returnObject:any) {
    const {inv,credit, toggleButton, creditNum, index} =  returnObject;
    this.creditApplied(inv,credit, toggleButton, creditNum, index);
  }
  creditApplied(inv:Event, credit:number, toggleButton:string, creditNum:number, index:number){

    this.isNegative = false;
    //this.cd.detectChanges()
    const event = inv.target as HTMLElement;

    if(toggleButton == 'credit'){
      if(event.classList.contains('glyphicon')){
        event.parentElement?.classList.add("creditApplyClicked");
      }else{
    event.classList.add("creditApplyClicked");
      }
    this.substractCredit = true;

    let obj={
      creditAmount : credit,
      creditNoteNumber : creditNum,
      id:index

    }
  if(this.rightsectionresponse.amountDue < credit ){
    this.disableNext = true
  }
  if(this.rightsectionresponse.amountDue == credit ){
    this.isCreditSameAmount = true
  }
    this.creditOnToal.push(obj);
    localStorage.setItem('creditsApplied', JSON.stringify(this.creditOnToal));

    // for(let i=0; i <= this.creditOnToal.length;i++ ){
    // this.totalAfterCredits = this.totalAfterCredits+ this.creditOnToal[i].creditAmount
     if(event.classList.contains('glyphicon')){
      event.parentElement?.nextElementSibling?.classList.add("removeEnabled");
    }else{
      event.nextElementSibling?.classList.add("removeEnabled")
    }
    }

    else if(toggleButton == 'remove'){
      let newIndex = index
      //if(this.topRemoveClicked){
        this.disableNext = false;
        this.substractCredit = true;
      const removeId = document.getElementById('removeId-'+ index);

      removeId?.classList.remove("removeEnabled");
    //  if(clickedClass == 'topClicked'){
    //   let finalindex = this.creditOnToal.findIndex((item:any)=> item.id == index );
    //   newIndex = finalindex
    //   console.log(finalindex)
    //  }else if(clickedClass == 'tableClicked'){
    //   newIndex = index
    //  }

    //  let finalindex = this.creditOnToal.findIndex((item:any)=> item.id == index );

    //   this.creditOnToal.splice(finalindex,1);
      this.creditOnToal = this.creditOnToal.filter((item:any) => item.creditNoteNumber !== creditNum)
      //console.log(this.creditOnToal,index);
      removeId?.previousElementSibling?.classList.remove("creditApplyClicked");

     // this.creditsSection.totalAvailableCredit = this.creditsSection.totalAvailableCredit+credit;
     //this.topRemoveClicked =false;
    }

  }
  // creditRemoved(inv:number, credit:number,i:number){
  //   this.creditsSection.totalAvailableCredit = this.creditsSection.totalAvailableCredit+credit;
  //   console.log(this.creditsSection.totalAvailableCredit);
  //   this.showRemove = true
  // }
  getFullCredit(){
   return this.creditOnToal.reduce((sum:any, el:any) => sum += el.creditAmount, 0)
  }
  openrightsectio(invoiceId:string, status:string){
    const temp= <HTMLElement>document.getElementsByClassName("activeorder")[0];
    if(temp){
    temp.classList.remove("activeorder");
    }
    const temp1= <HTMLElement> <unknown>document.getElementById(invoiceId);
    if(temp1){
    temp1.classList.add('activeorder');
    }
    this.rightisLoading = true;
    this.substractCredit = false;
    this.togglefilterView = false;
    this.togglesortView = false;
    // if(invoiceId){
    //   invoiceId=invoiceId.split("#")[1];

    //   }
      //'9130226308'
      this.isSchedulePay =false;
      this.isIframeOpen=false;
      this.currentDivAch =false;
      this.iframredirect = false;
      this.currentDivCredit = false;
      this.showCardSection = false;
      this.isSavedCardsOpen = false;
      this.showSuccessOrError = false;
      
      if(localStorage.getItem('isCancelled') != 'true' && !localStorage.getItem('scheduledDate')){
        this.dateInput=null;
        this.selectedOption='option1';
        }
      if(localStorage.getItem('isCancelTransaction') != 'true' && localStorage.getItem('iframeErrorCode') != '200' && localStorage.getItem('isCancelled') != 'true'  ){
        this.creditOnToal = [];


      }
    this.userInfoService.AllInvoicesRightsection(this.dropdownValue, invoiceId,status ).subscribe(value=>{
      localStorage.removeItem('isCancelled');
      this.rightsectionresponse=value;
      this.finalCreditList = JSON.parse(this.rightsectionresponse.availablecreditsList)
      this.rightisLoading = false;
      

      this.dataSource1 = new MatTableDataSource(this.rightsectionresponse);
      this.cd.detectChanges();
      // Compare dates
      const today = new Date();
      const expDate = new Date(this.rightsectionresponse.payDiscountExpDate);
      this.showDiv = expDate >= today;

      this.userInfoService.getIframeUrlTerms(this.dropdownValue).subscribe(res=>{
        let url = this.occ.buildUrl(`${res}`); //&embedded=true
        url = url.replace("occ/v2/seqirusb2b-flu360/", "");
        this.iframeUrlTerms = url;
      })

     });

    // this.rightsectionresponse= this.rightSection.SeqirusInvoiceDetailsDTO;



    //   this.dataSource1 = new MatTableDataSource(this.rightsectionresponse);
    //   this.cd.detectChanges();


  }
  //expandedElement: any | null;

  // toggleRow(element: any) {
  //   element.expanded = !element.expanded;
  // //  this.expandedrowdata=element.shippedStatusTable;
  //   this.cd.detectChanges();
  // }
  handleOnInputValueChange($event: any) {
    const specialCharsRegex = /[!@#$%^&*()_+\-=\[\]{};':",\\|,.<>\/?]+/;
    this.hasCardPoInputError = specialCharsRegex.test(this.cardPOInputValue);
  }

  formatCardValue(text: string) {
    const matches = text.match(/.{1,4}/g);
    return matches?.join(" ") || "";
  }

  //Schedule pay changes
  maskInputDate(event:any){
    var v = event.target.value;
    if (v.match(/^\d{2}$/) !== null) {
      event.target.value = v + '/';
    } else if (v.match(/^\d{2}\/\d{2}$/) !== null) {
      event.target.value = v + '/';
    }
  }
  getFormattedDueDate(date:string){
    let maxDate = new Date(date);
    maxDate.setDate(maxDate.getDate()-1)
    return maxDate;

  }
  isValidForSchedule(date:string){
    return new Date(date) > this.today;
  }

  clearDate(){
    if(this.selectedOption == 'option1'){
      this.dateInput=null;
    }
  }

  allowNumbersOnly(event:KeyboardEvent){
    const charCode = event.keyCode;
    if(charCode < 48 || charCode >57){
      event.preventDefault();
      return false;
    }
    return true;
  }
  
  isFieldFocused(inputref:any):boolean{
    return document.activeElement === inputref.valueAccessor._elementRef.nativeElement;
  }
  

}
