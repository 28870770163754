import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {
  private downloadStatus = new Subject<{ invnumber: number, isDownloading: boolean }>();

  downloadStatus$ = this.downloadStatus.asObservable();

  setDownloadStatus(invnumber: number, isDownloading: boolean) {
    this.downloadStatus.next({ invnumber, isDownloading });
  }
}