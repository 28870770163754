<!--for flucelvax top banner-->
<div id="cellBasedSolution" *ngIf="currentRoute !== 'fluad'"> 
    <!--desktop-->
     <section class="cellBasedSolution-product_common-banner cell-base-desktop d-none">
        <div class="cellBasedSolution-simple-banner banner__component--responsive">
            <img class="js-responsive-image" alt="Modern cell-based environment" title="Modern cell-based environment"
                src="assets/images/flucelvax_banner_LI_desktop_1440x170.webp">
        </div>
    </section> 
    <!--mobile version-->
    <section class="cellBasedSolution-product_common-banner cell-base-mobile d-none">
        <div class="cellBasedSolution-simple-banner banner__component--responsive">
            <img class="js-responsive-image" alt="Modern cell-based environment" title="Modern cell-based environment"
                src="assets/images/flucelvax_banner_mobile_750x340.webp">
        </div>
    </section>
</div>
<!--desktop nvigation-->
<div class="product_secondary-navigation fluad-nav hidden-xs hidden-sm desktop-nav" *ngIf="topheadline | async as item">
    <div class="container outercontainer">
        <a *ngFor="let item2 of mergedObject1; let i= index" [routerLink]="item2?.linkUrl" [innerHTML]="item2?.linkName"
            [ngClass]="{'blue-background': i === mergedObject1.length - 1,'blue-un':i==mergedObject1.length-2 ,'blue-un2':i==mergedObject1.length-3,'blue-un3':i==mergedObject1.length-4,'blue-un4':i==mergedObject1.length-5}"></a>
    </div>
</div>
<!-- for mobile view navigation-->
<div class="col-xs-12 hidden-md hidden-lg no-padding products-mobile-navigation">
    <div class="panel-group product_menu-Accordian fluad_menu-accordian" id="product_menu-Accordian">
        <div class="panel panel-default" *ngIf="topheadline | async as item">
            <div class="panel-heading">
                <h4 class="panel-title">
                    <a [routerLink]="mergedObject1[0].linkUrl" class="d-none fluad-heading" [innerHTML]="mergedObject1[0].linkName"></a>
                    <a [routerLink]="mergedObject1[1].linkUrl" class="d-none adjuvanted-heading" [innerHTML]="mergedObject1[1].linkName"></a>
                    <a [routerLink]="mergedObject1[2].linkUrl" class="d-none clinical-fluad-heading" [innerHTML]="mergedObject1[2].linkName"></a> 
                    <a [routerLink]="mergedObject1[3].linkUrl" class="d-none real-world-nav" [innerHTML]="mergedObject1[3].linkName"></a>

                    <a data-toggle="collapse" data-parent="#product_menu-Accordian" href="#productMenu" class="" aria-expanded="true">
                        <span class="arrow-icon" (click)="ismobnavCollapsed = !ismobnavCollapsed">
                            <i [ngClass]="{'fa-angle-down': !ismobnavCollapsed, 'fa-angle-up': ismobnavCollapsed}" class="fa fa-fw pull-right fas"></i>
                        </span>
                    </a>
                </h4>
            </div>
            <div id="productMenu" class="panel-collapse collapse" [ngClass]="{'in': ismobnavCollapsed}">
                <div class="yCmsContentSlot">
                    <div class="panel-body">
                        <a *ngFor="let item2 of mergedObject1; let i= index" href="{{item2?.linkUrl}}" [innerHTML]="item2.linkName"
                        [ngClass]="{'blue-background': i === mergedObject1.length - 1}"  class="child-item"></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>