import { ChangeDetectorRef, Component, Renderer2 } from '@angular/core';
import { CmsComponentData, NavigationNode, NavigationService } from '@spartacus/storefront';
import { CmsNavigationComponent, CmsLinkComponent, AuthService, User, UserIdService, CmsBannerComponent, OccConfig, BaseSiteService} from '@spartacus/core';
import { Observable, Subscription, forkJoin, map, take } from 'rxjs';
import { ContentService } from '../../spartacus/services/content.service';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { NavigationEnd, Router } from '@angular/router';
import { CsAgentAuthService } from '@spartacus/asm/root';
import { JoinAccountService  } from '../../shared';


@Component({
  selector: 'app-top-header',
  templateUrl: './top-header.component.html',
  styleUrl: './top-header.component.scss'
})

  export class TopHeaderComponent {
    toggleListView:boolean=false;
    node$: Observable<NavigationNode | null | undefined | any> = this.service.createNavigation(
      this.componentData?.data$
    );
    isCartExist: Observable<any> = this.userInfoService.checkCartExist();
    styleClass$: Observable<string | undefined> = this.componentData.data$.pipe(
      map((d) => d?.styleClass)
    );
  navigation: any=[];
  mobnav:any=[];
  rbavarible:any;
  Ukclass: boolean = false;
  iconClass:string="fa fa-bars";
  isOpen: boolean=false;
  afluriaurl: any;
  fluadurl: any;
  flucelvaxurl: any;
  isUserLoggedIn: boolean=false;
  userInfo: any;
  glyphiconIcon = "glyphicon-menu-down";
  iconClassMob: string="fa fa-bars";
  subscription:Subscription[]=[];
  flucelvaxPdf!: Observable<any>;
  fluadPdf!: Observable<any>;
  afluriaPdf!: Observable<any>;
  currentSite: string | undefined;
  multipleSoldTos: any;
  selectedAccount:string='';
  iconUkAcc: string="fa-angle-down";
  isCollapsed: boolean=false;
  searchText:string="";
  filteredMultipleSoldTos: any;
  asmUser: boolean = false;
  asmEnabled: boolean = false;
  asmAgentLoggedIn: boolean = false;
  customerEmulated: boolean = false;
  newuserData:boolean = true;
  private subscriptionJoinAccount: Subscription | undefined; // Store subscripti
  isSearchByProfile: boolean=false;

    constructor(
      public userInfoService:UserInfoService,public component: CmsComponentData<CmsBannerComponent>,
      private cdr:ChangeDetectorRef,private authService: AuthService,
      protected componentData: CmsComponentData<CmsNavigationComponent>,
      protected service: NavigationService, protected _mobmenu:ContentService,
      protected csAgentAuthService: CsAgentAuthService,
      protected config: OccConfig,private baseSiteService:BaseSiteService,private router: Router,private renderer:Renderer2,
      private joinAccountService: JoinAccountService,public contentService: ContentService,
    ) {

        // if(this.csAgentAuthService.isCustomerSupportAgentLoggedIn() && this.csAgentAuthService.isCustomerEmulated()){
        //   console.log("asm agent");
        // }
    }

    //multipleSoldTos$= this.userInfoService.fetchMultipleSoldToForUser();
    showStatus() {
     
      this.asmEnabled = localStorage.getItem('showASM') === 'true';
      this.isSearchByProfile = localStorage.getItem('isSearchByProfile') == 'true' ? true:false;
      this.csAgentAuthService.isCustomerSupportAgentLoggedIn().subscribe(i => {
          this.asmAgentLoggedIn = i;
      });
      this.csAgentAuthService.isCustomerEmulated().subscribe(res => {
        this.customerEmulated = res;
      });
      if(this.asmEnabled && this.asmAgentLoggedIn && this.customerEmulated) {
        return false;
      }
      return true;
    }

     goMyprofile(){
      this.router.navigate(['/my-profile']).then(() => {
        window.location.reload();
      });
     }

     logOutredirect(){
      this.router.navigate(['/logout']).then(() => {
        window.location.reload();
      });
     }

    fetchPIPdfData(){
      if(this.currentSite == 'seqirusb2b-flu360'){
        this.afluriaPdf= this._mobmenu.getNavigation("AfluriaHeaderLink");
        this.fluadPdf= this._mobmenu.getNavigation("FluadHeaderLink");
        this.flucelvaxPdf= this._mobmenu.getNavigation("FlucelvaxHeaderLink");

        this.afluriaPdf.pipe(map(val=> val.media?.url)).subscribe(res => {
          this.afluriaurl = res;
      });
      this.fluadPdf.pipe(map(val=> val.media?.url)).subscribe(res => {
        this.fluadurl = res;
      });
      this.flucelvaxPdf.pipe(map(val=> val.media?.url)).subscribe(res => {
        this.flucelvaxurl = res;
      });
      }
    }




    openList(){
      this.toggleListView=!this.toggleListView;

    }
    toggleSlideUkAcc(){
      if(this.iconUkAcc === 'fa-angle-down'){
        this.iconUkAcc = 'fa-angle-up';
        this.isCollapsed=true;
      }
      else{
        this.iconUkAcc='fa-angle-down';
        this.isCollapsed=false;

      }
    }
    toggleSlideLoggedInMob(){
      if(this.iconClassMob === 'fa fa-bars'){
        this.iconClassMob = 'fa fa-times';
        this.isOpen=true;
        this.userInfoService.setMobileHeader(true);
      }
      else{
        this.iconClassMob = 'fa fa-bars';
        this.isOpen=false;
        this.userInfoService.setMobileHeader(false);
      }
    }
    toggleSlide(){
      this._mobmenu.togglemabmenu=!this._mobmenu.togglemabmenu;

      const temp= <HTMLElement> document.getElementsByClassName("mob_exp_menu")[1];

      if(this._mobmenu.togglemabmenu){
        temp.style.display="block";
      }
      else{
        temp.style.display="none";
      }
      if(this.iconClass === 'fa fa-bars'){
        this.iconClass = 'fa fa-times';
        this.isOpen=true;
      }
      else{
        this.iconClass = 'fa fa-bars';
        this.isOpen=false;
      }
    }

    setDifferentTopHeader(){
      const temp= <HTMLElement> document.getElementsByClassName("TopHeaderNavSlot")[0];
      if(temp){
      if(this.isUserLoggedIn && this.userInfo.webEnabled){
          temp.style.display='none';
      }
      else if((!this.isUserLoggedIn) || ((this.isUserLoggedIn) && (!this.userInfo.webEnabled))){
        temp.style.display='block';
        }
      }
    }
    setDifferentBottomHeader(){
      const temp= <HTMLElement> document.getElementsByClassName("BottomHeaderSlot")[1];
      const body=<HTMLElement>document.querySelector("body");
      if(temp){
      if(this.isUserLoggedIn && this.userInfo.webEnabled){
        body.classList.add("loggedin");
        body.classList.remove("non-webEnabled");
        temp.style.display='none';
      }
      else if((this.isUserLoggedIn) && (!this.userInfo.webEnabled)){
        body.classList.add("non-webEnabled");
        body.classList.remove("loggedin");
        temp.style.display='none';
      }
      else if((!this.isUserLoggedIn)){
        body.classList.remove("loggedin");
        body.classList.remove("non-webEnabled");
        temp.style.display='none';
        }
        else{
        body.classList.remove("loggedin");
        body.classList.remove("non-webEnabled");
        temp.style.display='none';
        }
      }
    }



    ngOnInit(){
      this.baseSiteService.getActive().subscribe((siteId) => {
        this.currentSite = siteId;
      });
      this.isSearchByProfile = localStorage.getItem('isSearchByProfile') == 'true' ? true:false;
      this.subscriptionJoinAccount = this.joinAccountService.formSubmitted$.subscribe(() => {
        this.updateMultipleSoldToHeader();
      });



      const body=<HTMLElement>document.querySelector("body");
      if(this.currentSite=='seqirusb2b-uk'){
        body.classList.add("seqirusb2b-uk");
      }else{
        body.classList.add("seqirusb2b-flu360");
      }
      this.router.events.subscribe((event) => {

        if(event instanceof NavigationEnd){
          this.updateUserData();
          this.updateMultipleSoldToHeader();
          this.isCollapsed=false;
          this.iconUkAcc='fa-angle-down';
          this.cdr.detectChanges();
        }
      })


      // if(!this.afluriaurl && !this.fluadurl && !this.flucelvaxurl){
      this.fetchPIPdfData();
      // }
      this.updateUserData();
     // this.toprbafunction();
      this.cdr.detectChanges();
      this.node$.subscribe((res:any)=>{
        if(res){

          if(res.title=='For GB Healthcare Professionals Only'){


            this.navigation=res.children[0].children;
            this.Ukclass = true;

          }

          if(res.title=='For US Healthcare Professionals Only' && this.currentSite == 'seqirusb2b-flu360'){
            let a=res.children[0].children.find((x:any)=>x.title==='For US Healthcare Professionals Only');
            let b=res.children[0].children.find((x:any)=>x.title==='Prescribing Information');
            b.children[0].url = this.fluadurl;
            b.children[1].url = this.flucelvaxurl;
            b.children[2].url = this.afluriaurl;

            this.navigation=res.children[0].children;
            this.navigation.splice(this.navigation.findIndex((x:any)=>x.title==='For US Healthcare Professionals Only') , 1);
            this.navigation.unshift(a)
          }
          if(res.title=='Main Navigation Component' && this.currentSite == 'seqirusb2b-flu360'){
            let a=res.children[0].children.find((x:any)=>x.title==='Prescribing Information');
            a.children[0].url = this.fluadurl;
            a.children[1].url = this.flucelvaxurl;
            a.children[2].url = this.afluriaurl;
            this.mobnav = res.children[0].children;

          }
        }
      })

      if(this.currentSite == 'seqirusb2b-uk'){
       this.updateMultipleSoldToHeader();
      }

    }

    isUserregistered(){
      this.contentService.getnewUserData().pipe(take(1)).subscribe(
        response => {   
        this.newuserData = response.regSubmitted;
        this.cdr.detectChanges();
       // console.log('User registered' + this.newuserData);
        }, error => {
        //  console.error('User not registered', error);
          this.cdr.detectChanges();
        });
    }

    

    ngAfterViewInit() {
      
 
      this.userInfoService.getcarttrigger().subscribe(value => {
        this.isCartExist= this.userInfoService.checkCartExist();
      });



    this.userInfoService.getrbaheader().subscribe(value => {
      if(value){
       // this.rbavarible=localStorage.getItem('rbapermission');
        this.rbavarible=value;
        this.cdr.detectChanges();
      }
    });

    this.userInfoService.getswitchrba().subscribe(value => {
      if(value){
        this.rbavarible=value;
        this.cdr.detectChanges();
      }
    });
    }
  updateUserData() {
   if((!this.router.url.includes('homepage') && !this.router.url.includes('login')) || this.currentSite=='seqirusb2b-flu360'){
    this.authService.isUserLoggedIn().subscribe(res=>{
      this.isUserLoggedIn=res;
      
      if(this.isUserLoggedIn){
        this.userInfoService.getUserData().subscribe(res=>{
          if(res){
          this.userInfo=res;
          localStorage.setItem('webEnabled',  this.userInfo.webEnabled);
          this.selectedAccount = this.userInfo.orgUnit.uid;
          this.rbavarible=localStorage.getItem('rbapermission');
          this.rbavarible=JSON.parse(this.rbavarible);
          if(!this.userInfo.webEnabled){
          this.isUserregistered();
          }
          this.cdr.detectChanges();
          this.setDifferentTopHeader();
          this.setDifferentBottomHeader();

          }
        })
        
      }
      else{
      this.setDifferentTopHeader();
      this.cdr.detectChanges();
      }
    })
    }
  }
  updateMultipleSoldToHeader() {
    if(this.isUserLoggedIn){
      this.userInfoService.fetchMultipleSoldToForUser().subscribe((data:any) => {
        this.multipleSoldTos = data.multipleSoldToData;
        this.filteredMultipleSoldTos=this.multipleSoldTos;
        this.cdr.detectChanges();
      })
    }
  }
    getImage(image: any): string {
      return this.config.backend?.occ?.baseUrl + image.url;
    }
    ngOnDestroy(){
      if (this.subscriptionJoinAccount) {
        this.subscriptionJoinAccount.unsubscribe(); // Prevent memory leak
      }
    }
    onRadiochange(acc:string){
      this.selectedAccount=acc;

    }
    changeAccount(){
      const href = window.location.href;
      let inPlaceOrder:any;
      this.userInfoService.getInPlaceOrder().subscribe(val => {
        inPlaceOrder = val;
      })
      if(href.includes("start-order") && inPlaceOrder){
        this.userInfoService.setSelectedAccount(this.selectedAccount);
        this.userInfoService.setRouteLink("switchAccount");
        this.userInfoService.setQueryParams("");
        this.userInfoService.setPopUpValue(true);
      }
      else {
        localStorage.removeItem('rbapermission');
        sessionStorage.removeItem('returnsFirstPopup');
        sessionStorage.removeItem('returnsSecondPopup');
        this.userInfoService.changeB2BAccount(this.selectedAccount).subscribe((data)=>{
        if(!this.router.url.includes('my-account')){
          this.router.navigate(['/my-account']);
        }
        else{
        const url = (this.router.url).split('?')[0].split('#')[0];
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
     
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([url.substring(url.lastIndexOf('/')+1)]).then(()=>{
        this.cdr.detectChanges();
        });
      }
        this.userInfoService.updateUserdata();
      });
      }
    }
    filterAccount(){
      this.filteredMultipleSoldTos = this.multipleSoldTos.filter((soldTo:any )=> {
        return Object.values(soldTo).some((value:any) => value.toString().toLowerCase().includes(this.searchText.toLowerCase()));
      });
      // this.cdr.detectChanges();

    }
    toprbafunction(){
      if(this.currentSite=='seqirusb2b-uk' && this.isUserLoggedIn ){

        this.userInfoService.rbasiteflag().subscribe(data=>{

         this.rbavarible=data;
         this.cdr.detectChanges();
        });

      }

     }

     handleLinks(route: string, params: any){
      const href = window.location.href;
      let inPlaceOrder:any;
      this.userInfoService.getInPlaceOrder().subscribe(val => {
        inPlaceOrder = val;
      })
      if((href.includes("start-order") || href.includes("register") ) && inPlaceOrder){
        this.userInfoService.setRouteLink(route);
        this.userInfoService.setQueryParams(params);
        this.userInfoService.setPopUpValue(true);
      }
      else {
        this.router.navigate([route], { queryParams: params});
        
      }
     }
  }
