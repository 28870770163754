import { ChangeDetectorRef, Component } from '@angular/core';
import { ContentService } from '../../spartacus/services/content.service';
import { Observable, combineLatest } from 'rxjs';
import { CmsComponentData } from '@spartacus/storefront';
import { CmsBannerComponent, OccConfig } from '@spartacus/core';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-fluad-rwe-second',
  templateUrl: './fluad-rwe-second.component.html',
  styleUrl: './fluad-rwe-second.component.scss'
})
export class FluadRweSecondComponent {

  cardStates: boolean[] = [];

  constructor(private accessService: ContentService, public bannerComponent: CmsComponentData<CmsBannerComponent>, protected config: OccConfig, private cd: ChangeDetectorRef) {
    this.cardStates = Array(11).fill(false);
   }
  firstheadline: Observable<any> = this.bannerComponent.data$;
  mergedObject1: any = [];
  mergedObject2: any = [];
  mergedObject3: any = [];
    left:boolean=true;
    right:boolean=false;
    

    ngOnInit() {
      this.firstheadline.subscribe(res => {
        this.clinicaltabs(res);
      });
    }
  
    clinicaltabs(res: any) {
      const tilesList = res.reductionList.split(" ");
      const observables = tilesList.map((el: any) => this.accessService.helpandaq(el));
  
      combineLatest(observables).pipe(
        map((results:any) => results.flat())
      ).subscribe((mergedResults: any) => {
        this.mergedObject1 = mergedResults;
        this.cd.detectChanges();
        this.internalcard1(this.mergedObject1[0]);
        this.internalcard2(this.mergedObject1[1]);
      });
      
    }

    internalcard1(res: any) {
      const tilesList = res.tileList.split(" ");
      const observables = tilesList.map((el: any) => this.accessService.helpandaq(el));
  
      combineLatest(observables).pipe(
        map((results:any) => results.flat())
      ).subscribe((mergedResults: any) => {
        this.mergedObject2 = mergedResults;
        this.cd.detectChanges();
      });
    }
  
    internalcard2(res: any) {
      const tilesList = res.tileList.split(" ");
      const observables = tilesList.map((el: any) => this.accessService.helpandaq(el));
  
      combineLatest(observables).pipe(
        map((results:any) => results.flat())
      ).subscribe((mergedResults: any) => {
        this.mergedObject3 = mergedResults;
        this.cd.detectChanges();
      });
    }

    onClickLeft(){
      this.left = true;
      this.right = false;
    }

    onClickRight(){
      this.right=true;
      this.left=false;
    }

    onClickCard1(index: number): void {
      this.cardStates[index] = !this.cardStates[index];
    }

    

}




// import { ChangeDetectorRef, Component } from '@angular/core';
// import { ContentService } from '../../spartacus/services/content.service';
// import { Observable, combineLatest } from 'rxjs';
// import { CmsComponentData } from '@spartacus/storefront';
// import { CmsBannerComponent, OccConfig } from '@spartacus/core';
// import { map } from 'rxjs/operators';

// @Component({
//   selector: 'app-fluad-rwe-second',
//   templateUrl: './fluad-rwe-second.component.html',
//   styleUrl: './fluad-rwe-second.component.scss'
// })
// export class FluadRweSecondComponent {

//   cardStates: boolean[] = [];

//   constructor(private accessService: ContentService, public bannerComponent: CmsComponentData<CmsBannerComponent>, protected config: OccConfig, private cd: ChangeDetectorRef) {
//     this.cardStates = Array(11).fill(false);
//    }
//   firstheadline: Observable<any> = this.bannerComponent.data$;
//   mergedObject1: any = [];
//   mergedObject2: any = [];
//   mergedObject3: any = [];
//     left:boolean=true;
//     right:boolean=false;
    

//     ngOnInit() {
//       this.firstheadline.subscribe(res => {
//         this.clinicaltabs(res);
//       });
//     }
  
//   //   clinicaltabs(res: any) {
//   //     // const tilesList = res.reductionList.split(" ");
//   //     // const observables = tilesList.map((el: any) => this.accessService.opeffeaturedResources(el));
  
//   //     // combineLatest(observables).pipe(
//   //     //   map((results:any) => results.flat())
//   //     // ).subscribe((mergedResults: any) => {
//   //     //   this.mergedObject1 = mergedResults;
//   //     //   this.cd.detectChanges();
//   //     //   console.log(this.mergedObject1);
//   //     //   
//   //     // });
//   //     const tilesList = res.reductionList.split(" ");
//   //     this.accessService.opeffeaturedResources(tilesList).subscribe((res : any) => {
//   //      this.mergedObject1 = res.component;
//   //      console.log('123',this.mergedObject1);
//   //      this.internalcard1(this.mergedObject1[0]);
//   //       this.internalcard2(this.mergedObject1[1]);
//   //      this.cd.detectChanges;

//   //     });
//   //   }

//   //   internalcard1(res: any) {
      
//   //     // const observables = tilesList.map((el: any) => this.accessService.opeffeaturedResources(el));
  
//   //     // combineLatest(observables).pipe(
//   //     //   map((results:any) => results.flat())
//   //     // ).subscribe((mergedResults: any) => {
//   //     //   this.mergedObject2 = mergedResults;
//   //     //   this.cd.detectChanges();
//   //     //   console.log(this.mergedObject2);
//   //     // });
//   //     const tilesList = res.tileList.split(" ");
//   //     this.accessService.opeffeaturedResources(tilesList).subscribe((res : any) => {
//   //      this.mergedObject2 = res.component;
//   //      console.log(this.mergedObject2);
//   //      this.cd.detectChanges;
//   //     });
//   //   }
  
//   //   internalcard2(res: any) {
//   //     // const tilesList = res.tileList.split(" ");
//   //     // const observables = tilesList.map((el: any) => this.accessService.opeffeaturedResources(el));
  
//   //     // combineLatest(observables).pipe(
//   //     //   map((results:any) => results.flat())
//   //     // ).subscribe((mergedResults: any) => {
//   //     //   this.mergedObject3 = mergedResults;
//   //     //   this.cd.detectChanges();
//   //     //   console.log(this.mergedObject3);
//   //     // });
//   //     const tilesList = res.tileList.split(" ");
//   //     this.accessService.opeffeaturedResources(tilesList).subscribe((res : any) => {
//   //      this.mergedObject3 = res.component;
//   //      console.log(this.mergedObject3);
//   //      console.log('hellooo');
//   //      this.cd.detectChanges;
//   //   });
//   // }
//   // clinicaltabs(res: any) {
//   //   const tilesList = res.reductionList.split(" ");
//   //   this.accessService.opeffeaturedResources(tilesList).subscribe((res: any) => {
      
//   //     // Check if res.component is an array
//   //     if (Array.isArray(res.component)) {
//   //       this.mergedObject1 = res.component;
//   //       const item = this.mergedObject1.splice(2, 1);
//   //       this.mergedObject1.unshift(item[0]);
//   //     } else if (res.component) {
//   //       // Convert to array if it's not already
//   //       this.mergedObject1 = [res.component];
//   //     } else {
//   //       // Ensure it's always an array
//   //       this.mergedObject1 = [];
//   //     }
  
//   //     console.log('mergedObject1:', this.mergedObject1);
//   //     this.internalcard1(this.mergedObject1[0]);
//   //     this.internalcard2(this.mergedObject1[1]);
//   //     this.cd.detectChanges();  // Ensure detectChanges is called correctly
//   //   });
//   // }
  
//   // internalcard1(res: any) {
//   //   const tilesList = res.tileList.split(" ");
//   //   this.accessService.opeffeaturedResources(tilesList).subscribe((res: any) => {
      
//   //     // Check if res.component is an array
//   //     if (Array.isArray(res.component)) {
//   //       this.mergedObject2 = res.component;
//   //       const item = this.mergedObject2.splice(2, 1);
//   //       this.mergedObject2.unshift(item[0]);
//   //     } else if (res.component) {
//   //       // Convert to array if it's not already
//   //       this.mergedObject2 = [res.component];
//   //     } else {
//   //       // Ensure it's always an array
//   //       this.mergedObject2 = [];
//   //     }
  
//   //     console.log(this.mergedObject2);
//   //     this.cd.detectChanges();  // Ensure detectChanges is called correctly
//   //   });
//   // }
  
//   // internalcard2(res: any) {
//   //   const tilesList = res.tileList.split(" ");
//   //   this.accessService.opeffeaturedResources(tilesList).subscribe((res: any) => {
      
//   //     // Check if res.component is an array
//   //     if (Array.isArray(res.component)) {
//   //       this.mergedObject3 = res.component;
//   //       const item = this.mergedObject3.splice(2, 1);
//   //       this.mergedObject3.unshift(item[0]);
//   //     } else if (res.component) {
//   //       // Convert to array if it's not already
//   //       this.mergedObject3 = [res.component];
//   //     } else {
//   //       // Ensure it's always an array
//   //       this.mergedObject3 = [];
//   //     }
  
//   //     console.log(this.mergedObject3);
//   //     console.log('hellooo');
//   //     this.cd.detectChanges();  // Ensure detectChanges is called correctly
//   //   });
//   // }
//     onClickLeft(){
//       this.left = true;
//       this.right = false;
//     }

//     onClickRight(){
//       this.right=true;
//       this.left=false;
//     }

//     onClickCard1(index: number): void {
//       this.cardStates[index] = !this.cardStates[index];
//     }

    

// }
