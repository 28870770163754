<div *ngIf="creditBlockModal">
	<div  id="creditpopup" class="modal fade in show" role="dialog"  data-backdrop="static" data-keyboard="false" 
					tabindex="-1" >
			<div class="modal-dialog">
				<div class="modal-content">
								<div class="modal-body" *ngIf="creditPopUpData">
									<div class="col-xs-12 returnopenheader">
									<h4 class="modal-title return_popHeader col-xs-9 no-padding">{{creditPopUpData.headline}}</h4>
									<div class="col-xs-3 no-padding">
										<span class="OMexit_popClose creditBlockExit_pop" data-dismiss="modal" (click)="closeCreditPopUp()">&times;</span>
									</div>
								</div>
									<p class="returnpopupsub col-xs-12 margin-T20">{{creditPopUpData.message1}}</p>
									<p class="returnpopupsub col-xs-12">{{creditPopUpData.message2}}</p>
									
									<ng-container *ngIf="popupsData.hasOverDueInvoice == false;else noOverDue">
										<p class="returnpopupsub  col-xs-12">{{creditPopUpData.message3}}</p>
										<div class="clearboth"></div>
										<div class="OM_exitPopBtnParent">
											<button class="returnbtn allinvoiceredirect creditBlockExit_pop" [innerHTML]="creditPopUpData.content"></button>
										</div>
									</ng-container>
									<ng-template #noOverDue>
										<p class="returnpopupsub  col-xs-12 margin-B20">{{creditPopUpData.message3}}</p>
										<div class="clearboth"></div>
									</ng-template>
									<div class="clearboth"></div>
									<div class="returnpopupjobcode">
										{{creditPopUpData.jobBagCode}}
									</div>
								</div>
							</div>
						</div>
	</div>
	</div>
	
	<div *ngIf="showConsentPopup && !isAsmUser">
	<div id="consentpopup" class="modal fade in show" role="dialog" data-backdrop="static" data-keyboard="false">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-body">
					<h4 class="modal-title consentheader col-xs-9">{{ukConsentPopupData.headline}}</h4>
					<p class="consentsubheader col-xs-12"><span>{{ukConsentPopupData.message1}}</span>
						<span class="consentsubheader_mail">{{marketingEmail}}</span>
							<span>{{ukConsentPopupData.message2}} </span>
							<span>
								<a target="_blank" href={{ukConsentPopupData.button}}>privacy policy</a>.
							</span>
					</p>
					<p class="consentsubheader col-xs-12">{{ukConsentPopupData.message3}}</p>
					<div class="clearboth"></div>
					<div class="consentpopbtnparent">
						<button class="consentyesbtn" (click)="isConsentGranted('true')">{{ukConsentPopupData.content}}</button>
						<button class="consentNobtn" data-dismiss="modal" (click)="isConsentGranted('false')">{{ukConsentPopupData.label1}}</button>
					</div>
					<div class="consentPopupDocUk">{{ukConsentPopupData.jobBagCode}}<div>
				</div>
			</div>
		</div>
	</div>
		</div>
	</div>
	</div>
	
	<!-- US marketing consent popup starts ( marketing opts changes ) -->
	<div *ngIf="showUSConsentPopup && !isAsmUser">
			<div id="consentpopupUS" class="modal fade in show marketing-consent"
				role="dialog" data-backdrop="static" data-keyboard="false">
				<div class="modal-dialog">
					<div class="modal-content">
						<div class="modal-body">
							<h4 class="modal-title consentheader col-xs-10">{{usConsentPopupData.headline}}</h4>
							<div class="no-padding pull-right">
								<span class="consentClose" data-dismiss="modal" (click)="isConsentGranted('false')">&times; </span>
							</div>
							<div class="clearboth"></div>
							<p class="consentsubheader col-xs-12">
								{{usConsentPopupData.message1}}
							</p>
							<p class="consentsubheader col-xs-12" [innerHTML]="usConsentPopupData.message2">
								
							</p>
							<div class="clearboth"></div>
							<div class="consentpopbtnparent">
								<button class="consentyesbtn" (click)="isConsentGranted('true')">
									{{usConsentPopupData.message3}}
								</button>
								<button class="consentNobtn" data-dismiss="modal" (click)="isConsentGranted('false')">
									{{usConsentPopupData.content}}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
	</div>
	<!-- marketing consent popup ends -->
	
	<div *ngIf="showApiFailurePopup">
	<div id="apifailurepopup" class="modal fade in show" role="dialog" data-backdrop="static" data-keyboard="false" tabindex="-1">
				
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-body">
					<div class="col-xs-12 returnopenheader">
						<h4 class="modal-title return_popHeader col-xs-9 no-padding">{{apiFailurePopupData.headline}}</h4>
						<div class="col-xs-3 no-padding">
												<!-- <span class="OMexit_popClose" data-dismiss="modal">&times;</span>  -->
						</div> 
					</div>
					<p class="returnpopupsub col-xs-12 margin-T20">{{apiFailurePopupData.message1}}<br>{{apiFailurePopupData.message2}}</p>
					<div class="clearboth"></div>
					
					<div class="OM_exitPopBtnParent">
						<button class="returnbtn"><a class="exitSkipBtn" (click)="closeApifailurePopup()">{{apiFailurePopupData.label1}}</a></button>
					</div>
					<div class="clearboth"></div>
					<div class="returnpopupjobcode">{{apiFailurePopupData.jobBagCode}}</div>
				</div>
			</div>
		</div>
	</div>
	</div>
	
	<div *ngIf="(showReturnsFirstPopup && !isAsmUser)|| (isAsmUser && isSearchByProfile && showReturnsFirstPopup)">
	<div id="returnpopupactive" class="modal fade in show" role="dialog"  data-backdrop="static" data-keyboard="false" 
					tabindex="-1">
						<div class="modal-dialog">
		
							<div class="modal-content">
								<div class="modal-body">
									<div class="col-xs-12 returnopenheader">
									<h4 class="modal-title return_popHeader col-xs-9 no-padding">{{returnsFirstPopupData.headline}}</h4>
									<div class="col-xs-3 no-padding">
										<span class="OMexit_popClose returnsExit_pop" (click)="closeReturnsFirstPopup()" data-dismiss="modal">&times;</span>
									</div>
								</div>
									<p class="returnpopupsub col-xs-12 margin-T20">{{returnsFirstPopupData.message1}}</p>
									<p class="returnpopupsub col-xs-12" [innerHTML]="returnsFirstPopupData.message2"></p>
									<div class="clearboth"></div>
									<div class="OM_exitPopBtnParent">
										<button class="ReturnDont" data-dismiss="modal" (click)="sendNotShowFirstPopup()">{{returnsFirstPopupData.message3}}</button>
										<button class="returnbtn returnsExit_pop" (click)="closeReturnsFirstPopup()"><a routerLink="/returns">{{returnsFirstPopupData.label1}}</a></button>
									</div>
									<div class="clearboth"></div>
									<div class="returnpopupjobcode">
										{{returnsFirstPopupData.jobBagCode}}
									</div>
								</div>
							</div>
						</div>
	</div>
	</div>
	
	<div *ngIf="(showReturnsSecPopup && !isAsmUser)|| (isAsmUser && isSearchByProfile && showReturnsSecPopup)">
	<div id="returnpopup" class="modal fade in show" role="dialog"  data-backdrop="static" data-keyboard="false" 
					tabindex="-1">
						<div class="modal-dialog">
		
							<div class="modal-content">
								<div class="modal-body">
									<div class="col-xs-12 returnopenheader">
									<h4 class="modal-title return_popHeader col-xs-9 no-padding">{{returnsSecPopupData.headline}}</h4>
									<div class="col-xs-3 no-padding">
										<span class="OMexit_popClose returnsExit_pop" (click)="closeReturnsSecPopup()" data-dismiss="modal">&times;</span>
									</div>
								</div>
									<p class="returnpopupsub col-xs-12 margin-T20">{{returnsSecPopupData.message1}}</p>
									<p class="returnpopupsub col-xs-12" [innerHTML]="returnsSecPopupData.message2"></p>
									<div class="clearboth"></div>
									<div class="OM_exitPopBtnParent">
										<button class="ReturnDont" data-dismiss="modal" (click)="sendNotShowSecPopup()">{{returnsSecPopupData.message3}}</button>
										<button class="returnbtn returnsExit_pop" (click)="closeReturnsSecPopup()"><a routerLink="/returns">{{returnsSecPopupData.label1}}</a></button>
									</div>
									<div class="clearboth"></div>
									<div class="returnpopupjobcode">
										{{returnsSecPopupData.jobBagCode}}
									</div>
								</div>
							</div>
						</div>
	</div>
	</div>