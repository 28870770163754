import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  inject,
  Input,
  EventEmitter,
  Output
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { LiveAnnouncer } from '@angular/cdk/a11y';

export interface Credit {
  billTo: any;
  invoiceDate: string;
  invoiceNumber: number;
  formattedAmountWithTax: string;
  status: string;
}

@Component({
  selector: 'app-all-invoice-credit',
  templateUrl: './all-invoice-credit.component.html',
  styleUrl: './all-invoice-credit.component.scss'
})
export class AllInvoiceCreditComponent implements OnInit {

  @Input() invoices: Credit[] = [];
  @Output() creditAppliedEvent = new EventEmitter<any>();
  @ViewChild(MatSort) sort!: MatSort;

  creditcolumns: string[] = [
    'billTo',
    'invoiceDate',
    'invoiceNumber',
    'formattedAmountWithTax',
    'status',
  ];
  dataSource = new MatTableDataSource<Credit>();

  private _liveAnnouncer = inject(LiveAnnouncer);

  constructor(
    private cd: ChangeDetectorRef
  ) {}
  /** Announce the change in sort state for assistive technology. */
  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.invoices);
    this.dataSource.sort = this.sort;
    this.cd.detectChanges();
  }
  ngAfterViewInit() {
    const sortState: Sort = {active: 'invoiceDate', direction: 'desc'};
    this.sort.active = sortState.active;
    this.sort.direction = sortState.direction;
    this.sort.sortChange.emit(sortState);
  }
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
    this.dataSource.sort = this.sort;
    this.cd.detectChanges();
  }

  applyFilter(filterValue:string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  creditApplied(inv:Event, credit:number, toggleButton:string, creditNum:number, index:number) {
    const event = inv.target as HTMLElement;

    if(toggleButton == 'credit'){
      if(event.classList.contains('glyphicon')){
        event.parentElement?.classList.add("creditApplyClicked");
      }else{
        event.classList.add("creditApplyClicked");
      }
     if(event.classList.contains('glyphicon')){
        event.parentElement?.nextElementSibling?.classList.add("removeEnabled");
      }else{
        event.nextElementSibling?.classList.add("removeEnabled")
      }
    }
    else if(toggleButton == 'remove'){
      const removeId = document.getElementById('removeId-'+ index);
      removeId?.classList.remove("removeEnabled");
      removeId?.previousElementSibling?.classList.remove("creditApplyClicked");
    }
    const returnObject = {
      inv,
      credit,
      toggleButton,
      creditNum,
      index
    }
    this.creditAppliedEvent.emit(returnObject)
  }
}

