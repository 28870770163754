<ng-container *ngIf="isRegisterPage() | async">
<div class="loading" *ngIf="showloader" >
    <div class="loading_txt">
     <div class="load_txt">Loading</div>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
     </div>
</div>
<!------Business Details Section starts here------>  
<div [style.display]="isVisibleBussiness ? 'block' : 'none'" >
    <form  class="registration col-xs-12 no-padding"   [formGroup]="orgAddress">
             <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 registration_leftside no-padding-right">
                 <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 registration_nagivation no-padding">
                 <div class="registration_nagivationPart" id="registration_nagivationPart1">
                         <div class="registration_nagivationNo">
                             <div class="registration_nagivationtick" style="display:block;"><img src="assets\images\greentick.png" alt=""></div>
                         </div>
                         <div class="registration_nagivationName">{{'progresslabel.userProfile' | cxTranslate}}</div>
                     </div>
                     <div class="registration_nagivationPart" id="registration_nagivationPart2">
                         <div class="registration_nagivationNo">
                             <div class="registration_nagivationNumeric">2</div>
                             
                         </div>
                         <div class="registration_nagivationName active">{{'progresslabel.businessDetails' | cxTranslate}}</div>
                     </div>
                  <div class="registration_nagivationPart" id="registration_nagivationPart3">
                         <div class="registration_nagivationNo">
                             <div class="registration_nagivationNumeric">3</div>
                            
                         </div>
                         <div class="registration_nagivationName inactive">{{'progresslabel.contactAddress' | cxTranslate}}<ul>
                                
                                 <li>{{'progresslabel.billinginformation' | cxTranslate}}</li>
                                 <li>{{'progresslabel.payingInformation' | cxTranslate}}</li>
                                 <li>{{'progresslabel.shippingLocation' | cxTranslate}}</li>
                                 
                             </ul>
                         </div>
                     </div>
                     <div class="registration_nagivationPart" id="registration_nagivationPart4">
                         <div class="registration_nagivationNo">
                             <div class="registration_nagivationNumeric">4</div>
                             
                         </div>
                         <div class="registration_nagivationName inactive">{{'progresslabel.reviewConfirm' | cxTranslate}}</div>
                     </div>
                  </div>
             </div>
             <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 registration_rightside no-padding-left">
                 <div class="registration_help">
                 Having trouble? &nbsp;<a routerLink="/help-and-faq"  class="showPopupclick"><span>We Can Help</span><span class="global_blackarrow"></span></a>
                 </div>
                 <section class="registration_business registrationFlow" id="registration_section1" style="display: block;">
                    
                       
                         
                        <h1 class="registration_label padding-B10 new_header_register">Business Details</h1>
                        
                         <div class="registration_text1 padding-B10">Tell us about your business.</div>
                         <div class="registration_text2">All fields marked with an asterisk (*) are required.</div>
                         <div class="registration_existing padding-B20"><a (click)="openregistrationExitpopup()" target="_self" class="showPopupclick">Join an existing business profile instead <div class="global_blackarrow"></div></a></div>
                         <div class="padding-R15">
                             <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding margin-B20 registration_parent form-group">                        
                                 <label class="registration_inputLabel" for="business-orgname">Company Name*</label>
                                 <input class="registration_input form-control" id="orgName" formControlName="orgName">
                                 <div class="text-danger" *ngIf="orgAddress.controls['orgName'].invalid && (isFormSubmitted_bussiness || orgAddress.controls['orgName'].touched || orgAddress.controls['orgName'].dirty)">
                                    <i class="fa-solid fa-circle-exclamation"></i>
                                     <label *ngIf="orgAddress.controls['orgName'].errors?.['required']" class="error help-block">Please enter organization name for business.</label>
                                     <label *ngIf="orgAddress.controls['orgName'].errors?.['pattern']" class="error help-block">Please enter only letters, numbers.</label>
                                 </div>
                                 
                                     
                             </div>
                             <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding-right margin-B20 registration_parent form-group">                        
                                 <label class="registration_inputLabel" for="business-dunnumber">Dun &amp; Bradstreet (DUNS) Number</label>
                                 <input class="registration_input form-control" id="duns" formControlName="duns" autocomplete="off" >
                                 <div class="text-danger" *ngIf="orgAddress.controls['duns'].invalid && (isFormSubmitted_bussiness || orgAddress.controls['duns'].touched || orgAddress.controls['duns'].dirty)">
                                    <i class="fa-solid fa-circle-exclamation"></i>
                                     <label *ngIf="orgAddress.controls['duns'].errors?.['pattern']" class="error help-block">Please enter your Valid DUNS Number</label>
                                     <div *ngIf="!orgAddress.controls['duns'].errors?.['pattern']">
                                     <label *ngIf="orgAddress.controls['duns'].errors?.['minlength']" class="error help-block">Please enter your Valid DUNS Number Min is 10</label>
                                     <label *ngIf="orgAddress.controls['duns'].errors?.['maxlength']" class="error help-block">Please enter no more than 10 characters.</label>
                                      </div>   
                                 </div>
                                 
                             </div>
                             <div class="clearboth"></div>
                         </div>
                             <div class="padding-R15"> <!-- Membership for registration changes -->                     
                                 <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding margin-B20 registration_parent form-group">   
                                     <label class="registration_inputLabel" for="business-membership">Membership</label>
                                     <input class="registration_input form-control" id="membership" formControlName="membership" >
                                 </div>
                                 <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding-right margin-B20 registration_parent form-group ">   
                                     <label class="registration_inputLabel gln_field " for="business-gln">GS1 Global Location Number (GLN)&nbsp;<i class="fa-regular fa-circle-question locations_qm locations_tooltip"><span class="locations_tooltiptext"><p>Please enter your 13-digit GLN in this field.</p>For more information on GLNs, please visit the <a routerLink="/help-and-faq" target="_blank">flu360 Support</a> page.</span></i></label>
                                    
                                     <p-inputMask class="registration_input form-control" autoClear="false"   mask="9999999.99999.9" pattern="\d\d\d\d\d\d\d\.\d\d\d\d\d\.\d" id="globalLocationNumber" formControlName="globalLocationNumber" />
                                     <div class="text-danger" *ngIf="orgAddress.controls['globalLocationNumber'].invalid && (isFormSubmitted_bussiness || orgAddress.controls['globalLocationNumber'].touched || orgAddress.controls['globalLocationNumber'].dirty)">
                                        <i class="fa-solid fa-circle-exclamation"></i>
                                         <label *ngIf="orgAddress.controls['globalLocationNumber'].errors?.['pattern']" class="error help-block">Please enter a valid 13 digit Global Location Number (GLN)</label>
                                      </div>
                                     
                                 </div>
                             </div> <!-- Membership for registration changes --> 
                             <div class="clearboth"></div>
                         
                         <div class="registration_text1  margin-B10">Your membership refers to the organization with which you have a signed contract.</div>
                         <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding margin-B20 registration_address form-group">                        
                             <label class="registration_inputLabel" for="business-lookup">Address Lookup</label>
                             <input class="registration_input form-control" id="businesslookup"  name="businesslookup"  >
                             <span class="glyphicon glyphicon-search form-control-feedback"></span>
                         </div>
                         <div class="padding-R15">
                             <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding margin-B20 registration_parent form-group">                        
                                 <label class="registration_inputLabel " for="business-address1">Address Line 1*</label>
                                 <input class="registration_input form-control" name="line1"   id="addressLine1" formControlName="line1" autocomplete="off" >
                                 <div class="text-danger"   *ngIf="orgAddress.controls['line1'].invalid && (isFormSubmitted_bussiness || orgAddress.controls['line1'].touched || orgAddress.controls['line1'].dirty)">
                                    <i class="fa-solid fa-circle-exclamation"></i> 
                                    <label *ngIf="orgAddress.controls['line1'].errors?.['required']" class="error help-block">Please enter an Address.</label>
                                  </div>
                                  
                             </div>
                             <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding-right margin-B20 registration_parent form-group">                        
                                 <label class="registration_inputLabel " for="business-address2">Address Line 2</label>
                                  <input class="registration_input form-control" name="line2"  id="addressLine2" formControlName="line2" >
                             </div>
                             <div class="clearboth"></div>
                         </div>
                         <div class="padding-R15">
                             <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding margin-B20 registration_parent form-group">                        
                                 <label class="registration_inputLabel " for="business-city">City*</label>
                                 <input class="registration_input form-control"  id="city" name="city" formControlName="city" >
                                 <div class="text-danger"  *ngIf="orgAddress.controls['city'].invalid && (isFormSubmitted_bussiness || orgAddress.controls['city'].touched || orgAddress.controls['city'].dirty)">
                                    <i class="fa-solid fa-circle-exclamation"></i> 
                                    <label *ngIf="orgAddress.controls['city'].errors?.['required']" class="error help-block">Please enter your City.</label>
                                  </div>
                                 
                             </div>
                             <div class="col-lg-2 col-md-2 col-sm-6 col-xs-6 no-padding-right margin-B20 registration_mob form-group">                        
                                 <label class="registration_inputLabel " for="business-state">State*</label>
                                 <select class="registration_state form-control"  id="state" name="state" formControlName="state">
                                     <option selected="selected" *ngFor="let item of stateList" [value]="item.value">{{ item.label }}</option>
                                   </select> 
                                   <div class="text-danger"  *ngIf="orgAddress.controls['state'].invalid && (isFormSubmitted_bussiness || orgAddress.controls['state'].touched || orgAddress.controls['state'].dirty)">
                                    <i class="fa-solid fa-circle-exclamation"></i> 
                                    <label *ngIf="orgAddress.controls['state'].errors?.['required']" class="error help-block">Please select state.</label>
                                  </div>
                                   
                             </div>
                             <div class="col-lg-4 col-md-4 col-sm-6 col-xs-6 no-padding-right margin-B20 form-group">                        
                                 <label class="registration_inputLabel" for="business-zipcode">ZIP Code*</label>
                                 <input class="registration_input form-control"  id="zipCode" name="postalCode" formControlName="postalCode">
                                 <div class="text-danger"  *ngIf="orgAddress.controls['postalCode'].invalid && (isFormSubmitted_bussiness || orgAddress.controls['postalCode'].touched || orgAddress.controls['postalCode'].dirty)">
                                    <i class="fa-solid fa-circle-exclamation"></i> 
                                    <label *ngIf="orgAddress.controls['postalCode'].errors?.['required']" class="error help-block">Please enter a valid US zip code.</label>
                                     <label *ngIf="orgAddress.controls['postalCode'].errors?.['pattern']" class="error help-block">Please enter a valid US zip code.</label>
                                 </div>
                                
                             </div>
                             <div class="clearboth"></div>
                         </div>
                         
                         <button type="button" (click)="onSubmit_bussiness()" class="registration_save active" id="registration_save">Save and Continue</button>
                         
                 </section>
                     
             </div>

              
           
         
         </form>
</div>  
<!------Business Details Section Ends here------> 
<!------Billing  section Starts here------>
 <div [style.display]="isVisibleBilling ? 'block' : 'none'" >
    <form  class="registration col-xs-12 no-padding" novalidate [formGroup]="billingAddress">
       
       <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 registration_leftside no-padding-right">
           <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 registration_nagivation no-padding">
           <div class="registration_nagivationPart" id="registration_nagivationPart1">
                   <div class="registration_nagivationNo">
                       <div class="registration_nagivationtick" style="display:block;"><img src="assets\images\greentick.png" alt=""></div>
                   </div>
                  
                   <div class="registration_nagivationName">User Profile</div>
               </div>
   
               <div class="registration_nagivationPart" id="registration_nagivationPart2">
                   <div class="registration_nagivationNo">
                       <div class="registration_nagivationNumeric" style="display: none;">2</div>
                       <div class="registration_nagivationtick" style="display: block;"><img src="assets\images\greentick.png" alt=""></div>
                   </div>
                   <div class="registration_nagivationName">Business Details</div>
               </div>
            <div class="registration_nagivationPart" id="registration_nagivationPart3">
                   <div class="registration_nagivationNo">
                       <div class="registration_nagivationNumeric">3</div>
                      
                   </div>
                   <div class="registration_nagivationName active">Contacts &amp; Addresses<ul>
                          
                           <li class="active" style="display: list-item;">Billing Information</li>
                           <li style="display: list-item;">Paying Information</li>
                           <li style="display: list-item;">Shipping Location(s)</li>
                           
                       </ul>
                   </div>
               </div>
               <div class="registration_nagivationPart" id="registration_nagivationPart4">
                   <div class="registration_nagivationNo">
                       <div class="registration_nagivationNumeric">4</div>
                       
                   </div>
                   <div class="registration_nagivationName inactive">Review &amp; Confirm</div>
               </div>
            </div>
       </div>
       <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 registration_rightside no-padding-left">
           <div class=" registration_help">
           Having trouble? &nbsp;<a routerLink="/help-and-faq" class="showPopupclick"><span>We Can Help</span><span class="global_blackarrow"></span></a>
           </div>
           
           <section class="registration_billing registrationFlow" id="registration_section2" style="display: block;">
               
                <input type="hidden" id="loginemail" name="hiddenEmail" value="pridalal@mailinator.com">
                  <div class="registration_label padding-B10">Billing Information</div>
                   <div class="registration_text1 padding-B10">Complete this section with the contact information of the person or group who should receive all financial documents from CSL Seqirus.</div>
                   <div class="registration_text2">All fields marked with an asterisk (*) are required.</div>
                   <div class="registration_contact padding-B20">Billing Contact</div>
                   <div class="padding-R15">
                       <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding margin-B20 registration_parent form-group">                        
                           <label class="registration_inputLabel" for="billing-firstname">First Name*</label>
                           <input class="registration_input form-control" id="billing-firstname"  autocomplete="off" formControlName="firstName" >
                           <div class="text-danger" *ngIf="billingAddress.controls['firstName'].invalid && (isFormSubmitted_billing || billingAddress.controls['firstName'].touched || billingAddress.controls['firstName'].dirty)">
                            <i class="fa-solid fa-circle-exclamation"></i>   
                            <label *ngIf="billingAddress.controls['firstName'].errors?.['required']" class="error help-block">Please enter a First Name.</label>
                               <label *ngIf="billingAddress.controls['firstName'].errors?.['pattern']" class="error help-block">Please enter only letters.</label>
                           </div>
                       </div>
                       <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding-right margin-B20 registration_parent form-group">                        
                           <label class="registration_inputLabel" for="billing-lastname">Last Name*</label>
                           <input class="registration_input form-control" id="billing-lastname"  autocomplete="off" formControlName="lastName" >
                           <div class="text-danger" *ngIf="billingAddress.controls['lastName'].invalid && (isFormSubmitted_billing || billingAddress.controls['lastName'].touched || billingAddress.controls['lastName'].dirty)">
                            <i class="fa-solid fa-circle-exclamation"></i>  
                            <label *ngIf="billingAddress.controls['lastName'].errors?.['required']" class="error help-block">Please enter a Last Name.</label>
                               <label *ngIf="billingAddress.controls['lastName'].errors?.['pattern']" class="error help-block">Please enter only letters.</label>
                           </div>
                       </div>
                       <div class="clearboth"></div>
                   </div>
                   <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding margin-B20 registration_parent form-group">                        
                       <label class="registration_inputLabel" for="billing-email">Email Address*</label>
                       <input class="registration_input form-control" id="billing-email"  autocomplete="off" formControlName="email" >
                       <div class="text-danger" *ngIf="billingAddress.controls['email'].invalid && (isFormSubmitted_billing || billingAddress.controls['email'].touched || billingAddress.controls['email'].dirty)">
                        <i class="fa-solid fa-circle-exclamation"></i>   
                        <label *ngIf="billingAddress.controls['email'].errors?.['required']" class="error help-block">Please enter a valid Email Address.</label>
                           <label *ngIf="billingAddress.controls['email'].errors?.['pattern']" class="error help-block">Please enter a valid Email Address.</label>
                       </div>
                   </div>
                   <div class="clearboth"></div>
                   <div class="padding-R15">
                       <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding margin-B20 registration_parent form-group">                        
                           <label class="registration_inputLabel" for="billing-phone">Phone Number*</label>
                           <p-inputMask class="registration_input form-control" autocomplete="off" autoClear="false"  mask="(999) 999-9999" pattern="^(\([2-9]([02-9]\d|1[02-9])\)|[2-9]([02-9]\d|1[02-9])) ?[2-9]\d{2}-?\d{4}$"  formControlName="phone"   />
                           <div class="text-danger" *ngIf="billingAddress.controls['phone'].invalid && (isFormSubmitted_billing || billingAddress.controls['phone'].touched || billingAddress.controls['phone'].dirty)">
                            <i class="fa-solid fa-circle-exclamation"></i>  
                            <label *ngIf="billingAddress.controls['phone'].errors?.['required']" class="error help-block">Please enter a valid Phone Number.</label>
                               <label *ngIf="billingAddress.controls['phone'].errors?.['pattern']" class="error help-block">Please enter a valid Phone Number.</label>
                           </div>
                       </div>
                       <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 no-padding-right margin-B20 registration_parent form-group">                        
                           <label class="registration_inputLabel" for="billing-extn">Ext.</label>
                           <input class="registration_input form-control" formControlName="phoneExt"  maxlength="4" autocomplete="off">
                       </div>
                       <div class="clearboth"></div>
                   </div>
                   <div class="registration_contact padding-B20">Billing Address</div>
                   <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding margin-B20 registration_parent form-group">                        
                       <label class="registration_inputLabel" for="billing-orgname">Organization Name*</label>
                       <input class="registration_input form-control" formControlName="orgName"  autocomplete="off" >
                       <div class="text-danger" *ngIf="billingAddress.controls['orgName'].invalid && (isFormSubmitted_billing || billingAddress.controls['orgName'].touched || billingAddress.controls['orgName'].dirty)">
                        <i class="fa-solid fa-circle-exclamation"></i>   
                        <label *ngIf="billingAddress.controls['orgName'].errors?.['required']" class="error help-block">Please enter your Organization Name.</label>
                        <label *ngIf="billingAddress.controls['orgName'].errors?.['pattern']" class="error help-block">Please enter only letters, numbers.</label>
                       </div>
                   </div>
                   <div class="clearboth"></div>
                   <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding-left margin-B30 registration_address form-group">                        
                       <label class="registration_inputLabel" for="billing-lookup">Address Lookup</label>
                       <input class="registration_input form-control"  id="billinglookup"   name="billinglookup" >
                       <span class="glyphicon glyphicon-search form-control-feedback"></span>
                   </div>
                   <div class="padding-R15">
                       <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding margin-B20 registration_parent form-group">                        
                           <label class="registration_inputLabel" for="billing-address1">Address Line 1*</label>
                           <input class="registration_input form-control" id="line1" name="addressLine1_billing" formControlName="line1"  autocomplete="off" >
                           <div class="text-danger"  *ngIf="billingAddress.controls['line1'].invalid && (isFormSubmitted_billing || billingAddress.controls['line1'].touched || billingAddress.controls['line1'].dirty)">
                            <i class="fa-solid fa-circle-exclamation"></i>   
                            <label *ngIf="billingAddress.controls['line1'].errors?.['required']" class="error help-block">Please enter an Address.</label>
                           </div>
                       </div>
                       <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding-right margin-B20 registration_parent form-group">                        
                           <label class="registration_inputLabel" for="billing-address2">Address Line 2</label>
                           <input class="registration_input form-control" id="line2" name="addressLine2_billing" formControlName="line2"  autocomplete="off" >
                       </div>
                       <div class="clearboth"></div>
                   </div>
                   <div class="padding-R15">
                       <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding margin-B20 registration_parent form-group">                        
                           <label class="registration_inputLabel" for="billing-city">City*</label>
                           <input class="registration_input form-control" id="city" name="city_billing"  autocomplete="off" formControlName="city">
                           <div class="text-danger"  *ngIf="billingAddress.controls['city'].invalid && (isFormSubmitted_billing || billingAddress.controls['city'].touched || billingAddress.controls['city'].dirty)">
                            <i class="fa-solid fa-circle-exclamation"></i>   
                            <label *ngIf="billingAddress.controls['city'].errors?.['required']" class="error help-block">Please enter your City.</label>
                           </div>
                       </div>
                       <div class="col-lg-2 col-md-2 col-sm-6 col-xs-6 no-padding-right margin-B20 registration_mob form-group">                        
                           <label class="registration_inputLabel " for="billing-state">State*</label>
                           <select class="registration_state form-control" id="state" formControlName="state" name="state_billing">
                               <option selected="selected" *ngFor="let item of stateList" [value]="item.value">{{ item.label }}</option>
                           </select>
                           <div class="text-danger"  *ngIf="billingAddress.controls['state'].invalid && (isFormSubmitted_billing || billingAddress.controls['state'].touched || billingAddress.controls['state'].dirty)">
                            <i class="fa-solid fa-circle-exclamation"></i>   
                            <label *ngIf="billingAddress.controls['state'].errors?.['required']" class="error help-block">Please select a state.</label>
                           </div>
                       </div>
                       <div class="col-lg-4 col-md-4 col-sm-6 col-xs-6 no-padding-right margin-B20 form-group">                        
                           <label class="registration_inputLabel" for="billing-zipcode">ZIP Code*</label>
                           <input class="registration_input form-control" id="postalCode" name="zipCode_billing" formControlName="postalCode" autocomplete="off" >                              
                           <div class="text-danger"  *ngIf="billingAddress.controls['postalCode'].invalid && (isFormSubmitted_billing || billingAddress.controls['postalCode'].touched || billingAddress.controls['postalCode'].dirty)">
                            <i class="fa-solid fa-circle-exclamation"></i>   
                            <label *ngIf="billingAddress.controls['postalCode'].errors?.['required']" class="error help-block">Please enter a valid US zip code.</label>
                               <label *ngIf="billingAddress.controls['postalCode'].errors?.['pattern']" class="error help-block">Please enter a valid US zip </label>
                           </div>
                       </div>
                       <div class="clearboth"></div>
                   </div>
                   
                   <div class="registration_contact padding-B20">Communication Preferences</div>
                   <div class="padding-R15">
                       <div class="margin-B20 registration_reviewCheckbox registration_parent registration_checkparent">                                              
                       <label class="checkbox-button">
                           <input type="checkbox" class="registration_checkbox" (change)="onCheckboxChange($event)">
                           <span class="checkbox-button__control"></span>
                       </label>
                       
                       <label class="registration_checkboxLabel">Use this contact and address for payer contact as well &nbsp;&nbsp;<span tooltip="Selecting this box will also apply this billing contact information as the payer contact. If you'd like to add different payer information you can do that on the next page." tooltip-position="buttom" ><i class="fa fa-question-circle-o" ></i></span></label>
                       <div class="clearboth"></div>
                   </div>
                       <div class="margin-B20 registration_parent registration_reviewCheckbox">                        
                       <label class="checkbox-button">
                           <input type="checkbox" class="registration_checkbox" formControlName="isSameAsShippingAddress" (change)="onCheckboxCopytoShipping($event)">
                           <span class="checkbox-button__control"></span>
                       </label>
                       <label class="registration_checkboxLabel">Ship to this location &nbsp;&nbsp;<span tooltip="Selecting this box will also apply this billing contact information as a shipping location. If you'd like to add different shipping information you can do that on a following page." tooltip-position="buttom" ><i class="fa fa-question-circle-o" ></i></span></label>
                       <div class="clearboth"></div>
                   </div>
                   </div>
                   <div class="padding-R15 padding-B20 registration_checkQues">How would you like to receive account statements?</div>
                   <div class="padding-R15">
                    <fieldset id="group1">
                    <div class="col-lg-2 col-md-2 col-sm-4 col-xs-6 no-padding-left margin-B10 registration_parent">
                      <label class="radio-button">
                        <input type="radio" class="registration_radio" [checked]="billingAddress.get('optNotifyAcctStmtEmail')?.value" formControlName="confAcctStmtEmail" value="email"  (click)="toggleDiv(true)" >
                        <span class="radio-button__control"></span>
                      </label>
                      <label class="registration_checkboxLabel">Email only</label>
                      <div class="clearboth"></div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-4 col-xs-6 no-padding-right margin-B10 registration_parent">
                      <label class="radio-button">
                        <input type="radio"  class="registration_radio" formControlName="confAcctStmtEmail" [checked]="!billingAddress.get('optNotifyAcctStmtEmail')?.value" value="mail" (click)="toggleDiv(false)" id="bill-accountType_M" >
                        <span class="radio-button__control"></span>
                      </label>
                      <label class="registration_checkboxLabel">By mail</label>
                      <div class="clearboth"></div>
                    </div>
                    <div class="clearboth"></div>
                </fieldset>
                  </div>
                  <div *ngIf="isDivVisible" class="col-lg-6 col-md-6 col-sm-6 col-xs-12 no-padding margin-B20 registration_parent form-group" id="bill-accemail">
                    <label class="registration_inputLabel" for="billing-accountemail">Email Address*</label>
                    <input class="registration_input form-control" formControlName="acctStmtEmail" autocomplete="off">
                    <div class="text-danger" *ngIf="billingAddress.controls['acctStmtEmail'].invalid && (isFormSubmitted_billing || billingAddress.controls['acctStmtEmail'].touched || billingAddress.controls['acctStmtEmail'].dirty)">
                      <i class="fa-solid fa-circle-exclamation"></i>
                      <label *ngIf="billingAddress.controls['acctStmtEmail'].errors?.['required']" class="error help-block">Please enter a valid Email Address.</label>
                      <label *ngIf="billingAddress.controls['acctStmtEmail'].errors?.['pattern']" class="error help-block">Please enter a valid Email Address.</label>
                    </div>
                  </div>
                  <div class="clearboth"></div>
                  <div class="padding-R15 padding-B20 registration_checkQues">How would you like to receive invoices?</div>
                  <div class="padding-R15">
                    <fieldset id="group2">
                    <div class="col-lg-2 col-md-2 col-sm-4 col-xs-6 no-padding-left margin-B10 registration_parent">
                      <label class="radio-button">
                        <input type="radio"  class="registration_radio" formControlName="optNotifyInvoiceEmail" [checked]="billingAddress.get('optNotifyInvoiceEmail')?.value" formControlName="confInvoiceEmail" value="email" (click)="toggleDiv1(true)" >
                        <span class="radio-button__control"></span>
                      </label>
                      <label class="registration_checkboxLabel">Email only</label>
                      <div class="clearboth"></div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-4 col-xs-6 no-padding-right margin-B10 registration_parent">
                      <label class="radio-button">
                        <input type="radio"  class="registration_radio" id="bill-invoiceType_M" (click)="toggleDiv1(false)" formControlName="optNotifyInvoiceEmail" [checked]="!billingAddress.get('optNotifyInvoiceEmail')?.value" formControlName="confInvoiceEmail" value="mail" >
                        <span class="radio-button__control"></span>
                      </label>
                      <label class="registration_checkboxLabel">By mail</label>
                      <div class="clearboth"></div>
                    </div>
                    
                    <div class="clearboth"></div>
                    </fieldset>
                  </div>
                  <div *ngIf="isDivVisible1" class="col-lg-6 col-md-6 col-sm-6 col-xs-12 no-padding margin-B20 registration_parent form-group">
                    <label class="registration_inputLabel" for="billing-invoiceemail">Email Address*</label>
                    <input class="registration_input form-control" formControlName="invoiceEmail" autocomplete="off">
                    <div class="text-danger" *ngIf="billingAddress.controls['invoiceEmail'].invalid && (isFormSubmitted_billing || billingAddress.controls['invoiceEmail'].touched || billingAddress.controls['invoiceEmail'].dirty)">
                      <i class="fa-solid fa-circle-exclamation"></i>
                      <label *ngIf="billingAddress.controls['invoiceEmail'].errors?.['required']" class="error help-block">Please enter a valid Email Address.</label>
                      <label *ngIf="billingAddress.controls['invoiceEmail'].errors?.['pattern']" class="error help-block">Please enter a valid Email Address.</label>
                    </div>
                  </div>
                   <div class="clearboth"></div>
                   <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding margin-T20 margin-B20 registration_button">
                       <div class="registration_back active" (click)="showBussinessSection()">
                           <div class="global_blackarrow"></div>
                           <span>Back</span>
                           <div class="clearboth"></div>
                       </div>
                       <button type="button" (click)="onSubmit_billing()"  class="registration_save active" id="registration_save">Save and Continue</button>
                       <div class="clearboth"></div>
                   </div>    
               
           </section>
           
           
           
                   
       </div>
   
   </form>  
   </div>
<!------Billing section Ends here------>

<!------Paying Information Section Starts here------> 
<div [style.display]="isVisiblePaying ? 'block' : 'none'" >
    <form class="registration col-xs-12 no-padding" novalidate [formGroup]="payerAddress">
  
          <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 registration_leftside no-padding-right">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 registration_nagivation no-padding">
              <div class="registration_nagivationPart" id="registration_nagivationPart1">
                      <div class="registration_nagivationNo">
                          <div class="registration_nagivationtick" style="display:block;"><img src="assets\images\greentick.png" alt=""></div>
                      </div>
                     
                      <div class="registration_nagivationName">User Profile</div>
                  </div>
  
                  <div class="registration_nagivationPart" id="registration_nagivationPart2">
                      <div class="registration_nagivationNo">
                          <div class="registration_nagivationNumeric" style="display: none;">2</div>
                          <div class="registration_nagivationtick" style="display: block;"><img src="assets\images\greentick.png" alt=""></div>
                      </div>
                      <div class="registration_nagivationName">Business Details</div>
                  </div>
               <div class="registration_nagivationPart" id="registration_nagivationPart3">
                      <div class="registration_nagivationNo">
                          <div class="registration_nagivationNumeric">3</div>
                          <div class="registration_nagivationtick"><img src="assets\images\greentick.png" alt=""></div>
                      </div>
                      <div class="registration_nagivationName active">Contacts &amp; Addresses<ul>
                             
                              <li class="" style="display: list-item;">Billing Information</li>
                              <li style="display: list-item;" class="active">Paying Information</li>
                              <li style="display: list-item;">Shipping Location(s)</li>
                              
                          </ul>
                      </div>
                  </div>
                  <div class="registration_nagivationPart" id="registration_nagivationPart4">
                      <div class="registration_nagivationNo">
                          <div class="registration_nagivationNumeric">4</div>
                          <div class="registration_nagivationtick"><img src="assets\images\greentick.png" alt=""></div>
                      </div>
                      <div class="registration_nagivationName inactive">Review &amp; Confirm</div>
                  </div>
               </div>
          </div>
          <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 registration_rightside no-padding-left">
              <div class=" registration_help">
              Having trouble? &nbsp;<a routerLink="/help-and-faq" class="showPopupclick"><span>We Can Help</span><span class="global_blackarrow"></span></a>
              </div>
              
              <section class="registration_paying registrationFlow" id="registration_section3" style="display: block;">
                 
                  
                   <div class="registration_label padding-B10">Paying Information</div>
                      <div class="registration_text1 padding-B10">Complete this section with the contact information of the person or group who will be paying invoices that come from CSL Seqirus.</div>
                      <div class="registration_text2">All fields marked with an asterisk (*) are required.</div>
                      <div class="registration_contact padding-B20">Paying Contact</div>
                    <div class="padding-R15">
                          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding margin-B20 registration_parent form-group">                        
                              <label class="registration_inputLabel " for="pay-firstname">First Name*</label>
                              <input class="registration_input form-control" formControlName="firstName" autocomplete="off" >
                              <div class="text-danger" *ngIf="payerAddress.controls['firstName'].invalid && (isFormSubmitted_paying || payerAddress.controls['firstName'].touched || payerAddress.controls['firstName'].dirty)">
                                <i class="fa-solid fa-circle-exclamation"></i>  
                                <label *ngIf="payerAddress.controls['firstName'].errors?.['required']" class="error help-block">Please enter a First Name.</label>
                                  <label *ngIf="payerAddress.controls['firstName'].errors?.['pattern']" class="error help-block">Please enter only letters.</label>
                              </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding-right margin-B20 registration_parent form-group">                        
                              <label class="registration_inputLabel " for="pay-lastname">Last Name*</label>
                              <input class="registration_input form-control" id="pay-lastname" formControlName="lastName" autocomplete="off">
                              <div class="text-danger" *ngIf="payerAddress.controls['lastName'].invalid && (isFormSubmitted_paying || payerAddress.controls['lastName'].touched || payerAddress.controls['lastName'].dirty)">
                                <i class="fa-solid fa-circle-exclamation"></i>  
                                <label *ngIf="payerAddress.controls['lastName'].errors?.['required']" class="error help-block">Please enter a Last Name.</label>
                                  <label *ngIf="payerAddress.controls['lastName'].errors?.['pattern']" class="error help-block">Please enter only letters.</label>
                              </div>
                          </div>
                          <div class="clearboth"></div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding margin-B20 registration_parent form-group">                        
                          <label class="registration_inputLabel " for="pay-email">Email Address*</label>
                          <input class="registration_input form-control" id="email" formControlName="email" autocomplete="off">
                          <div class="text-danger" *ngIf="payerAddress.controls['email'].invalid && (isFormSubmitted_paying || payerAddress.controls['email'].touched || payerAddress.controls['email'].dirty)">
                            <i class="fa-solid fa-circle-exclamation"></i>  
                            <label *ngIf="payerAddress.controls['email'].errors?.['required']" class="error help-block">Please enter a valid Email Address.</label>
                              <label *ngIf="payerAddress.controls['email'].errors?.['pattern']" class="error help-block">Please enter a valid Email Address.</label>
                          </div>
                      </div>
                      <div class="clearboth"></div>
                      <div class="padding-R15">
                          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding margin-B20 registration_parent form-group">                        
                              <label class="registration_inputLabel" for="pay-phone">Phone Number*</label>
                              <p-inputMask class="registration_input form-control" autocomplete="off" autoClear="false"  mask="(999) 999-9999" pattern="^(\([2-9]([02-9]\d|1[02-9])\)|[2-9]([02-9]\d|1[02-9])) ?[2-9]\d{2}-?\d{4}$"  formControlName="phone"   />
                              <div class="text-danger" *ngIf="payerAddress.controls['phone'].invalid && (isFormSubmitted_paying || payerAddress.controls['phone'].touched || payerAddress.controls['phone'].dirty)">
                                <i class="fa-solid fa-circle-exclamation"></i>  
                                <label *ngIf="payerAddress.controls['phone'].errors?.['required']" class="error help-block">Please enter a valid Phone Number.</label>
                                  <label *ngIf="payerAddress.controls['phone'].errors?.['pattern']" class="error help-block">Please enter a valid Phone Number.</label>
                              </div>
                          </div>
                          <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 no-padding-right margin-B20 registration_parent form-group">                        
                              <label class="registration_inputLabel" for="pay-ext">Ext.</label>
                              <input class="registration_input form-control" id="pay-ext" formControlName="phoneExt" maxlength="4" autocomplete="off" >
                          </div>
                          <div class="clearboth"></div>
                      </div>
                      <div class="registration_contact padding-B20">Payer Address</div>
                      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding margin-B20 registration_parent form-group">                        
                          <label class="registration_inputLabel " for="pay-orgname">Organization Name*</label>
                          <input class="registration_input form-control"  autocomplete="off" formControlName="orgName" >
                          <div class="text-danger" *ngIf="payerAddress.controls['orgName'].invalid && (isFormSubmitted_paying || payerAddress.controls['orgName'].touched || payerAddress.controls['orgName'].dirty)">
                            <i class="fa-solid fa-circle-exclamation"></i>  
                            <label *ngIf="payerAddress.controls['orgName'].errors?.['required']" class="error help-block">Please enter your Organization Name.</label>
                            <label *ngIf="payerAddress.controls['orgName'].errors?.['pattern']" class="error help-block">Please enter only letters, numbers.</label>
                          </div>
                      </div>
                      <div class="clearboth"></div>
                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding-left margin-B30 registration_address form-group">                        
                          <label class="registration_inputLabel " for="pay-lookup">Address Lookup</label>
                          <input class="registration_input form-control" id="pay-lookup" name="paylookup"  placeholder="Start with post/zip code or street" >
                          <span class="glyphicon glyphicon-search form-control-feedback"></span>
                      </div>
                      <div class="padding-R15">
                          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding margin-B20 registration_parent form-group">                        
                              <label class="registration_inputLabel" for="billing-address1">Address Line 1*</label>
                              <input class="registration_input form-control" id="line1" name="addressLine1_paying" formControlName="line1"  autocomplete="off" >
                              <div class="text-danger"  *ngIf="payerAddress.controls['line1'].invalid && (isFormSubmitted_paying || payerAddress.controls['line1'].touched || payerAddress.controls['line1'].dirty)">
                                <i class="fa-solid fa-circle-exclamation"></i>  
                                <label *ngIf="payerAddress.controls['line1'].errors?.['required']" class="error help-block">Please enter an Address.</label>
                              </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding-right margin-B20 registration_parent form-group">                        
                              <label class="registration_inputLabel" for="billing-address2">Address Line 2</label>
                              <input class="registration_input form-control" id="line2" name="addressLine2_paying" formControlName="line2"  autocomplete="off" >
                          </div>
                          <div class="clearboth"></div>
                      </div>
                      <div class="padding-R15">
                          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding margin-B20 registration_parent form-group">                        
                              <label class="registration_inputLabel" for="billing-city">City*</label>
                              <input class="registration_input form-control" id="city" name="city_paying"  autocomplete="off" formControlName="city">
                              <div class="text-danger"  *ngIf="payerAddress.controls['city'].invalid && (isFormSubmitted_paying || payerAddress.controls['city'].touched || payerAddress.controls['city'].dirty)">
                                <i class="fa-solid fa-circle-exclamation"></i>  
                                <label *ngIf="payerAddress.controls['city'].errors?.['required']" class="error help-block">Please enter your City.</label>
                              </div>
                          </div>
                          <div class="col-lg-2 col-md-2 col-sm-6 col-xs-6 no-padding-right margin-B20 registration_mob form-group">                        
                              <label class="registration_inputLabel " for="billing-state">State*</label>
                              <select class="registration_state form-control" id="state" formControlName="state" name="state_paying">
                                  <option selected="selected" *ngFor="let item of stateList" [value]="item.value">{{ item.label }}</option>
                              </select>
                              <div class="text-danger"  *ngIf="payerAddress.controls['state'].invalid && (isFormSubmitted_paying || payerAddress.controls['state'].touched || payerAddress.controls['state'].dirty)">
                                <i class="fa-solid fa-circle-exclamation"></i>  
                                <label *ngIf="payerAddress.controls['state'].errors?.['required']" class="error help-block">Please select a state.</label>
                              </div>
                          </div>
                          <div class="col-lg-4 col-md-4 col-sm-6 col-xs-6 no-padding-right margin-B20 form-group">                        
                              <label class="registration_inputLabel" for="billing-zipcode">ZIP Code*</label>
                              <input class="registration_input form-control" id="postalCode" name="zipCode_paying" formControlName="postalCode" autocomplete="off" >                              
                              <div class="text-danger"  *ngIf="payerAddress.controls['postalCode'].invalid && (isFormSubmitted_paying || payerAddress.controls['postalCode'].touched || payerAddress.controls['postalCode'].dirty)">
                                <i class="fa-solid fa-circle-exclamation"></i>  
                                <label *ngIf="payerAddress.controls['postalCode'].errors?.['required']" class="error help-block">Please enter a valid US zip code.</label>
                                  <label *ngIf="payerAddress.controls['postalCode'].errors?.['pattern']" class="error help-block">Please enter a valid US zip </label>
                              </div>
                          </div>
                          <div class="clearboth"></div>
                      </div>
                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding margin-T20 margin-B20 registration_button">
                          <div class="registration_back active" (click)="showBillingSection()">
                              <div class="global_blackarrow"></div>
                              <span>Back</span>
                              <div class="clearboth"></div>
                          </div>
                          <button type="button" (click)="onSubmit_paying()" class="registration_save active" id="registration_save">Save and Continue</button>
                          <div class="clearboth"></div>
                      </div>
                  
              </section>
              
  
      
     </div>
    </form>
  </div>
    <!------Paying Information Section Ends here------> 

    <!------Shipping Information Section Starts here------>
   
    <div  [style.display]="isVisibleShipping ? 'block' : 'none'" >    
        <form  class="registration col-xs-12 no-padding" [formGroup]="shippingForm">
           
            <div formArrayName="shippingList">
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 registration_leftside no-padding-right">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 registration_nagivation no-padding">
                    <div class="registration_nagivationPart" id="registration_nagivationPart1">
                            <div class="registration_nagivationNo">
                                <div class="registration_nagivationtick" style="display:block;"><img src="assets\images\greentick.png" alt=""></div>
                            </div>
                           
                            <div class="registration_nagivationName">User Profile</div>
                        </div>
    
                        <div class="registration_nagivationPart" id="registration_nagivationPart2">
                            <div class="registration_nagivationNo">
                                <div class="registration_nagivationNumeric" style="display: none;">2</div>
                                <div class="registration_nagivationtick" style="display: block;"><img src="assets\images\greentick.png" alt=""></div>
                            </div>
                            <div class="registration_nagivationName">Business Details</div>
                        </div>
                     <div class="registration_nagivationPart" id="registration_nagivationPart3">
                            <div class="registration_nagivationNo">
                                <div class="registration_nagivationNumeric">3</div>
                                <div class="registration_nagivationtick"><img src="assets\images\greentick.png" alt=""></div>
                            </div>
                            <div class="registration_nagivationName active">Contacts &amp; Addresses<ul>
                                   
                                    <li class="" style="display: list-item;">Billing Information</li>
                                    <li style="display: list-item;" class="">Paying Information</li>
                                    <li style="display: list-item;" class="active">Shipping Location(s)</li>
                                    
                                </ul>
                            </div>
                        </div>
                        <div class="registration_nagivationPart" id="registration_nagivationPart4">
                            <div class="registration_nagivationNo">
                                <div class="registration_nagivationNumeric">4</div>
                                <div class="registration_nagivationtick"><img src="assets\images\greentick.png" alt=""></div>
                            </div>
                            <div class="registration_nagivationName inactive">Review &amp; Confirm</div>
                        </div>
                     </div>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 registration_rightside no-padding-left">
                    <div class=" registration_help">
                    Having trouble? &nbsp;<a routerLink="/help-and-faq" class="showPopupclick"><span>We Can Help</span><span class="global_blackarrow"></span></a>
                    </div>
                    <section class="registration_shipping registrationFlow" [ngClass]="{'show-shipping': showShippingFormOnly}"  id="registration_section4" >
                        
                             <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding-left registration_label padding-B10">
                            
     
                            <div class="col-lg-5 col-md-5 no-padding">Shipping Locations</div>
                                 <div class="col-lg-7 col-md-7 uploadcont no-padding">
                                     <div class="registration_download" *ngIf="templateUrl"><a [href]="templateUrl"  class="showPopupclick">Download Template &nbsp;<span class="glyphicon glyphicon-download-alt"></span></a></div>
                                     <div class="registration_upload" id="registration_upload" (click)="open_shipping_popup()">Upload Shipping Locations</div>
                                 </div>
                             </div>
                             <div class="registration_text1 padding-B10">Complete this section with shipping location(s) used for receiving vaccine shipments, or save time by uploading shipping location documents via the link above. You can always add more locations.</div>
                             <div class="registration_text1 padding-B10">Please note, if information is incomplete or incorrect, we will contact you within 5 business days. This may affect verification, which could delay any orders or shipments to that location.</div>
                             <div class="registration_text2">All fields marked with an asterisk (*) are required.</div>
                            <!---Add Shipping Locations starts----->
                             <div class="print_shipping" >
                                

                                   
                                     
                                     
                             </div>
                             <!---Add Shipping Locations ends----->
                             <div *ngFor="let shipping of shippingList.controls; let i = index" [formGroupName]="i">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 registration_locations" *ngIf="!shipping.get('isEditing')?.value && shipping.get('submitted')?.value" [style.display]="shipping.get('submitted')?.value ? 'block' : 'none'" >
                                    <div class="registration_locationparent">
                                      <div class="registration_locationLine1">
                                        <div class="registration_locationName">Location <span class="locationno">{{ i + 1 }}</span>
                                        </div>
                                        <div class="registration_locationOrg">{{ shipping.get('orgName')?.value }}
                                        </div>
                                        <div class="clearboth"></div>
                                      </div>
                                      <div class="registration_edit ship_del" (click)="removeShipping(i)">Remove <i class="fa fa-trash" aria-hidden="true"></i>
                                      </div>
                                      <div class="registration_edit only_edit_ship" (click)="toggleShipping(i)">Edit <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                      </div>
                                      <div class="clearboth"></div>
                                    </div>
                                    <div class="registration_locationLine2">
                                      <div class="registration_locationcontact">
                                        <div class="registration_locationcontactline1">Contact Info</div>
                                        <div class="registration_locationcontactname">
                                          <span>{{ shipping.get('firstName')?.value }}</span>&nbsp; <span>{{ shipping.get('lastName')?.value }}</span>
                                        </div>
                                        <div class="registration_locationcontactemail">
                                          <span>{{ shipping.get('email')?.value }}</span>
                                        </div>
                                        <div class="registration_locationcontactphn">
                                          <span>{{ shipping.get('phone')?.value }}</span> ext. <span>{{ shipping.get('phoneExt')?.value }}</span>
                                        </div>
                                      </div>
                                      <div class="registration_locationaddress">
                                        <div class="registration_locationcontactline1">Address</div>
                                        <div class="registration_locationaddress1">
                                          <span>{{ shipping.get('line1')?.value }}</span>
                                        </div>
                                        <div class="registration_locationaddress2">
                                          <span>
                                            {{ shipping.get('line2')?.value }}
                                          </span>
                                        </div>
                                        <div class="registration_locationaddress4">
                                          <span>{{ shipping.get('city')?.value }}
                                          </span>,<span>{{ shipping.get('state')?.value }}
                                          </span>
                                          <span>{{ shipping.get('postalCode')?.value }}
                                          </span>
                                        </div>
                                      </div>
                                      <div class="registration_locationLic">
                                        <div class="registration_locationcontactline1">License</div>
                                        <div class="registration_locationlic1">
                                          <span>{{ shipping.get('licenseDetailsForm.licenseName')?.value }}
                                          </span> - <span>{{ shipping.get('licenseDetailsForm.licenseNum')?.value }} 
                                          </span>
                                          <span>
                                            
                                          </span>
                                        </div>
                                        <div class="registration_locationlic3">
                                          <span>{{ shipping.get('licenseDetailsForm.licAddressLine1')?.value }}
                                          </span>
                                        </div>
                                        <div class="registration_locationlic4">
                                          <span>
                                            {{ shipping.get('licenseDetailsForm.licAddressLine2')?.value }}
                                          </span>
                                        </div>
                                        <div class="registration_locationlic5">
                                          <span>{{ shipping.get('licenseDetailsForm.licenseCity')?.value }}
                                          </span>,<span>{{ shipping.get('licenseDetailsForm.licenseState')?.value }}
                                          </span>
                                          <span>{{ shipping.get('licenseDetailsForm.licensePostalCode')?.value }}
                                          </span>
                                          
                                        </div>
                                      </div>
                                      
                                      <div class="clearboth"></div>
                                    </div>
                                    
                                  </div>
                                  <div class="clearboth"></div>
                                <div class="shipping-block" [ngClass]="{'registration_locations': shipping.get('isEditing')?.value}" *ngIf="shipping.get('isEditing')?.value || !shipping.get('submitted')?.value">
                                    <div class="row" *ngIf="showEditbtn"><div class="col-md-12"><div class="registration_locationName no-right-border">Location <span class="locationno">{{i + 1}}</span></div></div></div>
<div class="registration_contact padding-B20">Shipping Location Contact &nbsp;&nbsp;<span tooltip="Help This contact information will be used for shipping product. You can add more than one contact and/or location by uploading shipping locations in our template, or inputting the information manually." tooltip-position="buttom" ><i class="fa fa-question-circle-o" ></i></span><div class="registration_edit add_ship_del" style="display:block" *ngIf="i > 0" (click)="removeShipping(i)">Remove <i class="fa fa-trash" aria-hidden="true"></i></div></div>
                                
                                <div class="padding-R15">
                                 <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding margin-B20 registration_parent form-group">                        
                                     <label class="registration_inputLabel" for="shipping-firstname">First Name*</label>
                                     <input class="registration_input form-control" formControlName="firstName" autocomplete="off">
                                     <div class="text-danger" *ngIf="shipping.get('firstName')?.invalid && (isFormSubmitted_shipping || shipping.get('firstName')?.touched || shipping.get('firstName')?.dirty)">
                                        <i class="fa-solid fa-circle-exclamation"></i>
                                        <div *ngIf="shipping.get('firstName')?.errors?.['required']" class="error help-block">Please enter a First Name.</div>
                                        <div *ngIf="shipping.get('firstName')?.errors?.['pattern']" class="error help-block">Please enter only letters.</div>
                                      </div>
                                      
                                    </div>
                                 <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding-right margin-B20 registration_parent form-group">                        
                                     <label class="registration_inputLabel" for="shipping-lastname">Last Name*</label>
                                     <input class="registration_input form-control" formControlName="lastName" autocomplete="off">
                                     <div class="text-danger" *ngIf="shipping.get('lastName')?.invalid && (isFormSubmitted_shipping || shipping.get('lastName')?.touched || shipping.get('lastName')?.dirty)">
                                        <i class="fa-solid fa-circle-exclamation"></i>
                                        <div *ngIf="shipping.get('lastName')?.errors?.['required']" class="error help-block">Please enter a Last Name.</div>
                                        <div *ngIf="shipping.get('lastName')?.errors?.['pattern']" class="error help-block">Please enter only letters.</div>
                                      </div>
                                 </div>
                                 <div class="clearboth"></div>
                             </div>
                             <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding margin-B20 registration_parent form-group">                        
                                 <label class="registration_inputLabel" for="shipping-email">Email Address*</label>
                                 <input class="registration_input form-control" formControlName="email" autocomplete="off">
                                     <div class="text-danger" *ngIf="shipping.get('email')?.invalid && (isFormSubmitted_shipping || shipping.get('email')?.touched || shipping.get('email')?.dirty)">
                                        <i class="fa-solid fa-circle-exclamation"></i>
                                        <div *ngIf="shipping.get('email')?.errors?.['required']" class="error help-block">Please enter a valid Email Address.</div>
                                        <div *ngIf="shipping.get('email')?.errors?.['pattern']" class="error help-block">Please enter a valid Email Address.</div>
                                      </div>
                             </div>
                             <div class="clearboth"></div>
                             <div class="padding-R15">
                                 <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding margin-B20 registration_parent form-group">                        
                                     <label class="registration_inputLabel" for="shipping-phno">Phone Number*</label>
                                     <p-inputMask class="registration_input form-control" autocomplete="off" autoClear="false"  mask="(999) 999-9999" pattern="^(\([2-9]([02-9]\d|1[02-9])\)|[2-9]([02-9]\d|1[02-9])) ?[2-9]\d{2}-?\d{4}$"  formControlName="phone"   />
                                     <div class="text-danger" *ngIf="shipping.get('phone')?.invalid && (isFormSubmitted_shipping || shipping.get('phone')?.touched || shipping.get('phone')?.dirty)">
                                        <i class="fa-solid fa-circle-exclamation"></i>
                                        <div *ngIf="shipping.get('phone')?.errors?.['required']" class="error help-block">Please enter a valid Phone Number.</div>
                                        <div *ngIf="shipping.get('phone')?.errors?.['pattern']" class="error help-block">Please enter a valid Phone Number.</div>
                                      </div>
                                    </div>
                                 <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 no-padding-right margin-B20 registration_parent form-group">                        
                                     <label class="registration_inputLabel" for="shipping-extension">Ext.</label>
                                     <input class="registration_input form-control" formControlName="phoneExt" maxlength="4" autocomplete="no">
                                 </div>
                                 <div class="clearboth"></div>
                             </div>
                             <div class="registration_contact padding-B20">Shipping Address</div>
                             <div class="padding-R15">
                             <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding margin-B20 registration_parent form-group">                        
                                 <label class="registration_inputLabel" for="shipping-orgname">Organization Name*</label>
                                 <input class="registration_input form-control" formControlName="orgName" autocomplete="off">
                                 <div class="text-danger" *ngIf="shipping.get('orgName')?.invalid && (isFormSubmitted_shipping || shipping.get('orgName')?.touched || shipping.get('orgName')?.dirty)">
                                    <i class="fa-solid fa-circle-exclamation"></i>
                                    <div *ngIf="shipping.get('orgName')?.errors?.['required']" class="error help-block">Please enter organization name for shipping.</div>
                                    <div *ngIf="shipping.get('orgName')?.errors?.['pattern']" class="error help-block">Please enter only letters, numbers.</div>
                                  </div>
                             </div>
                             <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 padding-left margin-B20 registration_parent form-group">                        
                                 <label class="registration_inputLabel" for="shipping-gln">GS1 Global Location Number (GLN) <i class="fa-regular fa-circle-question locations_qm locations_tooltip"><span class="locations_tooltiptext"><p>Please enter your 13-digit GLN in this field.</p>For more information on GLNs, please visit the <a routerLink="/help-and-faq" target="_blank">flu360 Support</a> page.</span></i></label>
                                 
                                 <p-inputMask class="registration_input form-control" autoClear="false"  mask="9999999.99999.9" pattern="\d\d\d\d\d\d\d\.\d\d\d\d\d\.\d" id="addressNumber" formControlName="globalLocationNumber"   />
                                 <div class="text-danger" *ngIf="shipping.get('globalLocationNumber')?.invalid && (isFormSubmitted_shipping || shipping.get('globalLocationNumber')?.touched || shipping.get('globalLocationNumber')?.dirty)">
                                    <i class="fa-solid fa-circle-exclamation" style="right:28px;"></i>
                                    <div *ngIf="shipping.get('globalLocationNumber')?.errors?.['pattern']" class="error help-block">Please enter a valid 13 digit Global Location Number (GLN)</div>
                                  </div>    
                                 
                                </div>
                             </div>
                             <div class="clearboth"></div>
                             <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding-left margin-B30 registration_address form-group" >                        
                                 <label class="registration_inputLabel" for="shipping-lookup1">Address Lookup</label>
                                 <input class="registration_input form-control addresslook" formControlName="addressId"  [id]="'shipping_lookup' + i"  [name]="'shipping_lookup' + i"  placeholder="Start with post/zip code or street">
                                 
                                 <span class="glyphicon glyphicon-search form-control-feedback"></span>
                             </div>
                             <div class="padding-R15">
                                 <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding margin-B20 registration_parent form-group">                        
                                     <label class="registration_inputLabel" for="shipping-address1">Address Line 1*</label>
                                     <input class="registration_input form-control" formControlName="line1" id="line1{{i}}" name="line1{{i}}" autocomplete="off">
                                     <div class="text-danger" *ngIf="shipping.get('line1')?.invalid && (isFormSubmitted_shipping || shipping.get('line1')?.touched || shipping.get('line1')?.dirty)">
                                        <i class="fa-solid fa-circle-exclamation"></i>
                                        <div *ngIf="shipping.get('line1')?.errors?.['required']" class="error help-block">Please enter an Address.</div>
                                      </div>
                                 </div>
                                 <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding-right margin-B20 registration_parent form-group">                        
                                     <label class="registration_inputLabel" for="shipping-address2">Address Line 2</label>
                                     <input class="registration_input form-control" formControlName="line2" name="line2{{i}}" id="line2{{i}}" autocomplete="off">
                                 </div>
                                 <div class="clearboth"></div>
                             </div>
                             <div class="padding-R15">
                                 <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding margin-B20 registration_parent form-group">                        
                                     <label class="registration_inputLabel" for="shipping-city1">City*</label>
                                     <input class="registration_input form-control" formControlName="city" name="city{{i}}" id="city{{i}}" autocomplete="off">
                                     <div class="text-danger" *ngIf="shipping.get('city')?.invalid && (isFormSubmitted_shipping || shipping.get('city')?.touched || shipping.get('city')?.dirty)">
                                        <i class="fa-solid fa-circle-exclamation"></i>
                                        <div *ngIf="shipping.get('city')?.errors?.['required']" class="error help-block">Please enter your City</div>
                                      </div>
                                 </div>
                                 <div class="col-lg-2 col-md-2 col-sm-6 col-xs-6 no-padding-right margin-B20 registration_mob form-group">                        
                                     <label class="registration_inputLabel" for="shipping-state1">State*</label>
                                     <select class="registration_state form-control"  formControlName="state" name="state{{i}}" id="state{{i}}">
                                        <option selected="selected" *ngFor="let item of stateList" [value]="item.value">{{ item.label }}</option>
                                    </select>
                                    <div class="text-danger" *ngIf="shipping.get('state')?.invalid && (isFormSubmitted_shipping || shipping.get('state')?.touched || shipping.get('state')?.dirty)">
                                        <i class="fa-solid fa-circle-exclamation"></i>
                                        <div *ngIf="shipping.get('state')?.errors?.['required']" class="error help-block">Please select a state.</div>
                                      </div>
                                 </div>
                                 <div class="col-lg-4 col-md-4 col-sm-6 col-xs-6 no-padding-right margin-B20 form-group">                        
                                     <label class="registration_inputLabel" for="shipping-zip1">ZIP Code*</label>
                                     <input class="registration_input form-control" formControlName="postalCode" name="postalCode{{i}}" id="postalCode{{i}}" autocomplete="off">
                                     <div class="text-danger" *ngIf="shipping.get('postalCode')?.invalid && (isFormSubmitted_shipping || shipping.get('postalCode')?.touched || shipping.get('postalCode')?.dirty)">
                                        <i class="fa-solid fa-circle-exclamation"></i>
                                        <div *ngIf="shipping.get('postalCode')?.errors?.['required']" class="error help-block">Please enter a valid US zip code.</div>
                                        <div *ngIf="shipping.get('postalCode')?.errors?.['pattern']" class="error help-block">Please enter a valid US zip code.</div>
                                    </div>                          
                                 </div>
                                 <div class="clearboth"></div>
                             </div>
                    <div formGroupName="licenseDetailsForm">
                             <div class="registration_contact padding-B20">License</div>
                             <div class="padding-R15">
                                 <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding margin-B20 registration_parent form-group">                        
                                     <label class="registration_inputLabel" for="shipping-licensename">Name on License*</label>
                                     <input class="registration_input form-control" formControlName="licenseName" name="shipping_licenseName" autocomplete="off">
                                     <div class="text-danger" *ngIf="shipping.get('licenseDetailsForm.licenseName')?.invalid && (isFormSubmitted_shipping || shipping.get('licenseDetailsForm.licenseName')?.touched || shipping.get('licenseDetailsForm.licenseName')?.dirty)">
                                        <i class="fa-solid fa-circle-exclamation"></i>
                                        <div *ngIf="shipping.get('licenseDetailsForm.licenseName')?.errors?.['required']" class="error help-block">Please enter a License name</div>
                                    </div>
                                 </div>
                                 <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding-right margin-B20 registration_parent form-group">                        
                                     <label class="registration_inputLabel" for="shipping-licenseno">State License Number*</label>
                                     <input class="registration_input form-control" maxlength="35" formControlName="licenseNum" name="shipping_licenseNum" autocomplete="off" >
                                     <div class="text-danger" *ngIf="shipping.get('licenseDetailsForm.licenseNum')?.invalid && (isFormSubmitted_shipping || shipping.get('licenseDetailsForm.licenseNum')?.touched || shipping.get('licenseDetailsForm.licenseNum')?.dirty)">
                                        <i class="fa-solid fa-circle-exclamation"></i>
                                        <div *ngIf="shipping.get('licenseDetailsForm.licenseNum')?.errors?.['required']" class="error help-block">Please enter a License name</div>
                                     </div>
                                 </div>
                                 <div class="clearboth"></div>
                             </div>
                             <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 no-padding-left margin-B20 shipping_check registration_parent form-group">                                              
                                 <label class="checkbox-button">                           
                                     <input type="checkbox" class="registration_checkbox form-group checkboxship_0" formControlName="checkboxControl"  (change)="copyValues(i)">
                                     <span class="checkbox-button__control"></span>
                                 </label>
                                 <label class="registration_checkboxLabel" for="shipping-checkbox">Same address for license as shipping address</label>
                                 
                                 <div class="clearboth"></div>
                                
                             </div>
                             <div class="clearboth"></div>
                             <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding-left margin-B30 registration_address form-group">                        
                                 <label class="registration_inputLabel" for="shipping-lookup2">Address Lookup</label>
                                 <input class="registration_input form-group" id="shipping-lookup2" name="shipping-lookup2"  placeholder="Start with post/zip code or street">
                                 <span class="glyphicon glyphicon-search form-control-feedback"></span>
                             </div>
                             <div class="padding-R15">
                                 <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding margin-B20 registration_parent form-group">                        
                                     <label class="registration_inputLabel" for="shipping-address3">Address Line 1*</label>
                                     <input class="registration_input form-control" formControlName="licAddressLine1" name="licAddressLine1" autocomplete="off">
                                     <div class="text-danger" *ngIf="shipping.get('licenseDetailsForm.licAddressLine1')?.invalid && (isFormSubmitted_shipping || shipping.get('licenseDetailsForm.licAddressLine1')?.touched || shipping.get('licenseDetailsForm.licAddressLine1')?.dirty)">
                                        <i class="fa-solid fa-circle-exclamation"></i>
                                        <div *ngIf="shipping.get('licenseDetailsForm.licAddressLine1')?.errors?.['required']" class="error help-block">Please enter an Address.</div>
                                     </div>
                                 </div>
                                 <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding-right margin-B20 registration_parent form-group">                        
                                     <label class="registration_inputLabel" for="shipping-address4">Address Line 2</label>
                                     <input class="registration_input form-control" formControlName="licAddressLine2" name="licAddressLine2" autocomplete="off">
                                 </div>
                                 <div class="clearboth"></div>
                             </div>
                             <div class="padding-R15">
                                 <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding margin-B20 registration_parent form-group">                        
                                     <label class="registration_inputLabel" for="shipping-city2">City*</label>
                                     <input class="registration_input form-control" formControlName="licenseCity" name="licenseCity" autocomplete="off">
                                     <div class="text-danger" *ngIf="shipping.get('licenseDetailsForm.licenseCity')?.invalid && (isFormSubmitted_shipping || shipping.get('licenseDetailsForm.licenseCity')?.touched || shipping.get('licenseDetailsForm.licenseCity')?.dirty)">
                                        <i class="fa-solid fa-circle-exclamation"></i>
                                        <div *ngIf="shipping.get('licenseDetailsForm.licenseCity')?.errors?.['required']" class="error help-block">Please enter your City</div>
                                     </div>
                                 </div>
                                 <div class="col-lg-2 col-md-2 col-sm-6 col-xs-6 no-padding-right margin-B20 registration_mob form-group">                        
                                     <label class="registration_inputLabel" for="shipping-state2">State*</label>
                                     <select class="registration_state form-control"  formControlName="licenseState" name="licenseState">
                                        <option selected="selected" *ngFor="let item of stateList" [value]="item.value">{{ item.label }}</option>
                                     </select>
                                    <div class="text-danger" *ngIf="shipping.get('licenseDetailsForm.licenseState')?.invalid && (isFormSubmitted_shipping || shipping.get('licenseDetailsForm.licenseState')?.touched || shipping.get('licenseDetailsForm.licenseState')?.dirty)">
                                        <i class="fa-solid fa-circle-exclamation"></i>
                                        <div *ngIf="shipping.get('licenseDetailsForm.licenseState')?.errors?.['required']" class="error help-block">Please select a state.</div>
                                      </div>
                                 </div>
                                 <div class="col-lg-4 col-md-4 col-sm-6 col-xs-6 no-padding-right margin-B20 form-group">                        
                                     <label class="registration_inputLabel" for="shipping-zip2">ZIP Code*</label>
                                     <input class="registration_input form-control" formControlName="licensePostalCode" name="licensePostalCode" autocomplete="off">
                                     <div class="text-danger" *ngIf="shipping.get('licenseDetailsForm.licensePostalCode')?.invalid && (isFormSubmitted_shipping || shipping.get('licenseDetailsForm.licensePostalCode')?.touched || shipping.get('licenseDetailsForm.licensePostalCode')?.dirty)">
                                        <i class="fa-solid fa-circle-exclamation"></i>
                                        <div *ngIf="shipping.get('licenseDetailsForm.licensePostalCode')?.errors?.['required']" class="error help-block">Please enter a valid US zip code.</div>
                                        <div *ngIf="shipping.get('licenseDetailsForm.licensePostalCode')?.errors?.['pattern']" class="error help-block">Please enter a valid US zip code.</div>
                                    </div>                             
                                 </div>
                    </div>	
                    <button type="button" *ngIf="shipping.get('isEditing')?.value" class="shipping_edit_save" (click)="checkLicense2(i)">Save</button>
                    </div>
                    <div class="clearboth"></div>
                               
                                 <div *ngIf="!shipping.get('isEditing')?.value" class="registration_addbutton padding-B20 registration_addbutton_disable" [style.display]="!isVisible ? 'none' : 'block'"   (click)="onSubmit_shipping(i)" [ngClass]="{'registration_addbutton_disable': !formValid}" >Add Another Location&nbsp;<span class="glyphicon glyphicon-remove-circle"></span></div>
                                 
                                
                            </div>
            
                                 
                                 
                                </div>
                                  
                                <div  class="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding margin-T20 margin-B20 registration_button">
                                   
                                    <div *ngFor="let shipping of shippingList.controls; let i = index" [formGroupName]="i">  
                                 
                                <div *ngIf="i === shippingList.controls.length - 1" >
                                        <div class="padding-B20 only_show_field"   *ngIf="toggleButtonVisibility(i) && !isAnyFormEditing"  (click)="addShipping()">Add Another Location&nbsp;<span class="glyphicon glyphicon-remove-circle"></span></div>
                                        <div class="registration_back active" (click)="showPayingSection()" >
                                            <div class="global_blackarrow"></div>
                                            <span>Back</span>
                                            <div class="clearboth"></div>
                                        </div>
                                    
                                    <button type="button" (click)="save_next_shipping(i)"  *ngIf="!isVisible"  class="registration_save active" id="registration_save" >Save and Continue

                                    </button>

                                    <button type="button" (click)="onSubmit_shipping_license(i)"  *ngIf="isVisible" class="registration_save active" id="registration_save" >Save and Continue</button>
                                </div>
                            </div>
                                    <div class="clearboth"></div>
                                 
                                </div> 
                     </section>

                     <!-----After Shipping Template Upload section starts----->
                     <section class="registration_uploadConfirm registrationFlow" *ngIf="showAfterShippingUpload" id="registration_uploadConfirm" style="display: block;">

                        <div class="registration_label padding-B10">Shipping Locations</div>
                        <div class="registration_shippingUploadtext2 margin-B10">Complete this section with shipping location(s) used for receiving vaccine shipments, or save time by uploading shipping location documents via the link below. You can always add more locations whenever you need.<p>Please note, if information is incomplete or incorrect, we will contact you within 5 business days. This may affect verification, which could delay any orders or shipments to that location.</p>
                        </div>
                        <div class=" margin-B10">
                           <div class="registration_shippingUploadtext2 uploadformat"><span style="color:#2A3237;">Accepted File Formats:</span> .xls, .xlsx</div>
                            <div class="registration_popcont">
                                <div class="registration_download" *ngIf="templateUrl"><a [href]="templateUrl" class="showPopupclick">Download Template &nbsp;<span class="glyphicon glyphicon-download-alt"></span></a></div>
                                <input type="file"  id="upload" #fileInput (change)="onFileSelected($event)" accept=".xls,.xlsx" hidden/>
                                <label for="upload" [ngClass]="{'inactive': file, 'active': !file}">Upload Location Documents</label>
                            </div>
                            <div class="clearboth"></div>
                        </div>
                        <div>
                            <p *ngIf="errorMessage" style="display:block" class="registration_uploadFile">{{ errorMessage }}</p>
                            <div class="registration_uploadFile" *ngIf="fileName" style="display:block">{{ fileName }} <button (click)="removeUploadFile()" class="registration_uploadclose" style="display: block;">X</button></div>
                        </div>
                        <div class="registration_shippingUploadtext2 margin-B10">Please ensure that these are the correct documents before continuing, complete with <span>main contact</span> (name, email, and phone number) for each location, as well as the <span>full address</span> and <span>license information</span> If your upload is missing important information, we will contact you within 5 business days. This may affect location verification, which could delay any orders or shipments. You can also upload more than 1 document, or choose to <span class="registration_manualLocation" (click)="returnToShipping()">manually upload locations</span> instead.</div>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding margin-T20 margin-B20 registration_button">
                            <div class="registration_back active" (click)="returnToShipping()">
                                <div class="global_blackarrow"></div>
                                <span>Back</span>
                                <div class="clearboth"></div>
                            </div>
                            <button class="registration_save active" type="submit" id="registration_save" (click)="uploadFile()">Save and Continue</button>
                           
                            <div class="clearboth"></div>
                        </div>
                     </section>
                     <!-------After Shipping Template Upload section ends--------->
                     
                </div>

              
            </div>  
              
            
        </form>
           
    </div>          

  
            <!-----------Shipping Upload Popup Section Starts------->          
            <div class="registration_shippingUploadpop modal" style="display:block" *ngIf="showShippingUpload">
                <div class="registration_shippingUploadContent">
                <div class="registration_shippingUploadheader">
                        <div class="registration_shippingUploadtext1 margin-B10">Prefer to upload location information?</div>
                        <div class="registration_shippingUploadclose margin-B10" (click)="close_shipping_popup()">X</div>
                        <div class="clearboth"></div>
                    </div>
                    <div class="registration_shippingUploadtext2 margin-B10">If you have multiple shipping locations, you can save time by uploading that information.Please make sure you download the template and enter the necessary information, including the <span>main contact</span> (name, email, and phone number) for each location, as well as the <span>full address</span> and <span>license information</span>.<div>If your upload is missing important information, we will contact you within 5 business days. This may affect location verification, which could delay any orders or shipments.</div>
                    </div>
                    <div class=" margin-B10">
                        <div class="registration_shippingUploadtext2 uploadformat"><span style="color:#2A3237;">Accepted File Formats:</span> .xls, .xlsx</div>
                        <div class="registration_popcont">
                            <div class="registration_download" *ngIf="templateUrl"><a [href]="templateUrl" class="showPopupclick">Download Template &nbsp;<span class="glyphicon glyphicon-download-alt"></span></a></div>
                            <input type="file"  id="upload" #fileInput (change)="onFileSelected($event)" accept=".xls,.xlsx" hidden/>
                            <label for="upload" [ngClass]="{'inactive': file, 'active': !file}">Upload Location Documents</label>                            
                            <div class="clearboth"></div>
                            
                        </div>
                        <div class="clearboth"></div>
                    </div>
                    <div>

                    </div>
                    <div>
                        <p class="registration_uploadFile" *ngIf="errorMessage" style="display:block">{{ errorMessage }}</p>
                        <div class="registration_uploadFile" *ngIf="fileName" style="display:block">{{ fileName }} <button (click)="removeUploadFile()" class="registration_uploadclose" style="display: block;">X</button></div>
                    </div>
                    <button class="registration_shippingSave" (click)="close_shipping_popup()" [ngClass]="{'active': file, 'inactive': !file}" type="button">Save</button>
                    
                </div>
            </div>
            <!-----------Shipping Upload Popup Section Ends-------> 

            <!-----------License Validation Sections here--------->
            
            <div class="registration_medpropop3 modal" style="display:block"  *ngIf="licenseErrorPopup">
                <div class="registration_medproContent" *ngFor="let shipping of shippingList.controls; let i = index">
                    <div class="registration_helpheader">
                        <div class="registration_medheader">Your License Information was not able to be validated.</div>
                        <div class="registration_medproclose" (click)="closeModal()">X</div>
                        <div class="clearboth"></div>
                    </div>
                            <div class="registration_medsubheader">Please modify the license information or continue to have it manually reviewed.</div>
                            <div class="registration_medpop">
                                <button class="registration_medprobutton medprom1 registration_medpromodify" (click)="closeModal()">Modify License Information</button>
                                <button class="registration_medprobutton medprom2 medproaddlocation2" (click)="onSave_shipping(i)" >Continue Without Validating</button>
                            </div>

                </div>
            </div>
            <!-----------License Validation Section Ends here----->


            <div class="registration_medpropop3 modal" style="display:block"  *ngIf="licenseErrorPopup1">
                <div class="registration_medproContent" *ngFor="let shipping of shippingList.controls; let i = index">
                    <div class="registration_helpheader">
                        <div class="registration_medheader">Your License Information was not able to be validated.</div>
                        <div class="registration_medproclose" (click)="closeModal1()">X</div>
                        <div class="clearboth"></div>
                    </div>
                            <div class="registration_medsubheader">Please modify the license information or continue to have it manually reviewed.</div>
                            <div class="registration_medpop">
                                <button class="registration_medprobutton medprom1 registration_medpromodify" (click)="closeModal1()">Modify License Information</button>
                                <button class="registration_medprobutton medprom2 medproaddlocation2" (click)="save_license_shipping(i)" >Continue Without Validating</button>
                            </div>

                </div>
            </div>

            <div class="registration_medpropop3 modal" style="display:block"  *ngIf="licenseErrorPopup2">
                <div class="registration_medproContent" *ngFor="let shipping of shippingList.controls; let i = index">
                    <div class="registration_helpheader">
                        <div class="registration_medheader">Your License Information was not able to be validated.</div>
                        <div class="registration_medproclose" (click)="closeModal2()">X</div>
                        <div class="clearboth"></div>
                    </div>
                            <div class="registration_medsubheader">Please modify the license information or continue to have it manually reviewed.</div>
                            <div class="registration_medpop">
                                <button class="registration_medprobutton medprom1 registration_medpromodify" (click)="closeModal2()">Modify License Information</button>
                                <button class="registration_medprobutton medprom2 medproaddlocation2" (click)="modifyForm(i, shipping.value)" >Continue Without Validating</button>
                            </div>

                </div>
            </div>

  <!------Shipping Information Section Ends here------>

  <!-------Exit Popup Confirmation Popup---------->
  <div class="registration_medpropop3 modal" style="display:block"  *ngIf="registrationExitpopup">
    <div class="registration_medproContent" *ngFor="let shipping of shippingList.controls; let i = index">
        <div class="registration_helpheader">
            <div class="registration_medheader">Are you sure you want to leave this page?</div>
            <div class="registration_medproclose" (click)="closeExitpopup()">X</div>
            <div class="clearboth"></div>
        </div>
                <div class="registration_medsubheader">If you leave now, your registration will be incomplete, and the information you have entered so far will not be saved. It also means you will not be able to start orders or view important information about your account.</div>
                <div class="registration_popcont">
                    <button class="registration_helpContinue"  (click)="closeExitpopup()">Continue Registration</button>
                    <button class="registration_helpLeave" (click)="redirectTojointaccount()">Leave and Lose Progress</button>
                    
                </div>
               

    </div>
</div>

  <!------Review Page section Starts here------>
  <div class="registration col-xs-12 no-padding" [style.display]="isVisibleReview ? 'block' : 'none'">
    

    
    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 registration_leftside no-padding-right">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 registration_nagivation no-padding">
        <div class="registration_nagivationPart" id="registration_nagivationPart1">
                <div class="registration_nagivationNo">
                    <div class="registration_nagivationtick" style="display:block;"><img src="assets\images\greentick.png" alt=""></div>
                </div>
               
                <div class="registration_nagivationName">User Profile</div>
            </div>

            <div class="registration_nagivationPart" id="registration_nagivationPart2">
                <div class="registration_nagivationNo">
                    <div class="registration_nagivationNumeric" style="display: none;">2</div>
                    <div class="registration_nagivationtick" style="display: block;"><img src="assets\images\greentick.png" alt=""></div>
                </div>
                <div class="registration_nagivationName">Business Details</div>
            </div>
         <div class="registration_nagivationPart" id="registration_nagivationPart3">
                <div class="registration_nagivationNo">
                    <div class="registration_nagivationNumeric" style="display: none;">3</div>
                    <div class="registration_nagivationtick" style="display: block;"><img src="assets\images\greentick.png" alt=""></div>
                </div>
                <div class="registration_nagivationName">Contacts &amp; Addresses<ul>
                       
                        <li style="display: list-item;" class="">Billing Information</li>
                        <li style="display: list-item;" class="">Paying Information</li>
                        <li class="" style="display: list-item;">Shipping Location(s)</li>
                        
                    </ul>
                </div>
            </div>
            <div class="registration_nagivationPart" id="registration_nagivationPart4">
                <div class="registration_nagivationNo">
                    <div class="registration_nagivationNumeric">4</div>
                    <div class="registration_nagivationtick"><img src="assets\images\greentick.png" alt=""></div>
                </div>
                <div class="registration_nagivationName active">Review &amp; Confirm</div>
            </div>
         </div>
    </div>
    <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 registration_rightside no-padding-left">
        <div class=" registration_help">
        Having trouble? &nbsp;<a routerLink="/help-and-faq" class="showPopupclick"><span>We Can Help</span><span class="global_blackarrow"></span></a>
        </div>

        <section class="registration_review registrationFlow" id="registration_section5" style="display: block;">
            <form id="reviewForm" novalidate="novalidate" data-gtm-vis-recent-on-screen55327271_215="1160805" data-gtm-vis-first-on-screen55327271_215="1160805" data-gtm-vis-total-visible-time55327271_215="100" data-gtm-vis-has-fired55327271_215="1">
               <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding-left registration_label padding-B10">Review Information</div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding-left registration_text1 padding-B20">Please confirm everything looks correct. Once you've submitted your information, you'll have access to your account where you can pay invoices, access helpful resources, and place orders. We won't be able to process any orders until we confirm your information, which usually takes 3-5 business days.</div>
                <div class="clearboth"></div>
                <div class="registration_reviewContainer">
                    <div class="registration_reviewProfile padding-B20" *ngIf="userDatadetail">
                        <div class="registration_reviewlabel1">Profile</div>
                        <div class="clearboth"></div>
                        <div class="registration_reviewlabel2 margin-B20">Login Information</div>
                        <div class="registration_populate registration_reviewemail1">{{userDatadetail.email}}</div>
                        <div class="registration_populate registration_reviewpassword" id="reviewLoginPassword">***********</div>
                        <div class="registration_reviewlabel2 margin-T25 margin-B20">Contact Information</div>
                        <div class="registration_populate registration_reviewname" id="reviewName">{{userDatadetail.firstName}}&nbsp;{{userDatadetail.lastName}}</div>
                        <div class="registration_populate registration_reviewBuyer" id="reviewJob">{{userDatadetail.jobTitle}}</div>
                        <div class="registration_populate registration_reviewemail" id="reviewContactEmail">{{userDatadetail.email}}</div>
                        <div class="registration_populate registration_reviewPh reviewcdcphonehead">
                            <div id="reviewPhone">{{userDatadetail.phone}}</div>
                            <div class="reviewcdcphone">Ext.</div>
                            <div class="reviewcdcphone" id="reviewPhoneext">{{userDatadetail.phoneExt}}</div>
                          </div>
                    </div>
                    <div class="registration_reviewBusiness padding-B20" *ngIf="formDataBussiness">
                        <div class="registration_reviewparent">
                            <div class="registration_reviewlabel1">Business Details</div>
                            <div class="registration_edit" id="registration_Businessedit" (click)="showBussinessSection()">Edit <i class="fa fa-pencil-square-o" aria-hidden="true"></i></div>
                            <div class="clearboth"></div>
                        </div>
                        <div class="registration_reviewlabel2 margin-B20">Company Information</div>
                        <div class="registration_populate registration_companyName" id="review-orgname">{{ formDataBussiness.orgName}}</div>
                        <div class="registration_populate registration_duns" id="review-duns"></div>
                        <div class="registration_populate registration_membership" id="registration_membership"></div><!-- Membership for registration changes -->
                        <div class="registration_populate registration_gln" id="registration_gln">{{formDataBussiness.globalLocationNumber}}</div>
                        <div class="registration_reviewlabel2 margin-T25 margin-B20">Address</div>
                        <div class="registration_populate registration_reviewaddress1" id="review-bussiness-address1">{{formDataBussiness.line1}}</div>
                        <div class="registration_populate registration_reviewaddress2" id="review-bussiness-address2">{{formDataBussiness.line2}}</div>
                        <div class="registration_populate registration_reviewaddress3" id="review-bussiness-city-zip">{{formDataBussiness.city}}, {{formDataBussiness.state}} {{formDataBussiness.postalCode}}</div>
                    </div>
                </div>
                <div class="registration_reviewContainer">
                    <div class="registration_reviewBilling padding-B20" *ngIf="formDataBilling">
                        <div class="registration_reviewparent">
                            <div class="registration_reviewlabel1">Billing Details</div>
                            <div class="registration_edit" id="registration_Billingedit" (click)="showBillingSection()">Edit <i class="fa fa-pencil-square-o" aria-hidden="true"></i></div>
                            <div class="clearboth"></div>
                        </div>
                        <div class="registration_reviewlabel2 margin-B20">Contact Information</div>
                        <div class="registration_populate registration_reviewname" id="review-billing-name">{{formDataBilling.firstName}}&nbsp;{{formDataBilling.lastName}}</div>
                        <div class="registration_populate registration_reviewemail" id="review-billing-email">{{formDataBilling.email}}</div>
                        <div class="registration_populate registration_reviewPh" id="review-billing-phone">{{formDataBilling.phone}} ext.{{formDataBilling.phoneExt}} </div>
                        <div class="registration_reviewlabel2 margin-T25 margin-B20">Address</div>
                        <div class="registration_populate registration_reviewaddress1" id="review-billing-address1">{{formDataBilling.line1}}</div>
                        <div class="registration_populate registration_reviewaddress2" id="review-billing-address2">{{formDataBilling.line2}}</div>
                        <div class="registration_populate registration_reviewaddress3" id="review-billing-city-zip">{{formDataBilling.city}}, {{formDataBilling.state}} {{formDataBilling.postalCode}}</div>
                        <div class="registration_reviewlabel2 registration_reviewCheckbox margin-T25">
                          
                            <label class="checkbox-button" *ngIf="formDataBilling?.invoiceEmail; else noEmail1">
                                <input type="checkbox" class="registration_checkbox" formControlName="optNotifyInvoiceEmail"  value="true"  [checked]="true"  >
                                <span class="checkbox-button__control"></span>
                            </label>
                            <ng-template #noEmail1>
                                <label class="checkbox-button" *ngIf="formDataBilling?.invoiceEmail; else noEmail">
                                    <input type="checkbox" class="registration_checkbox" formControlName="optNotifyInvoiceEmail"  [checked]="billingAddress.get('optNotifyInvoiceEmail')?.value"  >
                                    <span class="checkbox-button__control"></span>
                                </label> 
                            </ng-template>

                            <label class="registration_checkboxLabel">Receiving invoices by email</label>
                            <div class="clearboth"></div>
                        </div>
                        <div class="registration_populate registration_reviewemail" id="review-billing-invoice-email">{{formDataBilling.invoiceEmail}}</div>
                        
                        <div class="registration_reviewlabel2 registration_reviewCheckbox margin-T25">
                           
                                <label class="checkbox-button" *ngIf="formDataBilling?.acctStmtEmail; else noEmail">
                                    <input type="checkbox" class="registration_checkbox" formControlName="optNotifyAcctStmtEmail" value="true"  [checked]="true" />
                                    <span class="checkbox-button__control"></span>
                                </label>
                            <ng-template #noEmail>
                            <label class="checkbox-button">
                                <input type="checkbox" class="registration_checkbox" formControlName="optNotifyAcctStmtEmail"  [checked]="billingAddress.get('optNotifyAcctStmtEmail')?.value" />
                                <span class="checkbox-button__control"></span>
                            </label>
                           </ng-template>
                            <label class="registration_checkboxLabel">Receiving account statements by email</label>
                            <div class="clearboth"></div>
                        </div>
                        <div class="registration_populate registration_reviewemail" id="review-billing-account-email">{{formDataBilling.acctStmtEmail}}</div>
                        
                    </div>
                    <div class="registration_reviewpaying padding-B20" *ngIf="formDataPaying">
                        <div class="registration_reviewparent">
                            <div class="registration_reviewlabel1">Paying Details</div>
                            <div class="registration_edit" id="registration_Payingedit" (click)="showPayingSection()">Edit <i class="fa fa-pencil-square-o" aria-hidden="true"></i></div>
                            <div class="clearboth"></div>
                        </div>
                        <div class="registration_reviewlabel2 margin-B20">Contact Information</div>
                        <div class="registration_populate registration_reviewname" id="review-paying-name">{{formDataPaying.firstName}}&nbsp;{{formDataPaying.lastName}}</div>
                        <div class="registration_populate registration_reviewemail" id="review-paying-email">{{formDataPaying.email}}</div>
                        <div class="registration_populate registration_reviewPh" id="review-paying-phone">{{formDataPaying.phone}} ext. {{formDataPaying.phoneExt}} </div>
                        <div class="registration_reviewlabel2 margin-T25 margin-B20">Address</div>
                        <div class="registration_populate registration_reviewaddress1" id="review-paying-adrress1">{{formDataPaying.line1}}</div>
                        <div class="registration_populate registration_reviewaddress2" id="review-paying-adrress2">{{formDataPaying.line2}}</div>
                        <div class="registration_populate registration_reviewaddress3" id="review-paying-city-zip">{{formDataPaying.city}}, {{formDataPaying.state}} {{formDataPaying.postalCode}}</div>
                    </div>
                </div>
                <div class="registration_reviewShipping margin-B20">
                    <div class="registration_reviewparent">
                        <div class="registration_reviewlabel1">Shipping Locations</div>
                        <div class="registration_edit" id="registration_Shippingedit" (click)="showShippingSection()">Edit <i class="fa fa-pencil-square-o" aria-hidden="true"></i></div>
                        <div class="clearboth"></div>
                    </div>
                    <div class="registration_reviewlabel2"><span class="total_ship_location">{{submissionCount}}</span> shipping locations</div>
                </div>                      
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding margin-T20 margin-B20 registration_button">
                    <div class="registration_back active" (click)="showShippingSection()">
                        <div class="global_blackarrow"></div>
                        <span>Back</span>
                        <div class="clearboth"></div>
                    </div>
                    <button class="registration_createAccount active" (click)="createAccount()" type="submit">Create Account</button>
                    <div class="clearboth"></div>
                </div>

            </form>
        </section>

    </div>



  </div>

<!------Review Page section Ends here------>
</ng-container>