import { Component } from '@angular/core';
import { CmsBannerComponent, CmsService } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { UserInfoService } from '../../spartacus/services/user-info.service';

@Component({
  selector: 'app-errorpage-content',
  templateUrl: './errorpage-content.component.html',
  styleUrl: './errorpage-content.component.scss'
})
export class ErrorpageContentComponent {

  constructor(public cmsService: CmsService, public component: CmsComponentData<CmsBannerComponent>, private userInfoService: UserInfoService) {}

  fourthnode: Observable<any> = this.component.data$;
  userLoggedIn: boolean = false;

  ngOnInit(): void{
    this.userInfoService.userLoggedIn$.subscribe(res=>{
     this.userLoggedIn = res;
   })
  }
}
