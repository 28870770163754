<div class="breadcrumbs--page-nav hidden-xs hidden-sm" *ngIf="!userLoggedIn || (userLoggedIn && (!webEnabled))">
  <div class="container">
    <p><a [routerLink]="'/'">Home</a><span> &gt; </span><a [routerLink]="'/products'">Products</a><span> &gt; </span><strong>The Adjuvant
        Advantage</strong></p>
  </div>
</div>
<div class="product_efficacy-box1 fluad_adjuvant-box1">
  <div class="container" *ngIf="node | async as item">
    <h1 class="product_efficacy-header">{{item.text1Content}}</h1>
    <div class="product_efficacy-text" [innerHTML]="item.text2Content"></div>
  </div>
</div>
<div class="product_adjuvant_animation">
  <div class="container">
    <div class="product_animation-container">
      <div class="simple-banner banner__component--responsive">
        <img class="js-responsive-image animate-shield3" alt="" title=""
          src="../../../assets/images/VennDiagram_Desktop_02_1x.webp">
      </div>
      <img class="product_animation-image animate-shield1 fadeOut" id="product_animation-3dshield" alt=""
        src="../../../assets/images/Shield3Bolt_Colored.svg" style="display: inline; transform: scale(1);">
      <img class="product_animation-image" id="product_animation-2dshield" alt=""
        src="../../../assets/images/FLUAD-Shield_2D_1x.webp" style="display: none;">
    </div>
    <hr class="hidden-xs hidden-sm">
    <div class="product_venn-textContainer hidden-md hidden-lg">
      <div class="product_venn-text">
        <div class="product_venn-headertext">strengthen</div>
        <hr>
        <div class="product_venn-bodytext">Stimulate more antibodies to help protect against influenza infection</div>
      </div>
      <div class="product_venn-text">
        <div class="product_venn-headertext">broaden</div>
        <hr>
        <div class="product_venn-bodytext">Create more diverse cross-reactive antibodies to help protect against strain
          mismatch</div>
      </div>
      <div class="product_venn-text">
        <div class="product_venn-headertext">lengthen</div>
        <hr>
        <div class="product_venn-bodytext">Increase activation of memory T and B cells to help extend protection against
          influenza</div>
      </div>
    </div>
  </div>
</div>


<!--OLD CODE OF SURUJIT-->
<!-- <div class="product_animation-container">
    <div class="simple-banner banner__component--responsive">
      <img class="js-responsive-image animate-shield3" src="../../../assets/images/VennDiagram_Desktop_02_1x.webp" [@fadeInOut]="animationFlag ? 'fadeIn' : 'fadeOut'">
    </div>
    <img class="product_animation-image animate-shield1" id="product_animation-3dshield" alt="" src="../../../assets/images/Shield3Bolt_Colored.svg" style="display: inline; transform: scale(1);" [@integrate]="animationFlag ? 'integrate' : ''">
     <img class="product_animation-image" id="product_animation-2dshield" src="../../../assets/images/FLUAD-Shield_2D_1x.webp" [@fadeInOut]="animationFlag ? 'fadeIn' : 'fadeOut'">
  </div> -
   <div class="product_animation-container">
    <div class="simple-banner banner__component--responsive">
      <img class="js-responsive-image animate-shield3" src="../../../assets/images/VennDiagram_Desktop_02_1x.webp" [@fadeInOut]="animationFlag ? 'fadeIn' : 'fadeOut'">
    </div>
    <img class="product_animation-image animate-shield1" id="product_animation-3dshield" alt="" src="../../../assets/images/Shield3Bolt_Colored.svg" style="display: inline; transform: scale(1);" [@fadeInOut]="animationFlag ? 'fadeIn' : 'fadeOut'">
    <img class="product_animation-image" id="product_animation-2dshield" src="../../../assets/images/FLUAD-Shield_2D_1x.webp" [@fadeInOut]="animationFlag ? 'fadeIn' : 'fadeOut'">
  </div>
   <div class="product_animation-container">
    <div class="simple-banner banner__component--responsive">
      <img class="js-responsive-image" src="../../../assets/images/VennDiagram_Desktop_02_1x.webp">
    </div>
    <img class="product_animation-image animate-shield" id="product_animation-shield" alt="" src="../../../assets/images/Shield3Bolt_Colored.svg" style="display: inline;">
  </div>  -->
<!--hidden-->
<!-- <div class="product_venn-textContainer hidden-md hidden-lg">
    <div class="product_venn-text">
            <div class="product_venn-headertext">strengthen</div>
            <hr>
            <div class="product_venn-bodytext">Stimulate more antibodies to help protect against influenza infection</div>
        </div>
        <div class="product_venn-text">
            <div class="product_venn-headertext">broaden</div>
            <hr>
            <div class="product_venn-bodytext">Create more diverse cross-reactive antibodies to help protect against strain mismatch</div>
        </div>
        <div class="product_venn-text">
            <div class="product_venn-headertext">lengthen</div>
            <hr>
            <div class="product_venn-bodytext">Increase activation of memory T and B cells to help extend protection against influenza</div>
        </div>
    </div> -->