import { ChangeDetectorRef, Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ClientTokenService, OccEndpointsService } from '@spartacus/core';
import { CommonApiService } from '../../spartacus/services/common-api.service';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { BaseSiteService, CmsService } from '@spartacus/core';

@Component({
  selector: 'app-custom-verifyprofile',
  templateUrl: './custom-verifyprofile.component.html',
  styleUrl: './custom-verifyprofile.component.scss'
})
export class CustomVerifyprofileComponent {
  setCurrentScreen: string | undefined='';
  currentRoute: any;
  emailVerificationForm: FormGroup | undefined | any;
  apiErr: string = '';
  paramsCode: string='valid';
  currentSite:any;
  public constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public userInfoService: UserInfoService,
    private occEndpointsService: OccEndpointsService,
    private commonService: CommonApiService,
    private clientTokenService: ClientTokenService,
    private cd: ChangeDetectorRef,
    private baseSiteService:BaseSiteService,
    
  ) {
     
      let url = this.router.url.split('?')[0].split('/');
      this.currentRoute = url[url.length - 1];
      this.route.queryParams.subscribe(params => {
       // if (params['errorCode'] && params['errorMessage']) {
        if (params['errorCode']) {
          this.paramsCode = params['errorCode'];
          // this.setCurrentScreen = 'Account activation link expired';
        }
      })
      this.setForm();
    
    }
  ngOnInit() {

    this.baseSiteService.getActive().subscribe(site => {
      this.currentSite = site;
    });
    
    if (this.paramsCode!=='403002'){
      
     // if (this.paramsCode=='206005'){
      if (this.currentSite=='seqirusb2b-uk'){
       this.redirectToJoinAccount();
      }
      else{
       this.redirectToProfileActivation();
      }
    }
 
  }
  redirectToJoinAccount() {
    setTimeout(()=>{ 
      this.router.navigate(['/register/join-account']);
     // this.router.navigate(['/register/join-account']).then(() => {
     //   window.location.reload();
     // });
    },8000);
  }
  
  redirectToProfileActivation() {
    setTimeout(()=>{ 
      this.router.navigate(['/profileactivation']);
    //this.router.navigate(['/profileactivation']).then(() => {
    //  window.location.reload();
    //});
    },8000);
  }
  setForm() {
    this.emailVerificationForm = this.fb.group({
      expired_email: ['', [Validators.required, Validators.email]]
    })
  }
  customValidator(control: AbstractControl): ValidationErrors | null {
    // Your custom validation logic here
    if (control) {
      return { 'customError': 'Custom validation failed' };
    }
    return null;
  }
  getFormControl(control: string) {
    return this.emailVerificationForm!.get(control);
  }
  resendVerification() {
    if (!this.emailVerificationForm!.valid) {
      this.emailVerificationForm!.markAllAsTouched();
      return;
    }
    this.clientTokenService.getClientToken().subscribe(res => {
      if (res) {
        let token = res.access_token;
        this.passCalltoVerification(token);
      }
    })

  }
  passCalltoVerification(token: string) {
    let endpoint = this.occEndpointsService.buildUrl(`Verify/VerifyProfileEmail?emailId=${this.emailVerificationForm!.value.expired_email}`);
    let payload = {
      url: endpoint,
      token: token,
      contentType: 'text/xml',
      responseType: 'text',
    }
    this.commonService.getAPICallNoAuth(payload).subscribe({
      next: (res: any) => {
        if (res == 'success') {
          localStorage.setItem('email', this.emailVerificationForm!.value.expired_email);
          this.router.navigate(['/verificationsent']);
        }
        else {
          this.apiErr = res;
        }
        this.cd.detectChanges();
      },
      error: (e) => {
      },
    })
  }

}
