import { Injectable } from '@angular/core';
import {NativeDateAdapter} from  '@angular/material/core';

@Injectable({
    providedIn: 'root'
  })
export class CustomDateAdapter extends NativeDateAdapter{

    override parse(val:String):Date | null{
        if(!val) return null;
        const [month,day,year] = val.split('/').map(Number);
        return new Date(year,month-1,day);

    }
    override format(date:Date,displayformat:string):string{
        
        if(displayformat === 'monthYearLabel' || displayformat === 'MMM YYYY'){
            const month = date.toLocaleString('default',{month:'short'})
            const year = date.getFullYear();
            return `${month} ${year}`;
        }
        const day = this._to2Digit(date.getDate());
        const month = this._to2Digit(date.getMonth() +1);
        const year = date.getFullYear();
        return `${month}/${day}/${year}`;

    }

    private _to2Digit(num: number) {
       return ('00'+num).slice(-2);
    }
}
