import { BaseSiteService, CmsBannerComponent } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { forkJoin, Observable, of } from 'rxjs';
import { ContentService } from '../../spartacus/services/content.service';
import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { StartOrderService } from '../../custom-start-order/start-order.service';
import { Router } from '@angular/router';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { ReturnsUkService } from '../returns-uk.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { ApiResponse, LocationData, ProductData, ShipData,ReturnData,Product,GroupedProduct,GalleryImage } from '../location-data.model';
import { TemplateRef } from '@angular/core';
import { AfterViewInit } from '@angular/core';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { MatDialog } from '@angular/material/dialog';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { GalleryItem, ImageItem } from 'ng-gallery';
import { HttpClient } from '@angular/common/http';
import { FileUploadModalComponent } from '../file-upload-modal/file-upload-modal.component';
import { Overlay, NoopScrollStrategy } from '@angular/cdk/overlay';
import { ReturnsExitPopupComponent } from '../returns-exit-popup/returns-exit-popup.component';

@Component({
  selector: 'app-return-new',
  templateUrl: './return-new.component.html',
  styleUrl: './return-new.component.scss'
})
export class ReturnNewComponent {
  @ViewChild('fileUploadModal') fileUploadModal!: TemplateRef<any>;
  imageObject: Array<{ image: string, thumbImage: string }> = [];
  locationImages: { [key: string]: GalleryImage[] } = {}; // Store images by locationID
  images: { src: string, alt: string }[] = [];
  galleryImages: GalleryImage[] = [];
  selectedImage: GalleryImage | null = null;
  imageCount: number = 0;
  selectedIndex: number = 0;
  isVisible = false;
  showFeedback = false;
  
  constructor(private overlay: Overlay,public component: CmsComponentData<CmsBannerComponent>,private http: HttpClient,public dialog: MatDialog, private cdr: ChangeDetectorRef, private router: Router, private startOrderService: StartOrderService, private userInfoService: UserInfoService, public contentService:ContentService, private baseSiteService:BaseSiteService,private returnsUkService:ReturnsUkService) {
    
  }

  bannerNode: Observable<any> = this.component.data$;
  displayedColumns: string[] = ['locname', 'address', 'state','checked',];
  private subscription: Subscription | undefined;
  dataSource = new MatTableDataSource<LocationData>([]);
  returnsProductData: any;
  searchValue: string = '';
  selectedAddress: string | undefined;
  inputValueaQiv: string = '';
  inputValueQivc: string = '';
  formattedValue: string | null = '';
  formattedValueQivc: string | null = '';
  errMsg1:boolean = false;
  errMsg2:boolean = false;
  errMsg1qivc:boolean = false;
  errMsg2qivc:boolean = false;
  aqivMaxAllowedQty: any;
  qivcMaxAllowedQty: any;
  checkboxChecked: boolean = false; // Property to hold the checkbox state
  inputErrorMessage: string = ''; // Property to hold the input field error message
  checkboxErrorMessage: string = ''; 
  imgcheckboxChecked: boolean = false;
  imgcheckboxErrorMessage: string = ''; 
  selection = new SelectionModel<LocationData>(true, []);
  selectedRows: LocationData[] = [];
  selectedCount = 0;
  productsToReturn: any[] = [];
  uploadedImages: string[] = [];
  imageFiles: File[] = [];
  slideLeft: boolean = false;
  slideRight: boolean = false;
  locationImageFiles: { [key: string]: File[] } = {};
  errorMessages: { [key: string]: string } = {};
  checkboxStates: { [key: string]: boolean } = {};
  checkboxErrorMessages: { [key: string]: string } = {};
  showloader: boolean = false;
  showNewReturn: boolean = false;
  showProductReturn: boolean = false;
  showLocationSelection: boolean = true;
  defaultseason:string=""
  ngOnInit() {
    this.dataSource.filterPredicate = (data: LocationData, filter: string) => {
      const dataStr = Object.values(data).join(' ').toLowerCase();
      return dataStr.includes(filter.trim().toLowerCase());
    };
    this.returnsUkService.currentShowStatus.subscribe(show => {
      this.showNewReturn = show;
      this.cdr.detectChanges(); // Manually trigger change detection
    });
   
  }
 
  backTolocation(){
    this.showProductReturn = false;
    this.showLocationSelection = true;

  }

  ngAfterViewInit(): void {
    this.returnsUkService.getSeasons().subscribe((res:any)=>{
      this.defaultseason=res.defaultReturnSeason
    
    this.returnsUkService.getLocations(this.defaultseason).pipe(take(1)).subscribe((response: any) => {
      const data = response.data; // Access the data property
      if (Array.isArray(data)) {
        const formattedData = data.map((item: any) => ({
          ...item,
          checked: item.checked ? item.checked.toString() : 'false' // Ensure checked is a string
        }));
       this.dataSource.data = data;
        this.updateSelectedRows();
      } else {
        console.error('Expected an array but got:', data);
      }
    }, error => {
      console.error('Error fetching data:', error); // Debugging: Log any errors
    });
  });
    this.returnsUkService.currentVisibility.subscribe(visibility => {
      this.isVisible = visibility === 'C';
      this.cdr.detectChanges();
    });
  }

  
  scrollTotop(): void {
    // Call this method to scroll to the top
    this.contentService.scrollToTop();
  }
  
  onRowClick(row: LocationData) {    
    this.selection.toggle(row);
    this.updateSelectedRows();
    
  }

  updateSelectedRows() {
    this.selectedRows = this.selection.selected;
    this.selectedCount = this.selectedRows.length; // Update the selected count
  }

  selectAll() {
    this.selection.clear();
    this.dataSource.data.forEach(row => {
      if (row.checked === 'true') {
        this.selection.select(row);
      }
    });
    this.updateSelectedRows();
  }

  clearAll() {
    this.selection.clear();
    this.dataSource.data.forEach(row => {
      if (row.checked === 'true') {
        row.checked = 'true';
      }
    });
    this.updateSelectedRows();
  }

  toggleAllRows(event: any) {
    if (event.checked) {
      this.selectAll();
    } else {
      this.clearAll();
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.updateSelectedRows();
  }

  navigateToReturns(event: Event, row: any) {
    event.stopPropagation(); // Stop the event propagation
    // Set the locname in the shared service
  
    // Change the visibility state to hide this component and show the other component
    this.returnsUkService.changeVisibility('A');
    this.returnsUkService.changeLocname(row.locname);
    this.cdr.detectChanges();
  }

  submitSelectedRows() {
    console.log('Selected Rows:', this.selectedRows); // Log selected rows to verify
  }
  
  getProductsToReturn() {
    this.showloader = true;
    const selectedLocIDs = this.selection.selected.map(row => row.locID);
    if (selectedLocIDs.length > 0) {
      this.returnsUkService.postProductsToReturn(selectedLocIDs,this.defaultseason).subscribe((response: { returnData: ReturnData[] }) => {
 
  
        // Access the array of returnData
        const returnDataArray = response.returnData;
  
        // Group products by productName within each returnData
        returnDataArray.forEach(returnData => {
          returnData.returnsProductData.shipData.forEach(shipData => {
            const groupedProducts: { [key: string]: Product[] } = {};
            shipData.qivcerrorquantity=false;
            shipData.aqiverrorquantity=false;
            shipData.aqiverrorsor=false;
            shipData.qivcerrorsor=false;
            shipData.productData.forEach(product => {
              if (!groupedProducts[product.productName]) {
                groupedProducts[product.productName] = [];
              }
              groupedProducts[product.productName].push(product);
              // Initialize validation properties
              product.formattedValue = product.formattedValue || '';
              product.formattedValueQivc = product.formattedValueQivc || '';
              product.errMsg1 = false;
              product.errMsg2 = false;
              product.errMsg1qivc = false;
              product.errMsg2qivc = false;
            });
            shipData.groupedProducts = groupedProducts;
          });
        });
  
        this.productsToReturn = returnDataArray;
        this.showLocationSelection = false;
        this.showProductReturn = true;
        this.showloader = false;
        this.cdr.detectChanges();
      }, error => {
        console.error('Error posting data:', error); // Handle the error as needed
      });
    } else {
      console.warn('No rows selected'); // Handle the case where no rows are selected
    }
  }
  getGroupedProductKeys(groupedProducts: { [key: string]: Product[] }): string[] {
    return Object.keys(groupedProducts);
  }
  formatNumber(value: string, product: Product, maxAllowedQty: number) {
    product.inputValueaQiv = value;
    const numericValue = value.replace(/\D/g, '');
    product.inputValueaQiv = numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
   

  
    if (numericValue.length > 6) {
      product.formattedValue = '';
    } else {
      product.formattedValue = product.inputValueaQiv;
    }
  
    const numericQty = parseInt(numericValue, 10);
    if (numericQty > product.totalQuantity) {
      product.errMsg2 = true;
    }
     /*  product.errMsg1 = false;
    } else if (numericQty > maxAllowedQty) {
      product.errMsg1 = true;
      product.errMsg2 = false;
      
    } */ else {
     // product.errMsg1 = false;
      product.errMsg2 = false;
    }
  }
  
  formatNumber1(value: string, product: Product, maxAllowedQty: number) {
    const numericValue = value.replace(/\D/g, '');
    product.inputValueQivc = numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  
    if (numericValue.length > 6) {
      product.formattedValueQivc = '';
    } else {
      product.formattedValueQivc = product.inputValueQivc;
    }
  
    const numericQty = parseInt(numericValue, 10);
    if (numericQty > product.totalQuantity) {
      product.errMsg2qivc = true;
     // product.errMsg1qivc = false;
    } /* else if (numericQty > maxAllowedQty) {
      product.errMsg1qivc = true;
      product.errMsg2qivc = false;
    }  */else {
     // product.errMsg1qivc = false;
      product.errMsg2qivc = false;
    }
  }
 
 
  calculateTotalDoses(group: Product[], productName: string,maxAllowedQty: number,shipData:any): string {
    const total = group.reduce((sum, product) => {
      const inputValue = productName === 'aQIV' ? product.inputValueaQiv : product.inputValueQivc;
      const numericValue = parseInt(inputValue?.replace(/,/g, '') || '0', 10);
      return sum + (isNaN(numericValue) ? 0 : numericValue);
    }, 0);
  
    if (total === 0) {
      return '';
    }
    if (total > maxAllowedQty) {
     if(productName === 'aQIV'){
      shipData.aqiverrorsor=true
      
     }else{
      shipData.qivcerrorsor=true
     }
    }else{
      if(productName === 'aQIV'){
        shipData.aqiverrorsor=false
       }else{
        shipData.qivcerrorsor=false
       }
      
    }
    if(productName === 'aQIV'){
     if( group.find((check: any) => {
        return check.errMsg2 == true;
      }) ){
        shipData.aqiverrorquantity=true
      }else{
        shipData.aqiverrorquantity=false
      }
    }
    else{
     if( group.find((check: any) => {
      return check.errMsg2qivc == true;
    }) ){
      shipData.qivcerrorquantity=true
    }else{
      shipData.qivcerrorquantity=false
    }
  }
    // Format the total in UK number format
    const formattedTotal = total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return formattedTotal;
  }
  
  restrictInput(event: KeyboardEvent) {
    const inputChar = String.fromCharCode(event.charCode);
    if (!/^\d+$/.test(inputChar)) {
      event.preventDefault();
    }
  }

  
 
  onCheckboxChange() {
    if (this.checkboxChecked) {
      this.checkboxErrorMessage = ''; // Clear the error message when checkbox is checked
    }
    else{
      this.checkboxErrorMessage = 'Please confirm to proceed';
    }
  }
  imgonCheckboxChange() {
    if (this.imgcheckboxChecked) {
      this.imgcheckboxErrorMessage = ''; // Clear the error message when checkbox is checked
    }
    else{
      this.imgcheckboxErrorMessage = 'Please either attach images or select the checkbox for manual inspection.';
    }
  }

  
  openFileUploadModal(locationID: string): void {
    if (!this.locationImages[locationID]) {
      this.locationImages[locationID] = [];
    }
    const dialogRef = this.dialog.open(FileUploadModalComponent, {
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      width: '800px',
      data: { locationID, images: this.locationImages[locationID] },
      disableClose: true,
      hasBackdrop: true,
      autoFocus: true,
      restoreFocus: true,
      backdropClass: 'custom-backdrop'
    });
  
    dialogRef.componentInstance.imagesUploaded.subscribe((data: { locationID: string, images: GalleryImage[], files: File[] }) => {
      this.locationImages[data.locationID] = data.images;
      this.locationImageFiles[data.locationID] = data.files;
      this.cdr.detectChanges(); // Trigger change detection
    });
  }

 
  getUploadedImageCount(locationID: string): number {
    return this.locationImages[locationID] ? this.locationImages[locationID].length : 0;
  }




  generatePayload(locationData: any): any {
    return {
      locationId: locationData.locationID || '',
      aqivMaxAllowedQuantity: locationData.aqivMaxAllowedQty ? locationData.aqivMaxAllowedQty.toString() : '',
      qivcMaxAllowedQuantity: locationData.qivcMaxAllowedQty ? locationData.qivcMaxAllowedQty.toString() : '',
      productsRefund: locationData.productData ? locationData.productData.map((product: any) => ({
        batchNumber: product.batchNumber || '',
        poNumber: product.poNumber || '',
        initialQuantity: this.getInitialQuantity(product).toString(),
        totalQuantity: product.totalQuantity ? product.totalQuantity.toString() : '0',
        additionalQuantity: "0", // Assuming no additional quantity
        productName: product.productName || ''
      })) : []
    };
  }

  getInitialQuantity(product: any): number {
    if (product.productName === 'aQIV') {
      return product.inputValueaQiv ? parseInt(product.inputValueaQiv, 10) : 0;
    } else if (product.productName === 'QIVc') {
      return product.inputValueQivc ? parseInt(product.inputValueQivc, 10) : 0;
    }
    return 0; // Default value if no input is provided
  }

  generateAllPayloads(locationsData: any[]): any {
    return {
      season: this.defaultseason, // Replace with actual season value
      additionalreturn: false,
      locations: locationsData.map(locationData => this.generatePayload(locationData))
    };
  }


  processAndSendPayloads(): void {
    if (!this.validateAll()) {
      return;
    }
    this.showloader = true; // Show loader
    const locationsData = this.getLocationsData();
    const payload = this.generateAllPayloads(locationsData);

    // First, submit images
    this.submitImages().subscribe(
      () => {
        // After successful image upload, submit the payload
        this.returnsUkService.submitReturns(payload).subscribe(response => {
          if(response=='FAILURE' || response== 'failure' || response=='Failure' ){
            this.returnsUkService.setReturnFailurePopup(true);
            this.showProductReturn =false;
            this.showLocationSelection = false;
            this.showFeedback = false;
            this.showloader = false; 
            this.scrollTotop();
            this.cdr.detectChanges();
          }else{
          this.showProductReturn =false;
          this.showLocationSelection = false;
          this.showFeedback = true;
          //To hide job bag code in returns thankyou page
          const bodyslot=document?.querySelector('.Section8-returnsLandingPage') as HTMLElement;
          bodyslot?.setAttribute("style","display:none");
           //To retuns banner in returns thankyou page
          const bodyslot1=document?.querySelector('.Section3-returnsLandingPage') as HTMLElement;
          bodyslot1?.setAttribute("style","display:none");  
          // To reduce space between top header alone in returns thankyou page  
          const bodyslot2=document?.querySelector('.UkCreateReturn') as HTMLElement;
          bodyslot2?.setAttribute("style","margin-top:10px");  
          //To reduce padding in pi banner in retun thankyou page
          const bodyslot3=document?.querySelector('.PibannerUk') as HTMLElement;
          bodyslot3?.setAttribute("style","padding: 10px 40px;");          
          this.scrollTotop();
          this.showloader = false; 
          this.cdr.detectChanges();
          }
        }, error => {
          console.error('Payload Submission Error:', error);
          this.returnsUkService.setReturnFailurePopup(true);
          this.showProductReturn =false;
          this.showLocationSelection = false;
          this.showFeedback = false;
          this.showloader = false; 
          this.scrollTotop();
          this.cdr.detectChanges();
        });
      },
      error => {
        console.error('Image Upload Error:', error);
        this.returnsUkService.setReturnFailurePopup(true);
        this.showProductReturn =false;
        this.showLocationSelection = false;
        this.showFeedback = false;
        this.showloader = false; 
        this.scrollTotop();
        this.cdr.detectChanges();
      }
    );
  }
  
  submitImages(): Observable<any> {
    const uploadObservables = [];
  
    for (const locationID in this.locationImageFiles) {
      if (this.locationImageFiles.hasOwnProperty(locationID)) {
        const imageFormData = new FormData();
        imageFormData.append('locationsId', locationID);
        imageFormData.append('season', this.defaultseason);
        this.locationImageFiles[locationID].forEach((file: File, index: number) => {
          imageFormData.append('registration_uploadfile', file, file.name);
        });
  
        uploadObservables.push(this.returnsUkService.submitImages(imageFormData));
      }
    }
  
    // If there are no images to upload, return an observable that completes immediately
    if (uploadObservables.length === 0) {
      return of(null);
    }
  
    // Return an observable that completes when all image uploads are done
    return forkJoin(uploadObservables);
  }

getLocationsData(): any[] {
  return this.productsToReturn.map(returnData => returnData.returnsProductData.shipData).flat();
}

validateAll(): boolean {
  let isValid = true;
  this.errorMessages = {}; // Clear previous error messages
  this.checkboxErrorMessages = {}; // Clear previous error messages
  this.checkboxErrorMessage = ''; // Clear single checkbox error message

  for (const returnData of this.productsToReturn) {
    for (const shipData of returnData.returnsProductData.shipData) {
      let hasInput = false;
      let hasErrorMessages = false;

      for (const productName of this.getGroupedProductKeys(shipData.groupedProducts)) {
        if (productName === 'aQIV' || productName === 'QIVc') {
          for (const product of shipData.groupedProducts[productName]) {
            if (product.inputValueaQiv || product.inputValueQivc) {
              hasInput = true;
              delete this.errorMessages[shipData.locationID];
            }
            if(shipData.aqiverrorsor || shipData.qivcerrorsor){
              hasErrorMessages = true;
              isValid = false;
            }
            // Check for additional error messages
            if (product.errMsg1 || product.errMsg2 || product.errMsg1qivc || product.errMsg2qivc) {
              hasErrorMessages = true;
              isValid = false;
            }
          }
        }
      }

      if (!hasInput && !hasErrorMessages) {
        this.errorMessages[shipData.locationID] = 'Please fill in at least one input field.';
        isValid = false;
      }

      const imageCount = this.getUploadedImageCount(shipData.locationID);
      if (imageCount === 0 && !this.checkboxStates[shipData.locationID]) {
        this.checkboxErrorMessages[shipData.locationID] = 'Please check the checkbox or upload an image.';
        isValid = false;
      }

    }
  }



  // Validate the single checkbox
  if (!this.checkboxChecked) {
    this.checkboxErrorMessage = 'Please confirm to proceed';
    isValid = false;
  }

  this.cdr.detectChanges(); // Trigger change detection
  return isValid;
}

  // Method to handle input changes
  handleInputChange(shipData: any, productName: string, inputValue: any) {
 
    if (inputValue) {
      delete this.errorMessages[shipData.locationID];
    }
    // Additional logic for handling input changes can be added here
    }

onCheckboxChangeimg(locationID: string): void {
  if (this.checkboxStates[locationID]) {
    this.checkboxErrorMessages[locationID] = ''; // Clear the error message when checkbox is checked
  }else{
    this.checkboxErrorMessages[locationID] ='Please check the checkbox or upload an image.'
  }
}

goToreturn(): void {
  window.location.reload();
}

openExistpopup(): void {

  const dialogRef = this.dialog.open(ReturnsExitPopupComponent, {
    scrollStrategy: this.overlay.scrollStrategies.noop(),
    width: '63%',
    disableClose: false,
    hasBackdrop: true,
    autoFocus: true,
    restoreFocus: true,
    backdropClass: 'custom-backdrop'
  });
  
}

}
