import { ChangeDetectorRef, Component, Renderer2 } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { combineLatest, map, Observable, switchMap } from 'rxjs';
import { ContentService } from '../../spartacus/services/content.service';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { BaseSiteService, CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';
import { HelpnfaqsearchPipe } from '../../cms-common/Pipes/helpnfaqsearch.pipe';

@Component({
  selector: 'app-uk-lo-second',
  templateUrl: './uk-lo-second.component.html',
  styleUrl: './uk-lo-second.component.scss'
})
export class UkLoSecondComponent {
  constructor(public component: CmsComponentData<CmsBannerComponent>, public accessService: ContentService,private cmsService:CmsService ,public cd: ChangeDetectorRef,private userInfoService:UserInfoService,  protected config: OccConfig,private baseSiteService:BaseSiteService,private renderer:Renderer2) { }
  secondnode: Observable<any> = this.component.data$;
  activeIndexes: Set<string> = new Set();
  activeAccordion: number | null = null;
  mergedObject1: any = [];
  mergedObject2: any = [];
  filteredData: any[] = [];
 seallbtn:boolean = false;
 searchvalue :string=''; 
 searchresults:boolean = false;
 shownoresult:boolean =false;
 userLoggedIn: boolean=false;
 userInfo: any;
 currentSite: string | undefined;
 isModalOpen = false;
 popupdata:any;
 private activeDiv: HTMLElement | null = null;
  ngOnInit() {
      window.onfocus = this.handleWindowFocus.bind(this);
    this.secondnode.subscribe(res => {
      this.loadFaqData(res);
    });
    
    //for looged, loggedout
    this.userInfoService.userLoggedIn$.subscribe(res=>{
      // console.log('login check',res);
      this.userLoggedIn = res;
      if(this.userLoggedIn){
        this.userInfoService.getUserData().subscribe(res=>{
          if(res){
          this.userInfo=res;
          }
        });
      }
      this.cd.detectChanges();
    })
    //to get the current basesite 
    this.baseSiteService.getActive().subscribe((siteId) => {
      this.currentSite = siteId;
      // console.log(this.currentSite);
    });
    this.cmsService.getComponentData('contactusPagePopup').subscribe(res=>{
      this.popupdata=res;
  });
  }
  ngOnDestroy() {
    window.onfocus = null;
  }
  handleWindowFocus() {
    if (this.isModalOpen) {
      this.closeModal();
    }
  }
  loadFaqData(res: any) {
    const tilesList = res.FaqAllResources.split(" ");
    const tileObservables = tilesList.map((el: any) => this.accessService.helpandaq(el));
  
    combineLatest(tileObservables).pipe(
      switchMap((results:any) => {
        this.mergedObject1 = results;
        const subtilesObservables = results.flatMap((result:any) => {
          const subtilesList = result.faqTopics.split(" ");
          return subtilesList.map((el: any) => this.accessService.helpandaq(el).pipe(
            map(result2 => ({ ...result2, parentId: result.uid }))
          ));
        });
        return combineLatest(subtilesObservables);
      })
    ).subscribe(results2 => {
      this.mergedObject2 = results2;
      this.cd.detectChanges();
      // console.log('The result of tiles is', this.mergedObject1);
      // console.log('The result of sub tiles is', this.mergedObject2);
    });
  }
  //when user searches something the following method will be useful for showing relavant searches
  applyFilter(mysearchvalue:any) { 
    if(mysearchvalue.length>0){
    let final =mysearchvalue.trim().toLowerCase(); 
    // this.searchvalue = final;
    // console.log('my final is',final);
    const filteredData1 = new HelpnfaqsearchPipe().transform(this.mergedObject2, final);
    if(this.filteredData){
      this.searchresults = true;
    }
    //when the search doesn't match or the user gives empty the no result will be opened
    if(final != " " && filteredData1.length<1){
  this.shownoresult=true;
    }
    else{
      this.shownoresult=false;
    }
    //iam assigning the filteredData1 to one variable so that i can use in html
    this.filteredData = filteredData1;
    // console.log(filteredData1);
    // console.log(filteredData1);
    return filteredData1
    // this.mergedObject2 = filteredData1;
  }
  else{
    this.shownoresult=false;
    this.searchresults = false;
  }
 }
//the following is to show all the accordions initally
  toggleDropdown(i: number, j: number) {
    const key = `${i}-${j}`;
    if (this.activeIndexes.has(key)) {
      this.activeIndexes.delete(key);
    } else {
      this.activeIndexes.add(key);
    }
  } isActive(i: number, j: number): boolean 
  {
    const key = `${i}-${j}`;
    return this.activeIndexes.has(key);
  }
//to show only the particualr accordion clicked by user
  // showAccordion(index: number) {
  //   this.activeAccordion = index; // Always show only the selected section
  //   this.seallbtn = true; // Show the "See All Topics" button
  // }
  showAccordion(index: number, myid: string) {
    this.activeAccordion = index; // Always show only the selected section
    this.seallbtn = true; // Show the "See All Topics" button
    // const div = event.target as HTMLElement;
    const div= <HTMLElement> document.getElementById(myid);
    // this.renderer.addClass(div, 'btn-active-class');
    if (this.activeDiv) {
      this.renderer.removeClass(this.activeDiv, 'btn-active-class');
    }
    if (div){
    this.renderer.addClass(div, 'btn-active-class');
    }
    this.activeDiv = div;
    this.cd.detectChanges();
  }
  //when clicking on SEE ALL TOPICS button to make every accordion appear again
  sealltopics() {
    this.activeAccordion = null; // Reset to show all accordions
    this.seallbtn = false; // Hide the "See All Topics" button
    this.renderer.removeClass(this.activeDiv, 'btn-active-class');
    this.cd.detectChanges();
  }
  //search functions
  toggleSearchAccordion(index: any) {     
    if (this.activeIndexes.has(index)) 
      {       
        this.activeIndexes.delete(index);     
      } else {       
        this.activeIndexes.add(index);     
      }   
    }   
    issearchActive(index: any){    
       return this.activeIndexes.has(index);  
       }
  //i wrote this for to show the results in accordions 
  toggleAccordion(item: any) {
    item.isOpen = !item.isOpen;
  }
  //for popup
  openModal() {
    this.isModalOpen = true;
    document.body.style.overflowY = 'auto';
    document.getElementById('commonleaving_abandonedPop')!.style.display = 'block';
  }
  closeModal() {
    this.isModalOpen = false;
    document.body.style.overflowY = 'auto';
    document.getElementById('commonleaving_abandonedPop')!.style.display = 'none';
  }
}
