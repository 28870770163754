<div class="product_common-box fluad-commonbox" *ngIf="choosefluad | async as item">
    <div class="container">
        <div class="product_common-box-container ">
            <div class="product_common-box-content">
                <div class="product_common-boxHeader" [innerHTML]="item.headLine"></div>
                <div [innerHTML]="item.textContent">
                </div>
                <a [routerLink]="item.linkUrl" class="product_box1-button">Order Now<img alt="Next screen"
                            src="../../../app/../assets/images/arrow-right-white.svg"></a>
            </div>
            <div class="product_common-box-Image1">
                <div class="simple-banner banner__component--responsive" *ngIf="item?.media">
                    <img class="js-responsive-image1 box" [attr.alt]="item.media?.altText" [attr.title]="item.media?.altText" [src]="getImage(item.media)">
                </div>
            </div>
            <div class="clearboth"></div>

            <div class="product_box1-disclaimer disclaimer--paragraph" [innerHTML]="item.bottomContent"></div>
        </div>
    </div>


