<div class="product_image-textCenter image-textCenter-real-world" *ngIf="fifthnode | async as item">
  <div class="simple-banner banner__component--responsive">
    <div class="simple-banner banner__component--responsive">
      <img
        class="js-responsive-image"
        [src]="getImage(item.media)" [attr.alt]="item.media?.altText" [attr.title]="item.media?.altText"
      />
    </div>
  </div>
  <div class="container">
    <div class="product_image-textcontainer">
      <div class="product_image-textHeader" [innerHTML]="item.headLine">
        >
      </div>
      <a
        [href]="getpdf(item.mediaPdf)"
        target="_blank"
        download=""
      >
        <span>{{item.pdfText}}</span
        ><img
          alt="Download"
          src="../../../assets/images/product_download_icon.svg"
      /></a>
    </div>
  </div>
</div>