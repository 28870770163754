import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialslandingComponent } from './materialslanding/materialslanding.component';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { MatTableModule } from '@angular/material/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    MaterialslandingComponent
  ],
  imports: [
    CommonModule,
    MatTableModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    ConfigModule.withConfig({
      cmsComponents: {
        MaterialsProductPageComponent : {
          component: MaterialslandingComponent ,
        }    
      }
    } as CmsConfig)
  ]
})
export class MaterialsukModule { }
