<cx-storefront></cx-storefront>

<div *ngIf="isMobileWidth" class="classIDVal" style="position: fixed; width: 100%; height: 100%; background-color: white; z-index: 10000; font-size: 25px;">
    <div style="top:50%;left:50%;position:absolute;transform:translate(-50%,-50%);width:100%;text-align:center;">Please maximize your window to view this content.</div>
    </div>

  
 
    <div id="onetrust-banner-sdk"  *ngIf="showcookiebottonbanner" class="otFlat otRelFont bottom ot-wo-title" tabindex="0" role="region" aria-label="Cookie banner" style="bottom: 0px;">
        <div role="dialog" aria-label="Privacy">
          <div class="ot-sdk-container">
            <div class="ot-sdk-row">
              <div id="onetrust-group-container" class="ot-sdk-eight ot-sdk-columns">
                <div class="banner_logo"></div>
                <div id="onetrust-policy">
                  <div id="onetrust-policy-text">
                    By clicking “Accept All Cookies”, you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts.
                  </div>
                </div>
              </div>
              <div id="onetrust-button-group-parent" class="ot-sdk-three ot-sdk-columns">
                <div id="onetrust-button-group">
                  <button id="onetrust-pc-btn-handler">Cookies Settings</button>
                  <button id="onetrust-accept-btn-handler">Accept All Cookies</button>
                </div>
              </div>
            </div>
          </div>
          <div id="onetrust-close-btn-container"></div>
        </div>
      </div>    
