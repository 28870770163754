import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ContentService } from '../../spartacus/services/content.service';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { first, Observable, take } from 'rxjs';
import { BaseSiteService } from '@spartacus/core';

@Component({
  selector: 'app-recent-order',
  templateUrl: './recent-order.component.html',
  styleUrl: './recent-order.component.scss'
})
export class RecentOrderComponent implements OnInit {
  // @ViewChild('')
  getdata:Observable<any> = this.userInfoService.getOrdersDashboard();
  currentSite: string="";
  rbavalue:any;

  public dummyData=[
    {
      'orderSeason':'2024-25','status':'Amendment Confirmed','orderID':'989979','shipToID':'SHiu76868','nameOne':"lkljjtest",'orderedProductDetails':[{'product':'abc','volume':'200'},{'product':'pmo','volume':'400'}],'addressDetail':{'addressLine1':'878 kjlj stree','addressLine2':'kjk test','city':'kjhj','state':"LO",'zipCode':'90899-909'},
    },
    {
      'orderSeason':'2024-25','status':'Amendment Pending','orderID':'88676','shipToID':'SH2368','nameOne':"new1",'orderedProductDetails':[{'product':'aQIV Dose Total','volume':'200'}],'addressDetail':{'addressLine1':'','addressLine2':'jdst','city':'kkh','state':"MK",'zipCode':'90899-7656'},
    },
    {
      'orderSeason':'2024-25','status':'Order Confirmed','orderID':'2359','shipToID':'SH00768','nameOne':"new2",'orderedProductDetails':[{'product':'QIVc Dose Total','volume':'200'},{'product':'lov','volume':'500'}],'addressDetail':{'addressLine1':'88876 test h','addressLine2':'tesb','city':'tersr','state':"MP",'zipCode':'243-909'},
    },
  ];

    constructor(private userInfoService: UserInfoService,private baseSiteService:BaseSiteService,private cdr:ChangeDetectorRef,) {}
    ngOnInit(): void {
      this.baseSiteService.getActive().subscribe(siteid => {
        this.currentSite = siteid;
        const body=<HTMLElement>document.querySelector("body");
        body.classList.remove('modal-open');
      })
      this.rbavalue=localStorage.getItem('rbapermission');
      this.rbavalue=JSON.parse(this.rbavalue);
      this.cdr.detectChanges(); 

      this.userInfoService.getswitchrba().subscribe(value => {
        if(value){
          this.rbavalue=value;
          this.cdr.detectChanges();
        }
      });
      
    }

    ngAfterViewInit() {
      const body=<HTMLElement>document.querySelector("body");
      body.classList.remove('modal-open')

        this.userInfoService.getrbaheader().subscribe(value => {
          if(value){
           // this.rbavarible=localStorage.getItem('rbapermission');
            this.rbavalue=JSON.parse(value);
            this.cdr.detectChanges();
          }
        });
      
      }
 
}
