import { Component, NgZone } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BaseSiteService, CmsBannerComponent, LanguageService, OccConfig, WindowRef } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable, catchError, distinctUntilChanged, take, tap, throwError } from 'rxjs';
import { GigyaService } from '../../spartacus/services/gigya';
import { ContentService } from '../../spartacus/services/content.service';
import { CdcConfig, CdcJsService } from '@spartacus/cdc/root';
import { GigyaRaasComponentData } from '@spartacus/cdc/core';

@Component({
  selector: 'app-homepage-uk-banner',
  templateUrl: './homepage-uk-banner.component.html',
  styleUrl: './homepage-uk-banner.component.scss'
})
export class HomepageUkBannerComponent {
  baseSite: any;
  protected renderScreenSet = true;
  language$: Observable<string> | undefined;
  jsError$: Observable<boolean> | undefined;
  jsLoaded$: Observable<boolean> | undefined;
  banners: any[] = [];

  constructor(public component: CmsComponentData<CmsBannerComponent>,
    private sv:ContentService,
    private gigya:GigyaService,
    private cdcJsService:CdcJsService,
    protected baseSiteService: BaseSiteService,
    protected config: OccConfig,
    ) {
  }
  bannerNode: Observable<any> = this.component.data$;
  
  getImage(media: any): string {
    return this.config.backend?.occ?.baseUrl + media.widescreen.url;
  }
  ngOnInit(){
    localStorage.removeItem("rbapermission");
    this.component.data$.subscribe((data) =>{
      this.banners = [data];
     
    })
  }
}
