// import { Component } from '@angular/core';
// import { Router } from '@angular/router';
// import { BaseSiteService } from '@spartacus/core';
// import { CmsComponentData } from '@spartacus/storefront';
// @Component({
//   selector: 'app-news-announcement-component',
//   templateUrl: './news-announcement-component.component.html',
//   styleUrl: './news-announcement-component.component.scss'
// })
// export class NewsAnnouncementComponentComponent {
//   largeparagraphcontent:string="";
//   headline:any;
//   startOrder:any;
//   contents:any[]=[];
//   jobbagcode: any;
//   currentSite: string="";
//   constructor(public component: CmsComponentData<any>, private router: Router,private baseSiteService:BaseSiteService) {
    
//   }

//   ngOnInit(){
    
//     this.baseSiteService.getActive().subscribe(site => {
//       this.currentSite = site;
//     });
//     this.component.data$.subscribe((data) =>{
//       this.largeparagraphcontent = data.largeparagraphcontent;
//       this.headline = data.headline;
//       this.startOrder = data.content;
//       this.jobbagcode = data.jobBagCode;

//     });
//   }
//   routerlinkClicked(event: any) {
//     event.preventDefault();
//     this.router.navigate([event.target.attributes['href'].value]);
//   }
// }

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseSiteService, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { UserInfoService } from '../../spartacus/services/user-info.service';

@Component({
  selector: 'app-news-announcement-component',
  templateUrl: './news-announcement-component.component.html',
  styleUrls: ['./news-announcement-component.component.scss']
})
export class NewsAnnouncementComponentComponent implements OnInit {
  largeparagraphcontent: string = "";
  headline: any;
  startOrder: any;
  contents: any[] = [];
  jobbagcode: any;
  currentSite: string = "";
  bannerdata: any;
  membershipDeclarationFLag: any;
  rbavalue:any;
  image:any;
  link: any;
  // membershipFlag: boolean = true; 

  constructor(public component: CmsComponentData<any>, private router: Router, private baseSiteService: BaseSiteService,private userInfoService: UserInfoService,private cdr:ChangeDetectorRef ,private config: OccConfig) { }


  ngAfterViewInit() {
      
 
  


  this.userInfoService.getrbaheader().subscribe(value => {
    if(value){
     // this.rbavarible=localStorage.getItem('rbapermission');
      this.rbavalue=JSON.parse(value);
      this.cdr.detectChanges();
    }
  });
}

  ngOnInit() {
    this.baseSiteService.getActive().subscribe(site => {
      this.currentSite = site;


      this.userInfoService.dashboardOrderData$.subscribe((data) => {
        data.subscribe((dta: any) => {
          this.bannerdata = dta;
       
          // Assuming the backend response contains membershipDeclaration flag
          this.membershipDeclarationFLag = dta.membershipDeclarationFLag;
         
          this.cdr.detectChanges();
        });
       
      });
       
          });

  

    this.component.data$.subscribe((data) => {
      this.largeparagraphcontent = data?.largeparagraphcontent;
      this.headline = data?.headline;
      this.startOrder = data?.content;
      this.jobbagcode = data?.jobBagCode;
      this.image = data?.media;
      this.link = data?.h3content;
      this.cdr.detectChanges();
      // this.membershipFlag = data.membershipFlag; // Set the flag based on data
    });
   if(this.currentSite=='seqirusb2b-uk'){
    this.rbavalue=localStorage.getItem('rbapermission');
    this.rbavalue=JSON.parse(this.rbavalue);
    this.cdr.detectChanges(); 

    this.userInfoService.getswitchrba().subscribe(value => {
      if(value){
        this.rbavalue=value;
        this.cdr.detectChanges();
      }
    });
  }
  }

  routerlinkClicked(event: any) {
    event.preventDefault();
    this.router.navigate([event.target.attributes['href'].value]);
  }
  getImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.desktop.url;
  }
}
