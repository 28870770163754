import { BaseSiteService, CmsBannerComponent } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { forkJoin, Observable, of } from 'rxjs';
import { ContentService } from '../../spartacus/services/content.service';
import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { StartOrderService } from '../../custom-start-order/start-order.service';
import { Router } from '@angular/router';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { ReturnsUkService } from '../returns-uk.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { ApiResponse,  ShipData,ReturnData,Product,GroupedProduct,GalleryImage } from '../location-data.model';
import { TemplateRef } from '@angular/core';
import { AfterViewInit } from '@angular/core';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { MatDialog } from '@angular/material/dialog';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { GalleryItem, ImageItem } from 'ng-gallery';
import { HttpClient } from '@angular/common/http';
import { FileUploadModalComponent } from '../file-upload-modal/file-upload-modal.component';
import { ReturnsExitPopupComponent } from '../returns-exit-popup/returns-exit-popup.component';
import { Overlay, NoopScrollStrategy } from '@angular/cdk/overlay';
interface LocationData {
  locationId: string;
  maxAqivQuantity: string;
  maxQivcQuantity: string;
  returnsProduct: ProductData[];
}

interface ProductData {
  batchNumber: string;
  selectedQuantity: number;
  totalQuantity: number;
  additionalQuantity: number;
  productName: string;
  poNumber: string;
}
@Component({
  selector: 'app-returns-additional',
  templateUrl: './returns-additional.component.html',
  styleUrl: './returns-additional.component.scss'
})
export class ReturnsAdditionalComponent {
  @ViewChild('fileUploadModal') fileUploadModal!: TemplateRef<any>;
  imageObject: Array<{ image: string, thumbImage: string }> = [];
  locationImages: { [key: string]: GalleryImage[] } = {}; // Store images by locationID
  images: { src: string, alt: string }[] = [];
  galleryImages: GalleryImage[] = [];
  selectedImage: GalleryImage | null = null;
  imageCount: number = 0;
  selectedIndex: number = 0;
  isVisible = false;
  locationId: any;
  adlReturnThanks:boolean = false;
  addtnlreturnSection:boolean = true;
  bannerNode: Observable<any> = this.component.data$;
  displayedColumns: string[] = ['locname', 'address', 'state','checked',];
  private subscription: Subscription | undefined;
  dataSource = new MatTableDataSource<LocationData>([]);
  returnsProductData: any;
  searchValue: string = '';
  selectedAddress: string | undefined;
  inputValueaQiv: string = '';
  inputValueQivc: string = '';
  formattedValue: string | null = '';
  formattedValueQivc: string | null = '';
  errMsg1:boolean = false;
  errMsg2:boolean = false;
  errMsg1qivc:boolean = false;
  errMsg2qivc:boolean = false;
  aqivMaxAllowedQty: any;
  qivcMaxAllowedQty: any;
  checkboxChecked: boolean = false; // Property to hold the checkbox state
  inputErrorMessage: string = ''; // Property to hold the input field error message
  checkboxErrorMessage: string = ''; 
  imgcheckboxChecked: boolean = false;
  imgcheckboxErrorMessage: string = ''; 
  selection = new SelectionModel<LocationData>(true, []);
  selectedRows: LocationData[] = [];
  selectedCount = 0;
  productsToReturn: any[] = [];
  uploadedImages: string[] = [];
  imageFiles: File[] = [];
  slideLeft: boolean = false;
  slideRight: boolean = false;
  locationImageFiles: { [key: string]: File[] } = {};
  errorMessages: { [key: string]: string } = {};
  checkboxStates: { [key: string]: boolean } = {};
  checkboxErrorMessages: { [key: string]: string } = {};
  showloader: boolean = false;
  showloader_return:boolean = true;
  showNewReturn: boolean = false;
  showProductReturn: boolean = false;
  showLocationSelection: boolean = true;
  additionalReturnData: any;
  maxDoseErrorMessage: string = '';
  batchDoseErrorMessages: { [batchNumber: string]: string } = {};
  productErrorMessage:string = '';
  defaultReturnSeason:string='';
  constructor(private overlay: Overlay,public component: CmsComponentData<CmsBannerComponent>,private http: HttpClient,public dialog: MatDialog, private cdr: ChangeDetectorRef, private router: Router, private startOrderService: StartOrderService, private userInfoService: UserInfoService, public contentService:ContentService, private baseSiteService:BaseSiteService,private returnsUkService:ReturnsUkService) {
   
  }


  
 
  ngOnInit() {
    this.returnsUkService.additionalReturnData$.subscribe(data => {
      if (data && data.returnsOrderData) {
        this.additionalReturnData = data;
       
        this.locationId = data.returnsOrderData.locationId; // Store the locationId  
        data.returnsOrderData.locations.forEach((location: any) => {
           location['aqiverrorsor']=false
         location['qivcerrorsor']=false
          location.returnsProduct.forEach((product: any) => {
            if(product.productName=='aQIV'){
             product['aqivbatcherror']=false
            }else{
              product['qivcbatcherror']=false
            }
            if (product.additionalQuantity === 0) {
              product.additionalQuantity = '';
            }
          });
        });
       // this.showloader = false;
        this.cdr.detectChanges();
      } else {  
        console.error('returnsOrderData is null or undefined');
      }
    });
    this.returnsUkService.getSeasons().subscribe(response => {
      this.defaultReturnSeason = response.defaultReturnSeason;
    });
    
  }
 
 

  

  ngAfterViewInit(): void {
    
    this.returnsUkService.currentVisibility.subscribe(visibility => {
      this.isVisible = visibility === 'D';
      this.scrollTotop();
      this.showLoaderForThreeSeconds();
      this.cdr.detectChanges();
    });
    
    
  }

  
  showLoaderForThreeSeconds() {
    this.showloader = true;
    setTimeout(() => {
      this.showloader = false;
      this.cdr.detectChanges();
    }, 1000);
  }
  
  scrollTotop(): void {
    // Call this method to scroll to the top
    this.contentService.scrollToTop();
  }

  
  getGroupedProductKeys(groupedProducts: { [key: string]: Product[] }): string[] {
    return Object.keys(groupedProducts);
  }
  formatNumber(value: string, product: Product, maxAllowedQty: number) {
    product.inputValueaQiv = value;
    const numericValue = value.replace(/\D/g, '');
    product.inputValueaQiv = numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  
    if (numericValue.length > 6) {
      product.formattedValue = '';
    } else {
      product.formattedValue = product.inputValueaQiv;
    }
  
    const numericQty = parseInt(numericValue, 10);
    if (numericQty > product.totalQuantity) {
      product.errMsg2 = true;
      product.errMsg1 = false;
    } else if (numericQty > maxAllowedQty) {
      product.errMsg1 = true;
      product.errMsg2 = false;
    } else {
      product.errMsg1 = false;
      product.errMsg2 = false;
    }
  }
  
  formatNumber1(value: string, product: Product, maxAllowedQty: number) {
    const numericValue = value.replace(/\D/g, '');
    product.inputValueQivc = numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  
    if (numericValue.length > 6) {
      product.formattedValueQivc = '';
    } else {
      product.formattedValueQivc = product.inputValueQivc;
    }
  
    const numericQty = parseInt(numericValue, 10);
    if (numericQty > product.totalQuantity) {
      product.errMsg2qivc = true;
      product.errMsg1qivc = false;
    } else if (numericQty > maxAllowedQty) {
      product.errMsg1qivc = true;
      product.errMsg2qivc = false;
    } else {
      product.errMsg1qivc = false;
      product.errMsg2qivc = false;
    }
  }
 
 
  
  
  restrictInput(event: KeyboardEvent) {
    const inputChar = String.fromCharCode(event.charCode);
    if (!/^\d+$/.test(inputChar)) {
      event.preventDefault();
    }
  }

  
 
  onCheckboxChange() {
    if (this.checkboxChecked) {
      this.checkboxErrorMessage = ''; // Clear the error message when checkbox is checked
    }
    else{
      this.checkboxErrorMessage = 'Please confirm to proceed';
    }
  }
  imgonCheckboxChange() {
    if (this.imgcheckboxChecked) {
      this.imgcheckboxErrorMessage = ''; // Clear the error message when checkbox is checked
    }
    else{
      this.imgcheckboxErrorMessage = 'Please either attach images or select the checkbox for manual inspection.';
    }
  }

  
  openFileUploadModal(locationID: string): void {
    const dialogRef = this.dialog.open(FileUploadModalComponent, {
      width: '800px',
      backdropClass: 'custom-backdrop',
      disableClose: true, // Disable closing the modal by clicking outside
      data: { locationID } // Pass the locationID to the modal
    });

    dialogRef.componentInstance.imagesUploaded.subscribe((data: { locationID: string, images: GalleryImage[], files: File[] }) => {
      this.locationImages[data.locationID] = data.images;
      this.locationImageFiles[data.locationID] = data.files;
      this.cdr.detectChanges(); // Trigger change detection
    });

    dialogRef.componentInstance.imageCountUpdated.subscribe((data: { locationID: string, count: number }) => {
      this.cdr.detectChanges(); // Trigger change detection
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  getUploadedImageCount(locationID: string): number {
    const count = this.locationImages[locationID] ? this.locationImages[locationID].length : 0;
    
    // Check if the count is more than 0 and update the checkbox state
    
    
  
    return count;

  }
  
  // Add properties to track the checkbox state and disabled state

  imgcheckboxDisabled: boolean = false;




  generatePayload(locationData: LocationData): any {
    return {
      locationId: this.locationId || '',
      aqivMaxAllowedQuantity: locationData.maxAqivQuantity ? locationData.maxAqivQuantity.toString() : '',
      qivcMaxAllowedQuantity: locationData.maxQivcQuantity ? locationData.maxQivcQuantity.toString() : '',
      productsRefund: locationData.returnsProduct ? locationData.returnsProduct.map((product: ProductData) => ({
        batchNumber: product.batchNumber || '',
        initialQuantity: product.selectedQuantity ? product.selectedQuantity.toString() : '',
        totalQuantity: product.totalQuantity ? product.totalQuantity.toString() : '',
        additionalQuantity: product.additionalQuantity ? product.additionalQuantity.toString() : '',
        productName: product.productName || '',
         poNumber: product.poNumber || ''
      })) : []
    };
  }
  

  getInitialQuantity(product: any): number {
    if (product.productName === 'aQIV') {
      return product.inputValueaQiv ? parseInt(product.inputValueaQiv, 10) : 0;
    } else if (product.productName === 'QIVc') {
      return product.inputValueQivc ? parseInt(product.inputValueQivc, 10) : 0;
    }
    return 0; // Default value if no input is provided
  }

  generateAllPayloads(response: any): any {
    const locationsData: LocationData[] = response.returnsOrderData.locations;
    return {
      season: response.season, // Replace with actual season value
      additionalReturnPresent: true,
      locations: locationsData.map(locationData => this.generatePayload(locationData))
    };
  }


  processAndSendPayloads(): void {
    if (!this.validateAll()) {
      return;
    }
  this.showloader = true; // Show loader
  
    // Use the API response data to generate the payload
    this.returnsUkService.additionalReturnData$.subscribe(data => {
      this.additionalReturnData = data;
      const payload = this.generateAllPayloads(this.additionalReturnData);
  
      // First, submit images
      this.submitImages().subscribe(
        () => {
          // After successful image upload, submit the payload
          this.returnsUkService.submitReturns(payload).subscribe(response => {

            if(response=='FAILURE' || response== 'failure' || response=='Failure' ){
              this.returnsUkService.setReturnFailurePopup(true);
              this.adlReturnThanks = false;
              this.addtnlreturnSection = false;
              this.showloader = false; // Hide loader
              this.scrollTotop();
              this.cdr.detectChanges();
            }else{
            this.adlReturnThanks = true;
            //To hide job bag code in returns thankyou page
            const bodyslot=document?.querySelector('.Section8-returnsLandingPage') as HTMLElement;
            bodyslot?.setAttribute("style","display:none");
            //To retuns banner in returns thankyou page
            const bodyslot1=document?.querySelector('.Section3-returnsLandingPage') as HTMLElement;
            bodyslot1?.setAttribute("style","display:none");
            // To reduce space between top header alone in returns thankyou page  
            const bodyslot2=document?.querySelector('.UkCreateReturn') as HTMLElement;
            bodyslot2?.setAttribute("style","margin-top:10px");
            //To reduce padding in pi banner in retun thankyou page
            const bodyslot3=document?.querySelector('.PibannerUk') as HTMLElement;
            bodyslot3?.setAttribute("style","padding: 10px 40px;"); 
            this.addtnlreturnSection = false;
            this.showloader = false; // Hide loader
            this.scrollTotop();
            this.cdr.detectChanges();
            }
          }, error => {
            console.error('Payload Submission Error:', error);
            this.showloader = false; // Hide loader
            this.adlReturnThanks = false;
            this.returnsUkService.setReturnFailurePopup(true);
            this.addtnlreturnSection = false;
            this.scrollTotop();
            this.cdr.detectChanges();
          });
        },
        error => {
          console.error('Image Upload Error:', error);
          this.adlReturnThanks = true;
          //To hide job bag code in returns thankyou page
          const bodyslot=document?.querySelector('.Section8-returnsLandingPage') as HTMLElement;
          bodyslot?.setAttribute("style","display:none");
          //To retuns banner in returns thankyou page
          const bodyslot1=document?.querySelector('.Section3-returnsLandingPage') as HTMLElement;
          bodyslot1?.setAttribute("style","display:none");
          // To reduce space between top header alone in returns thankyou page  
          const bodyslot2=document?.querySelector('.UkCreateReturn') as HTMLElement;
          bodyslot2?.setAttribute("style","margin-top:10px");   
          //To reduce padding in pi banner in retun thankyou page
          const bodyslot3=document?.querySelector('.PibannerUk') as HTMLElement;
          bodyslot3?.setAttribute("style","padding: 10px 40px;");  
          this.addtnlreturnSection = false;
          this.showloader = false; // Hide loader
          this.scrollTotop();
          this.cdr.detectChanges();
        }
      );
    });
  }
  
  submitImages(): Observable<any> {
    const uploadObservables = [];
   
    for (const locationID in this.locationImageFiles) {
      if (this.locationImageFiles.hasOwnProperty(locationID)) {
        const imageFormData = new FormData();
        imageFormData.append('locationsId', locationID);
        imageFormData.append('season', this.defaultReturnSeason);
        this.locationImageFiles[locationID].forEach((file: File, index: number) => {
          imageFormData.append('registration_uploadfile', file, file.name);
        });
        uploadObservables.push(this.returnsUkService.submitImages(imageFormData));
      }
    }
  
    // If there are no images to upload, return an observable that completes immediately
    if (uploadObservables.length === 0) {
      return of(null);
    }
  
    // Return an observable that completes when all image uploads are done
    return forkJoin(uploadObservables);
  }

getLocationsData(): any[] {
  return this.productsToReturn.map(returnData => returnData.returnsProductData.shipData).flat();
}

validateAll(): boolean {
  let isValid = true;
  this.errorMessages = {}; // Clear previous error messages
  this.checkboxErrorMessages = {}; // Clear previous error messages
  this.checkboxErrorMessage = ''; // Clear single checkbox error message
  this.productErrorMessage = ''; // Clear combined product error message

  for (const returnData of this.productsToReturn) {
    for (const shipData of returnData.returnsProductData.shipData) {
      let hasInput = false;
      for (const productName of this.getGroupedProductKeys(shipData.groupedProducts)) {
        if (productName === 'aQIV' || productName === 'QIVc') {
          for (const product of shipData.groupedProducts[productName]) {
            if (product.inputValueaQiv || product.inputValueQivc) {
              hasInput = true;
              break;
            }
          }
        }
      }
      if (!hasInput) {
        this.errorMessages[shipData.locationID] = 'Please fill in at least one input field.';
        isValid = false;
      }

      const imageCount = this.getUploadedImageCount(shipData.locationID);
      if (imageCount === 0 && !this.checkboxStates[shipData.locationID]) {
        this.checkboxErrorMessages[shipData.locationID] = 'Please check the checkbox or upload an image.';
        isValid = false;
      }
    }
  }

  // Validate the single checkbox
  if (!this.checkboxChecked) {
    this.checkboxErrorMessage = 'Please confirm to proceed';
    isValid = false;
  }

  // Validate the single checkbox only if image count is 0
  if (this.getUploadedImageCount(this.additionalReturnData.returnsOrderData.locationId) === 0 && !this.imgcheckboxChecked) {
    this.imgcheckboxErrorMessage = 'Please either attach images or select the checkbox for manual inspection.';
    isValid = false;
  }

  // Validate aQIV and QIVc input fields
  const aqivProducts = this.additionalReturnData.returnsOrderData.locations[0].returnsProduct.filter((product: any) => product.productName === 'aQIV');
  const qivcProducts = this.additionalReturnData.returnsOrderData.locations[0].returnsProduct.filter((product: any) => product.productName === 'QIVc');

  const isAqivValid = aqivProducts.some((product: any) => product.additionalQuantity && product.additionalQuantity > 0);
  const isQivcValid = qivcProducts.some((product: any) => product.additionalQuantity && product.additionalQuantity > 0);

  if (!isAqivValid && !isQivcValid) {
    this.productErrorMessage = 'Please enter the doses to return';
    isValid = false;
  }
  if(this.aqivErrorMessage || this.qivcErrorMessage || this.additionalReturnData.returnsOrderData.locations[0].aqiverrorsor || this.additionalReturnData.returnsOrderData.locations[0].qivcerrorsor){
    isValid = false;
  }

  this.cdr.detectChanges(); // Trigger change detection
  return isValid;
}

dynImageCheckboxChecked: boolean = false;
dynImageCheckboxErrorMessage: string = '';
aqivErrorMessage: string = '';
qivcErrorMessage: string = '';

updateTotals(product:any): void {
  if (this.additionalReturnData && this.additionalReturnData.returnsOrderData) {
    let totalAqivQuantity = 0;
    let totalQivcQuantity = 0;
    //let aqivBatchErrorMessage = '';
    //let qivcBatchErrorMessage = '';

   // this.additionalReturnData.returnsOrderData.locations[0].returnsProduct.forEach((product: any) => {
      product.totalDosesReturned = this.calculateTotalDosesReturned(product);

      if (product.productName === 'aQIV') {
        totalAqivQuantity += Number(product.totalDosesReturned);
        const additionalQuantity = Number(product.additionalQuantity)
        // Check if total doses returned for each batch number exceeds totalQuantity
        if ( Number(product.additionalQuantity) > Number(product.totalQuantity)) {
         // aqivBatchErrorMessage = 'You have exceeded the number of doses in this batch. Please correct.';
         product.aqivbatcherror=true
        }else{
          product.aqivbatcherror=false
        }
        // Check if total aQIV quantity exceeds maxAqivQuantity
        
      } else if (product.productName === 'QIVc') {
        totalQivcQuantity += Number(product.totalDosesReturned);
        const additionalQuantity = Number(product.additionalQuantity)
        // Check if total doses returned for each batch number exceeds totalQuantity
        if ( Number(product.additionalQuantity) > Number(product.totalQuantity)) {
         // qivcBatchErrorMessage = 'You have exceeded the number of doses in this batch. Please correct.';
         product.qivcbatcherror=true
        }
        // Check if total QIVc quantity exceeds maxQivcQuantity
        else {
          product.qivcbatcherror=false
        }
      }
   // });
     // Clear the combined error message if any valid input is detected
     const isAqivValid = this.additionalReturnData.returnsOrderData.locations[0].returnsProduct.some((product: any) => product.productName === 'aQIV' && product.additionalQuantity && product.additionalQuantity > 0);
     const isQivcValid = this.additionalReturnData.returnsOrderData.locations[0].returnsProduct.some((product: any) => product.productName === 'QIVc' && product.additionalQuantity && product.additionalQuantity > 0);
 
     if (isAqivValid || isQivcValid) {
       this.productErrorMessage = '';
     }
    this.cdr.detectChanges();
  }
  
}

isQivcQuantityZero(): boolean {
  return this.additionalReturnData.returnsOrderData.locations[0].maxQivcQuantity === "0";
}

ismaxAqivQuantityZero(): boolean {
  return this.additionalReturnData.returnsOrderData.locations[0].maxAqivQuantity === "0";
}


calculateTotalDosesReturned(product: any): number {
  const selectedQuantity = Number(product.selectedQuantity) || 0;
  const additionalQuantity = Number(product.additionalQuantity) || 0;
  return selectedQuantity + additionalQuantity;
}

calculateTotalSelectedDoses(productName: string): number {
  return this.additionalReturnData.returnsOrderData.locations[0].returnsProduct
    .filter((product: any) => product.productName === productName)
    .reduce((sum: number, product: any) => sum + (Number(product.selectedQuantity) || 0), 0);
}

calculateTotalAdditionalDoses(productName: string): number {
  let totaladditional=
   this.additionalReturnData.returnsOrderData.locations[0].returnsProduct
    .filter((product: any) => product.productName === productName)
    .reduce((sum: number, product: any) => sum + (Number(product.additionalQuantity) || 0), 0);

    if(productName=='aQIV'){
       if(Number(totaladditional) > Number(this.additionalReturnData.returnsOrderData.locations[0].maxAqivQuantity)){
        this.additionalReturnData.returnsOrderData.locations[0].aqiverrorsor=true;
       }else{
        this.additionalReturnData.returnsOrderData.locations[0].aqiverrorsor=false;
       }

     
    }else{
      if(Number(totaladditional) > Number(this.additionalReturnData.returnsOrderData.locations[0].maxQivcQuantity)){
        this.additionalReturnData.returnsOrderData.locations[0].qivcerrorsor=true;
       }else{
        this.additionalReturnData.returnsOrderData.locations[0].qivcerrorsor=false;
       }
    }
    
    if(productName=='aQIV'){
      if( this.additionalReturnData.returnsOrderData.locations[0].returnsProduct.find((check: any) => {
        return check.aqivbatcherror == true;
      }) ){
        this.aqivErrorMessage='You have exceeded the number of doses in this batch. Please correct.'
      }else{
        this.aqivErrorMessage=''
      }
    }else{
      if( this.additionalReturnData.returnsOrderData.locations[0].returnsProduct.find((check: any) => {
        return check.qivcbatcherror == true;
      }) ){
        this.qivcErrorMessage='You have exceeded the number of doses in this batch. Please correct.'
      }else{
        this.qivcErrorMessage=''
      }

    }
    
    return totaladditional
}

calculateTotalDoses(productName: string): number {
  return this.calculateTotalSelectedDoses(productName) + this.calculateTotalAdditionalDoses(productName);
}

hasProduct(productName: string): boolean {
  return this.additionalReturnData.returnsOrderData.locations[0].returnsProduct.some((product: any) => product.productName === productName);
}

openExistpopup(): void {

  const dialogRef = this.dialog.open(ReturnsExitPopupComponent, {
    scrollStrategy: this.overlay.scrollStrategies.noop(),
    width: '700px',
    disableClose: false,
    hasBackdrop: true,
    autoFocus: true,
    restoreFocus: true,
    backdropClass: 'custom-backdrop'
  });
  
}
goToreturn(): void {
  window.location.reload();
}

}
