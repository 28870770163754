<div class="breadcrumbs--page-nav hidden-xs hidden-sm d-none d-md-block" *ngIf="!userLoggedIn">
    <div class="container clinical-fluad-breadcrumbs">
        <p><a [routerLink]="'/'">Home</a><span> &gt; </span><a [routerLink]="'/products'">Products</a><span> &gt; </span><strong>Clinical
                Data</strong></p>
    </div>
</div>
<div class="clinical-fluad-first">
    <div class="container hidden-xs hidden-sm first-container" *ngIf=" firstheadline | async as item">
        <div class="clincialfirstBox">
            <div class="clinicalBoxleft">
                <div class="trivalent-formulation"></div>
                <h1 class="product_clinical-header" [innerHTML]="item.headLine"></h1>
                <div class="product_clinical-text" [innerHTML]="item.subHeadLine"></div>
            </div>
            <div class="clinicalBoxright" *ngIf="item.mediaPicture">
                <img [src]="getImage(item.mediaPicture)">
            </div>
        </div>
    </div>
    <div class="first-container-mobile container hidden-md hidden-lg" *ngIf=" firstheadline | async as item">
        <div class="trivalent-formulation"></div>
        <div class="clincialfirstBox">
            <div class="clinicalBoxleft">
                <h1 class="product_clinical-header" [innerHTML]="item.headLine"></h1>
            </div>
            <div class="clinicalBoxright" *ngIf="item.mediaPicture">
                <img [src]="getImage(item.mediaPicture)" >
            </div>
        </div>
        <div class="product_clinical-text" [innerHTML]="item.subHeadLine"></div>
    </div>
    <div id="FluadClincialData">
        <div class="mobcontainer container">
            <div class="BoostedClinical-tabContainer" id="BoostedClincal-tabContainer1">
                <div class="panel with-nav-tabs panel-primary">
                    <div class="panel-heading">
                        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" animationDuration="0ms"
                            class="clinical-fluad-tabs">
                            <div *ngFor="let item2 of mergedObject1; let i= index">
                                <mat-tab label="{{item2.headLine}}">
                                    <div class="wrapper">
                                        <div class="panel-body-text col-xs-12" [innerHTML]="item2.subHeadLine"></div>
                                        <div class="panel_body-Image col-xs-12">
                                            <div class="simple-banner banner__component--responsive graph">
                                                <img class="js-responsive-Image graph" [src]="gettabsImage(item2.media)" [attr.alt]="item2.media?.altText">
                                                    <!-- [src]="i === 0 ? '/assets/images/Antibody-Production_Desktop.webp' : '/assets/images/Boosted_HA-Inhib_Desktop.webp' -->
                                            </div>
                                            <div class="simple-banner-mobile banner__component--responsive-mobile">
                                                <img class="js-responsive-image-mobile" [src]="gettabsMobileImage(item2.media)" [attr.alt]="item2.media?.altText">
                                            </div>
                                        </div>
                                        <div class="panel_body-bottom col-xs-12">
                                            <div class="bottomtext1" [innerHTML]="item2.text1Content"></div>
                                            <div class="bottomtext2 text2pad" [innerHTML]="item2.text2Content"></div>
                                            <div class="bottomtext3" [innerHTML]="item2.text3Content + item2.text4Content"></div>
                                        </div>
                                    </div>
                                </mat-tab>
                            </div>
                        </mat-tab-group>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>