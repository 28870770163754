<div class="container" *ngIf="topheadline | async  as item">
    <div class="col-sm-12 text-center">
        <h2 class="head-line">{{item.headLine}}</h2>
        <p class="text-center top-text">{{item.Text1}}</p>
        <div class="row-flex large-carousel-desktop-row">
        </div>
    </div>
</div>
<div class="container supply_chain_carousel_container">
    <div id="custom-Carousel" class="carousel slide desktop-carousel supply_chain_destop_carousel" data-bs-ride="carousel">
        <div class="large--carousel-btn-container">
            <button class="custom-carousel-control-btn custom-carousel-control-prev" type="button"
                (click)="goprevious()">
                <span class="custom-icon prev-icon">
                    <img alt="Navigate to previous arrow" src="assets/images/prev-btn.svg">
                </span>
            </button>
            <button class="custom-carousel-control-btn custom-carousel-control-next" type="button" (click)="gonext()">
                <span class="custom-icon next-icon">
                    <img alt="Navigate to next arrow" src="assets/images/next-btn.svg">
                </span>
            </button>
        </div>
        <div class="carousel-inner">
            <div *ngFor="let item of mergedObject1; let i = index" class="carousel-item1 supply_chain_carousel"
                [ngClass]="{'active': activeIndex1 === i}">
                <div class="row slide--content d-flex">
                    <div class="col-lg-4 col-md-6 col-sm-12 left-column">
                        <div class="h2" [innerHTML]="item.headline"></div>
                        <p>{{item.largeparagraphcontent}}</p>
                        <!-- <a (click)="downloadFile()">{{item.h2content}}</a> -->
                        <a class="downloadLink" [href]="getpdf(item.pdfMedia)">{{item.h2content}}<img alt="Download"
                                src="../../../app/../assets/images/download.png"></a>
                        <a class="navigateLink" href="{{item.urlLink}}" target="_blank">{{item.h2content}}<img
                                alt="Next screen" src="../../../app/../assets/images/blackarrow.png"></a>
                    </div>
                    <div class="col-lg-8 col-md-6 col-sm-12 right-column">
                        <img [src]="getImage(item.media)" [attr.alt]="item.media?.altText" class="d-block w-100">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>