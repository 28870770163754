<!--Loggedin header section -->
<div class="return_form_topheader_loggedin" *ngIf="userLoggedin && userInfo?.webEnabled">
    <div class="returns_form_topheadline_loggedin">{{usReturnsData.headLine1}}</div>       
</div>
<div class="returns-main-section">
    <div *ngIf="!userLoggedin || (userLoggedin && !userInfo?.webEnabled)" class="breadcrumbs--page-nav">
        <span class="returns-breadcrumb"><a [routerLink]="'/'">Home </a> ><span class="active_page"> Returns</span></span>
    </div>
    <div  class="product_return_form_container">
        <!--header section-->
        <div *ngIf="!userLoggedin || (userLoggedin && !userInfo?.webEnabled)" class="return_form_topheader">
            <img alt="angled corner" src="../../../app/../assets/images/rectangle-down.svg" class="rectangle-down">
            <div class="returns_form_topheadline">{{usReturnsData.headLine1}}</div>   
        </div>
        
        <!--download blank returns section-->
        <div class="download_blank_returns_section">
            <div  class="return_vaccine_question">
                <div class="return_form_headLine2">{{usReturnsData.headLine2}}</div>
                <div class="return_download_form_section">
                   <a [href]="getpdf(usReturnsData.SeqReturnPdf)" target="_blank" class="blank_returns_pdf_link"> <div class="download_text_return_form">Download Blank Returns Form</div>
                <img alt="Download" src="../../../app/../assets/images/download.png"></a>
                </div>
            </div>
            <div class="download_sub_text_section"><span class="return_form_headline3">{{usReturnsData.headLine3}}<br/></span><span class="returns_subText1">{{usReturnsData.headLine4 }}</span></div>
        </div>
 
        <div *ngIf="!userLoggedin" class="login_section">
            <div class="return_form_login_left_setion">
                <div class="return_automatic_download_text">{{usReturnsData.subText1}}</div>
            </div>
           <button class="global_redbtn" (click)="onLoginClick()">Log In</button>
        </div>
 
        <form class="usReturnsForm" id="usReturnsForm" [formGroup]="usReturnsForm" (ngSubmit)="validateFormAndGeneratePdf()">
            
        <div class="returns_purchase_information">Purchase Information</div>
       
            <div class="group-gap">
                <h5 class="mb-r3">How was product purchased?</h5>
                <div class="d-block" [ngClass]="{'has-error':isFormSubmitted && usReturnsForm.get('purchaseFrom')?.invalid}">
                    
                    <div class="custom-control custom-radio purchase_products_radiobtn">
                        <label class="returns_radio">
                            <input id="csl" type="radio" class="custom-control-input returnsform_radiobtn" value="csl" name="purchaseFrom" (change)="onRadioChange($event)" formControlName="purchaseFrom">
                            <span  class="returns_radiobtncontrol returns_form_radiobtncontrol"></span>
                            <label class="returns-radio_label" for="custom-control-label">Direct from CSL Seqirus</label>
                        </label>
                    </div>
                    <div class="custom-control custom-radio purchase_products_radiobtn">
                        <label class="returns_radio">
                            <input id="wholesaler" type="radio" class="custom-control-input returnsform_radiobtn" value="wholesaler" name="purchaseFrom" (change)="onRadioChange($event)" formControlName="purchaseFrom">
                            <span  class="returns_radiobtncontrol returns_form_radiobtncontrol"></span>
                            <label class="returns-radio_label" for="wholesaler">Wholesaler</label>
                        </label>
                    </div>
                    <label *ngIf="isFormSubmitted && usReturnsForm.get('purchaseFrom')?.hasError('required')" id="usReturnsForm-purchaseForm-error" class="error has-error help-block radio_btn_error_label" for="usReturnsForm-purchaseFrom">Please select how your product was purchased.</label>
                </div>
            </div>

            <div *ngIf="usReturnsForm.get('wholesalerName')" class="col-lg-6 col-sm-12 col-xs-12 no-padding-left margin-B10 form-group has-feedback usReturns_wholesalerName" [ngClass]="{'has-error':(isFormSubmitted || usReturnsForm.get('wholesalerName')?.touched) && usReturnsForm.get('wholesalerName')?.invalid}">
                <label class="registration_inputLabel" for="usReturnsForm-wholesalerName">
                    Wholesaler Name*
                </label>
                <input class="usreturns_input form-control" (blur)="validateField('wholesalerName')"  (focus)="clearError('wholesalerName')" id="usReturnsForm-wholesalerName" name="wholesalerName"
                    autocomplete="off" formControlName="wholesalerName"/>
                    <span *ngIf="(isFormSubmitted || usReturnsForm.get('wholesalerName')?.touched) && usReturnsForm.get('wholesalerName')?.invalid" class='glyphicon glyphicon-exclamation-sign form-control-feedback'></span>
                    <label *ngIf="(isFormSubmitted || usReturnsForm.get('wholesalerName')?.touched) && usReturnsForm.get('wholesalerName')?.hasError('required')" id="usReturnsForm-wholesalerName-error" class="error has-error help-block" for="usReturnsForm-wholesalerName">Wholesaler Name is required</label>
            </div>
            <div class="clearboth"></div>
            <div class="returns_user_Section">
            <div class="col-lg-6 col-sm-12 col-xs-12 no-padding-left margin-B10 form-group has-feedback usReturns_email" [ngClass]="{'has-error':(isFormSubmitted || usReturnsForm.get('email')?.touched) && usReturnsForm.get('email')?.invalid}">
                <label class="registration_inputLabel" for="usReturnsForm-email">
                    CUSTOMER EMAIL ADDRESS*
                </label>
                <input class="usreturns_input form-control" (blur)="validateField('email')"  (focus)="clearError('email')" id="usReturnsForm-email" name="emailAddress"
                    autocomplete="off" formControlName="email"/>
                    <span *ngIf="(isFormSubmitted || usReturnsForm.get('email')?.touched) && usReturnsForm.get('email')?.invalid" class='glyphicon glyphicon-exclamation-sign form-control-feedback'></span>
                    <label *ngIf="(isFormSubmitted || usReturnsForm.get('email')?.touched) && usReturnsForm.get('email')?.invalid" id="usReturnsForm-email-error" class="error has-error help-block" for="usReturnsForm-email">Please enter a valid email address</label>
                   
            </div>
            <div class="clearboth"></div>
            <!-- <div class="padding-R15"> -->
                <div class="col-lg-3 col-sm-12 col-xs-12 no-padding margin-B10 has-feedback form-group usReturns_phone" [ngClass]="{'has-error':(isFormSubmitted || usReturnsForm.get('phone')?.touched) && usReturnsForm.get('phone')?.invalid}">
                    <label class="registration_inputLabel" for="usReturnsForm-phone">
                        PHONE*
                    </label>
                    <input class="usreturns_input form-control" (blur)="validateField('phone')" (focus)="clearError('phone')" id="usReturnsForm-phone" name="phoneNumber"  [mask]="mask" [dropSpecialCharacters]="false" (focus)="onMouseEnterPhn()" (blur)="onMouseLeavePhn()" (mouseenter)="onMouseEnterPhn()" (mouseleave)="onMouseLeavePhn()" [dropSpecialCharacters]="false" [showMaskTyped]="true" 
                        autocomplete="off" formControlName="phone"/>
                    <span *ngIf="(isFormSubmitted || usReturnsForm.get('phone')?.touched) && usReturnsForm.get('phone')?.invalid" class='glyphicon glyphicon-exclamation-sign form-control-feedback'></span>
                    <label *ngIf="usReturnsForm.get('phone')?.touched && usReturnsForm.get('phone')?.hasError('pattern') && !usReturnsForm.get('phone')?.hasError('required')" id="usReturnsForm-phnNumber-error" class="error has-error help-block" for="usReturnsForm-phnNumber">Please enter valid 10 digit phone number</label>
                    <label *ngIf="(isFormSubmitted || usReturnsForm.get('phone')?.touched) && !usReturnsForm.get('phone')?.hasError('pattern') && usReturnsForm.get('phone')?.hasError('required')" id="usReturnsForm-phnNumber-error" class="error has-error help-block" for="usReturnsForm-phnNumber">Phone is required</label>
    
                </div>
                <div class="clearboth"></div>
            <!-- </div> -->

            <div class="col-lg-12 col-sm-12 col-xs-12 no-padding-left margin-B10 form-group has-feedback usReturns_poNum" [ngClass]="{'has-error':(isFormSubmitted || usReturnsForm.get('poNum')?.touched) && usReturnsForm.get('poNum')?.invalid}">
                
                <label class="registration_inputLabel" for="usReturnsForm-poNum">
                    Debit Memo/PO#* <i class="fa-regular fa-circle-question returns_ponum_tooltip"><span class="returns_ponum_tooltiptext">A debit memo or PO# is the reference number used at the time the return is initiated. Create one of your choosing or autogenerate a number by clicking "Autogenerate PO" at the right of the text field. This will be the number referenced in the PO field on your credit.</span></i>  
                </label>
                <div class="return_form_autogenerate_field">
                    
                <input class="col-lg-6 usreturns_input form-control" maxlength="20" (blur)="validateField('poNum')"  (focus)="clearError('poNum')" id="usReturnsForm-poNum" name="poNum"
                    autocomplete="off" formControlName="poNum"/>
                
                    <span *ngIf="(isFormSubmitted || usReturnsForm.get('poNum')?.touched) && usReturnsForm.get('poNum')?.invalid" class='glyphicon glyphicon-exclamation-sign form-control-feedback'></span>

                <div class="returns_POautogenerate" (click)="handleOnAutoGeneratePoNo()">Autogenerate PO</div>
                
            </div>
            
                <label *ngIf="(isFormSubmitted || usReturnsForm.get('poNum')?.touched) && usReturnsForm.get('poNum')?.hasError('required')" id="usReturnsForm-poNum-error" class="error has-error help-block" for="usReturnsForm-poNum">Please enter a valid debit memo/PO#</label>
            </div>
            <div class="clearboth"></div>

            <div *ngIf="!userLoggedin || (userLoggedin && !userInfo?.webEnabled)" class="col-lg-6 col-sm-12 col-xs-12 no-padding-left margin-B10 form-group has-feedback usReturns_orgName" [ngClass]="{'has-error':(isFormSubmitted || usReturnsForm.get('orgName')?.touched) && usReturnsForm.get('orgName')?.invalid}">
                <label class="registration_inputLabel" for="usReturnsForm-orgName">
                    Organization's Name*
                </label>
                <input class="usreturns_input form-control usreturns-orgname" id="usReturnsForm-orgName" (blur)="validateField('orgName')"  (focus)="clearError('orgName')" name="orgName"
                    autocomplete="off" formControlName="orgName"/>
                    <span *ngIf="(isFormSubmitted || usReturnsForm.get('orgName')?.touched) && usReturnsForm.get('orgName')?.invalid" class='glyphicon glyphicon-exclamation-sign form-control-feedback'></span>
                    <label *ngIf="usReturnsForm.get('orgName')?.touched && usReturnsForm.get('orgName')?.hasError('pattern')" id="usReturnsForm-orgName-error" class="error has-error help-block" for="usReturnsForm-orgName">Organization's Name is required</label>
                    <label *ngIf="(isFormSubmitted || usReturnsForm.get('orgName')?.touched) && usReturnsForm.get('orgName')?.hasError('required')" id="usReturnsForm-orgName-error" class="error has-error help-block" for="usReturnsForm-orgName">Organization's Name is required</label>
            </div>
            <div class="col-lg-12 col-sm-12 col-xs-12 no-padding-left no-padding-right">
            <div *ngIf= "usReturnsForm.get('purchaseFrom')?.value != 'wholesaler'" class="col-lg-6 col-sm-12 col-xs-12 no-padding-left margin-B10 form-group has-feedback usReturns_soldToNum" [ngClass]="{'has-error':(isFormSubmitted || usReturnsForm.get('soldToNum')?.touched) && usReturnsForm.get('soldToNum')?.invalid}">
                <label class="registration_inputLabel" for="usReturnsForm-soldToNum">
                    CSL Seqirus Sold to Account #*
                </label>
                <input class="usreturns_input form-control" id="usReturnsForm-soldToNum" (blur)="validateField('soldToNum')" (focus)="clearError('soldToNum')" name="soldToNum"
                    autocomplete="off" formControlName="soldToNum"/>
                    <span *ngIf="(isFormSubmitted || usReturnsForm.get('soldToNum')?.touched) && usReturnsForm.get('soldToNum')?.invalid" class='glyphicon glyphicon-exclamation-sign form-control-feedback'></span>
                    <label *ngIf="(isFormSubmitted || usReturnsForm.get('soldToNum')?.touched) && usReturnsForm.get('soldToNum')?.invalid" id="usReturnsForm-soldToNum-error" class="error has-error help-block" for="usReturnsForm-soldToNum">Please enter a valid CSL Seqirus Sold to Account #</label>
                    
            </div>
            <div class="col-lg-6 col-sm-12 col-xs-12 no-padding-left margin-B10 form-group has-feedback usReturns_dEANum" [ngClass]="{'has-error':(isFormSubmitted || usReturnsForm.get('dEANum')?.touched) && usReturnsForm.get('dEANum')?.invalid}">
                <label class="registration_inputLabel" for="usReturnsForm-dEANum">
                    DEA # <i class="fa-regular fa-circle-question returns_dEANum_tooltip"><span class="returns_dEANum_tooltiptext">A DEA # is required to process returns If you do not have one, your return may be subject to additional review or processing delays.</span></i>  
                </label>
                <input class="usreturns_input form-control" id="usReturnsForm-dEANum" maxlength="9" mask="A*" (focus)="clearError('dEANum')" name="dEANum"
                    autocomplete="off" formControlName="dEANum"/>
                    <span *ngIf="(isFormSubmitted || usReturnsForm.get('dEANum')?.touched) && usReturnsForm.get('dEANum')?.invalid" class='glyphicon glyphicon-exclamation-sign form-control-feedback'></span>
                    <label *ngIf="(isFormSubmitted || usReturnsForm.get('dEANum')?.touched) && usReturnsForm.get('dEANum')?.hasError('dEANum')" id="usReturnsForm-dEANum-error" class="error has-error help-block" for="usReturnsForm-dEANum">E-mail address is invalid</label>
                    
            </div>
            </div>
            </div>
            <div class="original_shipping_container">
                <div class="original_shipping_section1">
                    <div class="orginal_shipping_loc_heading">Original Shipping Location</div>
                    <div class="orginal_shipping_location_subtext">Please enter the address of the location where the product was initially shipped or if no longer applicable, the location from which the return will be sent.</div>
                </div>
                <div *ngIf="!userLoggedin || (userLoggedin && !userInfo?.webEnabled)" class="col-xs-12 form-group has-feedback location_input_field">
                    <label class="registration_inputLabel lookup_label" for="loc_addlp">Address Lookup</label>
                    <i class="fa fa-search loc_addsearchicon"></i><input class="usreturns_input form-control" type="text" id="returns_AddressLookup" name="returns_AddressLookup" formControlName="shipAddrLookup"/>
                </div>
                <div *ngIf="userLoggedin && userInfo?.webEnabled" class="col-xs-12 form-group has-feedback returnloc_loggedin"><label class="registration_inputLabel" for="loc_addlp">Location</label><i class="fa fa-search loc_addsearchicon"></i>
                    <!-- <select id="returns_Addressdropdown" name="returns_Addressdropdown" formControlName="returns_Addressdropdown">
                        <option selected></option>
                        <option *ngFor="let shipAddr of shipLocations" value="">{{shipAddr.address}}</option>
                    </select> -->
                    <ng-select class="location_dropdown" [items]="shipLocations" bindLabel="address" bindValue="locID" [searchable]="true" (change)="onShipLocationchange($event)"><i class="fa fa-search loc_addsearchicon"></i></ng-select>
                </div>

                <div class="col-xs-12 form-group has-feedback street_adress_input_field" [ngClass]="{'has-error':(isFormSubmitted || usReturnsForm.get('street')?.touched) && usReturnsForm.get('street')?.invalid}">
                    <label class="registration_inputLabel" for="loc_add1">Street Address*</label>
                    <input type="text" id="street" name="street" formControlName="street" (blur)="validateField('street')" (focus)="clearError('street')" class="usreturns_input form-control" />
                    <span *ngIf="(isFormSubmitted || usReturnsForm.get('street')?.touched) && usReturnsForm.get('street')?.invalid" class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                    <label *ngIf="(isFormSubmitted || usReturnsForm.get('street')?.touched) && usReturnsForm.get('street')?.hasError('required')" id="usReturnsForm-street-error" class="error has-error help-block" for="usReturnsForm-street">Street Address is required</label>
                    
                </div>
                <!---   -->
                <div class="city_state_zipcode_section">
                <div class="col-xs-7 col-md-6 col-xs-12 col-sm-12 form-group has-feedback city_input_field no-padding-right" [ngClass]="{'has-error':(isFormSubmitted || usReturnsForm.get('city')?.touched) && usReturnsForm.get('city')?.invalid}"><label class="registration_inputLabel" for="city">City*</label>
                    <input type="text" id="city" name="city" formControlName="city" (blur)="validateField('city')" (focus)="clearError('city')" class="usreturns_input form-control"/>
                    <span *ngIf="(isFormSubmitted || usReturnsForm.get('city')?.touched) && usReturnsForm.get('city')?.invalid" class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                    <label *ngIf="usReturnsForm.get('city')?.touched && usReturnsForm.get('city')?.hasError('pattern')" id="usReturnsForm-street-error" class="error has-error help-block" for="usReturnsForm-street">City is required</label>
                    <label *ngIf="(isFormSubmitted || usReturnsForm.get('city')?.touched) && usReturnsForm.get('city')?.hasError('required')" id="usReturnsForm-street-error" class="error has-error help-block" for="usReturnsForm-street">City is required</label>
                    
                </div>
                <div class="col-xs-5 col-md-3 col-xs-12 form-group has-feedback state_input_field no-padding-right" [ngClass]="{'has-error':(isFormSubmitted || usReturnsForm.get('state')?.touched) && usReturnsForm.get('state')?.invalid}"><label class="registration_inputLabel" for="state">State*</label>
                    <select id="state" formControlName="state" name="state" (blur)="validateField('state')"  (focus)="clearError('state')" class="usreturns_input form-control"><option value="" selected="selected"></option>
                        <option value="AL"> AL </option>
                        <option value="AK"> AK </option>
                        <option value="AR"> AR </option>
                        <option value="AZ"> AZ </option>
                        <option value="CA"> CA </option>
                        <option value="CO"> CO </option>
                        <option value="CT"> CT </option>
                        <option value="DE"> DE </option>
                        <option value="DC"> DC </option>
                        <option value="FL"> FL </option>
                        <option value="GA"> GA </option>
                        <option value="GU"> GU </option>
                        <option value="HI"> HI </option>
                        <option value="IA"> IA </option>
                        <option value="ID"> ID </option>
                        <option value="IL"> IL </option>
                        <option value="IN"> IN </option>
                        <option value="KS"> KS </option>
                        <option value="KY"> KY </option>
                        <option value="LA"> LA </option>
                        <option value="ME"> ME </option>
                        <option value="MD"> MD </option>
                        <option value="MA"> MA </option>
                        <option value="MI"> MI </option>
                        <option value="MN"> MN </option>
                        <option value="MS"> MS </option>
                        <option value="MO"> MO </option>
                        <option value="MT"> MT </option>
                        <option value="NC"> NC </option>
                        <option value="ND"> ND </option>
                        <option value="NE"> NE </option>
                        <option value="NV"> NV </option>
                        <option value="NH"> NH </option>
                        <option value="NJ"> NJ </option>
                        <option value="NM"> NM </option>
                        <option value="NY"> NY </option>
                        <option value="OH"> OH </option>
                        <option value="OK"> OK </option>
                        <option value="OR"> OR </option>
                        <option value="PA"> PA </option>
                        <option value="PR"> PR </option>
                        <option value="RI"> RI </option>
                        <option value="SC"> SC </option>
                        <option value="SD"> SD </option>
                        <option value="TN"> TN </option>
                        <option value="TX"> TX </option>
                        <option value="UT"> UT </option>
                        <option value="VA"> VA </option>
                        <option value="VI"> VI </option>
                        <option value="VT"> VT </option>
                        <option value="WA"> WA </option>
                        <option value="WI"> WI </option>
                        <option value="WV"> WV </option>
                        <option value="WY"> WY </option>
                    </select>
                    <span *ngIf="(isFormSubmitted || usReturnsForm.get('state')?.touched) && usReturnsForm.get('state')?.invalid" class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                    <label *ngIf="(isFormSubmitted || usReturnsForm.get('state')?.touched) && usReturnsForm.get('state')?.hasError('required')" id="usReturnsForm-street-error" class="error has-error help-block" for="usReturnsForm-street">State is required</label>
                </div>
                
                <div class="col-xs-7 col-md-3 col-xs-12 form-group has-feedback zip_input_field no-padding-right" [ngClass]="{'has-error':(isFormSubmitted || usReturnsForm.get('zip')?.touched) && usReturnsForm.get('zip')?.invalid}"><label class="registration_inputLabel" for="zip">ZIP*</label>
                    <!-- <div class="input-group"> -->
                        <input type="text" id="zip" (focus)="clearError('zip')" (blur)="validateField('zip')" formControlName="zip" name="zip" class="usreturns_input form-control"/>
                    <!-- <span class="input-group-addon" *ngIf="(isFormSubmitted || usReturnsForm.get('zip')?.touched) && usReturnsForm.get('zip')?.invalid"> -->
                        <span  *ngIf="(isFormSubmitted || usReturnsForm.get('zip')?.touched) && usReturnsForm.get('zip')?.invalid" class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                    <!-- </span> -->
                    <!-- </div> -->
                    <div class="error help-block" *ngIf="usReturnsForm.get('zip')?.touched && usReturnsForm.get('zip')?.hasError('pattern')">Please enter valid US zip code</div>
                    <div class="error help-block" *ngIf="(isFormSubmitted || usReturnsForm.get('zip')?.touched) && usReturnsForm.get('zip')?.hasError('required')">ZIP is required</div>
                </div>	
                </div>
                <div *ngIf= "usReturnsForm.get('purchaseFrom')?.value != 'wholesaler'" class="col-lg-6 col-sm-12 col-xs-12 margin-B10 form-group has-feedback usReturns_shipToNum" [ngClass]="{'has-error':(isFormSubmitted || usReturnsForm.get('shipToNum')?.touched) && usReturnsForm.get('shipToNum')?.invalid}">
                    <label class="registration_inputLabel" for="usReturnsForm-shipToNum">
                        CSL Seqirus Ship to Account # (or Store #)*
                    </label>
                    <input class="usreturns_input form-control" id="usReturnsForm-shipToNum" (blur)="validateField('shipToNum')" (focus)="clearError('shipToNum')" name="shipToNum"
                        autocomplete="off" formControlName="shipToNum"/>
                    <span *ngIf="(isFormSubmitted || usReturnsForm.get('shipToNum')?.touched) && usReturnsForm.get('shipToNum')?.invalid" class='glyphicon glyphicon-exclamation-sign form-control-feedback'></span>
                    <label *ngIf="(isFormSubmitted || usReturnsForm.get('shipToNum')?.touched) && usReturnsForm.get('shipToNum')?.invalid" id="usReturnsForm-shipToNum-error" class="error has-error help-block" for="usReturnsForm-shipToNum">Please enter a valid CSL Seqirus Ship to Account # (or Store #)</label>
                </div>
           
            </div>
           <!--returns Product Information Section-->
            <div class="returns_product_info_container">
                <div class="returns_product_info_heading">Returns Product Information</div>
                
                <div class="col-lg-9 col-sm-12 col-xs-12 no-padding-left margin-B10 form-group has-feedback usReturns_ndcNumber" [ngClass]="{'has-error':(usReturnsForm.get('ndcNumber')?.touched && usReturnsForm.get('ndcNumber')?.invalid) || (isFormSubmitted  && usReturnsForm.get('ndcs')?.hasError('required'))}">
                    <label class="registration_inputLabel" for="usReturnsForm-ndcNumber">
                        NDC#* <i class="fa-regular fa-circle-question returns_ndcnum_tooltip"><span class="returns_ndcnum_tooltiptext"><img style="width: 188px; height: 171px; position: relative; border-radius: 2px"  src="/assets/images/ndc_tooltip.png"/> The NDC# is found on the top left of the front of the packaging.</span></i>  
                    </label>
                    <div class="ndc_input_button">
                    <input class="usreturns_input form-control" id="usReturnsForm-ndcNumber" (blur)="validateField('ndcs')"  (focus)="clearError('ndcs')" maxlength="13" [dropSpecialCharacters]="true" mask="00000-0000-00" name="ndcNumber"
                        autocomplete="off" formControlName="ndcNumber"/>
                        <span *ngIf="(usReturnsForm.get('ndcNumber')?.touched && usReturnsForm.get('ndcNumber')?.invalid) || (isFormSubmitted  && usReturnsForm.get('ndcs')?.hasError('required'))" class='glyphicon glyphicon-exclamation-sign form-control-feedback'></span>
                        <button type="button" class="ndc_btn global_greybtn" (click)="addNDC()">Add NDC#</button>
                    </div>
                    
                    <label *ngIf="(usReturnsForm.get('ndcNumber')?.touched && usReturnsForm.get('ndcNumber')?.invalid) || (isFormSubmitted  && usReturnsForm.get('ndcs')?.hasError('required'))" id="usReturnsForm-ndcNumber-error" class="error has-error help-block" for="usReturnsForm-ndcNumber">Please add a valid NDC #</label>
                    <!--Ndc form section-->
                    <div formArrayName="ndcs">
                        <div *ngFor="let ndc of ndcs.controls; let ndcIndex=index" class="ndc_Form_section">
                            <div [formGroupName]="ndcIndex">
                                <!--Name section-->
                                <div class="ndc_form_product_name_section">
                                    <a href="#" data-toggle="collapse"  class="profilenavlinks dashboard_accountUK-arrow" (click)="toggleSlideNdc(ndcIndex)" [ngClass]="{'collapsed':!isCollapsedSections[ndcIndex]}">
                                        <i class="fa fa-fw " [ngClass]="iconNdc=='fa-angle-down'?'fa-angle-down':'fa-angle-up'" ></i></a>
                                        <!-- <input class="usreturns_input form-control" id="usReturnsForm-doses"  type="text" name="prodName" formControlName="productName"/> -->
                                    <div class="ndc_name_value_fields">
                                        <div class="ndc_product_name">{{ndc.get('productName')?.value}}</div>
                                        <div class="ndc_product_value">{{ndc.get('ndcNumber')?.value | mask:'00000-0000-00'}}</div>
                                    </div>
                                    <div (click)="removeNDC(ndcIndex)" class="ndc_remove_button">Remove<span  class="glyphicon glyphicon-remove-circle"></span></div>
                                </div>
   
                                <!--ndc form middle section-->
                                <div [ngClass]="{'show':isCollapsedSections[ndcIndex]}" class="ndc_batch_lot_info collapse">
                                    <div>
                                        <div>
                                            <label class="registration_inputLabel batch_lotlabel" for="loc_addlp">Batch/Lot #*</label>
                                            <div class="col-xs-12 form-group has-feedback" class="ndc_search_form_group" [ngClass]="{'has-error':(isFormSubmitted || ndcs.at(ndcIndex).get('enteredBatchNo')?.touched) && ndcs.at(ndcIndex).get('batchDetails')?.hasError('required')}">
                                                <!-- <div class="input-group-addon"> -->
                                                <i class="fa fa-search ndc_form_addsearchicon"></i>
                                                <!-- </div> -->
                                             <!--  <select id="returns_batchNodropdown" (change)="addBatchNo(ndcIndex,$event)" name="returns_batchNodropdown">
                                                    <option selected></option>
                                                    <option *ngFor="let batch of ndc.get('batchNumber')?.value" [value]="batch">{{batch}}</option>
                                                </select> -->
                                            <ng-select id="returns_batchNodropdown" class="returns_batchNodropdown" [clearSearchOnAdd]="true" [items]="availableBatchNos[ndc.get('ndcNumber')?.value]" bindLabel="batchNumber" bindValue="batchNumber" [searchable]="true" (change)="addBatchNo(ndcIndex,$event)" formControlName="enteredBatchNo"></ng-select>
                                            <span  *ngIf="(isFormSubmitted || ndcs.at(ndcIndex).get('enteredBatchNo')?.touched) && ndcs.at(ndcIndex).get('batchDetails')?.hasError('required')" class='glyphicon glyphicon-exclamation-sign form-control-feedback'></span>
                                            
                                            <label *ngIf="(isFormSubmitted || ndcs.at(ndcIndex).get('enteredBatchNo')?.touched) && ndcs.at(ndcIndex).get('batchDetails')?.hasError('required')" id="usReturnsForm-ndcNumber-error" class="error has-error help-block" for="usReturnsForm-ndcNumber">Please add a valid Batch/Lot #</label>
                                                
                                            </div>
                                        </div>
                                        <div class="ndc_form_productsdetail_section" formArrayName="batchDetails">
                                            <!--This one i placed out of array because for every product the heading was coming-->
                                            <div class="heading_bacth_lot">
                                                <span class="Batch_lot">Batch/Lot #</span>
                                                <!-- <input type="number" formControlName="batchNo" /> -->
                                                <span class="doses_to_return">Doses to be returned</span>
                                            </div>
                                            <div *ngFor="let batchNo of ndcBatchNos(ndcIndex).controls; let batchIndex=index" [formGroupName]="batchIndex" class="ndc_product_num_quantity_section">
                                                <div class="Batch_lot_products_details">
                                                    <span class="Batch_lot_products_number_field">
                                                        <span (click)="removeBatchNo(ndcIndex,batchIndex,batchNo.get('batchNo')?.value)" class="glyphicon glyphicon-remove-circle"></span>
                                                        <span class="batchno_text">{{batchNo.get('batchNo')?.value}}</span>
                                                    </span>
                                                    <input class="col-md-2 usreturns_input form-control" id="usReturnsForm-doses{{batchIndex}}" maxlength="5" type="number" name="batch_doses" min="1" step="1" mask="09999" max="99999" formControlName="doses">
                                                    <!-- <span *ngIf="batchNo.get('doses')?.touched && batchNo.get('doses')?.invalid" class='glyphicon glyphicon-exclamation-sign form-control-feedback'></span>
                                                    <label *ngIf="batchNo.get('doses')?.touched && batchNo.get('doses')?.invalid" id="usReturnsForm-doses-error" class="error has-error help-block" for="usReturnsForm-doses">E-mail address is invalid</label> -->
                                                </div>                                                                      
                                            </div>
                                        </div>
                                    </div>
                                </div>
 
                                <!--Total doses section-->
                                <div class="total_Doses_Section">
                                    <div class="total_doses_contents">
                                        <div class="ndc_total_doses">Total number of doses being returned:</div>
                                        <div class="ndc_total_doses_quantity">{{getTotalDoses(ndcIndex)}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                       
                </div>
            </div>
                <div class="returns_information">
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none" class="information_svg">
                        <circle cx="13" cy="13" r="12" stroke="#5D6F7B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M13.0049 6.53857L12.9043 14.2001" stroke="#5D6F7B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M12.7629 18.9999H12.7749" stroke="#5D6F7B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg> -->
                      <i class="fa-solid fa-circle-exclamation"></i>
                    <div class="returns_information_text">
                        <div class="returns_information_download_info">Download your completed form and email it to: <a href="mailto:rarequest@inmar.com" class="returns_information_text2">rarequest&#64;inmar.com</a> or upload it at <a href="https://hrm.reskureturns.com" target="_blank" class="returns_information_text2">https://hrm.reskureturns.com</a></div>
                        <div class="returns_information_expect">You can expect to receive a Return Authorization (RA) box label in 5 business days.</div>
                    </div>
                </div>

                <div class="clearboth"></div>

                <div class="col-lg-3 col-sm-12 col-xs-12 no-padding-left margin-B10 form-group has-feedback usReturns_raLabel" [ngClass]="{'has-error':(isFormSubmitted || usReturnsForm.get('raLabel')?.touched) && usReturnsForm.get('raLabel')?.invalid}">
                    <label class="registration_inputLabel" for="usReturnsForm-raLabel">
                        # of RA Labels Needed* <i class="fa-regular fa-circle-question ra_Label_tooltip"><span class="ra_Label_tooltiptext">Select the # of RA Labels Needed for each box which will be returned.</span></i>  
                    </label>
                    <input class="usreturns_input form-control num_of_forms_input" (focus)="clearError('raLabel')" id="usReturnsForm-raLabel" maxlength="3" type="number" name="raLabel" value="1" mask="099" min="1" step="1" max="999"
                        autocomplete="off" formControlName="raLabel"/>
                        <!-- <span *ngIf="(isFormSubmitted || usReturnsForm.get('raLabel')?.touched) && usReturnsForm.get('raLabel')?.invalid" class='glyphicon glyphicon-exclamation-sign form-control-feedback'></span>
                        <label *ngIf="(isFormSubmitted || usReturnsForm.get('raLabel')?.touched) && usReturnsForm.get('raLabel')?.invalid" id="usReturnsForm-raLabel-error" class="error has-error help-block" for="usReturnsForm-raLabel">E-mail address is invalid</label> -->
                        
                </div>
                <div class="clearboth"></div>

                <div class="form_error_message" *ngIf="isFormSubmitted && usReturnsForm.invalid">
                    <!-- <span class='glyphicon glyphicon-exclamation-sign form-control-feedback'></span> -->
                    <i class="glyphicon glyphicon-exclamation-sign"></i>
                    <div>Please correct the above highlighted fields.</div>
                </div>
 
            <button type="submit" class="support_submit active">Download Completed Return Form <img  alt="Download" src="../assets/images/product_download_icon.svg"></button>
           
            <div class="clearboth"></div>
        </form>
    </div>
</div>
 
 
<div id="usreturns_wholeslaremodal" class="modal fade" role="dialog" >
    <div class="modal-dialog">
   
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-body">
        <div class="no-padding pull-right"><span class="schpaycancel_close" data-dismiss="modal">×</span></div>
        
          <div class="wholesaler_detailsection" [innerHTML]="usReturnsData.textPageContent1">
            {{usReturnsData.textPageContent1}}</div>
          <div class="wholesaler_continue">
            <button class="wh_continuebutton global_greybtn" data-dismiss="modal" #closeButton>Continue</button>
          </div>
 
        </div>
      </div>
    </div>
</div>
 
<div id="usreturns_dEANumModal" class="modal fade" role="dialog" >
    <div class="modal-dialog">
   
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-body">
            <div class="no-padding pull-right" data-dismiss="modal"><span class="returnsDEA_close" data-dismiss="modal">×</span></div>
        
        <div style="width: 100%; position: relative" [innerHTML]="usReturnsData.textPageContent2">{{usReturnsData.textPageContent2}}</div>
          
        <div class="returns_dea_buttons">
            <button class="returns_enter_dea global_greybtn" data-dismiss="modal" #closeButton>Enter DEA #</button>
            <button class="returns_pdf_button" data-dismiss="modal" (click)="generatePDFWithTable()">Download without DEA #</button>
 
        </div>
 
        </div>
      </div>
    </div>
</div>