import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserInfoService } from '../../spartacus/services/user-info.service';

@Component({
  selector: 'app-summarybanner',
  templateUrl: './summarybanner.component.html',
  styleUrl: './summarybanner.component.scss'
})
export class SummarybannerComponent {
  @ViewChild('seasonModal')
  seasonModal:boolean= false;
  enablepopup:any;
  constructor( private cdr: ChangeDetectorRef, private router: Router, private userInfoService: UserInfoService) {

  }

  ngOnInit() {
    this.userInfoService.getpopupseason().subscribe((data: any) => {
      this.enablepopup = data;
      this.cdr.detectChanges();
    });
    }

    closepop(){
      const obj = {
        popup: false,
        season:""
        
      }
      this.userInfoService.setpopupseason(obj);
      this.cdr.detectChanges();
    }

    continue(season:string){
      this.router.navigateByUrl("/start-order?season="+this.enablepopup?.season);
      const obj = {
        popup: false,
        season:""
        
      }
      this.userInfoService.setpopupseason(obj);
      this.cdr.detectChanges();

    }
}
