<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 joinAccount_parent_header no-padding-left margin-T20">
    <div id="breadcrumb" class="col-xs-12 col-md-6 pa_breacdcrumb">
            <ol class="breadcrumb">
<li>
<a [routerLink]="'/'">Home</a>
</li>

<li class="bc_bold"><strong>Create Account</strong></li>
</ol>
</div>
        <div class="col-xs-12 col-md-6 createprofile_helptext pa_help">
        
            <span class="createprofile_helplighttext">Having trouble?</span>
            <a [routerLink]="'/help-and-faq'">
            <span class="createprofile_helpdarktext">We Can Help</span>
            <div class="global_blackarrow"></div></a>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pa_rightside">

    <section class="pa_section" id="pa_section-email">
    <div class="yCmsContentSlot ">
<div class="paHeader_label">
        <div class="content">Thanks for verifying your email. Now let's get started.</div></div>
    </div><div class="yCmsContentSlot ">
<div class="pa_text1">
            <div class="content">You can either create a new business account or join an existing company account.</div></div>
    </div><div class="yCmsContentSlot accountPageBottomContent">
<div class="pa_text2 padding-B20"> 
        <div class="content">Does your organization already have an account with CSL Seqirus?</div></div>
        
          <span class="radioemail">
           <label class="radio-button">
                <input type="radio" class="emailverfiy_radio" name="email-check" value="yes" [checked]="true" id="email-Y">
                <span class="radio-button__control"></span>
            </label>
              <label class="emailverfiy_checkboxLabel">Yes</label>
            <label class="radio-button">
                <input type="radio" class="emailverfiy_radio" name="email-check"  value="no" id="email-N">
                <span class="radio-button__control"></span>
            </label>
            <label class="emailverfiy_checkboxLabel">No</label>
            </span>
    </div><div class="clearboth"></div>
    <button class="continueAccountBtn active" (click)="continuebtn()">Continue</button>
    </section>
    </div>
</div>
