import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SecondAdjuvantedVideoComponent } from './second-adjuvanted-video/second-adjuvanted-video.component';
import { ThirdImageComponent } from './third-image/third-image.component';
import { FourthCellComponent } from './fourth-cell/fourth-cell.component';
import { CmsConfig, ConfigModule } from '@spartacus/core';
// import { FifthBlueBannerComponent } from './fifth-blue-banner/fifth-blue-banner.component';
import { FirstNavComponent } from './first-nav/first-nav.component';
import { RouterModule } from '@angular/router';
@NgModule({
  declarations: [
    
    SecondAdjuvantedVideoComponent,
    ThirdImageComponent,
    FourthCellComponent,
    // FifthBlueBannerComponent,
    // FirstNavComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ConfigModule.withConfig({
      cmsComponents:{
  
        SeqirusAdjuvantedComponent:{
          component:SecondAdjuvantedVideoComponent
      },
      // SeqirusFluadAdjuvantFirstComponent:{
      //   component:FirstNavComponent
      // },

      SeqirusProductVideoComponent:{
        component:ThirdImageComponent
      },
      SeqirusPreferentiallyComponent:{
        component:FourthCellComponent
      },
      // SeqirusProductViewComponent:{
      //   component:FifthBlueBannerComponent
      // }
    }
    }as CmsConfig)
  ]
})
export class AdjuvantAdvantageModule { }