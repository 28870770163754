import { ChangeDetectorRef, Component, OnInit  } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { CmsBannerComponent, OccConfig } from '@spartacus/core';
import { Observable } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ContentService } from '../../spartacus/services/content.service';


@Component({
  selector: 'app-homepage-uk-products',
  templateUrl: './homepage-uk-products.component.html',
  styleUrl: './homepage-uk-products.component.scss'
})
export class HomepageUKProductsComponent implements OnInit{
  paracontent:string="";
  banners: any[] = [];
  safeh2content:SafeHtml | undefined;

  constructor(public component: CmsComponentData<any>,protected contentService:ContentService,private cdr:ChangeDetectorRef,private sanitizer:DomSanitizer,protected config: OccConfig) {
  }

  getMediaUrl(media:any):string{
    return this.config.backend?.occ?.baseUrl + media.url;
  }

  ngOnInit(){
    
    this.component.data$.subscribe((data) =>{
      this.banners = [data];
      this.paracontent = data?.media;
      //this.safeh2content = this.sanitizer.bypassSecurityTrustHtml(this.paracontent);
    })
  }

}
