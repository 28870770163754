import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseSiteService, CmsBannerComponent, CmsService } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-custom-left-panel',
  templateUrl: './custom-left-panel.component.html',
  styleUrl: './custom-left-panel.component.scss'
})
export class CustomLeftPanelComponent {

  pageLabel: string="";
  leftNode:any;
  currentSite: string="";
  constructor(private bannerComponent: CmsService,private route:ActivatedRoute,   protected baseSiteService: BaseSiteService,) {
    this.bannerComponent.getCurrentPage().subscribe((data)=>{
    this.pageLabel = data.pageId?data.pageId:"";
      
    })
  }

  
  
  //leftNode: Observable<any> = this.component.data$;

  ngOnInit(){
    this.baseSiteService.getActive().subscribe(site => {
      this.currentSite = site;
    });
    
    this.loadLeftData(this.pageLabel);
  }
  loadLeftData(pageLabel: string) {
    if(this.pageLabel === 'changepassword' && this.currentSite == 'seqirusb2b-flu360'){
      this.bannerComponent.getComponentData('ChangePasswordBanner_US').subscribe((data)=>{
        this.leftNode = data;
      });
    
    }else if(this.pageLabel === 'changepassword' && this.currentSite == 'seqirusb2b-uk'){
      this.bannerComponent.getComponentData('ChangePasswordBanner').subscribe((data)=>{
        this.leftNode = data;
      });
    
    }
    else if(this.pageLabel === 'changepasswordsuccess'&& this.currentSite == 'seqirusb2b-flu360'){
      this.bannerComponent.getComponentData('ChangePasswordSuccessBanner_US').subscribe((data)=>{
        this.leftNode = data;
      });
    
    }
    else if(this.pageLabel === 'changepasswordsuccess' && this.currentSite == 'seqirusb2b-uk'){
      this.bannerComponent.getComponentData('ChangePasswordSuccessBanner').subscribe((data)=>{
        this.leftNode = data;
      });
    
    }
    else if(this.pageLabel === 'forgotpassword'){
      this.bannerComponent.getComponentData('ForgotPasswordBanner').subscribe((data)=>{
        this.leftNode = data;
      });
    }
    else if(this.pageLabel==='profileactivation'){
      this.bannerComponent.getComponentData('ProfileActivationBanner').subscribe((data)=>{
        this.leftNode = data;
      });
    }
  //   if(this.pageLabel === 'resetpassword'){
  //       this.bannerComponent.getComponentData('ResetPasswordBanner_US').subscribe((data)=>{
  //         this.leftNode = data;
  //       });
  //   }
  //   else{
  //   this.bannerComponent.getComponentData('RegistrationBanner').subscribe((data)=>{
  //     this.leftNode = data;
  //   });
  // }
  else if(this.pageLabel === 'emailverification' && this.currentSite == 'seqirusb2b-flu360'){
    this.bannerComponent.getComponentData('EmailVerificationBanner').subscribe((data)=>{
      this.leftNode = data;
    });
  }
  else if (this.pageLabel === 'resetpassword') {
    if (this.currentSite == 'seqirusb2b-uk') {
      this.bannerComponent.getComponentData('ResetPasswordBanner').subscribe((data) => {
        this.leftNode = data;
      });
    } else {
      this.bannerComponent.getComponentData('ResetPasswordBanner_US').subscribe((data) => {
        this.leftNode = data;
      });
    }
  } else {
    this.bannerComponent.getComponentData('RegistrationBanner').subscribe((data) => {
      this.leftNode = data;
    });
  }
}
  
 
}
