<!-- <p>flucelvax-referenceparagraph works!</p> -->
<section class="col-12 hidden-xs hidden-sm d-none d-md-block no-padding animationbottom" *ngIf="referncenode | async as item">
    <div class="cbsanimationbelow">
        <div [innerHTML]="item.largeparagraphcontent"></div>
    </div>
    <a [href]="getpdf(item?.media)"  *ngIf="referncenode | async as item" target="_blank" download="" class="product_download-button">
        <span>Download Full RWE Data</span>
        <img alt="Download" src="../../../app/../assets/images/product_download_icon.svg">
    </a>
</section>
<section class="col-12 d-md-none d-lg-none no-padding cellBasedSolution" *ngIf="referncenode | async as item">
    <div class="mobileanimationbelow">
        <div [innerHTML]="item.largeparagraphcontent"></div>
      </div>
  </section>
