<nav aria-label="breadcrumb">
  <ol class="breadcrumb padLeft79">
    <li class="breadcrumb-item active"><a href="/" class="blacktext">Home</a></li>
    <li class="breadcrumb-item" aria-current="page">Cookie Policy</li>
  </ol>
</nav>
<div class="cookiePolicyLogout col-xs-12" *ngIf="node | async as item">
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 cookiePolicyLogout_tableLayout">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <h1 class="cookiePolicyLogout_left_header new_header_cookie">{{ item.headline }}</h1>
      <!-- <button id="ot-sdk-btn" class="ot-sdk-show-settings" (click)="showCookieSettings()">{{item.buttonLabel}}</button>
    -->
      <button id="ot-sdk-btn" class="ot-sdk-show-settings" (click)="showCookieSettings()"
        [disabled]="isCookieSettingsShown">{{item.buttonLabel}}</button>

      <div id="ot-sdk-cookie-policy"></div>
    </div>
  </div>
</div>

   <!-- <div id="onetrust-banner-sdk" class="otFlat otRelFont bottom ot-wo-title" tabindex="0" role="region" aria-label="Cookie banner" style="bottom: 0px;">
    <div role="dialog" aria-label="Privacy">
      <div class="ot-sdk-container">
        <div class="ot-sdk-row">
          <div id="onetrust-group-container" class="ot-sdk-eight ot-sdk-columns">
            <div class="banner_logo"></div>
            <div id="onetrust-policy">
              <div id="onetrust-policy-text">
                By clicking “Accept All Cookies”, you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts.
              </div>
            </div>
          </div>
          <div id="onetrust-button-group-parent" class="ot-sdk-three ot-sdk-columns">
            <div id="onetrust-button-group">
              <button id="onetrust-pc-btn-handler">Cookies Settings</button>
              <button id="onetrust-accept-btn-handler" >Accept All Cookies</button>
            </div>
          </div>
        </div>
        <div id="onetrust-button-group-parent" class="ot-sdk-three ot-sdk-columns">
          <div id="onetrust-button-group">
            <button id="onetrust-pc-btn-handler">Cookies Settings</button>
            <button id="onetrust-accept-btn-handler">Accept All Cookies</button>
          </div>
        </div>
      </div>
    </div>
  </div> -->
   <!--cookie-setting-->
   
