

<ng-container *ngIf="regThankYouData | async as data">
    <div *ngIf="isThankYouPage() | async">
    <div class="col-xs-12 registration_thankyou_mob">
        <div class="registration_thankyou_rightheadermob"><a href="/">Home</a> &gt; <span>Create Account</span></div>
        <div class="clearboth"></div>                   
    </div>
    <div class="col-md-3 col-xs-12 registration_thankyou_leftside">
        <div class="col-xs-12 col-md-10 registration_thankyou_nagivation no-padding">
            <div class="registration_thankyou_nagivationName1">
                {{data.title}}
            </div>
            <div class="registration_thankyou_nagivationName2">
                {{data.text2}}
            </div>
        </div>
        <!-- added job bag codes -->
         
            <div class="col-xs-12 grbno no-padding margin-T10 margin-B10" *ngIf="currentSite == 'seqirusb2b-uk'">
                {{data.jobBagCode}}
            </div>
        
    </div>   
    <div  class="col-md-9  col-xs-12 registration_thankyou_rightside ">
        <div class=" col-md-12 col-xs-12 registration_thankyou_parentheader no-padding-left">
            <div class="registration_thankyou_rightheader">
                
                    <a href="#">Home</a> &gt; <span>Create Account</span>
                
            </div>
            <div class="registration_thankyou_help" [innerHTML]="data.h2content">
                
            </div>
            <div class="clearboth"></div>                   
        </div>
        <div class="clearboth"></div>
        
        <div class="registration_thankyou_textheader" *ngIf="currentSite == 'seqirusb2b-flu360'">
            {{data.content}}
        </div>
        <div *ngIf="currentSite == 'seqirusb2b-flu360'">
            <div class="registration_thankyou_text">{{data.paragraphcontent}}</div>
            <div class="registration_thankyou_button">
                <a [routerLink]="data.urlLink">{{data.h3content}}</a>
            </div>
        </div>

        <div class="fornonwebenabled" *ngIf="currentSite == 'seqirusb2b-uk'">
            <div class="Loginthankheader" style="margin-top: 70px;">
                Thank you for creating a CSL Seqirus business account
            </div>
            <div class="registration_thankyou_text ">
                <!-- {{data.paragraphcontent}} -->
                Your information is being reviewed by CSL Seqirus. Our review process usually takes up to 5 working days. A confirmation email will be sent to you when your information has been verified.
            </div>
            <div class="registration_thankyou_button">
                <a [routerLink]="'/overview'">Explore flu360</a>
            </div>
        </div>
    </div>
        <div *ngIf="currentSite == 'seqirusb2b-uk'" class="col-md-12  col-xs-12 registration_thankyou_rightside forwebenabled">

            <div class="clearboth"></div>
            <div class="Loginthankheader">
                Thank you for creating a CSL Seqirus business account
            </div>
            <div class="Loginthanktext">
                Your information is being reviewed by CSL Seqirus. Our review process usually takes up to 5 days. A confirmation email will be sent to you when your information has been verified
                 <!-- {{data.paragraphcontent}} -->
            </div>
            <a class="registerToMyProfile showPopupclick" [routerLink]="'/my-profile'"><div class="loginthankbtn">Go to My Profile</div></a>

            <div class="col-xs-12 grbno no-padding margin-T10 margin-B10">
                {{data.jobBagCode}}
            </div>

        </div>

    </div>
</ng-container>
