import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomReturnsThankyouvideoComponent } from './custom-returns-thankyouvideo/custom-returns-thankyouvideo.component';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { CustomReturnsThankyoumaterialsComponent } from './custom-returns-thankyoumaterials/custom-returns-thankyoumaterials.component';
import { CustomPictureResourcesComponent } from './custom-picture-resources/custom-picture-resources.component';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [
    CustomReturnsThankyouvideoComponent,
    CustomReturnsThankyoumaterialsComponent,
    CustomPictureResourcesComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ReturnsThankYouVideoComponent: {
          component: CustomReturnsThankyouvideoComponent
        },
        ReturnsThankYouMaterialComponent: {
          component: CustomReturnsThankyoumaterialsComponent
        },
        ReturnsThankYouPictureResourcesComponent: {
          component: CustomPictureResourcesComponent
        }
      }
    } as CmsConfig)
  ],
  exports: [
    CustomReturnsThankyouvideoComponent,
    CustomReturnsThankyoumaterialsComponent,
    CustomPictureResourcesComponent,
  ]
})
export class ReturnsThankyouPageModule { }
