import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SchPayTopBannerComponent } from './sch-pay-top-banner/sch-pay-top-banner.component';
import { ScheduledPaymentsSectionComponent } from './scheduled-payments-section/scheduled-payments-section.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { ConfigModule } from '@spartacus/core';
import { MatSortModule } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { DateAdapter, MAT_DATE_FORMATS, provideNativeDateAdapter } from '@angular/material/core';
import { NgxMaskModule } from 'ngx-mask';
import { CUSTOM_DATE_FORMATS } from '../all-invoices/all-invoices-left-section/all-invoices-left-section.component';
import { CustomDateAdapter } from '../shared/custom-date-adapter';



@NgModule({
  declarations: [
    SchPayTopBannerComponent,
    ScheduledPaymentsSectionComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule,
    MatTableModule,
    MatSortModule,
    MatFormFieldModule, 
    MatInputModule, 
    MatDatepickerModule,
    ConfigModule.withConfig({
      cmsComponents: {
        SeqirusSchedulePaymentFlexComponent:{
          component:SchPayTopBannerComponent,
        },
        SeqirusSchedulePaymentComponent:{
          component:ScheduledPaymentsSectionComponent,
        }
      }
    }),
    
  ],
   providers: [{provide:DateAdapter,useClass:CustomDateAdapter},{provide:MAT_DATE_FORMATS,useValue:CUSTOM_DATE_FORMATS}]
  
})
export class ScheduledPaymentModule { }
