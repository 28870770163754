import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseSiteService, OccEndpointsService } from '@spartacus/core';
import { CommonApiService } from '../../spartacus/services/common-api.service';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-custom-update-profile',
  templateUrl: './custom-update-profile.component.html',
  styleUrl: './custom-update-profile.component.scss'
})
export class CustomUpdateProfileComponent {
  currentsite:string='';
  profileDetails$: Observable<any> | undefined;
  @Input() toggleEdit:boolean | undefined;
  @Output() onEditClicked = new EventEmitter<any>();
  isAsAgent: boolean=false;
  isSearchByProfile: boolean=false;
  profiledata:any;

  constructor(private occEndpointsService:OccEndpointsService,private cd:ChangeDetectorRef,
    private commonService:CommonApiService,private userInfoService:UserInfoService,private baseSiteService:BaseSiteService){

  }
  ngOnInit(){
      
      this.baseSiteService.getActive().subscribe((siteId) => {
        this.currentsite = siteId;
      });
      this.isSearchByProfile = localStorage.getItem('isSearchByProfile') == 'true' ?true:false;
      this.isAsAgent = this.userInfoService.checkAsmUser();
      this.getProfileDetails();

  }
getProfileDetails(){ 
  

  this.userInfoService.getUKMyProfileData().subscribe(
    (data) => {
      if (data) {
        this.profiledata=data
        this.userInfoService.setProfileData(data);
        this.cd.detectChanges();
      }
     
    },
    (error) => {
      console.log('error');
     
    }
  );
   /*  let endpoint = this.occEndpointsService.buildUrl(`/users/${this.commonService.userId}/my-profile/profile`);
    let payload = {
      url: endpoint,
    }
    this.profileDetails$=this.commonService.getAPICall(payload);
    this.profileDetails$.subscribe({
      next:(res: any) => {
        if (res) {
          this.userInfoService.setProfileData(res);
          this.cd.detectChanges();
        }
      },
      error: (e) =>{} ,
    }) */

  }

  edit(){
    this.toggleEdit=!this.toggleEdit;
    this.onEditClicked.emit(this.toggleEdit);
  }
}
