import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpdatepassComponent } from './updatepass/updatepass.component';
import { FailpassComponent } from './failpass/failpass.component';
import { SuccesspassComponent } from './successpass/successpass.component';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [
    UpdatepassComponent,
    FailpassComponent,
    SuccesspassComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ASMResetPWChangeFlexComponent:{
          component:UpdatepassComponent
         },
         ASMResetPWSuccessFlexComponent:{
          component:SuccesspassComponent
         },
         ASMResetPWFailureFlexComponent:{
          component:FailpassComponent
         }
        }
  
      } as CmsConfig)

    
  ]
})
export class AsmresetpasswordModule { }
