
<div class="joinAccount_top_headerUK" *ngIf="topBannerUkData | async as topBannerData">
    <div class="joinAccount_top-text">{{topBannerData.paragraphcontent}}
        <!-- <div class="col-xs-12 col-md-12">
            <div class="newreg_header">Create a Seqirus Business Account</div>
         </div> -->
    </div>
    <div class="joinAccount_top-support">
        <p>{{topBannerData.h2content}}</p>
        <p [innerHTML]="topBannerData.content">{{topBannerData.content}}</p>
        <p [innerHTML]="topBannerData.h3content">{{topBannerData.h3content}}</p>
    </div>
</div>
