import { Component } from '@angular/core';

@Component({
  selector: 'app-successpass',
  templateUrl: './successpass.component.html',
  styleUrl: './successpass.component.scss'
})
export class SuccesspassComponent {

}
