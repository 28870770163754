<ng-container *ngIf="!userLoggedIn || (userLoggedIn && (!userInfo?.webEnabled))" class="Lo-tiles">
    <div class="Support_lowerboxsUK container-lg container-fluid" *ngIf="uklocontactnode | async as a data">
        <div class=" padding-L40 sub-containerUK">
            <div class="Support_flu360 col-lg-4 col-md-4 col-sm-12 col-xs-12 no-padding padding-R20" *ngFor="let item of mergedObject1; let i = index;" [ngClass]="{'Support_adverseReporting': i === 1,'Support_medicalCommunication': i === 2}">
                <h4 class="Support_flu360header boxHeader" [ngClass]="{'Support_adverseReportingheader': i === 1}">{{item?.headLine}}</h4>
                <div class="Support_flu360Content boxContent" [ngClass]="{'Support_adverseReportingContent': i === 1}">{{item?.content}}</div>
                <div class="Support_email" [innerHTML]="item?.t1content"></div>
                <div class="Support_call" [innerHTML]="item?.t2content"></div>
            </div>
            <div class="clearboth"></div>
        </div>
    </div>
</ng-container>