import { ChangeDetectorRef, Component, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CmsComponentData } from '@spartacus/storefront';
import { AuthService, OccConfig, RoutingService } from '@spartacus/core';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import autoTable from 'jspdf-autotable';
import { HttpClient } from '@angular/common/http';
import { invalid } from 'moment';

interface jsPDFWithPlugin extends jsPDF {
  autoTable: (options: any) => void;
}

const pdf = new jsPDF() as jsPDFWithPlugin;

declare var clickToAddress:any;
declare var window:any;

// Define the type for the data parameter
interface HookData {
  pageNumber: number;
  settings: any;
  table: any;
}

@Component({
  selector: 'app-us-returns-form',
  templateUrl: './us-returns-form.component.html',
  styleUrl: './us-returns-form.component.scss'
})
export class UsReturnsFormComponent {
  usReturnsForm: FormGroup;
  cc: any;
  usReturnsData: any;
  mask: string='';
  iconNdc: string="fa-angle-down";
  userLoggedin: boolean = false;

  isCollapsed: boolean=false;
  isCollapsedSections:boolean[]=[];
  userDetail: any;
  shipLocations: any;
  shipAddr: any;
  userInfo: any;
  isFormSubmitted: boolean = false;
  availableBatchNos: {[key:string]:string[]}={};

  constructor(private cd:ChangeDetectorRef,private renderer:Renderer2,private routingService:RoutingService,private http: HttpClient,private userInfoService:UserInfoService,private fb:FormBuilder,private router:Router,protected config: OccConfig,private authService:AuthService,public usReturnsComponent: CmsComponentData<any>){

    const emailPattern = new RegExp("^(?=(.{1,64}@.{1,255}))([!#$%&'*+\\-\\/=?\\^_`{|}~a-zA-Z0-9]{1,64}(\\.[!#$%&'*+\\-\\/=?\\^_`{|}~a-zA-Z0-9]{0,}){0,})@((\(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}\)|([a-zA-Z0-9-]{1,63}(\\.[a-zA-Z0-9-]{2,63}){1,}))$");
    const alphanSpacePattern = new RegExp("^[A-Za-z\\s]+$");
    const alphaNumSpacePattern = new RegExp("^(?!\\s)(?!.*\\s$)[a-zA-Z0-9][a-zA-Z0-9\\-\\,\\s]*$");


    this.usReturnsForm = this.fb.group({
      purchaseFrom:['',[Validators.required]],
      email:["",[Validators.required,Validators.pattern(emailPattern)]],
      phone:["",[Validators.required,Validators.pattern('^(\\([2-9]([02-9]\\d|1[02-9])\\)|[2-9]([02-9]\\d|1[02-9])) ?[2-9]\\d{2}-?\\d{4}$'),Validators.minLength(10)]],
      poNum:["",[Validators.required]],
      orgName:["",[Validators.required,Validators.pattern(alphaNumSpacePattern)]],
      soldToNum:["",[Validators.required,Validators.pattern('^[0-9]*$'),Validators.minLength(8),Validators.maxLength(10)]],
      dEANum:["",[Validators.pattern('^[a-zA-Z0-9]*$'),Validators.maxLength(9)]],
      shipAddrLookup:[""],
      returns_Addressdropdown:[""],
      street:['',[Validators.required]],
      city:['',[Validators.required,Validators.pattern(alphanSpacePattern)]],
      state:['',[Validators.required,Validators.pattern('^[a-zA-Z]+$')]],
      zip:['',[Validators.required,Validators.pattern(/^\d{5}(-\d{4})?$/)]],
      shipToNum:['',[Validators.required,Validators.maxLength(10),Validators.pattern('^[0-9]*$')]],
      ndcNumber:[''],
      ndcs: this.fb.array([],formArrayNotEmpty),

      raLabel:['1',[Validators.required]]


    },{updateOn:'blur'});

    this.checkForZero(this.usReturnsForm.get('raLabel'));
    ['street','city','state','zip'].forEach(field => {
      this.usReturnsForm.get(field)?.valueChanges.subscribe(() => {
        if(this.userLoggedin && this.userInfo.webEnabled){
          this.usReturnsForm.get('shipToNum')?.patchValue('');
        }
      })
    })
   
  }

  clearError(control:string){
    const controlFocussed = this.usReturnsForm.get(control);
    if(controlFocussed){
      controlFocussed.setErrors(null);
    }
    if(control == 'ndcs'){
      const ndcNumControl = this.usReturnsForm.get('ndcNumber');
      if(ndcNumControl){
        ndcNumControl.setErrors(null);
        ndcNumControl.markAsUntouched();
      }
    }
    /* if(this.isFormSubmitted){
      this.isFormSubmitted=false;
    } */
  }
  validateField(fieldName:string){
    const controlBlurred = this.usReturnsForm.get(fieldName);
    controlBlurred?.updateValueAndValidity();
    if(!controlBlurred?.valid){
      controlBlurred?.markAsTouched();
    }
    /* if(fieldName == 'ndcs'){
      const ndcNumControl = this.usReturnsForm.get('ndcNumber');
      ndcNumControl?.updateValueAndValidity();
      if(!ndcNumControl?.valid){
        ndcNumControl?.markAsTouched();
      }
    } */
  }

  onRadioChange(event:Event){
    const  val = (event.target as HTMLInputElement).value;
    this.usReturnsForm.get('purchaseFrom')?.markAsTouched();
    this.usReturnsForm.get('purchaseFrom')?.updateValueAndValidity();
    this.cd.detectChanges();

    if(val == 'wholesaler'){
      this.usReturnsForm.addControl('wholesalerName',this.fb.control('',Validators.required));
      const modalElement = document.getElementById('usreturns_wholeslaremodal') as HTMLElement;
      if(modalElement){
        const wholeSalerModal = new window.bootstrap.Modal(modalElement);
        wholeSalerModal.show();
        
      }
      this.usReturnsForm.get('soldToNum')?.clearValidators();
      this.usReturnsForm.get('soldToNum')?.updateValueAndValidity();
      if(!this.userLoggedin || (this.userLoggedin && !this.userInfo.webEnabled)){
        this.usReturnsForm.get('soldToNum')?.setValue('');
        this.usReturnsForm.get('shipToNum')?.setValue('');
      }
      this.usReturnsForm.get('shipToNum')?.clearValidators();
      this.usReturnsForm.get('shipToNum')?.updateValueAndValidity();

    }
    else{
      this.usReturnsForm.removeControl('wholesalerName');
      this.usReturnsForm.get('soldToNum')?.setValidators([Validators.required,Validators.pattern('^[0-9]*$'),Validators.minLength(8),Validators.maxLength(10)]);
      this.usReturnsForm.get('soldToNum')?.updateValueAndValidity();
      this.usReturnsForm.get('shipToNum')?.setValidators([Validators.required,Validators.maxLength(10),Validators.pattern('^[0-9]*$')]);
      this.usReturnsForm.get('shipToNum')?.updateValueAndValidity();
    }
  }
  onShipLocationchange(locId:any){
    this.shipAddr = this.shipLocations.find((loc: any) => loc.locID === locId);
    this.usReturnsForm.patchValue({
      street:locId.addressLine1 + locId.addressLine2,
      city:locId.city,
      state:locId.state,
      zip:locId.zip,
      shipToNum:locId.locID
    })
  }
  onMouseEnterPhn(){
    this.mask='(999) 999-9999';
  }
  onMouseLeavePhn(){
    if(!this.usReturnsForm.get('phone')?.value)
      this.mask='';
  }
  onLoginClick(){
    sessionStorage.setItem('returnUrl','/returns'),
    this.router.navigate(['/login']);
    
  }

  newNDC(productDetail:any): FormGroup {
    return this.fb.group({
      batchDetails: this.fb.array([],formArrayNotEmpty),
      productName: new FormControl(productDetail.productname),
      ndcNumber:new FormControl(productDetail.ndcNumber),
      batchNumber:new FormControl(productDetail.batchNumbers),
      enteredBatchNo:new FormControl('')
      
    });
  }

  addNDC() {
    const ndcNum = this.usReturnsForm.get('ndcNumber')?.value;
    this.usReturnsForm.get('ndcNumber')?.markAsTouched();
    if(!ndcNum){
    this.usReturnsForm.get('ndcNumber')?.setErrors({required:true});

    }
    if(this.usReturnsForm.get('ndcNumber')?.valid && ndcNum && !this.isNdcAlreadyAdded(ndcNum)){
      this.isCollapsedSections = this.isCollapsedSections.map(()=>false);
      this.getProductDetailWithNDC(ndcNum);
      this.usReturnsForm.get('ndcNumber')?.reset();
    }
  }
  isNdcAlreadyAdded(ndcNum: any) {
    return this.ndcs.controls.some(control => control.get('ndcNumber')?.value === ndcNum);
  }
  getProductDetailWithNDC(ndcNum: any) {
    let prodData;
    this.userInfoService.getBatchNoDetailOfNdc(ndcNum).subscribe((data:any) =>{
      prodData =data;
      this.availableBatchNos[data.ndcNumber] = [...data.batchNumbers];
    //this.ndcs.push(this.newNDC(prodData));
    this.ndcs.insert(0,this.newNDC(prodData))
    this.isCollapsedSections.unshift(true);
    this.iconNdc = 'fa-angle-up';
    this.cd.detectChanges();

    })
    //return prodData;
  }

  get ndcs(){
    return this.usReturnsForm.get('ndcs') as FormArray;
  }

  removeNDC(ndcIndex: number) {
    this.ndcs.removeAt(ndcIndex);
  }

  ndcBatchNos(ndcIndex: number): FormArray {
    
    return this.ndcs
      .at(ndcIndex)
      .get('batchDetails') as FormArray;
  }

  newBatchNo(batchNo:any): FormGroup {
    return this.fb.group({
      batchNo: [batchNo],
      doses: ['1',{validators:Validators.required,updateOn:'change'}]
    });
  }

  addBatchNo(ndcIndex: number,selectedBatchNo:any) {
   // const selectedBatchNo =event;
   if(selectedBatchNo && !this.isBatchAlreadyAdded(ndcIndex,selectedBatchNo)){
      const batchGroup = this.newBatchNo(selectedBatchNo)
      this.ndcBatchNos(ndcIndex).push(batchGroup);
      this.checkForZero(batchGroup.get('doses'));
      this.getAvailableBatchNos(ndcIndex,selectedBatchNo);
      this.cd.detectChanges();

      //(event.target as HTMLSelectElement).value = '';
    }
    
  }
  checkForZero(control: AbstractControl<any, any> | null) {
    if(!control) return;
    control.valueChanges.subscribe((value) => {
      if(Number(value) == 0){
        control.setValue(1,{emitEvent:false});
      }
    })
  }
  getAvailableBatchNos(ind:number,selectedBatchNo:any) {
    const selectedBNo = this.ndcs.controls.map(control => control.get('batchNumber')?.value).filter(val => val);
    const selectedNdc = this.ndcs.at(ind).get('ndcNumber')?.value;
    //this.availableBatchNos = this.getBatchNumberForNdc(selectedNdc);
    this.availableBatchNos[selectedNdc] = this.availableBatchNos[selectedNdc].filter((batchNo:any) => batchNo !== selectedBatchNo);
    
  }
  getBatchNumberForNdc(selectedNdc: any) {
    const ndcData = this.ndcs.controls.find(data => data.get('ndcNumber')?.value === selectedNdc);
    return ndcData?.get('batchNumber')?.value;
  }

  isBatchAlreadyAdded(ndcIndex: number, selectedBatchNo: string) {
    return this.ndcBatchNos(ndcIndex).controls.some(control => control.get('batchNo')?.value === selectedBatchNo);
  }

  removeBatchNo(ndcIndex: number, batchNoIndex: number,batchNo:any) {
    this.ndcBatchNos(ndcIndex).removeAt(batchNoIndex);
    const ndcNum = this.ndcs.at(ndcIndex).get('ndcNumber')?.value;
    this.availableBatchNos[ndcNum].push(batchNo);
    this.availableBatchNos[ndcNum]=[...this.availableBatchNos[ndcNum]]
    this.cd.detectChanges();
  }

  getTotalDoses(ind:number){
    const batchArray = this.ndcBatchNos(ind);
    return batchArray.controls.reduce((total,group) => {
      const doseVal = Number(group.get('doses')?.value);
      return total+doseVal;
      
    },0)
  }

  //to autogenerate PO
  handleOnAutoGeneratePoNo() {
    this.userInfoService.getPORefNumberForReturns().subscribe(data => {
      this.usReturnsForm.patchValue({
        poNum:data
      })
       //set it to form po num -check
       this.cd.detectChanges();
    });
  }

  toggleSlideNdc(i:number){
    /* if(this.iconNdc === 'fa-angle-down'){
      this.iconNdc = 'fa-angle-up';
      this.isCollapsed=true;
    }
    else{
      this.iconNdc='fa-angle-down';
      this.isCollapsed=false;

    } */
   this.isCollapsedSections = this.isCollapsedSections.map((expanded,index) => index === i ?!expanded :false);
  }

  checkValidInput(event:any){
    let val = event.target.value;
    if(+val < 1){
      event.target.value=1;
    }
    this.usReturnsForm.updateValueAndValidity();
    this.cd.detectChanges();
  }

  ngAfterViewInit(){
  if(!this.cc){

    this.cc = new clickToAddress({
    accessToken: '252fa-0e273-cf8ab-03341',
    countryMatchWith: 'text', 
    enabledCountries: ['United States'],
    domMode: 'id',
    gfxMode: 1,
    style: {
      ambient: 'light', // Use white as main interface color
      accent: 'default' // Use default secondary color for interface
    },
    texts: {
    default_placeholder: 'Start with post/zip code or street',
    },
    showLogo: true,
    disableAutoSearch:false,
  
  });
  }

  ['street','city','state','zip'].forEach(field => {
    this.usReturnsForm.get(field)?.valueChanges.subscribe(() => {
      if(this.userLoggedin && this.userInfo.webEnabled){
        this.usReturnsForm.get('shipToNum')?.patchValue('');
      }
    })
  })

  
  }

  ngOnInit(){
    this.authService.isUserLoggedIn().subscribe(isLoggedin => {
      this.userLoggedin = isLoggedin;
      if(this.userLoggedin){
        this.userInfoService.getUserData().subscribe(res=>{
            if(res){
            this.userInfo=res;
            }
          });
        this.userInfoService.getUserDetailsForReturns().subscribe((data:any) => {
          this.userDetail =data;
          if(this.userInfo.webEnabled){
            this.usReturnsForm.get('orgName')?.clearValidators();
            this.renderer.setStyle(document.querySelector('.SideContent'),'display','block');
            this.usReturnsForm.patchValue({
              email:this.userDetail.email,
              phone:this.formatPhone(this.userDetail.phone),
              soldToNum:this.userDetail.soldToId,
              orgName:this.userDetail.orgName
            })
          }
          else{
            this.renderer.setStyle(document.querySelector('.SideContent'),'display','none');
            this.usReturnsForm.patchValue({
              email:this.userDetail.email,
              phone:this.userDetail.phone
            })
          }
          this.shipLocations = this.userDetail.shipAddress;
        });
      }
    })
    this.usReturnsComponent.data$.subscribe(data => {
      this.usReturnsData = data;
    })
    setTimeout(()=>{
      this.callFetchify();
    },0);

  }
  formatPhone(phone: any): any {
    if(!phone)return '';
    return phone.replace(/^(\d{3})(\d{3})(\d{4})$/,'($1)$2-$3')
  }
  getpdf(pdf: any): string {
    return this.config.backend?.occ?.baseUrl + pdf?.url;
  }

  callFetchify(){
    
    this.cc.attach({
          search:     'returns_AddressLookup',
          town:       'city',
          postcode:  'zip',
          line_1:     'street',
          county:    'state'
        },
        {
          onResultSelected: (c2a: any, elements: any, address: any) => {
            this.usReturnsForm.controls['street']?.setValue(address.line_1);
            this.usReturnsForm.get('street')?.markAsDirty();
            this.usReturnsForm.get('street')?.markAsTouched();
            this.usReturnsForm.controls['city']?.setValue(address.locality);
            this.usReturnsForm.get('city')?.markAsDirty();
            this.usReturnsForm.get('city')?.markAsTouched();
            this.usReturnsForm.controls['state']?.setValue(address.province);
            this.usReturnsForm.get('state')?.markAsDirty();
            this.usReturnsForm.get('state')?.markAsTouched();
            this.usReturnsForm.controls['zip']?.setValue(address.postal_code);
            this.usReturnsForm.get('zip')?.markAsDirty();
            this.usReturnsForm.get('zip')?.markAsTouched();
            this.usReturnsForm.updateValueAndValidity();
            this.cd.detectChanges();
        }
        }); 
  }

  validateFormAndGeneratePdf(){
    this.isFormSubmitted = true;
    this.usReturnsForm.updateValueAndValidity();
    if(this.usReturnsForm.valid && !this.usReturnsForm.get('dEANum')?.value){
      const modalElement = document.getElementById('usreturns_dEANumModal') as HTMLElement;
      if(modalElement){
        const deaNumModal = new window.bootstrap.Modal(modalElement);
        deaNumModal.show();
        
      }
    }
    else{
      this.generatePDFWithTable();
    }
  }

  generatePDFWithTable() {
    
    console.log(this.usReturnsForm.value);
    this.usReturnsForm.updateValueAndValidity();
    if(this.usReturnsForm.valid){
      console.log(this.usReturnsForm.value);
      this.http.get('assets/images/csl_seqirus.png', { responseType: 'blob' }).subscribe(blob => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result as string;
        const pdf = new jsPDF('p', 'mm', 'a4');
        // Add the image to the PDF
        pdf.addImage(base64data, 'PNG', 10, 10, 38, 8);
        // Add custom font to VFS
      pdf.addFileToVFS('Campton-SemiBold.ttf', 'base64-encoded-font-data');
      pdf.addFont('assets/fonts/campton/Campton-SemiBold.ttf', 'CustomFont', 'normal');
      pdf.addFileToVFS('Campton-Book.ttf', 'base64-encoded-font-data');
      pdf.addFont('assets/fonts/campton/Campton-Book.ttf', 'CustomFont1', 'normal');
      // Add Calibri font to jsPDF
      pdf.addFileToVFS('calibri.ttf', 'base64-encoded-font-data');
      pdf.addFont('assets/fonts/calibri.ttf', 'Calibri', 'normal');
        
        const pageWidth = pdf.internal.pageSize.getWidth();
        // Function to add page numbers and other elements
        function addPageNumbers(pdf: jsPDF) {
          const pageCount = pdf.internal.pages.length - 1; // Total number of pages
          for (let i = 1; i <= pageCount; i++) {
            pdf.setPage(i);
            pdf.setFont('helvetica', 'normal'); // Set font to normal
            pdf.setFontSize(10);
            pdf.setTextColor(0, 0, 0);
            pdf.text(`Page ${i} of ${pageCount}`, pdf.internal.pageSize.getWidth() / 2, pdf.internal.pageSize.getHeight() - 10, { align: 'center' });
          }
        }
        // Add Top Right Text
       // Function to add logo and right-side text
const addLogoAndText = () => {
  pdf.addImage('assets/images/csl_seqirus.png', 'PNG', 10, 10, 38, 8);
  const pageWidth = pdf.internal.pageSize.getWidth();
  pdf.setFont('times','normal');
  pdf.setFontSize(12);
  pdf.setTextColor(255, 0, 0);
  pdf.text('Flu360.com', pageWidth - 10, 15, { align: 'right' });
};

      // Add logo and text to the first page
      addLogoAndText();
      // Add a title
       pdf.setFont('CustomFont'); 
       pdf.setFontSize(16);
       pdf.setTextColor(255, 0, 0); // Red color
       pdf.text('INFLUENZA VACCINE RETURN FORM', pageWidth / 2, 30, { align: 'center' });
      
       
        // Add instruction Text
        pdf.setFont('CustomFont'); 
        pdf.setFontSize(10);
        pdf.setTextColor(255, 0, 0); // Red color
        pdf.text('INSTRUCTIONS:',10, 40);
        // Add instruction Text
       

    // Add normal text before the bold part
    pdf.setFont('CustomFont1');
    pdf.setFontSize(10);
    pdf.setTextColor(0, 0, 0); // Red color
    const initialText = 'Request the required Return Authorization (RA) labels from Inmar no later than ';
    pdf.text(initialText, 10, 50);

        // Add bold text with superscript
    const boldText = 'June 15';
    pdf.setFont('helvetica', 'bold');
    const boldTextWidth = pdf.getTextWidth(initialText);
    pdf.text(boldText, 12 + boldTextWidth, 50);
         // Add superscript text
    const superscriptText = 'th';
    const superscriptYOffset = 3; // Adjust this value to position the superscript correctly
    pdf.setFontSize(8); // Slightly smaller font size for superscript
    pdf.text(superscriptText, 15 + boldTextWidth + pdf.getTextWidth(boldText), 52 - superscriptYOffset);

    // Add normal text after the bold part
    pdf.setFont('helvetica', 'normal');
    const remainingText = 'in 1 of 2 ways:';
    pdf.setFontSize(10);
    pdf.text(remainingText, 14 + boldTextWidth + pdf.getTextWidth(boldText + superscriptText), 50);

   

   // Define the list items
   const items = [
    'Complete the form below and email it to',
    'Upload a PDF copy of your form to',
  ];

  // Add the bullet list
  const bulletX = 15;
  let bulletY = 60;
  const bullet = '•';

  items.forEach(item => {
  pdf.setFontSize(17); // Increase font size for the bullet
  pdf.text(bullet, bulletX, bulletY + 1); // Add the bullet
  pdf.setFont('CustomFont1');
  pdf.setFontSize(10); // Reset font size for the text
  pdf.text(item, bulletX + 5, bulletY); // Add the text with additional space
  bulletY += 5; // Move to the next line
  });

  // Add underlined text with hyperlink
  const linkText = 'rarequest@inmar.com';
  const linkX = 90;
  const linkY = 60;
  pdf.setTextColor(0, 0, 255); // Blue color
  pdf.text(linkText, linkX, linkY);

  // Calculate the width of the text
  const textWidth = pdf.getTextWidth(linkText);
  pdf.setDrawColor(0, 0, 255); // Blue color

  // Draw a line beneath the text to simulate an underline
  pdf.setLineWidth(0.1);
  pdf.line(linkX, linkY + 1, linkX + textWidth, linkY + 1);

  // Add the hyperlink
  pdf.textWithLink(linkText, linkX, linkY, { url: 'mailto:rarequest@inmar.com' });


    // Add underlined text with hyperlink
    const linkText1 = 'https://hrm.reskureturns.com';
    const linkX1 = 80;
    const linkY1 = 65;
    pdf.setTextColor(0, 0, 255); // Blue color
    pdf.text(linkText1, linkX1, linkY1);
  
    // Calculate the width of the text
    const textWidth1 = pdf.getTextWidth(linkText1);
    pdf.setDrawColor(0, 0, 255); // Blue color
  
    // Draw a line beneath the text to simulate an underline
    pdf.setLineWidth(0.1);
    pdf.line(linkX1, linkY1 + 1, linkX1 + textWidth1, linkY1 + 1);
  
    // Add the hyperlink
    pdf.textWithLink(linkText1, linkX1, linkY1, { url: 'https://hrm.reskureturns.com' });

    pdf.setFont('CustomFont1');
    pdf.setFontSize(10);
    pdf.setTextColor(0, 0, 0); // Red color
    const initialText1 = 'Once Return Authorization (RA) labels received from Inmar:';
    pdf.text(initialText1, 10, 75);

    
    const newItems = [
      'One or multiple box RA label(s) for your return will be sent to you from Inmar referencing the “Debit Memo/\nPO#” of your choosing entered below. This will be used on your credit memo for your internal reference #.',
      'Include the RA box label(s) with your return. Each box requires its own RA box label placed on the outside \nalong with a shipping label. CSL Seqirus is not responsible for any return associated costs.',
      'Returns received without the RA box labels may cause delays in crediting.',
    ];
    
    // Add the new bullet list
    const newBulletX = 15;
    let newBulletY = 83; // Adjust starting Y position as needed
    const newBullet = '•';
    
    newItems.forEach(item => {
      pdf.setFontSize(17); // Increase font size for the bullet
      pdf.text(newBullet, newBulletX, newBulletY + 0.5); // Add the bullet with a slight downward offset
      pdf.setFontSize(10); // Reset font size for the text
      pdf.text(item, newBulletX + 7, newBulletY); // Add the text with additional space
      newBulletY += 10; // Move to the next line, adjust as needed
    });
    
   // Add the special item with bold and superscript text
const specialItem = 'Returns must be received by Inmar no later than June 28th or your contractual return date. Please keep in mind to plan accordingly as Inmar is closed on weekends.';
const parts = specialItem.split('June 28th');
pdf.setFontSize(17); // Increase font size for the bullet
pdf.text(newBullet, newBulletX, newBulletY-3); // Add the bullet with a slight downward offset
pdf.setFontSize(10); // Reset font size for the text


// Manually control the line breaks
const firstPart = 'Returns must be received by Inmar no later than                   or your contractual return date. Please keep in';
const secondPart = 'mind to plan accordingly as Inmar is closed on weekends.';

pdf.text(firstPart, newBulletX + 7, newBulletY-3); // Add the first part of the text

// Add bold text with superscript
pdf.setFont('helvetica', 'bold');
pdf.text('June 28', newBulletX + 7 + 83, newBulletY-3); // Add the bold part
pdf.setFontSize(8); // Slightly smaller font size for superscript
pdf.text('th', newBulletX + 7 + 96 , newBulletY - 4); // Add the superscript part

pdf.setFontSize(10); // Reset font size for the remaining text
pdf.setFont('helvetica', 'normal');

// Split the remaining part of the text to fit within the page width
const wrappedSecondPart = pdf.splitTextToSize(secondPart, 180); // Adjust the width as needed
pdf.text(wrappedSecondPart, newBulletX + 7, newBulletY + 2); // Add the remaining part of the text on the next line

newBulletY += wrappedSecondPart.length * 5 + 7; // Adjust the gap for the next item

// Add the address item
const addressItem = 'Send returns to:\n     Inmar RX Solutions\n     3845 Grand Lakes Way\n     Suite 125\n     Grand Prairie, TX 75050';
const addressLines = addressItem.split('\n');
pdf.setFontSize(17); // Increase font size for the bullet
pdf.text(newBullet, newBulletX, newBulletY - 3); // Add the bullet with a slight downward offset
pdf.setFontSize(10); // Reset font size for the text
addressLines.forEach(line => {
  pdf.text(line, newBulletX + 7, newBulletY-3); // Add each line of the address
  newBulletY += 5; // Move to the next line
});

// Add the italic text
const italicText = 'Please keep a copy of this form and the product return tracking information for your records.';
pdf.setFont('helvetica', 'italic');
pdf.setFontSize(10);
pdf.text(italicText, newBulletX, newBulletY + 10); // Adjust the position as needed


// Define the table rows
const checkedImage = 'assets/images/checked.png';
const uncheckedImage = 'assets/images/unchecked.png';

const tableRows = [
  [{ content: 'Customer Email Address: ' + (this.usReturnsForm.get('email')?.value || ''), colSpan: 4 }],
  [{ content: 'Debit Memo/PO#: ' + (this.usReturnsForm.get('poNum')?.value || ''), colSpan: 4 }],
  [{ content: 'Organization’s Name: ' + (this.usReturnsForm.get('orgName')?.value || ''), colSpan: 4 }],
  [
    { content: 'CSL Seqirus Sold to Account #: ' + (this.usReturnsForm.get('soldToNum')?.value || ''), colSpan: 2 },
    { content: 'DEA #: ' + (this.usReturnsForm.get('dEANum')?.value || ''), colSpan: 2 }
  ],
  [
    { content: 'CSL Seqirus Ship to Account # (or Store #): ' + (this.usReturnsForm.get('shipToNum')?.value || ''), colSpan: 2 },
    { content: 'Street Address: ' + (this.usReturnsForm.get('street')?.value || ''), colSpan: 2 }
  ],
  [
    { content: 'City: ' + (this.usReturnsForm.get('city')?.value || ''), colSpan: 1 },
    { content: 'State: ' + (this.usReturnsForm.get('state')?.value || ''), colSpan: 1 },
    { content: 'Zip: ' + (this.usReturnsForm.get('zip')?.value || ''), colSpan: 1 },
    { content: 'Phone: ' + (this.usReturnsForm.get('phone')?.value || ''), colSpan: 1 }
  ],
  [
    { 
      content: 'How was product purchased?\n\n' + 
               '     Direct from CSL Seqirus\n\n' + 
               '     Wholesaler: '+ (this.usReturnsForm.get('wholesalerName')?.value || ''),
      colSpan: 2 
    },
    { content: '# of RA Labels Needed: ' + (this.usReturnsForm.get('raLabel')?.value || ''), colSpan: 2 }
  ]
];

const tableHeader = [
  [{ content: 'Customer Information', colSpan: 4 }]
];



// Add the table to the PDF
autoTable(pdf, {
  head: tableHeader,
  body: tableRows, // Each row is a single cell
  startY: newBulletY + 14, // Adjust the starting Y position as needed
  theme: 'grid',
  styles: {
    fontSize: 9,
    cellPadding: 3,
    overflow: 'linebreak',
    halign: 'left',
    valign: 'top',
    lineColor: [0, 0, 0],
    lineWidth: 0.1,
  },
  headStyles: {
    fillColor: [255, 0, 0], // Red background
    textColor: [255, 255, 255], // White text
    fontStyle: 'bold',
    fontSize: 12,
    halign: 'center', // Center align text
    valign: 'middle', // Vertically center text
  },
  bodyStyles: {
    fillColor: [255, 255, 255],
  },
  alternateRowStyles: {
    fillColor: [255, 255, 255],
  },
  didDrawCell: (data) => {
     // Draw the top border for the header cell
     if (data.row.index === 0 && data.column.index === 0 && data.section === 'head') {
      const cell = data.cell;
      pdf.setDrawColor(0, 0, 0); // Black color
      pdf.setLineWidth(2); // 2px width
      pdf.line(cell.x, cell.y, cell.x + cell.width, cell.y); // Top border
    }
    // Draw checkboxes manually
    if (data.row.index === 6 && data.column.index === 0) {
      const checkboxX = data.cell.x + 2;
      const checkboxY = data.cell.y + 7;
      const checkboxSize = 4;

      // Draw the first checkbox
      const firstCheckboxImage = this.usReturnsForm.get('purchaseFrom')?.value === 'csl' ? checkedImage : uncheckedImage;
      pdf.addImage(firstCheckboxImage, 'PNG', checkboxX, checkboxY + 2.5, checkboxSize, checkboxSize);

      // Draw the second checkbox
      const secondCheckboxImage = this.usReturnsForm.get('purchaseFrom')?.value === 'wholesaler' ? checkedImage : uncheckedImage;
      pdf.addImage(secondCheckboxImage, 'PNG', checkboxX, checkboxY + 10, checkboxSize, checkboxSize);
    }
    // Draw underline for wholesaler name
    if (data.row.index === 6 && data.column.index === 0) {
      const wholesalerName = this.usReturnsForm.get('wholesalerName')?.value || '';
      const textWidth = pdf.getTextWidth(wholesalerName);
      const underlineX = data.cell.x + 3 + pdf.getTextWidth('     Wholesaler: ');
      const underlineY = data.cell.y + 22; // Adjust Y position as needed
      pdf.setDrawColor(0, 0, 0);
      pdf.setLineWidth(0.1);
      pdf.line(underlineX, underlineY, underlineX + textWidth, underlineY);
    }

    
  },
  didDrawPage: (data) => {
    // Add logo and text to subsequent pages
    if (data.pageNumber > 1) {
      addLogoAndText();
    }
  }
});

// Add a new page for the second table
pdf.addPage();
addLogoAndText();

// Draw the custom header
// Draw the custom header
const headerYPosition = 30; // Adjust this value to move the header downward
const headerHeight = 10; // Decreased height for the header
pdf.setFillColor(255, 0, 0); // Red background
pdf.rect(10, headerYPosition, pageWidth - 20, headerHeight, 'F'); // Draw filled rectangle with decreased height
pdf.setDrawColor(0, 0, 0); // Black color for border
pdf.setLineWidth(2); // 2px width
pdf.line(10, headerYPosition, pageWidth - 10, headerYPosition); // Top border
pdf.line(10, headerYPosition + headerHeight, pageWidth - 10, headerYPosition + headerHeight); // Bottom border
pdf.setFontSize(10);
pdf.setFont('helvetica', 'bold'); // Set font to bold
pdf.setTextColor(255, 255, 255); // White text
pdf.text('Returns Product Information', pageWidth / 2, headerYPosition + 6, { align: 'center' }); // Adjust y position

// Define the second table structure
const secondTableHeader = [
  ['Product Name', 'NDC#', 'Quantity (Doses)', 'Batch/Lot#']
];

// Generate the second table rows dynamically from the form values
const secondTableRows = (this.usReturnsForm.get('ndcs')?.value || []).flatMap((ndc: any) => 
  ndc.batchDetails.map((batchDetail: any) => [
    ndc.productName,
    this.formatNdcNumber(ndc.ndcNumber),
    this.formatDoseCount(batchDetail.doses),
    batchDetail.batchNo
  ])
);

// Add the second table to the new page
autoTable(pdf, {
  head: secondTableHeader,
  body: secondTableRows,
  startY: 41, // Adjust startY to position the table below the custom header
  theme: 'grid',
  margin: { left: 10, right: 10 }, // Set the x coordinate to match the custom header
  //tableWidth: pageWidth - 20, // Set the table width to match the custom header
  styles: {
    fontSize: 9,
    cellPadding: 3,
    overflow: 'linebreak',
    halign: 'left',
    valign: 'top',
    lineColor: [0, 0, 0],
    lineWidth: 0.1,
  },
  headStyles: {
    fillColor: [224, 224, 224], // Gray background
    textColor: [0, 0, 0], // White text
    fontStyle: 'normal',
    halign: 'center', // Center align text
    valign: 'middle', // Vertically center text
    fontSize: 10, // Font size for the second table header
  },
  bodyStyles: {
    fillColor: [255, 255, 255],
  },
  alternateRowStyles: {
    fillColor: [255, 255, 255],
  },
  
});
// Access the finalY position of the last autoTable
const finalY = (pdf as any).lastAutoTable.finalY;
// Add the specified text below the second table
const finalText = 'All returns are subject to customer’s contract or the CSL Seqirus terms and conditions.\nThe Returning Party will pay transportation charges. CSL Seqirus shall not pay or give reimbursement for\n transportation, service, handling, or processing fees.';
pdf.setFont('helvetica', 'normal'); // Set font to normal
pdf.setFontSize(10);
pdf.setTextColor(0, 0, 0);
pdf.text(finalText, pdf.internal.pageSize.getWidth() / 2, finalY + 10, { align: 'center' });

// Add the new text with left alignment
const newText = 'USA-SEQ-25-0010';
pdf.setFont('helvetica', 'normal'); // Set font to normal
pdf.setFontSize(10);
pdf.setTextColor(0, 0, 0);
pdf.text(newText, 10, finalY + 30); // Adjust the Y position as needed


// Add page numbers after all content is added
addPageNumbers(pdf);


      // Save the PDF
      pdf.save('CSL Seqirus Customer Returns Form.pdf');

      // Generate the PDF as a Blob
    /* const pdfBlob = pdf.output('blob');

    // Create a URL for the Blob
    const pdfUrl = URL.createObjectURL(pdfBlob);

    // Open the URL in a new tab
    window.open(pdfUrl); */
    
    };
    });
 }
  }
  formatDoseCount(doses: any) {
   return new Intl.NumberFormat('en-US').format(doses);
  }
  formatNdcNumber(ndcNumber: any) {
    if(!ndcNumber)return'';
    return ndcNumber.replace(/^(\d{5})(\d{4})?(\d+)?$/,(_: any,p1: any,p2: any,p3: any) => [p1,p2,p3].filter(Boolean).join('-'));
  }

}

export function formArrayNotEmpty(control:AbstractControl):{[key:string]:any}| null {
  const formArray = control as FormArray;
  return formArray.length > 0 ? null :{required:true};
}


