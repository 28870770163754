import { Component,AfterViewInit,OnInit,ViewChild,HostListener, ChangeDetectorRef, Renderer2, ElementRef } from '@angular/core';
import { Observable } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { CustomfilterPipe } from '../../cms-common/Pipes/customfilter.pipe'; 
import { FormControl } from '@angular/forms';
import { CmsService } from '@spartacus/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CalendarOptions, EventInput } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import moment from 'moment';

import { FullCalendarComponent } from '@fullcalendar/angular';
import dayjs from 'dayjs';
import { StartOrderService } from '../../custom-start-order/start-order.service';



export interface PeriodicElement1 {
  loc: string;
  ordernum: number;
  prodname: string;
  season:string;
  status:string;
  unit:number;

}
export interface Products {
  materialID: string,
  itemNumber: string,
  quantity: string,
  baseName: string,
  presentationName: string,
  currencyIso: string,
  formattedValue: string,
  doses: string,
  seqId: string,
  sellable: true,
  price: 0
}

@Component({
  selector: 'app-uk-my-orders-left-section',
  templateUrl: './uk-my-orders-left-section.component.html',
  styleUrl: './uk-my-orders-left-section.component.scss',
 // encapsulation: ViewEncapsulation.None,
})
export class UkMyOrdersLeftSectionComponent  implements OnInit,AfterViewInit  {
  togglefilterView:boolean=false;
  togglesortView:boolean=false;
  calendarPlugins = [dayGridPlugin, timeGridPlugin, interactionPlugin];
  calendarEvents: EventInput[] = [{ title: 'Event Now', start: new Date() }];
  
  all_events:any[] =[]  

// {
//   "title": " ",
//   "start": "2023-11-21",
//   "end": "2023-11-25",
//   "description": null,
//   "className": null
// }
  @ViewChild('calendar') calendarComponent0!: FullCalendarComponent;
  @ViewChild('calendar1') calendarComponent1!: FullCalendarComponent;
  @ViewChild('calendar2') calendarComponent2!: FullCalendarComponent;
  startDate1: any;
  startDate2: any;
  startDate3: any;
  endDate1: any;
  endDate2:any;
  endDate3:any
  eventCalender: any;

  getStartDate(){
    return this.calendarOptions0.initialDate
    // start: moment(this.dropdownValue+'-09-01').format('YYYY-MM-DD'),
    // end: moment().format('YYYY-MM-DD')
    //return this.dropdownValue ;
  }
  getEndDate(){
    this.userInfoService.getDropdownValue().subscribe(value => {
      this.dropdownValue = value;
    })
    //   // Generate a new date for manipulating in the next step
       var startDate = new Date(moment(this.dropdownValue.substr(0,4)+'-09-01').format('YYYY-MM-DD'));
       var endDate = new Date(moment(this.dropdownValue.substr(0,4)+'-09-01').add(5,'M').format('YYYY-MM-DD'));

    //   // // Adjust the start & end dates, respectively
    //   startDate.setDate(startDate.getDate() ); // One day in the past
    //  endDate.setDate(endDate.getDate()); // Two days into the future
    // console.log("get End Date ==>", { start:moment(startDate).format('YYYY-MM-DD'), end: moment(endDate).format('YYYY-MM-DD') });
      return { start:moment(startDate).format('YYYY-MM-DD'), end: moment(endDate).format('YYYY-MM-DD') };
    
  }
  getEndDate1(){
    //this.cd.detectChanges();
    
  //  console.log('getenddate',this.startDate2,this.endDate2 )
    // Generate a new date for manipulating in the next step
    var startDate = new Date(moment(this.dropdownValue.substr(0,4)+'-10-01').format('YYYY-MM-DD'));
    var endDate = new Date(moment(this.dropdownValue.substr(0,4)+'-10-01').add(5,'M').subtract(1, 'd').format('YYYY-MM-DD'));

    // // Adjust the start & end dates, respectively
    startDate.setDate(startDate.getDate() ); // One 0day in the past
    endDate.setDate(endDate.getDate()); // Two days into the future
    // console.log("get End Date 1 ==>", { start:moment(startDate).format('YYYY-MM-DD'), end: moment(endDate).format('YYYY-MM-DD') });
    return { start: startDate, end: endDate };
  
}
getEndDate2(){
  //this.cd.detectChanges();
  // Generate a new date for manipulating in the next step
   var startDate = new Date(moment(this.dropdownValue.substr(0,4)+'-11-01').format('YYYY-MM-DD'));
  var endDate = new Date(moment(this.dropdownValue.substr(0,4)+'-11-01').add(5,'M').subtract(1, 'd').format('YYYY-MM-DD'));

  // // Adjust the start & end dates, respectively
   startDate.setDate(startDate.getDate() ); // One day in the past
   endDate.setDate(endDate.getDate()); // Two days into the future
  //  console.log("get End Date 2 ==>", { start:moment(startDate).format('YYYY-MM-DD'), end: moment(endDate).format('YYYY-MM-DD') });
  return { start: startDate, end: endDate };

}
  calendarOptions0: CalendarOptions = {
 
    plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
    dayHeaderFormat: { weekday: 'narrow' },
    initialDate: moment().format('YYYY-MM-DD'),
      editable: true,
      showNonCurrentDates: false,
      titleFormat: { month: 'short', year: 'numeric' },
      headerToolbar: {
       left: 'prev,myCustomPreviousButton1',
      center: 'title',
      right: 'myCustomNextButton1'
     },
  validRange: this.getEndDate(),
     customButtons: {
      myCustomPreviousButton1:{
        text: 'Previous',
        click:() =>  this.handlePreviousMonthClick()
      },
      myCustomNextButton1:{
        click:() =>  this.handleNextMonthClick()
      }
    },
  }
  calendarOptions1: CalendarOptions = {
    plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
    headerToolbar: {
      left: 'myCustomPreviousButton1',
      center: 'title',
      right: 'myCustomNextButton1'
    },
    showNonCurrentDates: false,
    titleFormat: { month: 'short', year: 'numeric' },
    
    customButtons: {
      myCustomPreviousButton1:{
        text: '',
        click:() =>  this.handlePreviousMonthClick()
      },
      myCustomNextButton1:{
        text: '',
        click:() =>  this.handleNextMonthClick()
      }
    },
   validRange: this.getEndDate1(),
   //events: this.all_events,
   
    dayHeaderFormat: { weekday: 'narrow' },
    initialDate: moment().format('YYYY-MM-DD'),
    //events: 
    // eventContent: function(arg:any) {
    //   let italicEl = document.createElement('i');
    //   if (arg.event.extendedProps.icon) {
    //     italicEl.classList.add('fa', 'fa-' + arg.event.extendedProps.icon);
    //   }
    //   let arrayOfDomNodes = [ italicEl ];
    //   return { domNodes: arrayOfDomNodes };
    // },
  //   eventDidMount: function(info) {
  //     const eventStart = dayjs(info.event.start);
  //     const dateString = eventStart.format('YYYY-MM-DD');
  //     const evStart = dayjs(info.view.activeStart).subtract(1, 'day');
  //     const evEnd = dayjs(info.view.activeEnd).subtract(1, 'day');
  //     if (!eventStart.isAfter(evStart) || eventStart.isAfter(evEnd)) { return; }
  //     const dayNumberEl:any = info.el.querySelector('.fc-day-number[data-date="' + dateString + '"]');
  //     if (info.event.title === "delivered") {
  //       dayNumberEl.style.backgroundColor = '#DF323F';
  //       dayNumberEl.style.color = '#ffffff';
  //       dayNumberEl.style.borderRadius = '50%';
  //       dayNumberEl.style.padding = '0px 5px';
  //       dayNumberEl.style.marginTop = '0.2rem';
  //       if (info.view.type === 'dayGridMonth') {
  //         info.el.style.display = 'none';
  //       }
  //     }
  //     if (info.event.title === "schedule") {
  //       dayNumberEl.style.backgroundColor = 'transparent';
  //       dayNumberEl.style.color = '#2A3237';
  //       dayNumberEl.style.display = 'block';
  //       dayNumberEl.style.padding = '0px 7px';
  //       dayNumberEl.style.marginTop = '0.2rem';
  //       dayNumberEl.style.border = '2px solid #EA181B';
  //       dayNumberEl.style.position = 'absolute';
  //       dayNumberEl.style.width = '10%';
  //       dayNumberEl.style.padding = '0px 2px';
  //       if (info.view.type === 'dayGridMonth') {
  //         info.el.style.display = 'none';
  //       }
  //     }
  //     if (info.event.title === "window") {
  //       if (info.view.type === 'dayGridMonth') {
  //         info.el.style.display = 'block';
  //       }
  //     }
  // }
  
  
}
  calendarOptions2: CalendarOptions = {
    plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
    initialDate: moment().format('YYYY-MM-DD'),
    headerToolbar: {
      left: 'myCustomPreviousButton',
      center: 'title',
      right: 'next,myCustomNextButton'
    },
    showNonCurrentDates: false,
    titleFormat: { month: 'short', year: 'numeric' },
    customButtons: {
      myCustomNextButton: {
        text: 'Next',
        click: () =>this.handleNextMonthClick()
      },
      myCustomPreviousButton: {
             text: '',
             click:()=> this.handlePreviousMonthClick()
           }
     
    },
    dayHeaderFormat: { weekday: 'narrow' },
    validRange: this.getEndDate2(),
   //events: this.all_events,
    // eventDidMount: function(info) {
    //   const eventStart = dayjs(info.event.start);
    //   const dateString = eventStart.format('YYYY-MM-DD');
    //   const evStart = dayjs(info.view.activeStart).subtract(1, 'day');
    //   const evEnd = dayjs(info.view.activeEnd).subtract(1, 'day');
    //   if (!eventStart.isAfter(evStart) || eventStart.isAfter(evEnd)) { return; }
    //   const dayNumberEl:any = info.el.querySelector('.fc-day-number[data-date="' + dateString + '"]');
    //   if (info.event.title === "delivered") {
    //     dayNumberEl.style.backgroundColor = '#DF323F';
    //     dayNumberEl.style.color = '#ffffff';
    //     dayNumberEl.style.borderRadius = '50%';
    //     dayNumberEl.style.padding = '0px 5px';
    //     dayNumberEl.style.marginTop = '0.2rem';
    //     if (info.view.type === 'dayGridMonth') {
    //       info.el.style.display = 'none';
    //     }
    //   }
    //   if (info.event.title === "schedule") {
    //     dayNumberEl.style.backgroundColor = 'transparent';
    //     dayNumberEl.style.color = '#2A3237';
    //     dayNumberEl.style.display = 'block';
    //     dayNumberEl.style.padding = '0px 7px';
    //     dayNumberEl.style.marginTop = '0.2rem';
    //     dayNumberEl.style.border = '2px solid #EA181B';
    //     dayNumberEl.style.position = 'absolute';
    //     dayNumberEl.style.width = '10%';
    //     dayNumberEl.style.padding = '0px 2px';
    //     if (info.view.type === 'dayGridMonth') {
    //       info.el.style.display = 'none';
    //     }
    //   }
    //   if (info.event.title === "window") {
    //     if (info.view.type === 'dayGridMonth') {
    //       info.el.style.display = 'block';
    //     }
    //   }
    // },
  }
  calendarApi: any;
  finalDosesCount: number = 0;
  // eventRender(info:any) {
  //   const event = info.event;
  //   const element = info.el;
  //   const dateString = moment(event.start).format('YYYY-MM-DD');
  //   const evStart = moment(info.view.activeStart).subtract(1, 'days');
  //   const evEnd = moment(info.view.activeEnd).subtract(1, 'days');
   
   
 
  //   if (!moment(event.start).isAfter(evStart) || moment(event.start).isAfter(evEnd)) {
  //     return false;
  //   }

  //   if (event.title === 'delivered') {
  //     element.style.backgroundColor = '#DF323F';
  //     element.style.color = '#ffffff';
  //     element.style.borderRadius = '50%';
  //     element.style.padding = '0px 5px';
  //     element.style.marginTop = '0.2rem';
  //   } else if (event.title === 'schedule') {
  //     element.style.backgroundColor = 'transparent';
  //     element.style.color = '#2A3237';
  //     element.style.border = '2px solid #EA181B';
  //     element.style.position = 'absolute';
  //     element.style.width = '10%';
  //     element.style.padding = '0px 2px';
  //   } else if (event.title === 'window') {
  //     element.style.display = 'block';
  //   }
  //   return true;
  // }

  // viewRender(info:any) {
  //   const view = info.view;
  //   const cur = view.activeStart;
  //   const d = moment(cur).add(1, 'months');
  //   const d1 = moment(cur).add(2, 'months');
  //   const cal1 = document.querySelector('#openorder_calendar1_');
  //   const cal2 = document.querySelector('#openorder_calendar2_');

   

  //   const now = new Date(parseInt(this.dropdownValue), 8, 1);
  //   const end = new Date(parseInt(this.dropdownValue) + 1, 3, 31);
  //   const calDateString = `${view.activeStart.month()}/${view.activeStart.year()}`;
  //   const curDateString = `${now.getMonth()}/${now.getFullYear()}`;
  //   const endDateString = `${end.getMonth() - 4}/${end.getFullYear()}`;

    
  // }
  // updateHeader() {
  //   this.calendarOptions2.headerToolbar = {
  //     left: 'prev,next myCustomPreviousButton',
  //     center: 'title',
  //     right: '',
  //   };
  // }

  handlePreviousMonthClick(){
    
    let calendarApi1 = this.calendarComponent0.getApi();
    let calendarApi2 = this.calendarComponent1.getApi();
    let calendarApi3 = this.calendarComponent2.getApi();
    calendarApi1.prev();   
    calendarApi2.prev();   
    calendarApi3.prev();         
        // const prevButton = document.getElementsByClassName('.fc-prev-button')[0] as HTMLElement;
        // if (prevButton) {
        //   prevButton.click();
        // }
    
    
  }
  handleNextMonthClick(){
    let calendarApi1 = this.calendarComponent0.getApi();
    let calendarApi2 = this.calendarComponent1.getApi();
    let calendarApi3 = this.calendarComponent2.getApi();
    calendarApi1.next();
    calendarApi2.next();
    calendarApi3.next();
      // const nextButton = document.querySelector('.fc-next-button') as HTMLElement;
      // if (nextButton) {
      //   nextButton.click();
      // }
     // const calendarApi = this.calendarComponent2.getApi();
      //this.calendarApi.next();
  }

  
  // @HostListener('window:click', ['$event'])
  // onArrowClick(event: any) {
  //   document.querySelectorAll('[data-toggle="popover"],[data-original-title]').forEach(element => {
  //     if (!element.contains(event.target as Node) && !document.querySelector('.popover')?.contains(event.target as Node)) {
  //       element.classList.add('fc-normal');
  //       element.classList.remove('fc-event-select');
  //       (element as any).popover('hide');
  //     }
  //   });
  // }

  // @HostListener('click', ['$event'])
  // onArrowClick(event: any) {
  //   if ((event.target as HTMLElement).classList.contains('fc-icon-right-single-arrow') ||
  //       (event.target as HTMLElement).classList.contains('fc-icon-left-single-arrow')) {
  //     document.querySelectorAll('.popover').forEach(popover => {
  //       (popover as any).popover('hide');
  //     });
  //   }
  // }


  @HostListener('document:click',['$event'])
  onDocumentClick(event:MouseEvent):void{
    if(this.togglefilterView){
      this.togglefilterView =false;
      if(event.target === this.eleRef.nativeElement.querySelector('filterbox')){
        this.togglefilterView = true;
      }
    }
  if(this.togglesortView){
    this.togglesortView =false;
    if(event.target === this.eleRef.nativeElement.querySelector('sortbox')){
      this.togglesortView = true;
    }
  }
  }


   
  
    
  
     

handleDateClick(arg:any) {
  alert('date click! ' + arg.dateStr);
}
  displayedColumns: string[] = ['combined'];
 //dataSource = new MatTableDataSource<any>();
 dataSource:any=[];
 filteredData1:any;
 dataSource1= new MatTableDataSource<any>();
 dataSource2= new MatTableDataSource<any>();
 dataSource3= new MatTableDataSource<any>();
 displayedColumns1: string[] = ['baseProdName', 'confirmedQty', 'listPrice','perDosePrice']; 
displayproduct:string[]=['detailedStatus', 'deliveryDate', 'volume1', 'volume2'];
 dataSourceWithPageSize = new MatTableDataSource<any>;
  @ViewChild('paginator') paginator!: MatPaginator;
  @ViewChild(MatSort)
sort!: MatSort;
  checkout: any;
  loadedFromSummary: any;
  inSeason: any;
  deliveriesTable: any;
  selectedorderactive:any;
  
  dataSource4 = new MatTableDataSource<any>();

dropdownValue:any;
getfilterdata:any;
apiresponse:any;
rightsectionresponse:any;
count=0;
getstatus:any
selectedproducts:any[]=[];
selectedstatus:any[]=[];
isLoading = false;
totalshippingquantity:number=0;
sortvalue:string=""


////////////////////////////////
rightisLoading = false;
editdata=false;
editdatasource = new MatTableDataSource<any>();
editcolumns: string[]=['fullProductName','price','totalOrderdQty','errorMessage'];
initialQuantities: Products[]=[];
originalQuantities:number[]=[];
totalQuantity: number = 0;
isFormValid:boolean = true;
activemembership:any 
nomembership:any 
membershipdata:any
selectedOrder: string | null = null;
amendactive:boolean=false;
showingLabel: string = "Showing";
  pageSize: number= 6;
  pageSizeControl = new FormControl(6);
  totalLength = 0;
  currentPage: number = 1;
  valformsummary :any;
  pageSizeOptions: number[] = [5, 10, 15];
firstvalue:string='';
filterValues = {
  search: '',
  filterbyproduct:[''],
  filterbystatus:[''],
};
productlist={
 
}
enablePage: any;
// year = moment().format("YYYY")
seasonValue: string = '';
   constructor(private userInfoService: UserInfoService,private cd:ChangeDetectorRef,private cmsService:CmsService,private activatedRoute: ActivatedRoute,private router: Router, private eleRef:ElementRef,private startOrderService: StartOrderService,private renderer: Renderer2) {
   
    
   this.userInfoService.filterstatus().subscribe((data)=>{
    this.getstatus=data;
   
 });

  // Assign the data to the data source for the table to render
 
   }
 

  ngOnInit(): void {
    if(!this.amendactive){
    this.userInfoService.getEnablePage().subscribe((data: any) => {
      this.enablePage = data;
    })
    this.userInfoService.getstartorderseason().subscribe((data:any) => {
      this.seasonValue = data;
    })
   // this.calendarApi = this.calendarComponent.getApi();
    //console.log(this.calendarApi)
    this.isLoading = true;
    this.userInfoService.getDropdownValue().subscribe(value => {
     this.dropdownValue = value;
     this.userInfoService.filterproduct(this.dropdownValue).subscribe((data)=>{
      this.getfilterdata=data;  
   });
     if(!this.amendactive){
      this.leftsection(this.dropdownValue);
      this.rightsectionresponse="";
      let subStr = this.dropdownValue.substr(0,4)
      
      
      this.startDate1 =  moment(subStr+'-09-01').format('YYYY-MM-DD');
      this.endDate1 =moment(subStr+'-09-01').add(4, 'M').format('DD-MM-YYYY');
    
      this.startDate2 = moment(subStr+'-10-01').format('YYYY-MM-DD');
      this.endDate2 =moment(subStr+'-10-01').add(4, 'M').format('DD-MM-YYYY');
      this.startDate3 = moment(subStr+'-11-01').format('YYYY-MM-DD');
      this.endDate3 =moment(subStr+'-11-01').add(4, 'M').format('DD-MM-YYYY');
      this.calendarOptions0.initialDate = moment(subStr+'-09-01').format('YYYY-MM-DD')
      this.calendarOptions1.initialDate = moment(subStr+'-10-01').format('YYYY-MM-DD')
      this.calendarOptions2.initialDate = moment(subStr+'-11-01').format('YYYY-MM-DD')
      
      // this.calendarComponent0.validRange ={
      //   start: moment(this.dropdownValue+'-09-01').format('YYYY-MM-DD'),
      //   end: moment().format('YYYY-MM-DD')
      // }
      //this.
     }
    }); 

    this.userInfoService.MembershipDetails().subscribe(res=>{
      this.membershipdata = res;
    });
    this.cmsService.getComponentData('Membership-myOrdersMessageComponent1').subscribe(res=>{
      this.activemembership=res;
  });
  this.cmsService.getComponentData('Membership-myOrdersMessageComponent2').subscribe(res=>{
 this.nomembership=res;
});
  }
  }

  ngAfterViewInit(){
    //this.calendarApi = this.calendarComponent2.getApi();
  
   let subStr = this.dropdownValue.substr(0,4)
   this.startDate1 =  moment(subStr+'-09-01').format('YYYY-MM-DD');
      this.endDate1 =moment(subStr+'-09-01').add(4, 'M').format('DD-MM-YYYY');
    
      this.startDate2 = moment(subStr+'-10-01').format('YYYY-MM-DD');
      this.endDate2 =moment(subStr+'-10-01').add(4, 'M').format('DD-MM-YYYY');
      this.startDate3 = moment(subStr+'-11-01').format('YYYY-MM-DD');
      this.endDate3 =moment(subStr+'-11-01').add(4, 'M').format('DD-MM-YYYY');
      this.calendarOptions0.initialDate = moment(subStr+'-09-01').format('YYYY-MM-DD')
      this.calendarOptions1.initialDate = moment(subStr+'-10-01').format('YYYY-MM-DD')
      this.calendarOptions2.initialDate = moment(subStr+'-11-01').format('YYYY-MM-DD')

   //this.calendarOptions0.initialDate = moment(this.dropdownValue).format('YYYY-MM-DD')+ '-09-01'
    this.cd.detectChanges(); 
  }
  getTotalCost() {
    return this.rightsectionresponse.seasonData[0].totalCost;
  }
  getFinalDiscount() {
    return this.finalDosesCount;
  }
  inputsearch(event:Event){
    const filterValue = (event.target as HTMLInputElement).value;
  
    this.filterValues.search =filterValue.trim().toLowerCase(); 
  }
  incrementcountproduct(event:any,i:number){
   
    if (event.target.checked) {
      

      this.count++;
      this.selectedproducts.push(event.target.value);
      this.filterValues.filterbyproduct=this.selectedproducts;
     
    } else {
  
      var index = this.selectedproducts.indexOf(event.target.value);
      this.selectedproducts.splice(index, 1);
      this.filterValues.filterbyproduct=this.selectedproducts;
     this.count--;
    } 
  } 
  incrementcountstatus(event:any,i:number){
   
    if (event.target.checked) {

      this.count++;
      this.selectedstatus.push(event.target.value);
      this.filterValues.filterbystatus=this.selectedstatus;
     
    } else {
      var index = this.selectedstatus.indexOf(event.target.value);
      this.selectedstatus.splice(index, 1);
      this.filterValues.filterbystatus=this.selectedstatus;
     this.count--;
    } 
  } 
  onSortClicked(){
    this.dataSource.data = this.apiresponse.seasonData;
    let data = this.dataSource.sortData(this.dataSource.data, this.dataSource.sort);
    const starti = (this.currentPage - 1) * this.pageSize;
    const endi = starti + this.pageSize;
    this.dataSource.data = data.slice(starti, endi);
  }
  
  sortTest(val:any){
    this.sortvalue=val
    let newdir :string = 'desc'
    if(val == '1to9' || val == '9to1'){
    const sortState: Sort = {active: 'orderId', direction: val == '1to9'?'asc': 'desc'};    
    this.sort.active = sortState.active;
    this.sort.direction = sortState.direction;
    this.sort.sortChange.emit(sortState);
    
    this.onSortClicked()

    }
    else if(val == 'AtoZ' || val == 'ZtoA'){
      const sortState: Sort = {active: 'delLocation', direction: val == 'AtoZ'?'asc': 'desc'};    
      this.sort.active = sortState.active;
      this.sort.direction = sortState.direction;
      this.sort.sortChange.emit(sortState);
      
      this.onSortClicked()
   
    }
    else if(val == 'LeastRecent' || val == 'MostRecent'){
      const sortState: Sort = {active: 'orderId', direction: val == 'LeastRecent'?'asc': 'desc'};    
      this.sort.active = sortState.active;
      this.sort.direction = sortState.direction;
      this.sort.sortChange.emit(sortState);
      
      this.onSortClicked()
 
    }
    const temp1= <HTMLElement> <unknown>document.getElementById(this.selectedorderactive);
    if(temp1){
    temp1.classList.add('activeorder');
    }
  }
  leftsection(currentdropdownValue:any){
    this.userInfoService.UkMyorderleftsection(currentdropdownValue).subscribe(value=>{
      this.apiresponse=value;
 
      this.inSeason = this.apiresponse?.seasonData[0]?.season
      this.dataSource = new MatTableDataSource(this.apiresponse.seasonData);
      this.dataSourceWithPageSize = new MatTableDataSource(this.apiresponse.seasonData);
      this.isLoading = false;
      this.cd.detectChanges();
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort; 
      this.totalLength = this.apiresponse.seasonData.length;
      this.updateTableData()
      this.firstvalue=this.apiresponse?.seasonData[0]?.orderId;
      this.activatedRoute.queryParams.subscribe(params => {   
        
  
        this.loadedFromSummary = params;
        if(this.loadedFromSummary.address) {
        this.filterValues.search = this.loadedFromSummary?.address;
        this.applyFilter();
        this.firstvalue = this.dataSource.filteredData[0]?.orderId;
        this.valformsummary = this.loadedFromSummary.address
        }else{
          this.filterValues.search ="";
          this.valformsummary=""
         
        }
       
        
         // Print the parameter to the console. 
    });
      if(this.firstvalue){
      this.openrightsectio(this.firstvalue);
      this.selectedorderactive=this.firstvalue
      }
      else{
        this.rightsectionresponse="";
       
      }
      
    
 }); 
  }
  resetFilter() {
    this.filterValues.filterbystatus = [''];
    this.selectedstatus = [];
    this.filterValues.filterbyproduct = [];
    this.count=0;
    //this.getfilterdata.forEach((item:any) => item.isChecked = false);
   // this.getstatus.forEach((item:any) => item.isChecked = false);
    this.applyFilter()
  }
  applyFilter() {
     
      this.filteredData1 = new CustomfilterPipe().transform(this.apiresponse.seasonData, this.filterValues);
    this.dataSource = new MatTableDataSource(this.filteredData1);
    this.cd.detectChanges();
    this.dataSourceWithPageSize = new MatTableDataSource(this.apiresponse.seasonData);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort; 
    this.totalLength = this.filteredData1.length;
    const starti = (this.currentPage - 1) * this.pageSize;
    const endi = starti + this.pageSize;
    this.dataSource.data = this.filteredData1.slice(starti, endi); 
  }
  getProgress(deliveredQty:number,totalQty:number){

    return (Math.floor((deliveredQty/totalQty)*100))
  }
  updateTableData() {
    const starti = (this.currentPage - 1) * this.pageSize;
    const endi = starti + this.pageSize;
    this.dataSource.data = this.apiresponse.seasonData.slice(starti, endi);
  }
  onPageSizeChange(event: any) {
    this.pageSize = parseInt(event.target.value, 10);
    this.currentPage = 1;
    this.updateTableData();
  }
  resetsort(){
    this.sortvalue="";
    this.dataSource.sort = this.sort; 
    const temp1= <HTMLElement> <unknown>document.getElementById(this.selectedorderactive);
    if(temp1){
    temp1.classList.add('activeorder');
    }
  }

  filteropen(){
    event?.stopPropagation();
    this.togglefilterView=!this.togglefilterView;
    this.togglesortView=false;
  }
  sortopen(){
    event?.stopPropagation();
    this.togglefilterView=false;
    this.togglesortView=!this.togglesortView;
    
  }
  onFilterClick(event:Event){
    event.stopPropagation();
  }
  

  goToLastPage() {
    const totalPages = Math.ceil(this.totalLength / this.pageSize);
    this.currentPage = totalPages;
    this.updateTableData();
  } 
 
   goToFirstPage() {
    this.currentPage = 1;
    this.updateTableData();
  }
  
  getPages(): any {
    const totalPages = Math.ceil(this.totalLength / this.pageSize);
    const pages: any = [];
    const startPage = Math.max(1, this.currentPage - 1);
    const endPage = Math.min(totalPages, startPage + 2);
 
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
 
 
    }
    if (endPage < totalPages - 1) {
      pages.push('...');
    }
    if (endPage < totalPages) {
      pages.push(totalPages);
    }
    return pages;
  }
   goToPage(page: number) {
    this.currentPage = page;
    const starti = (page - 1) * this.pageSize;
    this.dataSource.data = this.apiresponse.seasonData.slice(starti, starti + this.pageSize);
  } 
   previousPage() {
    if (this.currentPage > 1) {
      this.goToPage(this.currentPage - 1);
    }
  } 
     nextPage() {
    const totlpages = Math.ceil(this.totalLength / this.pageSize);
    if (this.currentPage < totlpages) {
      this.goToPage(this.currentPage + 1);
    }
  } 
  isFirstPage(): boolean {
    return this.currentPage == 1;
  }
  isLastPage(): boolean {
    return this.currentPage == Math.ceil(this.totalLength / this.pageSize);
  }
  isCurrentPage(page: number): boolean {
    return this.currentPage == page;
  }

  backbtn(){
    const mobileback=<HTMLElement>document.getElementById("backbtn");
    const mobiletemp=<HTMLElement>document.getElementById("rightsection");
    const left =<HTMLElement>document.getElementById("leftsection");
    left.classList.remove("lefthide");
        mobiletemp.classList.add("mobileright");
        mobileback.classList.remove("backhide");
  }
  

  openrightsectio(orderid:string){
   this.selectedorderactive=orderid;

    const temp= <HTMLElement>document.getElementsByClassName("activeorder")[0];
    if(temp){
    temp.classList.remove("activeorder");
    }
    const temp1= <HTMLElement> <unknown>document.getElementById(orderid);
    if(temp1){
    temp1.classList.add('activeorder');
    }
    this.rightisLoading = true;
      
      this.selectedOrder = orderid;
      this.editdata = false;
    this.userInfoService.UkMyorderRightsection(this.dropdownValue,orderid).subscribe(value=>{
      this.rightsectionresponse=value;
      
  
      this.dataSource1 = new MatTableDataSource(this.rightsectionresponse.seasonData);
     
      //orderedProducts
      this.dataSource2 = new MatTableDataSource(this.rightsectionresponse.seasonData[0].orderedandShippedProducts.orderedProducts);
      let newArray = this.rightsectionresponse.seasonData[0].orderedandShippedProducts.orderedProducts
      this.finalDosesCount = newArray.reduce((a:any,c:any)=>{
        a = a+c.quantityInDoses
        return a
      },0)
     
      this.dataSource3 = new MatTableDataSource(this.rightsectionresponse.seasonData[0].shipments);
      this.userInfoService.MyorderRightTable(this.dropdownValue,orderid).subscribe((res)=>{
        this.deliveriesTable = res;
       
        this.dataSource4 =  new MatTableDataSource(this.deliveriesTable.data);
        this.updateDisplayProductColumns();
      
        this.cd.detectChanges();
      })
      this.rightisLoading = false;
      this.userInfoService.MyCalenderUk(this.dropdownValue,orderid).subscribe((val)=>{;
        this.eventCalender = val;
        this.all_events =this.eventCalender.calendarEvent;
        // this.calendarComponent0.events = this.eventCalender.calendarEvent,
        // this.calendarComponent1.events = this.eventCalender.calendarEvent,
    //     let calendarApi1 = this.calendarComponent0.getApi();
    // let calendarApi2 = this.calendarComponent1.getApi();
    // let calendarApi3 = this.calendarComponent2.getApi();
    //calendarApi3.options.events :''
       // console.log('aaaa',calendarApi3)
      //  this.all_events.push({
      //     title: 'delivered',
      //     start: '2025-10-17',
      //     end: '2025-10-17',
      //   },
      //   {
      //     title: 'schedule',
      //     start: '2025-10-21',
      //     end: null,
      //   });
        this.all_events = this.all_events.map(item => {
          item.className = item.title;
          item.title = '';
          item.end = item.end? item.end: item.start;
          item.display = 'background';
          return item;
        });
        this.calendarOptions0.validRange = this.getEndDate();
        this.calendarComponent0?.getApi().render();
        // this.calendarComponent0.getApi().gotoDate(moment(this.getEndDate().start).format('YYYY-MM-DD'));
        this.calendarOptions1.validRange = this.getEndDate1();
        this.calendarComponent1.getApi().render();
        this.calendarOptions2.validRange = this.getEndDate2();
        this.calendarComponent2.getApi().render();
        this.cd.detectChanges()
      }) 

      const mobileback=<HTMLElement>document.getElementById("backbtn");
      const mobiletemp=<HTMLElement>document.getElementById("rightsection");
      const left =<HTMLElement>document.getElementById("leftsection");
      left?.classList.add("lefthide");
      mobiletemp?.classList.remove("mobileright");
      mobileback?.classList.add("backhide");
      this.editdatasource = new MatTableDataSource(this.rightsectionresponse.seasonData);
      this.initialQuantities = this.editdatasource.data.map(item => ({ ...item,}));
       this.originalQuantities = this.editdatasource.data.map(item => item.quantity);
      this.cd.detectChanges();
       
     });
  }
  updateDisplayProductColumns() {
    this.displayproduct = this.displayproduct.filter((col)=> col !== 'orderedDose');
    if(this.dataSource4?.data?.some((row: any) => row?.allogaHeaderCheck)){
  
      this.displayproduct.push('orderedDose');
    }
    else {
      this.displayproduct = this.displayproduct.filter((col)=> col !== 'orderedDose');
      
    }
    
  }
  expandedElement: any | null;

  toggleRow(element: any) {
    element.expanded = !element.expanded;
  //  this.expandedrowdata=element.shippedStatusTable;
    this.cd.detectChanges();
  }
  viewEditTable(){
    this.editdata= !this.editdata;
  }
  showTrackingLink():boolean{
    return this.dataSource4?.data?.some((row: any) => row?.allogaHeaderCheck);
  }
  onKeyPress(event: KeyboardEvent) {
    const char = event.key;
    if (!/^[0-9]$/.test(char)) {
      event.preventDefault();
    }
  }
  calculateTotalQuantiy():number{
    // const dataArray = this.editdatasource;
    const dataArray = this.editdatasource;
    this.totalQuantity= dataArray.data.reduce((total,element,index)=>{
    if(Number(element.quantity) < this.originalQuantities[index]){
       element.errorMessage = "Sorry, existing order quantities can't be decreased online";
       element.error=true;
       this.isFormValid=false;
      }else{
          element.errorMessage=""
          element.error = false;
      }
     return total + Number(element.quantity);
     },0);
     this.isFormValid = !dataArray.data.some(element=>element.error);
     return this.totalQuantity;
   }
      //method calculates the total amount for each product based on its price and quantity.

  calculateTotalAmount(element:any) {
    var price=element.formattedValue.replace("$","")
    var finalprice= Number(price);
    return (element.quantity)*(finalprice);
 }

   //This method calculates the overall total price by summing up the total amounts of all products.
   calculateTotalPrice() {
    const total = this.editdatasource.data.reduce((acc, element) => acc + this.calculateTotalAmount(element), 0);
    return total.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }
  closeEditOrderArea() {
    //when user clicks on edit but doesnt save it and closes the edit section value go back to previous
    this.editdatasource.data = this.initialQuantities.map(item => ({ ...item }));
    this.editdata = false;
  }
  iseditLoading = false;
  //method to save updated order
//   saveOrder(){
//     this.iseditLoading = !this.iseditLoading;
//   const updatedOrder= this.editdatasource.data.map((item) =>({
//     'materialID':item.materialID,
//     'itemNo': item.itemNumber,
//     'quantity':item.quantity,
//   }));
//   const newobj = {
//     'shipToID':this.rightsectionresponse.shipToID,
//     'sapOrderID':this.rightsectionresponse.orderID,
//     'maxItemNo':this.rightsectionresponse.maxItemNumber,
//     'updateOrderProduct': updatedOrder,
//     'poRefnumber':this.rightsectionresponse.poNumber
//   }  
// const poRefnumber=this.rightsectionresponse.poNumber
// const sapOrderID=this.rightsectionresponse.orderID

//   this.userInfoService.updateOrder(newobj).subscribe(res=>{
//     console.log(res);
//     if(res == "cart-success"){
//       this.userInfoService.editconfirmorder(poRefnumber,sapOrderID).subscribe((data: any)=>{
//         this.checkout=data;
//         console.log(this.checkout);
//       },
//       error=>{
//         console.error('Error confirming order:' ,error);
//       }
//       );
//     }
//     this.editdata = false;
//     this.cd.detectChanges();
//     console.log('Edit area closed:', this.editdata);
//     this.iseditLoading = false;
//     window.scrollTo(0, 0);
//     setTimeout(() => {
//       window.location.reload();
//     }, 6000)
//    },
//    err => {
//      console.error('Error updating order', err);
//      this.iseditLoading = false;
//   })
// }


  getTotal(items:any) {
    return items.reduce((sum: any, item: { itemQty: any; }) => sum + item.itemQty, 0);
  }
  handleOnConfirmOrderClick(value: any) {
    const obj = {
      showukOrdersLeftSection: false,
      showukOrdersBanner: false,
      showukCartBanner: true,
      showukCartReviewSection: true,
    }
    this.userInfoService.setEnablePage(obj);
    const locationPayload = {
      orderID: value.orderId,
      requestType: "AM",
      season: this.seasonValue,
      myOrderEditConfirm: true
    }
    this.startOrderService.getreviewpage(locationPayload);
  }
  handleOnAmendBtnClick(value: any) {
    const obj = {
      showukOrdersLeftSection: false,
      showukOrdersBanner: false,
      showukCartBanner: false,
      showAmendReviewPage: false,
      showAmendProductPage: true,
    }
    this.userInfoService.setEnablePage(obj);
    const locationPayload = {
      orderID: value.orderId,
      requestType: "AM",
      season: this.seasonValue
    }
    localStorage.setItem('orderId', value.orderId);
    this.userInfoService.sendstartorderseason(this.seasonValue);
    this.startOrderService.getAmendProductDetails(locationPayload);
    this.amendactive=true;
    this.router.navigate(
      ['/start-order/editOrder']
    );

  }

}
