<div class=" orders_rowsectionright_uk openorderpage open-order">
    <div class="row">
            <div class="col-xs-12 col-md-12 season_title_uk"> {{ordersummarydata?.rightSectionSeason | seasonspilt}} Season</div>
       <div class="row orders_rowsection ">
         <ng-container *ngIf="orderrbaright?.RBAPermissions==true;else ordernormalright">

            <ng-container *ngIf="orderrbaright?.showDashboardOrderUK==true">

          <div class="col-xs-12 orders_newordertitle ">New Order</div>
                    <div class="col-xs-12 orders_neworderbox1 ordertooms"  data-toggle="modal" data-target="#ordersummary_OM_seasonConfirmPopup"  (click)="seasonclick(ordersummarydata?.rightSectionSeason)">
                        <div class="ordertoomsseason">{{ordersummarydata?.rightSectionSeason | seasonspilt}}</div>
                           <div class="orders_boxtext1">Place an order for the</div>
                            <div class="orders_boxtext2">
                              {{ordersummarydata?.rightSectionSeason | seasonspilt}} Season <i class="orders_global_blackarrow pull-right"></i>
                       </div>
                 </div>
               </ng-container>
            </ng-container>
               <ng-template #ordernormalright>
                  <div class="col-xs-12 orders_newordertitle ">New Order</div>
                  <div class="col-xs-12 orders_neworderbox1 ordertooms"  data-toggle="modal" data-target="#ordersummary_OM_seasonConfirmPopup"  (click)="seasonclick(ordersummarydata?.rightSectionSeason)">
                      <div class="ordertoomsseason">{{ordersummarydata?.rightSectionSeason | seasonspilt}}</div>
                         <div class="orders_boxtext1">Place an order for the</div>
                          <div class="orders_boxtext2">
                            {{ordersummarydata?.rightSectionSeason | seasonspilt}} Season <i class="orders_global_blackarrow pull-right"></i>
                     </div>
               </div>
               </ng-template>
         
              </div>
              
    </div>
    <div class="row orders_rowsection rightupcoming ">
       <div class="">
          <!-- have to add conditon here for reserve -->
          <!-- provisional changes -->
               <div class="col-xs-12 col-md-6 orders_producttitle no-padding padding_noleft">Upcoming Orders</div>
                   <!-- have to add conditon here for reserve -->
                    <div class="col-xs-12 col-md-6 orders_vieworder upcomingvieworder rightviewall">
                    <a [routerLink]="'/orders/my-orders'" [queryParams]="{ season : ordersummarydata?.rightSectionSeason }" class="orders_viewalltxt"> View All Open Orders<i class="global_blackarrow"></i>
                        </a>
             </div>
          </div>
          <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 filterTable_upcomingOrder">
        
    
            <ng-container matColumnDef="combined" >
        <th class="odertableheader" mat-header-cell *matHeaderCellDef> order Data </th>
             <td mat-cell *matCellDef="let element"> 
               <div class="tablecolum_order1">
               <div class="order_season">{{element.season}} Season</div> 
               <div class="order_id"><span>Order Number :</span><span>{{element.orderId}}</span></div>
               <div class="order_id"><span>{{element.displayProduct1}}</span><span class="totalorderquantity_Order" [className]=element.ordernum>{{element.aqivTotal | number:'1.0'}}</span></div>
               <div class="order_id"><span>{{element.displayProduct2}}</span><span class="totalorderquantity_Order" [className]=element.ordernum>{{element.qivcTotal | number:'1.0'}}</span></div>

               <div class="order_id"><span>Delivery Location:</span><span [innerHtml]="element.delLocation"></span></div>
               <div class="upvieworder"><a [routerLink]="'/orders/my-orders'" [queryParams]="{ season : ordersummarydata?.rightSectionSeason,address:element.orderId }" >View Order <i class="global_blackarrow"></i></a></div>
               </div>
               <div class="tablecolum_order2">
                <div class="order_status">{{element.status}}</div></div>
               </td>
                 
        
             </ng-container>
             
                 <!-- Header and Row Declarations -->
                 <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                 <tr mat-row id="{{row.ordernum}}" *matRowDef="let row; columns: displayedColumns;"></tr>
                 <tr class="mat-row " *matNoDataRow>
                   <td class="mat-cell noorder">No orders available</td>
                 </tr>
               </table>
      
      
    </div>
    <div class="row margin-B30">
       <div class="row orders_rowsection prodsection">
          <div class="row">
                <div class="col-xs-12 col-md-12 orders_producttitle">Product Availability</div>
                <div class="col-xs-12 col-md-12 orders_productyear">{{ordersummarydata?.rightSectionSeason | seasonspilt}}</div>
             </div>
             <div class="col-xs-12 orders_prodbox">
                   <div class="orders_prodtext1" id="preBookProduct0">{{product1}} </div>
                   <div class="orders_prodtext2">
                      <span>{{product1}} &nbsp;</span><span class="product_avail_pack">({{pack1}})</span>

                      <ng-container *ngIf="rightdata?.products[0]?.sellable;else soldout1">
                        <span class="orders_avilabletxt pull-right">AVAILABLE</span>
                      </ng-container>
                      <ng-template #soldout1> <span class="orders_avilabletxt pull-right product_soldout">
                        <div class="soldouttxt unavailableProd">UNAVAILABLE</div>
                     </span></ng-template>
                     
                         </div>
                </div>
             <div class="col-xs-12 orders_prodbox">
                   <div class="orders_prodtext1" id="preBookProduct1">{{product2}} </div>
                   <div class="orders_prodtext2">
                      <span>{{product2}} &nbsp;</span><span class="product_avail_pack">({{pack2}})</span>
                      <ng-container *ngIf="rightdata?.products[0]?.sellable;else soldout1">
                        <span class="orders_avilabletxt pull-right">AVAILABLE</span>
                      </ng-container>
                      <ng-template #soldout1> <span class="orders_avilabletxt pull-right product_soldout">
                        <div class="soldouttxt unavailableProd">UNAVAILABLE</div>
                     </span></ng-template>
                         </div>
                </div>
             </div>
    </div>
   
    </div>


    
