import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'customfilter', pure: false }) 

 
export class CustomfilterPipe implements PipeTransform {
  tofiltervalue:any;
  searched: boolean =false;
  productby: boolean=false;
  statusby: boolean=false;
  transform(value: any, filter: any) {
  
    if (!value) return [];

    
     //this.tofiltervalue=value.filteredData;
    
      /* 
      value = value.filter(item => {
        return Object.values(item).some((val:any) => val.toString().toLowerCase().includes(filter.search));
      }); */
    
   /*  if (filter.search) {
      filter.search = filter.search.trim().toLowerCase();
    return value.filter((item:any) => {
      return Object.keys(item).some(key => {
        return item[key].toString().toLowerCase().includes(filter.search);
      });
    });
  } */
   /* var filteredArray = value.filter((item:any) => {
    const prodNamearray = item.prodname.split(',');
    return filter.filterbyproduct.find((el:any) => {
   
        if(prodNamearray.includes(el.toUpperCase())) { 
          
          return item 
        };
      });
       
    

  }); */
  return value.filter((product:any) => {
if(filter.search){
    filter.search = filter.search.trim().toLowerCase();
   
       this.searched= Object.keys(product).some(key => {
        return product[key].toString().toLowerCase().includes(filter.search);
      }); 

     
    
    }
    else {
      this.searched=true;
    };
    if(filter.filterbyproduct && filter.filterbyproduct.length>0){
    this.productby = filter.filterbyproduct.some((type:any) => 

    product.prodname.toLowerCase().includes(type.toLowerCase())

    );
    }
    else{
      this.productby=true;
    }
    if(filter.filterbystatus.length>0){
    this.statusby = filter.filterbystatus.some((type:any) => 

      product.status.toLowerCase().match(type.toLowerCase()) && product.status.toLowerCase().startsWith(type.toLowerCase())

    );
    }
    else{
      this.statusby=true;
    }


    return this.searched && this.productby && this.statusby;

  });
  }

}
