import { ChangeDetectorRef, Component, ElementRef, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { OccConfig, BaseSiteService, EventService, AuthService } from '@spartacus/core';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { shareReplay, Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';

declare var window:any;

@Component({
  selector: 'app-multiple-soldto-popup',
  templateUrl: './multiple-soldto-popup.component.html',
  styleUrl: './multiple-soldto-popup.component.scss'
})
export class MultipleSoldtoPopupComponent {
  showMultipleSoldToPopup: boolean=false;
  displayedColumns: string[] = ['soldTo', 'select'];
  dataSource = new MatTableDataSource();
  userEmail: any="";
  isTableHasData: boolean=false;
  soldToPopup: any;
  webflag:any;
  currentSite:any
  multiplesolddata:any;

  constructor(private authService: AuthService,
    public userInfoService:UserInfoService,private eleRef:ElementRef,private renderer:Renderer2,
    private cdr:ChangeDetectorRef,private baseSiteService:BaseSiteService,private router: Router,private eventService:EventService) {
  }

  ngOnInit(){
    localStorage.removeItem("rbapermission");
    this.userInfoService.getMultipleSoldToData().subscribe((res)=>{
      this.userInfoService.getUserData().subscribe((user)=>{
        this.userEmail = user?.email;
      })        
       // this.userInfoService.fetchMultipleSoldToForUser().subscribe((data)=>{
           
            this.checkPopUp(res);
              
        //  })
      this.cdr.detectChanges();
    })
  
  
  } 
  checkPopUp(res: any) {
  
    
    this.authService.isUserLoggedIn().subscribe(isloggedIn => {
     

      this.userInfoService.getUserData().subscribe(res=>{
        if(res){
           this.webflag=res.webEnabled
           }
          });
    if(isloggedIn){

    this.userInfoService.fetchMultipleSoldToForUser().subscribe((data:any)=>{

      this.multiplesolddata=data
        
    if(!this.userInfoService.checkAsmUser() && this.multiplesolddata?.multipleSoldToData.length > 1){
       
      this.showMultipleSoldToPopup = true;
        
      this.renderer.addClass(document.querySelector('body'),'modal-open');

      this.dataSource = new MatTableDataSource(this.multiplesolddata.multipleSoldToData);
      this.cdr.detectChanges();
  
    }
      else if(this.multiplesolddata?.multipleSoldToData.length == 1  && this.webflag){
      
         this.router.navigate(['/my-account']);
         
        }else if(this.userInfoService.checkAsmUser() &&  this.webflag){
          this.router.navigate(['/my-account']);
        }
   

      
    
     
     
      
      
     /*  if(localStorage.getItem("webEnabled")=='true'){
        
       
      } */
      /* else if(localStorage.getItem("webEnabled")=='false'){
        this.router.navigate(['/register/join-account']);
      }else {
        this.router.navigate(['/homepage']);
      } */
      
      });
      }
      else{
        this.baseSiteService.getActive().subscribe(site => {
          this.currentSite = site;
        });
       /*  if(this.currentSite ==  'seqirusb2b-uk'){
        this.router.navigate(['/homepage']);
        } */
        const body=<HTMLElement>document.querySelector("body");
        body.classList.remove('modal-open')
      }
  });
  }
  applyFilter(event:Event){
    const filterValue = (event.target as HTMLInputElement).value;
   
    this.dataSource.filter = filterValue || ''.trim().toLowerCase();
    if(this.dataSource.filteredData.length > 0){
    this.isTableHasData = true;
    }
    else{
      this.isTableHasData = false;
    }
  }

  selectSoldTo(accNum:string){
    this.showMultipleSoldToPopup =false;

    const body=<HTMLElement>document.querySelector("body");
    body.classList.remove('modal-open');
    this.cdr.detectChanges();
    this.userInfoService.changeB2BAccount(accNum).subscribe((data) => {
      const returnUrl = localStorage.getItem('redirectProductUrl') || '/my-account';
      //this.router.navigate([returnUrl]);
       window.location.href=returnUrl
      localStorage.removeItem('redirectProductUrl');
      //window.location.href='/my-account';
    })
  }

  /* ngOnDestroy(): void {
    this.multiplesolddata.unsubscribe();
    this.webflag.unsubscribe();
   

  } */

}
