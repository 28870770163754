<table
  class="invoice-apply-table d-none d-md-block d-lg-block"
  mat-table
  [dataSource]="dataSource"
  matSort
  (matSortChange)="announceSortChange($event)"
>
  <ng-container matColumnDef="billTo">
    <th class="heading" mat-header-cell mat-sort-header *matHeaderCellDef disableClear="true">
      Billing<br />Address
    </th>
    <td mat-cell class="matRow" *matCellDef="let element">
      {{ element.billTo.billingAddress }}
    </td>
  </ng-container>

  <ng-container matColumnDef="invoiceDate">
    <th
      class="invoiceDate"
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      disableClear="true"
      sortActionDescription="Sort by Date"
    >
    Date Issued
    </th>
    <td mat-cell class="matRow" *matCellDef="let element">
      {{ element.invoiceDate }}
    </td>
  </ng-container>

  <ng-container matColumnDef="invoiceNumber">
    <th class="invoiceNumber" mat-header-cell mat-sort-header *matHeaderCellDef disableClear="true">
      Credit Note #
    </th>
    <td mat-cell class="matRow" *matCellDef="let element">
      {{ element.invoiceNumber }}
    </td>
  </ng-container>
  <ng-container matColumnDef="formattedAmountWithTax">
    <th
      class="formattedAmountWithTax"
      mat-header-cell
      mat-sort-header
      *matHeaderCellDef
      disableClear="true"
    >
      Credit Amount
    </th>
    <td mat-cell class="matRow" *matCellDef="let element">
      {{ element.formattedAmountWithTax }}
    </td>
  </ng-container>
  <ng-container matColumnDef="status">
    <th class="status" mat-header-cell *matHeaderCellDef></th>
    <!-- <td mat-cell class="invoice_applybutton" *matCellDef="let element">{{element.formattedAmountWithTax}} </td> -->
    <td
      mat-cell
      class="matRow invoice_applybutton"
      *matCellDef="let element; let i = index"
    >
      <span
        id="appliedId-{{ i }}"
        class="buttonStyles applyCreditClass"
        (click)="
          creditApplied(
            $event,
            element.amoutWithTax,
            'credit',
            element.invoiceNumber,
            i
          )
        "
        >APPLY <i class="glyphicon glyphicon-plus invoiceCreditIcon"></i
      ></span>
      <span
        id="removeId-{{ i }}"
        class="buttonStyles removeCreditClass"
        (click)="
          creditApplied(
            $event,
            element.amoutWithTax,
            'remove',
            element.invoiceNumber,
            i
          )
        "
        >REMOVE <i class="glyphicon glyphicon-minus invoiceCreditIcon"></i
      ></span>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="creditcolumns"></tr>
  <tr mat-row *matRowDef="let row; columns: creditcolumns"></tr>
  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell no-matching-invoices" colspan="4">No matching records found</td>
  </tr>
</table>
