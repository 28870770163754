<div class="adjuvantbasedvideo" id="adjuvantbasedvideo" *ngIf="node2 | async as item">
    <div class="container col-md-12 col-xs-12">
        <div class="videocontainer abovewidth">
            <div class="col-md-5 col-xs-12">
                <div class="watchtext">{{item.headLine}}</div>
                <div class="watchsubtext" [innerHTML]="item.content"></div>
            </div>
            <div class="col-md-7 col-xs-12">
                <div class="video-wrap" *ngIf="item?.mediaVideo">
                    <video muted playsinline
                        poster="../../../assets/images/video-placeholder.png" controls=""
                         [attr.title]="item.mediaVideo?.altText" class="d-none adjuvant-video">
                        <source [src]="getVideo(item.mediaVideo)" type="video/mp4" />
                    </video>
                    <video muted playsinline
                    poster="../../../assets/images/MOA_Video.jpg" controls=""
                    [attr.title]="item.mediaVideo?.altText" class="d-none cell-based-video">
                    <source [src]="getVideo(item.mediaVideo)" type="video/mp4" />
                </video>
                </div>
            </div>
        </div>
    </div>
</div>