<div *ngIf="bannerNode | async  as item" class="main_inner-wrapper">
    <div class="home_container container col-xs-12" id="landingHome" [ngClass]="{'home_container-uk':item.uid=='seqirus-overviewuk-hero-component'}">
        <div class="d-none overview_us_breadcrumbs">
            <p><a href="/">Home</a><span> &gt; </span><a href="/overview"><strong>flu360<sup>®</sup>
                        Overview</strong></a></p>
        </div>
        <section class="col-xs-12 home_loginsectionUS" [ngClass]="{'home_loginsection-uk':currentSite=='seqirusb2b-uk'}" id="home_loginsection" style="background-image:url('{{getImage(item.media)}}');">
           <!--  <div class="simple-banner banner__component--responsive" *ngIf="item?.media">
                <img class="js-responsive-image" [ngClass]="{'banner1':currentSite=='seqirusb2b-uk'}" [attr.alt]="item?.media.altText"
                   [attr.title]="item?.media.altText" [src]="getImage(item.media)">
            </div> -->
            <div class="col-xs-12 home_topfirst home_stretch">
                <div *ngIf="item.uid !='seqirus-overview-hero-component'"
                    class="homepagemobuserguideparent col-xs-12 col-md-12  d-md-block d-lg-none d-block d-sm-none">
                    <app-help-widget></app-help-widget>
                </div>

                <div class="col-xs-12 col-md-6 col-lg-7" id="home_loginleftsection"
                    *ngIf="item.uid !='seqirus-overview-hero-component' && item.uid!='seqirus-overviewuk-hero-component'">
                    <div id="home_leftcontent">

                        <div id="home_leftheader" class="modified-h1">
                            <div class="global_Lshape">
                                <div class="global_horizontalL"></div>
                                <div class="global_verticalL"></div>
                            </div>
                            <div class="allproducts_heading" [innerHTML]="item.headline"></div>
                        </div>
                        <div id="home_leftcontenttext">{{item.h2content}}</div>
                        <div class="home_tivbtn" id="home_leftoverviewbtn" [innerHTML]="item.content"></div>
                        <div id="home_leftoverviewtext"></div>
                    </div>
                </div>
                <div *ngIf="item.uid =='seqirus-overview-hero-component' || item.uid=='seqirus-overviewuk-hero-component'" class="col-xs-12 col-md-6 col-lg-7"
                    id="overView_loginleftsection">
                    <div id="home_leftcontent" [ngClass]="{'home_leftcontent-uk':item.uid=='seqirus-overviewuk-hero-component'}">
                        <div class="breadcrumbs-opeff overViewCrumbs"[ngClass]="{'overViewCrumbs-uk':item.uid=='seqirus-overviewuk-hero-component'}">
                            <p><a href="/">Home</a><span> &gt; </span><a href="/overview"><strong>flu360<sup *ngIf="item.uid!='seqirus-overviewuk-hero-component'">®</sup>
                                        Overview</strong></a></p>
                        </div>
                        <h1 id="home_leftheader" class="modified-h1">
                            <div class="global_Lshape">
                                <div class="global_horizontalL"></div>
                                <div class="global_verticalL"></div>
                            </div>
                            <div class="allproducts_heading-overView" [innerHTML]="item.headline"></div>
                        </h1>
                        <div id="home_leftcontenttext">{{item.h2content}}</div>
                        <div id="home_leftcontenttext" *ngIf="item.
                        largeparagraphcontent
                        ">{{item.largeparagraphcontent}}</div>
                        <div class="overView_tivbtn" id="overView_leftoverviewbtn" [innerHTML]="item.content"></div>
                        <div id="home_leftoverviewtext"></div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-5 d-none d-sm-block d-md-none d-lg-block " id="home_loginrightsection">
                    <div id="home_rightcontent">
                        <div *ngIf="item.uid !='seqirus-overview-hero-component'" class="homepageuserguideparent">
                            <app-help-widget></app-help-widget>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- for overview page mobile view-->
        <div class="col-xs-12 col-md-6 col-lg-7 d-none" id="mobile_overView_loginleftsection">
            <div id="mobile_home_leftcontent" [ngClass]="{'home_leftcontent-uk':item.uid=='seqirus-overviewuk-hero-component'}">
                <div class="breadcrumbs-opeff overViewCrumbs" [ngClass]="{'overViewCrumbs-uk':item.uid=='seqirus-overviewuk-hero-component'}">
                    <p><a href="/">Home</a><span> &gt; </span><a href="/overview"><strong>flu360<sup *ngIf="item.uid!='seqirus-overviewuk-hero-component'">®</sup>Overview</strong></a></p>
                </div>
                <h1 id="home_leftheader" class="modified-h1">
                    <div class="global_Lshape">
                        <div class="global_horizontalL"></div>
                        <div class="global_verticalL"></div>
                    </div>
                    <div class="allproducts_heading-overView" [innerHTML]="item.headline"></div>
                </h1>
                <div id="home_leftcontenttext">{{item.h2content}}</div>
                <div id="home_leftcontenttext" *ngIf="item.largeparagraphcontent">{{item.largeparagraphcontent}}</div>
                <div class="overView_tivbtn" id="overView_leftoverviewbtn" [innerHTML]="item.content"></div>
                <div id="home_leftoverviewtext"></div>
            </div>
        </div>
    </div>
</div>

<!--FOR ALL PRODUCTS-->
<ng-container *ngIf="productpage">
<div *ngIf="bannerNode | async  as item" class="all-produts-main_inner-wrapper d-none">
    <div class="hero--grad" role="img" alt="Scientist with microscope" aria-label="Scientist with microscope" *ngIf="item?.media"
        [style.backgroundImage]="'url(' + getImage(item.media) + ')'">
        <!--breadcrumbs-->
        <div class="breadcrumbs--page-nav" *ngIf="!userLoggedIn">
            <div class="container">
                <p><a [routerLink]="'/'">Home</a><span> &gt; </span><strong>Products</strong></p>
            </div>
        </div>
        <div class="row-flex">
            <div class="col-flex-12 hidden-sm hidden-md hidden-lg hero--grad__mobile">
                <img alt="Scientist with microscope"
                [src]="getImage(item.media)"
                    role="presentation">
            </div>
            <div class="container">
                <div class="col-12 col-md-8">
                    <div class="hero--grad__content LO-hero--grad__content" *ngIf="!userLoggedIn">
                        <div class="content-container content-container--has-corner text-left">
                            <h1 [innerHTML]="item.headline"></h1>
                            <p class="text--grey-100">{{item.h2content}}</p>
                        </div>
                    </div>
                    <div class="hero--grad__content LI-hero--grad__content" *ngIf="userLoggedIn">
                        <div class="content-container content-container--has-corner text-left">
                            <h1 [innerHTML]="item.headline"></h1>
                            <p class="text--grey-100">{{item.h2content}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</ng-container>


<!-- <form [formGroup]="myForm" (ngSubmit)="onClick()">
    <div>
      <label>
        password:
        <input formControlName="password" placeholder="Your name">
      </label>
    </div>
    <div>
      <label>
        Email:
        <input formControlName="email" placeholder="Your email">
      </label>
    </div>
    <button type="submit">Send</button>
  </form> -->


      <!-- <div class="productsmobile d-none">
        <div class="simple-banner banner__component--responsive">
            <img class="js-responsive-image" alt="Healthcare professional silhouette"
                title="Healthcare professional silhouette" [src]="getImage(item.media)">
        </div>
        <div class="container products-mobile-content col-md-8">
            <h1 id="home_leftheader" class="modified-h1">
                <div class="global_Lshape">
                    <div class="global_horizontalL"></div>
                    <div class="global_verticalL"></div>
                </div>
                <div class="allproducts_heading" [innerHTML]="item.headline"></div>
            </h1>
            <div id="home_leftcontenttext">{{item.h2content}}</div>
            <div id="home_leftoverviewtext"></div>
        </div>
    </div> -->

            <!-- <div class="container" *ngIf="!userLoggedIn">
            <p class="breadcrumbs-for-products d-none"><a href="/">Home</a><span> &gt; </span><strong>Products</strong>
            </p>
        </div> -->