import { ChangeDetectorRef, Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { CommonApiService } from '../../spartacus/services/common-api.service';
import { OccEndpointsService, UserIdService } from '@spartacus/core';
import { ContentService } from '../../spartacus/services/content.service';
import { Router } from '@angular/router';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { environment } from '../../../environments/environment';

declare var window:any;

export interface FeedbackFormData {
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber:number;
  phoneExt:number;

}

@Component({
  selector: 'app-contact-us-form',
  templateUrl: './contact-us-form.component.html',
  styleUrl: './contact-us-form.component.scss'
})
export class ContactUsFormComponent {
  loggedInFeedbackForm: FormGroup;
  loggedOutFeedbackForm: FormGroup;

  emailPattern = new RegExp("^(?=(.{1,64}@.{1,255}))([!#$%&'*+\\-\\/=?\\^_`{|}~a-zA-Z0-9]{1,64}(\\.[!#$%&'*+\\-\\/=?\\^_`{|}~a-zA-Z0-9]{0,}){0,})@((\(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}\)|([a-zA-Z0-9-]{1,63}(\\.[a-zA-Z0-9-]{2,63}){1,}))$");

  //topics=['Billing & Payments','Delivery Issue','My Account','Orders & Shipping','Pricing','Product Information','Product Stability','Returns','Sales Rep Request','Serialization','Other']
  topics:string[]=[];
  acceptedFiles =['image/jpeg', 'image/png', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',  'eml', 'application/pdf','msg','pdf','docx','doc','jpg','png','jpeg'];
  filesToUpload: File[]=[];

  maxFiles: number=5;
  maxFileSize=5*1024*1024;
  maxNumFilesError: boolean=false;
  isUploading: boolean=false;

  maxFileSizeError: boolean=false;
  isValid: boolean = false;
  thankYouModal: any;
  isLoading: boolean = false;
  charCount: any=0;
  errors:string[]=[];
  userInfo: any;
  userLoggedIn: boolean=false;
  countryPopupModal: any;
  mask: string='';
  charCountlogout: any;
  captchaSiteKey:any;

  constructor(public component: CmsComponentData<any>, private fb:FormBuilder,private commonService:CommonApiService,private occEndpointsService: OccEndpointsService,
    private cdr:ChangeDetectorRef,private contentService:ContentService,private router:Router,private userSevice:UserInfoService,protected userIdService:UserIdService){

      const alphanSpacePattern = new RegExp("^[A-Za-z\\s]+$");

      this.loggedInFeedbackForm = this.fb.group({
      firstName:['',[Validators.required,Validators.pattern('^[a-zA-Z]+$')]],
      lastName:['',[Validators.required,Validators.pattern('^[a-zA-Z]+$')]],
      email:['',[Validators.required,Validators.email]],
      phnNumber:['',[Validators.required,Validators.pattern('^(\\([2-9]([02-9]\\d|1[02-9])\\)|[2-9]([02-9]\\d|1[02-9])) ?[2-9]\\d{2}-?\\d{4}$'),Validators.minLength(10)]],
      phnExt:['',[Validators.pattern('^[0-9]*$'),Validators.maxLength(4)]],
      topic:['',[Validators.required]],
      message:['',[Validators.required,Validators.maxLength(5000)]],
      contactUsFiles:[[],[this.filesValidator.bind(this)]],
      recaptcha:['',[Validators.required]]
    });
    this.loggedOutFeedbackForm = this.fb.group({
      firstName:['',[Validators.required,Validators.pattern('^[a-zA-Z]+$')]],
      lastName:['',[Validators.required,Validators.pattern('^[a-zA-Z]+$')]],
      email:['',[Validators.required,Validators.email]],
      country_code:['',[Validators.required,this.uscode()]],
      phnNumber:['',[Validators.required,Validators.pattern('^(\\([2-9]([02-9]\\d|1[02-9])\\)|[2-9]([02-9]\\d|1[02-9])) ?[2-9]\\d{2}-?\\d{4}$'),Validators.minLength(10)]],
      phnExt:['',[Validators.pattern('^[0-9]*$'),Validators.maxLength(4)]],
      orgName:['',[Validators.required,Validators.pattern(alphanSpacePattern)]],
      topic:['',[Validators.required]],
      message:['',[Validators.required,Validators.maxLength(5000)]],
      contactUsFiles:[[],[this.filesValidator.bind(this)]],
      recaptcha:['',[Validators.required]]
    });

  }
  formContent: Observable<any> = this.component.data$;

  filesValidator(control:any){
    const files: any=[];
    files.push(control.value);

    let totalSize =0;
    if(files && files.length > this.maxFiles){
      return {maxFiles :true};
    }
    for(const file of files){
      totalSize += file.size;
    }
    if(totalSize > this.maxFileSize){
      return {maxFileSize:true};
    }
    return null;
  }

  ngOnInit(){
    this.captchaSiteKey = environment.captchaSiteKey;

    this.formContent.subscribe(data => {

      this.topics = data.content.split(',');
    });
    if(this.userSevice.userLoggedIn$.subscribe(val => {
      this.userLoggedIn=val;
      if(val){
        this.userSevice.getUserData().subscribe(res=>{
          if(res){
          this.userInfo=res;
         }
        });
        this.getCustomerDetail();
      }
    }))
    this.loggedInFeedbackForm.get('message')?.valueChanges.subscribe(value => {
      this.charCount = value.length;
    })
    this.loggedOutFeedbackForm.get('message')?.valueChanges.subscribe(value => {
      this.charCountlogout = value.length;
    })

  }
  ngAfterViewInit(){

    const thankyouEle = document.getElementById('feedbackThankyou_popup') as HTMLElement;
    this.thankYouModal = new window.bootstrap.Modal(thankyouEle);
    thankyouEle.addEventListener('click',(event)=>{
      const target = event.target as HTMLElement;
      if(target.classList.contains('modal')){
        this.onclose();
      }
    });

    const countryPopupEle = document.getElementById('country_popup') as HTMLElement;
    this.countryPopupModal = new window.bootstrap.Modal(countryPopupEle);
  }
  getCustomerDetail() {
    let endpoint = this.occEndpointsService.buildUrl(`${this.fetchUserUrl()}/contactUserData`);
    let payload = {
      url: endpoint,
    }
    this.commonService.getAPICall(payload).subscribe({
      next: (res: FeedbackFormData) => {
        if (res) {
          this.patchFeedbackForm(res);
          this.cdr.detectChanges();
        }
      },
      error: (e) => {
      },
    })
  }
  fetchUserUrl() {
    let asmEnabled = localStorage.getItem('showASM') === 'true';
    let partUrl = "/users/current";
    this.userIdService.getUserId().subscribe(item => {
      if(item && asmEnabled)
        partUrl = `/users/${item}`;
    });
    return partUrl;
  }

  onPhoneNumberChange() {
    this.loggedInFeedbackForm.get('phnNumber')?.markAsTouched();
  }
  patchFeedbackForm(data: FeedbackFormData) {
    if (data.phoneNumber) {
      this.loggedInFeedbackForm.patchValue({
        phnNumber:data.phoneNumber
      })
    }
    this.loggedInFeedbackForm.patchValue({
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.emailAddress,
      phnExt:data.phoneExt?data.phoneExt:''
  });
  }
  onMouseEnterPhn(){
    this.mask='(999) 999-9999';
  }
  onMouseLeavePhn(){
    if(!this.loggedOutFeedbackForm.get('phnNumber')?.value)
      this.mask='';
  }

  onFileChange(event:any){

    const files = Array.from(event.target.files || []) as File[];
    if(files.length != 0){
      this.isUploading =true;
      this.isFileAccepted(files[0]?.name);
      this.addFiles(files);
    }
  }
  addFiles(files:File[]){

    let totalSize = 0;
    for(const file of this.filesToUpload){
      totalSize += file.size;
    }

    let currTotal = totalSize;
    for(const f of files){
      currTotal += f.size;
    }
    if(files.length + this.filesToUpload.length > this.maxFiles){
      this.maxNumFilesError = true;
      this.filesToUpload.push(...files);
      this.isUploading =false;
      this.cdr.detectChanges();
    }
    else if(currTotal > this.maxFileSize){
      this.maxFileSizeError =true;
      this.filesToUpload.push(...files);
      this.isUploading =false;
      this.cdr.detectChanges();

    }

    else{
      this.filesToUpload.push(...files);
      this.isUploading =false;

    }


  }

  onDragOver(event:DragEvent){
    event.preventDefault();
  }
  onDragLeave(event:DragEvent){
    event.preventDefault();
  }
  onDrop(event:DragEvent){
    event.preventDefault();
    if(event.dataTransfer?.files){
      const files = Array.from(event.dataTransfer.files) as File[];
      this.isFileAccepted(files[0]?.name);
      this.addFiles(files);
    }
  }
  removeFile(index:number){
    this.filesToUpload.splice(index,1);
    this.errors.splice(index,1);
    this.maxNumFilesError=false;
    this.maxFileSizeError=false;
    this.isUploading=false;
    let totalSize=0;
    for(const file of this.filesToUpload){
      totalSize += file.size;
    }
    if(this.filesToUpload.length > this.maxFiles){
      this.maxNumFilesError = true;
      this.cdr.detectChanges();
    }
    if(totalSize > this.maxFileSize){
      this.maxFileSizeError =true;
      this.cdr.detectChanges();
    }

  }
  submitFeedbackForm(){

    this.loggedInFeedbackForm.markAllAsTouched();
    this.loggedInFeedbackForm.updateValueAndValidity();
  
    if(this.isValid && this.loggedInFeedbackForm.valid && this.isFileError()){
      this.isLoading=true;
      this.cdr.detectChanges();

      const formData = new FormData();
      this.filesToUpload.forEach((file,index) => {
        formData.append('contactUsFiles',file,file.name);
      })

      formData.append('firstName',this.loggedInFeedbackForm.get('firstName')?.value);
      formData.append('lastName',this.loggedInFeedbackForm.get('lastName')?.value);
      formData.append('emailAddress',this.loggedInFeedbackForm.get('email')?.value);
      formData.append('phoneNumber',this.loggedInFeedbackForm.get('phnNumber')?.value);
      formData.append('phoneExt',this.loggedInFeedbackForm.get('phnExt')?.value);
      formData.append('topic',this.loggedInFeedbackForm.get('topic')?.value);
      formData.append('message',this.loggedInFeedbackForm.get('message')?.value);


      this.commonService.postAPICall(this.getRequestData(formData)).subscribe((data)=>{
     
        this.isLoading=false;
        this.cdr.detectChanges();
        this.thankYouModal.show();

      });


    }
  }
  submitLogoutFeedbackForm(){
    this.loggedOutFeedbackForm.markAllAsTouched();
    this.loggedOutFeedbackForm.updateValueAndValidity();
  
    if(this.isValid && this.loggedOutFeedbackForm.valid && this.isFileError()){
      this.isLoading=true;
      this.cdr.detectChanges();

      const formData = new FormData();
      this.filesToUpload.forEach((file,index) => {
        formData.append('contactUsFiles',file,file.name);
      })

      formData.append('firstName',this.loggedOutFeedbackForm.get('firstName')?.value);
      formData.append('lastName',this.loggedOutFeedbackForm.get('lastName')?.value);
      formData.append('emailAddress',this.loggedOutFeedbackForm.get('email')?.value);
      formData.append('phoneNumber',this.loggedOutFeedbackForm.get('phnNumber')?.value);
      formData.append('phoneExt',this.loggedOutFeedbackForm.get('phnExt')?.value);
      formData.append('topic',this.loggedOutFeedbackForm.get('topic')?.value);
      formData.append('message',this.loggedOutFeedbackForm.get('message')?.value);
      formData.append('companyName',this.loggedOutFeedbackForm.get('orgName')?.value);
      formData.append('countryCode',this.loggedOutFeedbackForm.get('country_code')?.value);


      this.commonService.postAPICall(this.getRequestData(formData)).subscribe((data)=>{
    
        this.isLoading=false;
        this.cdr.detectChanges();
        this.thankYouModal.show();

      });


    }
  }
  isFileError() {
    if(this.maxFileSizeError || this.maxNumFilesError || this.errors.includes('error')){
      return false;
    }
    else{
      return true;
    }
  }
  getRequestData(formData: FormData): any {
    let endpoint = this.occEndpointsService.buildUrl(`/users/${this.commonService.userId}/contactUsForm`);
    return {
      url:endpoint,
      contentType:'multipart/formData',
      data:formData
    }
  }
  //recaptcha check
  resolved(captchaResp:string | null){

    if(captchaResp){
      this.loggedInFeedbackForm.controls['recaptcha'].patchValue(captchaResp);
      this.loggedInFeedbackForm.updateValueAndValidity();

    this.contentService.verifyRecaptcha(captchaResp).subscribe((data) => {

      this.isValid=true;

    });
    }
  }
  logoutResolved(captchaResp:string | null){

    if(captchaResp){
      this.loggedOutFeedbackForm.controls['recaptcha'].patchValue(captchaResp);
      this.loggedOutFeedbackForm.updateValueAndValidity();

    this.contentService.verifyLogoutRecaptcha(captchaResp).subscribe((data) => {

      this.isValid=true;

    });
    }
  }

  onclose(){
    const url = (this.router.url).split('?')[0].split('#')[0];
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([url.substring(url.lastIndexOf('/')+1)]);
  }

  isFileAccepted(fileName:string){
    const fileExt = fileName.split('.').pop()?.toLowerCase();

    if(this.acceptedFiles.includes(fileExt??'')){
      this.errors.push('');
      this.cdr.detectChanges();
    }
    else{
      this.errors.push('error');
    }

  }

  onCountryChange(event:Event){
    const val = (event.target as HTMLInputElement).value;
    if(val == '+44'){
      this.countryPopupModal.show();
    }
  }


uscode(): any | string {
  return (control:AbstractControl):ValidationErrors | null =>{
    const value=control.value;
    if(value && value != '+1'){
      return {usCode:true};
    }
    return null;
  }
}

}

