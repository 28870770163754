import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ContentService } from '../../spartacus/services/content.service';
import { Observable } from 'rxjs';
import { CmsComponentData } from '@spartacus/storefront';
import { CmsBannerComponent, OccConfig } from '@spartacus/core';

@Component({
  selector: 'app-alfuria-third',
  templateUrl: './alfuria-third.component.html',
  styleUrls: ['./alfuria-third.component.scss']
})
export class AlfuriaThirdComponent implements OnInit {
  thirdnode: Observable<any> = this.bannerComponent.data$;
  mergedObject1: any = [];
  toggleArray: boolean[] = [true]; // Initialize with the first tab open

  constructor(
    private accessService: ContentService,
    public bannerComponent: CmsComponentData<CmsBannerComponent>,
    protected config: OccConfig,
    private cd: ChangeDetectorRef
  ) {}

  getImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.url;
  }

  ngOnInit() {
    this.thirdnode.subscribe(res => {
      this.productTabs(res);
    });
  }

  productTabs(res: any) {
    const tilesList = res.productInfoList.split(" ");
  
    this.accessService.opeffeaturedResources(tilesList).subscribe((res: any) => {
      if (Array.isArray(res.component)) {
        this.mergedObject1 = res.component;
      } else if (res.component) {
        this.mergedObject1 = [res.component];
      } else {
        this.mergedObject1 = [];
      }
  
    
      this.toggleArray = this.mergedObject1.map((_: any, i: number) => i === 0); // Set the first tab as active
      this.cd.detectChanges();
    });
  }

  toggleNav(i: number) {
    this.toggleArray = this.toggleArray.map((_, index) => index === i);
  }
}
// import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
// import { ContentService } from '../../spartacus/services/content.service';
// import { Observable, combineLatest } from 'rxjs';
// import { CmsComponentData } from '@spartacus/storefront';
// import { CmsBannerComponent, OccConfig } from '@spartacus/core';
// import { map } from 'rxjs/operators';

// @Component({
//   selector: 'app-alfuria-third',
//   templateUrl: './alfuria-third.component.html',
//   styleUrl: './alfuria-third.component.scss'
// })
// export class AlfuriaThirdComponent implements OnInit{
//   thirdnode: Observable<any> = this.bannerComponent.data$;
//   mergedObject1: any = [];
//   toggleArray: any = [];

//   constructor(private accessService: ContentService, public bannerComponent: CmsComponentData<CmsBannerComponent>, protected config: OccConfig, private cd: ChangeDetectorRef) {}

//   getImage(image: any): string {
//     return this.config.backend?.occ?.baseUrl + image.url;
//   }

//   ngOnInit() {
//     this.thirdnode.subscribe(res => {
//       this.productTabs(res);
//     });
//   }

//   productTabs(res: any) {
//     // const prodList = res.productInfoList.split(" ");
//     // const observables = prodList.map((el: any) => this.accessService.opeffeaturedResources(el));

//     // combineLatest(observables).pipe(
//     //   map((results:any) => results.flat())
//     // ).subscribe((mergedResults: any) => {
//     //   this.mergedObject1 = mergedResults;
//     {
//       const tilesList = res.productInfoList.split(" ");
//     
//       this.accessService.opeffeaturedResources(tilesList).subscribe((res :any) => {
      
        
//         // Check if apiRes.component is an array
//         if (Array.isArray(res.component)) {
//           this.mergedObject1 = res.component;
//         } else if (res.component) {
//           // Convert to array if it's not already
//           this.mergedObject1 = [res.component];
//         } else {
//           // Ensure it's always an array
//           this.mergedObject1 = [];
//         }
  
//         console.log('mergedObject1:', this.mergedObject1);
//         this.cd.detectChanges();  // Ensure detectChanges is called correctly
//       });
//     }
//       for(let i = 0; i < this.mergedObject1.length; i++) {
//         if (i == 0) {
//           this.toggleArray.push(true)
//         } else {
//           this.toggleArray.push(false)
//         }
//       }
//       this.cd.detectChanges();
    
//   }

//   toggleNav(i : number) {
//     for (let x = 0; x < 3; x++) {
//       if (x !== i) {
//         this.toggleArray[x] = false;
//       }
//     }
//     this.toggleArray[i] = true;
//   }

// }
