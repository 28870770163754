<div *ngIf="errorMsg$ | async" class='ASM_alert alert alert-dismissible fade show' role="alert">{{errorMsgContent}}
    <!-- <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="hideErrorMessage()">
        <span aria-hidden="true">{{'asm.close' | cxTranslate }}</span>
    </button> -->
</div>
<div id="asm" *ngIf="notCollapsed && showASM">
    <div class="asm-bar" [ngClass]="!(customerSupportAgentLoggedIn$ | async) ? 'asmLoggedOut' : 'asmLoggedIn' ">
        <div class="asm-bar-branding">
            <div class="asm-title asm-maintitle d-xl-block d-none">
                <span>{{ 'asm.mainTitle' | cxTranslate }}</span>
            </div>
            <div class="asm-title asm-smalltitle d-xl-none d-block">
                <span>{{ 'asm.asmTitle' | cxTranslate }}</span>
            </div>
            <div class="d-lg-none d-block" *ngIf="customerSupportAgentLoggedIn$ | async">
                <button class="close" title="{{ 'asm.hideUi' | cxTranslate }}" (click)="logout()">{{ 'asm.close' |
                    cxTranslate }}</button>
            </div>
        </div>
        <div class="asm-bar-actions " *ngIf="(!(customerSupportAgentLoggedIn$ | async)) && notCollapsed">
            <button id="forgot-password" *ngIf="!(customerSupportAgentLoggedIn$ | async)" data-toggle="modal"
                data-target="#forgotModal" data-backdrop="false">{{
                'asm.forgotPassword' | cxTranslate }}</button>
            <button class="close" title="{{ 'asm.hideUi' | cxTranslate }}" (click)="hideASMUi()"> {{ 'asm.close' |
                cxTranslate }}</button>
        </div>
        <div class="asm-bar-actions asmLogged" *ngIf="(customerSupportAgentLoggedIn$ | async) && notCollapsed">
            <app-flu360-asm-session-timer></app-flu360-asm-session-timer>
            <div class="signed-user">
                <span class="agent-name d-xl-block d-none">{{ 'asm.signedInLabel' | cxTranslate }}</span>
                <span>{{asAgentName}}</span>
                <button class="logout" title="{{ 'asm.logout' | cxTranslate }}" (click)="logout()">{{'asm.logout' |
                    cxTranslate }}</button>
            </div>

            <div>

            </div>
            <div class="asm-title d-lg-block d-none">
                <button class="close" title="{{ 'asm.hideUi' | cxTranslate }}" (click)="logout()">{{ 'asm.close' |
                    cxTranslate }}</button>
            </div>
        </div>
    </div>
</div>
<div class="" id="collapseExample" *ngIf="notCollapsed && showASM">
    <ng-container>
        <ng-container *ngIf="customerSupportAgentLoggedIn$ | async; else showLoginForm">
            <ng-container *ngIf="customer$ | async; else showCustomerSelection">
                <app-flu360-asm-cusomer-emulation></app-flu360-asm-cusomer-emulation>
            </ng-container>
            <ng-template #showCustomerSelection>
                <app-flu360-asm-customer-seletcion
                    (submitEvent)="startCustomerEmulationSession($event)"></app-flu360-asm-customer-seletcion>
            </ng-template>
        </ng-container>
        <ng-template #showLoginForm>
            <app-flu360-asm-login (submitEvent)="loginCustomerSupportAgent($event)"
                [csAgentTokenLoading]="!!(csAgentTokenLoading$ | async)"></app-flu360-asm-login>
        </ng-template>
    </ng-container>
</div>
<div [ngClass]="!(customerSupportAgentLoggedIn$ | async) ? 'asmLoggedOut' : 'asmLoggedIn'">
    <div *ngIf="showASM" class="collapseBtn-wrapper"
        [ngClass]="[showASM && notCollapsed ? !(customerSupportAgentLoggedIn$ | async) ? 'addOutline' : currentSite === 'seqirusb2b-uk' ? 'asm-uk-add-outline' : 'addOutline' : 'removeOutline']">
        <button (click)="handleToggleButton()" [ngClass]="notCollapsed ? 'showSvg' : 'transformSvg'"
            class="ASM_btn collapseBtn" data-toggle="collapse" data-target="#collapseExample">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve">
                <g>
                    <g transform="translate(0.000000,119.000000) scale(0.100000,-0.100000)">
                        <path
                            d="M2675.9-1189.9L404.9-3460.9l503.7-503.7l503.7-503.7l1793.9,1829.2L5000-809.9l1793.8-1829.2l1793.8-1829.2l503.7,503.7l503.7,503.7L7315.3-1181.1C6069.2,64.9,5026.5,1090,5000,1090S3930.7,64.9,2675.9-1189.9z">
                        </path>
                        <path
                            d="M2675.9-5431.5L404.9-7702.6l503.7-503.7l503.7-503.7l1793.9,1829.2L5000-5051.5l1793.8-1829.2L8587.7-8710l503.7,503.7l503.7,503.7L7315.3-5422.7c-1246,1246-2288.8,2271.1-2315.3,2271.1S3930.7-4176.7,2675.9-5431.5z">
                        </path>
                    </g>
                </g>
            </svg>
        </button>
    </div>
</div>
<div class="modal" id="forgotModal" tabindex="-1" role="dialog" aria-labelledby="forgotModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h7 class="modal-title" id="forgotModalLabel"><strong>{{ 'asm.resetPassword' | cxTranslate }}</strong>
                </h7>
                <button (click)="removeLocalStorage()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">{{ 'asm.close' | cxTranslate }}</span>
                </button>
            </div>
            <div *ngIf="showResetModal">
                <div class="modal-body">
                    <p>{{ 'asm.resetContent' | cxTranslate }}</p>
                    <label id="asm-email-wrapper">{{ 'asm.createCustomerForm.emailAddress.label' | cxTranslate
                        }}</label>
                    <input required type="email" #email />
                    <span style="color:red" *ngIf="httpResponse === 'NOT_FOUND'">{{ 'asm.errorMsg' | cxTranslate
                        }}</span>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-primary" (click)="onResetClick(email.value)">{{
                        'asm.resetPassword' | cxTranslate }}</button>
                </div>
            </div>
            <div class="modal-body" *ngIf="!showResetModal">
                <p>{{ 'asm.passwordResettedMessage' | cxTranslate }}</p>
            </div>
        </div>
    </div>
</div>