import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { PaymentInformationComponent } from './payment-information/payment-information.component';
import { SavedCardIframeComponent } from './saved-card-iframe/saved-card-iframe.component';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [PaymentInformationComponent, SavedCardIframeComponent],
  imports: [
    CommonModule,
    RouterModule,
    ConfigModule.withConfig({
      cmsComponents: {
        SeqirusPaymentInformationComponent :{
          component: PaymentInformationComponent,
      },
      SavedPaymentInfoFlexComponent:{
        component: SavedCardIframeComponent,
      }
    }
  }as CmsConfig)
  ]
})
export class PaymentInfoModule { }
