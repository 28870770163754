import { ChangeDetectorRef, Component, Input, SimpleChanges } from '@angular/core';
import { AuthService, CmsBannerComponent } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable, take } from 'rxjs';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { Router } from '@angular/router';
import { StartOrderService } from '../../custom-start-order/start-order.service';

@Component({
  selector: 'app-exit-popup',
  templateUrl: './exit-popup.component.html',
  styleUrl: './exit-popup.component.scss'
})
export class ExitPopupComponent {
  
  constructor(public component: CmsComponentData<CmsBannerComponent>,private cdr: ChangeDetectorRef,private userInfoService: UserInfoService, private startOrderService:StartOrderService,private router: Router, private authService: AuthService) {

  }
  exitPopupData: any;
  showExitPopUp: boolean = false;
  showFeedbackContent: boolean = false;
  showApiFailurePopup: boolean=false;
  route: any;
  queryParams: any;
  isUserLoggedIn: Observable<boolean> = this.authService.isUserLoggedIn();
  ngOnInit() {
    this.component.data$.subscribe((data:any)=> {
        this.exitPopupData = data;
        this.cdr.detectChanges();
    })
    this.userInfoService.getPopValue().subscribe((val: boolean) => {
      this.showExitPopUp = val;
      this.cdr.detectChanges();
    })
    /* this.startOrderService.getApiFailurePopup().subscribe((val:boolean)=>{
      this.showApiFailurePopup=val;
      this.cdr.detectChanges();
    }) */
  }
  handleOnCloseButton() {
    this.userInfoService.setPopUpValue(false);
  }
  handleOnExitBtnClick() {
    this.showFeedbackContent = true;
  }

  handleLoggedInUser(path: any) {
    if (path.includes('product') || path.includes('help-and-faq')) {
      this.handleLoggedOutUser(path);
    } else {
      localStorage.setItem('lastVisitedFooterUrl', path);
      localStorage.setItem('sessionTimedOut', 'true');
      this.authService.logout();
      this.router.navigate(['/logout']).then(() => {
      });
    }
  }

  handleLoggedOutUser(path: any) {
    this.router.navigate([path]);
  }
  
  handleOnSkipBtnClick() {
    this.showFeedbackContent = false;
    this.userInfoService.setPopUpValue(false);
    this.userInfoService.setInPlaceOrder(false);
    this.userInfoService.getRouteLink().subscribe(route => {
      this.route = route;
    });
    this.userInfoService.getQueryParams().subscribe(params => {
      this.queryParams = params;
    });

    let fromFooter:Boolean = false;
    this.userInfoService.getFromFooter().subscribe((data: Boolean) => {
      fromFooter = data;
    })
    if(fromFooter){
      this.isUserLoggedIn.pipe(take(1)).subscribe((isLoggedIn: boolean) => {
        if (isLoggedIn) {
          this.handleLoggedInUser(this.route);
        } else {
          this.handleLoggedOutUser(this.route);
        }
      });
    }
    else if(this.route == "/my-profile" || this.route == "/my-account"){
      this.router.navigate([this.route]).then(() => {
        window.location.reload();
      });
    }
    else if(this.route == "switchAccount"){
      localStorage.removeItem('rbapermission');
      let selectedAccount: any;
      this.userInfoService.getSelectedAccount().subscribe(data => {
        selectedAccount = data;
      })
        this.userInfoService.changeB2BAccount(selectedAccount).subscribe((data)=>{
        if(!this.router.url.includes('my-account')){
          this.router.navigate(['/my-account']);
        }
        else{
        const url = (this.router.url).split('?')[0].split('#')[0];
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
     
        //this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([url.substring(url.lastIndexOf('/')+1)], { onSameUrlNavigation: 'reload', queryParams: this.queryParams}).then(()=>{
        this.cdr.detectChanges();
        });
      }
        this.userInfoService.updateUserdata();
      });
    }
    else {
      this.router.navigateByUrl('/', { onSameUrlNavigation: 'reload' }).then(() => {
      this.router.navigate([this.route], {queryParams: this.queryParams})});
      //this.router.navigate([this.route], { onSameUrlNavigation: 'reload' });
    }
  }
}
