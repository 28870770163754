import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AuthService,
  OccEndpointsService,
  UserIdService,
} from '@spartacus/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MaterialsUKService {
  public asmenabled: boolean = false;

  userLoggedIn$: Observable<boolean> = this.authService.isUserLoggedIn();
  constructor(
    private http: HttpClient,
    private occEndPointsService: OccEndpointsService,
    private userIdService: UserIdService,
    private authService: AuthService
  ) {
    this.asmenabled = localStorage.getItem('showASM') === 'true';
  }
  fetchUserUrl() {
    let partUrl = '/users/current';
    this.userIdService.getUserId().subscribe((item) => {
      if (item && this.asmenabled) partUrl = `/users/${item}`;
    });

    return partUrl;
  }

  getShippingLocationData() {
    const shippingLocationUrl = this.occEndPointsService.buildUrl(
      `${this.fetchUserUrl()}/materialOrder/shippingLocation?fields=DEFAULT`
    );
    return this.http.get(shippingLocationUrl);
  }

  submitMaterialData(materialData: any): Observable<any> {
    const submitMaterialUrl = this.occEndPointsService.buildUrl(
      `${this.fetchUserUrl()}/materialOrder/submitMaterialOrder`
    );
    return this.http.post(submitMaterialUrl, JSON.stringify(materialData), {
      responseType: "text",
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  checkUserLoggedIn() {
    this.userLoggedIn$ = this.authService.isUserLoggedIn();
    this.userLoggedIn$.subscribe((data) => {
    });
  }
}
