import { ChangeDetectorRef, Component } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { UserInfoService } from '../../spartacus/services/user-info.service';

@Component({
  selector: 'app-contact-us-side',
  templateUrl: './contact-us-side.component.html',
  styleUrl: './contact-us-side.component.scss'
})
export class ContactUsSideComponent {
  userLoggedIn: boolean=false;
  userInfo: any;
 

  constructor(public component: CmsComponentData<any>,private userInfoService:UserInfoService,private cd: ChangeDetectorRef){
    
  }
  sideContent: Observable<any> = this.component.data$;
  
  ngOnInit(){
    this.userInfoService.userLoggedIn$.subscribe(res=>{
     
      this.userLoggedIn = res;
      if(this.userLoggedIn){
        this.userInfoService.getUserData().subscribe(res=>{
          if(res){
          this.userInfo=res;
        
          }
        });
      }
      /* if(this.userInfo?.webEnabled == false){
        this.renderer.setStyle(document.querySelector('.SideContent'),'display','none');
       } */
      this.cd.detectChanges();
    })
  }

}
