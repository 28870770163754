import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OccConfig } from '@spartacus/core';

@Component({
  selector: 'app-image-dialog',
  templateUrl: './image-dialog.component.html',
  styleUrls: ['./image-dialog.component.scss']
})
export class ImageDialogComponent implements OnInit {
  images: { url: string }[] = [];
  baseUrl: string;
  slideIndex = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected config: OccConfig,
    private dialogRef: MatDialogRef<ImageDialogComponent>
  ) {
    this.baseUrl = data.baseUrl;
    this.images = data.images;
  }

  ngOnInit(): void {
    this.showSlides(this.slideIndex);
  }

  plusSlides(n: number): void {
    this.showSlides(this.slideIndex += n);
    this.showSlides(this.slideIndex);
  }

  currentSlide(n: number): void {
    this.showSlides(this.slideIndex = n);
    this.showSlides(this.slideIndex);
  }

  showSlides(n: number): void {
    const slides = document.getElementsByClassName('slider-item') as HTMLCollectionOf<HTMLElement>;
    const thumbnails = document.getElementsByClassName('thumbnail') as HTMLCollectionOf<HTMLElement>;
    if (n >= slides.length) { this.slideIndex = 0; }
    if (n < 0) { this.slideIndex = slides.length - 1; }
    const offset = -this.slideIndex * 100;
    const sliderWrapper = document.querySelector('.slider-wrapper') as HTMLElement;
    sliderWrapper.style.transform = `translateX(${offset}%)`;
    for (let i = 0; i < thumbnails.length; i++) {
      thumbnails[i].className = thumbnails[i].className.replace(' active', '');
    }
    thumbnails[this.slideIndex].className += ' active';
  }

  getFileName(url: string): string {
    const fileNameWithParams = url.substring(url.lastIndexOf('/') + 1);
    return fileNameWithParams.split('?')[0];
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}