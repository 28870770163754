
// import { Component, HostListener } from '@angular/core';
// import { CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';
// import { CmsComponentData } from '@spartacus/storefront';
// import { Observable } from 'rxjs';
// @Component({
//   selector: 'app-second-adjuvanted-video',
//   templateUrl: './second-adjuvanted-video.component.html',
//   styleUrl:'./second-adjuvanted-video.component.scss',
// })
// export class SecondAdjuvantedVideoComponent {
//   node: Observable<any> = this.component.data$;
//   animationFlag!: boolean;
//   constructor(public component: CmsComponentData<CmsBannerComponent>,private cmsService:CmsService, protected config: OccConfig,) {
    
// }
// }
// import { animate, state, style, transition, trigger } from '@angular/animations';
// import { Component, HostListener, OnInit } from '@angular/core';
// import { CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';
// import { CmsComponentData } from '@spartacus/storefront';
// import { Observable } from 'rxjs';
// @Component({
//   selector: 'app-second-adjuvanted-video',
//   templateUrl: './second-adjuvanted-video.component.html',
//   styleUrls: ['./second-adjuvanted-video.component.scss'],
//   animations: [
//     trigger('fadeInOut', [
//       state('fadeIn', style({ opacity: 1, transform: 'scale(1)' })),
//       state('fadeOut', style({ opacity: 0, transform: 'scale(0.6)' })),
//       transition('fadeOut => fadeIn', [
//         animate('2.5s ease-in-out')
//       ]),
//       transition('fadeIn => fadeOut', [
//         animate('2.5s ease-in-out')
//       ])
//     ]),
//     trigger('integrate', [
//       state('integrate', style({ opacity: 1, transform: 'scale(1)' })),
//       transition('* => integrate', [
//         animate('2.5s ease-in-out')
//       ])
//     ])
//   ]
// })
// export class SecondAdjuvantedVideoComponent implements OnInit {
//   node: Observable<any> = this.component.data$;
//   animationFlag = true;
//   constructor(
//     public component: CmsComponentData<CmsBannerComponent>,
//     private cmsService: CmsService,
//     protected config: OccConfig
//   ) {}
//   ngOnInit(): void {
//     if (document.body.classList.contains('page-adjuvantedFluadPage')) {
//       setTimeout(() => {
//         const vennDiagram = document.querySelector('.js-responsive-image.animate-shield3');
//         const shield3 = document.getElementById('product_animation-3dshield');
//         const shield2 = document.getElementById('product_animation-2dshield');
//         if (vennDiagram) {
//           vennDiagram.classList.add('fadeIn');
//           setTimeout(() => {
//             vennDiagram.classList.add('fadeOut');
//           }, 2500);
//         }
//         if (shield3) {
//           shield3.style.display = 'inline';
//           setTimeout(() => {
//             shield3.classList.add('fadeOut');
//             setTimeout(() => {
//               shield3.classList.add('integrate');
//             }, 2500);
//           }, 2500);
//         }
//         if (shield2) {
//           shield2.style.display = 'inline';
//           shield2.classList.add('fadeIn');
//         }
//       }, 1000);
//     }
//   }
//   @HostListener('window:scroll', [])
//   onWindowScroll(): void {
//     const container = document.querySelector('.product_animation-container');
//     if (container) {
//       const eT = container.getBoundingClientRect().top - 250;
//       const wSt = window.scrollY;
//       if (wSt > eT && this.animationFlag) {
//         this.shieldAnimation();
//       }
//     }
//   }
//   shieldAnimation(): void {
//     const vennDiagram = document.querySelector('.js-responsive-image.animate-shield3');
//     const shield3 = document.getElementById('product_animation-3dshield');
//     const shield2 = document.getElementById('product_animation-2dshield');
//     if (vennDiagram) {
//       vennDiagram.classList.add('fadeIn');
//       vennDiagram.classList.add('fadeOut');
//     }
//     if (shield3) {
//       shield3.classList.add('fadeOut');
//       shield3.classList.add('integrate');
//     }
//     if (shield2) {
//       shield2.classList.add('fadeOut');
//       shield2.classList.add('fadeIn');
//     }
//     this.animationFlag = false;
//   }
// }
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, HostListener, OnInit, Renderer2 } from '@angular/core';
import { CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { UserInfoService } from '../../spartacus/services/user-info.service';
@Component({
  selector: 'app-second-adjuvanted-video',
  templateUrl: './second-adjuvanted-video.component.html',
  styleUrls: ['./second-adjuvanted-video.component.scss'],
  animations: [
    trigger('moveUp', [
      state('moveUp', style({ transform: 'translateY(-70%) scale(0.3)', opacity: 1 })),
      transition('* => moveUp', [
        animate('2.5s ease-in-out')
      ])
    ]),
    trigger('integrate', [
      state('integrate', style({ transform: 'translateY(-70%) scale(0.3)', opacity: 0 })),
      transition('* => integrate', [
        animate('2.5s ease-in-out')
      ])
    ])
  ]
})
export class SecondAdjuvantedVideoComponent implements OnInit {
  node: Observable<any> = this.component.data$;
  animationFlag = true;
  userLoggedIn: boolean = false;
  userInfo: any;
  webEnabled: any;
  constructor(
    public component: CmsComponentData<CmsBannerComponent>,
    private cmsService: CmsService,
    protected config: OccConfig,
    private userInfoService: UserInfoService,
    private renderer: Renderer2,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (document.body.classList.contains('page-adjuvantedFluadPage')) {
      setTimeout(() => {
        this.startAnimations();
      }, 1000);
    }
      this.userInfoService.userLoggedIn$.subscribe(res=>{
       
        this.userLoggedIn = res;
        if(this.userLoggedIn){
        // this.userInfoService.getUserData().subscribe(res=>{
   
        // if(res){
        //   this.userInfo=res;

        //   this.cd.detectChanges();
        //   }
        // });
        this.webEnabled =  localStorage.getItem('webEnabled');
      
      }
        
      })
      if(this.userInfo?.webEnabled == false){
        this.renderer.setStyle(document.querySelector('.SideContent'),'display','none');
       }
      this.cd.detectChanges();
  }

  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    const container = document.querySelector('.product_animation-container');
    if (container) {
      const eT = container.getBoundingClientRect().top - 250;
      const wSt = window.scrollY;
      if (wSt > eT && this.animationFlag) {
        this.shieldAnimation();
      }
    }
  }

  startAnimations(): void {
    const shield = document.getElementById('product_animation-shield') as HTMLElement;

    if (shield) {
      shield.classList.add('moveUp');
      setTimeout(() => {
        shield.classList.add('integrate');
      }, 2500);
    }
  }

  shieldAnimation(): void {
    const shield = document.getElementById('product_animation-shield') as HTMLElement;

    if (shield) {
      shield.classList.add('moveUp');
      setTimeout(() => {
        shield.classList.add('integrate');
      }, 2500);
    }

    this.animationFlag = false;
  }
}