import { NgModule } from '@angular/core';
import { ConfigModule, provideConfig } from '@spartacus/core';
import { LayoutConfig, defaultLayoutConfig } from '@spartacus/storefront';



@NgModule({
  declarations: [],
  imports: [
  ],

  providers:[
    provideConfig(
      <LayoutConfig>{
        ...defaultLayoutConfig,
        layoutSlots: {
       
          header:{
              lg:{
              slots:['TopHeaderSlot','TopHeaderNavSlot','SiteLogo','BottomHeaderSlot'],
                 },
				      sm:{
                  slots:['TopHeaderSlot','TopHeaderNavSlot','SiteLogo','BottomHeaderSlot','SideContent'],
                 },
              xs:{
                  slots:['TopHeaderSlot','TopHeaderNavSlot','SiteLogo','BottomHeaderSlot','SideContent'],
                 }
          },
          LandingPage2Template:{
            slots:['AlertMessageSlot','homepageBanner','SeqirusPISlot','homepageproducts','planningSection','solutionsSection','strengthenSection','featuredResourcesSection','BroughtybSection','SectionReferences1-LandingPage2','HomepageJobCodeSlot','SeqirusMultipleSoldToPopupContentSlot'],
          },
          LandingPage1Template:{
            slots:['HeaderLinksSlot','Section1','Section2B','SeqirusPIoverviewSlot','Section2A','Section2C','Section3','seqirusOverviewMultipleNeed-Slot','Section6','BottomHeaderSlot'],
          },
          AccountPageTemplate:{
            lg:{
              slots: ['SideContent','BodyContent', 'MyOrdersSection-MyOrdersPage','LeftContent']
            },
            sm:{
              slots :['BodyContent','MyOrdersSection-MyOrdersPage','LeftContent'],
            },
            xs:{
              slots :[ 'BodyContent','MyOrdersSection-MyOrdersPage','LeftContent'],
            }
          },
          LandingPage5Template:{
            lg:{  
              slots :['SideContent','BodyContent']
            },
            sm:{
              slots :['BodyContent']
            },
            xs:{
              slots :['BodyContent']
            }
          },
          InvoicePageTemplate:{
            lg:{
              slots: ['SideContent','BodyContent','LeftContent']
            },
            sm:{
              slots :['BodyContent','LeftContent'],
            },
            xs:{
              slots :[ 'BodyContent','LeftContent'],
            }
          },
          PaymentStatusPageTemplate:{
            lg:{
            slots:['MiddleContent']
          },
          sm:{
            slots :['MiddleContent'],
          },
          xs:{
            slots :['MiddleContent'],
          }
          },
          savedCardFailedStatusPageTemplate:{
            lg:{
              slots:['MiddleSlot']
            },
            sm:{
              slots :['MiddleSlot'],
            },
            xs:{
              slots :['MiddleSlot'],
            }
          },
        LoginPageTemplate:{
          lg:{
            slots:['BodyContent']
          }
        },      
        GigyaRegistrationPageTemplate:{
          // lg:{
            slots:['LeftContentSlot','BodyContent'],
          // }
        },
        GigyaProfileVerificationPageTemplate:{
          slots:['LeftContentSlot','BodyContent']
        },
        GigyaChangePasswordTemplate:{
          slots:['LeftContentSlot','BodyContent']
        },
        GigyaChangePasswordSuccessTemplate:{
          slots:['LeftContentSlot','BodyContent']
        },
        GigyaForgotPasswordTemplate:{
          slots:['LeftContentSlot','BodyContent']
        },
        GigyaResetPasswordTemplate:{
          slots:['LeftContentSlot','BodyContent']
        },
        ProfileActivationPageTemplate:{
          slots:['LeftContentSlot','BodyContentSlot']
        },
        
        JoinAccountTemplate:{
          slots:['SideContent','BodyContent']
        },
        VerificationLinkExpiredPageTemaplate:{
          slots:['LeftContentSlot','BodyContentSlot1']
        },
        VerificationEmailSentPageTemplate:{
          slots:['LeftContentSlot','BodyContentSlot1']
        },
        RegistrationPageTemplate:{
          slots:['registerBodyContentSlot','RegisterThankyouContentSlot']
        },
        SeqirusDynamicPageTemplate:{
          slots:['SideContent','MiddleSlot']
        },
        paymentInformationPageTemplate:{
           
          slots: ['SideContent','BodyContent','LeftContent']
        },
        OperationalEfficiencyNewPageTemplate:{
          slots:['AlertMessageSlot','SeqirusOperationalEfficiencyFirst-Slot','seqirusOperationalEfficiencySecond-Slot','seqirusOperationalEfficiencyMultipleNeed-Slot','SeqirusOperationalEfficiencyThird-Slot','SeqirusOperationalEfficiencyFourth-Slot']
      },
      ClinicalSupportNewPageTemplate:{
        slots:['AlertMessageSlot','SeqirusClinicalSupportFirst-Slot','SeqirusClinicalSupportSecond-Slot','SeqirusClinicalSupportMultipleNeed-Slot','SeqirusClinicalSupportThird-Slot','SeqirusClinicalSupportFourth-Slot']   
       },
       Influenza65PlusNewPageTemplate:{
        slots:['AlertMessageSlot','SeqirusInfluenzaPlusFirst-Slot','SeqirusInfluenzaPlusSecond-Slot','SeqirusInfluenzaPlusThird-Slot','SeqirusInfluenzaPlusFourth-Slot','SeqirusInfluenzaPlusFifth-Slot','SeqirusInfluenzaPlusSixth-Slot']
       },
      //  AllProductsPageTemplate:{
      //    slots:['AlertMessageSlot','SideContent','SeqirusLOAllProductFirst-Slot','SeqirusLOAllProductSecond-Slot','SeqirusLOAllProductThird-Slot','SeqirusLOAllProductFourth-Slot','SeqirusLOAllProductFifth-Slot']
      //  },
      //  FaqsPageTemplate:{
      //   slots:['AlertMessageSlot','SideContent','SeqirusHelpAndFAQLOFourth-Slot','SeqirusHelpAndFAQLOFirst-Slot','SeqirusHelpAndFAQLOSecond-Slot','SeqirusHelpAndFAQLOThird-Slot']
      // },
      AllProductsPageTemplate:{
       slots:['SideContent','BodyContent','SeqirusLOAllUKProductEigth-Slot']
      },
      FaqsPageTemplate:{
        slots:['SideContent','BodyContent']
      },
      fluadClinicalDataPageTemplate:{
        slots:['SideContent','BodyContent']
      },
      fluadRealWorldEvidencePageTemplate:{
        slots:['SideContent','BodyContent']
      },
      cellBasedSolutionPageTemplate:{
        slots:['SideContent','BodyContent']
      },
       AboutSeqirusPageTemplate:{
        slots:['AlertMessageSlot','seqirusaboutSection1-aboutPage','seqirusaboutusoptionSection1-aboutPage','seqirusaboutusFLuworldSection1-aboutPage','seqirusaboutusReferenceSection1-aboutPage',
          'SeqirusAboutPageUKFirst-Slot','SeqirusAboutPageUKSecond-Slot','SeqirusAboutPageUKThird-Slot','SeqirusAboutPageUKFourth-Slot','SeqirusAboutPageUKFifth-Slot','SeqirusAboutPageUKSixth-Slot'
        ]
       },
       FinancialGuidanceNewPageTemplate:{
        slots:['AlertMessageSlot','SeqirusFinancialGuidanceFirst-Slot','seqirusFinancialGuidanceSecond-Slot','seqirusFinancialGuidanceMultipleNeed-Slot','SeqirusFinancialGuidanceThird-Slot','SeqirusFinancialGuidanceFourth-Slot']
       
       },
       PublicErrorPageTemplate:{
         slots:['SeqirusPublicPageLOThird-Slot','SeqirusPublicPageLOFourth-Slot','Footerslot']
       },

       RegistrationAccountTemplate:{
        slots: ['SideContent','BodyContentUK','BodyContent']
       },
     
       AfluriasPageTemplate:{
        slots:['SideContent','BodyContent']
       },
       SiteMapAPITemplate:{
        slots:['MiddleSlot']
       },
       SiteMapPageTemplate:{
        slots:['MiddleSlot']
       }, 
       efficacyandSafetyPageTemplate:{
        slots:['SideContent','BodyContent']
       },
       FluadPageTemplate:{
        slots:['SideContent','BodyContent']
 
       },
       CookiePolicyPageTemplate:{
        slots:['AlertMessageSlot','BodyContent']
       },
       RealWorldEvidencePageTemplate:{
slots:['SideContent','BodyContent']
       },
 adjuvantedFluadPageTemplate:{
        slots:['SideContent','BodyContent']
 
       },
       FlucelvaxPageTemplate:{
        slots:['AlertMessage','SideContent','BodyContent']
      },
ContentPage1Template:{
       slots:['BodyContent','Section1','customFooterlogout']
      },
      ResourcesLandingTemplate:{
         slots: ['SideContent','BodyContent']
      },
      
       ErrorPageTemplate:{
        slots:['errorPosition','SideContent','notFound1']
       },
        CartPageTemplate: {
          lg:{
            slots: ['SideContent','CenterLeftContentSlot']
          },
          sm:{
            slots :['CenterLeftContentSlot'],
          },
          xs:{
            slots :['CenterLeftContentSlot'],
          }
        },
        CreateOrderPageTemplate: {
          slots:['SideContent', 'BodyContent']
        },
        ReturnsPageTemplate:{
          slots: ['SideContent','BodyContent','Section3-returnsLandingPage','prescribing-returnsLandingPage','Section1-returnsLandingPage','Section2-returnsLandingPage','Section4-returnsLandingPage','Section5-returnsLandingPage','Section9-returnsLandingPage','Section8-returnsLandingPage']
        },
        ReturnsNewPageTemplate:{
          slots: ['SideContent','BodyContentUK']
        },
        mobileUKRedirectionPageTemplate:{
          slots: ['BodyContent']
        },
        PrescribingInformationPageTemplate:{
          slots: ['SideContent','SeqirusPrescribingInfoFirst-Slot','SeqirusPrescribingInfoThird-Slot','SeqirusPrescribingInfoFourth-Slot']
        },
        ExplicitConsentPageTemplate:{
          slots:['explicitConsent','ExplicitFooter']
        },
        InactivePageTemplate:{
          slots:['BodyContent']
        },
        footer:{
            lg:{
            slots:['Footer'],
            }, 
        },
        InfleunzaNewPageTemplate:{
          slots:['AlertMessageSlot','SeqirusInfluenzaFirst-Slot','SeqirusInfluenzaSecond-Slot','SeqirusInfluenzaThird-Slot','SeqirusInfluenzaFourth-Slot','SeqirusInfluenzaFifth-Slot','SeqirusInfluenzaSixth-Slot']
         },
         ResourcesLandingNewPageTemplate:{
          slots:['SideContent','SeqirusToolsAndResourceMainPage-Slot']
         },
         ResourcesDetailsPageTemplate:{
          slots:['SideContent','SeqirusToolsAndResourceDetailPage2-Slot']
         },
         CodingAndBillingNewPageTemplate:{
          slots:['AlertMessageSlot','SeqirusCodingBillingFirst-Slot','SeqirusCodingBillingSecond-Slot','SeqirusCodingBillingThird-Slot','SeqirusCodingBillingSixthMob-Slot','SeqirusCodingBillingFourth-Slot','SeqirusCodingBillingFifth-Slot','SeqirusCodingBillingSixth-Slot']
         },
         ASMResetPasswordPageTemplate:{
          slots:['AlertMessageSlot','BodyContent']
         },
        // FluadPageTemplate: {
        //   slots: ['SideContent', 'BodyContent']
        // }
        GigyaEmailVerificationPageTemplate:{
          slots:['LeftContentSlot','BodyContent']
        }        
      },
      }
    )
  ]
})
export class LayoutModule { }
