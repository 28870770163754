   <div class="overlay" *ngIf="paramsCode!=='403002'">
      <div class="overlay__inner">
        <div class="overlay__content"><span class="spinner"></span>
          <br>
          Loading...
        </div>
      </div>
    </div> 
    <div class="expired-link-container" *ngIf="paramsCode=='403002'">
        <section class="expiredEmail_section">
      <form [formGroup]="emailVerificationForm" class="col-lg-12 col-xs-12 nopadding expiredEmail_form"
      id="expiredEmail_form" (ngSubmit)="resendVerification()">
      <div class="col-xs-12 col-sm-8 col-md-8 form-group">
        <label for="expired_email" class="form-label">Email Address</label>
        <input formControlName="expired_email" type="text" id="expired_email" name="expired_email"
          class="form-control" placeholder="Enter Email Address" required
          [ngClass]="{'is-invalid': (getFormControl('expired_email').invalid && getFormControl('expired_email').touched) || apiErr.length>0, 
          'is-valid': getFormControl('expired_email').valid, '': getFormControl('expired_email').untouched}">
          <!-- <span class="input-group-addon">

          <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
          </span> -->

          <div
          class="invalid-feedback"
          *ngIf="getFormControl('expired_email').hasError('required')">
          Please enter your Email
          </div>
          <div
          class="invalid-feedback"
          *ngIf="getFormControl('expired_email').hasError('email')">
          Please enter valid Email
          </div>
          <div class="invalid-api-feedback">
            {{apiErr}}
            </div>
      </div>
     
      <button (click)="resendVerification()" type="button" class="expiredEmail_setsuppbtn active">Send</button>
    </form>
    </section>
    </div>
