<!-- <div>
  <img src="../../../assets/images/final-cell-animation.gif" #lazyGif alt="Animated GIF" class="lazy-load">
</div> -->



<div class="slider-wrapper" #sliderWrapper>
  <div class="slider-container" #sliderContainer>
    <div class="slider" #slider [style.transform]="'translateX(' + translateX + 'px)'">
      <div class="centeralign cellcontainer">
        <div class="celltext">
            <div class="cellheader">ADVANCING WITH THE CELL-BASED SOLUTION</div>
                <ul class="animationsubtext  col-md-10">
                    <li>Produced in mammalian cells to avoid egg adaptation, providing an exact antigenic match to WHO-selected flu strains<sup>2,14-16</sup></li>
                    <li><span>May improve vaccine effectiveness and reduce hospitalizations</span> vs traditional, egg-based vaccines, based on RWE<sup>*17-19</sup></li>
                </ul>
        </div>
        <div class="cellimage">
            <img src="assets/images/CellImage.webp">
        </div>
        </div>
    </div>
    <div class="slider" #slider [style.transform]="'translateX(' + translateX + 'px)'">
      <div class="parallexgroup centeralign" id="egggroup" style="opacity: 1;">
        <div class="eggtext ">
            <div class="eggheader">MOVING BEYOND THE RISK OF EGG ADAPTATION</div>
            <ul class="animationsubtext  col-md-10">
                <li>Occurs when vaccine strains mutate to grow in eggs, which can reduce vaccine effectiveness and may increase hospitalizations<sup>2</sup></li>
                <li>In 7 recent flu seasons where strain mismatch occurred,<span> nearly half were caused by egg adaptation</span><sup>*3-13</sup></li>
            </ul>
        </div>
        <div class="eggimage"><img src="assets/images/EggImage.webp"></div>
      </div>
    </div>
    <div class="slider" #slider [style.transform]="'translateX(' + translateX + 'px)'">
      <div class="afterintotext">NOT ALL FLU VACCINES<br> ARE CREATED THE SAME.<sup>1</sup></div>
    </div>
    <div #arrowImage class="introarrowsymbol">
      <img src="assets/images/GreenArrow.png">
    </div>
  </div>
</div>
