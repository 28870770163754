<div class="alert alert-info alert-dismissable getAccAlert customalert">
    <button class="close closeAccAlert" aria-hidden="true" data-dismiss="alert" type="button">×</button>
    Success! You can now login using your new password.
</div>

<div id="breadcrumb" class="col-sm-12 col-md-12">
    <ol class="breadcrumb">
        <li>
            <a [routerLink]="'/'">Home</a>
        </li>

        <li class="bc_bold"><strong>Update Password</strong></li>
    </ol>
</div>
<div class="col-xs-12" id="asmsucces">
    <div class="contentArea col-xs-11">
        <div class="leftcontentArea col-xs-12 col-md-4">
            <div class="leftHeader col-xs-11">LOG IN</div>
            <div class="leftsubHeader col-xs-11">Access orders, view
                invoices, and find relevant resources for your clinics.</div>
        </div>
        <div class="rightcontentArea col-xs-12 col-md-8" id="rightSection_1">
            <div class="section1_header col-xs-12">
                <div class="passtext">PASSWORD RESET SUCCESSFUL</div>
            </div><br><br>
            <div class="enterCodeheader col-xs-12">Please login with your new password</div>
            <br><br>
            <div class="col-xs-12 prevnmail">
                <div class="logInbtn"><a [routerLink] ="'/liveassist'">Log in</a></div>
            </div>
            <br><br>
            <br><br>

        </div>
    </div>
</div>