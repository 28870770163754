<div class="col-xs-12 col-sm-12 myprofile_tabContent" id="myprofile_tabContent2" >
              
    <div class="col-xs-12 col-sm-12 no-padding myprofile_tabContentHeader">My Account</div>
    <div class="col-xs-12 col-sm-12 no-padding org_content">{{accountdata?.spCompanyName}}</div>


    <div class="col-xs-12 col-sm-12 no-padding ">

        <div class="row myprofile_row-flex myprofile_topsections myProfileForUK">
        
            <div class="col-md-4 col-sm-12 col-xs-12  no-left-padding" *ngIf="isSearchByProfile">
                <div class=" myprofile_acc ">
                  
                    <div class="account-header">Seqirus Account Info</div>
                    <div class="profileaccount-content">
                        <div class="myprofile_hybrisheader">Account Number</div>
                    <div class="myprofile_hybrisheader_txt_sub">{{accountdata?.soldToAcc}}</div>
                    </div>
                    
                    <div class="profileaccount-content">
                        <div class="myprofile_hybrisheader">Email</div>
                        <div class="myprofile_hybrisheader_txt_sub">{{accountdata?.soldToEmail}}</div>
                    </div>
                    
                    <div class="profileaccount-content">
                        <div class="myprofile_hybrisheader">Phone</div>
                        <div class="myprofile_hybrisheader_txt_sub">{{accountdata?.soldToPh}}<span class="myprofile_hybrisheader_txt_sub">&nbsp;Ext.
                            {{accountdata?.soldToExt}}</span>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div class="col-md-8 col-sm-12 col-xs-12 myprofile_flex myProfile_myOrg no-right-padding">
                <div class="myprofileuk_content">
                    <div class="myOrg_parent">
                        <div class="col-md-9 col-sm-8 col-xs-8 no-left-padding">
                            <div class="Org_header">My Organisation</div>
                            
                        </div>

                        <div class="col-md-3 col-sm-4 col-xs-4 no-right-padding">
                            <ng-container *ngIf="accountrba?.currentUserAccountPermission=='MANAGE'">
                                    <div class="myprofile_edit" id="myprofile_editbtn" *ngIf="accountdata?.status != 'PENDING' && !editview && showsubmitedit" (click)="showedit()">
                                        Edit <img  src="assets/images/edit.png" width="20" alt="Edit">
                                    </div></ng-container>
                                </div>
                            </div>
                    <div class="myprofile_view" *ngIf="!editview">
                        <div class="row margin-T20">
                            <div class="col-md-4 col-sm-3 col-xs-5">
                                <div class="myprofile_hybrisheader_sub_txt">Organisation Type</div>
                                <div class="myprofile_hybrisheader_txt_sub">{{accountdata?.businessType}}</div>
                            </div>
                            <div class="col-md-4 col-sm-4 col-xs-5">
                                <div class="myprofile_hybrisheader_sub_txt"># of Delivery Locations</div>
                                <div class="myprofile_hybrisheader_txt_sub">{{accountdata?.countuk}}</div>
                            </div>
                            <div class="col-md-4 col-sm-5 col-xs-5 org_mob">
                                <div class="myprofile_hybrisheader_sub_txt">NHS Code</div>
                                <div class="myprofile_hybrisheader_txt_sub">{{accountdata?.nhcNumber}}</div>
                            </div>



                        </div>
                        <div class="row margin-T20">
                            <div class="col-md-4 col-sm-3 col-xs-5">
                                <div class="myprofile_hybrisheader_sub_txt">VAT #</div>
                                <div class="myprofile_hybrisheader_txt_sub">{{accountdata?.vatNumber}}</div>
                            </div>
                            <div class="col-md-4 col-sm-4 col-xs-5" style="padding-right:0px;">
                                <div class="myprofile_hybrisheader_sub_txt">Company Registration #</div>
                                <div class="myprofile_hybrisheader_txt_sub">{{accountdata?.companyRegNumber}}</div>
                            </div>
                            <div class="col-md-4 col-sm-5 col-xs-5 org_mob">
                                <div class="myprofile_hybrisheader_sub_txt">Trading Since</div>
                                <div class="myprofile_hybrisheader_txt_sub">{{accountdata?.tradingSince}}</div>
                            </div>
                        </div>
                        <div class="row margin-T20">
                            <div class="col-md-6 col-sm-6 col-xs-12 ">
                                <div class="myprofile_hybrisheader_sub_txt">Address</div>
                                <div class="myprofile_company_address">
                                    <ng-container *ngIf="accountdata?.orgAddressLine1">{{accountdata?.orgAddressLine1}},<br></ng-container>
                                    <ng-container *ngIf="accountdata?.orgAddressLine2">{{accountdata?.orgAddressLine2}},<br></ng-container>
                                     {{accountdata?.orgAddressCity}},
                                    {{accountdata?.orgAddressCountryName}}<br>
                                   {{accountdata?.orgAddressZipCode}}</div>
                            </div>
                            <div class="col-md-6 col-md-offset-3 col-sm-6 col-xs-12 org-add">
                                <div class="myprofile_hybrisheader_sub_txt">Main Contact</div>
                                <div class="myprofile_company_contact">
                                    {{accountdata?.customerDataLastName}}&nbsp;{{accountdata?.customerDataFirstName}}<br>
                                    {{accountdata?.customerDataOrgAddressEmail}}<br>
                                    {{accountdata?.customerDataOrgAddressPhone}}&nbsp;Ext. {{accountdata?.customerDataOrgAddressPhoneExt}}</div>
                            </div>

                        </div>
                    </div>
                    



<!-- edit view  -->
<div class="myprofile_editview" *ngIf="editview" >
    <div class="row">
        <div class="col-md-9 col-sm-12 col-xs-12 margin-T20">
            <div class="myprofile_editheader_txt ">Contact Information &nbsp;&nbsp;&nbsp;<a 
                 (click)="openrequestmodal()"
                    id="myprofile_request" data-toggle="modal" data-target="#requestform"><span class="myprofile_editheader_txt_sub">Request Changes<div class="global_blackarrow"></div></span></a>
            </div>
        </div>

    </div>
    <form  id="myprofile_editForm" [formGroup]="customerRegisterForm" method="post" novalidate="novalidate">
        <div class="row margin-T10">
            <div class="col-md-6 col-sm-6 col-xs-12">
                <label class="myprofile_editcompany_txt editnameuk" for="Account-no">Organisation Name</label>
                <input class="myprofile_input form-control" formControlName="Accountname" id="Account-no" name="Account-no" value="" >
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12">
                <label class="myprofile_editcompany_txt" for="company-name">Organisation Type</label>
                <select class="myprofile_state form-control" id="company-type" name="companytype" formControlName="businessType" >
                    <option [value]="accountdata?.businessType">{{accountdata?.businessType}}</option>

                </select>
                
            </div>
           
        </div>
        <div class="row margin-T10">
            <div class="col-md-3 col-sm-6 col-xs-12">
                <label class="myprofile_editcompany_txt editnameuk" for="delivery_count"># of Delivery Locations</label> <input class="myprofile_input form-control" id="delivery_count" name="delivery_count" formControlName="countuk" value="" >
            </div>
            <div class="col-md-3 col-sm-6 col-xs-12">
                <label class="myprofile_editcompany_txt editnameuk" for="nhs_code">NHS Code</label> <input class="myprofile_input form-control" id="nhs_code" name="nhs_code" value=""  formControlName="nhcNumber">

            </div>
            <div class="col-md-3 col-sm-6 col-xs-12">
                <label class="myprofile_editcompany_txt editnameuk" for="vat_no">VAT #</label> <input class="myprofile_input form-control" id="vat_no" name="vat_no" value=""  formControlName="vatNumber">

            </div>
            <div class="col-md-3 col-sm-6 col-xs-12">
                <label class="myprofile_editcompany_txt editnameuk" for="com_reg">Company Registration #</label> <input class="myprofile_input form-control" id="com_reg" name="com_reg" value=""  formControlName="companyRegNumber">

            </div>
            <div class="col-md-3 col-sm-6 col-xs-12">
                <label class="myprofile_editcompany_txt editnameuk" for="trading_since">Trading Since</label> <input class="myprofile_input form-control" id="trading_since" name="trading_since" value=""  formControlName="tradingSince">

            </div>
        </div>
        <div class="row margin-T20">
            <div class="col-md-6 col-sm-6 col-xs-12">
                <label class="myprofile_editbusinessinfo_txt">Business Information</label> <label class="myprofile_editbusinessinfo_txt_sub">Business Address</label>
            </div>
        </div>
      
       
        <div class="row margin-T10">
            <div class="col-md-6 col-sm-6 col-xs-12">
                <label class="myprofile_editcompany_txt" for="myprofile_Address1">Address Line 1*</label>
                <div class="input-group">
                <input class="myprofile_state form-control" id="myprofile_Address1"
                    name="orgAddressForm.Line1" formControlName="orgAddressLine1" value="">
                </div>
                        <span class="glyphicon glyphicon-exclamation-sign twofiled" *ngIf="getFormControl('orgAddressLine1').dirty && (getFormControl('orgAddressLine1').hasError('required'))"></span>
                   
               
                    <ng-container *ngIf="getFormControl('orgAddressLine1').dirty && (getFormControl('orgAddressLine1').hasError('required'))">
                        <!-- <span class="glyphicon form-control-feedback glyphicon-exclamation-sign"></span> -->
                        <label id="myprofile_Address1-error" class="error help-block" for="myprofile_Address1">Please enter an Address</label>
                    </ng-container>    
            </div>
        </div>
        <div class="row margin-T10">
            <div class="col-md-6 col-sm-6 col-xs-12">
                <label class="myprofile_editcompany_txt" for="myprofile_Address2">Address Line 2</label>
                <input class="myprofile_state form-control" id="myprofile_Address2" value=""
                    name="orgAddressForm.line2" formControlName="orgAddressLine2">
            </div>
        </div>
        <div class="row margin-T10">
            <div class="col-md-6 col-sm-6 col-xs-12">
                <label class="myprofile_editcompany_txt" for="myprofile_city">City*</label>
                <div class="input-group">
                  <input class="myprofile_state form-control" id="myprofile_city"
                    name="orgAddressForm.city" formControlName="orgAddressCity" value="">
                </div>
                        <span class="glyphicon glyphicon-exclamation-sign twofiled" *ngIf="getFormControl('orgAddressCity').dirty && getFormControl('orgAddressCity').hasError('required')"></span>
                  
               
                    <ng-container *ngIf="getFormControl('orgAddressCity').dirty && getFormControl('orgAddressCity').hasError('required')">
                        <!-- <span class="glyphicon form-control-feedback glyphicon-exclamation-sign"></span> -->
                        <label *ngIf="getFormControl('orgAddressCity').hasError('required')" id="myprofile_Address1-error" class="error help-block" for="myprofile_Address1">Please enter your City</label>
                    </ng-container>

            </div>
        </div>
        <div class="row margin-T10 stateZip">
            <div class="col-md-3 col-sm-6 col-xs-6">
                <label class="myprofile_editcompany_txt" for="myprofile_state">Post code*</label>
                <input class="myprofile_state form-control" id="myprofile_post" name="orgAddressForm.postalCode" value="" formControlName="orgAddressZipCode">
                <span class="glyphicon glyphicon-exclamation-sign twofiled" *ngIf="getFormControl('orgAddressZipCode').dirty && (getFormControl('orgAddressZipCode').hasError('required') || getFormControl('orgAddressZipCode').hasError('pattern'))">
                   
                </span>
           
                <ng-container *ngIf="getFormControl('orgAddressZipCode').dirty && (getFormControl('orgAddressZipCode').hasError('required') || getFormControl('orgAddressZipCode').hasError('pattern'))">
                    <!-- <span class="glyphicon form-control-feedback glyphicon-exclamation-sign"></span> -->
                    <label *ngIf="getFormControl('orgAddressZipCode').hasError('required')" id="myprofile_Address1-error" class="error help-block" for="myprofile_Address1">Please enter a valid Post code</label>
                    <label *ngIf="getFormControl('orgAddressZipCode').hasError('pattern')" id="myprofile_Address1-error" class="error help-block" for="myprofile_Address1">Please enter a valid Post code</label>
                </ng-container>
            </div>
            <div class="col-md-3 col-sm-6 col-xs-6">
                <label class="myprofile_editcompany_txt" for="myprofile_zipcode">Country*</label>
                <div class="input-group">
                <input class="myprofile_state form-control myprofile_zipcode" id="myprofile_zipcode"
                    name="orgAddressForm.postalCode" value="" formControlName="orgAddressCountryName">
                    <span class="glyphicon glyphicon-exclamation-sign" *ngIf="getFormControl('orgAddressCountryName').dirty && (getFormControl('orgAddressCountryName').hasError('required') || getFormControl('orgAddressCountryName').hasError('pattern'))">
                        
                    </span>
                
                    <ng-container *ngIf="getFormControl('orgAddressCountryName').dirty && (getFormControl('orgAddressCountryName').hasError('required') || getFormControl('orgAddressCountryName').hasError('pattern'))">
                        <!-- <span class="glyphicon form-control-feedback glyphicon-exclamation-sign"></span> -->
                        <label *ngIf="getFormControl('orgAddressCountryName').hasError('required')" id="myprofile_Address1-error" class="error help-block" for="myprofile_Address1">Please enter a valid Country</label>
                        <label *ngIf="getFormControl('orgAddressCountryName').hasError('pattern')" id="myprofile_Address1-error" class="error help-block" for="myprofile_Address1">Please enter a valid Country</label>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="row margin-T10">
            <div class="col-md-3 col-sm-6 col-xs-6">
                <label class="myprofile_editbusinessinfo_txt_sub manage_top">Main Contact</label>
            </div>
        </div>
        <div class="row margin-T10">
            <div class="col-md-6 col-sm-6 col-xs-12">
                <label class="myprofile_editcompany_txt editnameuk" for="contact-name">First Name*</label> <input class="myprofile_state form-control" id="contact-name" name="firstName" value="" formControlName="customerDataFirstName">
                <span class="glyphicon glyphicon-exclamation-sign twofiled" *ngIf="getFormControl('customerDataFirstName').dirty && (getFormControl('customerDataFirstName').hasError('required') || getFormControl('customerDataFirstName').hasError('pattern'))">
                   
                </span>
            
                <ng-container *ngIf="getFormControl('customerDataFirstName').dirty && (getFormControl('customerDataFirstName').hasError('required') || getFormControl('customerDataFirstName').hasError('pattern'))">
                    <!-- <span class="glyphicon form-control-feedback glyphicon-exclamation-sign"></span> -->
                    <label *ngIf="getFormControl('customerDataFirstName').hasError('required')" id="myprofile_Address1-error" class="error help-block" for="myprofile_Address1">Please enter a First Name.</label>
                    <label *ngIf="getFormControl('customerDataFirstName').hasError('pattern')" id="myprofile_Address1-error" class="error help-block" for="myprofile_Address1">Please enter only letters</label>
                </ng-container>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12">
                <label class="myprofile_editcompany_txt editnameuk" for="contact-last">Last Name*</label> <input class="myprofile_state form-control" id="contact-last" name="lastName" value="" formControlName="customerDataLastName">
                <span class="glyphicon glyphicon-exclamation-sign twofiled" *ngIf="getFormControl('customerDataLastName').dirty && (getFormControl('customerDataLastName').hasError('required') || getFormControl('customerDataLastName').hasError('pattern'))">
                   
                </span>
            
                <ng-container *ngIf="getFormControl('customerDataLastName').dirty && (getFormControl('customerDataLastName').hasError('required') || getFormControl('customerDataLastName').hasError('pattern'))">
                    <!-- <span class="glyphicon form-control-feedback glyphicon-exclamation-sign"></span> -->
                    <label *ngIf="getFormControl('customerDataLastName').hasError('required')" id="myprofile_Address1-error" class="error help-block" for="myprofile_Address1">Please enter a Last Name.</label>
                    <label *ngIf="getFormControl('customerDataLastName').hasError('pattern')" id="myprofile_Address1-error" class="error help-block" for="myprofile_Address1">Please enter Only Letters</label>
                </ng-container>
            </div>

        </div>
        <div class="row margin-T10">
            <div class="col-md-6 col-sm-6 col-xs-12">
                    <label class="myprofile_editcompany_txt editnameuk" for="contact-tittle">Job Title*</label>
                     <!-- <input class="myprofile_state form-control" id="contact-tittle" name="orgAddressForm.jobTitle" value="" formControlName="customerDataJobTitle"> -->
                     <!-- job title change for feb  -->
                        <select id="contact-tittle" name="orgAddressForm.jobTitle" class="myprofile_state form-control" formControlName="customerDataJobTitle">
                            <option *ngFor="let job of jobTitles" value="{{job}}">{{job}}</option>
                        </select>
                        <span class="glyphicon glyphicon-exclamation-sign twofiled" *ngIf="getFormControl('customerDataJobTitle').touched && getFormControl('customerDataJobTitle').hasError('required')">
                        </span>
                       <ng-container *ngIf="getFormControl('customerDataJobTitle').hasError('required')"> <label  id="myprofile_jobtitle-error" class="error help-block" for="myprofile_jobtitle">Please select the Job Title</label></ng-container>
                       
                </div>
                
                <div class="col-md-6 col-sm-6 col-xs-12">
                    <label class="myprofile_editcompany_txt editnameuk" for="contact-email">Email*</label> <input class="myprofile_state form-control" id="contact-email" name="orgAddressForm.email" value="" formControlName="customerDataOrgAddressEmail">
                    <span class="glyphicon glyphicon-exclamation-sign twofiled" *ngIf="getFormControl('customerDataOrgAddressEmail').dirty && (getFormControl('customerDataOrgAddressEmail').hasError('required') || getFormControl('customerDataOrgAddressEmail').hasError('pattern'))">
                        
                    </span>
               
                    <ng-container *ngIf="getFormControl('customerDataOrgAddressEmail').dirty && (getFormControl('customerDataOrgAddressEmail').hasError('required') || getFormControl('customerDataOrgAddressEmail').hasError('pattern'))">
                        <!-- <span class="glyphicon form-control-feedback glyphicon-exclamation-sign"></span> -->
                       <ng-container *ngIf="getFormControl('customerDataOrgAddressEmail').hasError('required')"> <label  id="myprofile_Address1-error" class="error help-block" for="myprofile_Address1">Please enter a valid Email Address.</label></ng-container>
                       <ng-container *ngIf="getFormControl('customerDataOrgAddressEmail').hasError('pattern')"><label  id="myprofile_Address1-error" class="error help-block" for="myprofile_Address1">Please enter a valid Email Address.</label></ng-container>
                    </ng-container>
                </div>
                
            </div>
            <div class="row margin-T10">
                <div class="col-md-4 col-sm-6 col-xs-12">
                    <label class="myprofile_editcompany_txt editnameuk" for="contact-phone">Phone*</label> <input class="myprofile_state form-control" id="contact-phone" maxlength="11" name="phoneNumber" value="" formControlName="customerDataOrgAddressPhone" (keyup)="change()">
                    <ng-container *ngIf="getFormControl('customerDataOrgAddressPhone').dirty && (getFormControl('customerDataOrgAddressPhone').hasError('maxlength') || getFormControl('customerDataOrgAddressPhone').hasError('pattern') || getFormControl('customerDataOrgAddressPhone').hasError('required') || getFormControl('customerDataOrgAddressPhone').hasError('minlength'))"><span class="glyphicon glyphicon-exclamation-sign twofiled" >
                        
                    </span></ng-container>
                
                    <ng-container *ngIf="getFormControl('customerDataOrgAddressPhone').dirty && (getFormControl('customerDataOrgAddressPhone').hasError('required') || getFormControl('customerDataOrgAddressPhone').hasError('pattern') || getFormControl('customerDataOrgAddressPhone').hasError('minlength') || getFormControl('customerDataOrgAddressPhone').hasError('maxlength'))">
                        <!-- <span class="glyphicon form-control-feedback glyphicon-exclamation-sign"></span> -->
                        <label *ngIf="getFormControl('customerDataOrgAddressPhone').hasError('required')" id="myprofile_Address1-error" class="error help-block" for="myprofile_Address1">Please enter a valid UK format phone number.</label>
                        <label *ngIf="getFormControl('customerDataOrgAddressPhone').hasError('minlength')" id="myprofile_Address1-error" class="error help-block" for="myprofile_Address1">Please enter a valid UK format phone number.</label>

                        <label *ngIf="getFormControl('customerDataOrgAddressPhone').hasError('maxlength')" id="myprofile_Address1-error" class="error help-block" for="myprofile_Address1">Please enter a valid UK format phone number.</label>
                        <label *ngIf="getFormControl('customerDataOrgAddressPhone').hasError('pattern')" id="myprofile_Address1-error" class="error help-block" for="myprofile_Address1">Please enter only Numbers</label>
                    </ng-container>
                </div>
                <div class="col-md-2 col-sm-6 col-xs-12">
                    <label class="myprofile_editcompany_txt editnameuk" for="contact-phoneext">Ext</label> <input class="myprofile_state form-control" id="contact-phoneext" maxlength="4" name="phoneExt" value="" formControlName="customerDataOrgAddressPhoneExt">
                    <span class="glyphicon glyphicon-exclamation-sign twofiled" *ngIf="getFormControl('customerDataOrgAddressPhoneExt').dirty && (getFormControl('customerDataOrgAddressPhoneExt').hasError('maxlength') || getFormControl('customerDataOrgAddressPhoneExt').hasError('pattern'))">
                        
                    </span>
               
                    <ng-container *ngIf="getFormControl('customerDataOrgAddressPhoneExt').dirty && (getFormControl('customerDataOrgAddressPhoneExt').hasError('maxlength') || getFormControl('customerDataOrgAddressPhoneExt').hasError('pattern'))">
                        <!-- <span class="glyphicon form-control-feedback glyphicon-exclamation-sign"></span> -->
                        <label *ngIf="getFormControl('customerDataOrgAddressPhoneExt').hasError('maxlength')" id="myprofile_Address1-error" class="error help-block" for="myprofile_Address1">Please enter your Valid Extn Number</label>
                        <label *ngIf="getFormControl('customerDataOrgAddressPhoneExt').hasError('pattern')" id="myprofile_Address1-error" class="error help-block" for="myprofile_Address1">Please enter your Valid Extn Number</label>
                    </ng-container>
                </div>
                
            </div>
        <input type="hidden" name="orgAddressForm.addressId" value="SP0000376350">
        <input type="hidden" name="orgAddressForm.modifiedQualifiers" value="" id="modifiedQualifiers"
            modified-id="orgAddressForm.addressId">
        <div class="row margin-T30">
            <div class="col-md-5 col-sm-6 col-xs-12"> </div>
            <div class="col-md-7 col-sm-6 col-xs-12">
                <div class="col-md-6 col-sm-6 col-xs-6 no-padding">
                    <button (click)="showedit()" type="button" class="myprofile_cancel"
                        id="myprofile_cancel">Cancel</button>
                </div>
                <div class="col-md-6 col-sm-6 col-xs-6 no-padding">
                    <button (click)="submitBusinessDetails()" type="button" class="myprofile_submit submituk" id="myprofile_submit">Submit</button>
                </div>
            </div>
        </div>

        <!-- request modal -->
        <div class="modal fade" id="requestform"  *ngIf="requestModal" role="dialog" style="padding-right: 10px;" >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title myprofile_modal_header" id="myModalLabel">Request Changes</h4>
                        <div  class="myprofile_modalclose" data-dismiss="modal" >×</div>
                       
                    </div>
                    <div class="modal-body">
                        <p class="myprofile_modalbody_txt">
                            Please note, some information cannot be changed automatically via flu360 online. If you need to change any of the below details, submit the form to <a href="/contact-us"><span class="mysupporttxt"><u>flu360 | Support</u></span>.</a>Only complete the fields you want to change.</p>
                        
                        <div class="row margin-T20">
                            <div class="col-md-6 col-sm-6 col-xs-12">
                                <label class="myprofile_editbusinessinfo_txt">Company Details</label>
        
                            </div>
                        </div>
                        <div class="row margin-T10">
                            <div class="col-md-6 col-sm-6 col-xs-12">
                                <label class="myprofile_editcompany_txt editnameuk" for="company-name">Current Organisation Name</label>
                                <div>
                                    <label class="myprofile_editmodalbusinessinfo_txt editnameuk">{{accountdata?.spCompanyName}}</label>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6 col-xs-12 has-feedback has-success">
                                <label class="myprofile_editcompany_txt editnameuk" for="reg_company">New Organisation Name</label> <input class="myprofile_input form-control" id="reg_company" name="orgName" modified-id="reg_company_updated" aria-invalid="false" formControlName="orgName" (keyup)="requestcheck()">
                                <span class="glyphicon glyphicon-exclamation-sign twofiled" *ngIf="getFormControl('orgName').dirty && getFormControl('orgName').hasError('pattern') ">
                        
                                </span>
                            
                                <ng-container *ngIf="getFormControl('orgName').dirty && getFormControl('orgName').hasError('pattern')">
                                    <!-- <span class="glyphicon form-control-feedback glyphicon-exclamation-sign"></span> -->
                                    <label *ngIf="getFormControl('orgName').hasError('pattern')" id="myprofile_Address1-error" class="error help-block" for="myprofile_Address1">Please enter only letters</label>
                                </ng-container>
                            </div>
                        </div>
                        <div class="row margin-T10">
                            <div class="col-md-6 col-sm-6 col-xs-12">
                                <label class="myprofile_editcompany_txt editnameuk" for="cur_org_type">Current Organisation Type</label>
                                <div>
                                    <label class="myprofile_editmodalbusinessinfo_txt editnameuk">{{accountdata?.businessType}}</label>
                                        
                                
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6 col-xs-12 has-success">
                                <label class="myprofile_editcompany_txt editnameuk" for="reg_org">New Organisation Type</label> <select class="myprofile_input form-control" id="reg_org" name="orgAddressForm.groupOrganization" modified-id="reg_org_updated" aria-invalid="false" formControlName="orgtype"  (change)="requestcheck()">
                                    <option value="" selected="selected">Select</option>
                                    <option name="businessType" value="GENERAL_PRACTICE">GP Medical Practice</option>
                                    <option name="businessType" value="PHARMACY">Pharmacy</option>
                                    <option name="businessType" value="HOSPITAL_PUBLIC">Hospital - Public</option>
                                    <option name="businessType" value="HOSPITAL_PRIVATE">Hospital - Private</option>
                                    <option name="businessType" value="NURSING_HOMES">Nursing Homes</option>
                                    <option name="businessType" value="CORRECTIONAL_FACILITY">Correctional Facility</option>
                                    <option name="businessType" value="WHOLESALER">Wholesaler</option>
                                </select>
        
        
                            </div>
                        </div>
                        <div class="row margin-T10">
                            <div class="col-md-6 col-sm-6 col-xs-12">
                                <label class="myprofile_editcompany_txt editnameuk" for="cur_nhscode">NHS Code</label>
                                <div>
                                    <label class="myprofile_editmodalbusinessinfo_txt editnameuk"></label>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6 col-xs-12 feedback has-feedback has-success">
                                <label class="myprofile_editcompany_txt editnameuk" for="reg_nhscode">New NHS Code</label> <input class="myprofile_input form-control" [attr.maxlength]=nhsmaxlength id="reg_nhscode" [ngClass]="nhsvalidationgp || nhsvalidationpharma || nhsvalidationall?'highlighbox':''" name="orgAddressForm.nhsCode" modified-id="reg_nhscode_updated" aria-invalid="false" (keyup)="nhsvalidation($event);requestcheck()" formControlName="nhscode"  >
                                <span class="glyphicon glyphicon-exclamation-sign twofiled" *ngIf= "nhsvalidationgp || nhsvalidationpharma || nhsvalidationall">
                        
                                </span>
                            
                                <ng-container *ngIf= "nhsvalidationgp || nhsvalidationpharma || nhsvalidationall">
                                    <!-- <span class="glyphicon form-control-feedback glyphicon-exclamation-sign"></span> -->
            
                                    <label *ngIf="nhsvalidationgp" id="myprofile_Address1-error" class="error help-block" for="myprofile_Address1">Please provide a valid NHS code e.g. Y02906</label>
                                    <label *ngIf="nhsvalidationpharma" id="myprofile_Address1-error" class="error help-block" for="myprofile_Address1">Please provide a valid NHS code e.g. F1J4D</label>
                                    <label *ngIf="nhsvalidationall" id="myprofile_Address1-error" class="error help-block" for="myprofile_Address1">Please provide a valid NHS code</label>

                                </ng-container>
                            </div>
                        </div>
                        <div class="row margin-T10">
                            <div class="col-md-6 col-sm-6 col-xs-12 ">
                                <label class="myprofile_editcompany_txt editnameuk" for="cur_vat">VAT #</label>
                                <div>
                                    <label class="myprofile_editmodalbusinessinfo_txt editnameuk"></label>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6 col-xs-12 has-feedback has-success">
                                <label class="myprofile_editcompany_txt editnameuk" for="reg_vat">New VAT #</label> <input class="myprofile_input form-control" id="reg_vat" name="orgAddressForm.vatNumber" formControlName="vatno"  (keyup)="requestcheck()">
                                <span class="glyphicon glyphicon-exclamation-sign twofiled" *ngIf="getFormControl('vatno').dirty && (getFormControl('vatno').hasError('maxlength') || getFormControl('vatno').hasError('pattern'))">
                        
                                </span>
                            
                                <ng-container *ngIf="getFormControl('vatno').dirty && (getFormControl('vatno').hasError('maxlength') || getFormControl('vatno').hasError('pattern'))">
                                    <!-- <span class="glyphicon form-control-feedback glyphicon-exclamation-sign"></span> -->
            
                                    <label *ngIf="getFormControl('vatno').hasError('maxlength')" id="myprofile_Address1-error" class="error help-block" for="myprofile_Address1">Please provide a valid Vat #</label>
                                    <label *ngIf="getFormControl('vatno').hasError('pattern')" id="myprofile_Address1-error" class="error help-block" for="myprofile_Address1">Please enter only Numbers</label>
                                </ng-container>
                                
                            </div>
                        </div>
                        <div class="row margin-T10">
                            <div class="col-md-6 col-sm-6 col-xs-12">
                                <label class="myprofile_editcompany_txt editnameuk" for="cur_regno">Company Registration #</label>
                                <div>
                                    <label class="myprofile_editmodalbusinessinfo_txt editnameuk"></label>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6 col-xs-12 has-feedback has-success">
                                <label class="myprofile_editcompany_txt editnameuk" for="reg_regno">New Company Registration #</label> <input class="myprofile_input form-control" maxlength="8" id="reg_regno" name="orgAddressForm.companyRegistration" aria-invalid="false" modified-id="reg_regno_updated" formControlName="companyRegistration"  (keyup)="requestcheck()">
                                <span class="glyphicon glyphicon-exclamation-sign twofiled" *ngIf="getFormControl('companyRegistration').dirty && (getFormControl('companyRegistration').hasError('maxlength') || getFormControl('companyRegistration').hasError('pattern') || getFormControl('companyRegistration').hasError('minlength'))">
                        
                                </span>
                            
                                <ng-container *ngIf="getFormControl('companyRegistration').dirty && (getFormControl('companyRegistration').hasError('maxlength') || getFormControl('companyRegistration').hasError('pattern') || getFormControl('companyRegistration').hasError('minlength') )">
                                    <!-- <span class="glyphicon form-control-feedback glyphicon-exclamation-sign"></span> -->
                                    <label *ngIf="getFormControl('companyRegistration').hasError('minlength')" id="myprofile_Address1-error" class="error help-block" for="myprofile_Address1">Should not be less than 7 character</label>

                                    <label *ngIf="getFormControl('companyRegistration').hasError('maxlength')" id="myprofile_Address1-error" class="error help-block" for="myprofile_Address1">Should not be more than 8 character</label>
                                    <label *ngIf="getFormControl('companyRegistration').hasError('pattern')" id="myprofile_Address1-error" class="error help-block" for="myprofile_Address1">Please enter only Numbers</label>
                                </ng-container>
                            </div>
                        </div>
        
        </div>
        <div class="modal-footer">
        <button type="button" (click)="requestsubmit()"class="btn btn-default myprofile_modalrequestbtn requestchangesuk" [ngClass]="requestbtn?'':'requestbtn_disable'"id="request_change" data-dismiss="modal">Request Changes</button>
        
        </div>
        </div>
        </div>
        </div>
    </form>
</div>
</div>
            </div>
     

</div>
</div>


<app-custom-manage-location class="ukmanagelocation"></app-custom-manage-location>
<div class="col-xs-12 col-sm-12 myaccountheader credits_invoiceheader" *ngIf="isSearchByProfile">My Account Network</div>
<div class="col-md-12 col-sm-12 col-xs-12 no-padding" *ngIf="isSearchByProfile">
    <div class="myprofile_content_account locationsuk">

        <div class="col-md-6 col-sm-12 col-xs-12 myprofile_locationheader allaccount">All Associated Accounts</div>
        <div class="col-md-6 col-sm-12 col-xs-12">
        <div class="joinaccountbox">
            <a [routerLink]="'/register/join-account'"><div class="myjoinaccountbtn">Link To Another Account</div></a>
        </div>
        </div>
        <div class="col-md-9 col-xs-12 accounttext">

        If you would like to unlink the account which you are currently logged into, you must first switch to another account in the menu at the top of the page. Please contact&nbsp;<a href="/contact-us" class="supportlink">flu360 | Support&nbsp;</a> with any questions.</div>
        <div class="col-md-12 col-sm-12 col-xs-12 myprofile_selectoptions">
            <div class="myprofile_Accountsearch">
                <i class="myprofile_global_searchicon"></i> <input type="search" #search placeholder="Search for locations" id="myprofile_account_searchbox" (keyup)="accountapplyFilter($event)">
                
            </div>
        </div>

        <div class="col-md-12 col-sm-12 col-xs-12 margin-T20">
            <div class="row myprofilelocation myprofileuklocation unlinkaccounttable">
               

                    <table  class="account-table" mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)">

                   

                    <ng-container matColumnDef="locID">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true" sortActionDescription="Sort by number">
                            Account Number
                        </th>
                        <td mat-cell *matCellDef="let element">{{element.locID}} </td>
                    </ng-container>

                    <ng-container matColumnDef="locname">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true" sortActionDescription="Sort by name">
                            Organisation Name
                        </th>
                        <td mat-cell *matCellDef="let element">{{element.locname}} </td>
                    </ng-container>
                    <ng-container matColumnDef="address">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true" sortActionDescription="Sort by name">
                            Address
                        </th>
                        <td mat-cell *matCellDef="let element">{{element.address}} </td>
                    </ng-container>

                    <ng-container matColumnDef="checked">
                        <th mat-header-cell *matHeaderCellDef  >
                           
                        </th>
                        <td mat-cell  class="unlinkColumn" *matCellDef="let element" [ngClass]="element.checked=='true'?'nonClickable':'Clickable'" ><span *ngIf="element.checked!='true'" data-toggle="modal" data-target="#Unlinkpopup" (click)="unlinkaccount(element.locID)" >Unlink</span><span *ngIf="element.checked=='true'">Unlink</span>  </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumnsuk;sticky: true"></tr>
                    
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsuk;">
                        
                    </tr>
                
                    </table>
                    <div *ngIf="ACCOUNTsearchResultEmpty" class="no-results-message">
                        No matching records found
                      </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="Unlinkpopup"  role="dialog" style="padding-right: 10px;" >
    <div class="modal-dialog">
        <div class="modal-content">
           
           
            <div class="modal-body">
                <h4 class="modal-title Unlink_popHeader col-xs-9 unlinkshow" *ngIf="unlinkmessage" >Are you sure you want to unlink this account?</h4>
                <h4 class="modal-title Unlink_popHeader col-xs-9 " *ngIf="thankshow" >Thank you!</h4>
                <p class="Unlink_popSubHeader col-xs-9  unlinkerrortext" *ngIf="errorshow">Sorry we are not able to unlink your account at this time. Please contact our customer support team.</p>
                <div class="col-xs-3">
                    <span class="Unlink_popClose unlinkerrorclose" data-dismiss="modal">×</span>
                </div>
                <p class="Unlink_popSubHeader col-xs-12 unlinkshow" *ngIf="unlinkmessage">You can relink it at a later point in time.</p>
                <p class="Unlink_popSubHeader col-xs-12 " *ngIf="thankshow">This account has been unlinked.</p>

                <div class="clearboth"></div>
                <div class="Unlink_PopBtnParent">
                    <button class="Unlinkclosebtn" data-dismiss="modal"  *ngIf="thankshow">Close</button>
                    <button class="Unlinkclosebtn" data-dismiss="modal"  *ngIf="errorshow">Close</button>

                    <button class="Unlink_popBackBtn unlinkshow" data-dismiss="modal"  *ngIf="unlinkmessage" >Cancel</button>
                    <button class="Unlink_popContBtn unlinkshow" (click)="unlinkcontine()"  *ngIf="unlinkmessage">Unlink account</button>
                     
                </div>
            </div>
</div>
</div>
</div>

<div class="modal fade" id="thanksModal" [ngClass]="thanksModal?'show':'hide'"  role="dialog" >
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title myprofile_modal_header" id="myModalLabel">Thank You!</h4>
                <button type="button" class="myprofile_modalclose" (click)="closemodal()" data-dismiss="modal" aria-hidden="true">×</button>
               
            </div>
            <div class="modal-body">
                <p class="myprofile_modalbody_txt">Your request has been submitted. Our team will review and verify your updated information, and may contact you for clarification, within 5 business days.</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default myprofile_modalclosebtn" (click)="closemodal()" data-dismiss="modal">Close</button>

            </div>
        </div>
    </div>
</div>
</div>




