<!-- <p>flucelvax-impactchart works!</p> -->
<div class="col-md-12 breadcrumbs--page-nav hidden-xs hidden-sm">
    <div class="container">
        <p><a href="/">Home</a><span> &gt; </span><a href="/products">Products</a><span> &gt; </span><strong>The
                Cell-Based Solution</strong></p>
    </div>
</div>

<section class="col-lg-12 col-md-12 col-xs-12 chartsection"  *ngIf="referncenode | async as item">
    <div class="abovewidth">
        <!--Top Header-->
        <a #impactChartAnchor id="impactChartAnchor"></a>
        <div class="col-xs-12  no-padding abovechartheader">
            <div class="col-md-12 col-xs-12 chartheader" [innerHTML]="item.headline"></div>
        </div>
        <div class="col-md-12 col-xs-12 no-padding chartcontianer" id="impactchsrtsection">
            <!-- sub header-->
            <div class="col-xs-12 chartsubheader" id="chartsubheader">{{item.horizontal}}</div>
            <div id="legendContainer" class="col-xs-12 hidden-md hidden-lg ">
                <div class="moblielegend">
                    <div class="legenditem-1 customflex" id="legenditem-0">
                        <div class="legendsymbol"></div>
                        <div class="legendtext">Matches circulating strains</div>
                    </div>
                    <div class="legenditem-2 customflex" id="legenditem-1">
                        <div class="legendsymbol"></div>
                        <div class="legendtext">A/H3N2 antigenic drift</div>
                    </div>
                    <div class="legenditem-3 customflex" id="legenditem-2">
                        <div class="legendsymbol"></div>
                        <div class="legendtext">A/H3N2 egg adaptation</div>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 no-padding chartview">
                <!--chart container-->
                <!-- <div id="ordergraphcontainer" class="col-md-8 col-xs-12 col-sm-12 chartgraph no-padding" [chart]="columnChart"></div> -->
                <div id="ordergraphcontainer" class="col-md-8 col-xs-12 col-sm-12 chartgraph no-padding">
                    <highcharts-chart 
                      [Highcharts]="Highcharts"
                      [options]="chartOptions"
                      style="width: 100%; height: 400px; display: block;">
                    </highcharts-chart>
                  </div>  
                <!--side information-->
                <div class="col-md-4 col-xs-12 chartdescription no-padding">
                    <div class="col-xs-12 rightdescriptioncontainer no-padding">
                        <div class="rightlegend1" [style.display]="displayLegend1 ? 'block' : 'none'">
                            <div class="col-xs-12 addtiondesp1 customflex">
                                <div class="columnsymbol"></div>
                                <div class="columndeshead" [innerHTML]="item.green1">
                                </div>
                            </div>
                        </div>
                        <div class="rightlegend2" [style.display]="displayLegend2 ? 'block' : 'none'">
                            <div class="col-xs-12 addtiondesp2 customflex">
                                <div class="columnsymbol"></div>
                                <div class="columndeshead" [innerHTML]="item.grey1">
                                </div>
                            </div>
                        </div>
                        <div class="rightlegend3" [style.display]="displayLegend3 ? 'block' : 'none'">
                            <div class="col-xs-12 addtiondesp3 customflex">
                                <div class="columnsymbol"></div>
                                <div class="columndeshead" [innerHTML]="item.red1">
                                    <div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--below strain information-->
            <div id="legendContainer" class="col-md-12 hidden-xs hidden-sm">
                <div class="customlegend">
                    <div class="legenditem-1 customflex" id="legenditem-0">
                        <div class="legendsymbol"></div>
                        <div class="legendtext" [innerHTML]="item.green2"></div>
                    </div>
                    <div class="legenditem-2 customflex" id="legenditem-1">
                        <div class="legendsymbol"></div>
                        <div class="legendtext" [innerHTML]="item.grey2"></div>
                    </div>
                    <div class="legenditem-3 customflex" id="legenditem-2">
                        <div class="legendsymbol"></div>
                        <div class="legendtext" [innerHTML]="item.red2"></div>
                    </div>
                </div>
            </div>
            <!--below heading-->
            <div class="col-xs-12 chartbottomdes">
                <div class="chartdesbox" style="display: block;" [innerHTML]="item.bottomcontent">
                </div>
            </div>
        </div>
    </div>
</section>