import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { InvoiceFinancialCreditTableComponent } from './invoice-financial-credit-table/invoice-financial-credit-table.component';
import { AllInvoiceCreditComponent } from './all-invoice-credit/all-invoice-credit.component';

@NgModule({
  declarations: [
    InvoiceFinancialCreditTableComponent,
    AllInvoiceCreditComponent,
  ],
  imports: [
    CommonModule,
    MatTableModule,
    MatSortModule,
  ],
  exports: [
    InvoiceFinancialCreditTableComponent,
    AllInvoiceCreditComponent,
  ]
})
export class GlobalModule { }
