import { ChangeDetectorRef, Component } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { BaseSiteService, CmsBannerComponent, OccConfig } from '@spartacus/core';
import { Observable } from 'rxjs';
import { ContentService } from '../../../spartacus/services/content.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-homepage-solution',
  templateUrl: './homepage-solution.component.html',
  styleUrl: './homepage-solution.component.scss'
})
export class HomepageSolutionComponent {

  banners:any[]=[];
  h2content:string="";
  paracontent:string="";
  safeh2content:SafeHtml | undefined;
  show:boolean[]=[];
  currentSite:String='';
  node: Observable<any> = this.bannerComponent.data$;

  constructor(public component: CmsComponentData<any>,protected contentService:ContentService,private cdr:ChangeDetectorRef,private sanitizer:DomSanitizer,protected config: OccConfig,protected baseSiteService: BaseSiteService,public bannerComponent: CmsComponentData<CmsBannerComponent>) {
    this.show=Array(3).fill(false);
   
  }
  getMediaUrl(media:any):string{
    return this.config.backend?.occ?.baseUrl + media.url;
  }

  onClick(i:number){
    this.show[i]=!this.show[i];
  }

  ngOnInit(){

    this.baseSiteService.getActive().subscribe(site => {
      this.currentSite = site;
    
    });

    this.node.subscribe((res:any)=>{}
     
    )
    
    this.component.data$.subscribe((data) =>{
      this.h2content = data.h2content;
      this.paracontent = data.paragraphcontent;

      this.safeh2content = this.sanitizer.bypassSecurityTrustHtml(this.h2content);
    
      this.contentService.getMultipleComponentData(data.banners.split(' ')).subscribe((solData:any) => {
        this.banners=solData.component;
        this.cdr.detectChanges();
      });
      
    }
  );
}
  }



