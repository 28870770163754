export const environment = {
    production: true,
    CdcUskey:"4_j2N1gJh9trySoJWdfkER8w",
    CdcUkKey:"3_nIthv9QicJncMvJtyqbI0Ed-xb-q2NmeaYj6NwqIuiBzZQV3Qoc6HGSsJx5-t9x7",
    sapcustomerid:"",
    cdcCust:"",
    CookieUs:"5ea7c315-ff3b-4a89-918a-6b57d928f790",
    CookieUk:"62c80424-fd7b-4d36-a5f1-f1d19cb1a6b5",
    GtmUsId:"GTM-KPLCV8V",
    GtmUkId:"GTM-M53ZPN7",
    captchaSiteKey:"6Le5ABIqAAAAAOItDoX_txybsSy2boHR3EZNvMCV"
  };