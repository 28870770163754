<div *ngIf="rightisLoading" class="loading">
  <div class="loading_txt">
    <div class="load_txt">Loading</div>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>
</div>
<div
  class="col-lg-5 col-md-5 col-sm-12 open-order float_left main-invoice-container"
  *ngIf="this.firstvalue != 'N/A'"
>
  
  <div class="form-group invoice_datasearch">
    <div
      class="col-md-10 col-lg-10 col-sm-10 no-padding for_tab_view float_left"
    >
      <div class="input-group seach_order">
        <div class="input-group-addon">
          <i class="fa fa-search"></i>
        </div>
        <input
          (keyup)="inputsearch($event);applyFilter()"
          class="form-control"
          id="open_order_search"
          name="lookup"
          type="text"
        />
      </div>
    </div>
    <div
      class="col-md-1 col-1 filterbox float_left"
      (click)="filteropen()"
      style="background-image: url('../assets/images/filter.png')"
    >
      <!-- <span class="select_counter">{{count}}</span> -->
    </div>
    <div
      class="col-md-1 col-2 sortbox float_left"
      (click)="sortopen()"
      style="background-image: url('../assets/images/sort.png')"
      [ngClass]="togglesortView ? 'open' : ''"
    ></div>

    <div class="filteropen" [ngClass]="togglefilterView ? 'open' : ''">
      <div class="statussection">
        <div class="filterheader">BY STATUS</div>
        <div class="row">
          <div class="col-md-12" *ngFor="let list of getstatus; let i = index">
            <label>
              <input
                type="checkbox"
                class="registration_checkbox"
                name="status"
                value="{{ list.list }}"
                (click)="incrementcountstatus($event, i); applyFilter()"
                [(ngModel)]="list.isChecked"
              />
              <span class="checkbox-button__control"></span
              >{{ list.list }}</label
            >
          </div>
        </div>
      </div>
      <div
        class="invoice_reset"
        id="invoice_resetfilter"
        (click)="resetFilter()"
      >
        RESET
      </div>
    </div>

    <div class="sortopen" [ngClass]="togglesortView ? 'open' : ''">
      <div class="row">
        <div class="col-md-6">
          <div class="filterheader">INVOICE NUMBER</div>
          <label>
            <input
              type="radio"
              class="registration_radio"
              name="radiobtn"
              [(ngModel)]="selectedSort"
              value="1to9"
              (click)="sortTest('1to9')"
            />
            <span class="radio-button__control"></span>1 TO 9</label
          >
          <label>
            <input
              type="radio"
              class="registration_radio"
              name="radiobtn"
              [(ngModel)]="selectedSort"
              value="9to1"
              (click)="sortTest('9to1')"
            />
            <span class="radio-button__control"></span>9 TO 1</label
          >
        </div>
        <div class="col-md-6">
          <div class="filterheader">LOCATION</div>
          <label>
            <input
              type="radio"
              class="registration_radio"
              name="radiobtn"
              value="AtoZ"
              [(ngModel)]="selectedSort"
              (click)="sortTest('AtoZ')"
            />
            <span class="radio-button__control"></span>A TO Z</label
          >
          <label>
            <input
              type="radio"
              class="registration_radio"
              name="radiobtn"
              value="ZtoA"
              [(ngModel)]="selectedSort"
              (click)="sortTest('ZtoA')"
            />
            <span class="radio-button__control"></span>Z TO A</label
          >
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-md-6">
          <div class="filterheader">DATE ISSUED</div>
          <label>
            <input
              type="radio"
              class="registration_radio"
              name="radiobtn"
              [(ngModel)]="selectedSort"
              value="MostRecent"
              (click)="sortTest('MostRecent')"
            />
            <span class="radio-button__control"></span>MOST RECENT</label
          >
          <label>
            <input
              type="radio"
              class="registration_radio"
              name="radiobtn"
              [(ngModel)]="selectedSort"
              value="LeastRecent"
              (click)="sortTest('LeastRecent')"
            />
            <span class="radio-button__control"></span>LEAST RECENT</label
          >
        </div>

      </div>
      <div
      class="invoice_reset_sort"
      id="invoice_resetSort"
      (click)="resetSort()"
    >
      RESET
    </div>
    </div>
  </div>

  <div class="clearfix"></div>

  <hr />
  <div *ngIf="isLoading">loading</div>
  <div *ngIf="!isLoading">
    <div class="mat-elevation-z8" id="orderfiltertable">
      <mat-form-field class="mattablesearch">
        <mat-label>Filter</mat-label>
        <input
          matInput
          (keyup)="inputsearch($event); applyFilter()"
          placeholder="Search, filter, or sort your orders"
        />
      </mat-form-field>
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        class="mat-elevation-z8"
      >
        <ng-container matColumnDef="combined">
          <th class="odertableheader" mat-header-cell *matHeaderCellDef>
            order Data
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            (click)="openrightsectio(element.invoiceNumber, element.status)"
          >
            <div class="tablecolum_order1">
              <div class="order_id">Invoice #{{ element.invoiceNumber }}</div>
              <div class="order_unit">
                <span>Unit Qty Total :</span
                ><span
                  class="totalorderquantity_Order"
                  [className]="element.invoiceNumber"
                  >{{ element.invoiceQuantity }}</span
                >
              </div>
              <div class="order_location">
                <span>Shipping Location:</span
                ><span [innerHtml]="element.location"></span>
              </div>
            </div>

            <div class="tablecolumn_order2">

              <div class="invoice_status {{ getElementStatus(element.status) | lowercase }}">
                {{ element.status }}
              </div>
            </div>
          </td>
        </ng-container>

        <!-- Header and Row Declarations -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          id="{{ row.invoiceNumber }}"
          *matRowDef="let row; columns: displayedColumns"
        ></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell no-matching-invoices">
            No matching records found
          </td>
        </tr>
      </table>
      <!--        <mat-paginator #paginator  [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
 -->
      <div class="custom-paginator" *ngIf="dataSource?.filteredData?.length > 0" >
        <button
          class="pagination-buttonOrg"
          (click)="goToFirstPage(); scrollToTop()"
          [disabled]="isFirstPage()"
          id="first-page"
        >
          <img
            alt="Navigate to previous page"
            src="assets/images/pagination-double-prev-arrow-icon.svg"
            class="locations_arrows"
          />
        </button>
        <button
          class="pagination-buttonOrg"
          (click)="previousPage(); scrollToTop()"
          [disabled]="isFirstPage()"
          id="previous-page"
        >
          <img
            alt="Navigate to previous page"
            src="assets/images/pagination-prev-btn.svg"
            class="locations_arrows"
          />
        </button>
        <button
          class="pagination-buttonOrg"
          *ngFor="let pge of getPages()"
          [class.active-page]="isCurrentPage(pge)"
          (click)="goToPage(pge); scrollToTop()"
        >
          {{ pge }}
        </button>
        <button
          class="pagination-buttonOrg"
          (click)="nextPage(); scrollToTop()"
          [disabled]="isLastPage()"
          id="next-page"
        >
          <img
            alt="Navigate to next page"
            src="assets/images/pagination-next-btn.svg"
            class="locations_arrows"
          />
        </button>
        <button
          class="pagination-buttonOrg"
          (click)="goToLastPage(); scrollToTop()"
          [disabled]="isLastPage()"
          id="last-page"
        >
          <img
            alt="Navigate to last page"
            src="assets/images/pagination-double-next-arrow-icon.svg"
            class="locations_arrows"
          />
        </button>
      </div>
    </div>
  </div>
</div>

<!-- right section for my orders -->

<div
  class="col-lg-7 col-md-7 col-span-12 right-order order-details-view float_left right-invoice-container"
  *ngIf="this.firstvalue != 'N/A'"
>
  <ng-container *ngIf="rightsectionresponse">
    <div
      class="order_details_section col-md-12"
      id="{{ rightsectionresponse.invoiceNumber }}"
    >
      <div class="row invoice_topHeader">
        <div>
          <div class="invoice_no">
            Invoice Number: #<span class="invoice_fetch_id">{{
              rightsectionresponse.invoiceNumber
            }}</span>
            <div class="invoice_download" (click)="downloadInvoice(rightsectionresponse.invoiceNumber)">
                <span>Download Invoice</span>
                <img
                  alt="Download"
                  class="invoice_download-svg"
                  src="assets/images/Group.svg"
                  width="15"
                  
                />
              </div>
          </div>
        </div>
        <div>
          <!-- <div class="invoice_download invoice_download-US">
            <img
              alt="Download"
              class="invoice_download-svg"
              src="assets/images/icon-download-invoice.svg"
              width="15"
              (click)="downloadInvoice(rightsectionresponse.invoiceNumber)"
            />
          </div> -->
        </div>
        <div
          class="invoice_paybutton newClass"
          *ngIf="rightsectionresponse.invoiceStatus?.toLowerCase() !== 'paid'"
        >
          <div
            *ngIf="rightsectionresponse.showPaymentProccessingBanner == false && rightsectionresponse.showSchedulePaymentBanner == false"
          >
            <div
              class="invoice_pay invoice_paycreditcard"
              (click)="ShowCredit()"
              [ngClass]="
                currentDivCredit ||
                showCardSection ||
                showSuccessOrError ||
                iframredirect
                  ? 'open'
                  : ''
              "
            >
              Pay by Credit Card / Use Credits
            </div>
            <div
              class="invoice_pay invoice_payACH"
              (click)="ShowAch()"
              [ngClass]="currentDivAch ? 'open' : ''"
            >
              Pay by ACH
            </div>
          </div>
          <div class="clearfix"></div>
          <!-- <div
            class="moveRight"
            *ngIf="rightsectionresponse.showPaymentProccessingBanner == true || rightsectionresponse.showSchedulePaymentBanner == true "
          >
            <div
              class="invoice_pay invoice_payACH"
              (click)="ShowAch()"
              [ngClass]="currentDivAch ? 'open' : ''"
            >
              Pay by ACH
            </div>
          </div> -->
          <div class="clearfix"></div>
          <!--pay by credit card 1-->
          <div
            class="invoice_paypopup invoice_payCareditpopup"
            *ngIf="currentDivCredit && rightsectionresponse.total < 150000"
          >
            <form id="pay_creditcard" novalidate="novalidate">
              <div class="order_panel_header">
                <div class="row">
                  <div class="grey_sec col-xs-12">
                    <div class="col-md-3 col-xs-4">
                      <div class="order_level">invoice total</div>

                      <div class="fetch_data">
                        ${{ rightsectionresponse.total | number : "1.2-2" }}
                      </div>
                    </div>
                    <div class="col-md-5 col-xs-8">
                      <div class="order_level">
                        amount due (if paid by
                        {{ rightsectionresponse.paymentDueDate }})
                      </div>
                      <div class="fetch_data fetch_data_actual">
                        ${{ rightsectionresponse.amountDue | number : "1.2-2" }}
                      </div>
                    </div>
                    <div
                      class="col-md-4 col-xs-7 hidden-xs hidden-sm visible-md-* visible-lg-*"
                    >
                      <div class="order_level">po number</div>
                      <div class="cardpoinput no-pad" id="poCrditInputBox">
                        <input
                          type="text"
                          name="card_po"
                          class="form-control invoice_edit_fld"
                          [ngClass]="hasCardPoInputError? 'error-input' : ''"
                          id="card_po"
                          autocomplete="no"
                          [(ngModel)]="cardPOInputValue"
                          (ngModelChange)="handleOnInputValueChange($event)"
                        />
                        <span class="glyphicon form-control-feedback glyphicon-exclamation-sign"  *ngIf="hasCardPoInputError"></span>
                      </div>
                    </div>
                    <div class="col-md-8 col-xs-12 margin-T10">
                      <!-- save credit card changes -->
                      <div class="order_level">Total</div>
                      <div class="fetch_data_total">
                        $<span>{{
                          rightsectionresponse.amountDue | number : "1.2-2"
                        }}</span
                        ><span
                          *ngIf="rightsectionresponse.discountApplicable"
                          class="static_data"
                          >&nbsp;({{
                            rightsectionresponse.payDiscount | number
                          }}% Prompt Pay Discount)</span
                        >
                      </div>
                      <div
                        class="invoice_creditnote-list"
                        *ngIf="substractCredit"
                      >
                        <div
                          class="invoice_insidelist"
                          *ngFor="let item of creditOnToal; trackBy: trackByCreditNum; let i = index"
                          (click)="makeRemovetoggle()"
                        >
                          <div
                            class="invoice_creditnote-item"
                            id="invoice_creditnote-{{
                              rightsectionresponse.invoiceNumber
                            }}"
                          >
                            -
                            <span class="invoice_creditamountApplied"
                              >${{ item.creditAmount | number : "1.2-2" }}</span
                            >
                            Credit Note #
                            <span class="invoice_creditID">{{
                              item.creditNoteNumber
                            }}</span>
                            <span
                              (click)="
                                creditApplied(
                                  $event,
                                  item.creditAmount,
                                  'remove',
                                  item.creditNoteNumber,
                                  item.id
                                )
                              "
                              class="invoice-creditremoveButton"
                              >REMOVE
                              <i
                                class="glyphicon glyphicon-minus invoiceCreditIcon"
                              ></i
                            ></span>
                          </div>
                        </div>
                        <hr class="lineClass" />
                        <div
                          class="invoice_creditnote-sum"
                          [ngClass]="getFinalCreditAmmount() < 0 ? 'error' : ''"
                        >
                          <span
                            class="invoice_creditnote-sumAmt"
                            *ngIf="isNegative == true"
                          >
                            - ${{
                              getFinalCreditAmmount() * -1 | number : "1.2-2"
                            }}
                          </span>
                          <span
                            class="invoice_creditnote-sumAmt"
                            *ngIf="isNegative == false"
                          >
                            ${{ getFinalCreditAmmount() | number : "1.2-2" }}
                          </span>
                          &nbsp;Credit Card Payment<span
                            class="invoice_creditnote-sumError"
                          >
                            <img
                              src="assets/images/exclamation-mark.png"
                              height="20"
                              *ngIf="getFinalCreditAmmount() < 0"
                              matTooltip="For assistance applying credits to this invoice please contact the flu360 Customer Service team at (855)-358-8966. Our hours of operation are Monday through Friday, 9:00 AM to 6:30 PM EDT/EST."
                            />
                            <!-- <span class="invoice_creditnote-tooltip">For assistance applying credits to this invoice please contact the flu360 Customer Service team at (855)-358-8966. Our hours of operation are Monday through Friday, 9:00 AM to 6:30 PM EDT/EST.</span> -->
                          </span>
                        </div>
                        <div *ngIf="getFinalCreditAmmount() < 0">
                          <label class="invoice_errorLabel"
                            >Your applied credits exceed the total amount due on
                            the invoice.</label
                          >
                        </div>
                      </div>
                      <!------apply credit changes end------>
                    </div>
                    <div class="col-md-4 col-xs-12">
                      <!------ apply credit mobile design ------>
                      <div class="order_level po-error-message" *ngIf="hasCardPoInputError">Please enter only Numbers and Letters</div>
                      <div class="static_data hidden-xs hidden-sm">
                        <!-- apply credit mobile design -->
                        {{ payMessage.paymentLimitMessage }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div
                    (click)="hideCredit()"
                    class="col-md-6 col-xs-6 padding-L15"
                  >
                    <span class="cancel_btn_edit">Cancel</span>
                  </div>
                  <!-- save credit card changes -->
                  <div
                    [ngClass]="disableNext ? 'disabled' : ''"
                    (click)="showCards()"
                    class="col-md-6 col-xs-6 padding-R15"
                  >
                    <span type="button" class="save_btn_edit">Next</span>
                  </div>
                  <!-- save credit card changes -->
                </div>
              </div>
            </form>
            <div
              class="invoice_applyCredit-parent"
              *ngIf="finalCreditList && finalCreditList?.length == 0"
            >
              <div class="invoice_applyCredit-header">Apply Credits</div>
              <div class="invoice_applyCredit-amount">
                Total available credits:&nbsp;<span class="zeroapplycredit"
                  >${{
                    rightsectionresponse.totalcreditamount | number : "1.0-0"
                  }}</span
                >
              </div>
              <!-- <div class="invoice_applyCredit-text availableCreditText hidden">The total amount of credits applied must not exceed the total amount due on the invoice.<br><br>It may take several business days for a credit to be removed from your Available Credits if the credit was applied to an invoice outside of flu360.com.</div> -->

              <div class="invoice_applyCredit-text noAvailableCredit">
                There are currently no available credits. Once credits become
                available, they will be listed.
              </div>
              <!-- to be shown if no credit available-->
              <!-- to be shown if credit available-->
            </div>
            <div
              class="invoice_applyCredit-parent"
              *ngIf="finalCreditList && finalCreditList?.length > 0"
            >
              <div class="invoice_applyCredit-header">Apply Credits</div>
              <div class="invoice_applyCredit-amount">
                Total available credits:&nbsp;<span
                  >${{
                    rightsectionresponse.totalcreditamount | number : "1.2-2"
                  }}</span
                >
              </div>
              <div class="invoice_applyCredit-text availableCreditText">
                The total amount of credits applied must not exceed the total
                amount due on the invoice.<br /><br />It may take several
                business days for a credit to be removed from your Available
                Credits if the credit was applied to an invoice outside of
                flu360.com.
              </div>
            </div>
            <div
              class="invoice_applycreditTable"
              *ngIf="finalCreditList && finalCreditList?.length > 0"
            >
              <!-- to be shown if credit available-->
              <!--
                                              <mat-form-field  class="full-width">

                                                <mat-label>seach,filter, or sort your orders</mat-label>
                                                <input
                                                  matInput
                                                  (keyup)="applyFilterNew($event)"

                                                  #input
                                                />
                                              </mat-form-field> -->
              <div
                id="invoice_applycreditTable-{{
                  rightsectionresponse.invoiceNumber
                }}_filter"
                class="dataTables_filter"
              >
                <label
                  ><i class="fa fa-search-credits"></i
                  ><input
                    type="text"
                    (keyup)="applyFilterNew($event)"
                    class=""
                    placeholder="Search, filter, or sort your orders"
                    aria-controls="invoice_applycreditTable"
                    autofocus
                  />
                </label>
              </div>

              <!-- <div class="input-group-table seach_order">

                                                <div class="input-group-addon-table">
                                                    <i class="fa fa-search"></i>
                                                </div>
                                                <input (keyup)="applyFilterNew($event)" class="form-control" id="open_order_search-table" name="lookup" type="text" >
                                            </div> -->
            <app-all-invoice-credit
              [invoices]="finalCreditList"
              (creditAppliedEvent)="creditAppliedEvent($event)"
            />
            </div>
          </div>
          <div
            class="invoice_paypopup invoice_AccountReceivable"
            *ngIf="rightsectionresponse.total >= 150000 && currentDivCredit"
          >
            <div
              class="invoice_AccountReceivable-text"
              [innerHTML]="payMessage.partialPaymentMessage"
            ></div>
            <button
              class="invoice_AccountReceivableClose"
              (click)="hideCredit()"
            >
              Close
            </button>
          </div>
          <div
            class="invoice_paypopup invoice_payACHpopup"
            *ngIf="currentDivAch"
            [innerHtml]="payMessage.achMessage"
          ></div>
          <div class="invoice_paypopup select-payment" *ngIf="showCardSection">
            <div class="select-payment_header">
              <!-- <div class="select-payment_header-text">
                Select payment method
              </div> -->
              <div class="select-payment_secure">
                <img alt="" width="11" src="assets/images/lock-key.png" />Secure
                payment
              </div>
            </div>
            <!-- Schedule payment changes -->
            <ng-container *ngIf="isValidForSchedule(rightsectionresponse.paymentDueDate)">
                <div class="schpay_radiocontainer"><label class="schpay_radio"><input type="radio" id="schpay_radiobtn1" value="option1" (change)="clearDate()" [(ngModel)]="selectedOption" class="schpay_radiobtn"><span class="schpay_radiobtncontrol"></span><label  for="schpay_radiobtn1"[ngClass]="selectedOption == 'option1'?'':'schpay_text'">Pay Now</label></label></div>
                <div class="schpay_radiocontainer"><label class="schpay_radio"><input type="radio" id="schpay_radiobtn2" value="option2" [(ngModel)]="selectedOption" class="schpay_radiobtn"><span class="schpay_radiobtncontrol"></span><label for="schpay_radiobtn2" class="schpay_radio2" [ngClass]="selectedOption == 'option2'?'':'schpay_text'">Schedule Payment</label></label>
                <i class="fa fa-question-circle-o sch-payinfo-tooltip">
                    <span class="sch-payinfo-tooltiptext">You must choose a date before your invoice due date.</span>
                </i>
            </div>
                <form #dateForm="ngForm" class="datepicker_form">
                    <mat-form-field class="mat-focused">
                        <input  (keypress)="allowNumbersOnly($event)" required matInput maxlength="10" [matDatepicker]="picker" [disabled]="selectedOption == 'option1'" name="schdate" (input)="maskInputDate($event)" [(ngModel)]="dateInput" placeholder="MM/DD/YYYY" [min]="today" [max]="getFormattedDueDate(rightsectionresponse.paymentDueDate)"  #date="ngModel" [ngClass]="(selectedOption == 'option2' && !dateInput && !isFieldFocused(date) && (date?.touched || isSchedulePay)) || (date?.invalid && date?.dirty && !isFieldFocused(date)) ? 'invalid-date':''">
                        <mat-datepicker-toggle matIconPrefix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker startView="month"></mat-datepicker>
                        <span *ngIf="(selectedOption == 'option2' && !dateInput && !isFieldFocused(date) && (date?.touched || isSchedulePay)) || (date?.invalid && date?.dirty && !isFieldFocused(date))" class="glyphicon glyphicon-exclamation-sign"></span>
                    </mat-form-field>
                    
                    <div style="width: 100%;margin-top:-17px;"><span style="color: #5D6F7B; font-size: 10px; font-family: Campton-book; font-weight: 400; line-height: 15px; word-wrap: break-word">Pay on or before </span><span style="color: #5D6F7B; font-size: 10px; font-family: Campton-bold; font-weight: 700; word-wrap: break-word">{{rightsectionresponse.paymentDueDate}} </span><span style="color: #5D6F7B; font-size: 10px; font-family: Campton-book; font-weight: 400; line-height: 15px; word-wrap: break-word">for the 2% Prompt Pay Discount</span></div>
                    <div *ngIf="selectedOption == 'option2' && !dateInput && !isFieldFocused(date) && (date?.touched || isSchedulePay)" class="sch-pay-error-text">
                      <p>This field is required</p>
                    </div>
                    <div *ngIf="dateInput && date?.invalid && date?.dirty && !isFieldFocused(date)" class="sch-pay-error-text">
                      <p *ngIf="date?.errors">You must select a future date on or before {{rightsectionresponse.paymentDueDate}}</p>
                    </div>
                </form>
            </ng-container>
            <div class="select-payment_savedCards">
              <div class="select-payment_savedCardsHeader newSavedCard">
                Saved credit cards
              </div>
              <div
                *ngIf="savedCardsData && savedCardsData.length > 0"
                id="select-payment_savedCards-table_wrapper"
                class="dataTables_wrapper no-footer"
              >
                <div *ngFor="let item of savedCardsData">
                  <table
                    class="select-payment_savedCards-table dataTable no-footer"
                    id="select-payment_savedCards-table"
                    role="grid"
                  >
                    <thead>
                      <tr role="row">
                        <th
                          class="sorting_disabled"
                          rowspan="1"
                          colspan="1"
                        ></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr role="row" class="odd">
                        <td>
                          <div
                            class="select-payment-parent"
                            [ngClass]="{disabledSelectedPayment: isCardExpired(item.expiryDate)}"
                            id="item.cardNumber"
                            (click)="savedCardClicked(item)"
                          >
                            <img
                              class="select-payment-cardImage"
                              alt=""
                              width="39"
                              src="{{ item.cardType == '128' ?'../../assets/images/Discover_icon.png':cardImages[item.cardType - 1] }}"
                            />
                            <div class="select-payment-cardno">
                              {{ formatCardValue(item.cardNumber) }}
                              <div class="select-payment-expiryDate"
                                [ngClass]="getExpiryClass(item.expiryDate)"
                              >
                              <ng-container *ngIf="item.expiryTime == 'past'">Expired {{ item.expiryDate }}&nbsp;</ng-container>
                              <ng-container *ngIf="item.expiryTime != 'past'">Expires {{ item.expiryDate }}&nbsp;</ng-container>
                                <img *ngIf="getExpiryClass(item.expiryDate) === 'expired_card'" class="icon expiry-image"
                      src="../../assets/images/exclamation-mark.png">
                              </div>
                            </div>
                            <div class="select-payment-arrow">
                              <i class="fa fa-chevron-right"></i>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                class="select-payment_nosavedCards"
                *ngIf="savedCardsData && savedCardsData.length == 0"
              >
                You don't have any saved credit cards. You can save a credit
                card during the payment process or in My Profile.
              </div>
            </div>

            <div class="select-payment_savedCardsHeader newCreditClass">
              New credit cards<i
                class="fa fa-question-circle-o payment-info-tooltip"
                matTooltip="New credit cards or debit cards may be subject to a temporary authorization hold from your bank. No action will be needed from you - holds typically disappear in 1-3 business days."
              >
              </i>
            </div>

            <div class="select-payment_addCards" (click)="iframClicked()">
              <div class="select-paymentAdd-parent">
                <img
                  class="select-paymentAdd-cardImage"
                  alt=""
                  width="39"
                  src="assets/images/amex_icon.png"
                />
                <div class="select-paymentAdd-cardName">American Express</div>
                <div class="select-paymentAdd-arrow">
                  <i class="fa fa-chevron-right"></i>
                </div>
              </div>
              <div class="select-paymentAdd-parent">
                <img
                  class="select-paymentAdd-cardImage"
                  alt=""
                  width="39"
                  src="assets/images/Visa_icon.png"
                />
                <div class="select-paymentAdd-cardName">Visa</div>
                <div class="select-paymentAdd-arrow">
                  <i class="fa fa-chevron-right"></i>
                </div>
              </div>
              <div class="select-paymentAdd-parent">
                <img
                  class="select-paymentAdd-cardImage"
                  alt=""
                  width="39"
                  src="assets/images/master_icon.png"
                />
                <div class="select-paymentAdd-cardName">MasterCard</div>
                <div class="select-paymentAdd-arrow">
                  <i class="fa fa-chevron-right"></i>
                </div>
              </div>
              <div class="select-paymentAdd-parent">
                <img
                  class="select-paymentAdd-cardImage"
                  alt=""
                  width="39"
                  src="assets/images/Discover_icon.png"
                />
                <div class="select-paymentAdd-cardName">Discover</div>
                <div class="select-paymentAdd-arrow">
                  <i class="fa fa-chevron-right"></i>
                </div>
              </div>
            </div>
          </div>
          <div
            class="invoice_paypopup paymentfailed mobpaddingpayment"
            *ngIf="showSuccessOrError"
          >
            <div
              class="order_panel_header successcontainer"
              *ngIf="errorCode == 3"
            >
              <div class="row">
                <div class="col-md-12">
                  <div class="title_data">{{ payErrorMsg3.errorHeadline }}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="msg_data">
                    {{ payErrorMsg3.h2content }}
                  </div>
                  <div class="msg_data paymentfailtext">
                    {{ payErrorMsg3.content }}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="failerbtncont col-xs-12" id="failurebtncont">
                  <div
                    class="failerretry mobretry"
                    id="retry"
                    (click)="retryClicked()"
                  >
                    Retry or Change Method
                  </div>
                  <div
                    class="failercancelbtn"
                    id="cancel"
                    (click)="cancelTransactionClicked()"
                  >
                    Cancel Transaction
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="invoice_paypopup paymentfailed mobpaddingpayment"
            *ngIf="showSuccessOrError && errorCode == 2"
          >
            <div class="order_panel_header successcontainer">
              <div class="row">
                <div class="col-md-12">
                  <div class="title_data">{{ payErrorMsg2.errorHeadline }}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="msg_data">
                    {{ payErrorMsg2.h2content }}
                  </div>
                  <div class="msg_data paymentfailtext">
                    {{ payErrorMsg2.content }}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="failerbtncont col-xs-12" id="failurebtncont">
                  <div
                    class="failerretry mobretry"
                    id="retry"
                    (click)="retryClicked()"
                  >
                    Retry or Change Method
                  </div>
                  <div
                    class="failercancelbtn"
                    id="cancel"
                    (click)="cancelTransactionClicked()"
                  >
                    Cancel Transaction
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="invoice_paypopup paymentfailed mobpaddingpayment"
            *ngIf="showSuccessOrError && errorCode == 1"
          >
            <div class="order_panel_header successcontainer">
              <div class="row">
                <div class="col-md-12">
                  <div class="title_data">{{ payErrorMsg1.errorHeadline }}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="msg_data">
                    {{ payErrorMsg1.h2content }}
                  </div>
                  <div class="msg_data paymentfailtext">
                    {{ payErrorMsg1.content }}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="failerbtncont col-xs-12" id="failurebtncont">
                  <div
                    class="failerretry mobretry"
                    id="retry"
                    (click)="retryClicked()"
                  >
                    Retry or Change Method
                  </div>
                  <div
                    class="failercancelbtn"
                    id="cancel"
                    (click)="cancelTransactionClicked()"
                  >
                    Cancel Transaction
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="invoice_paypopup paymentfailed mobsuccesscontainer" [ngClass]="schedulePaySavedCardDetail?.cardNumber?'schpay-successcontainer':''"
            *ngIf="showSuccessOrError && errorCode == 200"
          >
            <div class="order_panel_header successcontainer">
              <div class="row">
                <div class="col-md-12">
                  <div *ngIf="!schedulePaySavedCardDetail?.cardNumber;else schedulePayTitle" class="title_data">Thank You for Submitting Your Payment</div>
				          <ng-template #schedulePayTitle>
					            <div class="title_data">Thank you for scheduling your payment</div>
				          </ng-template>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="msg_data">
                    You will receive an email shortly confirming your payment
                    details. If you do not receive an email or if you require
                    support, please contact <ng-container *ngIf="!schedulePaySavedCardDetail?.cardNumber">Customer Service at &nbsp;<a href="mailto:customerservice.us@seqirus.com" target="_blank">{{emailId}}</a>.</ng-container><ng-container *ngIf="schedulePaySavedCardDetail?.cardNumber"><a routerLink="/contact-us">Customer Service</a>.</ng-container>
                  </div>
                </div>
              </div>
              <div *ngIf="!schedulePaySavedCardDetail?.cardNumber;else schedulePayBanner" class="row">
                <div  class="col-md-6 col-xs-10 marginClass">
                  <div class="Transactiontext">
                    TRANSACTION ID
                    <div class="tractionid">
                      0302/{{ rightsectionresponse.invoiceNumber }}
                    </div>
                  </div>
                </div>
              </div>
                <ng-template #schedulePayBanner>
                  <div class="new_schedule_pay_banner">
                  <div class="marginClass">
                    <div class="Transactiontext">DATE<div class="tractionid">{{schedulePaySavedCardDetail.currentDate}}</div>
                    </div>
                  </div>
                  <div class=" marginClass">
                    <div class="Transactiontext second_buttontext">Card
                      <div class="tractionid">
                        <div style="width: 100%; height: 100%; justify-content: flex-start; align-items: center; gap: 7px; display: inline-flex">
                          <div style="width: 38px; border-radius: 2px; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 10px; display: inline-flex">
                            <img width="38" class="image" *ngIf="schedulePaySavedCardDetail.cardType == '1'" src="../../assets/images/Visa_icon.png"
                            alt="Visa">
                            <img width="38" class="image" *ngIf="schedulePaySavedCardDetail.cardType == '2'" src="../../assets/images/amex_icon.png"
                            alt="American Express">
                            <img width="38" class="image" *ngIf="schedulePaySavedCardDetail.cardType == '3'" src="../../assets/images/master_icon.png"
                            alt="MasterCard">
                            <img width="38" class="image" *ngIf="schedulePaySavedCardDetail.cardType == '128'" src="../../assets/images/Discover_icon.png"
                            alt="Discover">
                          </div>
                          <div style="text-align: center; color: #2A3237; font-size: 14px; font-family: Campton; font-weight: 600; line-height: 16px; word-wrap: break-word">
                            **{{schedulePaySavedCardDetail.cardNumber.slice(-4)}}
                           </div>
                      </div>
                      
                      </div>
                    </div>
                  </div>
                </div>
                </ng-template>
              
              <div *ngIf="!schedulePaySavedCardDetail?.cardNumber;else schedulePayButtonSection" class="row">
                <div class="col-md-6 col-xs-6 close_container">
                  <a href="/financial-dashboard/all-invoices" target="_parent" class="close_btn_thankyou mobclose_btn">Close</a>
                </div>
              </div>
              <ng-template #schedulePayButtonSection>
                <div class="new_schedule_pay_buttons">
                <div class="close_container">
                  <a href="/scheduled-payments" target="_parent" class="close_btn_thankyou view_sch_pay mobclose_btn">View All Schedule Payments</a>
                </div>
                <div class="close_container">
                  <a href="/financial-dashboard/all-invoices" target="_parent" class="close_btn_thankyou mobclose_btn">Close</a>
                </div>
                </div>
              </ng-template>
            </div>
          </div>
          <!-- this.iframredirect  -->
          <div class="invoice_paypopup" id="iframePopup" *ngIf="iframredirect">
            <iframe
              width="100%"
              height="400"
              frameborder="0"
              [src]="cleanURL()"
            >
            </iframe>
          </div>
        </div>
      </div>
      
      <div class="row invoice_topHeader invoice_right_content">
        <div class="col-md-2 invoice_duedate">
          <label>DUE ON</label>
          <div>{{ rightsectionresponse.paymentDueDate | dateformat }}</div>
        </div>
        <div class="col-md-2 invoice_issueddate">
          <label>ISSUED ON</label>
          <div>{{ rightsectionresponse.invoiceDate }}</div>
        </div>
        <div class="col-md-3 invoice_payment">
          <label>PAYMENT</label>
          <div>{{ rightsectionresponse.paymentTermDescription }}</div>
        </div>
        <div class="col-md-4 invoice_totalcost">
          <label>TOTAL COST</label>

          <div>
            <span>$ {{ rightsectionresponse.total | number : "1.2-2" }}</span>
            USD
          </div>
        </div>
        <div class="col-md-1 invoice_detailstatusParent">
          <div
            class="invoice_detailstatus {{
              getElementStatus(rightsectionresponse.invoiceStatus) | lowercase
            }}"
          >
            {{ rightsectionresponse.invoiceStatus }}
          </div>
          <div class="clearfix"></div>
        </div>
        <div class="clearfix"></div>
      </div>
      <!-- //schedule pay changes -->
      <div class="row">
        <div
          class="col-md-12 payment_status_update"
          *ngIf="
            showSuccessBanner ||
            rightsectionresponse.showPaymentProccessingBanner || rightsectionresponse.showSchedulePaymentBanner
          "
        >
          <ng-container *ngIf="rightsectionresponse.showPaymentProccessingBanner">{{ payMessage.paymentProcessingMessage }}</ng-container>
          <ng-container *ngIf="rightsectionresponse.showSchedulePaymentBanner"><span class="new_schpay_message">This invoice is scheduled to be paid on {{rightsectionresponse.schedulePaymentDate}} using your card ending in {{rightsectionresponse?.cardNumber?.slice(-4)}}. Go to <a routerLink="/scheduled-payments">Scheduled Payments</a> to edit or cancel.</span></ng-container>

        </div>
      </div>

      <div class="invoice_topHeader">
        <div class="invoice_detailbox1">
          <div class="invoice_boxdetail invoice_detailPO">
            <div class="invoice_detaillabel invoice_detailPOlabel">
              PO NUMBER
            </div>
            <div class="invoice_detailno invoice_detailPOno">
              {{ rightsectionresponse.poNumber }}
            </div>
            <div class="invoice_detailissued invoice_detailPOissued">
              Issued on: <span>{{ rightsectionresponse.poDate }}</span>
            </div>
          </div>
          <div class="invoice_boxdetail invoice_detailorder">
            <div class="invoice_detaillabel invoice_detailorderlabel">
              ORDER NUMBER
            </div>
            <div class="invoice_detailno invoice_detailorderno">
              #{{ rightsectionresponse.salesOrderNumber }}
            </div>
            <div class="invoice_detailissued invoice_detailorderissued">
              Placed on: <span>{{ rightsectionresponse.placedOn }}</span>
            </div>
            <div class="invoice_detailotherorder" style="display: none">
              View other invoices for this order<span
                ><img alt="Black view next arrow" width="20"
              /></span>
            </div>
          </div>
        </div>
        <div class="invoice_detailbox2">
          <div class="invoice_boxdetail invoice_detaildelivery">
            <div class="invoice_detaillabel invoice_detaildeliverylabel">
              DELIVERY ORDER NUMBER
            </div>
            <div class="invoice_detailno invoice_detaildeliveryno">
              {{ rightsectionresponse.deliveryNumber }}
            </div>
          </div>
        </div>
        <div class="invoice_detailbox3">
          <div class="invoice_boxdetail invoice_detailship">
            <div class="invoice_detaillabel invoice_detailshiplabel">
              SHIP TO
            </div>
            <div class="invoice_detailno invoice_detailshipno">
              #{{ rightsectionresponse.shipTo.shipToId }}
            </div>
            <div class="invoice_detaillabel invoice_detailshiploc">
              Location Name
            </div>
            <div class="invoice_detailissued invoice_detailshipaddress">
              {{ rightsectionresponse.shipTo.shipToStreet }},&nbsp;{{
                rightsectionresponse.shipTo.shipToCity
              }},&nbsp;{{ rightsectionresponse.shipTo.shipToState }},&nbsp;{{
                rightsectionresponse.shipTo.shipToZipCode
              }}
            </div>
          </div>
          <div class="invoice_boxdetail invoice_detailbil">
            <div class="invoice_detaillabel invoice_detailbilllabel">
              BILL TO
            </div>
            <div class="invoice_detailno invoice_detailbillno">
              #{{ rightsectionresponse.billTo.billToId }}
            </div>
            <div class="invoice_detaillabel invoice_detailbillloc">
              Location Name
            </div>
            <div class="invoice_detailissued invoice_detailbilladdress">
              {{ rightsectionresponse.billTo.billToStreet }},&nbsp;{{
                rightsectionresponse.billTo.billToCity
              }},&nbsp;{{ rightsectionresponse.billTo.billToState }} ,&nbsp;{{
                rightsectionresponse.billTo.billToZipCode
              }}
            </div>
          </div>
        </div>
        <div class="invoice_detailtotalcal invoice_detailsubtotal">
          <div class="invoice_detailtotalcallabel">Subtotal</div>
          <div class="invoice_detailtotalcalLine"></div>

          <div
            class="invoice_detailtotalcalamount invoice_detailsubtotalAmount"
          >
            ${{ rightsectionresponse.subTotal | number : "1.2-2" }}
          </div>
        </div>
        <div class="invoice_detailtotalcal invoice_detailtax">
          <div class="invoice_detailtotalcallabel">Taxes &amp; Fees</div>
          <div class="invoice_detailtotalcalLine"></div>

          <div class="invoice_detailtotalcalamount invoice_detailtaxAmount">
            ${{ rightsectionresponse.vat | number : "1.2-2" }}
          </div>
        </div>
        <!-- discount-->
        <div class="invoice_detailtotalcal invoice_detailtax"  *ngIf="showDiv">
          <div class="invoice_detailtotalcallabel">{{ rightsectionresponse.payDiscount | number : "1.0-0" }}% Prompt Pay Discount
            <span class="invoice-expiry-dicount">Exp: {{rightsectionresponse.payDiscountExpDate}}</span>
          </div>
          <div class="invoice_detailtotalcalLine"></div>

          <div class="invoice_detailtotalcalamount invoice_detailtaxAmount">
            ${{ rightsectionresponse.payDiscountAmout | number : "1.2-2" }}
          </div>
        </div>
        <!-- IREC Invoice Details changes start-->
        <div class="invoice_detailtotalcal">
          <div class="invoice_detailtotalcallabel">Total Cost</div>
          <div class="invoice_detailtotalcalLine"></div>
          <div class="invoice_detailtotalcalamount">
            ${{ rightsectionresponse.total | number : "1.2-2" }}
          </div>
          <div class="clearfix"></div>
        </div>
        <div class="invoice_detailtotalcost">
          <div class="invoice_detailtotalcostlabel">Remaining Balance</div>
          <div class="invoice_detailtotalcostamount">
            ${{ rightsectionresponse.amountDue | number : "1.2-2" }}
          </div>
          <div class="clearfix"></div>
        </div>
        <!-- IREC Invoice Details changes ends-->
      </div>
      <div
        class="invoice_topHeader invoice_topHeader_pdf"
        id="invoicePdf_{{ dropdownValue }}"
      >
        <div class="invoice_detailpdf" (click)="handleOnLinkClick($event)">
          <a href="/terms-conditions-sales" target="_blank"
            >See Terms and Conditions of Sale</a
          >
        </div>
      </div>
    </div>
  </ng-container>
</div>
