


<ng-container *ngIf="currentSite === 'seqirusb2b-flu360'">  
     <div class="yCmsContentSlot row dashboard-splitscetion">
    <div class="col-sm-12 col-lg-6 dashboard-invoicesection dashboard-invoicesection float_left">
        <div class="dashboard-invoicesection-header ">{{'invoice.title' | cxTranslate}}</div>
            <div class="dashboard-invoicesection-text" *ngIf="invoiceNode | async as item" >
               
                <div class="invoice-para"[innerHTML]="'invoice.paragraph1' | cxTranslate : { invoice: item.invoiceCount }"></div>
                
            <div class="dashboard-invoicesection-text header" *ngIf="invoiceNode | async as item">
                <div [innerHTML]="'invoice.paragraph2' | cxTranslate : { invoiceamount: item.invoiceTotalAmount | number:'1.2-2'}"></div>
               
            </div>
            <div class="dashboard-viewall dashboard-viewinvociemargin dashboard-viewinvociemargin">
                <a class="anchorunderline" routerLink="/financial-dashboard">{{'invoice.linktext' | cxTranslate}}<i class="global_blackarrow"></i></a>
            </div> 
        </div>
    </div>
    <div class="col-sm-12 col-lg-6 dashboard-creditsection dashboard-creditsection float_left">
        <div class="dashboard-invoicesection-header">{{'credit.title' | cxTranslate}}</div>
            <div class="dashboard-invoicesection-text dashboard-invoicesection-text" *ngIf="invoiceNode | async as item" [innerHTML]="'credit.paragraph1' | cxTranslate : { creditavailable: item.returnsandcreditsTotalAmount}"></div>
           
        <div class="dashboard-invoicesection-text dashboard-invoicesection-text1 hold"  [innerHTML]="'credit.paragraph2' | cxTranslate"></div>
        
        <div class="dashboard-viewall dashboard-viewcreditmargin">
                <a class="anchorunderline" routerLink="/financial-dashboard">{{'credit.linktext' | cxTranslate}}<i class="global_blackarrow"></i></a>
            </div>
    </div>
    </div>
    </ng-container>
    
    <ng-container *ngIf="currentSite === 'seqirusb2b-uk' && rbavalue?.hasManageFin==true"> 
    <div class="yCmsContentSlot row dashboard-splitscetion dashboard-splitscetionuk">  
        <div class="col-sm-12 col-lg-6 dashboard-invoicesection dashboard-invoicesectionuk float_left">
            <div class="dashboard-invoicesection-header">{{'invoice.uktitle' | cxTranslate}}</div>
                <div class="dashboard-invoicesection-text" *ngIf="invoiceNode | async as item" >
                    <div [innerHTML]="'invoice.paragraph1' | cxTranslate : { invoice: item.invoiceCount }"></div>
                </div>
                    <div class="dashboard-viewall dashboard-viewinvociemargin dashboard-viewinvociemarginuk">
                    <a class="anchorunderline" routerLink="/financial-dashboard/all-invoices">{{'invoice.linktext' | cxTranslate}}<i class="global_blackarrow"></i></a>
                </div> 
            
        </div>
        <div class="col-sm-12 col-lg-6 dashboard-creditsection dashboard-creditsectionuk float_left">
            <div class="dashboard-invoicesection-header">{{'credit.uktitle' | cxTranslate}}</div>
           <div class="dashboard-invoicesection-text dashboard-invoicesectionuk-text" *ngIf="invoiceNode | async as item" [innerHTML]="'credit.ukparagraph1' | cxTranslate : { creditavailable: item.returnsandcreditsTotalAmount }"></div>
               
            <div class="dashboard-invoicesection-text dashboard-invoicesectionuk-text1" [innerHTML]="'credit.ukparagraph2' | cxTranslate"></div>
            
        </div>
        </div>
        </ng-container> 

