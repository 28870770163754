import { Component } from '@angular/core';

@Component({
  selector: 'app-failpass',
  templateUrl: './failpass.component.html',
  styleUrl: './failpass.component.scss'
})
export class FailpassComponent {

}
