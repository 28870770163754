<div id="influenza-hero" *ngIf="object1 | async as item" class="row-flex influenza-fourth-rowflex">
    <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 image-col">
        <img class="influenza-hero-image" alt="Healthcare professional administering flu vaccine"
            [src]="getImage(item.mediaPicture)">
        <!-- <img class="hero--circlechart-chart" alt="image" [src]="getImage(item.mediaPicture)"> -->

    </div>
    <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 influenza-callout-desktop">
        <div class="influenza-hero-container">
            <img class="rectangle-down" alt="angled corner" src="/assets/images/rectangle-down.svg">
            <p class="callout" [innerHTML]="item.headline"></p>
            <img class="rectangle-up" alt="angled corner" src="../../../app/../assets/images/rectangle-up.svg">
            <p class="small-callout" [innerHTML]="item.h2content"></p>
        </div>
    </div>
</div>