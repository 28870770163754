import { BaseSiteService, CmsBannerComponent, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { forkJoin, Observable } from 'rxjs';
import { ContentService } from '../../spartacus/services/content.service';
import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, Renderer2,HostListener,OnDestroy   } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { StartOrderService } from '../../custom-start-order/start-order.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { ReturnsUkService } from '../returns-uk.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { ApiResponse, LocationData, ProductData, ShipData,ReturnData,GroupedProduct,GalleryImage,ReturnOrder } from '../location-data.model';
import { TemplateRef} from '@angular/core';
import { AfterViewInit } from '@angular/core';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { MatDialog } from '@angular/material/dialog';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { GalleryItem, ImageItem } from 'ng-gallery';
import { HttpClient } from '@angular/common/http';
import { FileUploadModalComponent } from '../file-upload-modal/file-upload-modal.component';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { PageEvent } from '@angular/material/paginator';
import { ImageDialogComponent } from '../image-dialog/image-dialog.component';
import { Overlay, NoopScrollStrategy } from '@angular/cdk/overlay';
import { ToastrService } from 'ngx-toastr';
import { DownloadService } from '../../financial-dashboard/download.service';

interface Product {
  additionalQuantity: number;
  batchNumber: string;
  productFullName: string;
  productName: string;
  productPresentCheck: string;
  selectedQuantity: number;
  totalQuantity: number;
}
@Component({
  selector: 'app-returns-section1',
  templateUrl: './returns-section1.component.html',
  styleUrl: './returns-section1.component.scss'
})
export class ReturnsSection1Component  implements OnInit, OnDestroy{
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('searchInput', { static: false }) searchInput!: ElementRef;

  private clickListener!: () => void;
  
  constructor(private overlay: Overlay,private el: ElementRef,private route: ActivatedRoute,private renderer: Renderer2,public component: CmsComponentData<CmsBannerComponent>,private http: HttpClient,public dialog: MatDialog, private cdr: ChangeDetectorRef, private router: Router, private startOrderService: StartOrderService, private userInfoService: UserInfoService, public contentService:ContentService, private baseSiteService:BaseSiteService,private returnsUkService:ReturnsUkService,protected config: OccConfig,private downloadService: DownloadService) {
    
  }

  bannerNode: Observable<any> = this.component.data$;
  showloader: boolean = false;
  displayedColumns: string[] = ['combined'];
  dataSource = new MatTableDataSource<any>();
  showloader_download:boolean = false;
  filterOpen = false;
  sortOpen = false;
  selectedDetails: any = null;
  isVisible = false;
  selectedRow: any;
  location: any;
  aqivProducts: any[] = [];
  qivcProducts: any[] = [];
  totalAqivDoses: number = 0;
  totalQivcDoses: number = 0;
  seasons: string[] = [];
  defaultReturnSeason: string = '';
  selectedReturnId: string = '';
  eachCheckboxChecked:any[] = [false,false];
  isCheckboxChecked:boolean=false
  returnId: string = ''; 
  confirm_destroy_msg:any[] = [false,false];
  credit_dest_box:any[] = [true,true];
  originalData: any[] = [];
  selectedSortOrder: string | null = null;
  showReturnLanding:boolean = true;
  showFilterSort = false;
  showFilterPanel = false;
  selectedProducts = { aqiv: false, qivc: false };
  selectedStatus = { approved: false, submitted: false, noReturns: false };
  selectedFilterCount = 0;
  filteredDataLength = 0;
  searchFilteredDataLength = 0;
  returnsAvailble:boolean = true;
  noReturnsAvailable:boolean = false;
  returnEligilibity:boolean = true;
  currentPage = 1;
  pageSize = 5;
  totalPages = 0;
  totalItems = 0;
  pages: number[] = [];
  returnrba:any;
  destroyreturn:string=''
  returnidex:number=0



 
  ngOnInit() {
    localStorage.removeItem('defalutseason');

    this.returnsUkService.getSeasons().subscribe(response => {
      this.showloader = true;
      this.cdr.detectChanges();
      this.defaultReturnSeason = response.defaultReturnSeason;
      this.seasons = response.seqirusSeasonList;
      
      localStorage.setItem('defalutseason',this.defaultReturnSeason);

      // Fetch the initial data based on the default season
      this.fetchSeasonReturnOrders(this.defaultReturnSeason);
      this.showloader = false;
      this.cdr.detectChanges();
    }, error => {
      console.error('API Error:', error); // Log any errors
    });
      

    this.dataSource.sort = this.sort;
    this.returnrba=localStorage.getItem('rbapermission');
    this.returnrba=JSON.parse(this.returnrba);
    this.clickListener = this.renderer.listen('document', 'click', this.onDocumentClick.bind(this));
   
  }

  ngOnDestroy() {
    if (this.clickListener) {
      this.clickListener();
      this.cdr.detectChanges();
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.returnsUkService.currentLocname.subscribe(locname => {
       this.showloader = true;
        if (locname) {
          if (this.searchInput) {
            this.searchInput.nativeElement.value = locname;
            this.applyFilter({ target: { value: locname } } as unknown as Event);
            if (this.dataSource.data.length > 0) {
              const firstRow = this.dataSource.data[0];
              this.onRowClicked(firstRow);
              this.cdr.detectChanges();
            }
           this.showloader = false;
            this.cdr.detectChanges(); // Ensure view is updated
          } else {
            console.error('searchInput is not defined');
          }
        }
      });
    }, 0);

    this.returnsUkService.currentVisibility.subscribe(visibility => {
      this.isVisible = visibility === 'A';
      this.cdr.detectChanges();
    });
    
    this.dataSource.paginator = this.paginator;
    this.cdr.detectChanges();
  }

  financialgetDownloadInvoiceData(invnumber: number) {
    this.showloader_download = true;
    this.downloadService.setDownloadStatus(invnumber, true);

    this.userInfoService.financialdownloadInvoice(invnumber.toString())
      .subscribe(
        (data: ArrayBuffer) => {
          const file = new Blob([data], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);

          const a = document.createElement('a');
          a.href = fileURL;
          a.target = '_blank';
          a.download = `${invnumber}.pdf`;
          document.body.appendChild(a);
          a.click();

          this.downloadService.setDownloadStatus(invnumber, false);
          this.showloader_download = false;
          this.cdr.detectChanges();
        },
        (error) => {
          console.log('getPDF error: ', error);
          this.downloadService.setDownloadStatus(invnumber, false);
        }
      );
  }

  onPageChange(event: any) {
    this.currentPage = event.pageIndex + 1;
    this.updatePages();
    this.cdr.detectChanges();
  }
  hasSelectedQuantity(): boolean {
    return this.aqivProducts.some(product => product.selectedQuantity > 0 || product.additionalQuantity > 0);
  }
  hasSelectedQuantity1(): boolean {
    return this.qivcProducts.some(product => product.selectedQuantity > 0 || product.additionalQuantity > 0);
  }
  updatePages() {
    this.pages = Array.from({ length: this.totalPages }, (_, i) => i + 1);
    this.cdr.detectChanges();
  }

  goToPage(page: number) {
    this.paginator.pageIndex = page - 1;
    this.currentPage = page;
    this.dataSource.paginator!._changePageSize(this.pageSize); // Trigger page change
    this.cdr.detectChanges();
  }

  goToFirstPage() {
    this.paginator.firstPage();
    this.currentPage = 1;
    this.updatePages();
    this.cdr.detectChanges();
  }

  goToPreviousPage() {
    if (this.paginator.hasPreviousPage()) {
      this.paginator.previousPage();
      this.currentPage--;
      this.updatePages();
      this.cdr.detectChanges();
    }
  }

  goToNextPage() {
    if (this.paginator.hasNextPage()) {
      this.paginator.nextPage();
      this.currentPage++;
      this.updatePages();
      this.cdr.detectChanges();
    }
  }

  goToLastPage() {
    this.paginator.lastPage();
    this.currentPage = this.totalPages;
    this.updatePages();
    this.cdr.detectChanges();
  }

  showAdditionalReturn(){
    
    this.returnsUkService.changeVisibility('D');
    // Pass the selected return ID to the new component
    this.returnsUkService.getAdditionalReturn(this.selectedReturnId, this.defaultReturnSeason).subscribe(response => {
      this.returnsUkService.setAdditionalReturnData(response); // Store the response in the service
      this.cdr.detectChanges();
    }, error => {
      
      console.error('API Error:', error); // Log any errors
    });
  }

  onSeasonChange(event: Event): void {
    this.showloader = true;
    const selectedSeason = (event.target as HTMLSelectElement).value;

    // Fetch data based on the selected season
    this.fetchSeasonReturnOrders(selectedSeason);
   // this.getReturnEligibility(selectedSeason)
   this.updatePages();
    this.showloader = false;
    this.cdr.detectChanges();
  }

  getReturnEligibility(season: string): void {
    this.returnsUkService.getReturnEligibility(season).subscribe(data => {
      this.returnEligilibity = data.customerEligibleForReturn;
      
     
     this.cdr.detectChanges();
    }, error => {
      console.error('API Error:', error); // Log any errors
    });
  }

  fetchSeasonReturnOrders(season: string): void {
    this.returnsUkService.getSeasonReturnOrders(season).subscribe(data => {
      this.dataSource.data = data.data;
      this.originalData = [...data.data];
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;

      this.totalItems = this.dataSource.data.length;
      this.totalPages = Math.ceil(this.totalItems / this.pageSize);
      this.updatePages();

      this.returnsUkService.getReturnEligibility(season).subscribe(data => {
        this.returnEligilibity = data.customerEligibleForReturn;
        this.cdr.detectChanges();
      }, error => {
        console.error('API Error:', error);
      });

      if (this.dataSource.data.length > 0) {
        this.noReturnsAvailable = false;
        this.returnsAvailble = true;
        const firstRow = this.dataSource.data[0];
        this.onRowClicked(firstRow);
      } else {
        this.noReturnsAvailable = true;
        this.returnsAvailble = false;
      }

      this.cdr.detectChanges();
    }, error => {
      console.error('API Error:', error);
    });
  }

  
  showNoreturn() {
    this.returnsUkService.changeVisibility('B');
    this.cdr.detectChanges();
  }

  showNewReturn() {
    this.returnsUkService.changeVisibility('C');
    this.cdr.detectChanges();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    
    
  }

  toggleFilter(event: MouseEvent) {
    event.stopPropagation();
    this.showFilterPanel = !this.showFilterPanel;
  }

  toggleSort(event: MouseEvent) {
    event.stopPropagation();
    this.showFilterSort = !this.showFilterSort;
  }


  onDivClick(event: MouseEvent) {
    event.stopPropagation();
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent):void {
    const target = event.target as HTMLElement;
    const modal = this.el.nativeElement.querySelector('#returns_destroyPopup .modal-dialog');
    if (this.isCheckboxChecked && modal && !modal.contains(target)) {
      this.isCheckboxChecked = false;
      this.eachCheckboxChecked[this.returnidex]=false
    }
    if (this.showFilterPanel) {
      this.showFilterPanel = false;
    }
    if (this.showFilterSort) {
      this.showFilterSort = false;
    }
    
  }

  sortData(order: string) {
    this.dataSource.data = this.dataSource.data.sort((a, b) => {
      const locA = a.loc.toLowerCase();
      const locB = b.loc.toLowerCase();
      if (order === 'asc') {
        return locA < locB ? -1 : locA > locB ? 1 : 0;
        
      } else {
        return locA > locB ? -1 : locA < locB ? 1 : 0;
      }
      
    });
    this.updatePages();
    this.cdr.detectChanges();
  }

  resetSort() {
    this.dataSource.data = [...this.originalData];
    this.selectedSortOrder = null;
    this.updatePages();
    this.cdr.detectChanges();
  }

  applyFilters() {
    const filteredData = this.originalData.filter(item => {
      const productMatch = (this.selectedProducts.aqiv && item.prodname.includes('aQIV')) || (this.selectedProducts.qivc && item.prodname.includes('QIVc')) || (!this.selectedProducts.aqiv && !this.selectedProducts.qivc);
      const statusMatch = (this.selectedStatus.approved && item.status === 'APPROVED') || (this.selectedStatus.submitted && item.status === 'SUBMITTED') || (this.selectedStatus.noReturns && item.status === 'NO RETURNS') || (!this.selectedStatus.approved && !this.selectedStatus.submitted && !this.selectedStatus.noReturns);
      return productMatch && statusMatch;
    });

    this.dataSource.data = filteredData;
    this.filteredDataLength = filteredData.length;
    this.updateFilterCount();
    this.updatePages();
    this.cdr.detectChanges();
  }

  resetFilters() {
    this.selectedProducts = { aqiv: false, qivc: false };
    this.selectedStatus = { approved: false, submitted: false, noReturns: false };
    this.dataSource.data = [...this.originalData];
    this.filteredDataLength = this.originalData.length;
    this.updateFilterCount();
    this.updatePages();
    this.cdr.detectChanges();
  }

  updateFilterCount() {
    this.selectedFilterCount = Object.values(this.selectedProducts).filter(Boolean).length + Object.values(this.selectedStatus).filter(Boolean).length;
    this.updatePages();
    this.cdr.detectChanges();
  }
  

  
  onRowClicked(row: any) {
    this.confirm_destroy_msg=[false,false];
   this.credit_dest_box = [true,true];
  this.eachCheckboxChecked = [false,false];
    this.returnsUkService.getReturnDetails(row.locID, row.orderSeason).subscribe(details => {
      this.showloader = true;
      this.selectedDetails = details;
      this.selectedRow = row;
      this.location = details.locations[0];
      this.selectedReturnId = row.returnId; // Store the selected return ID

      this.aqivProducts = this.location.returnsProduct.filter((product: any) => product.productName === 'aQIV');
      this.qivcProducts = this.location.returnsProduct.filter((product: any) => product.productName === 'QIVc');

      this.totalAqivDoses = this.aqivProducts.reduce((sum, product: any) => sum + this.getDisplayedQuantity(product), 0);
      this.totalQivcDoses = this.qivcProducts.reduce((sum, product: any) => sum + this.getDisplayedQuantity(product), 0);

      this.showloader = false;
      this.cdr.detectChanges();
    });
  }

  getDisplayedQuantity(product: any): number {
    return product.additionalQuantity !== 0 ? product.additionalQuantity : product.selectedQuantity;
  }

  getTotalDoses(products: any[]): number {
    return products.reduce((sum, product: any) => sum + this.getDisplayedQuantity(product), 0);
  }

  filterProductsByType(location: any, productName: string): any[] {
    return location.returnsProduct.filter((product: any) => product.productName === productName);
  }

  hasProducts(location: any, productName: string): boolean {
    return this.filterProductsByType(location, productName).some(product => product.selectedQuantity !== 0 || product.additionalQuantity !== 0);
  }

  shouldDisplayProduct(product: any): boolean {
    return product.selectedQuantity !== 0 || product.additionalQuantity !== 0;
  }
  
  openImageDialog(eachlocation:any): void {
    const baseUrl = this.config.backend?.occ?.baseUrl; // Get the base URL
    if (eachlocation && eachlocation.imageUploads && eachlocation.imageUploads.length > 0) {
      this.dialog.open(ImageDialogComponent, {
        data: {
          images: eachlocation.imageUploads,
          baseUrl: baseUrl, 
        },
        scrollStrategy: this.overlay.scrollStrategies.noop(),
        width: '700px',
        disableClose: true,
        hasBackdrop: true,
        autoFocus: true,
        restoreFocus: true,
        backdropClass: 'custom-backdrop'
      });
      
    } else {
      console.error('No images to display');
    }
  }

  onCheckboxChange(event: Event,returnid:string,i:number): void {
    const checkbox = event.target as HTMLInputElement;
    if (checkbox.checked) {
      
      
        this.isCheckboxChecked = true;
        this.eachCheckboxChecked[i]=true

       // Show the div after 1 second
    } else {
    
      this.isCheckboxChecked = false;
      this.eachCheckboxChecked[i]=false
    }
    this.destroyreturn=returnid
    this.returnidex=i
    this.cdr.detectChanges();
  }

  onButtonClick(): void {
    this.isCheckboxChecked = false;
    this.eachCheckboxChecked[this.returnidex]=false
    const checkbox = document.getElementById('destroyCheckbox') as HTMLInputElement;
    if (checkbox) {
      checkbox.checked = false;
    }
  }

  callDeleteDosesConsent() {
    this.returnsUkService.deleteDosesConsent(this.destroyreturn).subscribe(
      (response:any) => {
        this.isCheckboxChecked = false;
        this.eachCheckboxChecked[this.returnidex]=false
        this.confirm_destroy_msg[this.returnidex] = true;
        this.credit_dest_box[this.returnidex] = false;
        this.cdr.detectChanges();
      },
      error => {
        console.error('API call error:', error);
        this.isCheckboxChecked = false;
        this.eachCheckboxChecked[this.returnidex]=false
        this.confirm_destroy_msg[this.returnidex]  = true;
        this.credit_dest_box[this.returnidex] = false;
        this.cdr.detectChanges();
      }
    );
  }


  
  
}
