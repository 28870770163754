<div id="publicpage" *ngIf="publicnode | async as item">
    <div class="bannerimg">
        <img alt="Public-Banner" [src]="getImage(item.media)">
    </div>
    <div class="publiccontainer1">
        <img class="rectangle-down" alt="angled corner" src="../../../app/../assets/images/rectangle-down.svg">
        <div class="publicheader">{{item.headLine1}}</div>
        <div class="publicsubtext" [innerHTML]="item.textPageContent1"></div>
    </div>
    <div class="publiccontainer2">
        <div class="gbtext" [innerHTML]="item.textPageContent2"></div>
        <div class="gbflex">
            <div class="gbblacktext" [innerHTML]="item.TextPage2"></div>
            <div class="gbredtext">
                <a href="{{item.link1}}" target="_blank" [innerHTML]="item.Commonlintext">
                </a>
            </div>
        </div>
        <div class="gbflex">
            <div class="gbblacktext" [innerHTML]="item.TextPage3"></div>
            <div class="gbredtext">
                <a href="{{item.link2}}" target="_blank" [innerHTML]="item.Commonlintext"></a>
            </div>
        </div>
    </div>
    <div class="publiccontainer3">
        <div class="reporttext">{{item.header2}}</div>
        <div class="reportsubtext textbtn">{{item.PageSubText}}</div>
        <div class="reportsubtext textbtn">{{item.PageBottomcontent1}}
            <a class="mhralink externallinkpopupUK" data-toggle="modal" (click)="openModal()">{{item.linkText}}<svg
                    width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M2 0H9V1.5H2C1.72386 1.5 1.5 1.72386 1.5 2V16.0001C1.5 16.2762 1.72386 16.5001 2 16.5001H16.0001C16.2762 16.5001 16.5001 16.2762 16.5001 16.0001V9.375H18.0001V16.0001C18.0001 17.1047 17.1047 18.0001 16.0001 18.0001H2C0.895428 18.0001 0 17.1047 0 16.0001V2C0 0.895428 0.895431 0 2 0ZM17 0.25L10.25 0.249999C9.83579 0.249999 9.5 0.585786 9.5 1C9.5 1.41421 9.83579 1.75 10.25 1.75H15.1893L6.46967 10.4697L7.53033 11.5303L16.25 2.81066V7.75C16.25 8.16421 16.5858 8.5 17 8.5C17.4142 8.5 17.75 8.16421 17.75 7.75V1C17.75 0.585786 17.4142 0.25 17 0.25Z"
                        fill="#2A3237"></path>
                </svg></a>.
        </div>
    </div>
    <div class="commonleaving_abandonedPop modal  fade in show" role="dialog" data-backdrop="static"
        data-keyboard="false" id="commonleaving_abandonedPop" tabindex="-1">

        <div class="modal-body knowledgeHub_abandonedPopContent">
            <div class="knowledgeHub_abandonedPopHeader">
                <div class="knowledgeHub_abandonedPopTitle">{{item.PopupData.headline}}</div>
                <div class="knowledgeHub_abandonedPopClose" (click)="closeModal()">X</div>
                <div class="clearboth"></div>
            </div>
            <div class="knowledgeHub_abandonedPopText">
                <p [innerHTML]="item.PopupData.message1"></p>
                <p [innerHTML]="item.PopupData.message2"></p>
            </div>
            <div class="knowledgeHub_abandonedPopBtns">
                <button  class="knowledgeHub_abandonedPopBtn commonleave_popContBtn">
                <a href="{{item.PopupData.link}}" target="_blank">{{item.PopupData.label1}}</a>
                </button>
                <!-- <button class="knowledgeHub_abandonedPopBtn iframe_popContBtn">Continue</button> -->
                <button class="knowledgeHub_abandonedPopBtn knowledgeHub_popCanceltBtn"
                    (click)="closeModal()">{{item.PopupData.label2}}</button>
            </div>
            <div class="knowledgedocnumberUk">{{item.PopupData.jobBagCode}}</div>
        </div>

    </div>
</div>