import { ChangeDetectorRef, Injectable, NgZone } from '@angular/core';
import { AuthRedirectService, AuthRedirectStorageService, AuthService, BaseSiteService, OccEndpointsService, StateWithProcess, StateWithUser, User, UserIdService } from '@spartacus/core';
import { Observable, tap, catchError, shareReplay, of, switchMap, BehaviorSubject, take } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRoute, NavigationStart, Router, RouterEvent } from '@angular/router';
import { NavigationEvent } from '@spartacus/storefront';
import { Store } from '@ngrx/store';
import {
  CsAgentAuthService,
} from '@spartacus/asm/root';
import { RefundData, AppliedCreditDetails } from '../../financial-dashboard-uk/credits-section/credits-section.component';
import { environment } from '../../../environments/environment';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
@Injectable({
  providedIn: 'root'
})
export class UserInfoService{
  
  userInfo$ = new BehaviorSubject<any>(null);//Observable<any> | undefined;
  seasonValue$ = new BehaviorSubject<string>('');
  invoiceCount$ = new BehaviorSubject<string>('0')
  scheduledPaymentCount$ = new BehaviorSubject<string>('0')
  profileInfo$ = new BehaviorSubject<any>(null);
  checkoutId$ = new BehaviorSubject<any>('');
  isCancelled$ = new BehaviorSubject<any>(false);
  currentrba$=new BehaviorSubject<any>(false); 
  chnagerba$=new BehaviorSubject<any>(false); 
  switchaccountrba=new BehaviorSubject<any>(false); 
  userLoggedIn$: Observable<boolean> = this.authService.isUserLoggedIn();
  dataInvoice$: Observable<any> | undefined;
  orderData$: Observable<any> | undefined;
  isMobileHeader$ = new BehaviorSubject<boolean>(false); // <---- You give 'false' as an initial value
  showPopUp$= new BehaviorSubject<any>(false);
  cartretrigger=new BehaviorSubject<any>(false);
  private orderDataSubject = new BehaviorSubject<any>(null);
  public dashboardOrderData$ = this.orderDataSubject.asObservable();
  private orgLocRespData = new BehaviorSubject<any>(null);
  currentOrgLocData = this.orgLocRespData.asObservable();
  private filterValSub= new BehaviorSubject<string>("");
  filterData$ = this.filterValSub.asObservable();
  paidInvoiceCount$ = new BehaviorSubject<string>('0');
  startseasonValue$ = new BehaviorSubject<string>('');
  enablePage$ = new BehaviorSubject<any>('');
  myprofiletab$ = new BehaviorSubject<any>('');
  summarypopup$=new BehaviorSubject<any>('');
  public asmEnabled:boolean = false;
  asmAgentLoggedIn: boolean = false;
  customerEmulated: boolean = false;
  multipleSoldToData$ = new BehaviorSubject<any>(null);
  currentSite: string='';
  unlinkaccount$ = new BehaviorSubject<any>('');
  routeLink$ = new BehaviorSubject<string>('/my-account') //to store url for exit popup integration
  queryParams$ = new BehaviorSubject<any>(null);//to store query params for exit popup integration
  inPlaceOrder$ = new BehaviorSubject<Boolean>(false);//flag to enable exit popup
  selectedAccount$ = new BehaviorSubject<String>("");//account which user selectes to switch - for exit popup purpose
  fromFooter$ = new BehaviorSubject<Boolean>(false)//to check popup loaded from footer
  constructor(
    private http: HttpClient,
    private occ: OccEndpointsService,
    private router: Router,
    private authService: AuthService,private activatedRoute:ActivatedRoute,private baseSiteService:BaseSiteService,
    private userIdService: UserIdService,
    private gtmService: GoogleTagManagerService,protected csAgentAuthService: CsAgentAuthService
  ) {
    this.asmEnabled = localStorage.getItem('showASM') === 'true';
      if(window.location.href.includes('liveassist') || window.location.href.includes('?asm=true')) {
        localStorage.setItem('showASM', 'true');
        // this.asmEnabled = true;
      }
  }

  fetchUserUrl() {
    this.asmEnabled = localStorage.getItem('showASM') === 'true';
    let partUrl = "/users/current";
    this.userIdService.getUserId().subscribe(item => {
      if(item && this.asmEnabled){
        partUrl = `/users/${item}`;
      }else{
        localStorage.removeItem('isSearchByProfile')
      }
    });
    
    return partUrl;
  }
  getIsSearchByProfile(){
    if(localStorage.getItem('isSearchByProfile')  == 'false'){
      return false;
    }
    return true;
  }
  checkUserLoggedIn(){
    this.userLoggedIn$ = this.authService.isUserLoggedIn();
    this.userLoggedIn$.subscribe(data=>{
      //console.log("entered",data);
    });
  }
  fetchMultipleSoldToForUser(){
    const url = this.occ.buildUrl(`${this.fetchUserUrl()}/fetchMultipleSoldTo`);
    return this.http.get(url);
  }
  fetchsapcustdata(){
    const url = this.occ.buildUrl(`${this.fetchUserUrl()}/my-account/dataLayerdata`);
    return this.http.get(url);
  }
  sendDropdownValue(value: string) {
    this.seasonValue$.next(value);
  }
  getDropdownValue() {
    return this.seasonValue$.asObservable();
  }
  setInvoiceCount(value: string) {
    this.invoiceCount$.next(value);
  }
  getInvoiceCount() {
    return this.invoiceCount$.asObservable();
  }
  setScheduledPaymentCount(value: string) {
    this.scheduledPaymentCount$.next(value);
  }
  getScheduledPaymentCount() {
    return this.scheduledPaymentCount$.asObservable();
  }
  setPaidInvoiceCount(paidInvoiceCount: any) {
    this.paidInvoiceCount$.next(paidInvoiceCount);
  }
  getPaidInvoiceCount() {
    return this.paidInvoiceCount$.asObservable();
  }
  setcheckOutId(value: string) {
    this.checkoutId$.next(value);
    localStorage.setItem('checkoutId', value);
  }

  getcheckOutId() {
    return this.checkoutId$.asObservable();
  }

  
  //rba behavioural
  setrbachange(value: string) {
    this.currentrba$.next(value);
    
  }

  getrbachnage() {
    return this.currentrba$.asObservable();
  }

  //rba behavioural
  setrbaheader(value: string) {
    this.chnagerba$.next(value);
    
  }

  getrbaheader() {
    return this.chnagerba$.asObservable();
  }
  setswitchrba(value: string) {
    this.switchaccountrba.next(value);
    
  }

  getswitchrba() {
    return this.switchaccountrba.asObservable();
  }
  setcarttigger(value: string) {
    this.cartretrigger.next(value);
    
  }

  getcarttrigger() {
    return this.cartretrigger.asObservable();
  }
  

//   setCancelStatus(){
//    // let new = localStorage.getItem('isCancelled')
//     this.isCancelled$.next(localStorage.getItem('isCancelled'))
//   }
// getCancelStatus(){
//   return this.isCancelled$
// }

  loginRedirection(res: any) {
    localStorage.removeItem('sapcustomerid');
    localStorage.removeItem('cdcCust');
 
    this.fetchsapcustdata().subscribe((data:any)=>{
      environment.sapcustomerid=data.stCust;
      environment.cdcCust=data.cdcCust;
      localStorage.setItem('sapcustomerid', data.stCust);
      localStorage.setItem('cdcCust', data.cdcCust);
 
      const loginForm = {
        'event' : 'login',
        'cdcCust' : data.cdcCust, //to be replaced with the CDC UID
        'stCust' : data.stCust,  //this number must be replaced with the SAP Sold to ID only if it exists };
        }
   
     this.gtmService.pushTag(loginForm);
     
 
    });
    this.baseSiteService.getActive().subscribe((site)=>{
      this.currentSite=site;
    });
    if (res.webEnabled) {

        if(this.currentSite == 'seqirusb2b-flu360'){
          this.setmodelNapi().subscribe((data)=>{
          });
          const returnUrl = sessionStorage.getItem('returnUrl') || '/my-account';
          sessionStorage.removeItem('returnUrl');
          this.router.navigate([returnUrl]);
        }
        if(this.currentSite == 'seqirusb2b-uk'){

          //this.fetchMultipleSoldToForUser().subscribe((data)=>{
            this.setMultipleSoldToData("chnage");

          //})
        }


    }
    else {
      if(this.currentSite == 'seqirusb2b-uk'){
        this.router.navigate(['register/join-account']);
      }
      else{
        this.router.navigate(['/homepage']);
      }
    }
  }
  setMultipleSoldToData(data: any) {
    this.multipleSoldToData$.next(data);

  }
  getMultipleSoldToData(){
    return this.multipleSoldToData$.asObservable();
  }

  setMobileHeader(flag: boolean) {
    return this.isMobileHeader$.next(flag)
  }
  /**
   * Get current user
   */
  public getMobileHeader(): Observable<boolean> {
    return this.isMobileHeader$.asObservable();
  }
  fetchUserData(): Observable<any> | any {
      this.authService.isUserLoggedIn().pipe(shareReplay(1)).subscribe(isloggedIn => {
        if (isloggedIn) {
          let url = this.occ.buildUrl(this.fetchUserUrl());
          this.http.get(url).subscribe({
            next: (v: any) => {
              //console.log(v),
              v.webEnabled = v.orgUnit.uid !== 'SeqirusUK';
              if (v) {
                this.setUserData(v);
                if (!localStorage.getItem('loggedIn')) {
                  this.loginRedirection(v);
                localStorage.setItem('loggedIn', v.uid);
                }

              }
            },

            error: (e) => console.error(e),
          })
        }
        else{
          localStorage.removeItem('loggedIn');
        }
      })
}
setUserData(result: any){
  this.userInfo$.next(result);
}
getUserData(){
  return this.userInfo$.asObservable();
}
setProfileData(result: any){
  this.profileInfo$.next(result);
}

getUKMyProfileData(){
  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/my-profile/profile?isSearchByProfile=${this.getIsSearchByProfile()}`);
       return this.http.get(url);
}
setmodelNapi(){
  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/my-account/setModelNPrice`);
  return this.http.get(url);
}
getCustomerSearch(searchText:any) {
  const url = this.occ.buildUrl(`/asm/autocomplete?customerId=${searchText}&isSearchByProfile=false`);
       return this.http.get(url);
}
getProfileData(){
  return this.profileInfo$.asObservable();
}

getInvoiceData(): Observable < any > {
  let url=this.occ.buildUrl(`${this.fetchUserUrl()}/financials/invoicesAndCreditDetails?isSearchByProfile=${this.getIsSearchByProfile()}`);
  // if(!this.dataInvoice$) {
  this.dataInvoice$ = this.http.get(url).pipe(
    shareReplay(1),
    catchError(error => {
      console.error('Error fetching data', error);
      return of(null);
    })
  );
//}
return this.dataInvoice$;
    }
    getseasonDropdown(season:any){
      const url = this.occ.buildUrl(`${this.fetchUserUrl()}/orders/seasonDropdown/${season}?fields=FULL`);
       return this.http.get(url);
    }
    getOrderSummary(){
      const url = this.occ.buildUrl(`${this.fetchUserUrl()}/orders/getOrderSummary?fields=FULL&isSearchByProfile=${this.getIsSearchByProfile()}`);
       return this.http.get(url);
    }
    getMyOrdersShipmentTable(){
      const url = this.occ.buildUrl(`${this.fetchUserUrl()}/orders/getMyOrdersShipmentTable?fields=FULL&isSearchByProfile=${this.getIsSearchByProfile()}`);
       return this.http.get(url);
    //getMyOrdersShipmentTable?fields=FULL
    }
getOrdersDashboard(){
  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/my-account/dashboard?fields=FULL&isSearchByProfile=${this.getIsSearchByProfile()}`);
  // if (!this.orderData$) {
    this.orderData$ = this.http.get(url).pipe(
      shareReplay(1),
      catchError(error => {
        console.error('Error fetching data', error);
        return of(null);
      })
    );
    this.orderDataSubject.next(this.orderData$);
  // }
  return this.orderData$;
}

getsavedcard():Observable<any> {
  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/my-profile/savedCards`);

  return this.http.get(url);
}
getmembershipus():Observable<any> {
  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/my-profile/membership-status`);

  return this.http.get(url);
}
getconsentus():Observable<any> {
  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/my-profile/isConsentGranted`);

  return this.http.get(url);
}

getOrgLocData():Observable<any> {
  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/fetchOrgLocTableData?isSearchByProfile=${this.getIsSearchByProfile()}`);

  return this.http.get(url);
}
getOrgdetails():Observable<any> {
  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/my-profile/orgDetails?isSearchByProfile=${this.getIsSearchByProfile()}`);

  return this.http.get(url);
}
saveOriginalLocData(originalLocData: any) {

  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/saveOriginalLocationData`);
  this.http.post(url,originalLocData,{headers:{'Content-Type':'application/json'}}).subscribe(data => {
    if(data == 200){
    }
  });
}

updateLocationData(updateLocData:any):Observable<any>{
  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/updateOrgLocations`);
  return this.http.post(url,updateLocData,{headers:{'Content-Type':'application/json'}})
}

validateLicense(data: HttpParams) :Observable<any>{
  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/register/validateLicence`);
  return this.http.get(url,{params:data,responseType: 'text'});
}

addOrgLocation(addLocData: any): Observable<any> {
  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/addOrgLocation`);
  return this.http.post(url,addLocData,{headers:{'Content-Type':'application/json'}})
}


filterproduct(season:any){
  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/orders/filterProducts?fields=FULL&selectedSeason=${season}`);
  return this.http.get(url);
    // console.log(res)

}
UkMyorderleftsection(season:string){
  //https://api.caah8q6d5y-seqirusuk1-d5-public.model-t.cc.commerce.ondemand.com/occ/v2/seqirusb2b-uk/users/current/orders/ordersUK?fields=DEFAULT&selectedSeason=2024-2025
  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/orders/ordersUK?fields=DEFAULT&selectedSeason=${season}&isSearchByProfile=${this.getIsSearchByProfile()}`);
  return this.http.get(url);
}
//https://api.caah8q6d5y-seqirusuk1-d5-public.model-t.cc.commerce.ondemand.com/occ/v2/seqirusb2b-uk/users/current/orders/getOrderbyID?fields=DEFAULT&orderId=0010319474&selectedSeason=2024-2025


UkMyorderRightsection(season:string,orderid:string){
  const url=this.occ.buildUrl(`${this.fetchUserUrl()}/orders/getOrderbyID?fields=FULL&orderId=${orderid}&selectedSeason=${season}&isSearchByProfile=${this.getIsSearchByProfile()}`);
  return this.http.get(url);
}
//https://api.caah8q6d5y-seqirusuk1-d5-public.model-t.cc.commerce.ondemand.com/occ/v2/seqirusb2b-uk/users/current/orders/getDeliveryScheduleDetails?fields=DEFAULT&orderId=0010319179&selectedSeason=2023-2024
MyorderRightTable(season:string,orderid:string){
  const url=this.occ.buildUrl(`${this.fetchUserUrl()}/orders/getDeliveryScheduleDetails?fields=FULL&orderId=${orderid}&selectedSeason=${season}&isSearchByProfile=${this.getIsSearchByProfile()}`);
  return this.http.get(url);
}
//https://api.caah8q6d5y-seqirusuk1-d5-public.model-t.cc.commerce.ondemand.com/occ/v2/seqirusb2b-uk/users/current/orders/CalenderEvents?fields=FULL&orderId=0010319179&selectedSeason=2023-2024&lang=en&curr=GBP
//https://api.caah8q6d5y-seqirusuk1-d5-public.model-t.cc.commerce.ondemand.com/occ/v2/seqirusb2b-uk/users/current/orders/calenderEvents?fields=DEFAULT&orderId=0010319179&selectedSeason=2023-2024
MyCalenderUk(season:string,orderid:string){
  const url=this.occ.buildUrl(`${this.fetchUserUrl()}/orders/calenderEvents?fields=DEFAULT&orderId=${orderid}&selectedSeason=${season}&isSearchByProfile=${this.getIsSearchByProfile()}`);
  return this.http.get(url);
}
filterstatus(){

  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/orders/filterStatus?fields=FULL`);
  return this.http.get(url);
}
Myorderleftsection(season:string){

  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/orders/ordersList/${season}?fields=FULL&isSearchByProfile=${this.getIsSearchByProfile()}`);
  return this.http.get(url);
}
MyorderRightsection(season:string,orderid:string){
  const url=this.occ.buildUrl(`${this.fetchUserUrl()}/orders/orderDetails/${season}/${orderid}?fields=FULL&isSearchByProfile=${this.getIsSearchByProfile()}`);
  return this.http.get(url);
}
AllInvoicesRightsection(season:string,invoiceId:string, status:string){
  const url=this.occ.buildUrl(`${this.fetchUserUrl()}/financials/invoicesDetailsAjax?fields=FULL&invoiceNumber=${invoiceId}&invoiceStatus=${status}&isSearchByProfile=${this.getIsSearchByProfile()}`);
  return this.http.get(url);
}
fetchSavedCards(){

  const url=this.occ.buildUrl(`${this.fetchUserUrl()}/financials/fetch-saved-cards?fields=FULL`);
  return this.http.get(url);
}
getIframeUrlTerms(season: string) {
  if(season=="2024-2025"){
    const iframeUrl = this.occ.buildUrl(`/SeqirusPDF/terms-conditions-sales-april-${season}?fields=DEFAULT`);
    return this.http.get(iframeUrl, { responseType: 'text' })
  }else{
 // const seasonValue = season === 'inSeason' ? '2024-2025' : '2025-2026';
  const iframeUrl = this.occ.buildUrl(`/SeqirusPDF/terms-conditions-sales-${season}?fields=DEFAULT`);
  return this.http.get(iframeUrl, { responseType: 'text' })
  }
}

savedCardClicked(obj: any): Observable<any> {
  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/financials/saved-card-payment?fields=FULL`);
  return this.http.post(url,obj,{headers:{'Content-Type':'application/json'}})
}
schedulePayWithSavedCard(obj: any): Observable<any> {
  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/financials/savedCardSchedulePayment?fields=FULL`);
  return this.http.post(url,obj,{headers:{'Content-Type':'application/json'}})
}
redirectIframeUrl(obj: any): Observable<any> {
  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/financials/createHostedCheckout?fields=FULL`);
  return this.http.post(url,obj,{headers:{'Content-Type':'application/json'}})
}
getAllInvoices(season:any):Observable<any>{
  const url=this.occ.buildUrl(`${this.fetchUserUrl()}/financials/dashboard/all-invoices?fields=DEFAULT&selectedSeason=${season}&isSearchByProfile=${this.getIsSearchByProfile()}`);
  return this.http.get(url);
  ///"+season+"?fields=FULL"
}
getCredits(season:any):Observable<any>{
  const url=this.occ.buildUrl(`${this.fetchUserUrl()}/financials/dashboard?fields=DEFAULT&selectedSeason=2023-2024&isSearchByProfile=${this.getIsSearchByProfile()}`)
  return this.http.get(url);
  ///"+season+"?fields=FULL"
}
downloadInvoiceApi(invoicenum :string) {

  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/financials/downloadInvoice?invoiceNumber=${invoicenum}`);
  return this.http.get(url, { responseType: 'arraybuffer' });
}
financialdashboard(selectedseason:string){
  const url=this.occ.buildUrl(`${this.fetchUserUrl()}/financials/dashboard?fields=FULL&selectedSeason=${selectedseason}&isSearchByProfile=${this.getIsSearchByProfile()}`);
  return this.http.get(url);
}
financialdashboardUK(selectedseason:string){
  const url=this.occ.buildUrl(`${this.fetchUserUrl()}/financials/credits?fields=FULL&selectedSeason=${selectedseason}&isSearchByProfile=${this.getIsSearchByProfile()}`);
  return this.http.get(url);
}
financialdownloadInvoice(invoicenumber:string) {

  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/financials/viewCreditReport?invoiceNumber=${invoicenumber}`);
  return this.http.get(url, { responseType: 'arraybuffer' });
}
financialcurrentseason(){
  const url=this.occ.buildUrl(`${this.fetchUserUrl()}/financials/dashboard/currentSeason?isSearchByProfile=${this.getIsSearchByProfile()}`);
  return this.http.get(url);

}


//createHostedCheckout
//financials/dashboard?fields=DEFAULT&selectedSeason=2023-2024
//https://api.caah8q6d5y-seqirusuk1-d5-public.model-t.cc.commerce.ondemand.com/occ/v2/seqirusb2b-flu360/users/current/financials/invoicesDetailsAjax?fields=FULL&invoiceNumber=9130226308&invoiceStatus=DueSoon

deleteCreditDetails(token: string) {
  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/financials/delete-saved-card/${token}?fields=FULL`);
  return this.http.get(url);
}
//edit
updateOrder(orderData:any){
  const url= this.occ.buildUrl(`${this.fetchUserUrl()}/cart/editOrder/addToCart?fields=DEFAULT`);
  return this.http.post(url,orderData,{ responseType: 'text' });

}
MembershipDetails(){
  const url=this.occ.buildUrl(`${this.fetchUserUrl()}/my-profile/membership-status?fields=DEFAULT`);
  return this.http.get(url);
}
editconfirmorder(PORefNumber:any,sapOrderID:any,dropdownYear:string){
  const url=this.occ.buildUrl(`${this.fetchUserUrl()}/checkout/confirmOrder?PORefNumber=${PORefNumber}&fields=DEFAULT&sapOrderID=${sapOrderID}&season=editorder&seasonYear=${dropdownYear}`)
  return this.http.get(url, {responseType: 'text'});
}
paymentStatusIframe(obj: any, id:any): Observable<any> {
  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/financials/payment-status?fields=FULL&hostedCheckoutId=${id}&isFramed=true`);
  return this.http.post(url,obj,{headers:{'Content-Type':'application/json'}})
}
paymentInformationStatusIframe(id: any): Observable<any> {
  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/financials/new-card-status?fields=FULL&hostedCheckoutId=${id}&isFramed=true`);
  return this.http.get(url,{responseType: 'text'})
}
checkCartExist() {
  const checkOrderUrl = this.occ.buildUrl(`${this.fetchUserUrl()}/Start-order/checkCartExistforSeason?fields=DEFAULT&season=`);
  return this.http.get(checkOrderUrl, { responseType: 'text' });
}
setPopUpValue(result: boolean){
  this.showPopUp$.next(result);
}
getPopValue(){
  return this.showPopUp$.asObservable();
}
updateOrgLocData(data: any) {
  this.orgLocRespData.next(data);
}
sendFilterValue(filterValue: any) {
 this.filterValSub.next(filterValue);
}
sendstartorderseason(value:string){
  this.startseasonValue$.next(value);
}
getstartorderseason(){
  return this.startseasonValue$.asObservable();
}
setEnablePage(value:any){
  this.enablePage$.next(value);
}
getEnablePage(){
  return this.enablePage$.asObservable();
}
//ordersummaryuk
setpopupseason(value:any){
  this.summarypopup$.next(value);
}
getpopupseason(){
  return this.summarypopup$.asObservable();
}

leftsection(){
  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/orders/getOrderSummary?fields=FULL&isSearchByProfile=${this.getIsSearchByProfile()}`);
  return this.http.get(url);
}
chartfilter(){
  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/orders/getOrdersChartFilterData?fields=FULL&isSearchByProfile=${this.getIsSearchByProfile()}`);
  return this.http.get(url);
}

upcomingorderUk(season:string){
  const url=this.occ.buildUrl(`${this.fetchUserUrl()}/orders/getUpcomingOrders?fields=DEFAULT&selectedSeason=${season}&isSearchByProfile=${this.getIsSearchByProfile()}`);
  return this.http.get(url);
}

latestdelivery()
{
  const url=this.occ.buildUrl(`${this.fetchUserUrl()}/orders/getMyOrdersDeliveredTable?fields=FULL&isSearchByProfile=${this.getIsSearchByProfile()}`);
  return this.http.get(url);
}
termsofpdf(url:string){
  const iframeUrl = this.occ.buildUrl(`/SeqirusPDF/${url}?fields=DEFAULT`);
  return this.http.get(iframeUrl, { responseType: 'text' })
}
getFluadPdf() {
  const url = this.occ.buildUrl(`SeqirusPDF/FluadPrescribingInfoPDF?fields=DEFAULT`);
  return this.http.get(url, { responseType: 'text' });
}
getAfluriaPdf() {
  const url = this.occ.buildUrl(`SeqirusPDF/AfluriaPrescribingInfoPDF?fields=DEFAULT`);
  return this.http.get(url, { responseType: 'text' });
}
getFlucelvaxPdf() {
  const url = this.occ.buildUrl(`SeqirusPDF/FlucelvaxPrescribingInfoPDF?fields=DEFAULT`);
  return this.http.get(url, { responseType: 'text' });
}
changeB2BAccount(account: string) {
  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/setDefaultB2Bunit/`);
  const params = new HttpParams().set('account',account);
  return this.http.post(url,{},{params});

}
//myprofile uk
 //myprofile uk
 rbasiteflag(){
  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/customer-permissions?fields=DEFAULT&isSearchByProfile=${this.getIsSearchByProfile()}`);
  return this.http.get(url);

 }
settabdata(value:any){
    this.myprofiletab$.next(value);
  }
gettabdata(){
   return this.myprofiletab$.asObservable();
  }
  getconsentuk() {
      const url = this.occ.buildUrl(`${this.fetchUserUrl()}/my-profile/isConsentGranted?fields=DEFAULT`);
      return this.http.get(url);
    }
    setconsentuk(value:boolean) {
      const url = this.occ.buildUrl(`${this.fetchUserUrl()}/my-profile/marketingconsent/${value}`);
      return this.http.post(url,{ responseType: 'text' });
    }

    unlinkaccount(value:number) {
      const url = this.occ.buildUrl(`${this.fetchUserUrl()}/my-profile/unlinkB2bUnit/${value}`);
      return this.http.post(url,{ responseType: 'text' });
    }

    settingrbachanges(value:string,permission:string) {
      const url = this.occ.buildUrl(`${this.fetchUserUrl()}/saveChangedSettingPermissionData/${value}/${permission}`);
      return this.http.post(url,{ responseType: 'text' });
    }

    getaccountdetailsuk(){
      const url = this.occ.buildUrl(`${this.fetchUserUrl()}/my-profile/account-data?fields=DEFAULT&isSearchByProfile=${this.getIsSearchByProfile()}`);
      return this.http.get(url);

    }
    getaccountlink(){
      const url = this.occ.buildUrl(`${this.fetchUserUrl()}/my-profile/unlinkTable?fields=DEFAULT`);
      return this.http.get(url);

    }
    rbaintialdata(){
      const url = this.occ.buildUrl(`${this.fetchUserUrl()}/profile-permissions?fields=DEFAULT&isSearchByProfile=${this.getIsSearchByProfile()}`);
      return this.http.get(url);
    }
    accountrba(){
      const url = this.occ.buildUrl(`${this.fetchUserUrl()}/adminCustomerAccount?fields=DEFAULT&isSearchByProfile=${this.getIsSearchByProfile()}`);
      return this.http.get(url);
    }
    locationrba(locid:string){
      const url = this.occ.buildUrl(`${this.fetchUserUrl()}/adminCustomerLoc/${locid}?fields=DEFAULT&isSearchByProfile=${this.getIsSearchByProfile()}`);
      return this.http.get(url);
    }
    userrba(userid:string){
      const url = this.occ.buildUrl(`${this.fetchUserUrl()}/adminCustomerUserTab/${userid}?fields=DEFAULT`);
      return this.http.get(url);
    }
    nonadmin(){
      const url = this.occ.buildUrl(`${this.fetchUserUrl()}/nonAdminCustomer?fields=DEFAULT&isSearchByProfile=${this.getIsSearchByProfile()}`);
      return this.http.get(url);
    }
    changeaccountpermssion(obj: any){
      const url = this.occ.buildUrl(`${this.fetchUserUrl()}/saveChangedAccountPermissionData?fields=FULL`);
      return this.http.post(url,obj,{headers:{'Content-Type':'application/json'}})
    }
    submitformaccount(obj:any){
      const url = this.occ.buildUrl(`${this.fetchUserUrl()}/my-profile/account-Form?fields=FULL`);
      return this.http.post(url,obj,{headers:{'Content-Type':'application/json'}})
    }
    changelocationpermssion(obj: any){
      const url = this.occ.buildUrl(`${this.fetchUserUrl()}/saveChangedLocPermissionData?fields=FULL`);
      return this.http.post(url,obj,{headers:{'Content-Type':'application/json'}})
    }
    sendunlink(value:string){
      this.unlinkaccount$.next(value);
    }
    getunlink(){
      return this.unlinkaccount$.asObservable();
    }
    setRouteLink(route: string){
      this.routeLink$.next(route);
    }
    getRouteLink(){
      return this.routeLink$.asObservable();
    }
    setQueryParams(params: any){
      this.queryParams$.next(params);
    }
    getQueryParams(){
      return this.queryParams$.asObservable();
    }
    setInPlaceOrder(value: Boolean){
      this.inPlaceOrder$.next(value);
    }
    getInPlaceOrder(){
      return this.inPlaceOrder$.asObservable();
    }
    setSelectedAccount(value: string){
      this.selectedAccount$.next(value);
    }
    getSelectedAccount(){
      return this.selectedAccount$.asObservable();
    }
    setFromFooter(value: Boolean){
      this.fromFooter$.next(value);
    }
    getFromFooter(){
      return this.fromFooter$.asObservable();
    }

//for userdata
updateUserdata() {
  const url = this.occ.buildUrl(`${this.fetchUserUrl()}`);
  this.http.get(url).subscribe((data:any)=>{
    data.webEnabled = data.orgUnit.uid !== 'SeqirusUK';
    this.setUserData(data);
  })
}

sendCustomerNotification(value: any) {
  const url = this.occ.buildUrl("/users/anonymous/customerNotification");
  const params = new HttpParams().set('emailId',value);
  return this.http.post(url,{},{params});
}

submitRefundData(refundData:RefundData){
  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/financials/submitRefunds`);
  return this.http.post(url,refundData,{headers:{'Content-Type':'application/json'}})

}

getScheduledPayments() {
  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/financials/scheduled-payment`);
  return this.http.get(url);
}

reschedulePayment(invoiceData: any) {
  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/financials/modify-scheduled-payment`);
  return this.http.post(url,invoiceData,{headers:{'Content-Type':'application/json'}})

}updateAppliedCreditDetails(appliedCreditDetails:AppliedCreditDetails){
  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/financials/updateAppliedCreditsDetails`);
  return this.http.post(url,appliedCreditDetails, {headers:{'Content-Type':'application/json'}})
}
getIframeForSchedulePayment(data:any) {
  //map new api
  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/financials/schedulePaymenIngenico?fields=FULL`);
  return this.http.post(url,data,{headers:{'Content-Type':'application/json'}})
}

checkScheduledPaymentStatus(obj: any, id:any): Observable<any> {
  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/financials/scheduled-payment-status?fields=FULL&hostedCheckoutId=${id}&isFramed=true`);
  return this.http.post(url,obj,{headers:{'Content-Type':'application/json'}})
}

isSavedCardUsed(selectedtoken: string):Observable<boolean> {
   const url = this.occ.buildUrl(`${this.fetchUserUrl()}/financials/has-scheduled-payment?fields=FULL&token=${selectedtoken}`);
    return this.http.get<boolean>(url);
      
}


getAsAgentDetail(){
  const url = this.occ.buildUrl(`/asm/getAsmDetails`);
  return this.http.get(url,{ responseType: 'text' });

}

checkAsmUser(): boolean {
      
  this.asmEnabled = localStorage.getItem('showASM') === 'true';
  this.csAgentAuthService.isCustomerSupportAgentLoggedIn().subscribe(i => {
      this.asmAgentLoggedIn = i;
  });
  this.csAgentAuthService.isCustomerEmulated().subscribe(res => {
      this.customerEmulated = res;
  });
  if(this.asmEnabled && this.asmAgentLoggedIn && this.customerEmulated) {
    return true;
  }
    return false;
}
fetchUserDataForASM(): Observable<User | undefined> {
    
    let url = this.occ.buildUrl(this.fetchUserUrl());
    return this.http.get(url);
    
} 
resetasmpassword(payload:any):Observable<any>{
  const url = this.occ.buildUrl(`/login/pw/change`);
  return this.http.post(url,payload,{headers:{'Content-Type':'application/json'}})
}

getBatchNoDetailOfNdc(ndcNum: any) {
  
  return this.authService.isUserLoggedIn().pipe(
    switchMap((isLoggedIn: boolean) => {
      let url: string;
      if (isLoggedIn) {
        url = this.occ.buildUrl(`${this.fetchUserUrl()}/returnsOrders/getbatchNumbersforNDC/?fields=FULL&ndcNumber=${ndcNum}`);
      } else {
        url = this.occ.buildUrl(`users/anonymous/returnsOrders/getbatchNumbersforNDC/?fields=FULL&ndcNumber=${ndcNum}`);
      }
      return this.http.get(url);
    })
  );
}

getUserDetailsForReturns() {
  let url = this.occ.buildUrl(`${this.fetchUserUrl()}/returnsOrders/USReturnsLocDetails`);
  return this.http.get(url);
}

getPORefNumberForReturns() {
  let url = this.occ.buildUrl("/SeqirusPDF/generatePOforReturns?fields=DEFAULT");
  return this.http.get(url,{ responseType: 'text' } );   
}

sendReturnsPopupNotShow(val1:any,val2:any) {
  let url = this.occ.buildUrl(`${this.fetchUserUrl()}/my-account/updateReturnsPopup`);
  let params = new HttpParams().set('returnfirstpopupshow',val1).set('returnSecondpopupshow',val2)
  return this.http.post(url,{},{params});
}
emailapi(){
  let url = this.occ.buildUrl(`${this.fetchUserUrl()}/my-profile/sendProfileUpdateEmail`);
  return this.http.get(url);
}


}
