import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CmsService, OccConfig } from '@spartacus/core';
import { combineLatest, map, Observable } from 'rxjs';
import { ContentService } from '../spartacus/services/content.service';

@Component({
  selector: 'app-prescribing-information-uk',
  templateUrl: './prescribing-information-uk.component.html',
  styleUrl: './prescribing-information-uk.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class PrescribingInformationUkComponent {
  headerItems$ : Observable<any> = this.cmsService.getComponentData('SeqirusPrescribingFirstComp');
  contentItems$ : Observable<any> = this.cmsService.getComponentData('PrescribingDetailInfoMainComponent');
  prescribingProducts: any = [];
  contentProducts: any = [];
  sectionIds: string[] = ['aqivsection', 'qivcsection', 'aTIVsection', 'TIVcsection'];

  constructor(private accessService: ContentService,private cmsService:CmsService, protected config: OccConfig, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.headerItems$.subscribe(res => {
      this.productTabs(res);
    })

    this.contentItems$.subscribe(res => {
      this.contentTabs(res);
    })
  }

  productTabs(res: any) {
    const prodList = res.prescribingList.split(" ");
    const observables = prodList.map((el: any) => this.accessService.opeffeaturedResources1(el));

    combineLatest(observables).pipe(
      map((results:any) => results.flat())
    ).subscribe((mergedResults: any) => {
      this.prescribingProducts = mergedResults;
      this.cd.detectChanges();
    });
  }

  contentTabs(res: any) {
    const contList = res.PDetailedList.split(" ");
    const observables = contList.map((el: any) => this.accessService.opeffeaturedResources1(el));

    combineLatest(observables).pipe(
      map((results:any) => results.flat())
    ).subscribe((mergedResults: any) => {
      this.contentProducts = mergedResults;
      this.cd.detectChanges();
    })
  }

  scrollToSection(sectionId: string, event: Event) {
    event.preventDefault();
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
}