<div *ngIf="!userLoggedIn || (userLoggedIn && (!webEnabled))">
    <div class="LO_allProduct col-xs-12 prodLanding_prodSectionUK" id="prodLanding_prodSection"
        *ngIf="topheadline | async  as item">
        <div class="col-xs-12 col-md-6 prodLanding_prodCol">
            <div class="col-xs-12 prod_content">
                <div class="col-xs-12 prodLanding_prodimg" id="prodLanding_prodimg1">
                    <img [src]="getImage(item.media)" alt="aQIV Product Logo">
                    <div class="col-xs-12 prodLanding_prodheader no-pad">{{item.h2content}}</div>
                    <div class="col-xs-12 prodLanding_prodcontent no-pad" [innerHTML]="item.content"></div>
                </div>
            </div>
            <div class="col-xs-12" id="prodLanding_learnmorebtn"><a (click)="saveUrlAndRedirect(item.largeparagraphcontent, item.materialSubHeading)">{{item.paragraphcontent}}</a></div>
        </div>
    </div>
</div>

<!-- <div *ngIf="userLoggedIn">
    <div class="LI_allProduct col-xs-12 col-md-6 prodLanding_prodSectionUK" id="prodLanding_prodSection"
        *ngIf="topheadline | async  as item">
        <div class="col-xs-12 prodLanding_prodCol">
            <div class="col-xs-12 col-md-6 prod_content">
                <div class="col-xs-12 prodLanding_prodheader no-pad">{{item.headline}}</div>
                <div class="col-xs-12 prodLanding_prodcontent no-pad" [innerHTML]="item.content"></div>
                <div class="col-xs-12" id="prodLanding_learnmorebtn">
                    <a [innerHTML]="item.h3content"></a>
                </div>
            </div>
            <div class="col-xs-12 col-md-6 prodLanding_prodimg" id="prodLanding_prodimg1">
                <img [src]="getImage(item.media)" alt="aQIV Product Logo">
            </div>
        </div>
    </div>
</div> -->
<div *ngIf="userLoggedIn && webEnabled" class="LI_allProduct">
    <div class="product-3col" *ngIf="topheadline | async  as item">
        <!--flu vaccination campaign goal poster ends -->
        <div class="product-3col__body product-3col__bodyUK marginUK" id="product_row1">
            <div class="product-3col__item col-md-7 product-3col__itemUK">
                <h3>{{item.headline}}</h3>
                <p [innerHTML]="item.content"></p>
                <div class="btnParent">
                    <a class="no-underline" [routerLink]="item.largeparagraphcontent" [innerHTML]="item.h3content"></a>
                </div>
            </div>
            <div class="product-3col__item col-md-5 product-3col__itemUK">
                <img alt="Flucelvax Quadrivalent logo" [src]="getImage(item.media)" alt="aQIV Product Logo">
            </div>
        </div>
        <div class="speedbump__content"></div>
    </div>
</div>