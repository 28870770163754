import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { BaseSiteService, CmsNavigationComponent, OccConfig, OccEndpointsService } from '@spartacus/core';
import { CmsComponentData, NavigationNode, NavigationService } from '@spartacus/storefront';
import { Observable, map } from 'rxjs';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { ContentService } from '../../spartacus/services/content.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-custom-left-navigation',
  templateUrl: './custom-left-navigation.component.html',
  styleUrl: './custom-left-navigation.component.scss'
})
export class CustomLeftNavigationComponent implements OnInit,AfterViewInit{
  currentSite: string="";
  flucelvaxPdf!: Observable<any>;
  fluadPdf!: Observable<any>;
  afluriaPdf!: Observable<any>;
  prescribingFluadPdf: any;
  prescribingFlucelvaxPdf: any;
  prescribingAfluriaPdf: any;
  constructor(public component: CmsComponentData<CmsNavigationComponent>,
    private userInfoService:UserInfoService,protected contentService: ContentService,
    protected service: NavigationService,private cd:ChangeDetectorRef,
    private usersrvice:UserInfoService,
    private gtmService: GoogleTagManagerService,
    protected config: OccConfig, private router: Router, private route: ActivatedRoute,private renderer: Renderer2, private el: ElementRef,private baseSiteService:BaseSiteService,private occEndPointsService: OccEndpointsService) { }
  bannerNode: Observable<NavigationNode | null | undefined | any> = this.service.createNavigation(
    this.component?.data$
  );
  iconClass: string = "fa fa-bars";
  isOpen: boolean = false;
  glyphiconIcon = "glyphicon-menu-down";
  userInfo: any;

  afluriaurl: any;
  fluadurl: any;
  flucelvaxurl: any;
  rbaflag:any;

  fetchPIPdfData(){
    // if(this.currentSite == 'seqirusb2b-flu360'){
    //   this.afluriaPdf= this.contentService.getNavigation("AfluriaHeaderLink");

    //   this.fluadPdf= this.contentService.getNavigation("FluadHeaderLink");

    //   this.flucelvaxPdf= this.contentService.getNavigation("FlucelvaxHeaderLink");
    //   this.afluriaPdf.pipe(map(val => val.media?.url)).subscribe(res => {
    //     this.afluriaurl = res;
    //   });
    //   this.fluadPdf.pipe(map(val => val.media?.url)).subscribe(res => {
    //     this.fluadurl = res;
    //   });
    //   this.flucelvaxPdf.pipe(map(val => val.media?.url)).subscribe(res => {
    //     this.flucelvaxurl = res;
    //   });
    // }
    if(this.currentSite == 'seqirusb2b-flu360'){
    this.contentService.getpdfFlucelvax().subscribe(res =>{
      this.flucelvaxurl =res ;
      this.cd.detectChanges();
    })
    this.contentService.getpdffluad().subscribe(res =>{
      this.fluadurl =res ;
      this.cd.detectChanges();
    })
    this.contentService.getpdfafluria().subscribe(res =>{
      this.afluriaurl =res ;
      this.cd.detectChanges();
    })
  }
   }
   isUserLoggedIn: boolean=false;
  ngOnInit(){
    this.baseSiteService.getActive().subscribe(siteId => {
      this.currentSite = siteId;
    
    })
    this.usersrvice.userLoggedIn$.subscribe(res=>{
      this.isUserLoggedIn=res;
    });
   

    const loginForm = {
      'event' : 'add_iddata',
      'cdcCust' :  localStorage.getItem('cdcCust'),//to be replaced with the CDC UID
      'stCust' :  localStorage.getItem('sapcustomerid')  //this number must be replaced with the SAP Sold to ID only if it exists };
      }
  
   this.gtmService.pushTag(loginForm);
   
   
    this.fetchPIPdfData();
    this.userInfoService.getMobileHeader().subscribe((res)=>{
      this.isOpen=res;
      if(this.isOpen){
        const temp= <HTMLElement> document.getElementsByClassName("SideContent")[0];
        if(temp){
          temp.style.width='100%';
        }
        
      }
      this.cd.detectChanges();
    })
   
    // if(this.currentSite == 'seqirusb2b-flu360'){
    // this.userInfoService.getFluadPdf().subscribe(res => {
    //   this.prescribingFluadPdf = this.occEndPointsService.buildUrl(res);
    // })
    // this.userInfoService.getFlucelvaxPdf().subscribe(res => {
    //   this.prescribingFlucelvaxPdf = this.occEndPointsService.buildUrl(res);
    // })
    // this.userInfoService.getAfluriaPdf().subscribe(res => {
    //   this.prescribingAfluriaPdf = this.occEndPointsService.buildUrl(res);
    // });
     // }
  if(this.currentSite != 'seqirusb2b-flu360'){
    this.rbafunction();
  }
    
    this.cd.detectChanges();
  }
  
  goMyprofile(){
    this.router.navigate(['/my-profile']).then(() => {
      window.location.reload();
    });
  }
  handleIconToggle(node: any) {
    if (node.title == 'Prescribing Information') {
      node.children[0].url = this.fluadurl;
      node.children[1].url =this.flucelvaxurl; 
      node.children[2].url = this.afluriaurl;
      this.cd.detectChanges();
    }
    this.cd.detectChanges();
  }
  getImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.url;
  }
  geturl(url: any): string {
    return this.config.backend?.occ?.baseUrl + url;
  }
  
  isActive(url: string): boolean { 
    if (!url) {
      return false;
    }
    const currentUrlSegment = this.router.url.split('/').pop();
  const nodeUrlSegment = url.split('/').pop();
  return currentUrlSegment === nodeUrlSegment;
    
  // return this.router.url === url || this.router.url.includes(url); 
  }

  ngAfterViewInit() {
    
    setTimeout(()=>{ 
    const childElements = this.el.nativeElement.querySelectorAll('.active');
    childElements.forEach((child: HTMLElement) => {
      
        const parent = child.closest('.collapse');
        if (parent) {
          this.renderer.addClass(parent, 'show');
        }
     
    });
  }, 5000);

  this.userInfoService.getrbachnage().subscribe(value => {
    if(value){
      this.afterloadrbafunction();
    }
  });
  }
  
  exp:boolean=false;
  isExpanded(){
    this.exp=!this.exp;
  }

  updateUserData() {
    if((!this.router.url.includes('homepage') && !this.router.url.includes('login')) || this.currentSite=='seqirusb2b-flu360' || this.currentSite=='seqirusb2b-uk'){
     this.userInfoService.userLoggedIn$.subscribe(res=>{
       this.isUserLoggedIn=res;
       if(this.isUserLoggedIn){
         this.userInfoService.getUserData().subscribe(res=>{
           if(res){
           this.userInfo=res;
           }
         })
       }
       else{
       this.cd.detectChanges();
       }
     })
     }
   }
   rbafunction(){
    if(this.currentSite=='seqirusb2b-uk' && this.isUserLoggedIn ){


      this.userInfoService.rbasiteflag().subscribe(data=>{
     
       this.rbaflag=data;

       if(!localStorage.getItem("rbapermission")){
        this.userInfoService.setswitchrba(this.rbaflag);
       }
       localStorage.setItem("rbapermission",JSON.stringify(this.rbaflag))
     
       
       this.cd.detectChanges();
      
        
   
      });
     
    }
 
   }
   afterloadrbafunction(){
    if(this.currentSite=='seqirusb2b-uk' && this.isUserLoggedIn ){
      this.userInfoService.rbasiteflag().subscribe(data=>{
     
       this.rbaflag=data;
       localStorage.setItem("rbapermission",JSON.stringify(this.rbaflag))
   
       this.userInfoService.setrbaheader(this.rbaflag)
       this.cd.detectChanges();
      
        
   
      });
     
    }
 
   }

   handleLinks(route: string, params: any){
    const href = window.location.href;
    let inPlaceOrder:any;
    this.userInfoService.getInPlaceOrder().subscribe(val => {
      inPlaceOrder = val;
    })
    if((href.includes("start-order") || href.includes("register")) && inPlaceOrder){
      this.userInfoService.setRouteLink(route);
      this.userInfoService.setQueryParams(params);
      this.userInfoService.setPopUpValue(true);
    }
    else {
      this.router.navigate([route], { queryParams: params}).then(() => {
        window.location.reload();
      });;
    }
   }

}


