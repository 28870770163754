<!-- <ng-container *ngIf="currentSite === 'seqirusb2b-flu360'">
<div class="col-xs-12 dashborad_anoucementssection dashborad_anoucementssection">
    <strong [innerHTML]="this.headline"></strong></div>
    <div class="col-xs-12 margin-T25 dashborad_announceleftcontent"  [innerHTML]="this.largeparagraphcontent"></div>
    <div class="col-xs-12 margin-T25 dashborad_readmore" (click)="routerlinkClicked($event)" [innerHTML]="this.startOrder">
    </div>
</ng-container>

<ng-container *ngIf="currentSite === 'seqirusb2b-uk'">
    <div class="col-xs-12 col-md-6  dashborad_announceleftheader dashborad_announceleftheaderuk">
        <div class="row">
    <div class="col-xs-12 dashborad_anoucementssection dashborad_anoucementssectionuk">{{headline}}</div>
        <div class="col-xs-12 margin-T25 dashborad_announceleftcontentuk" [innerHTML]="this.largeparagraphcontent"></div>
        <div class="col-xs-12 margin-T25 dashborad_Exploreuk dashboardtoomsuk" [innerHTML]="this.startOrder">
    </div>
</div>
    <div class="dashboarddocnumberUk">{{jobbagcode}}</div>
    </div>
</ng-container>

<div class="col-xs-12 margin-T25 dashboard_membership">
     
 <div class="col-xs-12 margin-T25 dashboard_membershipHeader">Membership</div>
    <div class="col-xs-12 dashboard_membershipBody">Please update or confirm your current membership for the season in <a href="/my-profile">My Profile</a>.</div>
</div>   -->
<ng-container *ngIf="currentSite === 'seqirusb2b-flu360'">
    <div class="col-xs-12 dashborad_anoucementssection dashborad_anoucementssection">
      <strong [innerHTML]="this.headline"></strong>
    </div>
    <div class="col-xs-12 margin-T25 dashborad_announceleftcontent" [innerHTML]="this.largeparagraphcontent"></div>
    <div class="col-xs-12 margin-T25 dashborad_readmore" (click)="routerlinkClicked($event)" [innerHTML]="this.startOrder"></div>
  </ng-container>
  
  <ng-container *ngIf="currentSite === 'seqirusb2b-uk'">
    <div class="col-xs-12 col-md-6 dashborad_announceleftheader dashborad_announceleftheaderuk">
      <div class="row">
        <div class="col-xs-12 dashborad_anoucementssection dashborad_anoucementssectionuk">{{headline}}</div>
        <div class="col-xs-12 margin-T25 dashborad_announceleftcontentuk" [innerHTML]="this.largeparagraphcontent"></div>
        <div class="col-xs-12 dashborad_announceleftcontentuk_image">
          <a [routerLink]="this.link">
            <img [src]="getImage(this.image)" [attr.title]="this.image.altText">
          </a>
        </div>
        <div class="col-xs-12 margin-T25 dashborad_Exploreuk dashboardtoomsuk" *ngIf="rbavalue?.showDashboardOrderUK==true">
          <a [routerLink]="this.link" [innerHTML]="this.startOrder"></a>
        </div>
      </div>
      <div class="dashboarddocnumberUk">{{jobbagcode}}</div>
    </div>
  </ng-container>
  
  <ng-container *ngIf="currentSite === 'seqirusb2b-flu360' && !membershipDeclarationFLag">
    <div class="col-xs-12 margin-T25 dashboard_membership">
      <div class="col-xs-12 margin-T25 dashboard_membershipHeader">Membership</div>
      <div class="col-xs-12 dashboard_membershipBody">
        Please update or confirm your current membership for the season in
        <a href="/my-profile">My Profile</a>.
      </div>
    </div>
  </ng-container>
 
  