<div class="account-section-content createprofile_cdccontent col-xs-12 col-md-12 margin-T10"
    *ngIf="userGuide | async  as item">
    <div class="col-xs-12 col-md-12 createprofile_helptext">
        <div *ngIf="!userLoggedIn && item.uid=='SeqirusCreateProfileUserGuidePDFComponent'" id="breadcrumb" class="col-xs-12 col-md-6">
            <ol class="breadcrumb">
                <li><a href="/">Home</a></li>
               &nbsp; &gt; &nbsp;
                <li class="bc_bold">Create Account</li>
            </ol>
            <!-- <a [routerLink]="['/profileverification']">verify profile</a> -->
        </div>
        <div *ngIf="!userLoggedIn && item.uid=='SeqirusEmailVerificationNeedHelpComponent'" id="breadcrumb-emailverification" class="col-xs-12">
            <ol class="breadcrumb">
                <li><a href="/">Home</a></li>
               &nbsp; &gt; &nbsp;
                <li class="bc_bold">Create Account</li>
            </ol>
        </div>
        <div *ngIf="!userLoggedIn">
            <div class="global_breadcrumbs_helpnfaq col-md-6 col-lg-6 col-xs-12 d-none"><span
                    class="global_breadcrumblink">
                    <a [routerLink]="'/'">Home</a></span>&gt;<span class="global_breadcrumbactive">Help &amp; FAQs</span>
            </div>
        </div>
        <div class="userguideparent">
            <span class="userguidegrey">{{item.headline}} </span>
            <a class="userguideanchor" [href]="getImage(item.media)" target="_blank"><span
                    class="userguideblack anchorunderline">{{item.h2content}}</span>
                <div class="global_blackarrow black_arrow_class"></div>
            </a>
        </div>
        <div *ngIf="!userLoggedIn">
            <div class="global_breadcrumbs_helpnfaq_mobile col-md-12 col-lg-12 col-xs-12 d-none"><span
                    class="global_breadcrumblink">
                    <a [routerLink]="'/'">Home</a></span>&gt;<span class="global_breadcrumbactive">Help &amp; FAQs</span>
            </div>
        </div>
    </div>
</div>