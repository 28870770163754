import { Component } from '@angular/core';
import { BaseSiteService, CmsBannerComponent, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-overview-laptop',
  templateUrl: './overview-laptop.component.html',
  styleUrl: './overview-laptop.component.scss'
})
export class OverviewLaptopComponent {
  constructor(public component: CmsComponentData<CmsBannerComponent>,protected config: OccConfig,protected baseSiteService: BaseSiteService){}
  bannerNode: Observable<any> = this.component.data$;
  currentSite:any;
  getImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.desktop.url;
  }
  getcodingnbillingimage(image: any):string{
    return this.config.backend?.occ?.baseUrl + image.url;
  }

  getpdf(pdf: any): string {
    return this.config.backend?.occ?.baseUrl + pdf?.url + "&attachment=true";
  }
  
  ngOnInit(){
    this.baseSiteService.getActive().subscribe(site => {
      this.currentSite = site;
      //console.log(site);
    });
  }
}
