import { ChangeDetectorRef, Component, ElementRef, HostListener, NgZone, Renderer2, ViewChild  } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CmsComponentData } from '@spartacus/storefront';
import { ContentService } from '../../spartacus/services/content.service';
import { FormBuilder, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, map } from 'rxjs';
import { CmsService } from '@spartacus/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { RegistrationUkService } from '../registration-uk.service';
import { UserInfoService } from '../../spartacus/services/user-info.service';
export function alphanumericValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const valid = /^[A-Za-z0-9]*$/.test(control.value);
    return valid ? null : { 'alphanumeric': true };
  };

}
@Component({
  selector: 'app-registration-uk',
  templateUrl: './registration-uk.component.html',
  styleUrl: './registration-uk.component.scss'
})

export class RegistrationUKComponent {
  tradingYears: any;
  businessTypes: { value: string, display: string }[] = [];
  companyTypes: string[] = [];
  tradingSinceYears: string[] = [];
  bussinessDetails:FormGroup;
  billingDetails:FormGroup;
  payerDetails:FormGroup;
  shippingForm: FormGroup;
  isFormSubmitted_bussiness:boolean=false;
  isFormSubmitted_billing:boolean=false;
  isFormSubmitted_paying:boolean=false;
  isFormSubmitted_shipping:boolean=false;
  emailPattern: RegExp;
  maxLength: number | undefined;
  callFetchifyMethod:any;
  userData: any;
  formDataBussiness:any;
  formDataBilling:any;
  formDataPaying:any;
  showBussinessdetails:boolean = true;
  showInvoicedetails:boolean = false;
  showPayingdetails:boolean = false;
  showShippingdetails:boolean = false;
  showReviewdetails:boolean = false
  showloader: boolean = false;
  selectedBusinessType: string = '';
  formattedBusinessType: string = '';


  constructor(private userInfoService: UserInfoService,public registrationUkService: RegistrationUkService,public component: CmsComponentData<any>,private cmsService:CmsService,private fb: FormBuilder,public contentService: ContentService,private cdr:ChangeDetectorRef,private renderer: Renderer2,private ngZone: NgZone,private router: Router) {
    this.emailPattern = new RegExp("^(?=(.{1,64}@.{1,255}))([!#$%&'*+\\-\\/=?\\^_`{|}~a-zA-Z0-9]{1,64}(\\.[!#$%&'*+\\-\\/=?\\^_`{|}~a-zA-Z0-9]{0,}){0,})@((\\(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}\\)|([a-zA-Z0-9-]{1,63}(\\.[a-zA-Z0-9-]{2,63}){1,}))$");
    this.bussinessDetails =  new FormGroup({
      addressType: new FormControl("Business"),
      orgName: new FormControl("",[Validators.required,Validators.pattern("^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$")]),
      orgRegNo: new FormControl("",[Validators.pattern('^[0-9]+$'),Validators.minLength(7)]),
      vatNo: new FormControl(""),
      tradingSince: new FormControl("",[Validators.required]),
      orgType: new FormControl("",[Validators.required]),
      nhsCode: new FormControl("", [Validators.pattern('^[A-Za-z0-9]*$'), alphanumericValidator()]),
      firstName: new FormControl("",[Validators.required,Validators.pattern('^[a-zA-Z]+$')]),
      lastName: new FormControl("",[Validators.required,Validators.pattern('^[a-zA-Z]+$')]),
      jobTitle: new FormControl("",[Validators.required]),
      emailId: new FormControl("",[Validators.required,Validators.pattern(this.emailPattern)]),
      phoneNo: new FormControl("",[Validators.required,Validators.pattern('^[0-9]+$'),Validators.minLength(10)]),
      phoneExt: new FormControl("",[Validators.pattern('^[0-9]+$')]),
      line1: new FormControl("",[Validators.required]),
      line2: new FormControl(""),
      city: new FormControl("",[Validators.required]),
      country: new FormControl("",[Validators.required]),
      state: new FormControl(""),
      postalCode: new FormControl("",[Validators.required,Validators.pattern('^(([A-Za-z0-9]){2}([ ]){1}([A-Za-z0-9]){3}|([A-Za-z0-9]){3}([ ]){1}([A-Za-z0-9]){3}|([A-Za-z0-9]){4}([ ]){1}([A-Za-z0-9]){3})$')]),
    })

    this.billingDetails =  new FormGroup({
      addressType: new FormControl("Billing"),
      addressId: new FormControl(""),
      confAcctStmtEmail: new FormControl(""),
      confInvoiceEmail: new FormControl(""),
      country: new FormControl("",[Validators.required]),
      firstName: new FormControl("",[Validators.required,Validators.pattern("^[A-Za-z\\s]+$")]),
      lastName: new FormControl("",[Validators.required,Validators.pattern("^[A-Za-z\\s]+$")]),
      email: new FormControl("",[Validators.required,Validators.pattern(this.emailPattern)]),
      phone: new FormControl("",[Validators.required,Validators.pattern('^[0-9]+$'),Validators.minLength(10)]),
      phoneExt: new FormControl("",[Validators.pattern('^[0-9]+$')]),
      orgName: new FormControl("",[Validators.required,Validators.pattern("^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$")]),
      line1: new FormControl("",[Validators.required]),
      line2: new FormControl(""),
      city: new FormControl("",[Validators.required]),
      state: new FormControl(""),
      postalCode: new FormControl("",[Validators.required,Validators.pattern('^(([A-Za-z0-9]){2}([ ]){1}([A-Za-z0-9]){3}|([A-Za-z0-9]){3}([ ]){1}([A-Za-z0-9]){3}|([A-Za-z0-9]){4}([ ]){1}([A-Za-z0-9]){3})$')]),
      invoiceEmail:  new FormControl("",[Validators.pattern(this.emailPattern)]),
      acctStmtEmail: new FormControl("",[Validators.pattern(this.emailPattern)]),
      optNotifyAcctStmtEmail: new FormControl(false),
      optNotifyInvoiceEmail: new FormControl(false),
      isSameAsShippingAddress: new FormControl(false),
      optNotifyAcctStmtMail: new FormControl(true),
      optNotifyInvoiceMail: new FormControl(true),

    })

    this.payerDetails =  new FormGroup({
      addressType: new FormControl("Paying"),
      addressId: new FormControl(""),
      confAcctStmtEmail: new FormControl(""),
      confInvoiceEmail: new FormControl(""),
      country: new FormControl("",[Validators.required]),
      firstName: new FormControl("",[Validators.required,Validators.pattern("^[A-Za-z\\s]+$")]),
      lastName: new FormControl("",[Validators.required,Validators.pattern("^[A-Za-z\\s]+$")]),
      email: new FormControl("",[Validators.required,Validators.pattern(this.emailPattern)]),
      phone: new FormControl("",[Validators.required,Validators.pattern('^[0-9]+$'),Validators.minLength(10)]),
      phoneExt: new FormControl("",[Validators.pattern('^[0-9]+$')]),
      orgName: new FormControl("",[Validators.required,Validators.pattern("^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$")]),
      line1: new FormControl("",[Validators.required]),
      line2: new FormControl(""),
      city: new FormControl("",[Validators.required]),
      state: new FormControl(""),
      postalCode: new FormControl("",[Validators.required,Validators.pattern('^(([A-Za-z0-9]){2}([ ]){1}([A-Za-z0-9]){3}|([A-Za-z0-9]){3}([ ]){1}([A-Za-z0-9]){3}|([A-Za-z0-9]){4}([ ]){1}([A-Za-z0-9]){3})$')]),
      invoiceEmail:  new FormControl("",[Validators.pattern(this.emailPattern)]),
      acctStmtEmail: new FormControl("",[Validators.pattern(this.emailPattern)]),
      optNotifyAcctStmtEmail: new FormControl(false),
      optNotifyInvoiceEmail: new FormControl(false),
      isSameAsShippingAddress: new FormControl(false),
      optNotifyAcctStmtMail: new FormControl(true),
      optNotifyInvoiceMail: new FormControl(true),

    })

    this.shippingForm = this.fb.group({
      shippingList: this.fb.array([this.createShippingForm()])
    });
    
  }

  get shippingList(): FormArray {
    return this.shippingForm.get('shippingList') as FormArray;
  }

  createShippingForm(): FormGroup {
    return this.fb.group({
      addressType: ['Shipping'],
      firstName: new FormControl("",[Validators.required,Validators.pattern("^[A-Za-z\\s]+$")]),
      lastName: new FormControl("",[Validators.required,Validators.pattern("^[A-Za-z\\s]+$")]),
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      phone: new FormControl("",[Validators.required,Validators.pattern('^[0-9]+$'),Validators.minLength(10)]),
      phoneExt: new FormControl("",[Validators.pattern('^[0-9]+$')]),
      globalLocationNumber: [''],
      addressId: [''],
      postalCode: new FormControl("",[Validators.required,Validators.pattern('^(([A-Za-z0-9]){2}([ ]){1}([A-Za-z0-9]){3}|([A-Za-z0-9]){3}([ ]){1}([A-Za-z0-9]){3}|([A-Za-z0-9]){4}([ ]){1}([A-Za-z0-9]){3})$')]),
      licenseDetailsForm: this.fb.group({
        licenseName: ['',[Validators.required,Validators.pattern("^[A-Za-z\\s]+$")]],
        licenseNum: ['',[Validators.required,Validators.pattern('^[0-9]+$'),Validators.minLength(7)]],
        licAddressLine1: [''],
        licAddressLine2: [''],
        licensePostalCode: [''],
        licenseCity: [''],
        licenseState: [''],
        nhsCode: ['',[Validators.pattern('^[0-9]+$')]],
        isMedProLicenseVerified: ['']
      }),
      line1: new FormControl("",[Validators.required]),
      line2: new FormControl(""),
      city: new FormControl("",[Validators.required]),
      country: new FormControl("",[Validators.required]),
      orgName: new FormControl("",[Validators.pattern("^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$")]),
      state: ['']
    });
  }

  onBusinessTypeChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedBusinessType = selectElement.value;
  }
  isAnyFormEditing: boolean = false;
  isAddingNewForm: boolean = false;
  
  removeShippingForm(index: number) {
    this.shippingList.removeAt(index);
    const isLastItem = index === this.shippingList.length - 1;
    if (this.shippingList.length < 1) {
      this.addShippingForm();
      this.showLocationcount = false;
      this.showCheckbox = true;
      this.cdr.detectChanges();
    }

  }

  checkFormValidity(index: number) {
    const shippingFormGroup = this.shippingList.at(index) as FormGroup;
    return shippingFormGroup.valid;
  }

  addAnotherLocation(index: number) {
    const shippingFormGroup = this.shippingList.at(index) as FormGroup;
    if (shippingFormGroup.valid) {
      shippingFormGroup.addControl('submitted', new FormControl(true));
      shippingFormGroup.addControl('isEditing', new FormControl(false));
      this.modifyForm(index, shippingFormGroup.value);
      this.showCheckbox = false;
    } else {
      this.isFormSubmitted_shipping = true;
    }
  }
  
  modifyForm(index: number, formDataShipping: any) {
    const shippingForm = this.shippingList.at(index) as FormGroup;
    shippingForm.get('isFormSubmitted')?.setValue(true);
    if (shippingForm.valid) {
      shippingForm.patchValue(formDataShipping);
      shippingForm.get('submitted')?.setValue(true);
      shippingForm.get('isEditing')?.setValue(false);
      this.isAnyFormEditing = false;
      this.scrollTotop();
    }
  }

  toggleButtonVisibility(index: number) {
    const shippingFormGroup = this.shippingList.at(index) as FormGroup;
    return shippingFormGroup.get('submitted')?.value;
  }
 
  toggleShipping(index: number) {
    this.isAnyFormEditing = true;
    this.showLocationcount = true;
    this.shippingList.controls.forEach((control, i) => {
      if (i === index) {
        control.get('isEditing')?.setValue(true);
        const newFieldId = 'shipping_lookup' + index;
        setTimeout(() => {
          this.attachFetchifyMethod(index, newFieldId);
          this.cdr.detectChanges();
        }, 3000);
      } else {
        control.get('isEditing')?.setValue(false);
      }
    });
  }

  jobTitle = [
    { value: 'Practice Manager', label: 'Practice Manager' },
    { value: 'Doctor', label: 'Doctor' },
    { value: 'Pharmacist', label: 'Pharmacist' },
    { value: 'Pharmacy Technician', label: 'Pharmacy Technician' },
    { value: 'Nurse Practitioner', label: 'Nurse Practitioner' },
    { value: 'Nurse', label: 'Nurse' },
    { value: 'Purchasing/Procurement', label: 'Purchasing/Procurement' },
    { value: 'Finance Manager', label: 'Finance Manager' },
    { value: 'Receptionist', label: 'Receptionist' },
    { value: 'Operations Manager', label: 'Operations Manager' },
    { value: 'Healthcare Assistant', label: 'Healthcare Assistant' },
    
  ];
  uid: string | null = null;
  errorMessage: string | null = null;
  ngOnInit(): void {
    const body=<HTMLElement>document.querySelector("body");
    body.classList.remove('modal-open')
      this.userInfoService.setInPlaceOrder(true);
    this.callFetchifyMethod= this.registrationUkService.fetchifyCallUK();
    this.registrationUkService.getUserData().subscribe(
      data => {
        this.userData = data;
      },
      error => {
        console.error('Error fetching user data', error);
        this.errorMessage = 'Error fetching user data';
      }
    );
   
    this.registrationUkService.getTradingYears().subscribe(data => {
      this.businessTypes = data.businessTypes.map((type: string) => ({
        value: type,
        display: this.formatType(type)
      }));
      this.companyTypes = data.companyTypes;
      this.tradingSinceYears = data.tradingSinceYears;
      this.cdr.detectChanges();
    });

   
    this.bussinessDetails.get('orgType')?.valueChanges.subscribe((value: string) => {
      this.setValidationRules(value);
    });
    
  }
  formatType(type: string): string {
    const formattedType = type.split('_').map(word => word.charAt(0) + word.slice(1).toLowerCase()).join(' ');
    switch (type) {
      case 'GENERAL_PRACTICE':
        return 'GP Medical Practice';
      case 'HOSPITAL_PUBLIC':
        return 'Hospital - Public';
      case 'HOSPITAL_PRIVATE':
        return 'Hospital - Private';
      default:
        return formattedType;
    }
  }
  
  ngAfterViewInit() {
    setTimeout(()=>{

      this.shippingList.controls.forEach((group, index) => {
        const newFieldId = 'shipping_lookup' + index;
        this.attachFetchifyMethod(index,newFieldId);
        
      });
      },500);
     
  }


  updateShippingForm(address: any, index: number): void {
    const shippingGroup = this.shippingList.at(index) as FormGroup;

    shippingGroup.patchValue({
      line1: address.line_1,
      line2: address.line_2,
      city: address.locality,
      country: address.country_name,
      postalCode: address.postal_code
    });

    shippingGroup.markAsDirty();
    this.cdr.detectChanges();
  }

  

  attachFetchifyMethod(index: number,selector: string): void {
   
    
   //Address lookup for Shipping Details
        

   this.callFetchifyMethod.attach({
    search:     selector,
    town:       'shipping_city',
    postcode:   'shipping_postalCode',
    line_1:     'shipping_line1',
    line_2:     'shipping_line2',
    county:     'shipping_country'
  },
  
  {
    onResultSelected: (c2a: any, elements: any, address: any) => {
      this.updateShippingForm(address, index);
     
    }
  }); 




    this.callFetchifyMethod.attach({
      search:     'businesslookup',
      town:       'city',
      postcode:  'postalCode',
      line_1:     'line1',
      line_2:     'line2',
      county:    'country'
    },
    
    {
      onResultSelected: (c2a: any, elements: any, address: any) => {
        this.bussinessDetails.controls['line1']?.setValue(address.line_1);
        this.bussinessDetails.get('line1')?.markAsDirty();
        this.bussinessDetails.controls['line2']?.setValue(address.line_2);
        this.bussinessDetails.get('line2')?.markAsDirty();
        this.bussinessDetails.controls['city']?.setValue(address.locality);
        this.bussinessDetails.get('city')?.markAsDirty();
        this.bussinessDetails.controls['country']?.setValue(address.country_name);
        this.bussinessDetails.get('country')?.markAsDirty();
        this.bussinessDetails.controls['postalCode']?.setValue(address.postal_code);
        this.bussinessDetails.get('postalCode')?.markAsDirty();
        this.bussinessDetails.updateValueAndValidity();
        this.cdr.detectChanges();
      }
    }); 

    this.callFetchifyMethod.attach({
      search:     'billinglookup',
      town:       'city_billing',
      postcode:  'postalCode_billing',
      line_1:     'line1_billing',
      line_2:     'line2_billing',
      county:    'country_billing'
    },
    
    {
      onResultSelected: (c2a: any, elements: any, address: any) => {
        this.billingDetails.controls['line1']?.setValue(address.line_1);
        this.billingDetails.get('line1')?.markAsDirty();
        this.billingDetails.controls['line2']?.setValue(address.line_2);
        this.billingDetails.get('line2')?.markAsDirty();
        this.billingDetails.controls['city']?.setValue(address.locality);
        this.billingDetails.get('city')?.markAsDirty();
        this.billingDetails.controls['country']?.setValue(address.country_name);
        this.billingDetails.get('country')?.markAsDirty();
        this.billingDetails.controls['postalCode']?.setValue(address.postal_code);
        this.billingDetails.get('postalCode')?.markAsDirty();
        this.billingDetails.updateValueAndValidity();
        this.cdr.detectChanges();
      }
    }); 

    this.callFetchifyMethod.attach({
      search:     'payinglookup',
      town:       'city_paying',
      postcode:  'postalCode_paying',
      line_1:     'line1_paying',
      line_2:     'line2_paying',
      county:    'country_paying'
    },
    
    {
      onResultSelected: (c2a: any, elements: any, address: any) => {
        this.payerDetails.controls['line1']?.setValue(address.line_1);
        this.payerDetails.get('line1')?.markAsDirty();
        this.payerDetails.controls['line2']?.setValue(address.line_2);
        this.payerDetails.get('line2')?.markAsDirty();
        this.payerDetails.controls['city']?.setValue(address.locality);
        this.payerDetails.get('city')?.markAsDirty();
        this.payerDetails.controls['country']?.setValue(address.country_name);
        this.payerDetails.get('country')?.markAsDirty();
        this.payerDetails.controls['postalCode']?.setValue(address.postal_code);
        this.payerDetails.get('postalCode')?.markAsDirty();
        this.payerDetails.updateValueAndValidity();
        this.cdr.detectChanges();
      }
    });

    
    



  }   

  toggleAcctStmtEmail(event: any) {
    const acctStmtEmailControl = this.billingDetails.get('acctStmtEmail');
    const emailField = document.getElementById('bill-accemail');
  
    if (event.target.checked) {
      acctStmtEmailControl?.setValidators([Validators.required, Validators.pattern(this.emailPattern)]);
      emailField!.style.display = 'block';
    } else {
      acctStmtEmailControl?.clearValidators();
      acctStmtEmailControl?.reset();
      emailField!.style.display = 'none';
    }
    acctStmtEmailControl?.updateValueAndValidity();
  }

  copyBusinessToBilling(event: any) {
    if (event.target.checked) {
      this.billingDetails.patchValue({
        addressType: 'Billing',
        orgName: this.bussinessDetails.get('orgName')?.value,
        firstName: this.bussinessDetails.get('firstName')?.value,
        lastName: this.bussinessDetails.get('lastName')?.value,
        email: this.bussinessDetails.get('emailId')?.value, // Mapping emailId to email
        phone: this.bussinessDetails.get('phoneNo')?.value, // Mapping phoneNo to phone
        phoneExt: this.bussinessDetails.get('phoneExt')?.value, 
        line1: this.bussinessDetails.get('line1')?.value,
        line2: this.bussinessDetails.get('line2')?.value,
        city: this.bussinessDetails.get('city')?.value,
        country: this.bussinessDetails.get('country')?.value,
        postalCode: this.bussinessDetails.get('postalCode')?.value
      });
    } else {
      this.billingDetails.reset();
    }
  }

  copyBillingToPaying(event: any) {
    if (event.target.checked) {
      this.payerDetails.patchValue({
        addressType: 'Paying',
        orgName: this.billingDetails.get('orgName')?.value,
        firstName: this.billingDetails.get('firstName')?.value,
        lastName: this.billingDetails.get('lastName')?.value,
        email: this.billingDetails.get('email')?.value, // Mapping emailId to email
        phone: this.billingDetails.get('phone')?.value, // Mapping phoneNo to phone
        phoneExt: this.billingDetails.get('phoneExt')?.value, 
        line1: this.billingDetails.get('line1')?.value,
        line2: this.billingDetails.get('line2')?.value,
        city: this.billingDetails.get('city')?.value,
        country: this.billingDetails.get('country')?.value,
        postalCode: this.billingDetails.get('postalCode')?.value
    });
    } else {
      this.payerDetails.reset();
    }
  }

  copyBusinessToShipping(event: any, index: number) {
    if (event.target.checked) {
      const shippingFormGroup = this.shippingList.at(index) as FormGroup;
      shippingFormGroup.patchValue({
        firstName: this.bussinessDetails.get('firstName')?.value,
        lastName: this.bussinessDetails.get('lastName')?.value,
        email: this.bussinessDetails.get('emailId')?.value,
        phone: this.bussinessDetails.get('phoneNo')?.value,
        phoneExt: this.bussinessDetails.get('phoneExt')?.value, 
        line1: this.bussinessDetails.get('line1')?.value,
        line2: this.bussinessDetails.get('line2')?.value,
        city: this.bussinessDetails.get('city')?.value,
        country: this.bussinessDetails.get('country')?.value,
        postalCode: this.bussinessDetails.get('postalCode')?.value,
        orgName: this.bussinessDetails.get('orgName')?.value,
        licenseDetailsForm: {
          nhsCode: this.bussinessDetails.get('nhsCode')?.value
        }
      });
    } else {
      const shippingFormGroup = this.shippingList.at(index) as FormGroup;
      shippingFormGroup.reset();
    }
  }

  setValidationRules(value: string) {
    const nhsCode = this.bussinessDetails.get('nhsCode');
      nhsCode?.reset(); 
      if (value === 'GENERAL_PRACTICE') {
        nhsCode?.setValidators([
          Validators.pattern('^[A-Za-z]{1}[0-9]{5}$'),
          alphanumericValidator()
        ]);
        this.maxLength = 6;
      } 
      else if (value === 'PHARMACY') {
        nhsCode?.setValidators([
          Validators.pattern('^[F|f]([A-Za-z]|[0-9]){4}$'),
          alphanumericValidator()
        ]);
        this.maxLength = 5;
      } 
      else if (value === 'HOSPITAL_PUBLIC' || value === 'HOSPITAL_PRIVATE' || value === 'NURSING_HOMES' || value === 'CORRECTIONAL_FACILITY' || value === 'WHOLESALER') {
        nhsCode?.setValidators([
          Validators.pattern('^.{6,6}$'),Validators.minLength(6),
          alphanumericValidator()
        ]);
        this.maxLength = 6;
      } 
      
      else {
        nhsCode?.clearValidators();
      }
      nhsCode?.updateValueAndValidity();

      // Inside the setValidationRules method
this.shippingList.controls.forEach((control: AbstractControl) => {
  const shippingFormGroup = control as FormGroup;
  const shippingNhsCode = shippingFormGroup.get('licenseDetailsForm.nhsCode');
  shippingNhsCode?.reset();
  if (value === 'GENERAL_PRACTICE') {
    shippingNhsCode?.setValidators([
      Validators.pattern('^[A-Za-z]{1}[0-9]{5}$'),
      alphanumericValidator()
    ]);
  } else if (value === 'PHARMACY') {
    shippingNhsCode?.setValidators([
      Validators.pattern('^[F|f]([A-Za-z]|[0-9]){4}$'),
      alphanumericValidator()
    ]);
  } else if (value === 'HOSPITAL_PUBLIC' || value === 'HOSPITAL_PRIVATE' || value === 'NURSING_HOMES' || value === 'CORRECTIONAL_FACILITY' || value === 'WHOLESALER') {
    shippingNhsCode?.setValidators([
      Validators.pattern('^.{6,6}$'), Validators.minLength(6),
      alphanumericValidator()
    ]);
  } else {
    shippingNhsCode?.clearValidators();
  }
  shippingNhsCode?.updateValueAndValidity();
});
    
  }

  onOrgTypeChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedBusinessType = selectElement.value;
    this.formattedBusinessType = this.formatType(this.selectedBusinessType);
    const value = selectElement?.value;
    if (value) {
      this.setValidationRules(value);
    }
  }
   
  scrollTotop(): void {
    // Call this method to scroll to the top
    this.contentService.scrollToTop();
  }

  isContactsActive(): boolean {
    return this.showInvoicedetails || this.showPayingdetails || this.showShippingdetails;
  }

  getActiveSection(): string {
    if (this.showBussinessdetails) return 'business';
    if (this.showInvoicedetails) return 'invoice';
    if (this.showPayingdetails) return 'paying';
    if (this.showShippingdetails) return 'shipping';
    if (this.showReviewdetails) return 'review';
    if (this.showInvoicedetails || this.showPayingdetails || this.showShippingdetails) return 'contacts';
    return '';
  }

  showBussinessSection(){
    this.showBussinessdetails = true;
    this.showInvoicedetails = false;
    this.showPayingdetails = false;
    this.showShippingdetails = false;
    this.showReviewdetails = false;
    this.scrollTotop();
  }

  showBillingSection(){
    this.showBussinessdetails = false;
    this.showInvoicedetails = true;
    this.showPayingdetails = false;
    this.showShippingdetails = false;
    this.showReviewdetails = false;
    this.scrollTotop();
  }

  showPayingSection(){
    this.showBussinessdetails = false;
    this.showInvoicedetails = false;
    this.showPayingdetails = true;
    this.showShippingdetails = false;
    this.showReviewdetails = false;
    this.scrollTotop();
  }

  showShippingSection(){
    this.showBussinessdetails = false;
    this.showInvoicedetails = false;
    this.showPayingdetails = false;
    this.showShippingdetails = true;
    this.showReviewdetails = false;
    this.scrollTotop();
  }

  showReviewSection(){
    this.showBussinessdetails = false;
    this.showInvoicedetails = false;
    this.showPayingdetails = false;
    this.showShippingdetails = false;
    this.showReviewdetails = true;
    this.scrollTotop();
  }

  onSubmit_bussiness(){
    
    const isFormValid = this.bussinessDetails.valid;
    this.isFormSubmitted_bussiness =  true;
    if(isFormValid){
    this.formDataBussiness =this.bussinessDetails.value;
    this.showBillingSection();
  
    }
  }  

  onSubmit_billing(){
    
    const isFormValid = this.billingDetails.valid;
    this.isFormSubmitted_billing =  true;
    
    if(isFormValid){
      this.formDataBilling =this.billingDetails.value;
      this.showPayingSection();
    
      }

  } 

  onSubmit_paying(){
    const isFormValid = this.payerDetails.valid;
    this.isFormSubmitted_paying =  true;
    
    if(isFormValid){
      this.formDataPaying = this.payerDetails.value;
      this.showShippingSection();
    
      }
  }  
  totalShippingCount: number = 0;
  onSubmit_shipping(index: number):void{
    const shippingForm = this.shippingList.at(index) as FormGroup;
    
    const isFormValid = this.shippingForm.valid;
    this.isFormSubmitted_shipping =  true;
    if(isFormValid){
      shippingForm.addControl('submitted', new FormControl(true));
      shippingForm.addControl('isEditing', new FormControl(false));
      this.modifyForm(index, shippingForm.value);
      this.totalShippingCount = this.shippingList.length;
      this.showReviewSection();
    
      }
  }

  showLocationcount:boolean=false;
  showCheckbox:boolean = true;

  addShippingForm() {
    this.showLocationcount = true;
    this.isAddingNewForm = true;
    this.shippingList.push(this.createShippingForm());
    this.isFormSubmitted_shipping = false; 
    const index = this.shippingList.length - 1;
    const newFieldId = 'shipping_lookup' + index; 
    setTimeout(()=>{
      this.attachFetchifyMethod(index,newFieldId);
      this.cdr.detectChanges();
      },3000);

    const value = this.bussinessDetails.get('orgType')?.value;
    this.setValidationRules(value);
  
    this.cdr.detectChanges();
  }

  create_Account() {
    this.showloader = true;
    if (this.shippingForm.valid) {
      const payload = {
        businessDetails: this.bussinessDetails.value,
        billingDetails: this.billingDetails.value,
        payerDetails: this.payerDetails.value,
        shippingList: { shippingList: this.shippingList.value }
      };

      this.registrationUkService.submitRegistration(payload).subscribe(response => {
        this.router.navigate(['/regThankYou']);
        this.showloader = false;
      }, error => {
        console.error('Registration failed', error);
        this.showloader = false;
      });
    } else {
    }
  }




}

