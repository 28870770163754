<div class="col-xs-12 col-sm-12 myprofile_consentsection no-padding" *ngIf="!showStatus()">
    <div class="myprofile_consentcontainer" >  
          <div class="myprofile_consentheader">{{ukConsentPopupData?.headline}}</div>  
          <div class="myprofile_consentcontent"> 
               <span>{{ukConsentPopupData?.message1}}</span>
						<span class="consentsubheader_mail">{{consentdata?.marketingConsentEmailId}}</span>
							<span>{{ukConsentPopupData?.message2}} </span>
							<span class="consent_privacypolicy">
								<a target="_blank" href={{ukConsentPopupData?.button}}>privacy policy</a>.
							</span>
          </div> 
      <div class="myprofile_radiocontainer">
            <label class="myprofile_radio">
                 <input type="radio"  class="myprofile_radiobtn" id="myprofile_radiobtn1" value="true" [checked]="consentdata?.isConsentGranted==true?'checked':''" (change)="onRadiochange(true)"><span class="myprofile_radiobtncontrol"></span><label for="myprofile_radiobtn1">{{ukConsentPopupData?.content}}</label>
            </label>
          </div>
          <div class="myprofile_radiocontainer">
            <label class="myprofile_radio">
                 <input type="radio"  class="myprofile_radiobtn" id="myprofile_radiobtn2" value="false" [checked]="consentdata?.isConsentGranted==false?'checked':''"  (change)="onRadiochange(false)"><span class="myprofile_radiobtncontrol"></span><label for="myprofile_radiobtn2">{{ukConsentPopupData?.label1}}</label>
            </label>
         </div>
          <button type="button" id="myprofile_updatebtn" (click)="submitconsent()">{{ukConsentPopupData?.thankYouMessage}}</button>   
          <div class="myprofile_consentsuccess" [ngClass]="successshow?'show':''">{{ukConsentPopupData?.label2}}</div>
        </div>  
 </div>
