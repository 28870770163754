import { ChangeDetectorRef, Component } from '@angular/core';
import { ContentService } from '../../spartacus/services/content.service';
import { Observable, combineLatest } from 'rxjs';
import { CmsComponentData } from '@spartacus/storefront';
import { CmsBannerComponent, OccConfig } from '@spartacus/core';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-clinical-flucelvax-second',
  templateUrl: './clinical-flucelvax-second.component.html',
  styleUrl: './clinical-flucelvax-second.component.scss'
})
export class ClinicalFlucelvaxSecondComponent {
  thirdnode: Observable<any> = this.bannerComponent.data$;
  userLoggedIn: boolean = false;
  mergedObject2: any = [];
  selectedTabIndex: any = []; 
  study = [false,false];
  mergedObject1: any = [];

  onTabChange(event: any) {
    this.selectedTabIndex = event.index;
  }
  constructor(private accessService: ContentService, public bannerComponent: CmsComponentData<CmsBannerComponent>, protected config: OccConfig, private cd: ChangeDetectorRef) { }

  getImage1(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.desktop.url;
  }
  openModal(): void {
    const modal = document.getElementById('adult_abandonedPop');
    if (modal) {
      modal.style.display = 'block';
      document.body.classList.add('modal-open');
    }
  }
  closeModal(): void {
    const modal = document.getElementById('adult_abandonedPop');
    if (modal) {
      modal.style.display = 'none';
      document.body.classList.remove('modal-open');
    }
  }

  toggleStudy(i:number){
    this.study[i]=!this.study[i]
  }

  ngOnInit() {
    this.thirdnode.subscribe(res => {
      this.clinicaltabs(res);
    });
  }

  clinicaltabs(res: any) {
  //   const tilesList = res.safetyProfiletList.split(" ");
  //   const observables = tilesList.map((el: any) => this.accessService.opeffeaturedResources(el));

  //   combineLatest(observables).pipe(
  //     map((results:any) => results.flat())
  //   ).subscribe((mergedResults: any) => {
  //     this.mergedObject2 = mergedResults;
  //     this.cd.detectChanges();
  //     console.log(this.mergedObject2);
  //   });
  // }
  const tilesList = res.safetyProfiletList.split(" ");
  this.accessService.opeffeaturedResources(tilesList).subscribe((res : any) => {
   this.mergedObject2 = res.component;

   this.cd.detectChanges;
  });
}
}
