import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ContentService } from '../spartacus/services/content.service';
import { map, Observable, Subscription } from 'rxjs';
import { UserInfoService } from '../spartacus/services/user-info.service';
import { BaseSiteService, CmsService, OccEndpointsService } from '@spartacus/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-custom-pip',
  templateUrl: './custom-pip.component.html',
  styleUrl: './custom-pip.component.scss'
})
export class CustomPipComponent implements OnInit {
  fluadurl: any;
  prescribingFluadPdf: any;
  prescribingFlucelvaxPdf: any;
  prescribingAfluriaPdf: any;
  pageLabel: string="";
  currentSite: string="";
  private subscription: Subscription | undefined;
  termsofUseLink: any;
  termsofUseNode!: Observable<any>;

  constructor(
    private contentService: ContentService,
    private cd: ChangeDetectorRef,
    private userInfoService: UserInfoService,
    private occEndPointsService: OccEndpointsService,
    private router: Router,
    private baseSiteService: BaseSiteService,
    private bannerComponent: CmsService,
    protected paraService: ContentService,
  ) {
    this.bannerComponent.getCurrentPage().subscribe((data)=>{
      this.pageLabel = data.pageId?data.pageId:"";
    })
  }

  ngOnInit() {
    if(this.pageLabel === 'termsAndConditionsofUse') {
      this.termsofUseNode = this.paraService.getNavigation('TermsOfUseLink');
      this.termsofUseNode
        .pipe(map((val) => val.media?.url))
        .subscribe((res) => {
          this.termsofUseLink = res;
          let repo = this.occEndPointsService.buildUrl(res);
          repo = repo.replace('occ/v2/seqirusb2b-flu360/', '');
          this.termsofUseLink = repo;
          window.location.href=this.termsofUseLink;
        });
    }
    
   if(this.pageLabel.includes("terms-conditions-")){


      this.userInfoService.termsofpdf(this.pageLabel ).subscribe(res => {
        let repo = this.occEndPointsService.buildUrl(res);
        repo = repo.replace('occ/v2/seqirusb2b-flu360/', '');
        this.prescribingFluadPdf = repo;
        const url = res;
        window.location.href=this.prescribingFluadPdf;
      });
    }
    /* if(this.pageLabel === 'termsAndConditionsofUse') {
      this.termsofUseNode = this.paraService.getNavigation('TandCofsaleLink2024-2025');
      this.termsofUseNode
        .pipe(map((val) => val.media?.url))
        .subscribe((res) => {
          this.termsofUseLink = res;
          let repo = this.occEndPointsService.buildUrl(res);
          repo = repo.replace('occ/v2/seqirusb2b-flu360/', '');
          this.termsofUseLink = repo;
          window.location.href=this.termsofUseLink;
        });
    }*/

    if(this.pageLabel === 'fluadPrescribingInfoPDF') {
      this.userInfoService.getFluadPdf().subscribe(res => {
        let repo = this.occEndPointsService.buildUrl(res);
        repo = repo.replace('occ/v2/seqirusb2b-flu360/', '');
        this.prescribingFluadPdf = repo;
        const url = res;
        window.location.href=this.prescribingFluadPdf;
      });
    }

    if(this.pageLabel === 'flucelvaxPrescribingInfoPDF') {
      this.userInfoService.getFlucelvaxPdf().subscribe(res => {
        let repo = this.occEndPointsService.buildUrl(res);
        repo = repo.replace('occ/v2/seqirusb2b-flu360/', '');
        this.prescribingFlucelvaxPdf = repo;
        const url = res;
        window.location.href=this.prescribingFlucelvaxPdf;

      });
    }

    if(this.pageLabel === 'afluriaPrescribingInfoPDF') {
      this.userInfoService.getAfluriaPdf().subscribe(res => {
        let repo = this.occEndPointsService.buildUrl(res);
        repo = repo.replace('occ/v2/seqirusb2b-flu360/', '');
        this.prescribingAfluriaPdf = repo;

        const url = res;
        window.location.href=this.prescribingAfluriaPdf;

      });
    } 
  }
}
