import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SitemapComponent } from './sitemap/sitemap.component';
import { RobotsComponent } from './robots/robots.component';
import { CmsConfig, ConfigModule } from '@spartacus/core';



@NgModule({
  declarations: [
    SitemapComponent,
    RobotsComponent
  ],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents:{
        RobotsTextBodyComponent:{
          component: RobotsComponent
        },
        SiteMapBodyComponent:{
          component: SitemapComponent
        },
      }
    }as CmsConfig)
  ]
})
export class SitemapModule { }
