import { ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { BaseSiteService, CmsService, OccEndpointsService, UserIdService } from '@spartacus/core';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { CommonApiService } from '../../spartacus/services/common-api.service';
import { StartOrderService } from '../../custom-start-order/start-order.service';

@Component({
  selector: 'app-popupdashboard',
  templateUrl: './popupdashboard.component.html',
  styleUrl: './popupdashboard.component.scss'
})
export class PopupdashboardComponent {
  popupsData: any;
  creditBlockModal: boolean=false;
  showConsentPopup: boolean = false;
  creditPopUpData: any="";
  ukConsentPopupData: any="";
  marketingEmail: any;
  showApiFailurePopup: boolean=false;
  apiFailurePopupData: any="";
  showReturnsSecPopup: boolean=false;
  returnsSecPopupData: any="";
  returnsFirstPopupData: any="";
  showReturnsFirstPopup: boolean=false;
  showUSConsentPopup: boolean=false;
  usConsentPopupData: any;
  isApiFailurePopupEnable: boolean=false;
  isAsmUser: boolean = false;
  rbavalue: any;
  isSearchByProfile: boolean=false;

  constructor(private router: Router,private baseSiteService:BaseSiteService,private component: CmsService,private userInfoService: UserInfoService,private startOrderService:StartOrderService,
    private occEndpointsService:OccEndpointsService,private commonService:CommonApiService,private userIdService: UserIdService,private cdr:ChangeDetectorRef) {
    
  }
  closeCreditPopUp(){
    this.creditBlockModal=false;
    
  }
 closeReturnsSecPopup(){
  this.showReturnsSecPopup=false;
  sessionStorage.setItem('returnsSecondPopup','false');
 }
 closeReturnsFirstPopup(){
  this.showReturnsFirstPopup=false;
  sessionStorage.setItem('returnsFirstPopup','false');
 }

  isConsentGranted(text:string){
    let userId =this.commonService.userId;
    this.userIdService.getUserId().subscribe(item => {
      if(item)
        userId = item;
    });
    let endpoint = this.occEndpointsService.buildUrl(`/users/${userId}/my-profile/marketingconsent/${text}`);
    let payload: any = {
      url: endpoint,
    }
    this.commonService.postAPICall(payload).subscribe(res => {
      if(this.showConsentPopup)
        this.showConsentPopup = false;
      if(this.showUSConsentPopup)
        this.showUSConsentPopup=false;
      this.cdr.detectChanges();

    });
  }

  ngOnInit(){
    
    this.rbavalue=localStorage.getItem('rbapermission');
    this.rbavalue=JSON.parse(this.rbavalue);
    this.cdr.detectChanges(); 

    this.userInfoService.getswitchrba().subscribe(value => {
      if(value){
        this.rbavalue=value;
        this.cdr.detectChanges();
      }
    });

    this.userInfoService.dashboardOrderData$.subscribe((data) => {
      if(data){
      data.subscribe((dta:any) => {
        this.popupsData = dta;
        if(this.popupsData.creditBlockPopup == true){
          this.creditBlockModal = true;
          this.component.getComponentData('creditsPopup').subscribe((data)=>{
            this.creditPopUpData = data;
            this.cdr.detectChanges();
          });
          
        }
        if(this.popupsData.isConsentDisplay == false){
          this.baseSiteService.getActive().subscribe(site => {
            if(site == 'seqirusb2b-flu360'){
              this.showUSConsentPopup = true;
              this.marketingEmail = this.popupsData.marketingEmailId;
              this.component.getComponentData('consent-popup-Component').subscribe((data)=>{
                this.usConsentPopupData = data;
                this.cdr.detectChanges();
              });

            }
            else if(site == 'seqirusb2b-uk'){
              this.showConsentPopup = true;
              this.marketingEmail = this.popupsData.marketingEmailId;
              this.component.getComponentData('ukConsentPopup').subscribe((data)=>{
                this.ukConsentPopupData = data;
                this.cdr.detectChanges();
              });
            }
          })
          
        }
        
        this.component.getComponentData('apiFailurePopup').subscribe((data)=>{
            this.apiFailurePopupData = data;
            this.cdr.detectChanges();
        });
        
        if(sessionStorage.getItem('returnsFirstPopup') != 'false'){
        if((this.rbavalue?.RBAPermissions==false && this.popupsData.returnsFirstPopupShow == true) || (this.rbavalue?.RBAPermissions==true && this.rbavalue?.showDashboardOrderUK==true && this.popupsData.returnsFirstPopupShow == true)){
          this.showReturnsFirstPopup = true;
          this.component.getComponentData('returnsPopupfirst').subscribe((data)=>{
            this.returnsFirstPopupData = data;
            this.cdr.detectChanges();
          });
        }
        }
        
        if(sessionStorage.getItem('returnsSecondPopup') != 'false'){
          if((this.rbavalue?.RBAPermissions==false && this.popupsData.returnsSecondPopupShow == true) || (this.rbavalue?.RBAPermissions==true && this.rbavalue?.showDashboardOrderUK==true && this.popupsData.returnsSecondPopupShow == true)){
              
          this.showReturnsSecPopup = true;
          this.component.getComponentData('returnsPopupSecond').subscribe((data)=>{
            this.returnsSecPopupData = data;
            this.cdr.detectChanges();
          });
          }
        }
      })
    }
  
    })

    this.startOrderService.getApiFailurePopup().subscribe(value => {
      if(value){
        this.showApiFailurePopup=true;
        this.component.getComponentData('apiFailurePopup').subscribe((data)=>{
          this.apiFailurePopupData = data;
          this.cdr.detectChanges();
        });
      }
      this.cdr.detectChanges();
    })

    this.isAsmUser = this.userInfoService.checkAsmUser();
    this.isSearchByProfile = localStorage.getItem('isSearchByProfile') == 'false' ? false:true;
  }

  ngAfterViewInit(){
    
  }

  closeApifailurePopup(){
    this.startOrderService.setApiFailurePopup(false);
    this.router.navigate(['/my-account']);
  }

  sendNotShowFirstPopup(){
    this.userInfoService.sendReturnsPopupNotShow('false','').subscribe(data => {
      this.showReturnsFirstPopup=false;
      this.cdr.detectChanges();
    });
  }
  sendNotShowSecPopup(){
    this.userInfoService.sendReturnsPopupNotShow('','false').subscribe(data => {
      this.showReturnsSecPopup=false;
      this.cdr.detectChanges();
    });
  }

  
}
