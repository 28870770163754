import { Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from "@angular/router";
import { AuthService, BaseSiteService, OccEndpointsService, SemanticPathService } from "@spartacus/core";
import { Observable, map, of, switchMap, tap } from "rxjs";
import { UserInfoService } from "../spartacus/services/user-info.service";
import { CookieService } from "ngx-cookie-service";


@Injectable({
  providedIn: 'root'
})
export class SeqirusNotAuthGuard {
  currentRoute: string='';
  constructor(private authService: AuthService, private router: Router,private cookieService:CookieService,
    private userInfoService: UserInfoService, private occ: OccEndpointsService,private baseSiteService:BaseSiteService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | boolean | any {
    return this.authService.isUserLoggedIn().pipe(
      map(isLoggedIn => {
        /* if (this.occ.getBaseUrl().includes('seqirusb2b-uk') && !localStorage.getItem('isLandedToSplashPage')) {
          localStorage.setItem('isLandedToSplashPage', 'true');
          return true;
        } */
        if(!this.occ.getBaseUrl().includes('seqirusb2b-flu360') && (!this.cookieService.check('visitor_type') || this.cookieService.get('visitor_type') != 'healthcare')){
          let url = state.url.split('?')[0].split('/');
          this.currentRoute = url[url.length - 1];
          if(this.currentRoute !== ''){
          sessionStorage.setItem('navigateToUrl',this.currentRoute);
          this.router.navigate(['/']);
         }
          return true;
        }

        else if (isLoggedIn) {
         this.userInfoService.getUserData().subscribe(res => {
            if (res) {
              let url = state.url.split('?')[0].split('/');
              this.currentRoute = url[url.length - 1];
              if (res.webEnabled) {
                
                if(this.currentRoute !== ''){
                  return false;
                }
                else{
                  this.router.navigate(['/my-account']);
                }
              }
              else{
                this.baseSiteService.getActive().subscribe(site =>{
                  if(site == 'seqirusb2b-uk'){
                    if(this.currentRoute == ''){
                      this.router.navigate(['register/join-account']);
                    }
                    return true;
                  } 
                  else{
                    return true;
                  } 

                });
                
              }
              //return true;

            }
            return true;
          })
          //return false;
        }
        else{
          let url = state.url.split('?')[0].split('/');
          this.currentRoute = url[url.length - 1];
          if(this.currentRoute == '' && this.occ.getBaseUrl().includes('seqirusb2b-uk')){
            this.router.navigate(['/homepage']);
          }
          
        }
        return true;

      })
    );
  }
}

