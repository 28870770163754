import { ChangeDetectorRef, Component, inject, ViewChild } from '@angular/core';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { LiveAnnouncer } from '@angular/cdk/a11y';

@Component({
  selector: 'app-permissionadmin',
  templateUrl: './permissionadmin.component.html',
  styleUrl: './permissionadmin.component.scss'
})
export class PermissionadminComponent {
  constructor(private userInfoService: UserInfoService,private cd:ChangeDetectorRef) {
  }
  rbainitaldata:any;

  userdetails:any;
  nonadminfull:any;
 
  locationdetails:any;

  getuserlogin:any;
  private _liveAnnouncer = inject(LiveAnnouncer);
  admintabsection = {
    account: false,
    location: false,
    user: false,
   
  }

  nonadmin=new MatTableDataSource();
  adminaccount=new MatTableDataSource();
  adminlocataion=new MatTableDataSource();
  adminuser=new MatTableDataSource();
  displayedaccount:string[]=['userName','accountPermissions','adminPermissions'];
  displayedlocation:string[]=['userName','orderingPermissions','financialPermission'];
  
  displayeduser:string[]=['location','postCode','orderingPermissions','financialPermission']
  displayedColumnsuk: string[] = ['location', 'postCode','orderingPermissions','financialPermission', 'locID'];
  useradminpermission:string="";
  useraccountpermission:string=""
  @ViewChild(MatSort) sort!: MatSort
  ngOnInit() {
  
     this.getuserlogin=localStorage.getItem('loggedIn');
    this.userInfoService.rbaintialdata().subscribe((data: any) => {
     this.rbainitaldata=data;
     this.toggleview(this.rbainitaldata?.userPermissionView);
     const body=<HTMLElement>document.querySelector("body");
     if(this.rbainitaldata?.isCustomerAdmin){
      body.classList.remove("nonadmin")
      body.classList.add("adminsreen")
     }else{
      body.classList.remove("adminsreen")
      body.classList.add("nonadmin")
     }
    
     this.cd.detectChanges();
     
    });
    this.userInfoService.nonadmin().subscribe((data: any) => {
     this.nonadminfull=data
      this.nonadmin=new MatTableDataSource(data.locPermissionData);
      this.nonadmin.sort = this.sort;
    });
   this.cd.detectChanges();
  }
 /*  radiochnage(event:any){
    const element =(event.target as HTMLInputElement);
   if(event.target.checked){
    element.parentElement?.classList.add("btnselected");
    element.parentElement?.parentElement.
   }
   
  } */

   accountdata(){
    this.userInfoService.accountrba().subscribe((data: any) => {

this.adminaccount=new MatTableDataSource(data.locPermissionData);
this.cd.detectChanges();
    });
    
    
   }
   locationdata(locid:string){
    this.userInfoService.locationrba(locid).subscribe((data: any) => {
      this.adminlocataion=new MatTableDataSource(data.locPermissionData);
//this.adminaccount=new MatTableDataSource(data.locPermissionData);
this.cd.detectChanges();
    });
    
    
   }
   userdata(userid:string){
    this.userInfoService.userrba(userid).subscribe((data: any) => {
      this.adminuser=new MatTableDataSource(data?.locPermissionData);
      //this.adminaccount=new MatTableDataSource(data.locPermissionData);
      this.useradminpermission=data?.adminPermissions;
      this.useraccountpermission=data?.accountPermissions
      this.cd.detectChanges();
    });
    
    
   }
   announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
  toggleview(value:string){
    if(value=='Account' || value=='ACCOUNT'){
      this.admintabsection.location=false;
      this.admintabsection.account=true;
      this.admintabsection.user=false;
      this.accountdata();
     }else if(value=='Location' || value=='LOCATION'){
      var e = (document.getElementById("Permision_location")) as HTMLSelectElement;
      var sel = e?.selectedIndex;
      var opt = e?.options[sel];
      var CurValue = (<HTMLSelectElement><unknown>opt)?.value;
      if(CurValue){
      this.locationdata(CurValue);
      }else{
        this.locationdata(this.rbainitaldata?.custLocationPermission[0]?.locID)
      }
      //const location=<HTMLSelectElement>document.getElementById("Permision_location").no;
     
      this.admintabsection.location=true;
      this.admintabsection.account=false;
      this.admintabsection.user=false;
     
     }else if(value=='User'|| value=='USER'){

      var euser = (document.getElementById("Permision_User")) as HTMLSelectElement;
      var seluser = euser?.selectedIndex;
      var optuser = euser?.options[seluser];
      var CurValueuser = (<HTMLSelectElement><unknown>optuser)?.value;
      if(CurValueuser){
      this.userdata(CurValueuser);
      }else{
        this.userdata(this.rbainitaldata?.customersRBAData[0]?.userId)
      }
      this.admintabsection.user=true;
      this.admintabsection.location=false;
      this.admintabsection.account=false;
    
     }
     this.cd.detectChanges();
  }
  locationselchange(){
    var e = (document.getElementById("Permision_location")) as HTMLSelectElement;
    var sel = e?.selectedIndex;
    var opt = e?.options[sel];
    var CurValue = (<HTMLSelectElement><unknown>opt)?.value;
    this.locationdata(CurValue);
  }
  userselchange(){
    var euser = (document.getElementById("Permision_User")) as HTMLSelectElement;
    var seluser = euser?.selectedIndex;
    var optuser = euser?.options[seluser];
    var CurValueuser = (<HTMLSelectElement><unknown>optuser)?.value;
    this.userdata(CurValueuser);
  }

  accountpermssiontoggle(userid:string,persmission:string,value:string){

    const obj =
      {
        changedPermissionValue:value,
        userId: userid,
        locationId: "",
        permission: persmission
      }
    
    this.userInfoService.changeaccountpermssion(obj).subscribe(
      (data) => {
        
        if(this.getuserlogin==userid){
         this.userInfoService.setrbachange("changed");
        }
       
      },
      (error) => {
        console.log('error');
       
      }
    );
  }
  locpermissiontoggle(id:string,persmission:string,value:string){
if(this.admintabsection.location){
  var e = (document.getElementById("Permision_location")) as HTMLSelectElement;
  var sel = e?.selectedIndex;
  var opt = e?.options[sel];
  var CurValue = (<HTMLSelectElement><unknown>opt)?.value;
  const obj =
  {
    changedPermissionValue:value,
    userId: id,
    locationId: CurValue,
    permission: persmission
  }
   
  this.userInfoService.changelocationpermssion(obj).subscribe(
    (data) => {
      if(this.getuserlogin==id){
        this.userInfoService.setrbachange("changed");
       }
     
    },
    (error) => {
      console.log('error');
     
    }
  );


}else if(this.admintabsection.user){
  var euser = (document.getElementById("Permision_User")) as HTMLSelectElement;
  var seluser = euser?.selectedIndex;
  var optuser = euser?.options[seluser];
  var CurValueuser = (<HTMLSelectElement><unknown>optuser)?.value;
  const obj =
  {
    changedPermissionValue:value,
    userId: CurValueuser,
    locationId: id,
    permission: persmission
  }
  this.userInfoService.changelocationpermssion(obj).subscribe(
    (data) => {
      if(this.getuserlogin==CurValueuser){
        this.userInfoService.setrbachange("changed");
       }
     
    },
    (error) => {
      console.log('error');
     
    }
  );

}

  

  }
  settingtoggle(value:string,permission:string){
this.userInfoService.settingrbachanges(value,permission).subscribe(
  (data) => {
    
   
  },
  (error) => {
    console.log('error');
   
  }
);
  }

  adminpermssiontoggle(persmission:string,value:string){
    var euser = (document.getElementById("Permision_User")) as HTMLSelectElement;
    var seluser = euser?.selectedIndex;
    var optuser = euser?.options[seluser];
    var CurValue = (<HTMLSelectElement><unknown>optuser)?.value;
    const obj =
      {
        changedPermissionValue:value,
        userId: CurValue,
        locationId: "",
        permission: persmission
      }
    
    this.userInfoService.changeaccountpermssion(obj).subscribe(
      (data) => {
        
        if(this.getuserlogin==CurValue){
         this.userInfoService.setrbachange("changed");
        }
       
      },
      (error) => {
        console.log('error');
       
      }
    );
  }
  

  
}

