import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsCommonModule } from '../cms-common/cms-common.module';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { I18nModule } from '@spartacus/core';
import { FormsModule} from "@angular/forms";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { RegistrationUKComponent } from './registration-uk/registration-uk.component';
import { InputMaskModule } from 'primeng/inputmask';
import { RegistrationUkThankyouComponent } from './registration-uk-thankyou/registration-uk-thankyou.component';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [
    RegistrationUKComponent,
    RegistrationUkThankyouComponent
  ],
  imports: [
    InputMaskModule,
    CommonModule,
    CmsCommonModule,
    I18nModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    CommonModule,
    ReactiveFormsModule,
    ConfigModule.withConfig({
      cmsComponents: {
        
        RegisterPageBodyComponent : {
          component: RegistrationUKComponent 
        },
        RegisterThankyouCMSTabParagraphComponent : {
          component: RegistrationUkThankyouComponent 
        },

  
     
      }
    } as CmsConfig)
  ],
})
export class RegistrationUkModule { }
