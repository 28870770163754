
<div class="permissionsection" >
<div class=adminsection *ngIf="rbainitaldata?.isCustomerAdmin">
<div class="col-xs-12 col-sm-12 no-padding myprofile_tabContentHeader adminpermission">Permissions Admin</div>
<!-- permission toggle btn -->
<div class="col-xs-12 no-padding per_locationt1">
    <div class="col-xs-12 no-padding perlocationcont">
    
    <!-- user view dropdow-->
    <div class="col-xs-7 no-padding adminUserView" [ngClass]="admintabsection.user?'active':''">
                       <div class=" col-xs-12 per_locationdropdow">
            <div class="permission_droptext">Users</div>
            <div class="permission_drop_box">
                <select class="Permission_dropdown" name="Permission_User" id="Permision_User" (change)="userselchange()">
                
                <option value={{item.userId}} id="Per_user-{{i}}" *ngFor="let item of rbainitaldata?.customersRBAData;let i=index">{{item.userName}}</option>
  
                  
                 
                   </select>
            </div>
        </div>
    </div>
    
    <!-- user view dropdow Ends-->
    
    <div class="col-xs-7 no-padding adminAccountView" [ngClass]="admintabsection.account?'active':''"><div class="permission_droptext">Permissions</div></div>
    <div class="col-xs-7 no-padding adminLocationView"  [ngClass]="admintabsection.location?'active':''">
        <div class=" col-xs-12 per_locationdropdow">
            <div class="permission_droptext">Locations</div>
            <div class="permission_drop_box">
                <select class="Permission_dropdown" name="Permission_location" id="Permision_location" (change)="locationselchange()">
                    <option value={{item.locID}} id="Per_location-{{i}}" *ngFor="let item of rbainitaldata?.custLocationPermission;let i=index">{{item.location}}</option>

                   </select>
            </div>
        </div>
    </div>

    <div class="col-xs-5 viewtoggle viewselection">
        <div class="viewtext">View by</div>
        <div class="permissionradioparent">
        <div class="settingradioholder ">
        
            <input type="radio" class="permission_radiobtn" id="Viewby0" name="Viewby" [checked]="rbainitaldata.userPermissionView=='Account'?'checked':''" value="ACCOUNT" (change)="toggleview('ACCOUNT')">
            <label class="permissionlabel" for="Viewby0">Account</label>
        </div>
        <div class="settingradioholder">
            <input type="radio" class="permission_radiobtn viewByRadio viewBySetting" id="Viewby1" [checked]="rbainitaldata.userPermissionView=='Location'?'checked':''"  name="Viewby" value="LOCATION" (change)="toggleview('LOCATION')">
            <label class="permissionlabel" for="Viewby1">Location</label>
        </div>	
        <div class="settingradioholder">
            <input type="radio" class="permission_radiobtn viewByRadio viewBySetting" id="Viewby2"  name="Viewby"  [checked]="rbainitaldata.userPermissionView=='User'?'checked':''"value="USER" (change)="toggleview('USER')">
            <label class="permissionlabel" for="Viewby2">User</label>
        </div>
        </div>
    </div>
</div>
</div>
<div class=" col-xs-12 no-padding per_locationt2">
<ng-container *ngIf="admintabsection.account">
<div class="adminpermission_locationtable">
    <div class="tablecustomheader">
        <div class="customheaderfirst customheaderdiv">User</div>
        <div class="customheaderdiv customAccountFirst">
           Account Permissions<div class="uktooltip">
              <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <circle cx="11" cy="11.575" r="10.5" stroke="black"></circle>
                 <path d="M11.448 13.5033H10.472L10.36 11.1513H11.128C12.552 11.1513 13.592 10.1753 13.592 8.86326C13.592 7.56726 12.632 6.59126 11.24 6.59126C10.136 6.59126 9.192 7.26326 9 8.31926H7.768C7.992 6.60726 9.32 5.50326 11.192 5.50326C13.256 5.50326 14.792 6.91126 14.792 8.84726C14.792 10.6393 13.48 11.9193 11.48 12.0313L11.448 13.5033ZM10.936 16.6873C10.488 16.6873 10.12 16.3353 10.12 15.8873C10.12 15.4393 10.488 15.0873 10.936 15.0873C11.384 15.0873 11.752 15.4393 11.752 15.8873C11.752 16.3353 11.384 16.6873 10.936 16.6873Z" fill="#212121"></path>
              </svg>
              <span>Includes Account Details e.g. main Contact, NHS Code, etc</span>
           </div>
        </div>
        <div class="customheaderdiv customAccountDiv">
           Admin Permissions<div class="uktooltip">
              <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <circle cx="11" cy="11.575" r="10.5" stroke="black"></circle>
                 <path d="M11.448 13.5033H10.472L10.36 11.1513H11.128C12.552 11.1513 13.592 10.1753 13.592 8.86326C13.592 7.56726 12.632 6.59126 11.24 6.59126C10.136 6.59126 9.192 7.26326 9 8.31926H7.768C7.992 6.60726 9.32 5.50326 11.192 5.50326C13.256 5.50326 14.792 6.91126 14.792 8.84726C14.792 10.6393 13.48 11.9193 11.48 12.0313L11.448 13.5033ZM10.936 16.6873C10.488 16.6873 10.12 16.3353 10.12 15.8873C10.12 15.4393 10.488 15.0873 10.936 15.0873C11.384 15.0873 11.752 15.4393 11.752 15.8873C11.752 16.3353 11.384 16.6873 10.936 16.6873Z" fill="#212121"></path>
              </svg>
              <span>Admins can manage everything and set permissions for other users on the account</span>
           </div>
        </div>
     </div>
     <div class="adminscrolltable">
     <table mat-table [dataSource]="adminaccount" class="admintable">
        <ng-container matColumnDef="userName">
            
            <td mat-cell *matCellDef="let element">{{element.userName}} </td>
        </ng-container>
        <ng-container matColumnDef="accountPermissions">
            
            <td mat-cell *matCellDef="let element;let i = index"><div class="permissionradioparent">
                <div class="radioholder">
                    <input type="radio" class="permission_radiobtn" id="accountpermission_new0{{i}}" name="accountpermission_new{{i}}" [checked]="element.accountPermissions=='NONE'?'checked':''" value="NONE"  (change)="accountpermssiontoggle(element.userId,'accountPermissions','NONE')">

                    <label class="permissionlabel" for="accountpermission_new0{{i}}">None</label>
                </div>
                <div class="radioholder">
                    <input type="radio" class="permission_radiobtn" id="accountpermission_new1{{i}}" name="accountpermission_new{{i}}" [checked]="element.accountPermissions=='VIEWONLY'?'checked':''" value="VIEWONLY" (change)="accountpermssiontoggle(element.userId,'accountPermissions','VIEWONLY')">

                    <label class="permissionlabel" for="accountpermission_new1{{i}}">View Only</label>
                </div>
                <div class="radioholder ">
                    <input type="radio" class="permission_radiobtn" id="accountpermission_new2{{i}}" name="accountpermission_new{{i}}" [checked]="element.accountPermissions=='MANAGE'?'checked':''" value="MANAGE" (change)="accountpermssiontoggle(element.userId,'accountPermissions','MANAGE')">

                    <label class="permissionlabel" for="accountpermission_new2{{i}}">Manage</label>
                </div>
            </div> 
        </td>
        </ng-container>
        <ng-container matColumnDef="adminPermissions">
            
            <td mat-cell *matCellDef="let element;let i = index">
                <div class="permissionradioparent">
                    <div class="radioholder">
                        <input type="radio" class="permission_radiobtn" id="adminPermissions0{{i}}" name="adminPermissions{{i}}" [checked]="element.adminPermissions=='NONE'?'checked':''" value="NONE" (change)="accountpermssiontoggle(element.userId,'adminPermissions','NONE')">
    
                        <label class="permissionlabel" for="adminPermissions0{{i}}">None</label>
                    </div>
                   
                    <div class="radioholder ">
                        <input type="radio" class="permission_radiobtn" id="adminPermissions2{{i}}" name="adminPermissions{{i}}" [checked]="element.adminPermissions=='MANAGE'?'checked':''" value="MANAGE" (change)="accountpermssiontoggle(element.userId,'adminPermissions','MANAGE')">
    
                        <label class="permissionlabel" for="adminPermissions2{{i}}">Manage</label>
                    </div>
                </div></td>
        </ng-container>
        
                
        <tr mat-row *matRowDef="let row; columns: displayedaccount;">
            
        </tr>
    
     </table>
    </div>
     <div ></div>
</div>


</ng-container>
<!-- location table -->
<ng-container *ngIf="admintabsection.location">
<div class="adminpermission_locationtable">
    <div class="tablecustomheader">
        <div class="customheaderfirst customheaderdiv">User</div>
        <div class="customheaderdiv">
            Ordering Permissions<div class="uktooltip">
                <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="11" cy="11.575" r="10.5" stroke="black"></circle>
                          <path d="M11.448 13.5033H10.472L10.36 11.1513H11.128C12.552 11.1513 13.592 10.1753 13.592 8.86326C13.592 7.56726 12.632 6.59126 11.24 6.59126C10.136 6.59126 9.192 7.26326 9 8.31926H7.768C7.992 6.60726 9.32 5.50326 11.192 5.50326C13.256 5.50326 14.792 6.91126 14.792 8.84726C14.792 10.6393 13.48 11.9193 11.48 12.0313L11.448 13.5033ZM10.936 16.6873C10.488 16.6873 10.12 16.3353 10.12 15.8873C10.12 15.4393 10.488 15.0873 10.936 15.0873C11.384 15.0873 11.752 15.4393 11.752 15.8873C11.752 16.3353 11.384 16.6873 10.936 16.6873Z" fill="#212121"></path>
                               </svg>
                <span>Includes Vaccine Orders and Returns, and Location Details</span>
            </div>
        </div>
        <div class="customheaderdiv customthirddiv">
            Financial Permissions<div class="uktooltip">
                <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="11" cy="11.575" r="10.5" stroke="black"></circle>
                          <path d="M11.448 13.5033H10.472L10.36 11.1513H11.128C12.552 11.1513 13.592 10.1753 13.592 8.86326C13.592 7.56726 12.632 6.59126 11.24 6.59126C10.136 6.59126 9.192 7.26326 9 8.31926H7.768C7.992 6.60726 9.32 5.50326 11.192 5.50326C13.256 5.50326 14.792 6.91126 14.792 8.84726C14.792 10.6393 13.48 11.9193 11.48 12.0313L11.448 13.5033ZM10.936 16.6873C10.488 16.6873 10.12 16.3353 10.12 15.8873C10.12 15.4393 10.488 15.0873 10.936 15.0873C11.384 15.0873 11.752 15.4393 11.752 15.8873C11.752 16.3353 11.384 16.6873 10.936 16.6873Z" fill="#212121"></path>
                         </svg>
                <span>Includes Invoices &amp; Credits</span>
            </div>
        </div>
    </div>

    <div class="adminscrolltable">
        <table mat-table [dataSource]="adminlocataion" class="admintable">
           <ng-container matColumnDef="userName">
               
               <td mat-cell *matCellDef="let element">{{element.userName}} </td>
           </ng-container>
           <ng-container matColumnDef="orderingPermissions">
               
               <td mat-cell *matCellDef="let element;let i = index"><div class="permissionradioparent">
                   <div class="radioholder">
                       <input type="radio" class="permission_radiobtn" id="locationpermission_new0{{i}}" name="locationpermission_new{{i}}" [checked]="element.orderingPermissions=='NONE'?'checked':''" value="NONE" (change)="locpermissiontoggle(element.userId,'orderingPermissions','NONE')">
   
                       <label class="permissionlabel" for="locationpermission_new0{{i}}">None</label>
                   </div>
                   <div class="radioholder">
                       <input type="radio" class="permission_radiobtn" id="locationpermission_new1{{i}}" name="locationpermission_new{{i}}" [checked]="element.orderingPermissions=='VIEWONLY'?'checked':''" value="VIEWONLY" (change)="locpermissiontoggle(element.userId,'orderingPermissions','VIEWONLY')" >
   
                       <label class="permissionlabel" for="locationpermission_new1{{i}}">View Only</label>
                   </div>
                   <div class="radioholder ">
                       <input type="radio" class="permission_radiobtn" id="locationpermission_new2{{i}}" name="locationpermission_new{{i}}" [checked]="element.orderingPermissions=='MANAGE'?'checked':''" value="MANAGE"  (change)="locpermissiontoggle(element.userId,'orderingPermissions','MANAGE')">
   
                       <label class="permissionlabel" for="locationpermission_new2{{i}}">Manage</label>
                   </div>
               </div> 
           </td>
           </ng-container>
           <ng-container matColumnDef="financialPermission">
               
               <td mat-cell *matCellDef="let element;let i = index">
                   <div class="permissionradioparent">
                       <div class="radioholder">
                           <input type="radio" class="permission_radiobtn" id="financialPermission0{{i}}" name="financialPermission{{i}}" [checked]="element.financialPermission=='NONE'?'checked':''" value="NONE" (change)="locpermissiontoggle(element.userId,'financialPermission','NONE')" >
       
                           <label class="permissionlabel" for="financialPermission0{{i}}">None</label>
                       </div>
                       <div class="radioholder">
                           <input type="radio" class="permission_radiobtn" id="financialPermission1{{i}}" name="financialPermission{{i}}" [checked]="element.financialPermission=='VIEWONLY'?'checked':''" value="VIEWONLY" (change)="locpermissiontoggle(element.userId,'financialPermission','VIEWONLY')">
       
                           <label class="permissionlabel" for="financialPermission1{{i}}">View Only</label>
                       </div>
                       <div class="radioholder ">
                           <input type="radio" class="permission_radiobtn" id="financialPermission2{{i}}" name="financialPermission{{i}}" [checked]="element.financialPermission=='MANAGE'?'checked':''" value="MANAGE" (change)="locpermissiontoggle(element.userId,'financialPermission','MANAGE')">
       
                           <label class="permissionlabel" for="financialPermission2{{i}}">Manage</label>
                       </div>
                   </div></td>
           </ng-container>
           
                   
           <tr mat-row *matRowDef="let row; columns: displayedlocation;">
               
           </tr>
       
        </table>
       </div>

 
    </div>
</ng-container>
<!-- user section table -->

<ng-container *ngIf="admintabsection.user">
    <table class="useraccounttable">
        <thead>
            <tr>
                <th>Account</th>
                <th>Account Permissions<div class="uktooltip">
                    <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="11" cy="11.575" r="10.5" stroke="black"></circle>
                        <path d="M11.448 13.5033H10.472L10.36 11.1513H11.128C12.552 11.1513 13.592 10.1753 13.592 8.86326C13.592 7.56726 12.632 6.59126 11.24 6.59126C10.136 6.59126 9.192 7.26326 9 8.31926H7.768C7.992 6.60726 9.32 5.50326 11.192 5.50326C13.256 5.50326 14.792 6.91126 14.792 8.84726C14.792 10.6393 13.48 11.9193 11.48 12.0313L11.448 13.5033ZM10.936 16.6873C10.488 16.6873 10.12 16.3353 10.12 15.8873C10.12 15.4393 10.488 15.0873 10.936 15.0873C11.384 15.0873 11.752 15.4393 11.752 15.8873C11.752 16.3353 11.384 16.6873 10.936 16.6873Z" fill="#212121"></path>
                    </svg>
                    <span>Includes Account Details e.g. main Contact, NHS Code, etc</span>
                </div>
                
                    </th>
                <th>Admin Permissions<div class="uktooltip">
                    <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="11" cy="11.575" r="10.5" stroke="black"></circle>
                        <path d="M11.448 13.5033H10.472L10.36 11.1513H11.128C12.552 11.1513 13.592 10.1753 13.592 8.86326C13.592 7.56726 12.632 6.59126 11.24 6.59126C10.136 6.59126 9.192 7.26326 9 8.31926H7.768C7.992 6.60726 9.32 5.50326 11.192 5.50326C13.256 5.50326 14.792 6.91126 14.792 8.84726C14.792 10.6393 13.48 11.9193 11.48 12.0313L11.448 13.5033ZM10.936 16.6873C10.488 16.6873 10.12 16.3353 10.12 15.8873C10.12 15.4393 10.488 15.0873 10.936 15.0873C11.384 15.0873 11.752 15.4393 11.752 15.8873C11.752 16.3353 11.384 16.6873 10.936 16.6873Z" fill="#212121"></path>
                    </svg>
                    <span>Admins can manage everything and set permissions for other users on the account</span>
                </div>
                
                </th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>{{rbainitaldata?.companyName}}</td>
                
                <td class="accountPermissions">
                <div class="permissionradioparent">
                    <div class="userradioholder">
                    
                        <input type="radio" class="permission_radiobtn_user" id="useraccper0" name="useraccper" value="NONE" [checked]="useraccountpermission=='NONE'?'checked':''"  (change)="adminpermssiontoggle('accountPermissions','NONE')">
                        <label class="permissionlabel" for="useraccper0">None</label>
                    </div>
                    <div class="userradioholder">
                        <input type="radio" class="permission_radiobtn_user" id="useraccper1" name="useraccper" value="VIEWONLY" [checked]="useraccountpermission=='VIEWONLY'?'checked':''" (change)="adminpermssiontoggle('accountPermissions','VIEWONLY')">
                        <label class="permissionlabel" for="useraccper1">View Only</label>
                    </div>	
                    <div class="userradioholder ">
                        <input type="radio" class="permission_radiobtn_user" id="useraccper2" name="useraccper" value="MANAGE" [checked]="useraccountpermission=='MANAGE'?'checked':''" (change)="adminpermssiontoggle('accountPermissions','MANAGE')" >
                        <label class="permissionlabel" for="useraccper2">Manage</label>
                    </div>
                  </div>
            </td>
            
            
                <td class="adminPermissions">
                <div class="permissionradioparent">
                    <div class="userradioholder ">
                    
                        <input type="radio" class="permission_radiobtn_userAdmin" id="useradminper0" name="useradminper" value="NONE" [checked]="useradminpermission=='NONE'?'checked':''" (change)="adminpermssiontoggle('adminPermissions','NONE')">
                        <label class="permissionlabel" for="useradminper0">None</label>
                    </div>
                    <div class="userradioholder">
                        <input type="radio" class="permission_radiobtn_userAdmin" id="useradminper1" name="useradminper" value="MANAGE" [checked]="useradminpermission=='MANAGE'?'checked':''" (change)="adminpermssiontoggle('adminPermissions','MANAGE')" >
                        <label class="permissionlabel" for="useradminper1">Manage</label>
                    </div>	
                  </div>
                  </td>
                
            </tr>
        </tbody>
        
            

    </table>
    <div class="adminpermission_locationtable">
    <div class="tablecustomheader adminuserheader">
        <div class="usercustomheader customheaderdiv">Location</div>
        <div class="usercustomheader customheaderdiv">Post Code</div>
        <div class="customheaderdiv usercustomheader">
            Ordering Permissions<div class="uktooltip">
                <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="11" cy="11.575" r="10.5" stroke="black"></circle>
                          <path d="M11.448 13.5033H10.472L10.36 11.1513H11.128C12.552 11.1513 13.592 10.1753 13.592 8.86326C13.592 7.56726 12.632 6.59126 11.24 6.59126C10.136 6.59126 9.192 7.26326 9 8.31926H7.768C7.992 6.60726 9.32 5.50326 11.192 5.50326C13.256 5.50326 14.792 6.91126 14.792 8.84726C14.792 10.6393 13.48 11.9193 11.48 12.0313L11.448 13.5033ZM10.936 16.6873C10.488 16.6873 10.12 16.3353 10.12 15.8873C10.12 15.4393 10.488 15.0873 10.936 15.0873C11.384 15.0873 11.752 15.4393 11.752 15.8873C11.752 16.3353 11.384 16.6873 10.936 16.6873Z" fill="#212121"></path>
                               </svg>
                <span>Includes Vaccine Orders and Returns, and Location Details</span>
            </div>
        </div>
        <div class="customheaderdiv usercustomheader">
            Financial Permissions<div class="uktooltip">
                <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="11" cy="11.575" r="10.5" stroke="black"></circle>
                          <path d="M11.448 13.5033H10.472L10.36 11.1513H11.128C12.552 11.1513 13.592 10.1753 13.592 8.86326C13.592 7.56726 12.632 6.59126 11.24 6.59126C10.136 6.59126 9.192 7.26326 9 8.31926H7.768C7.992 6.60726 9.32 5.50326 11.192 5.50326C13.256 5.50326 14.792 6.91126 14.792 8.84726C14.792 10.6393 13.48 11.9193 11.48 12.0313L11.448 13.5033ZM10.936 16.6873C10.488 16.6873 10.12 16.3353 10.12 15.8873C10.12 15.4393 10.488 15.0873 10.936 15.0873C11.384 15.0873 11.752 15.4393 11.752 15.8873C11.752 16.3353 11.384 16.6873 10.936 16.6873Z" fill="#212121"></path>
                         </svg>
                <span>Includes Invoices &amp; Credits</span>
            </div>
        </div>
    </div>
    <div class="adminscrolltable">
        <table mat-table [dataSource]="adminuser" class="admintable usertable">
           <ng-container matColumnDef="location">
               
               <td mat-cell *matCellDef="let element">{{element.location}} </td>
           </ng-container>
           <ng-container matColumnDef="postCode">
               
            <td mat-cell *matCellDef="let element">{{element.postCode}} </td>
        </ng-container>
           <ng-container matColumnDef="orderingPermissions">
               
               <td mat-cell *matCellDef="let element;let i = index"><div class="permissionradioparent">
                   <div class="radioholder">
                       <input type="radio" class="permission_radiobtn" id="userpermission_new0{{i}}" name="userpermission_new{{i}}" [checked]="element.orderingPermissions=='NONE'?'checked':''" value="NONE" (change)="locpermissiontoggle(element.locID,'orderingPermissions','NONE')">
   
                       <label class="permissionlabel" for="userpermission_new0{{i}}">None</label>
                   </div>
                   <div class="radioholder">
                       <input type="radio" class="permission_radiobtn" id="userpermission_new1{{i}}" name="userpermission_new{{i}}" [checked]="element.orderingPermissions=='VIEWONLY'?'checked':''" value="VIEWONLY" (change)="locpermissiontoggle(element.locID,'orderingPermissions','VIEWONLY')" >
   
                       <label class="permissionlabel" for="userpermission_new1{{i}}">View Only</label>
                   </div>
                   <div class="radioholder ">
                       <input type="radio" class="permission_radiobtn" id="userpermission_new2{{i}}" name="userpermission_new{{i}}" [checked]="element.orderingPermissions=='MANAGE'?'checked':''" value="MANAGE" (change)="locpermissiontoggle(element.locID,'orderingPermissions','MANAGE')">
   
                       <label class="permissionlabel" for="userpermission_new2{{i}}">Manage</label>
                   </div>
               </div> 
           </td>
           </ng-container>
           <ng-container matColumnDef="financialPermission">
               
               <td mat-cell *matCellDef="let element;let i = index">
                   <div class="permissionradioparent">
                       <div class="radioholder">
                           <input type="radio" class="permission_radiobtn" id="userfinancialPermission0{{i}}" name="userfinancialPermission{{i}}" [checked]="element.financialPermission=='NONE'?'checked':''" value="NONE" (change)="locpermissiontoggle(element.locID,'financialPermission','NONE')">
       
                           <label class="permissionlabel" for="userfinancialPermission0{{i}}">None</label>
                       </div>
                       <div class="radioholder">
                           <input type="radio" class="permission_radiobtn" id="userfinancialPermission1{{i}}" name="userfinancialPermission{{i}}" [checked]="element.financialPermission=='VIEWONLY'?'checked':''" value="VIEWONLY" (change)="locpermissiontoggle(element.locID,'financialPermission','VIEWONLY')">
       
                           <label class="permissionlabel" for="userfinancialPermission1{{i}}">View Only</label>
                       </div>
                       <div class="radioholder ">
                           <input type="radio" class="permission_radiobtn" id="userfinancialPermission2{{i}}" name="userfinancialPermission{{i}}" [checked]="element.financialPermission=='MANAGE'?'checked':''" value="MANAGE" (change)="locpermissiontoggle(element.locID,'financialPermission','MANAGE')">
       
                           <label class="permissionlabel" for="userfinancialPermission2{{i}}">Manage</label>
                       </div>
                   </div></td>
           </ng-container>
           
                   
           <tr mat-row *matRowDef="let row; columns: displayeduser;">
               
           </tr>
       
        </table>
       </div>

</div>
</ng-container>
</div>
<!-- setting section -->
<div class="col-xs-12 no-padding per_locationt3">
		
   
 
        <div class="settingtext">Settings</div>
        <div class="intialview">Initial View Table<div class="uktooltip">
                    <svg width="19" height="19" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <circle cx="11" cy="11.575" r="10.5" stroke="black"></circle>
                              <path d="M11.448 13.5033H10.472L10.36 11.1513H11.128C12.552 11.1513 13.592 10.1753 13.592 8.86326C13.592 7.56726 12.632 6.59126 11.24 6.59126C10.136 6.59126 9.192 7.26326 9 8.31926H7.768C7.992 6.60726 9.32 5.50326 11.192 5.50326C13.256 5.50326 14.792 6.91126 14.792 8.84726C14.792 10.6393 13.48 11.9193 11.48 12.0313L11.448 13.5033ZM10.936 16.6873C10.488 16.6873 10.12 16.3353 10.12 15.8873C10.12 15.4393 10.488 15.0873 10.936 15.0873C11.384 15.0873 11.752 15.4393 11.752 15.8873C11.752 16.3353 11.384 16.6873 10.936 16.6873Z" fill="#212121"></path>
                             </svg>
                    <span>When you first come to the Permissions Admin tab, how do you want to view the table?</span>
                </div>
        
        </div>
        <div class="permissionradioparent initialviewtable">
            <div class="settingradioholder">
            
                <input type="radio" class="initialpermission_radiobtn settingradio" id="Newuser_view0" name="Newuser_view" value="ACCOUNT" [checked]="rbainitaldata.userPermissionView=='Account'?'checked':''" (change)="settingtoggle('Account','initialviewtable')">
                <label class="permissionlabel" for="Newuser_view0">Account</label>
            </div>
            <div class="settingradioholder">
                <input type="radio" class="initialpermission_radiobtn settingradio" id="Newuser_view1" name="Newuser_view" value="LOCATION" [checked]="rbainitaldata.userPermissionView=='Location'?'checked':''" (change)="settingtoggle('Location','initialviewtable')">
                <label class="permissionlabel" for="Newuser_view1">Location</label>
            </div>	
            <div class="settingradioholder ">
                <input type="radio" class="initialpermission_radiobtn settingradio" id="Newuser_view2" name="Newuser_view" value="USER" [checked]="rbainitaldata.userPermissionView=='User'?'checked':''" (change)="settingtoggle('User','initialviewtable')">
                <label class="permissionlabel" for="Newuser_view2">User</label>
            </div>
        </div>

        <div class="intialview newuserview">New User Initial permissions<div class="uktooltip">
            <svg width="19" height="19" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="11" cy="11.575" r="10.5" stroke="black"></circle>
                            <path d="M11.448 13.5033H10.472L10.36 11.1513H11.128C12.552 11.1513 13.592 10.1753 13.592 8.86326C13.592 7.56726 12.632 6.59126 11.24 6.59126C10.136 6.59126 9.192 7.26326 9 8.31926H7.768C7.992 6.60726 9.32 5.50326 11.192 5.50326C13.256 5.50326 14.792 6.91126 14.792 8.84726C14.792 10.6393 13.48 11.9193 11.48 12.0313L11.448 13.5033ZM10.936 16.6873C10.488 16.6873 10.12 16.3353 10.12 15.8873C10.12 15.4393 10.488 15.0873 10.936 15.0873C11.384 15.0873 11.752 15.4393 11.752 15.8873C11.752 16.3353 11.384 16.6873 10.936 16.6873Z" fill="#212121"></path>
                                 </svg>
            <span>When new users join your account, what permissions do you want them to have at the beginning? It will be the same for all locations.<br>
                          When a new user joins your account, you will receive an email notification. You can then change their permissions on a per location basis.</span>
        </div>
        
        </div>

        <div class="col-xs-6 no-padding newpermission ordering_permission">
                        <div class="newpermissionText">Ordering</div>
         <div class="permissionradioparent orderingPermissions">
            <div class="settingradioholder">
            
                <input type="radio" class="orderingpermission_radiobtn settingradio" id="Ordering_new0" name="Ordering_new" value="NONE" [checked]="rbainitaldata?.newUserInitialOrderingPermission=='NONE'?'checked':''" (change)="settingtoggle('NONE','orderingPermissions')">
                <label class="permissionlabel" for="Ordering_new0">None</label>
            </div>
            <div class="settingradioholder ">
                <input type="radio" class="orderingpermission_radiobtn settingradio" id="Ordering_new1" name="Ordering_new" value="VIEWONLY" [checked]="rbainitaldata?.newUserInitialOrderingPermission=='VIEWONLY'?'checked':''" (change)="settingtoggle('VIEWONLY','orderingPermissions')">
                <label class="permissionlabel" for="Ordering_new1">View Only</label>
            </div>	
            <div class="settingradioholder">
                <input type="radio" class="orderingpermission_radiobtn settingradio" id="Ordering_new2" name="Ordering_new" value="MANAGE" [checked]="rbainitaldata?.newUserInitialOrderingPermission=='MANAGE'?'checked':''" (change)="settingtoggle('MANAGE','orderingPermissions')">
                <label class="permissionlabel" for="Ordering_new2">Manage</label>
            </div>
         </div>
                       </div>

        <div class="col-xs-6 no-padding newpermission Financial_permission">
            <div class="newpermissionText">Financial</div>
            <div class="permissionradioparent financialPermission">
               <div class="settingradioholder">
               
                   <input type="radio" class="financialpermission_radiobtn settingradio" id="Financial_new0" name="Financial_new" value="NONE" [checked]="rbainitaldata?.newUserInitialFinancialPermission=='NONE'?'checked':''" (change)="settingtoggle('NONE','financialPermission')">
                   <label class="permissionlabel" for="Financial_new0">None</label>
               </div>
               <div class="settingradioholder ">
                   <input type="radio" class="financialpermission_radiobtn settingradio" id="Financial_new1" name="Financial_new" value="VIEWONLY" [checked]="rbainitaldata?.newUserInitialFinancialPermission=='VIEWONLY'?'checked':''" (change)="settingtoggle('VIEWONLY','financialPermission')">
                   <label class="permissionlabel" for="Financial_new1">View Only</label>
               </div>	
               <div class="settingradioholder">
                   <input type="radio" class="financialpermission_radiobtn settingradio" id="Financial_new2" name="Financial_new" value="MANAGE" [checked]="rbainitaldata?.newUserInitialFinancialPermission=='MANAGE'?'checked':''" (change)="settingtoggle('MANAGE','financialPermission')">
                   <label class="permissionlabel" for="Financial_new2">Manage</label>
               </div>
           </div>
           </div>

    
    
    </div>
</div>


 <div class="" *ngIf="!rbainitaldata?.isCustomerAdmin"> 
    
    <div class="col-xs-12 col-sm-12 no-padding myprofile_tabContentHeader nonadminpermission">My Permissions</div>
    <div class="col-xs-12 col-sm-12 permissiontable1">
		<div class="permission_text">You have access to the following account level permissions and location specific permissions.</div>
		<div class="permission_text padding-T5">These permissions determine the level of functionality you have availble to you.</div>
	</div>
    <div class="col-xs-12 col-sm-12 permissiontable2">
		<table class="accountnonadmin">
			<thead>
				<tr>
					<th>Account</th>
					<th>Account Permissions<div class="uktooltip">
						<svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <circle cx="11" cy="11.575" r="10.5" stroke="black"></circle>
                                  <path d="M11.448 13.5033H10.472L10.36 11.1513H11.128C12.552 11.1513 13.592 10.1753 13.592 8.86326C13.592 7.56726 12.632 6.59126 11.24 6.59126C10.136 6.59126 9.192 7.26326 9 8.31926H7.768C7.992 6.60726 9.32 5.50326 11.192 5.50326C13.256 5.50326 14.792 6.91126 14.792 8.84726C14.792 10.6393 13.48 11.9193 11.48 12.0313L11.448 13.5033ZM10.936 16.6873C10.488 16.6873 10.12 16.3353 10.12 15.8873C10.12 15.4393 10.488 15.0873 10.936 15.0873C11.384 15.0873 11.752 15.4393 11.752 15.8873C11.752 16.3353 11.384 16.6873 10.936 16.6873Z" fill="#212121"></path>
                                 </svg>
						<span>Includes Account Details e.g. main Contact, NHS Code, etc</span>
					</div>
					
						</th>
					<th>Admin Permissions<div class="uktooltip">
						<svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                               			<circle cx="11" cy="11.575" r="10.5" stroke="black"></circle>
                               			<path d="M11.448 13.5033H10.472L10.36 11.1513H11.128C12.552 11.1513 13.592 10.1753 13.592 8.86326C13.592 7.56726 12.632 6.59126 11.24 6.59126C10.136 6.59126 9.192 7.26326 9 8.31926H7.768C7.992 6.60726 9.32 5.50326 11.192 5.50326C13.256 5.50326 14.792 6.91126 14.792 8.84726C14.792 10.6393 13.48 11.9193 11.48 12.0313L11.448 13.5033ZM10.936 16.6873C10.488 16.6873 10.12 16.3353 10.12 15.8873C10.12 15.4393 10.488 15.0873 10.936 15.0873C11.384 15.0873 11.752 15.4393 11.752 15.8873C11.752 16.3353 11.384 16.6873 10.936 16.6873Z" fill="#212121"></path>
                               		</svg>
						<span>Admins can manage everything and set permissions for other users on the account</span>
					</div>
					
					</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>{{rbainitaldata?.companyName}}</td>
					<td><ng-container *ngIf="nonadminfull?.accountPermissions=='NONE'">None</ng-container>
                        <ng-container *ngIf="nonadminfull?.accountPermissions=='MANAGE'">Manage</ng-container>
                        <ng-container *ngIf="nonadminfull?.accountPermissions=='VIEWONLY'">View Only</ng-container>
                        
                        </td><!-- hard coded value -->
					<td>
                        <ng-container *ngIf="nonadminfull?.adminPermissions=='false';else mangeshow">None</ng-container>
                        <ng-template #mangeshow> Manage</ng-template>
                    </td>
					
				</tr>
			</tbody>
			
				

		</table>

	</div>
    <div class="col-xs-12 col-sm-12 permissiontable3">
		<div class="">
		
       
	      
            <table id="locationnonadmin" class="nonadmin-table" mat-table [dataSource]="nonadmin" matSort (matSortChange)="announceSortChange($event)">

                   

                <ng-container matColumnDef="location">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true" sortActionDescription="Sort by location">
                        Location
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.location}} </td>
                </ng-container>
                <ng-container matColumnDef="postCode">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true" sortActionDescription="Sort by postcode">
                        Postcode
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.postCode}} </td>
                </ng-container>

                <ng-container matColumnDef="orderingPermissions">
                    <th mat-header-cell *matHeaderCellDef>
                        Ordering Permissions<div class="uktooltip">
                            <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <circle cx="11" cy="11.575" r="10.5" stroke="black"></circle>
                                      <path d="M11.448 13.5033H10.472L10.36 11.1513H11.128C12.552 11.1513 13.592 10.1753 13.592 8.86326C13.592 7.56726 12.632 6.59126 11.24 6.59126C10.136 6.59126 9.192 7.26326 9 8.31926H7.768C7.992 6.60726 9.32 5.50326 11.192 5.50326C13.256 5.50326 14.792 6.91126 14.792 8.84726C14.792 10.6393 13.48 11.9193 11.48 12.0313L11.448 13.5033ZM10.936 16.6873C10.488 16.6873 10.12 16.3353 10.12 15.8873C10.12 15.4393 10.488 15.0873 10.936 15.0873C11.384 15.0873 11.752 15.4393 11.752 15.8873C11.752 16.3353 11.384 16.6873 10.936 16.6873Z" fill="#212121"></path>
                                     </svg>
                            <span>Includes Vaccine Orders and Returns, and Location Details</span>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.orderingPermissions}} </td>
                </ng-container>
                <ng-container matColumnDef="financialPermission">
                    <th mat-header-cell *matHeaderCellDef>
                        Financial Permissions
                        <div class="uktooltip">
                            <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                   <circle cx="11" cy="11.575" r="10.5" stroke="black"></circle>
                                   <path d="M11.448 13.5033H10.472L10.36 11.1513H11.128C12.552 11.1513 13.592 10.1753 13.592 8.86326C13.592 7.56726 12.632 6.59126 11.24 6.59126C10.136 6.59126 9.192 7.26326 9 8.31926H7.768C7.992 6.60726 9.32 5.50326 11.192 5.50326C13.256 5.50326 14.792 6.91126 14.792 8.84726C14.792 10.6393 13.48 11.9193 11.48 12.0313L11.448 13.5033ZM10.936 16.6873C10.488 16.6873 10.12 16.3353 10.12 15.8873C10.12 15.4393 10.488 15.0873 10.936 15.0873C11.384 15.0873 11.752 15.4393 11.752 15.8873C11.752 16.3353 11.384 16.6873 10.936 16.6873Z" fill="#212121"></path>
                                     </svg>
                            <span>Includes Invoices &amp; Credits</span>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.financialPermission}} </td>
                </ng-container>
                <ng-container matColumnDef="locID">
                    <th mat-header-cell *matHeaderCellDef class="nonadminlocid">
                        
                    </th>
                    <td mat-cell class="nonadminlocid" *matCellDef="let element">{{element.locID}} </td>
                </ng-container>

             

                <tr mat-header-row *matHeaderRowDef="displayedColumnsuk;sticky: true"></tr>
                
                <tr mat-row *matRowDef="let row; columns: displayedColumnsuk;">
                    
                </tr>
            
                </table>


		
	</div>
	</div>
    <div class="col-xs-12 col-sm-12 permissiontable4">
		<div class="permissiontable4_text">
			<div class="padding-B10">You may request changes by contacting an admin for this account:</div>
			<div class="centersubtext padding-B10" *ngFor="let item of rbainitaldata?.custAdminDataList">{{item.userName}}<span class="permissionmail">( <a href="'mailto:'+item?.userId">{{item?.userId}}</a>)</span> </div>
			
			<div class="padding-T10">If you are unable to reach an admin, please contact&nbsp;<a href="/contact-us" class="rbaflu360support">flu360 | Support</a>.</div>
		</div>
	</div>
</div>

</div>