import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ContentService } from '../../spartacus/services/content.service';
import { Observable, combineLatest } from 'rxjs';
import { CmsComponentData } from '@spartacus/storefront';
import { CmsBannerComponent, OccConfig } from '@spartacus/core';
import { map } from 'rxjs/operators';
 
@Component({
  selector: 'app-custom-carousel',
  templateUrl: './custom-carousel.component.html',
  styleUrls: ['./custom-carousel.component.scss']
})
 
export class CustomCarouselComponent implements OnInit {
  thirdnode: Observable<any> = this.bannerComponent.data$;
  mergedObject1: any = [];
  toggleArray: any = [];
  subTileData: any = [];
  subTileData1: any = [];
  selectedTile: any;
  selectedTileIndex: number | null = 0; // Default to the first tile
  mergedResults: any;
  isModalOpen: boolean=false;
  item: any;
  constructor(
    private accessService: ContentService,
    public bannerComponent: CmsComponentData<CmsBannerComponent>,
    protected config: OccConfig,
    private cd: ChangeDetectorRef
  ) {}
 
  getImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.desktop.url;
  }
  getImage1(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.url;
  }
  ngOnInit() {
   
    this.thirdnode.subscribe((res: any) => {
      this.productTabs(res);
    });
    
  }
 
   productTabs(res: any) {
    const prodList = res.AllResources.split(" ");
    const observables = prodList.map((el: any) => this.accessService.aqivResources(el));
    combineLatest(observables).pipe(map((results: any) => results.flat())).subscribe((mergedResults: any) => {
      this.mergedObject1 = mergedResults;
      this.mergedObject1.forEach((_: any, i: number) => this.toggleArray[i] = i === 0);
      this.selectedTile = this.mergedObject1[0]; // Default to the first tile
      this.cd.detectChanges();
      // Check and fetch sub-tile data for the fourth tile
      const fourthTile = this.mergedObject1.find((item: any) => item);
      if (fourthTile && fourthTile.fourthCOMP) {
        this.fetchSubTileData(fourthTile.fourthCOMP.vaccineInfoFeaturedResourcesfactor);
      }
      const thirdTile = this.mergedObject1.find((item: any) => item.uid =='QIVCCarausalComp5');
      if(thirdTile && thirdTile.thirdCOMP){
        this.fetchSubTileData1(thirdTile.thirdCOMP.vaccineFeaturedResources);
      }
    });
  }
  openModal() {
    this.isModalOpen=true;
    document.body.style.overflowY = 'hidden'; // Prevent scrolling when modal is open
    document.getElementById('commonleaving_abandonedPop')!.style.display = 'block';
}
closeModal() {
    document.body.style.overflowY = 'auto'; // Restore scrolling
    document.getElementById('commonleaving_abandonedPop')!.style.display = 'none';
}
continueAndClose(url: string) {
    this.closeModal(); // Close the modal first
    setTimeout(() => {
        window.location.href = url; // Redirect after modal closes
    }, 300); // Small delay to allow modal close animation
}


  fetchSubTileData1(resourceString: string) {
    const subTileIds = resourceString.split(' ');
    const subTileObservables = subTileIds.map((id: string) => this.accessService.aqivResources(id));
    combineLatest(subTileObservables).subscribe((subTileResults: any) => {
      this.subTileData1 = subTileResults;
      this.cd.detectChanges();
    });
  }
  fetchSubTileData(resourceString: string) {
    const subTileIds = resourceString.split(' ');
    const subTileObservables = subTileIds.map((id: string) => this.accessService.aqivResources(id));
    combineLatest(subTileObservables).subscribe((subTileResults: any) => {
      this.subTileData = subTileResults;
      this.cd.detectChanges();
    });
  }
  toggleNav1(i: number) {
    this.toggleArray = this.toggleArray.map((_: any, index: number) => index === i);
    this.selectedTile = this.mergedObject1[i]; // Update selected tile content
    // Fetch sub-tile data when the fourth tile is toggled
    if (this.mergedObject1[i].thirdCOMP) {
      this.fetchSubTileData1(this.mergedObject1[i].thirdCOMP.vaccineFeaturedResources);
    }
    this.cd.detectChanges();
  }
  toggleNav(i: number) {
    this.toggleArray = this.toggleArray.map((_: any, index: number) => index === i);
    this.selectedTile = this.mergedObject1[i]; // Update selected tile content
    // Fetch sub-tile data when the fourth tile is toggled
    if (this.mergedObject1[i].fourthCOMP) {
      this.fetchSubTileData(this.mergedObject1[i].fourthCOMP.vaccineInfoFeaturedResourcesfactor);
    }
    this.cd.detectChanges();
  }
  
  openExtLink(url:string){
    window.open(url,'_blank')
    this.closeModal();
  }
}
