<table
  class="credittable d-none d-md-block d-lg-block"
  mat-table
  [dataSource]="dataSource" matSortDirection="desc"
  matSort
  (matSortChange)="announceSortChange($event)"
>
  <ng-container matColumnDef="billTo">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      disableClear="true"
      sortActionDescription="Sort by Address"
    >
      Billing Address
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.billTo.billingAddress }}
    </td>
  </ng-container>

  <ng-container matColumnDef="invoiceDate">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      disableClear="true"
      sortActionDescription="Sort by Date"
      aria-sort="ascending"
    >
      {{ dataTable === 3 ? 'Date Applied': 'Date Issued' }}
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.invoiceDate }}
    </td>
  </ng-container>

  <ng-container matColumnDef="invoiceNumber">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      disableClear="true"
      class="creditWhiteSpace"
      sortActionDescription="Sort by Credit"
    >
      Credit Note #
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.invoiceNumber }}
    </td>
  </ng-container>

  <ng-container matColumnDef="formattedAmountWithTax">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      disableClear="true"
      class="creditWhiteSpace"
      sortActionDescription="Sort by Amount"
    >
      Credit Amount
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.formattedAmountWithTax }}
    </td>
  </ng-container>
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element; let i = index">
      <div class="col-sm-12 no-padding">
        <div class="viewreportchild">
          <a
            class="anchorlink"
            (click)="financialgetDownloadInvoiceData(element.invoiceNumber)"
            ><span class="anchorunderline">View Report</span>
            <div class="global_blackarrow"></div
          ></a>
        </div>
        <div style="clear:both;"></div>
        <div *ngIf="element.isDownloading" class="download_txt"><img src="../assets/images/loading_download.gif" width="20px" alt="Loading...">&nbsp;Downloading...</div>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="creditcolumns"></tr>
  <tr mat-row *matRowDef="let row; columns: creditcolumns"></tr>
  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell no-matching-invoices">
      No matching records found
    </td>
  </tr>
</table>
