import { ChangeDetectorRef, Component } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { CmsBannerComponent, OccConfig } from '@spartacus/core';
import { Observable } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ContentService } from '../../spartacus/services/content.service';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-homepage-uk-pislot',
  templateUrl: './homepage-uk-pislot.component.html',
  styleUrl: './homepage-uk-pislot.component.scss'
})
export class HomepageUKPISlotComponent {
  paracontent:string="";
  urllink:string="";
  constructor(public component: CmsComponentData<any>,protected contentService:ContentService,private cdr:ChangeDetectorRef,private sanitizer:DomSanitizer,protected config: OccConfig, private userInfoService:UserInfoService, private router: Router) {  
  }

  ngOnInit(){
    
    this.component.data$.subscribe((data) =>{
      
      this.paracontent = data.name;
      this.urllink = data.url;
     

    })
  }

  handleClick() {
    const href = window.location.href;
    let inPlaceOrder:any;
    this.userInfoService.getInPlaceOrder().subscribe(val => {
      inPlaceOrder = val;
    })
    if(href.includes("start-order") && inPlaceOrder){
      this.userInfoService.setRouteLink(this.urllink);
      this.userInfoService.setQueryParams("");
      this.userInfoService.setPopUpValue(true);
    }
    else {
      this.router.navigate([this.urllink]);
    }
  }

}
