import { ChangeDetectorRef, Component, Renderer2 } from '@angular/core';
import { BaseSiteService } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-topbanner-uk',
  templateUrl: './topbanner-uk.component.html',
  styleUrl: './topbanner-uk.component.scss'
})
export class TopbannerUkComponent {
  currentSite?: string;
  isUserLoggedIn?: boolean;
  userInfo: any;

  constructor(public topBannerUkComponent: CmsComponentData<any>,private cdr:ChangeDetectorRef,private baseSiteService:BaseSiteService,private userInfoService:UserInfoService,public renderer:Renderer2) {
  }

  topBannerUkData: Observable<any>= this.topBannerUkComponent.data$;

  ngOnInit(){
    this.baseSiteService.getActive().subscribe(site => {
      this.currentSite = site;
    });
    this.userInfoService.userLoggedIn$.subscribe(res=>{
      this.isUserLoggedIn=res;
      if(this.isUserLoggedIn){
        this.userInfoService.getUserData().subscribe(res=>{
          if(res){
          this.userInfo=res;
          this.cdr.detectChanges();
         const element=document?.querySelector('app-topbanner-uk') as HTMLElement;
           
          if(this.userInfo?.webEnabled == true){
            
            element?.setAttribute("style","display:block");
           // this.renderer?.setStyle(document?.querySelector('app-topbanner-uk'),'display','block');
            this.cdr.detectChanges();
          }
          else{
            element?.setAttribute("style","display:none");
           // this.renderer?.setStyle(document?.querySelector('app-topbanner-uk'),'display','none');
           this.cdr.detectChanges();
          }
         
          }
        });
      }
    });
    
  
  }
      
}
