import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  inject,
  Input,
  EventEmitter,
  Output
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { DownloadService } from '../../financial-dashboard/download.service'

export interface Credit {
  isDownloading: boolean;
  billTo: any;
  invoiceDate: string;
  invoiceNumber: number;
  formattedAmountWithTax: string;
  status: string;
}

@Component({
  selector: 'app-invoice-financial-credit-table',
  templateUrl: './invoice-financial-credit-table.component.html',
  styleUrl: './invoice-financial-credit-table.component.scss',
})
export class InvoiceFinancialCreditTableComponent implements OnInit {
  lastSortedCol:string=''
  @Input() invoices: Credit[] = [];
  @Input() dataTable: number = 1;
  @Output() downloadInvoice = new EventEmitter<any>();
  @ViewChild(MatSort) sort!: MatSort;

  creditcolumns: string[] = [
    'billTo',
    'invoiceDate',
    'invoiceNumber',
    'formattedAmountWithTax',
    'status',
  ];
  dataSource = new MatTableDataSource<Credit>();

  private _liveAnnouncer = inject(LiveAnnouncer);

  constructor(
    private cd: ChangeDetectorRef,
    private downloadService: DownloadService
  ) {}
  /** Announce the change in sort state for assistive technology. */
  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.invoices);
    this.dataSource.sort = this.sort;
    this.dataSource.data.forEach(element => {
      element.isDownloading = false;
    });

    this.downloadService.downloadStatus$.subscribe(status => {
      const element = this.dataSource.data.find(item => item.invoiceNumber === status.invnumber);
      if (element) {
        element.isDownloading = status.isDownloading;
        this.cd.detectChanges();
      }
    });
  }
  ngAfterViewInit() {
    const sortState: Sort = {active: 'invoiceDate', direction: 'desc'};
    this.sort.active = sortState.active;
    this.sort.direction = sortState.direction;
    this.sort.sortChange.emit(sortState);
  }
  announceSortChange(sortState: Sort) {
  
    const active = sortState.active;
      let direction = sortState.direction;
    
    if (sortState.direction) {  
      if(!direction){
        direction = 'asc';
        this.sort.direction = direction;
      }
      if(active !== this.lastSortedCol){
        direction = 'desc';
        this.sort.direction = 'desc';
  
      }
      this.lastSortedCol = active;
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
    this.dataSource.sort = this.sort;
    this.cd.detectChanges();
  }
  Isdownloading:boolean = false;
  applyFilter(filterValue:string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  financialgetDownloadInvoiceData(invnumber: number) {
    const row = this.dataSource.data.find(item => item.invoiceNumber === invnumber);
    if (row) {
      row.isDownloading = true;
      this.downloadInvoice.emit(invnumber);
    }
  }
}
