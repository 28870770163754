import { Injectable,Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { OccEndpointsService } from '@spartacus/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GetgtmidService {
  //private document: Document;
  constructor( private document: Document) {

  }
 
    getGtmId(): string  {
    const hostname = this.document.location.hostname;

    if (hostname.includes('localhost') || hostname.includes('com')) {
      return environment.GtmUsId ;
    } else if (hostname.includes('co.uk')) {
       return environment.GtmUkId;
    }
    return ""

    }
  
}





