import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { UserInfoService } from '../../spartacus/services/user-info.service';

@Component({
  selector: 'app-rightsection',
  templateUrl: './rightsection.component.html',
  styleUrl: './rightsection.component.scss'
})
export class RightsectionComponent {
  displayedColumns: string[] = ['combined'];
  dataSource:any=[];
  rightdata:any;
  ordersummarydata:any;
  product1: string='';
  pack1: string='';
  product2:string="";
  pack2: string="";
  orderrbaright:any

  
  constructor(private userInfoService: UserInfoService,private cd:ChangeDetectorRef){}

ngOnInit() {

  this.userInfoService.leftsection().subscribe((res)=>{
  this.ordersummarydata=res;
  this.upcomingseason(this.ordersummarydata?.rightSectionSeason);
  this.cd.detectChanges();
  });
  this.orderrbaright=localStorage.getItem('rbapermission');
  this.orderrbaright=JSON.parse(this.orderrbaright);
  this.cd.detectChanges();
 
  
 
}
seasonclick(value:string){
  const obj = {
    popup: true,
    season:value
    
  }
  this.userInfoService.setpopupseason(obj);
  
}
upcomingseason(season:string){
  this.userInfoService.upcomingorderUk(season).subscribe((res)=>{
    this.rightdata=res;
    this.product1=this.rightdata?.products[0]?.baseProductName.split("-")[0];
    this.pack1=this.rightdata?.products[0]?.baseProductName.split("-")[1];
    this.product2=this.rightdata?.products[1]?.baseProductName.split("-")[0];
    this.pack2=this.rightdata?.products[0]?.baseProductName.split("-")[1];
 
    this.dataSource=this.rightdata.seasonData
    this.cd.detectChanges();
  })
}
}
