import { ChangeDetectorRef, Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService, CmsBannerComponent } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { StartOrderService } from '../../custom-start-order/start-order.service';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { ContentService } from '../../spartacus/services/content.service';
import { Observable, take } from 'rxjs';

@Component({
  selector: 'app-amend-product-page',
  templateUrl: './amend-product-page.component.html',
  styleUrl: './amend-product-page.component.scss'
})
export class AmendProductPageComponent {
  showClearBtn: boolean = false;
  prodVal: any;
  errorMsg: string = '';
  productData: any;
  seasonValue: string = '';
  totalCost: number = 0.00;
  disableDeliveryBtn = false;
  enablePage: any;
  route: any;
  queryParams:any;
  isLoading: boolean = false;
  ruleloopindex = 4;
  disabledelivery: boolean = false;
  showAmendDeliveryPage: boolean = false;
  disableRepeatDeliveryBtn: boolean = false;
  isDeliveryBtnClicked: boolean = false;
  showErrorMsg: boolean = false;
  showAmendProductPage: boolean = false;
  isamendFlow: boolean = true;
  emailId: string = 'flu.salesuk@seqirus.com';
  deliverydata: any;
  inseason: string = '';
  preseason: string = '';
  deliveryseason:string='';
  maxdeliveryslot:number=5000;
  mindeliveryslot:number=100;
  minproductdose:number=200;
  maxproductdose:number=10000;
  maxdeliveryslotwithcomma="5,000"
  minproductwithcomma="200";
  maxproductwithcomma="10,000";
  editdeliverydata:any;
  landingapi: any;
  aqivtotalValue: any;
  confirmwithzeroaqiv:boolean=false;
  confirmwithzeroqivc:boolean=false;
  qivctotalValue:any;
  showExitPopUp: boolean = false;
  amendapifailure:boolean=false;
  isUserLoggedIn: Observable<boolean> = this.authService.isUserLoggedIn();
  constructor(public component: CmsComponentData<CmsBannerComponent>, private cdr: ChangeDetectorRef, private router: Router, private startOrderService: StartOrderService, private userInfoService: UserInfoService, private fb: FormBuilder, private contentservice: ContentService,private authService: AuthService) {

  }
  ngOnInit() {
    this.contentservice.startapi().subscribe(data => {
      this.landingapi=data;
      this.inseason=this.landingapi?.currentInSeason;
      this.preseason=this.landingapi?.prePreSeason;
      this.cdr.detectChanges();
    });
    if(window.location.href.includes('editOrder') && this.seasonValue == '' && this.enablePage == undefined ) {
      const obj = {
        showukOrdersLeftSection: false,
        showukOrdersBanner: false,
        showukCartBanner: false,
        showukCartReviewSection: false,
        showAmendProductPage: true,
      }
      this.userInfoService.setInPlaceOrder(true);
      this.userInfoService.sendstartorderseason('2025-2026');
      this.userInfoService.setEnablePage(obj);
      const locationPayload = {
        orderID: localStorage.getItem('orderId'),
        requestType: "AM",
        season: '2025-2026'
      }
      this.startOrderService.getAmendProductDetails(locationPayload);
      this.isLoading=false;
      this.cdr.detectChanges();
    }
    this.userInfoService.getstartorderseason().subscribe((seasonValue: any) => {
      this.seasonValue = seasonValue;
      this.deliveryseason=seasonValue.split("-")[0];//deliverychanges
      this.userInfoService.getEnablePage().subscribe((data: any) => {
        this.enablePage = data;
        if (this.enablePage.showAmendProductPage || this.showAmendProductPage) {
          this.isLoading = true;
          this.scrollToTop();
          this.startOrderService.getProductData().subscribe((data: any) => {
            if(data?.priceResponse?.status!='Failure' || data?.showAPIFailurePopUp ==false){
            if (data && (this.enablePage.showAmendProductPage || this.showAmendProductPage)) {
              this.productData = data;

                this.minproductdose=this.productData.amendOrderConstraintList.minLocProdQuantity;
                this.maxproductdose=this.productData.amendOrderConstraintList.maxLocProdQuantity;
             
              this.minproductwithcomma=this.numberWithCommas(this.minproductdose)
              this.maxproductwithcomma=this.numberWithCommas(this.maxproductdose)

              if(this.productData.orderSummary.orderedandShippedProducts.orderedProducts.length<2){


                if(this.productData.orderSummary.aqivTotal==0){
            const addobj={
              
              "baseProdName":"aTIV 10 Pack",
              "confirmedDate": "2025-02-02",
              "confirmedQty": null,
              "materialID": "10054972",
              "prodAddedDate": "2025-02-02",
              "productName": "aQIV 10 Pack",
              "productQuantity": null,
              "quantity": null,
              "quantityInDoses":null,
              "quantityInPacks": 100,
              "salesReturn": "10.00",
              "seqId":1,
              "totalInvoiceAmount": 0,
              "type": "Increment"
            }
            this.confirmwithzeroaqiv=true
            this.productData.orderSummary.orderedandShippedProducts.orderedProducts.unshift(addobj);
          

          }else if(this.productData.orderSummary.qivcTotal==0){
            const addobj={
            "baseProdName":"TIVc 10 Pack",
            "confirmedDate": "2025-02-02",
            "confirmedQty": null,
            "materialID": "10055059",
            "prodAddedDate": "2025-02-02",
            "productName": "QIVc 10 Pack",
            "productQuantity": null,
            "quantity": null,
            "quantityInDoses":null,
            "quantityInPacks": 100,
            "salesReturn": "10.00",
            "seqId":2,
            "totalInvoiceAmount": 0,
            "type": "Increment"
          }
          this.confirmwithzeroqivc=true;
          this.productData.orderSummary.orderedandShippedProducts.orderedProducts.push(addobj);

          }
              
                
              }
             
              this.productData.orderSummary.orderedandShippedProducts.orderedProducts.forEach((el: any, index: number) => {
                el['errorMsg'] = '';
                if(this.productData.orderSummary.status == 'Order Pending Approval') {
                  // el.quantityInDoses = this.numberWithCommas(el.quantityInDoses);
                  if(el.quantityInDoses){
                  el.quantityInDoses = this.numberWithCommas(el?.quantityInDoses);
                  }
                  this.handleAmendSaveValidation(el,index);
                } else {
                  // el.confirmedQty = this.numberWithCommas(el.confirmedQty);
                  if(el.baseProdName === 'aTIV 10 Pack') {
                  const initialValue = el.confirmedQty;
                  const percentage = el.baseProdName === 'aTIV 10 Pack' ? this.productData.amendOrderConstraintList.aqivPercentageAmendOrder : this.productData.amendOrderConstraintList.qivcPercentageAmendOrder;
                  this.aqivtotalValue = (initialValue) + ((percentage / 100) * initialValue);
                  if(this.confirmwithzeroaqiv==false){
                  el.quantityInDoses = this.numberWithCommas(el.quantityInDoses);
                  }
                  } else if(el.baseProdName === 'TIVc 10 Pack' ){
                    const initialValue1 = el.confirmedQty;
                  const percentage1 = el.baseProdName === 'aTIV 10 Pack' ? this.productData.amendOrderConstraintList.aqivPercentageAmendOrder : this.productData.amendOrderConstraintList.qivcPercentageAmendOrder;
                  this.qivctotalValue = (initialValue1) + ((percentage1 / 100) * initialValue1);
                  if(this.confirmwithzeroqivc==false){
                  el.quantityInDoses = this.numberWithCommas(el.quantityInDoses);
                  }
                  }
                  else {

                    el.quantityInDoses = this.numberWithCommas(el.quantityInDoses);
                    this.handleAmendConfirmValidation(el,index);
                  }
                } 
               
              })
              this.handleOnDisable('initialMap');
              this.isLoading = false;
              this.cdr.detectChanges();
              this.startOrderService.setProductData(null);
           }
           }else{
            this.isLoading = false;
            this.userInfoService.setInPlaceOrder(false);
            this.amendapifailure=true;
            this.cdr.detectChanges();
          } 
          }, (error) => {
            console.log('error: ', error);
            this.isLoading = false;
            this.userInfoService.setInPlaceOrder(false);
            this.amendapifailure=true;
            this.cdr.detectChanges();
          })
        } if (this.enablePage.isDeliveryBackBtnClicked) {
          this.showAmendDeliveryPage = true;
        }
      })
    })

    this.userInfoService.getPopValue().subscribe((val: boolean) => {
      this.showExitPopUp = val;
      this.cdr.detectChanges();
    })
  }
  ngOnDestroy() {
    localStorage.removeItem('orderId');
  }
  
  handleOnExitClick() {
    this.userInfoService.setPopUpValue(true);
  }
  handleOnProdValueChange(productValue: string, prodIndex: number, product: string) {
    
    if(productValue!=null){
    this.productData.orderSummary.orderedandShippedProducts.orderedProducts[prodIndex].quantityInDoses = this.numberWithCommas(Number(productValue.replace(/,/g, "")));
    }
    if(product == 'quantityInDoses') {
      this.handleAmendSaveValidation(this.productData.orderSummary.orderedandShippedProducts.orderedProducts[prodIndex],prodIndex);
    } else {
     this.handleAmendConfirmValidation(this.productData.orderSummary.orderedandShippedProducts.orderedProducts[prodIndex],prodIndex);
    }
    this.calculateTotalDose();
    this.handleOnDisable('prodValueChange');
    this.cdr.detectChanges();
    // You can increase up to 240
    // You can enter 200 - 10,000 doses
  }
  calculateTotalDose() {
    this.totalCost = 0.00;
    this.productData.orderSummary.totalCost=0;
    this.productData.orderSummary.orderedandShippedProducts.orderedProducts.forEach((el: any) => {
        if (this.productData.orderSummary.status == 'Order Pending Approval') {
          const material = this.productData.priceResponse.materials.find((data: any) => data.materialID === el.materialID)
          this.productData.orderSummary.totalCost += (Number(el.quantityInDoses?.replace(/,/g, "")) * Number(material.salePrice));
        } else {
          const material = this.productData.priceResponse.materials.find((data: any) => data.materialID === el.materialID)
          this.productData.orderSummary.totalCost += (Number(el.quantityInDoses?.replace(/,/g, "")) * Number(material.salePrice));
        }
    })
  }
  onSubmit() {
  }
  handleOnDisable(mappingText: string) {
    if(this.productData?.orderSummary.orderedandShippedProducts.orderedProducts[0]?.errorMsg != '' || this.productData?.orderSummary.orderedandShippedProducts.orderedProducts[1]?.errorMsg != '') { 
      this.disableDeliveryBtn = true;
    } 
    if(mappingText == 'prodValueChange') {
      this.disableDeliveryBtn = Number(this.productData.orderSummary.orderedandShippedProducts.orderedProducts[0].quantityInDoses?.replace(/,/g, "")) == 0 && Number(this.productData.orderSummary.orderedandShippedProducts.orderedProducts[1].quantityInDoses?.replace(/,/g, "")) == 0;
    } else {
      this.disableDeliveryBtn = this.productData.orderSummary.orderedandShippedProducts.orderedProducts[0].quantityInDoses == 0 && this.productData.orderSummary.orderedandShippedProducts.orderedProducts[1].quantityInDoses == 0;
    }


    // else if(this.productData.orderSummary.status == 'Order Pending Approval') {
    //   if(mappingText == 'prodValueChange') {
    //     this.disableDeliveryBtn = Number(this.productData.orderSummary.orderedandShippedProducts.orderedProducts[0].quantityInDoses?.replace(/,/g, "")) == 0 && Number(this.productData.orderSummary.orderedandShippedProducts.orderedProducts[1].quantityInDoses?.replace(/,/g, "")) == 0;
    //   } else {
    //     this.disableDeliveryBtn = this.productData.orderSummary.orderedandShippedProducts.orderedProducts[0].quantityInDoses == 0 && this.productData.orderSummary.orderedandShippedProducts.orderedProducts[1].quantityInDoses == 0;
    //   }
    // } 
    // else {
    //   if(mappingText == 'prodValueChange') {
    //     this.disableDeliveryBtn = Number(this.productData.orderSummary.orderedandShippedProducts.orderedProducts[0].confirmedQty?.replace(/,/g, "")) == 0 && Number(this.productData.orderSummary.orderedandShippedProducts.orderedProducts[1].confirmedQty?.replace(/,/g, "")) == 0;
    //   } else {
    //     this.disableDeliveryBtn = this.productData.orderSummary.orderedandShippedProducts.orderedProducts[0].confirmedQty == 0 && this.productData.orderSummary.orderedandShippedProducts.orderedProducts[1].confirmedQty == 0;
    //   }
    // }
              
    this.cdr.detectChanges();
  }
  handleOnRemoveBtn(index: number) {
    this.productData.shippingData.splice(index, 1);
    if (this.productData.shippingData.length == 1) {
      this.productData.shippingData[0].disableRemove = true;
    }
    this.cdr.detectChanges();
  }
  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  handleOnBackbtnClick() {
    const obj = {
      showLandingPage: false,
      showLocationPage: true,
      showAmendProductPage: false,
      isProductBackBtnClicked: true,
      showAmendReviewPage: false
    }
    this.userInfoService.setEnablePage(obj);
    this.scrollToTop();
  }
  handleOnDeliveryBtnClick() {

    if(this.productData.orderSummary.orderedandShippedProducts?.orderedProducts[0]?.errorMsg == '' && this.productData.orderSummary.orderedandShippedProducts?.orderedProducts[1]?.errorMsg == '') {
      const confirmOrderArray = [
        {
          "locID": this.productData.orderSummary.shipToID,
          
          "createOrderProductData": [
            {
              "productCode": Number(this.productData.orderSummary.orderedandShippedProducts.orderedProducts[0].materialID),
              "productQuantity": Number(this.productData.orderSummary.orderedandShippedProducts.orderedProducts[0].quantityInDoses?.replace(/,/g, ""))
            },
            {
              "productCode":  Number(this.productData.orderSummary.orderedandShippedProducts.orderedProducts[1].materialID),  
              "productQuantity": Number(this.productData.orderSummary.orderedandShippedProducts.orderedProducts[1].quantityInDoses?.replace(/,/g, "")),  
            }
          ]
        }
      ]
      const saveOrderArray = [
        {
          "locID": this.productData.orderSummary.shipToID,
          
          "createOrderProductData": [
            {
              "productCode": Number(this.productData.orderSummary.orderedandShippedProducts.orderedProducts[0].materialID),
              "productQuantity": Number(this.productData.orderSummary.orderedandShippedProducts.orderedProducts[0].quantityInDoses?.replace(/,/g, "")) 
            },
            {
              "productCode":  Number(this.productData.orderSummary.orderedandShippedProducts.orderedProducts[1].materialID),  
              "productQuantity": Number(this.productData.orderSummary.orderedandShippedProducts.orderedProducts[1].quantityInDoses?.replace(/,/g, "")),  
            }
          ]
        }
      ]
      const payload = {
        requestType: "AM",
        orderID: this.productData.orderSummary.orderId,
        season: this.seasonValue, 
        myOrderEditConfirm: false,
        priceResponse: this.productData.priceResponse,
        createOrderData: this.productData.orderSummary.status == 'Order Pending Approval' ? saveOrderArray : confirmOrderArray
      }
      this.isLoading = true;
      this.startOrderService.getUkDeliverySlots(payload).subscribe((data: any) => {
        this.editdeliverydata = data;
        this.editdeliverydata.deliverySlotConstraintList=JSON.parse(this.editdeliverydata.deliverySlotConstraintList)
        //delivery code have to chnage for the inseaosn and normal seaoson with the rule index
     if(this.editdeliverydata.deliverySlotConstraintList?.PrePreBookMinLocProdQuantityAmendOrder){
      this.maxdeliveryslot=this.editdeliverydata.deliverySlotConstraintList.PrePreBookMaxDelSlotQtyforProdAmendOrder;
      this.mindeliveryslot=this.editdeliverydata.deliverySlotConstraintList.PrePreBookMinDelSlotQtyforProdAmendOrder;
    }else{
      this.maxdeliveryslot=this.editdeliverydata.deliverySlotConstraintList.MaxDelSlotQtyforProdAmendOrder;
      this.mindeliveryslot=this.editdeliverydata.deliverySlotConstraintList.MinDelSlotQtyforProdAmendOrder;
    } 
    this.maxdeliveryslotwithcomma=this.numberWithCommas(this.maxdeliveryslot)
        this.editdeliverydata.deliverySlotResponseData.shippingLocations.forEach((el: any) => {
          el['locationlevelerror'] = false;
          el['locationmsg'] = '';
          el['locationsumerror'] = false;
    
          el.materials.forEach((sel: any) => {
            sel['allocatederrorMsg'] = '';
            sel['allocatedvalue'] = null;
            sel['matchtick'] = false;
    
            /* if(sel.materialID==this.deliverydata?.productList[0]?.code){ */
            sel.deliverySlots.forEach((del: any) => {
              del['producterrorMsg'] = '';
              del['disablerule']=false;
              if(!del['quantity'] ){
                del['quantity']=null
              }
                
            });
           
          });
    
        });
        for(let i=0;i< this.editdeliverydata.deliverySlotResponseData.shippingLocations[0].materials.length;i++){
          for(let j=0;j<this.editdeliverydata.deliverySlotResponseData.shippingLocations[0].materials[i].deliverySlots.length;j++){
            if(this.editdeliverydata.deliverySlotResponseData.shippingLocations[0].materials[i].deliverySlots[j].quantity>0){
             this.amendhandleOndeliveryValueChange(this.editdeliverydata.deliverySlotResponseData.shippingLocations[0].materials[i].deliverySlots[j].quantity.toString(), j,i );
             if(j<this.editdeliverydata.deliverySlotConstraintList.maxDeliverySlots)
             this.businessrule(j,Number(this.editdeliverydata.deliverySlotResponseData.shippingLocations[0].materials[i].deliverySlots[j].quantity.replace(/,/g, "")),this.editdeliverydata.deliverySlotResponseData.shippingLocations[0].materials.length)
            }
            
          }
        }
       this.showAmendDeliveryPage=true;
        this.isLoading = false;
        this.scrollToTop();
        this.cdr.detectChanges();
      }, (error) => {
        console.log('error: ', error);
        this.isLoading = false;
            this.userInfoService.setInPlaceOrder(false);
            this.amendapifailure=true;
            this.cdr.detectChanges();
      })
    }
    this.cdr.detectChanges();
  }
  handleOnBackToProductBtnClick() {
    this.showAmendDeliveryPage = false;
    this.showAmendProductPage = true;
    this.showClearBtn = false;
    this.showErrorMsg = false;
    this.scrollToTop();
  }
  handleOnReviewOrderBtnClick() {

    this.showAmendDeliveryPage = false;
    this.showAmendProductPage = false;
    const obj = {
      showLandingPage: false,
      showLocationPage: false,
      showAmendProductPage: false,
      isProductBackBtnClicked: false,
      showAmendReviewPage: true
    }
    this.userInfoService.setEnablePage(obj);
    let createOrderData = this.editdeliverydata.deliverySlotResponseData.shippingLocations.map((el: any) => {
      const createOrderProductData = el.materials.map((data: any) => {
        const createOrderDeliverySlotData = data.deliverySlots.map((del: any) => {
          return{
            month: del.month,
            date: del.date,
            deliverySlotWeek: del.week,
            quantity: Number(del.quantity?.replace(/,/g, ""))
          }
        })

        return {
          productCode:data.materialID,
          productQuantity: data.qty,
          createOrderDeliverySlotData:createOrderDeliverySlotData
        }
      })
      return {
        locID: el.shippingLocID,
       qty50to64y: 0,
       createOrderProductData:createOrderProductData
      }
    })

    const delEditpayload = {
      requestType: "AM",
      orderID: this.productData.orderSummary.orderId,
      season: this.seasonValue,
      myOrderEditConfirm: false,
      priceResponse: this.productData.priceResponse,
      constraintList:JSON.stringify(this.editdeliverydata.deliverySlotConstraintList),
      createOrderData: createOrderData
    }
    this.startOrderService.getreviewpage(delEditpayload);
    this.cdr.detectChanges();
    this.scrollToTop();
  }
  handleAmendConfirmValidation(el: any, index: number) {
    const numWithoutCommaprod = Number(el.quantityInDoses?.replace(/,/g, ""));
    if(isNaN(numWithoutCommaprod)){
      el.quantityInDoses=null
    }
    let productValue = el.quantityInDoses;
    // const productValue = this.productData.orderSummary.status == 'Order Pending Approval' ?  el.quantityInDoses : el.confirmedQty;
   
    
    if(el.quantityInDoses){
       productValue = Number(el.quantityInDoses?.replace(/,/g, ""));
    }
   
    if (this.productData.orderSummary.status != 'Order Pending Approval' && el.baseProdName === 'aTIV 10 Pack' && this.confirmwithzeroaqiv==false) {
      if (productValue == null || (productValue > this.aqivtotalValue || productValue < el.quantity) || productValue == 0 || isNaN(productValue)) {
        el.errorMsg = `You can increase up to ${this.aqivtotalValue}`
      }
      else if (productValue != 0 && productValue % 10 != 0) {
        el.errorMsg = 'Please enter a volume to the nearest unit of 10';
      } 
       else if (productValue == this.aqivtotalValue) {
        this.productData.orderSummary.orderedandShippedProducts.orderedProducts[index].errorMsg = '';
      } else {
        this.productData.orderSummary.orderedandShippedProducts.orderedProducts[index].errorMsg = '';
      }
    }else if(this.productData.orderSummary.status != 'Order Pending Approval' && el.baseProdName === 'TIVc 10 Pack' && this.confirmwithzeroqivc==false){
      if (productValue == null || (productValue > this.qivctotalValue || productValue < el.quantity) || productValue == 0 || isNaN(productValue) ) {
        el.errorMsg = `You can increase up to ${this.qivctotalValue}`
      }
      else if (productValue != 0 && productValue % 10 != 0) {
        el.errorMsg = 'Please enter a volume to the nearest unit of 10';
      } 
       
      else if (productValue == this.qivctotalValue) {
        this.productData.orderSummary.orderedandShippedProducts.orderedProducts[index].errorMsg = '';
      } else {
        this.productData.orderSummary.orderedandShippedProducts.orderedProducts[index].errorMsg = '';
      }
    }
     else {
      if (productValue == null || productValue == 0) {
        this.productData.orderSummary.orderedandShippedProducts.orderedProducts[index].errorMsg = '';
      } else if (productValue != 0 && (productValue < this.productData.amendOrderConstraintList.minLocProdQuantity || productValue > this.productData.amendOrderConstraintList.maxLocProdQuantity) || isNaN(productValue)) {
        this.productData.orderSummary.orderedandShippedProducts.orderedProducts[index].errorMsg = `Please enter between ${this.minproductwithcomma} & ${this.maxproductwithcomma} doses`;
      } else if (productValue != 0 && productValue % 10 != 0) {
        this.productData.orderSummary.orderedandShippedProducts.orderedProducts[index].errorMsg = 'Please enter a volume to the nearest unit of 10';
      } 
      else {
        this.productData.orderSummary.orderedandShippedProducts.orderedProducts[index].errorMsg = '';
      }
    }
  }
  handleAmendSaveValidation(el: any, index: number) {
    // const productValue = this.productData.orderSummary.status == 'Order Pending Approval' ? el.quantityInDoses : el.confirmedQty;
    let productValue =el.quantityInDoses
    
    if(el.quantityInDoses){
      productValue = Number(el.quantityInDoses?.replace(/,/g, ""));
    
    }

    
    if (productValue == null) {
      this.productData.orderSummary.orderedandShippedProducts.orderedProducts[index].errorMsg = '';
    } else if (productValue != 0 && (productValue < this.productData.amendOrderConstraintList.minLocProdQuantity || productValue > this.productData.amendOrderConstraintList.maxLocProdQuantity)) {
      this.productData.orderSummary.orderedandShippedProducts.orderedProducts[index].errorMsg = `Please enter between ${this.minproductwithcomma} & ${this.maxproductwithcomma} doses`;
    } 
    else if (productValue != 0 && productValue % 10 != 0) {
      this.productData.orderSummary.orderedandShippedProducts.orderedProducts[index].errorMsg = 'Please enter a volume to the nearest unit of 10';
    } 
    else {
      this.productData.orderSummary.orderedandShippedProducts.orderedProducts[index].errorMsg = '';
    }
  }
  handleOnRemoveDoses() {
    this.startOrderService.removeAllDoses(this.productData.orderSummary.orderId).subscribe((data:any) => {
      localStorage.removeItem('orderId');
      this.router.navigate(['/my-account']);
    })
  }

  //delivery code start here
  amendhandleOndeliveryValueChange(deliveryValueString: string, deliveryIndex: number, materialindex: number) {
    let deliveryValue:any = Number(deliveryValueString.replace(/,/g, ""));
    deliveryValueString=deliveryValueString.trim()
    if(deliveryValueString == ""){
      deliveryValue=null;
    }
    if(!isNaN(deliveryValue)){  
    if (deliveryValue != null) {
      if(this.editdeliverydata.deliverySlotResponseData.shippingLocations[0].materials[materialindex].deliverySlots[deliveryIndex].quantity!=deliveryValue){
        this.editdeliverydata.deliverySlotResponseData.shippingLocations[0].materials[materialindex].deliverySlots[deliveryIndex].quantity=deliveryValue
      }
      if (deliveryValue < this.mindeliveryslot || deliveryValue > this.maxdeliveryslot) {
        this.editdeliverydata.deliverySlotResponseData.shippingLocations[0].materials[materialindex].deliverySlots[deliveryIndex].producterrorMsg = 'You can enter '+this.mindeliveryslot+ ' - '+this.maxdeliveryslotwithcomma+' doses per selected slot';
      } else if (deliveryValue != 0 && deliveryValue % 10 != 0) {
        this.editdeliverydata.deliverySlotResponseData.shippingLocations[0].materials[materialindex].deliverySlots[deliveryIndex].producterrorMsg = 'Please enter a volume to the nearest unit of 10';
      } else {

        this.editdeliverydata.deliverySlotResponseData.shippingLocations[0].materials[materialindex].deliverySlots[deliveryIndex].producterrorMsg = '';
      }
    } else {
      this.editdeliverydata.deliverySlotResponseData.shippingLocations[0].materials[materialindex].deliverySlots[deliveryIndex].producterrorMsg = '';

    }
    if(deliveryValue != null){
      this.editdeliverydata.deliverySlotResponseData.shippingLocations[0].materials[materialindex].deliverySlots[deliveryIndex].quantity = this.numberWithCommas(deliveryValue);
      this.cdr.detectChanges();
    }
    else{
      this.editdeliverydata.deliverySlotResponseData.shippingLocations[0].materials[materialindex].deliverySlots[deliveryIndex].quantity = null;
      this.cdr.detectChanges();
    }
    this.editdeliverydata.deliverySlotResponseData.shippingLocations[0].materials[materialindex].allocatedvalue = null;
    this.editdeliverydata.deliverySlotResponseData.shippingLocations[0].materials[materialindex].deliverySlots.forEach((sum: any) => {
     
        
      const numWithoutComma = Number(sum?.quantity?.toString().replace(/,/g, ""));
      
      if(!isNaN(numWithoutComma)){
      this.editdeliverydata.deliverySlotResponseData.shippingLocations[0].materials[materialindex].allocatedvalue += numWithoutComma;
      }
    });

    if (this.editdeliverydata.deliverySlotResponseData.shippingLocations[0].materials[materialindex].allocatedvalue != this.editdeliverydata.deliverySlotResponseData.shippingLocations[0].materials[materialindex].qty) {
      this.editdeliverydata.deliverySlotResponseData.shippingLocations[0].materials[materialindex].allocatederrorMsg = "sum not match";
      this.editdeliverydata.deliverySlotResponseData.shippingLocations[0].materials[materialindex].matchtick = false;
    }
    else {
      this.editdeliverydata.deliverySlotResponseData.shippingLocations[0].materials[materialindex].allocatederrorMsg = "";
      this.editdeliverydata.deliverySlotResponseData.shippingLocations[0].materials[materialindex].matchtick = true;
    }
    if (this.editdeliverydata.deliverySlotResponseData.shippingLocations[0].materials[materialindex].allocatedvalue == 0) {
      this.editdeliverydata.deliverySlotResponseData.shippingLocations[0].materials[materialindex].allocatedvalue = null;
      this.editdeliverydata.deliverySlotResponseData.shippingLocations[0].materials[materialindex].allocatederrorMsg = "";
    }
    if(this.editdeliverydata.deliverySlotResponseData.shippingLocations[0].materials[materialindex].allocatedvalue != null){
      this.editdeliverydata.deliverySlotResponseData.shippingLocations[0].materials[materialindex].allocatedvalue=this.numberWithCommas(this.editdeliverydata.deliverySlotResponseData.shippingLocations[0].materials[materialindex].allocatedvalue);
    }
     

      this.editdeliverydata.deliverySlotResponseData.shippingLocations[0]?.materials?.find((loop: any) => {
        if (
          loop.deliverySlots.find((check: any) => {

            return check.producterrorMsg == "You can enter "+this.mindeliveryslot+" - "+this.maxdeliveryslotwithcomma+" doses per selected slot";
          })) {
          this.editdeliverydata.deliverySlotResponseData.shippingLocations[0].locationmsg = "You can enter "+this.mindeliveryslot+" - "+this.maxdeliveryslotwithcomma+" doses per selected slot"
          return this.editdeliverydata.deliverySlotResponseData.shippingLocations[0].locationlevelerror=true;
        } else if (
          loop.deliverySlots.find((check: any) => {
            return check.producterrorMsg == "Please enter a volume to the nearest unit of 10";
          })) {
          this.editdeliverydata.deliverySlotResponseData.shippingLocations[0].locationmsg = 'Please enter a volume to the nearest unit of 10'
          return this.editdeliverydata.deliverySlotResponseData.shippingLocations[0].locationlevelerror=true;
        } else {
          this.editdeliverydata.deliverySlotResponseData.shippingLocations[0].locationmsg = ''
          return this.editdeliverydata.deliverySlotResponseData.shippingLocations[0].locationlevelerror=false;
        }
      });

    this.editdeliverydata.deliverySlotResponseData.shippingLocations[0].locationsumerror =

      this.editdeliverydata.deliverySlotResponseData.shippingLocations[0].materials.some((sum: any) => {
        return (sum.allocatederrorMsg != '')

      });
    this.handledeliverybtn();
    this.cdr.detectChanges();
    }
  }
  handledeliverybtn() {
    this.disabledelivery =
      this.editdeliverydata?.deliverySlotResponseData?.shippingLocations.every((ell: any) => {
        if (
          ell.materials.find((check: any) => {
            return (check.matchtick != true);
          })) {
          return false;
        }
        else { 
        
          return (ell.locationsumerror != true && ell.locationlevelerror != true)
        }
        
      });
  }
  handlenan(event:Event){
    let value1:any =  (event.target as HTMLInputElement).value;
    value1= Number(value1?.replace(/,/g, ""));
    if(isNaN(value1)){
      (event.target as HTMLInputElement).value=''
    }
  }
  businessrule( inputnumber: number, value: number, product: number) {
    //const element = (event.target as HTMLInputElement);
    if (product==2) {
      if (value > 0) {
        for (let i = 0; i < this.editdeliverydata.deliverySlotConstraintList.maxDeliverySlots; i++) {
        
            if (i != inputnumber) {
         this.editdeliverydata.deliverySlotResponseData.shippingLocations[0].materials[0].deliverySlots[i].disablerule=true;
         this.editdeliverydata.deliverySlotResponseData.shippingLocations[0].materials[1].deliverySlots[i].disablerule=true;
            }
        }

      } else {
        const currentaqiv = <HTMLInputElement>document.getElementById("aqivfirst[0][" + inputnumber + "]");
        const currentqivc = <HTMLInputElement>document.getElementById("qivcfirst[0][" + inputnumber + "]");

        if (currentqivc.value.length <= 0 && currentaqiv.value.length <= 0) {
          for (let i = 0; i < this.editdeliverydata.deliverySlotConstraintList.maxDeliverySlots; i++) {
         
            this.editdeliverydata.deliverySlotResponseData.shippingLocations[0].materials[0].deliverySlots[i].disablerule=false;
            this.editdeliverydata.deliverySlotResponseData.shippingLocations[0].materials[1].deliverySlots[i].disablerule=false;
          }
        }
      }
    }
    else {
      //let inputelement = element.id;
     // inputelement = inputelement.split("[")[0];
      if (value > 0) {
        for (let i = 0; i < this.editdeliverydata.deliverySlotConstraintList.maxDeliverySlots; i++) {
           if (i != inputnumber) {
              this.editdeliverydata.deliverySlotResponseData.shippingLocations[0].materials[0].deliverySlots[i].disablerule=true;
          }
        }
      } else {
        for (let i = 0; i < this.editdeliverydata.deliverySlotConstraintList.maxDeliverySlots; i++) {
          this.editdeliverydata.deliverySlotResponseData.shippingLocations[0].materials[0].deliverySlots[i].disablerule=false;

        }
      }
    }

  }
  numberWithCommas(number: number) {
    var parts = number.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }
  handleOnCloseButton() {
    this.userInfoService.setPopUpValue(false);
  }
  handleLoggedOutUser(path: any) {
    this.router.navigate([path]);
  }
  handleLoggedInUser(path: any) {
    if (path.includes('product') || path.includes('help-and-faq')) {
      this.handleLoggedOutUser(path);
    } else {
      localStorage.setItem('lastVisitedFooterUrl', path);
      localStorage.setItem('sessionTimedOut', 'true');
      this.authService.logout();
      this.router.navigate(['/logout']).then(() => {
      });
    }
  }
  handleOnExitBtnClick() {
    this.userInfoService.setPopUpValue(false);
    this.userInfoService.setInPlaceOrder(false);
    this.userInfoService.getRouteLink().subscribe(route => {
      this.route = route;
    });
    this.userInfoService.getQueryParams().subscribe(params => {
      this.queryParams = params;
    });

    let fromFooter:Boolean = false;
    this.userInfoService.getFromFooter().subscribe((data: Boolean) => {
      fromFooter = data;
    })
    if(fromFooter){
      this.isUserLoggedIn.pipe(take(1)).subscribe((isLoggedIn: boolean) => {
        if (isLoggedIn) {
          this.handleLoggedInUser(this.route);
        } else {
          this.handleLoggedOutUser(this.route);
        }
      });
    }
    else if(this.route == "/my-profile" || this.route == "/my-account"){
      this.router.navigate([this.route]).then(() => {
        window.location.reload();
      });
    }
    else if(this.route == "switchAccount"){
      localStorage.removeItem('rbapermission');
      let selectedAccount: any;
      this.userInfoService.getSelectedAccount().subscribe(data => {
        selectedAccount = data;
      })
        this.userInfoService.changeB2BAccount(selectedAccount).subscribe((data)=>{
        if(!this.router.url.includes('my-account')){
          this.router.navigate(['/my-account']);
        }
        else{
        const url = (this.router.url).split('?')[0].split('#')[0];
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
     
        //this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([url.substring(url.lastIndexOf('/')+1)], { onSameUrlNavigation: 'reload', queryParams: this.queryParams}).then(()=>{
        this.cdr.detectChanges();
        });
      }
        this.userInfoService.updateUserdata();
      });
    }
    else {
      this.router.navigateByUrl('/', { onSameUrlNavigation: 'reload' }).then(() => {
      this.router.navigate([this.route], {queryParams: this.queryParams})});
      //this.router.navigate([this.route], { onSameUrlNavigation: 'reload' });
    }
  }
   
  
}
