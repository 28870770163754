

import { Component, OnInit } from '@angular/core';
import { CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

declare function OptanonWrapper(): void;
declare global {
  interface Window {
    Optanon?: {
      ShowPreferences: () => void;
    };
  }
}

@Component({
  selector: 'app-first-header',
  templateUrl: './first-header.component.html',
  styleUrls: ['./first-header.component.scss']
})

export class FirstHeaderComponent implements OnInit {
  node: Observable<any> = this.bannerComponent.data$;
  currentSite: string | undefined;
  private readonly ukDomainScriptId = environment.CookieUk;
  private readonly usDomainScriptId = environment.CookieUs;
  cookieService: any;
  username: string = '';
  rememberMe: boolean = false;
  isCookieSettingsShown: boolean = false;
  cookieBanner: any | undefined;
  // private readonly ukDomainScriptId = 'a8e54e33-d1df-4545-a1eb-239c1eff16d1';
  // private readonly usDomainScriptId = '4b9a2748-81b2-43af-87bf-50d897d432a6';

  constructor(
    private cmsService: CmsService,
    protected config: OccConfig,
    public bannerComponent: CmsComponentData<CmsBannerComponent>
  ) {}

  ngOnInit(): void {
    console.log('ngOnInit called in FirstHeaderComponent');
    //this.setCurrentSite();
    console.log('Current site before loading script:', this.currentSite);
   // this.loadOneTrustScript();
  }

  private setCurrentSite(): void {
    const fullUrl = window.location.href;

    if (fullUrl.includes('uk') || fullUrl.includes('site=uk') || fullUrl.includes('flu360.co.uk')) {
      this.currentSite = 'seqirusb2b-uk';
    } else if (fullUrl.includes('flu360') || fullUrl.includes('site=flu360') || fullUrl.includes('flu360.com')) {
      this.currentSite = 'seqirusb2b-flu360';
    } else {
      console.error('URL path does not match any known sites.');
      this.currentSite = undefined;
    }
    
    console.log('Current site set to:', this.currentSite);
  } 

  private loadOneTrustScript(): void {
    console.log('loadOneTrustScript called');
    const domainScriptId = this.getDomainScriptId();
    if (!domainScriptId) {
      console.error('No domain script ID found for the current site.');
      return;
    }
    console.log(`Loading OneTrust script with domain script ID: ${domainScriptId}`);
    const existingScript = document.querySelector('script[data-domain-script]');
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
      script.type = 'text/javascript';
      script.charset = 'UTF-8';
      script.setAttribute('data-domain-script', domainScriptId);
      document.body.appendChild(script);
      script.onload = () => {
        console.log('OneTrust script loaded successfully.');
        if (typeof OptanonWrapper === 'function') {
          OptanonWrapper();
        }
        this.addAcceptAllCookiesListener();
        console.log('OptanonWrapper function called.');
      };
    } else {
      console.log('OneTrust script already loaded.');
      if (typeof OptanonWrapper === 'function') {
        OptanonWrapper();
      }
      this.addAcceptAllCookiesListener();
      console.log('OptanonWrapper function called.');
    }
  }

  private getDomainScriptId(): string {
    console.log('getDomainScriptId called');
    console.log('Current site:', this.currentSite);
    switch (this.currentSite) {
      case 'seqirusb2b-flu360':
        console.log('Using US domain script ID.');
        return this.usDomainScriptId;
      case 'seqirusb2b-uk':
        console.log('Using UK domain script ID.');
        return this.ukDomainScriptId;
      default:
        console.error('Current site does not match any known sites.');
        return '';
    }
  }

  private addAcceptAllCookiesListener(): void {
    const checkBannerExistence = setInterval(() => {
      const acceptButton = document.getElementById('onetrust-accept-btn-handler');
     
      if (acceptButton) {
        acceptButton.addEventListener('click', () => {
          console.log('Accept All Cookies button clicked.');
  const banner = document.getElementById('onetrust-banner-sdk');
  if (banner) {
    console.log('Hiding OneTrust banner.');
    banner.remove();
    //banner.style.display = 'none'; // Use display to remove from layout
  } else {
    console.error('OneTrust banner not found.');
  }
         
        });
        clearInterval(checkBannerExistence);
      }
    }, 500); // Check every 500ms
  }
  acceptCookies(): void {
    console.log('acceptCookies called');
    this.cookieBanner = document.getElementsByClassName("ot-sdk-row");
    if (this.cookieBanner.length > 0) {
      this.cookieBanner[0].style.display = "none";
    } 
  }
  showCookieSettings(): void {
    console.log('showCookieSettings called');
    if (this.isCookieSettingsShown) {
      console.log('Cookie settings popup already shown, ignoring subsequent clicks.');
      return;
    }
    if (window.Optanon) {
      console.log('window.Optanon exists.');
      this.isCookieSettingsShown = true;
      window.Optanon.ShowPreferences();
      
      console.log('Button disabled.');
    } else {
      console.error('window.Optanon is not defined.');
      this.isCookieSettingsShown = false;
    }
   // this.isCookieSettingsShown=false;
  }

}

 

