import { ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, BaseSiteService, CmsService } from '@spartacus/core';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { Observable, take } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-reg-join-acc-exit-popup',
  templateUrl: './reg-join-acc-exit-popup.component.html',
  styleUrl: './reg-join-acc-exit-popup.component.scss'
})
export class RegJoinAccExitPopupComponent {
  showJoinAccExitPopUp: boolean=false;
  route: any;
  queryParams: any;
  isUserLoggedIn: Observable<boolean> = this.authService.isUserLoggedIn();
  joinAccPopupData: any;
  currentSite: string='';
  userInfo: any;
  joinaccountpage:boolean=false;
  
  constructor( private cdr: ChangeDetectorRef,private baseSiteService:BaseSiteService,private cmsService: CmsService,private userInfoService: UserInfoService,private router: Router, private authService: AuthService) {

  }

  ngOnInit() {
    
  const url=this.router.url
    if (url.includes('/register/join-account')) {
   this.joinaccountpage=true
    }else{
      this.joinaccountpage=false
    }
    this.cdr.detectChanges();
    this.baseSiteService.getActive().subscribe(site => {
      this.currentSite = site;
    });
    this.isUserLoggedIn.pipe(take(1)).subscribe((isLoggedIn: boolean) => {
      if (isLoggedIn) {
        this.userInfoService.getUserData().subscribe(res=>{
          if(res){
          this.userInfo=res;
          }
        })
      }
    });
   
    this.userInfoService.getPopValue().subscribe((val: boolean) => {
      this.showJoinAccExitPopUp = val;
      this.cdr.detectChanges();
    })
  }

  handleOnCloseButton(){
    this.userInfoService.setPopUpValue(false);
  }

  handleOnExitBtnClick() {
   
    this.userInfoService.setPopUpValue(false);
    this.userInfoService.setInPlaceOrder(false);
    this.userInfoService.getRouteLink().subscribe(route => {
      this.route = route;
    });
    this.userInfoService.getQueryParams().subscribe(params => {
      this.queryParams = params;
    });

    let fromFooter:Boolean = false;
    this.userInfoService.getFromFooter().subscribe((data: Boolean) => {
      fromFooter = data;
    })
    if(fromFooter){
      this.isUserLoggedIn.pipe(take(1)).subscribe((isLoggedIn: boolean) => {
        if (isLoggedIn) {
          this.handleLoggedInUser(this.route);
        } else {
          this.handleLoggedOutUser(this.route);
        }
      });
    }
    else if(this.route == "/my-profile" || this.route == "/my-account"){
      this.router.navigate([this.route]).then(() => {
        window.location.reload();
      });
    }
    else if(this.route == "switchAccount"){
      localStorage.removeItem('rbapermission');
      let selectedAccount: any;
      this.userInfoService.getSelectedAccount().subscribe(data => {
        selectedAccount = data;
      })
        this.userInfoService.changeB2BAccount(selectedAccount).subscribe((data)=>{
        if(!this.router.url.includes('my-account')){
          this.router.navigate(['/my-account']);
        }
        else{
        const url = (this.router.url).split('?')[0].split('#')[0];
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
     
        //this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([url.substring(url.lastIndexOf('/')+1)], { onSameUrlNavigation: 'reload', queryParams: this.queryParams}).then(()=>{
        this.cdr.detectChanges();
        });
      }
        this.userInfoService.updateUserdata();
      });
    }
    else {
      this.router.navigateByUrl('/', { onSameUrlNavigation: 'reload' }).then(() => {
      this.router.navigate([this.route], {queryParams: this.queryParams})});
      //this.router.navigate([this.route], { onSameUrlNavigation: 'reload' });
    }
  }

  handleLoggedInUser(path: any) {
    if (path.includes('product') || path.includes('help-and-faq')) {
      this.handleLoggedOutUser(path);
    } else {
      localStorage.setItem('lastVisitedFooterUrl', path);
      localStorage.setItem('sessionTimedOut', 'true');
      this.authService.logout();
      this.router.navigate(['/logout']).then(() => {
      });
    }
  }

  handleLoggedOutUser(path: any) {
    this.router.navigate([path]);
  }

  ngAfterViewInit() {
  this.cmsService.getComponentData('joinAccountPopup').subscribe((data)=>{
    this.joinAccPopupData = data;
    this.cdr.detectChanges();
  });
}

}
