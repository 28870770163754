import { Component ,Renderer2,ElementRef} from '@angular/core';
import { CmsBannerComponent,CmsService, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { UserInfoService } from '../../spartacus/services/user-info.service';

@Component({
  selector: 'app-fluad-rwe-first',
  templateUrl: './fluad-rwe-first.component.html',
  styleUrl: './fluad-rwe-first.component.scss'
})
export class FluadRweFirstComponent {

userLoggedIn: boolean = false;
userInfo: any;
webEnabled: any;
firstnode: Observable<any> = this.bannerComponent.data$;
constructor(private cmsService:CmsService, protected config: OccConfig,public bannerComponent: CmsComponentData<CmsBannerComponent>, private userInfoService:UserInfoService,private renderer: Renderer2) {
   }
ngAfterViewInit(){
  this.userInfoService.userLoggedIn$.subscribe(res=>{
    // console.log('login check',res);
    this.userLoggedIn = res;
    if(this.userLoggedIn){
    // this.userInfoService.getUserData().subscribe(res=>{
    //   console.log(res);
    // if(res){
    //   this.userInfo=res;

    //   this.cd.detectChanges();
    //   }
    // });
    this.webEnabled =  localStorage.getItem('webEnabled');
    // console.log('123',this.webEnabled);
  }
  })
  if(this.userInfo?.webEnabled == false){
    this.renderer.setStyle(document.querySelector('.SideContent'),'display','none');
   }
}
ngoninit(){
  // this.userInfoService.userLoggedIn$.subscribe(res=>{
  //   console.log('login check',res);
  //   this.userLoggedIn = res;
  //   // if(this.userLoggedIn == true){
  //   //   this.renderer.setStyle(document.querySelector('.SideContent'),'display','block');
  //   // }
  // })

   }

getImage(image: any): string {
  return this.config.backend?.occ?.baseUrl + image.url;
}


}
