import { ChangeDetectorRef, Component, HostListener, Renderer2 } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { CmsComponentData } from '@spartacus/storefront';
import { OccConfig } from '@spartacus/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CsAgentAuthService } from '@spartacus/asm/root';

export interface UkCredit {
  billTo:any;
  invoiceDate:string;
  invoiceNumber:number;
  formattedAmountWithTax:string;
  status:string;
}
export interface RefundData {
  bankAccNo:any;
  bankAccName:string;
  sortCode:string;
  country:string;
  creditNumbers:Array<string>;
  totalCredits:string;
  requestType: string;
}

interface AppliedCreditNote {
  creditNoteNumber: string,
  creditAmount: string
}
export interface AppliedCreditDetails {
  invoiceNumber: string,
  creditNotes: Array<AppliedCreditNote>,
  paymentFlag: string
}

@Component({
  selector: 'app-credits-section',
  templateUrl: './credits-section.component.html',
  styleUrl: './credits-section.component.scss'
})
export class CreditsSectionComponent {

  creditpending=new MatTableDataSource<UkCredit>;
  creditavailable=new MatTableDataSource<UkCredit>;
  creditapplied=new MatTableDataSource<UkCredit>;
  financialresponse: any;
  isLoading: boolean=false;
  creditcolumns:string[]=['creditNote','creditAmount','issuedDate','viewCredit'];
  ukCreditsContent: any;
  showRefundSection: boolean = false;
  showCreditSection: boolean =true;
  refundCreditsForm:FormGroup;
  showEditForm: boolean=false;
  showConfirmBank: boolean=false;
  isChecked:boolean=false;
  finalBankAccNo: string="";
  finalSortCode: string="";
  refundData?: RefundData;
  showThankyouSection: boolean=false;
  selectedSeason: string='';
  isAsmUser: boolean=false;
  asmEnabled: boolean = false;
  asmAgentLoggedIn: boolean = false;
  customerEmulated: boolean = false;
  bankDetailUpdated: boolean=false;
  showfootnote: boolean=true;
  editSortCode: any;
  editBankAccNum: any;
  rbavalue:any;
  bankdetailsavaiable:boolean=false;
  finalaccountname:string="";

  constructor(private userInfoService: UserInfoService,private config:OccConfig,protected csAgentAuthService: CsAgentAuthService,private cd:ChangeDetectorRef,private renderer: Renderer2,private componentdata:CmsComponentData<any>,private fb:FormBuilder) {
    this.refundCreditsForm = this.fb.group({
      requestType:[],
      bankAccName:['',[Validators.required]],
      bankAccNo:['',[Validators.required,Validators.minLength(8),this.accNoValidator.bind(this)]],
      sortCode:['',[Validators.required,Validators.minLength(6),this.sortCodeValidator.bind(this)]],
      country:['GB'],
    });
  }
  sortCodeValidator(control:any){

    if(control.value == this.financialresponse?.bankDetails?.payersortCode || control.value == this.editSortCode){
      return true;
    }
    else{
      return /^[0-9]+$/.test(control.value)?true:{invalidSortCode:true};
    }
  }
  accNoValidator(control:any){
    if(control.value == this.financialresponse?.bankDetails?.payerbankAccountNumber  || control.value == this.editBankAccNum){
      return true;
    }
    else{
      return /^[0-9]+$/.test(control.value)?true:{invalidAccNum:true};
    }
  }

  ngOnInit(){
    this.isAsmUser = this.showStatus();
    this.componentdata.data$.subscribe(val => {
      this.ukCreditsContent = val;
    })
    this.userInfoService.getDropdownValue().subscribe(season =>{
      this.cd.detectChanges();
      this.isLoading=true;
      this.selectedSeason = season;
      this.userInfoService.financialdashboardUK(season).subscribe(value=>{
        this.refundCreditsForm.reset();
        this.showRefundSection=false;
        this.showEditForm=false;
        this.showCreditSection=true;
        this.financialresponse=value;
        this.isLoading = false;
        this.cd.detectChanges();
        this.creditavailable=new MatTableDataSource(this.financialresponse?.seqirusFinancialDashboardData?.availableCreditList);
        this.creditpending=new MatTableDataSource(this.financialresponse?.seqirusFinancialDashboardData?.submitedCreditList);
        this.creditapplied=new MatTableDataSource(this.financialresponse?.seqirusFinancialDashboardData?.usedCreditList);
        this.cd.detectChanges();
        },
      (error)=>{
        this.isLoading=false;
      });
      this.opencredit(1);
    })

    this.rbavalue=localStorage.getItem('rbapermission');
    this.rbavalue=JSON.parse(this.rbavalue);
    this.cd.detectChanges(); 

  }
  financialgetDownloadInvoiceData(invnumber:string) {
    event?.stopPropagation();
    this.isLoading=true;
    this.userInfoService.financialdownloadInvoice(invnumber)
      .subscribe(

        (data: ArrayBuffer) => {
          // Create a Blob from the ArrayBuffer
          const file = new Blob([data], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL); // Open in a new tab

          const a = document.createElement('a');
          a.href = fileURL;
          a.target = '_blank';
          a.download = invnumber;
          document.body.appendChild(a);
          a.click();
          this.isLoading = false;
          this.cd.detectChanges();
        },
        (error) => {
          console.log('getPDF error: ', error);
          this.isLoading=false;
        }
      );

  }

  getPdfUrl(pdf:any){
    return this.config.backend?.occ?.baseUrl + pdf?.url;

  }

  opencredit(idindex:number){
    const elements = document.querySelectorAll(`.credits_creditbtn`);
    elements.forEach(element => {
        this.renderer.removeClass(element, "active");
    });
    const elements1 = document.querySelectorAll(`.credits_credittable`);
    elements1.forEach(element => {
        this.renderer.removeClass(element, "open");
    });
    const btnnew= <HTMLElement> document.getElementById("credits_creditbtn-"+idindex);
    const containernew= <HTMLElement> document.getElementById("credits_credittable"+idindex);
    btnnew.classList.add("active");
    containernew.classList.add("open");

    if(idindex != 1){
      this.showfootnote=false;
    }
    else{
      this.showfootnote=true;
    }

  }

  openRefund(){
    this.showRefundSection = true;
    this.showCreditSection = false;
    this.showConfirmBank=true;
    if(this.financialresponse?.bankDetails?.payersortCode && this.financialresponse?.bankDetails?.bankName && this.financialresponse?.bankDetails?.payerbankAccountNumber && this.financialresponse?.bankDetails?.bankCountry){
      this.bankdetailsavaiable=true
      this.showEditForm=false;
    this.refundCreditsForm.setValue({
      requestType:this.financialresponse?.bankDetails?.payerbankAccountNumber?'verified':'new',
      bankAccName:this.financialresponse?.bankDetails?.bankName,
      bankAccNo:this.financialresponse?.bankDetails?.payerbankAccountNumber,
      sortCode:this.financialresponse?.bankDetails?.payersortCode,
      country:this.financialresponse?.bankDetails?.bankCountry,
    });
  }else{
    this.bankdetailsavaiable=false;
    this.showEditForm=true;
    this.refundCreditsForm.patchValue({
      country:'GB'
    }) 
  }
  }
  editBankDetails(){
    this.editSortCode = document.getElementsByClassName('masked3')[0].innerHTML;
    this.editBankAccNum = document.getElementsByClassName('masked4')[0].innerHTML;
    this.showEditForm = true;
    this.showConfirmBank=false;
    this.refundCreditsForm.get('bankAccNo')?.setValue(this.applyAccNoMask(this.refundCreditsForm.get('bankAccNo')?.value));
    this.refundCreditsForm.get('sortCode')?.setValue(this.applySortCodeMask(this.refundCreditsForm.get('sortCode')?.value));

  }
  cancelEditForm(){
    this.showEditForm=false;
    this.refundCreditsForm.markAsUntouched();
   
      if(this.financialresponse?.bankDetails?.payersortCode && this.financialresponse?.bankDetails?.bankName && this.financialresponse?.bankDetails?.payerbankAccountNumber && this.financialresponse?.bankDetails?.bankCountry){
        this.showConfirmBank=true;
        if(this.bankDetailUpdated ){
          this.refundCreditsForm.patchValue({
            bankAccName:this.finalaccountname,
            bankAccNo:this.finalBankAccNo,
            sortCode:this.finalSortCode,
            country:'GB',
          });
       
        }else{
          this.refundCreditsForm.patchValue({
            requestType:this.financialresponse?.bankDetails?.payerbankAccountNumber?'verified':'new',
            bankAccName:this.financialresponse?.bankDetails?.bankName,
            bankAccNo:this.financialresponse?.bankDetails?.payerbankAccountNumber,
            sortCode:this.financialresponse?.bankDetails?.payersortCode,
            country:'GB',
          });

        }

      }
        else{
        this.showRefundSection = false;
         this.showCreditSection = true;
         this.showConfirmBank=false;
         this.refundCreditsForm.patchValue({
          requestType:this.financialresponse?.bankDetails?.payerbankAccountNumber?'verified':'new',
          bankAccName:this.financialresponse?.bankDetails?.bankName,
          bankAccNo:this.financialresponse?.bankDetails?.payerbankAccountNumber,
          sortCode:this.financialresponse?.bankDetails?.payersortCode,
          country:'GB',
        });

      }
      
     
     
    
    
  
  }
  closeRefund(){
    this.showRefundSection=false;
    this.showCreditSection=true;
    this.isChecked=false;
    this.refundCreditsForm.markAsUntouched();
    if(this.bankDetailUpdated && this.financialresponse?.bankDetails?.payersortCode && this.financialresponse?.bankDetails?.bankName && this.financialresponse?.bankDetails?.payerbankAccountNumber && this.financialresponse?.bankDetails?.bankCountry ){
      this.refundCreditsForm.patchValue({
        bankAccName:this.finalaccountname,
        bankAccNo:this.finalBankAccNo,
        sortCode:this.finalSortCode,
        country:'GB',
      });
   
    }else{
    this.refundCreditsForm.patchValue({
      requestType:this.financialresponse?.bankDetails?.payerbankAccountNumber?'verified':'new',
      bankAccName:this.financialresponse?.bankDetails?.bankName,
      bankAccNo:this.financialresponse?.bankDetails?.payerbankAccountNumber,
      sortCode:this.financialresponse?.bankDetails?.payersortCode,
      country:'GB',
    }); 
  }


  }
  updateBankDetail(){
    this.refundCreditsForm.markAllAsTouched();
    if(this.refundCreditsForm.valid){
    this.showEditForm=false;
    this.showConfirmBank=true;
    this.bankdetailsavaiable=true
    this.refundCreditsForm.get('requestType')?.setValue('modified');
    this.bankDetailUpdated = true;
    this.finalBankAccNo = this.refundCreditsForm.get('bankAccNo')?.value;
    this.finalSortCode = this.refundCreditsForm.get('sortCode')?.value;
    this.finalaccountname=this.refundCreditsForm.get('bankAccName')?.value;

    }
  }

  @HostListener('keyup',['$event'])
  @HostListener('change',['$event'])
  @HostListener('blur',['$event'])
  handleEvents(event:KeyboardEvent){
    const target = event.target as HTMLInputElement;
    if(event.key === 'Backspace'){
      if(target.id === 'accountNumber' || target.id === 'sortCode'){
        if(target.value.substring(0,1)=='*'){
          this.refundCreditsForm.get('bankAccNo')?.setValue('');
          this.refundCreditsForm.get('sortCode')?.setValue('');

        }
      }
    }

  }

  applyAccNoMask(val:string){
    
    return val?.replace(/\d(?=\d{4})/g, "*");
  }
  applySortCodeMask(val:string){
    return val?.replace(/\d(?=\d{2})/g, "*");
  }

  submitRefund(){
    if(this.refundCreditsForm.get('requestType')?.value != 'verified'){
    const refundData:RefundData=this.refundCreditsForm.value;

    refundData.totalCredits=this.financialresponse?.seqirusFinancialDashboardData?.credit.totalAvailableCredit;
    refundData.creditNumbers = this.financialresponse?.seqirusFinancialDashboardData?.availableCreditList.map((inv:any) => inv.invoiceNumber);
    this.submitRefundsApi(refundData);

    }
    else{
    const refundData:RefundData={
      requestType:'verified',
      bankAccName:'',
      bankAccNo:'',
      sortCode:'',
      country:'GB',
      totalCredits:this.financialresponse?.seqirusFinancialDashboardData?.credit.totalAvailableCredit,
      creditNumbers:this.financialresponse?.seqirusFinancialDashboardData?.availableCreditList.map((inv:any) => inv.invoiceNumber)

    }
    this.submitRefundsApi(refundData);

    }


  }
  submitRefundsApi(refundData:RefundData){
    this.userInfoService.submitRefundData(refundData).subscribe({
      next:(response:any) => {
        this.showThankyouSection=true;
        this.showRefundSection=false;
        this.showCreditSection=false;
        this.cd.detectChanges();
      }
    })
  }
  showProcessingCredits(){
    this.showThankyouSection=false;
    this.showCreditSection=true;
    this.userInfoService.sendDropdownValue(this.selectedSeason);
    this.opencredit(2);
    this.cd.detectChanges();

  }
  showStatus() {
    this.asmEnabled = localStorage.getItem('showASM') === 'true';
    this.csAgentAuthService.isCustomerSupportAgentLoggedIn().subscribe(i => {
        this.asmAgentLoggedIn = i;
    });
    this.csAgentAuthService.isCustomerEmulated().subscribe(res => {
      this.customerEmulated = res;
    });
    if(this.asmEnabled && this.asmAgentLoggedIn && this.customerEmulated) {
      return false;
    }
    return true;
  }


}
