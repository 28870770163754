

<ng-container *ngIf="joinAccData | async as para">
    <ng-container *ngIf="currentSite == 'seqirusb2b-uk' && userInfo?.webEnabled;else otherUser">
         <!-- join account changes for logged-in version -->
    
            <div class="col-lg-12 col-md-10 col-sm-12 col-xs-12 no-padding joinAccount joinAccount_parent_header joinAccount_parent_headerUK join-loggedIn">
            
            <div  *ngIf="joinAccSubmitStatus == ''" class="joinAccount_rightside joinAccount_rightsideUK">
                <div class="joinAccount_section" id="joinAccount_section1">
                    
                     <div class="joinAccloginText" [innerHTML]="para.topContent">{{para.topContent}}</div>
                    <div class="padding-R15">
                        <form (ngSubmit)="searchUKForm()" id= "jointaccountFormUK" [formGroup]="jointaccountFormUK">
                                                    <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12 no-padding margin-B20 joinAccount_parent form-group">
                                                    <label class="joinAccount_inputLabel" for="joinAccount-number">{{'form.accountNumUK' | cxTranslate}}</label>
                                                    <div class="input-group">
                                                    <input class="joinAccount_input joinAccount_inputUK form-control" id="joinAccount-number" name="joinAccount-number" [ngClass]="{'is-invalid': jointaccountFormUK.get('account')?.touched && jointaccountFormUK.get('account')?.invalid}" formControlName="account"/>
                                                    <span class="input-group-addon" *ngIf="jointaccountFormUK.get('account')?.touched && jointaccountFormUK.get('account')?.invalid">
                                                        <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                                                    </span>
                                                    </div>
                                                    <div class="error help-block" *ngIf="(submitted || jointaccountFormUK.get('account')?.touched) && hasErrorInUK('account','required')">{{'form.errorAccNum' | cxTranslate}}</div>
                                                    <div class="error help-block" *ngIf="!hasErrorInUK('account','pattern') && hasErrorInUK('account','minlength')">{{'form.errorAccNumMin' | cxTranslate}}</div>
                                                    <div class="error help-block" *ngIf="hasErrorInUK('account','pattern')">{{'form.errorAccNumUK' | cxTranslate}}</div>
                                                    <!-- <div class="error help-block" *ngIf="(submitted || jointaccountFormUK.get('account')?.touched) && !hasErrorInUK('account','required') && (hasErrorInUK('account','minlength') || hasErrorInUK('account','maxlength') || hasErrorInUK('account','pattern'))">{{'form.errorAccNumUK' | cxTranslate}}</div> -->
                                                    
                                                </div>
                                                <div class="col-lg-2 col-md-2 col-sm-6 col-xs-6 no-padding-right margin-B20 joinAccount_mob form-group">
                                                    <label class="joinAccount_inputLabel"  for="joinAccount-zipcode">{{'form.zipCodeUK' | cxTranslate}}</label>
                                                    
                                                    <div class="input-group">
                                                    <input class="joinAccount_input joinAccount_inputUK form-control" id="joinAccount-zipcode" name="joinAccount-zipcode" [ngClass]="{'is-invalid': jointaccountFormUK.get('accessCodeFlag')?.touched && jointaccountFormUK.get('accessCodeFlag')?.invalid}" formControlName="accessCodeFlag"/>
                                                    <span class="input-group-addon" *ngIf="jointaccountFormUK.get('accessCodeFlag')?.touched && jointaccountFormUK.get('accessCodeFlag')?.invalid">
                                                        <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                                                    </span>
                                                    </div>
                                
                                                    <div class="error help-block" *ngIf="(submitted || jointaccountFormUK.get('accessCodeFlag')?.touched) && jointaccountFormUK.get('accessCodeFlag')?.invalid">{{'form.errorZipCodeUK' | cxTranslate}}</div>
                                                
                                                </div>
                                                <div class="col-lg-2 col-md-2 col-sm-6 col-xs-6 no-padding-right margin-B20 joinAcc_search">
                                                    <button type="submit" class="joinAccount_search active">{{'form.search' | cxTranslate}}</button>
                                                </div>
                                
                                                <div class="clearboth"></div>
                                               
                                
                                                <div *ngIf="(accountName | async) as response">
                                                 <p *ngIf="response === 'accountBlocked'" class="joinAccount_errortextUK">{{'form.errorAccBlocked' | cxTranslate}}</p>
                                                </div>                
                                                </form>


                    </div>
                     <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-left-pad margin-B8">
                        <div class="joinAccount_text3" id="jointaccount-existing-orgname" style="display:none" ></div>
                    </div>
                    <div *ngIf="(accountName | async) as acc">
                    <div *ngIf = "acc == 'unitAlreadyThere'" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-left-pad margin-B8" id="joinaccount-already-there">
                        <div class="joinAccount_text3">You have already joined this account, please try other account</div>
                    </div>
                    
                        <div *ngIf="acc != 'Exception' && acc != 'accountBlocked' && acc != 'notFound' && acc != 'unitAlreadyThere';else requestAssist" class="suceesshowsection">
                        
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-left-pad margin-B8">
                            <div class="joinAccount_text3" id="jointaccount-orgname">{{acc}}</div>
                            </div>
                       
                            <div class="margin-B20 joinAccount_text4parent joinAccount_checkparent joinAccount_text4parentUK joinAccount_checkparentUK">
                                <label class="checkbox-button">
                                    <input type="checkbox" class="checkbox-button__input" id="jointacccheckbox" (change)="onCheckboxChange($event)">
                                        <span class="checkbox-button__control"></span> 
                                        <span *ngIf="currentSite=='seqirusb2b-uk'" class="jointaccountchecklable">&nbsp;{{'form.acknowledgeTextUK' | cxTranslate}}</span>
                                </label>
                            </div>
                        
                        <button class="joinAccountBtnUK" [ngClass]="isChecked ?'joinAccountBtn active':'joinAccountBtn inactive'" [disabled]="!isChecked" (click)="submitJoinAccount(acc)">{{'form.joinAcc' | cxTranslate}}</button>
                        
                        <div class="clearboth"></div>
                        
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin-B20 no-padding-left">
                            <div class="joinAccount_text5 joinAccount_text5UK">Can’t find your organisation? Email us at <a href='mailto:service.uk@seqirus.com' class="showPopupclick removeEmailleave">service.uk&#64;seqirus.com</a>.</div>
                        </div>
                        
                        
                        <div class="clearboth"></div>
            
                        </div>
                    
                
                    <ng-template #requestAssist>
                        <div *ngIf="acc != 'accountBlocked' && acc != 'unitAlreadyThere'" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin-B20 searchFailText no-padding-left">  
                            <p class="joinAccount_errortextUK">The information you’ve entered is incorrect or was not found. Please try again.</p>	  
                               <div *ngIf="currentSite=='seqirusb2b-uk'" class="joinAccount_text6 joinAccount_text6UK">{{'form.accNotFoundUK' | cxTranslate}}</div>
                               
                               <div *ngIf="!showReqAssistanceSuccess" class="col-md-4 col-sm-4 col-xs-12 margin-T20 requestAssistance_hideButton" [ngClass]="currentSite == 'seqirusb2b-uk' ?'no-padding-left':'col-lg-4'">
                                   <button type="button" class="joinAccount_request active" [ngClass]="currentSite == 'seqirusb2b-uk' ?'joinAccount_requestUK':''" (click)="raiseAssistanceReq()">
                                    {{'form.reqAssistance' | cxTranslate}}</button> 
                                </div>
                                <div *ngIf="!showReqAssistanceSuccess" class="col-md-8 col-sm-8 col-xs-12 joinAccount_text7 margin-T20 requestAssistance_hideText" [ngClass]="currentSite == 'seqirusb2b-uk' ? 'col-lg-6 joinAccount_text7UK':'col-lg-8'" [innerHTML] ="para.bottomContent"></div>
                                <div *ngIf="currentSite=='seqirusb2b-uk' && showReqAssistanceSuccess" class="col-lg-7 col-md-7 col-sm-12 col-xs-12 joinAccount_text7 joinAccount_text7UK margin-T20 no-padding-left requestAssistance_showSuccessMessage" [innerHTML] ="'form.thankyouTextUK' | cxTranslate"></div>
                            
                        </div>
                    </ng-template>
                
                </div>

                    </div>
                </div>


              <div *ngIf="joinAccSubmitStatus != ''" class="joinaccount_thankyou col-xs-10">
                                  <div class="joinAccount_label padding-B30">Thank you for joining an account</div>
                                  <div class="joinAccount_Rbatext padding-B10">Your account will be shown in the Associated Accounts in My Profile.</div>
                                  <div class="joinAccount_Rbatext padding-B30">You can add additional organisations in My Profile.</div>
                                   <div class="joinAccount_Rbatext padding-B20">Your initial permission(s) for all allocations for this account :</div>
                                   <div class="joinAccount_Rbatext padding-B30 Rbaordermanage">Ordering <span class="joinaccount_ordering">{{ukJoinAccResp.orderingPermission}}</span> and Financial <span class="joinaccount_financial">{{ukJoinAccResp.financialPermission}}</span></div>
                                   
                                  <div *ngIf="ukJoinAccResp.firstB2BUnit == false && (ukJoinAccResp.adminPermissions == null || ukJoinAccResp.adminPermissions == '');else ukAdminDetail" class="joinAccount_Rbatext padding-B30 joinAccount_selfadmin">
                                    <div class="padding-B20">You may request changes by contacting <span class="permissionmail"><a href=mailto:service.uk@seqirus.com>service.uk&#64;seqirus.com</a></span> for this account</div>
                                  </div>

                                  <ng-template #ukAdminDetail>
                                  <div class="joinAccount_Rbatext padding-B30 joinAccount_adminDetail">
                                    <div class="padding-B20">You may request changes by contacting an admin for this account:</div>
                                    <div class="joinAccount_dynamicId margin-B30">
                                        
                                        <div *ngFor="let admin of ukJoinAccResp.adminPermissions" class="rbacentertextjoinaccount padding-B10">{{admin.name}}&nbsp;<span class="permissionmail"><a href=mailto:{{admin.email}}>{{admin.email}}</a></span></div>
                                    
                                    </div>
                                    <div>If you are unable to reach an admin, please contact <a [routerLink]="'/contact-us'" class="rbaflu360support">flu360 | Support</a>.</div>
                                </div>
                            </ng-template>

                                  <div class="joinAccount_Thankyou-button">
                                      <a href="/register/join-account"><button type="button" class="joinAccount_another-account">Join Another Account</button></a>
                                      <a href="/my-profile"><button type="button" class="joinAccount_my-profile">Go to my profile</button></a>
                                  </div>
                              </div>
				<!-- added job bag codes -->
				<div class="col-xs-12 grbno logindocUK joinaccountgrb">
					
				</div>
            </div>

    </ng-container>
    <ng-template #otherUser>
        <div class="joinAccount col-xs-12 no-padding" [ngClass]="currentSite=='seqirusb2b-uk'?'joinAccountUK':''">
            <ng-container *ngIf="currentSite=='seqirusb2b-flu360';else ukstructure">
                <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 joinAccount_parent_header">
                    <div class="joinAccount_right_header">{{'breadcrumb.home' | cxTranslate}} &gt; <span>{{'breadcrumb.createAcc' | cxTranslate}}</span></div>
                </div>
                <div class="clearboth"></div>
                <div class="col-md-3 col-sm-12 col-xs-12 col-lg-4  no-padding-right joinAccount_leftside">
                    <div class="col-lg-7 col-md-7 joinAccount_nagivation no-padding">
                            
                        <div [innerHTML]="para.leftContent"></div>
                          
                    </div>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 joinAccount_rightside no-padding-left">
                    <section class="joinAccount_section" id="joinAccount_section1">
                        <div [innerHTML]="para.topContent">{{para.topContent}}</div>
            
                        <div class="padding-R15">
                            <form (ngSubmit)="submitForm()" id= "jointaccountFormUS" [formGroup]="jointaccountFormUS">
                                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding margin-B20 joinAccount_parent form-group">
                                <label class="joinAccount_inputLabel" for="joinAccount-number">{{'form.accountNum' | cxTranslate}}</label>
                                <div class="input-group">
                                <input class="joinAccount_input form-control" id="joinAccount-number" name="joinAccount-number" [ngClass]="{'is-invalid':submitted && jointaccountFormUS.get('account')?.invalid}" formControlName="account"/>
                                <span class="input-group-addon" *ngIf="submitted && jointaccountFormUS.get('account')?.invalid">
                                    <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                                </span>
                                </div>
                                <div class="error help-block" *ngIf="submitted  && hasError('account','required')">{{'form.errorAccNum' | cxTranslate}}</div>
                                <div class="error help-block" *ngIf="submitted && !hasError('account','pattern') && hasError('account','minlength')">{{'form.errorAccNumMin' | cxTranslate}}</div>
                                <div class="error help-block" *ngIf="submitted && hasError('account','pattern')">{{'form.errorAccNumUK' | cxTranslate}}</div>
                            
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-6 col-xs-6 no-padding-right margin-B20 joinAccount_mob form-group">
                                <label class="joinAccount_inputLabel"  for="joinAccount-zipcode">{{'form.zipCode' | cxTranslate}}</label>
                                
                                <div class="input-group">
                                <input class="joinAccount_input form-control" id="joinAccount-zipcode" name="joinAccount-zipcode" [ngClass]="{'is-invalid':submitted && jointaccountFormUS.get('accessCodeFlag')?.invalid}" formControlName="accessCodeFlag"/>
                                <span class="input-group-addon" *ngIf="submitted && jointaccountFormUS.get('accessCodeFlag')?.invalid">
                                    <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                                </span>
                                </div>
            
                                <div class="error help-block" *ngIf="submitted && jointaccountFormUS.get('accessCodeFlag')?.invalid">{{'form.errorZipCode' | cxTranslate}}</div>
                            
                            </div>
                            <div class="col-lg-2 col-md-2 col-sm-6 col-xs-6 no-padding-right margin-B20 joinAcc_search">
                                <button type="submit" class="joinAccount_search active">{{'form.search' | cxTranslate}}</button>
                            </div>
            
                            <div class="clearboth"></div>
                            
                            <div *ngIf="submitted && (hasError('account','required') || hasError('accessCodeFlag','required') || hasError('account','minlength'))">
                                <p class="joinAccount_errortext">{{'form.errorBothField' | cxTranslate}}</p>
                            </div>
            
                            <div *ngIf="(accountName | async) as response">
                             <p *ngIf="response === 'accountBlocked'" class="joinAccount_errortext">{{'form.errorAccBlocked' | cxTranslate}}</p>
                            </div>                
                            </form>
                        </div>
            
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding margin-B20">
                            <div class="joinAccount_text3" id="jointaccount-existing-orgname" style="display:none" ></div>
                        </div>
                        
                <div *ngIf="(accountName | async) as acc">
                        <div *ngIf="acc != 'Exception' && acc != 'accountBlocked' && acc != 'notFound' && acc != 'unitAlreadyThere';else requestAssist" class="suceesshowsection">
                        
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding margin-B20">
                            <div class="joinAccount_text3" id="jointaccount-orgname">{{acc}}</div>
                            </div>
                       
                            <div class="margin-B20 joinAccount_text4parent joinAccount_checkparent">
                                <label class="checkbox-button">
                                    <input type="checkbox" class="checkbox-button__input" id="jointacccheckbox" (change)="onCheckboxChange($event)">
                                        <span class="checkbox-button__control"></span> 
                                        <span class="jointaccountchecklable">&nbsp;{{'form.acknowledgeText' | cxTranslate}}</span>
                                </label>
                            </div>
                        
                        <button [ngClass]="isChecked ?'joinAccountBtn active':'joinAccountBtn inactive'" [disabled]="!isChecked" (click)="submitJoinAccount(acc)">{{'form.joinAcc' | cxTranslate}}</button>
                        
                        <div class="clearboth"></div>
                        
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin-B20">
                                <div class="joinAccount_text5" [innerHTML]="para.requestAssistanceContent">{{para.requestAssistanceContent}}</div>
                        </div>
            
                        <div class="clearboth"></div>
            
                        </div>
                    
                    <ng-template #requestAssist>
                        <div *ngIf="acc != 'accountBlocked'" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin-B20 searchFailText no-padding-left">  
                              <div class="joinAccount_text6">{{'form.accNotFound' | cxTranslate}}</div>
                               
                               <div *ngIf="!showReqAssistanceSuccess" class="col-md-4 col-sm-4 col-xs-12 margin-T20 requestAssistance_hideButton col-lg-4">
                                   <button type="button" class="joinAccount_request active" (click)="raiseAssistanceReq()">
                                    {{'form.reqAssistance' | cxTranslate}}</button> 
                                </div>
                                <div *ngIf="!showReqAssistanceSuccess" class="col-md-8 col-sm-8 col-xs-12 joinAccount_text7 margin-T20 requestAssistance_hideText col-lg-8" [innerHTML] ="para.bottomContent"></div>
                                <div *ngIf="showReqAssistanceSuccess" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 joinAccount_text7 margin-T20 requestAssistance_showSuccessMessage" [innerHTML] ="'form.thankyouText' | cxTranslate"></div>
                        </div>
                    </ng-template>
                
                </div>
            
                </section>
                </div>
                
            </ng-container>
            <ng-template #ukstructure>
            <div class="col-md-3 col-sm-12 col-xs-12 joinAccount_leftside" [ngClass]="currentSite=='seqirusb2b-uk'?'col-lg-3 joinAccount_leftsideUK':'col-lg-4  no-padding-right'">
                <div class="joinAccount_nagivation no-padding" [ngClass]="currentSite=='seqirusb2b-uk'?'col-lg-12':'col-lg-7 col-md-7 '">
                        
                    <div [innerHTML]="para.leftContent"></div>
                      
                </div>
            </div>
            <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 joinAccount_parent_header"[ngClass]="currentSite=='seqirusb2b-uk'?'joinAccount_parent_headerUK':''">
            <div class="joinAccount_right_header"  [ngClass]="currentSite=='seqirusb2b-uk'?'joinAccount_right_headerUK':''">{{'breadcrumb.home' | cxTranslate}} &gt; <span>{{'breadcrumb.createAcc' | cxTranslate}}</span></div>
            
            <div class="clearboth"></div>
            <div class="joinAccount_rightside no-padding-left" [ngClass]="currentSite == 'seqirusb2b-uk' ?'joinAccount_rightsideUK':''">
                <section class="joinAccount_section" id="joinAccount_section1">
                    <div [innerHTML]="para.topContent">{{para.topContent}}</div>
        
                    <div class="padding-R15">
                        <form (ngSubmit)="searchUKForm()" id= "jointaccountFormUK" [formGroup]="jointaccountFormUK">
                                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding margin-B20 joinAccount_parent form-group">
                                <label class="joinAccount_inputLabel" for="joinAccount-number">{{'form.accountNumUK' | cxTranslate}}</label>
                                <div class="input-group">
                                <input class="joinAccount_input joinAccount_inputUK form-control" id="joinAccount-number" name="joinAccount-number" [ngClass]="{'is-invalid':jointaccountFormUK.get('account')?.touched && jointaccountFormUK.get('account')?.invalid}" formControlName="account"/>
                                <span class="input-group-addon" *ngIf="jointaccountFormUK.get('account')?.touched && jointaccountFormUK.get('account')?.invalid">
                                    <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                                </span>
                                </div>
                                <div class="error help-block" *ngIf="(submitted || jointaccountFormUK.get('account')?.touched)  && hasErrorInUK('account','required')">{{'form.errorAccNum' | cxTranslate}}</div>
                                <div class="error help-block" *ngIf="!hasErrorInUK('account','pattern') && hasErrorInUK('account','minlength')">{{'form.errorAccNumMin' | cxTranslate}}</div>
                                <div class="error help-block" *ngIf="hasErrorInUK('account','pattern')">{{'form.errorAccNumUK' | cxTranslate}}</div>
                                <!-- <div class="error help-block" *ngIf="(submitted || jointaccountFormUK.get('account')?.touched)  && !hasErrorInUK('account','required') && (hasErrorInUK('account','minlength') || hasErrorInUK('account','maxlength') || hasErrorInUK('account','pattern'))">{{'form.errorAccNumUK' | cxTranslate}}</div> -->
                                
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-6 col-xs-6 no-padding-right margin-B20 joinAccount_mob form-group">
                                <label class="joinAccount_inputLabel"  for="joinAccount-zipcode">{{'form.zipCodeUK' | cxTranslate}}</label>
                                
                                <div class="input-group">
                                <input class="joinAccount_input joinAccount_inputUK form-control" id="joinAccount-zipcode" name="joinAccount-zipcode" [ngClass]="{'is-invalid':jointaccountFormUK.get('accessCodeFlag')?.touched && jointaccountFormUK.get('accessCodeFlag')?.invalid}" formControlName="accessCodeFlag"/>
                                <span class="input-group-addon" *ngIf="jointaccountFormUK.get('accessCodeFlag')?.touched && jointaccountFormUK.get('accessCodeFlag')?.invalid">
                                    <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                                </span>
                                </div>
            
                                <div class="error help-block" *ngIf="(submitted || jointaccountFormUK.get('accessCodeFlag')?.touched)   && jointaccountFormUK.get('accessCodeFlag')?.invalid">{{'form.errorZipCodeUK' | cxTranslate}}</div>
                            
                            </div>
                            <div class="col-lg-2 col-md-2 col-sm-6 col-xs-6 no-padding-right margin-B20 joinAcc_search">
                                <button type="submit" class="joinAccount_search active">{{'form.search' | cxTranslate}}</button>
                            </div>
            
                            <div class="clearboth"></div>
                           <!--  
                            <div *ngIf="submitted && (hasErrorInUK('account','required') || hasErrorInUK('accessCodeFlag','required') || hasErrorInUK('accessCodeFlag','pattern') || hasErrorInUK('account','minlength'))">
                                <p class="joinAccount_errortext">{{'form.errorBothField' | cxTranslate}}</p>
                            </div> -->
            
                            <div *ngIf="(accountName | async) as response">
                             <p *ngIf="response === 'accountBlocked'" class="joinAccount_errortextUK">{{'form.errorAccBlocked' | cxTranslate}}</p>
                            </div>                
                            </form>  
                    </div>
        
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding margin-B20">
                        <div class="joinAccount_text3" id="jointaccount-existing-orgname" style="display:none" ></div>
                    </div>
                    
            <div *ngIf="(accountName | async) as acc">
                    <div *ngIf="acc != 'Exception' && acc != 'accountBlocked' && acc != 'notFound' && acc != 'unitAlreadyThere';else requestAssist" class="suceesshowsection" [ngClass]="currentSite=='seqirusb2b-uk'?'logged-out-showsection':''">
                    
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" [ngClass]="currentSite=='seqirusb2b-uk'?'no-left-pad margin-B8':'no-padding margin-B20'">
                        <div class="joinAccount_text3" id="jointaccount-orgname">{{acc}}</div>
                        </div>
                   
                        <div class="margin-B20 joinAccount_text4parent joinAccount_checkparent" [ngClass]="currentSite=='seqirusb2b-uk'?'joinAccount_text4parentUK joinAccount_checkparentUK':''">
                            <label class="checkbox-button">
                                <input type="checkbox" class="checkbox-button__input" id="jointacccheckbox" (change)="onCheckboxChange($event)">
                                    <span class="checkbox-button__control"></span> 
                                    <span *ngIf="currentSite=='seqirusb2b-flu360'" class="jointaccountchecklable">&nbsp;{{'form.acknowledgeText' | cxTranslate}}</span>
                                    <span *ngIf="currentSite=='seqirusb2b-uk'" class="jointaccountchecklable">&nbsp;{{'form.acknowledgeTextUK' | cxTranslate}}</span>
                            </label>
                        </div>
                    
                    <button *ngIf="currentSite == 'seqirusb2b-uk'" [ngClass]="isChecked ?'joinAccountBtn joinAccountBtnUK active':'joinAccountBtn joinAccountBtnUK inactive'" [disabled]="!isChecked" (click)="submitJoinAccount(acc)">{{'form.joinAcc' | cxTranslate}}</button>
                    
                    <div class="clearboth"></div>
                    
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin-B20 no-padding-left">
                        <div class="joinAccount_text5 joinAccount_text5UK">Can’t find your organisation? Email us at <a href='mailto:service.uk@seqirus.com' class="showPopupclick removeEmailleave">service.uk&#64;seqirus.com</a>.</div>
                    </div>
                    
                    <div class="clearboth"></div>
        
                    </div>
                
            
                <ng-template #requestAssist>
                    <div *ngIf="acc != 'accountBlocked'" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin-B20 searchFailText no-padding-left">  
                        <p *ngIf="currentSite=='seqirusb2b-uk'" class="joinAccount_errortextUK">The information you’ve entered is incorrect or was not found. Please try again.</p>	  
                           <div *ngIf="currentSite=='seqirusb2b-uk'" class="joinAccount_text6 joinAccount_text6UK">{{'form.accNotFoundUK' | cxTranslate}}</div>
                           
                           <div *ngIf="!showReqAssistanceSuccess" class="col-md-4 col-sm-4 col-xs-12 margin-T20 requestAssistance_hideButton" [ngClass]="currentSite == 'seqirusb2b-uk' ?'no-padding-left':'col-lg-4'">
                               <button type="button" class="joinAccount_request active" [ngClass]="currentSite == 'seqirusb2b-uk' ?'joinAccount_requestUK':''" (click)="raiseAssistanceReq()">
                                {{'form.reqAssistance' | cxTranslate}}</button> 
                            </div>
                            <div *ngIf="!showReqAssistanceSuccess" class="col-md-8 col-sm-8 col-xs-12 joinAccount_text7 margin-T20 requestAssistance_hideText" [ngClass]="currentSite == 'seqirusb2b-uk' ? 'joinAccount_text7UK':'col-lg-8'" [innerHTML] ="para.bottomContent"></div>
                            <div *ngIf="currentSite=='seqirusb2b-uk' && showReqAssistanceSuccess" class="col-lg-10 col-md-10 col-sm-12 col-xs-12 joinAccount_text7 joinAccount_text7UK margin-T20 no-padding-left requestAssistance_showSuccessMessage" [innerHTML] ="'form.thankyouTextUK' | cxTranslate"></div>
                        
                    </div>
                </ng-template>
            
            </div>
        
            </section>
            </div>
            </div>
            </ng-template>
    
    
        </div>
    </ng-template>
</ng-container>

<app-reg-join-acc-exit-popup></app-reg-join-acc-exit-popup>

