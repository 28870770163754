// import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ContentService } from '../../spartacus/services/content.service';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

declare var window:any;

@Component({
  selector: 'app-payment-information',
  templateUrl: './payment-information.component.html',
  styleUrls: ['./payment-information.component.scss'],
})
export class PaymentInformationComponent implements OnInit {
  cardsData: any[] = [];
  creditdetails: any = { cardsData: [] };
  cardservice: any;
  apiResponse: any;
  Key: string = '';
  token: any;
  cardType: any;
  selectedtoken: string = '';
  confirmDeletemodal: boolean = false;
  event: any;
  occEndPointsService: any;
  http: any;
  iframeUrl: string = '';
  getIframeUrl: any;
  iFramedetails: any;
  paymentDetailsList!: Object;
  iFrame!: Object;
  checkOutId: any;
  returnUrl: string = '';
  trustedUrl!: SafeResourceUrl;
  isCardUsed: boolean=false;
  selectedCardData: any;
  cardMap:any ={
    1:'Visa',2:'American Express',3:'MasterCard',128:'Discover'
  }

  constructor(
    private sanitizer: DomSanitizer,
    private contentservice: ContentService,
    private userinfoservice: UserInfoService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.contentservice.savedCreditDetail().subscribe((data: any) => {
      this.creditdetails = data;
      this.cdr.detectChanges();
    });
const obj = {
   //returnURL :"https://www.flu360.com"
   returnURL :"https://dev5.flu360.com/savedCardIframePage"
   // returnURL :"http://local.flu360:4200/savedCardIframePage"


};

this.contentservice.iFrame(obj).subscribe((data: any) => {
  this.iFramedetails = data;
  this.checkOutId = data.hostedCheckoutId;
   this.updateIframeUrl(data.partialRedirectUrl);

   localStorage.setItem('paymentInfoIframeHostedId',this.checkOutId )
});
  }

updateIframeUrl(returnUrl:string): void {
   this.returnUrl = returnUrl; // Set the actual URL you want to display
//  this.returnUrl = 'https://payment.pay1.preprod.checkout.worldline-solutions.com/checkout/11122-ed273adc127f4dd783dfb31088a653ea:06757e57-976f-71ff-9659-ec3c26c260d8:7302a145511d494dbe0d8b480574d673'
  this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.returnUrl);
  this.cdr.detectChanges();
  }

cleanURL(): SafeResourceUrl {
return this.trustedUrl;
}

reloadPageAndRedirect(returnUrl: string): void {
  window.location.href = this.returnUrl;
}
//redirect
// onPaymentSuccess(): void {
//   window.location.href = 'http://www.flu360.com';
// }
  getExpiryClass(expiryDate: string): string {
    const [month, year] = expiryDate.split('/');
    const today = new Date();
    const cardExpiry = new Date(parseInt(year), parseInt(month), 0); // 0 gets the last day of the previous month

    if (cardExpiry < today ||
      (cardExpiry.getFullYear() === today.getFullYear() &&
      (cardExpiry.getMonth() === today.getMonth() || cardExpiry.getMonth() === today.getMonth() +1))
    ) {
      return 'expired';
    } else {
      return '';
    }

  }

  handleOnDelete(cardData: any) {
    this.selectedtoken = cardData.token;
    this.selectedCardData = cardData;
    this.userinfoservice.isSavedCardUsed(this.selectedtoken).subscribe((data:boolean)=>{
      this.isCardUsed=data;
    
      if(this.isCardUsed){
      
      const modalElement = document.getElementById('schedulepay_deletemodal') as HTMLElement;
      if(modalElement){
        const schPayDeleteModal = new window.bootstrap.Modal(modalElement);
        schPayDeleteModal.show();
        
      }
      }
      else{
      this.confirmDeletemodal = true;
      
      const modalElement = document.getElementById('payment-info_deletemodal') as HTMLElement;
        if(modalElement){
          const payInfoDeleteModal = new window.bootstrap.Modal(modalElement);
          payInfoDeleteModal.show();
          
        }
      }
    });

  }

  confirmDelete(): void {
    if (this.selectedtoken) {
      this.userinfoservice.deleteCreditDetails(this.selectedtoken).subscribe(
        (_response: any) => {
          this.creditdetails.cardsData = this.creditdetails.cardsData.filter(
            (cards: any) => cards.token !== this.selectedtoken
          );
          this.cdr.detectChanges();
        },
        (error: any) => {
          console.error('Error deleting card:', error);
          alert('There was an error deleting the card. Please try again.');
        }
      );
    }
  }
  formatCard(cardNumber:string){
    return cardNumber.replace(/(.{4})/g, '$1 ').trim();
  }
}
// // this.contentservice.iFrame(obj).subscribe((data: any) => {
// //   console.log('API Response:', data); // Log the entire response
// //   this.updateIframeUrl(data.partialRedirectUrl);
// // }, error => {
// //   // console.error('API call error:', error);
// // });
// //   }

// updateIframeUrl(url: string) {
//  {
//   console.log('Iframe URL:', url); // Log the URL for debugging
//   this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
//   this.cdr.detectChanges();
// }
// }

// this.contentservice.iFrame(obj ).subscribe((data: any) => {
//   this.iFramedetails = data;
//   this.checkOutId = data.hostedCheckoutId;
//   console.log(this.iFramedetails);
//   this.cdr.detectChanges();
//   this.updateIframeUrl();
// });
// }

// updateIframeUrl(): void {
// this.returnUrl = 'https://www.flu360.com'; // Set the actual URL you want to display
// this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.returnUrl);
// }

// cleanURL(): SafeResourceUrl {
// return this.trustedUrl;
// }
