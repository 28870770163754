import { ChangeDetectorRef, Component, HostListener } from '@angular/core';
import {
  AuthService,
  BaseSiteService,
  CmsBannerComponent,
  CmsNavigationComponent,
  CmsService,
  OccConfig,
} from '@spartacus/core';
import {
  CmsComponentData,
  NavigationNode,
  NavigationService,
} from '@spartacus/storefront';
import { Observable, map, take } from 'rxjs';
import { ContentService } from '../../spartacus/services/content.service';
import { CmsParagraphComponent } from '@spartacus/core';
import { NavigationStart, Router, RouterEvent } from '@angular/router';
import { UserInfoService } from '../../spartacus/services/user-info.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent {
  footernode$: Observable<NavigationNode> = this.service.getNavigationNode(
    this.componentData.data$
  );

  styleClass$: Observable<string | undefined> = this.componentData.data$.pipe(
    map((d) => d?.styleClass)
  );

  termsnConditionNodes: any = [];
  visitsite!: Observable<CmsParagraphComponent>;
  footerPara!: Observable<CmsParagraphComponent>;
  termsofUseNode!: Observable<any>;
  tandCSale25_26Node!: Observable<any>;
  tandCSale24_25_apr_Node!: Observable<any>;
  tandCSale24_25Node!: Observable<any>;
  tandCSale23_24_apr_Node!: Observable<any>;

  termsofUseLink: any;
  tandCSale25_26Link: any;
  tandCSale23_24_apr_Link: any;
  tandCSale24_25Link: any;
  tandCSale24_25_apr_Link: any;
  currentSite: string = '';
  ukFooterData!: Observable<any>;

  isUserLoggedIn: Observable<boolean> = this.authService.isUserLoggedIn();
  popupdata: any;
  isModalOpen: boolean=false;
  userInfo: any;
  isLoggedIn: boolean=false;

  constructor(
    protected componentData: CmsComponentData<CmsNavigationComponent>,
    protected service: NavigationService,
    protected paraService: ContentService,
    private authService: AuthService,
    protected config: OccConfig,
    private baseSiteService: BaseSiteService,
    public userInfoService:UserInfoService,
    private router: Router,private cmsService:CmsService,protected cd:ChangeDetectorRef
  ) {}

  logoData: Observable<any> = this.paraService.getNavigation(
    'SeqirusLogoFooterLink'
  );

  fetchFooterData() {
    if (this.currentSite == 'seqirusb2b-flu360') {
      this.footerPara = this.paraService.getNavigation(
        'SeqirusFooterTextLoggedOut'
      );
      this.visitsite = this.paraService.getNavigation(
        'corporatesitelinkcomponent'
      );
     // this.termsofUseNode = this.paraService.getNavigation('TermsOfUseLink');
     /*  this.tandCSale24_25Node = this.paraService.getNavigation(
        'TandCofsaleLink2024-2025'
      );
      this.tandCSale24_25_apr_Node = this.paraService.getNavigation(
        'TandCofsaleLink2024-2025-april'
      );
      this.tandCSale25_26Node = this.paraService.getNavigation(
        'TandCofsaleLink2025-2026'
      );

      this.tandCSale25_26Node
        .pipe(map((val) => val.media?.url))
        .subscribe((res) => {
          this.tandCSale25_26Link = res;
        });

      this.tandCSale24_25Node
        .pipe(map((val) => val.media?.url))
        .subscribe((res) => {
          this.tandCSale24_25Link = res;
        });
      this.tandCSale24_25_apr_Node
        .pipe(map((val) => val.media?.url))
        .subscribe((res) => {
          this.tandCSale24_25_apr_Link = res;
        });
      this.termsofUseNode
        .pipe(map((val) => val.media?.url))
        .subscribe((res) => {
          this.termsofUseLink = res;
        }); */
    }
  }

  ngOnInit() {

    // window.onfocus = this.handleWindowFocus.bind(this);
    this.baseSiteService.getActive().subscribe((siteid) => {
      this.currentSite = siteid;
    });

    this.isUserLoggedIn.subscribe(res=>{
      this.isLoggedIn=res;
      if(this.isLoggedIn){
        this.userInfoService.getUserData().subscribe(res=>{
          if(res){
          this.userInfo=res;
          }
        })
      }
      else{
      this.cd.detectChanges();
      }
    })

    this.fetchFooterData();
    this.footernode$.subscribe((res: any) => {
      if (res) {
        if (
          res.children[2].title == 'Website Terms of Use' &&
          this.currentSite == 'seqirusb2b-flu360'
        ) {
          let a = res.children[2];

         // a.children[2].url = this.termsofUseLink;
         // a.children[3].url = this.tandCSale24_25Link;
         // a.children[4].url = this.tandCSale24_25_apr_Link;
         // a.children[5].url = this.tandCSale25_26Link;

          this.termsnConditionNodes = res.children[2].children;
        }
      }
    });

    if (this.currentSite == 'seqirusb2b-uk') {
      this.ukFooterData = this.paraService.getNavigation(
        'customFooterComponent'
      );
      this.cmsService.getComponentData('contactusPagePopup').subscribe(res=>{
        this.popupdata=res;
      });
    }
  }

  gocookies(url:any){
    /* this.router.navigate(['/cookiepolicy']).then(() => {
      window.location.reload();
    }); */
    window.open(url,'_blank');
    
  }

  getImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.url;
  }

  openRoute(path: any) {
    this.isUserLoggedIn.pipe(take(1)).subscribe((isLoggedIn: boolean) => {
      if (isLoggedIn) {
        this.handleLoggedInUser(path);
      } else {
        this.handleLoggedOutUser(path);
      }
    });
  }

  handleLoggedInUser(path: any) {
    if (path.includes('product') || path.includes('help-and-faq')) {
      this.handleLoggedOutUser(path);
    } else {
      localStorage.setItem('lastVisitedFooterUrl', path);
      localStorage.setItem('sessionTimedOut', 'true');
      this.authService.logout();
      this.router.navigate(['/logout']).then(() => {
      });
    }
  }

  handleLoggedOutUser(path: any) {
    this.router.navigate([path]);
  }

  handleLinks(route: any, params: any){
    const href = window.location.href;
    let inPlaceOrder:any;
    this.userInfoService.getInPlaceOrder().subscribe(val => {
      inPlaceOrder = val;
    })
    if((href.includes("start-order") || href.includes("register")) && inPlaceOrder){
      this.userInfoService.setRouteLink(route);
      this.userInfoService.setQueryParams(params);
      this.userInfoService.setPopUpValue(true);
      this.userInfoService.setFromFooter(true);
    }
    else {
      this.openRoute(route);
    }
   }
  @HostListener('document:click',['$event'])
  handleClick(event:Event){
    const targetEle = event.target as HTMLElement;
    const linkEle = targetEle.closest('.externallinkpopupUK') as HTMLAnchorElement;

    if(linkEle && linkEle.href){
      event.preventDefault();
      
      this.handleExternalLinkClick(linkEle.href);
    }
  }
  handleExternalLinkClick(href: string) {
    this.isModalOpen = true;
    const popup = document.querySelector('.commonleaving_abandonedPop') as HTMLElement;
    document.body.appendChild(popup);
    document.getElementById('commonleaving_abandonedPop')!.style.display = 'block';
    document.body.style.overflowY = 'auto';
    
  }
 
 
  openUrl(url:string){
    window.open(url,'_blank');
    this.closeModal();
  }

  closeModal() {
    this.isModalOpen = false;
    document.body.style.overflowY = 'auto';
    document.getElementById('commonleaving_abandonedPop')!.style.display = 'none';
  }
 
}
