<ng-container *ngIf="this.pageLabel === 'returnsLandingPage'">
    <div *ngIf="returnsthqvideo | async as item" class="returns_Top_Job_Bag_Code">{{item.h3content}}</div>
</ng-container>
<div *ngIf="returnsthqvideo | async as item" class="returns_thq_video_container">
    <div class="video_Container">
        <span [innerHTML]="item.headline" class="thq_Video_Headline"></span>
        <div class="video-wrap">
            <img src="../../../assets/images/returnsUnmuteIcon.png" id="volume-off_icon" (click)="toggleMute()">
<ng-container *ngIf="item?.name;else uiassestvide ">
        <video autoplay="" #videoPlayer [muted]="true" playsinline controls [attr.title]="item.media?.altText"
        class="returns_video" id="video-element">
        <source src="../../../assets/images/returnsUKMedia.mov" >
        Your browser does not support the video tag.
    </video>
</ng-container>
<ng-template #uiassestvide>
            <video autoplay="" #videoPlayer [muted]="true" playsinline controls [attr.title]="item.media?.altText"
                class="returns_video" id="video-element">
                <source [src]="getVideo(item?.media)"/>
                Your browser does not support the video tag.
            </video>
</ng-template>
        </div>
    </div>

    <div class="arrow_icon_Button_container">
        <img class="red_arrow_icon" src="../../../assets/images/arrow-icon.png">
        <button class="register_button" data-toggle="modal" data-target="#register_Thankyou_PopUp"
            (click)="onClickRegisterd()" [innerHTML]="item.h2content"></button>
    </div>
    <span class="email_shortly_text" [innerHTML]="item.content"></span>

    <!--ThankYou Popup-->
    <div class="returns_Thankyou_Popup modal  fade" role="dialog" id="register_Thankyou_PopUp" tabindex="-1">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body register_Thankyou_PopContent">
                    <div class="register_Thankyoupopup_Header">
                        <div class="register_Thankyoupopup_Title">{{retunsPopupData?.content}}</div>
                        <div class="register_Thankyoupopup_Close" data-dismiss="modal">X</div>
                        <div class="clearboth"></div>
                    </div>
                    <div class="returns_ThankyouPopup_Text">
                        <p [innerHTML]="retunsPopupData?.t1content"></p>

                        <!--Smiley Emojis-->
                        <div class="register_emoji_Title" *ngIf="this.pageLabel === 'returnsLandingPage'">
                            {{retunsPopupData?.headLine}}</div>
                        <div style="font-family: Verdana; font-size: 12px; overflow:hidden;"
                            class="returns_Thankyou_Emojis" *ngIf="this.pageLabel === 'returnsLandingPage'">
                            <table cellpadding="0" cellspacing="0" border="0" class="feedbacktable no-padding">
                                <tbody>
                                    <tr>
                                        <td class="feedbackbox">
                                            <a href="https://app.customerthermometer.com/?template=log_feedback&amp;hash=7bc5e051&amp;embed_data=dGVtcGVyYXR1cmVfaWQ9NCZ0aGVybW9tZXRlcl9pZD0xNzk2NDUmbnBzX3JhdGluZz0=&amp;e=&amp;f=&amp;l=&amp;c=&amp;c1=&amp;c2=&amp;c3=&amp;c4=&amp;c5=&amp;c6=&amp;c7=&amp;c8=&amp;c9=&amp;c10="
                                                target="_blank" class="returnPopupshow">
                                                <img style="border: none;"
                                                    src="https://app.customerthermometer.com/sites/app/images/icon_sets/icon_set_3s/red.png"
                                                    alt="Red Light" title="Red Light" class="_temp">
                                            </a>
                                        </td>
                                        <td class="feedbackbox">
                                            <a href="https://app.customerthermometer.com/?template=log_feedback&amp;hash=7be5181c&amp;embed_data=dGVtcGVyYXR1cmVfaWQ9MyZ0aGVybW9tZXRlcl9pZD0xNzk2NDUmbnBzX3JhdGluZz0=&amp;e=&amp;f=&amp;l=&amp;c=&amp;c1=&amp;c2=&amp;c3=&amp;c4=&amp;c5=&amp;c6=&amp;c7=&amp;c8=&amp;c9=&amp;c10="
                                                target="_blank" class="returnPopupshow">
                                                <img style="border: none;"
                                                    src="https://app.customerthermometer.com/sites/app/images/icon_sets/icon_set_3s/amber.png"
                                                    alt="Yellow Light" title="Yellow Light" class="_temp">
                                            </a>
                                        </td>
                                        <td class="feedbackbox">
                                            <a href="https://app.customerthermometer.com/?template=log_feedback&amp;hash=32ff96cc&amp;embed_data=dGVtcGVyYXR1cmVfaWQ9MiZ0aGVybW9tZXRlcl9pZD0xNzk2NDUmbnBzX3JhdGluZz0=&amp;e=&amp;f=&amp;l=&amp;c=&amp;c1=&amp;c2=&amp;c3=&amp;c4=&amp;c5=&amp;c6=&amp;c7=&amp;c8=&amp;c9=&amp;c10="
                                                target="_blank" class="returnPopupshow">
                                                <img style="border: none;"
                                                    src="https://app.customerthermometer.com/sites/app/images/icon_sets/icon_set_3s/green.png"
                                                    alt="Green Light" title="Green Light" class="_temp">
                                            </a>
                                        </td>
                                        <td class="feedbackbox">
                                            <a href="https://app.customerthermometer.com/?template=log_feedback&amp;hash=3a1f4098&amp;embed_data=dGVtcGVyYXR1cmVfaWQ9MSZ0aGVybW9tZXRlcl9pZD0xNzk2NDUmbnBzX3JhdGluZz0=&amp;e=&amp;f=&amp;l=&amp;c=&amp;c1=&amp;c2=&amp;c3=&amp;c4=&amp;c5=&amp;c6=&amp;c7=&amp;c8=&amp;c9=&amp;c10="
                                                target="_blank" class="returnPopupshow">
                                                <img style="border: none;"
                                                    src="https://app.customerthermometer.com/sites/app/images/icon_sets/icon_set_3s/gold.png"
                                                    alt="Gold Star" title="Gold Star" class="_temp">
                                            </a>
                                        </td>

                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="returns_ThankyouPopup_Btncontainer">
                        <a *ngIf="this.pageLabel === 'returnsLandingPage'" class="view_Returns_Link"
                            (click)="goToReturnPage()" data-dismiss="modal">{{retunsPopupData?.t1linkurl}}</a>
                        <button class="returns_ThankyouPopup_Close_Btn"
                            data-dismiss="modal">{{retunsPopupData?.t2linkurl}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>