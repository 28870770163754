import { ChangeDetectorRef, Component } from '@angular/core';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-payment-test',
  templateUrl: './payment-test.component.html',
  styleUrl: './payment-test.component.scss'
})
export class PaymentTestComponent {
  constructor(
    public userInfoService:UserInfoService,private router: Router,private cd:ChangeDetectorRef,private datePipe:DatePipe) {
  }
  iframeData$:Observable<any> = this.userInfoService.getcheckOutId();
  errorCode :any;
  paymentDetails:any;
  loaded = false;
  emailId = 'customerservice.us@seqirus.com'
  ngOnInit(): void {
    localStorage.removeItem('isCancelTransaction')
    let id = localStorage.getItem('checkoutId')
 
    let obj:any = localStorage.getItem('iframeObj')
   

    // setTimeout(() => {
    //   this.iframeData$.subscribe(res=>{
    //     console.log('subject',res);
    //   })
    // }, 1000);
    // this.iframeData$.subscribe(res=>{
    //   console.log('subject',res);

    //   //06728f2b-10b6-71ff-b86f-d2269a968ba9
    //   let obj = {
    //     invoiceAmt: '1027.5',
    //     invoiceNumber: '9130225906',
    //     tokenId: '06729c68-e6c6-71ff-b3ff-147f8d689c86',
    //     poNumber: 'tw0112202368',
    //     creditsAppliedFlag: "applied",
    //     appliedCredits: {
    //       invoiceNumber: '9130225906',
    //       creditNotes: [
    //         {
    //           creditNoteNumber: 9130225307,
    //           creditAmount: 10
    //         }
    //       ],
    //       paymentFlag: ""
    //     }pi
    //   }

      if((JSON.parse(obj)).scheduledDate){
        this.userInfoService.checkScheduledPaymentStatus(obj,id).subscribe(data => {
          console.log(data);
          this.errorCode = data.errorCode;
        this.paymentDetails = data.paymentDetails;
        this.paymentDetails.currentDate = localStorage.getItem('scheduledDate');
        this.cd.detectChanges();
        localStorage.setItem('isCancelled', data.cancelled) 
        localStorage.setItem('iframeErrorCode', data.errorCode) 
         
        this.userInfoService.isCancelled$.next(true);
        if(this.errorCode == 200){
          localStorage.removeItem('iframeInvoiceid');
          localStorage.removeItem('iframeSeason');
          localStorage.removeItem('scheduledDate');

        }
        if(data.cancelled == true){
          window.top?.location.reload();
        }
        

        })
      }
      else{
      this.userInfoService.paymentStatusIframe(obj,id).subscribe(data=>{

        //console.log(document.getElementById('iframeHolder'));
        //document.getElementById('iframeHolder')?.style.display = none
        //(document.querySelector('iframeHolder') as HTMLElement).style.visibility = 'hidden'
        this.loaded = true
     
        this.errorCode = data.errorCode;
        this.paymentDetails = data.paymentDetails;
        this.cd.detectChanges();
        localStorage.setItem('isCancelled', data.cancelled)
        localStorage.setItem('iframeErrorCode', data.errorCode)

        this.userInfoService.isCancelled$.next(true);
        if(this.errorCode == 200){
          localStorage.removeItem('iframeInvoiceid');
          localStorage.removeItem('iframeSeason')
        }
      //   this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      //     this.router.navigate(['financial-dashboard/all-invoices']);
      // });
     //window.location.href= '/financial-dashboard/all-invoices'
     if(data.cancelled == true){
     window.top?.location.reload();
     }
        //this.router.navigate(['financial-dashboard/all-invoices']);
       // location.reload();
      })
    }

    // })

  }
  cancelClicked(){
    window.top?.location.reload();
  }
  cancelTransactionClicked(){
    window.top?.location.reload();
    localStorage.setItem('isCancelTransaction','true')
  }
}
