import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { CmsBannerComponent } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-flucelvax-impactchart',
  templateUrl: './flucelvax-impactchart.component.html',
  styleUrls: ['./flucelvax-impactchart.component.scss']
})
export class FlucelvaxImpactchartComponent implements OnInit {
  referncenode: Observable<any>;
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions!: Highcharts.Options;
  chart1 = [[0, 290], [3, 380], [5, 290]];
  chart2 = [[1, 150], [2, 580], [4, 590], [6, 500], [7, 750], [8, 380], [9, 385]];
  chart4 = [[1, 150], [4, 590], [8, 380], [9, 385]];
  chart3 = [[2, 580], [6, 500], [7, 750]];

  beforeChart1 = [[0, 10], [3, 10], [5, 10]];
  beforeChart2 = [[1, 10], [2, 10], [4, 10], [6, 10], [7, 10], [8, 10], [9, 10]];
  beforeChart3 = [];
  displayLegend1 = false;
  displayLegend2 = false;
  displayLegend3 = false;
  private animationTriggered = false;

  constructor(public bannerComponent: CmsComponentData<CmsBannerComponent>, private cd: ChangeDetectorRef) {
    this.referncenode = this.bannerComponent.data$;
  }
  ngOnInit(): void {
    this.setHighchart(this.beforeChart1, this.beforeChart2, this.beforeChart3);
  }
  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.checkChart();
  }

  checkChart() {
    // const anchor = document.getElementById('impactChartAnchor');
    const chartInfo = document.getElementById('chartsubheader');
    if (chartInfo && !this.animationTriggered) {
      const anchorPosition = chartInfo.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      if (anchorPosition <= windowHeight) {
        this.animationTriggered = true; 
        console.log("animation:",this.animationTriggered);
        this.startChartAnimation();
      }
    }
  }

  startChartAnimation() {
    setTimeout(() => {
      this.setHighchart(this.chart1, this.beforeChart2, this.beforeChart3);
      this.displayLegend1 = true;
      this.cd.detectChanges();
      console.log("1 seconds",this.setHighchart)
    }, 1000);
    setTimeout(() => {
      this.setHighchart(this.chart1, this.chart2, this.beforeChart3);
      this.displayLegend2 = true;
      this.cd.detectChanges();
      console.log("2 seconds",this.setHighchart)
    }, 2000);
    setTimeout(() => {
      this.setHighchart(this.chart1, this.chart4, this.chart3);
      this.displayLegend3 = true;
      this.cd.detectChanges();
      console.log("3 seconds",this.setHighchart)
    }, 3000);
    this.cd.detectChanges();
  }

  setHighchart(render1: number[][], render2: number[][], render3: number[][]): void {
    const yAxisLabels = [0, 200, 400, 600, 800];
    const chartType = window.innerWidth > 1023 ? 'column' : 'bar';

    this.chartOptions = {
      chart: {
        type: chartType
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: ['2010 - 2011', '2011 - 2012', '2012 - 2013', '2013 - 2014', '2014 - 2015', '2015 - 2016', '2016 - 2017', '2017 - 2018', '2018 - 2019', '2019 - 2020'],
        crosshair: true,
        labels: {
          useHTML: true,
          style: {
            color: 'rgba(112, 115, 114, 1)',
            fontSize: '16px',
            fontFamily: 'Campton-Book',
            fontWeight: 'normal',
            lineHeight: '24px',
            paddingLeft: '4px',
            paddingRight: '5px',
            whiteSpace: 'nowrap',
            transform: 'rotate(0deg)' // Override rotation
          },
          rotation: 0, // Explicitly set rotation to 0
          formatter: function () {
            const value = this.value.toString(); // Ensure the value is a string
            const parts = value.split(' - ');
            return parts.join(' -<br/>'); // Ensure '-' is included
          }
       
        },
        accessibility: {
          description: 'Year'
        }
      },
      yAxis: {
        allowDecimals: false,
        min: 0,
        title: {
          text: 'Number of Hospitalizations',
          useHTML: true,
          style: {
            color: '#000000',
            fontSize: '20px',
            fontFamily: 'Campton-semibold',
            fontWeight: '600',
            lineHeight: '28px',
            paddingBottom: '10px',
            transform: 'none'
          }
        },
        tickPositioner: function () {
          return yAxisLabels;
        },
        labels: {
          style: {
            color: '#707372',
            fontSize: '20px',
            fontFamily: 'Campton-semibold',
            fontWeight: '600',
            lineHeight: '26px',
            transform: 'none'
          },
          formatter: function () {
            if (typeof this.value === 'number' && this.value > 0) {
              return this.value + 'k';
            } else {
              return this.value.toString();
            }
          }
        }
      },
      tooltip: {
        valueSuffix: 'k',
        enabled: false
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
          pointWidth: 30 // Moved pointWidth here
        },
        series: {
          enableMouseTracking: false
        }
      },
      series: [
        {
          type: 'column',
          name: 'Matches circulating strains',
          data: render1,
          color: {
            linearGradient: { x1: 1, x2: 1, y1: 1, y2: 0 },
            stops: [
              [0, 'rgb(9, 89, 56)'],
              [1, 'rgb(29, 137, 18)']
            ]
          },
          zIndex: 1,
          pointPlacement: 0.2 // Adjust point placement
        },
        {
          type: 'column',
          name: 'A/H3N2 antigenic drift',
          data: render2,
          color: {
            linearGradient: { x1: 1, x2: 1, y1: 1, y2: 0 },
            stops: [
              [0, 'rgba(157, 160, 159, 1)'],
              [1, 'rgba(76, 76, 76, 1)']
            ]
          },
          zIndex: 0,
          pointPlacement: 0 // Adjust point placement
        },
        {
          type: 'column',
          name: 'A/H3N2 egg adaptation',
          data: render3,
          color: {
            linearGradient: { x1: 1, x2: 1, y1: 1, y2: 0 },
            stops: [
              [0, 'rgba(213, 57, 10, 1)'],
              [1, 'rgba(135, 33, 2, 1)']
            ]
          },
          zIndex: 2,
          pointPlacement: -0.2 // Adjust point placement
        }
      ]
    };
  }
}























































































// import { Component, OnInit } from '@angular/core';
// import { CmsBannerComponent } from '@spartacus/core';
// import { CmsComponentData } from '@spartacus/storefront';
// import { Observable } from 'rxjs';
// import * as Highcharts from 'highcharts';

// @Component({
//   selector: 'app-flucelvax-impactchart',
//   templateUrl: './flucelvax-impactchart.component.html',
//   styleUrls: ['./flucelvax-impactchart.component.scss']
// })
// export class FlucelvaxImpactchartComponent implements OnInit {
//   referncenode: Observable<any>;
//   Highcharts: typeof Highcharts = Highcharts;
//   chartOptions!: Highcharts.Options;

//   constructor(public bannerComponent: CmsComponentData<CmsBannerComponent>) {
//     this.referncenode = this.bannerComponent.data$;
//   }

//   ngOnInit(): void {
//     this.setHighchart();
//   }

//   setHighchart(): void {
//     const yAxisLabels = [0, 200, 400, 600, 800];
//     const chartType = window.innerWidth > 1023 ? 'column' : 'bar';

//     this.chartOptions = {
//       chart: {
//         type: chartType
//       },
//       title: {
//         text: ''
//       },
//       xAxis: {
//         categories: ['2010 - 2011', '2011 - 2012', '2012 - 2013', '2013 - 2014', '2014 - 2015', '2015 - 2016', '2016 - 2017', '2017 - 2018', '2018 - 2019', '2019 - 2020'],
//         crosshair: true,
//         labels: {
//           useHTML: true,
//           style: {
//             color: 'rgba(112, 115, 114, 1)',
//             fontSize: '16px',
//             fontFamily: 'Campton-Book',
//             fontWeight: 'normal',
//             lineHeight: '24px',
//             paddingLeft: '10px',
//             paddingRight: '5px',
//             whiteSpace: 'normal'
//           }
//         },
//         accessibility: {
//           description: 'Year'
//         }
//       },
//       yAxis: {
//         allowDecimals: false,
//         min: 0,
//         title: {
//           text: 'Number of Hospitalizations',
//           useHTML: true,
//           style: {
//             color: '#000000',
//             fontSize: '20px',
//             fontFamily: 'Campton-semibold',
//             fontWeight: '600',
//             lineHeight: '28px',
//             paddingBottom: '10px',
//             transform: 'none'
//           }
//         },
//         tickPositioner: function () {
//           return yAxisLabels;
//         },
//         labels: {
//           style: {
//             color: '#707372',
//             fontSize: '20px',
//             fontFamily: 'Campton-semibold',
//             fontWeight: '600',
//             lineHeight: '26px',
//             transform: 'none'
//           },
//           formatter: function () {
//             if (typeof this.value === 'number' && this.value > 0) {
//               return this.value + 'k';
//             } else {
//               return this.value.toString();
//             }
//           }
//         }
//       },
//       tooltip: {
//         valueSuffix: 'k',
//         enabled: false
//       },
//       credits: {
//         enabled: false
//       },
//       legend: {
//         enabled: false
//       },
//       plotOptions: {
//         column: {
//           pointPadding: 0.2,
//           borderWidth: 0,
//           pointWidth: 30 // Moved pointWidth here
//         },
//         series: {
//           enableMouseTracking: false
//         }
//       },
//       series: [
//         {
//           type: 'column',
//           name: 'Matches circulating strains',
//           data: [[0, 290], [3, 380], [5, 290]],
//           color: {
//             linearGradient: { x1: 1, x2: 1, y1: 1, y2: 0 },
//             stops: [
//               [0, 'rgb(9, 89, 56)'],
//               [1, 'rgb(29, 137, 18)']
//             ]
//           },
//           zIndex: 1
//         },
//         {
//           type: 'column',
//           name: 'A/H3N2 antigenic drift',
//           data: [[1, 150], [2, 580], [4, 590], [6, 500], [7, 750], [8, 380], [9, 385]],
//           color: {
//             linearGradient: { x1: 1, x2: 1, y1: 1, y2: 0 },
//             stops: [
//               [0, 'rgba(157, 160, 159, 1)'],
//               [1, 'rgba(76, 76, 76, 1)']
//             ]
//           },
//           zIndex: 0
//         },
//         {
//           type: 'column',
//           name: 'A/H3N2 egg adaptation',
//           data: [[2, 580], [6, 500], [7, 750]],
//           color: {
//             linearGradient: { x1: 1, x2: 1, y1: 1, y2: 0 },
//             stops: [
//               [0, 'rgba(213, 57, 10, 1)'],
//               [1, 'rgba(135, 33, 2, 1)']
//             ]
//           },
//           zIndex: 2
//         }
//       ]
//     };
//   }
// }


































































































// import { Component } from '@angular/core';
// import { CmsBannerComponent } from '@spartacus/core';
// import { CmsComponentData } from '@spartacus/storefront';
// import { Observable } from 'rxjs';
// import { Chart } from 'angular-highcharts';
// import { chart } from 'highcharts';
// import * as Highcharts from 'highcharts';
// import patternFill from 'highcharts/modules/pattern-fill';
// patternFill(Highcharts);
// interface CustomPoint extends Highcharts.Point {
//   value: number;
// }

// @Component({
//   selector: 'app-flucelvax-impactchart',
//   templateUrl: './flucelvax-impactchart.component.html',
//   styleUrl: './flucelvax-impactchart.component.scss'
// })
// export class FlucelvaxImpactchartComponent {
//   constructor( public bannerComponent: CmsComponentData<CmsBannerComponent>) { }
//   referncenode: Observable<any> = this.bannerComponent.data$;
//   Highcharts: typeof chart = chart;
//   columnChart!: Chart;  
//   var yAxisLabels = [0, 200, 400, 600, 800];
//   var animateone=true;
//   var chart;
//   var xlabel = ['2010-2011', '2011-2012', '2012-2013', '2013-2014', '2014-2015', '2015-2016', '2016-2017', '2017-2018', '2018-2019', '2019-2020'];
//   //var chartData=[[0,10],[1,10],[2,1],[3,1],[4,10],[5,1],[6,1],[7,1],[8,100],[9,100]];
//   var chart1 = [[0, 290], [3, 380], [5, 290]];
//   var chart2 = [[1, 150], [2, 580], [4, 590], [6, 500], [7, 750], [8, 380], [9, 385]];
//   var chart4 = [[1, 150], [4, 590], [8, 380], [9, 385]];
//   var chart3 = [[2, 580], [6, 500], [7, 750]];
//   var chartype = 'column';
//  if(screen.width>1023){
//   chartype = 'column';
//  }
//  else{
//   chartype = 'bar';
//  }
//   sethighchart(chartype);
//   chart = Highcharts.chart('container', {
//     chart: {
//       type: chartype
//     },

//     title:{
//       text:''
//     },
//     xAxis: {
//       categories: ['2010 - 2011', '2011 - 2012', '2012 - 2013', '2013 - 2014', '2014 - 2015', '2015 - 2016', '2016 - 2017', '2017 - 2018', '2018 - 2019', '2019 - 2020'],
//       crosshair: true,
//       align: 'center',
//       labels: {
//         useHTML: true,
//         rotation: 0,

//         style: {
//           color: 'rgba(112, 115, 114, 1)',
//           fontSize: '16px',
//           fontFamily: 'Campton-Book',
//           fontWeight: 'normal',
//           lineHeight: '24px',
//           paddingLeft: '10px',
//           paddingRight: '5px',
//           whiteSpace: 'normal'
//         }
//       },
//       accessibility: {
//         description: 'Year'
//       },
//     },
//     yAxis: {
//       allowDecimals: false,
//       min: 0,
//       title: {
//         text: 'Number of Hospitalizations',
//         useHTML: true,
//         style: {
//           color: '#000000',
//           fontSize: '20px',
//           fontFamily: 'Campton-semibold',
//           fontWeight: '600',
//           lineHeight: '28px',
//           paddingBottom: '10px',
//           transform: 'none',

//         },
//       },
//       tickPositioner: function () {
//         return yAxisLabels;
//       },
//       labels: {
//         style: {
//           color: '#707372',
//           fontSize: '20px',
//           fontFamily: 'Campton-semibold',
//           fontWeight: '600',
//           lineHeight: '26px',
//           transform: 'none'

//         },
//         formatter: function () {
//           if (this.value > 0) {
//             return this.value + 'k'
//           }
//           else {
//             return this.value
//           }
//         }

//       }
//     },
//     tooltip: {
//       valueSuffix: 'k',
//       enabled: false 
//     },
//     credits: {
//       enabled: false
//     },
//     legend: {
//       enabled: false,
//     },
//     plotOptions: {
      
//       column: {
//         pointPadding: 0.2,
//         borderWidth: 0,
//       },
     
//       series: {
//         pointWidth: 30,
//         align: 'center',
//         overflow: 'hidder',
//         centerInCategory: true,
//         enableMouseTracking: false,
//         pointPadding: 0.2,

//       }
//     },
//     series: [

//       {

//         name: 'Matches circulating strains',
//         data: [[0, 10], [3, 10], [5, 10]],
//         description:'nsnsjfjsdsd',
        
        
//         color: {
//           linearGradient: { x1: 1, x2: 1, y1: 1, y2: 0 },
//           stops: [
//             [0, 'rgb(9, 89, 56)'],
//             [1, 'rgb(29, 137, 18)']
//           ]
//         }


//       }, {

//         name: 'A/H3N2 antigenic drift',
//         data: [[1, 10], [2, 10], [4, 10], [6, 10], [7, 10], [8, 10], [9, 10]],
//         description:'nsnsjfjsdsd2',
//         color: {
//           linearGradient: { x1: 1, x2: 1, y1: 1, y2: 0 },
//           stops: [
//             [0, 'rgba(157, 160, 159, 1)'],
//             [1, 'rgba(76, 76, 76, 1)']
//           ]
//         }

//       },
//       {
//         name: 'A/H3N2 egg adaptation',
//         data: [],
//         description:'nsnsjfjsdsd3',
//         color: {
//           linearGradient: { x1: 1, x2: 1, y1: 1, y2: 0 },
//           stops: [
//             [0, 'rgba(213, 57, 10, 1)'],
//             [1, 'rgba(135, 33, 2, 1)']
//           ]
//         }
//       },
//     ]
//   });
// }
// import { Component, OnInit } from '@angular/core';
// import { CmsBannerComponent } from '@spartacus/core';
// import { CmsComponentData } from '@spartacus/storefront';
// import { Observable } from 'rxjs';
// import * as Highcharts from 'highcharts';
// import patternFill from 'highcharts/modules/pattern-fill';
// patternFill(Highcharts);

// @Component({
//   selector: 'app-flucelvax-impactchart',
//   templateUrl: './flucelvax-impactchart.component.html',
//   styleUrls: ['./flucelvax-impactchart.component.scss']
// })
// export class FlucelvaxImpactchartComponent {
//   referncenode: Observable<any>;
//   constructor(public bannerComponent: CmsComponentData<CmsBannerComponent>) {
//     this.referncenode = this.bannerComponent.data$;
//   }
//   Highcharts: typeof Highcharts = Highcharts;
//   chartOptions: Highcharts.Options = {
//     chart: {
//       type: 'bar'
//     },
//     title: {
//       text: 'Fruit Consumption'
//     },
//     xAxis: {
//       categories: ['Apples', 'Bananas', 'Oranges']
//     },
//     yAxis: {
//       title: {
//         text: 'Fruit eaten'
//       }
//     },
//     series: [{
//       type: 'bar',
//       name: 'Jane',
//       data: [1, 0, 4]
//     }, {
//       type: 'bar',
//       name: 'John',
//       data: [5, 7, 3]
//     }]
//   };

// }