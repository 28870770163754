import { ChangeDetectorRef, Component } from '@angular/core';
import { CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { StartOrderService } from '../../custom-start-order/start-order.service';
import { ContentService } from '../../spartacus/services/content.service';

@Component({
  selector: 'app-return-banner',
  templateUrl: './return-banner.component.html',
  styleUrl: './return-banner.component.scss'
})
export class ReturnBannerComponent {
pageLabel : string ="";
  constructor(public component: CmsComponentData<CmsBannerComponent>, protected config: OccConfig, public accessService: ContentService, public cd: ChangeDetectorRef, private startOrderService: StartOrderService,private cmsService:CmsService ){
    this.cmsService.getCurrentPage().subscribe((data)=>{
      this.pageLabel = data.pageId?data.pageId:"";
    })
  }
}
