import { Component } from '@angular/core';
import { CmsBannerComponent, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-flucelvax-referenceparagraph',
  templateUrl: './flucelvax-referenceparagraph.component.html',
  styleUrl: './flucelvax-referenceparagraph.component.scss'
})
export class FlucelvaxReferenceparagraphComponent {
  constructor( public bannerComponent: CmsComponentData<CmsBannerComponent>,protected config: OccConfig) { }
  referncenode: Observable<any> = this.bannerComponent.data$;

  getpdf(pdf: any): string {
    return this.config.backend?.occ?.baseUrl + pdf?.url + "&attachment=true";
  }
}
