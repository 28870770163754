<!--loggedin-->

<div *ngIf="isUserLoggedIn && userInfo?.webEnabled" class="tools_datasearch1 loggedin">
    <div class="tools_filtername1">
        <div class="tools_filterLabel1">Filter by</div>
        <img alt="Filter" src="../../../assets/images/filter.png" width="25" />

    </div>
    <div class="tools_filterbox1">
        <div class="tools_filterboxparent1" (click)="toggleFilter('solution')">
            <div class="tools_filterboxname1">Solution Suite<span *ngIf="selectedSolutionsCount > 0">({{
                    selectedSolutionsCount }})</span></div>
            <span class="tools_productarrow glyphicon glyphicon-menu-down" [ngClass]="{
                'glyphicon-menu-down': !showSolutionFilter,
                'glyphicon-menu-up': showSolutionFilter
              }"></span>
            <div class="clearfix"></div>
        </div>
        <div class="solutionopen" *ngIf="showSolutionFilter">
            <div class="statussection">
                <div class="tools_checkboxparent" *ngFor="let solution of filterdata.resourceTypeList">
                    <label class="checkbox-button">
                        <input type="checkbox" name="solution" [value]="solution"
                            (change)="onCheckboxChange('solution', solution, $event)" class="tools_checkbox"
                            [checked]="selectedType.includes(solution)">
                        <span class="checkbox-button__control"></span>
                    </label>
                    <label>{{ solution }}</label>
                </div>
            </div>
        </div>
    </div>
    <div class="tools_productbox1">
        <div class="tools_productboxparent1" (click)="toggleFilter('product')">
            <div class="tools_productboxname1">Products<span *ngIf="selectedProductsCount > 0">({{ selectedProductsCount
                    }})</span></div>
            <span class="tools_productarrow glyphicon glyphicon-menu-down" [ngClass]="{
                'glyphicon-menu-down': !showProductFilter,
                'glyphicon-menu-up': showProductFilter
              }"></span>
            <div class="clearfix"></div>
        </div>
        <div class="productopen" *ngIf="showProductFilter">
            <div class="statussection">
                <div class="tools_checkboxparent1" *ngFor="let product of filterdata.resourceProductList">
                    <label class="checkbox-button">
                        <input type="checkbox" name="product" [value]="product"
                            (change)="onCheckboxChange('product', product, $event)" class="tools_checkbox"
                            [checked]="selectedProduct.includes(product)">
                        <span class="checkbox-button__control"></span>
                    </label>
                    <label>{{ product }}&reg;</label>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="tools-and-resources col-xs-12 col-md-12 tools-resources-outercontainer">
    <div class="resourceFilterContainer col-xs-12 col-md-12">
        <div class="tools_datasearch">
           
            <div class="tools_filtername">
                <div class="tools_filterLabel">Filter by</div>
                <img alt="Filter" src="../../../assets/images/filter.png" width="25" />
            </div>
           
            <div class="tools_filterbox">
                <div class="tools_filterboxparent" (click)="toggleFilter('solution')">
                    <div class="tools_filterboxname">
                        Solution Suite<span *ngIf="selectedSolutionsCount > 0">({{ selectedSolutionsCount }})</span>
                    </div>
                    <span class="tools_productarrow glyphicon-1" [ngClass]="{
                           'glyphicon-menu-down': !showSolutionFilter,
                           'glyphicon-menu-up': showSolutionFilter
                         }">
                    </span>
                    <div class="clearfix"></div>
                </div>
                <div class="solutionopen" *ngIf="showSolutionFilter">
                    <div class="statussection">
                        <div class="tools_checkboxparent"
                            *ngFor="let solution of filterdata.resourceTypeList">
                            <label class="checkbox-button">
                                <input type="checkbox" name="solution" [value]="solution"
                                    (change)="onCheckboxChange('solution', solution, $event)" class="tools_checkbox"
                                    [checked]="selectedType.includes(solution)" />
                                <span class="checkbox-button__control"></span>
                            </label>
                            <label>{{ solution }}</label>
                        </div>
                    </div>
                </div>
            </div>
          
            <div class="tools_productbox">
                <div class="tools_productboxparent" (click)="toggleFilter('product')">
                    <div class="tools_productboxname">
                        Products<span *ngIf="selectedProductsCount > 0">({{ selectedProductsCount }})</span>
                    </div>
                    <span class="tools_productarrow glyphicon-1" [ngClass]="{
                    'glyphicon-menu-down': !showProductFilter,
                    'glyphicon-menu-up': showProductFilter
                  }"></span>
                    <div class="clearfix"></div>
                </div>
                <div class="productopen" *ngIf="showProductFilter">
                    <div class="statussection">
                        <div class="tools_checkboxparent" *ngFor="let product of filterdata.resourceProductList">
                            <label class="checkbox-button">
                                <input type="checkbox" name="product" [value]="product"
                                    (change)="onCheckboxChange('product', product, $event)" class="tools_checkbox"
                                    [checked]="selectedProduct.includes(product)" />
                                <span class="checkbox-button__control"></span>
                            </label>
                            <label>{{ product }}&reg;</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="resourcefilterTableForNewPage_wrapper" class="dataTables_wrapper no-footer">
            <table id="resourcefilterTableForNewPage" class="dataTable no-footer" role="grid" *ngIf="details.length">
                <thead></thead>
                <tbody>
                    <!-- Dynamically Render Tiles -->
                    <tr *ngFor="let resource of details">
                        <td>
                            <div class="tools_img">
                                <img [alt]="resource.tileDescription" [src]="getImage(resource.tileImage)" />
                            </div>
                            <div class="tools_solutiontype">{{ resource.tileType }}</div>
                            <div class="tools_title" [innerHTML]="resource.tileHeadLine"></div>
                            <div class="tools_text">{{ resource.tileDescription }}</div>
                            <ng-container *ngIf="resource.tileLinkLabel != 'Order Now'">
                                <a [routerLink]="resource.tileLink">
                                    <div class="tools_accessparent">
                                        <div class="tools_accesstext">{{ resource.tileLinkLabel }}</div>
                                        <img class="arrow" alt="arrow" src="../../../assets/images/blackarrow.png"
                                            width="19" />
                                    </div>
                                </a>
                            </ng-container>
                            <ng-container *ngIf="resource.tileLinkLabel === 'Order Now'">
                                <a [href]="resource.tileLink" target="_blank">
                                    <div class="tools_accessparent">
                                        <div class="tools_accesstext">{{ resource.tileLinkLabel }}</div>
                                        <img class="arrow" alt="arrow" src="../../../assets/images/blackarrow.png"
                                            width="19" />
                                    </div>
                                </a>
                            </ng-container>
                        </td>
                    </tr>
                </tbody>
            </table>
            <!-- Display message if no matching records are found -->
            <div *ngIf="!details.length" class="no-records-found">
                <table id="resourcefilterTableForNewPage-norecords" class="dataTable no-footer" role="grid">
                    <tbody>
                        <tr class="odd">
                            <td valign="top" colspan="1" class="dataTables_empty">No matching records found</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- Pagination Section -->
            <div class="dataTables_paginate paging_full_numbers">
                <!-- First Page Button(directly will take us to the first page) -->
                <a class="paginate_button previous first-page-button" [class.disabled]="currentPage === 0"
                    (click)="goToPage(0)" [ngClass]="{'disabled-button': currentPage === 0}">
                    <img alt="Navigate to previous page"
                        src="../../../assets/images/pagination-double-prev-arrow-icon.svg" />
                </a>
                <!-- Previous Page Button -->
                <a class="paginate_button first prev-page-btn" [class.disabled]="currentPage === 0"
                    (click)="goToPage(currentPage - 1)"
                    [ngClass]="{'disabled-button': currentPage === 0 || totalPages === 1}">
                    <img alt="Navigate to previous page" src="../../../assets/images/pagination-prev-btn.svg" />
                </a>
                <!-- Page Numbers -->
                <span>
                    <a *ngFor="let page of [].constructor(totalPages); let i = index" class="paginate_button"
                        [class.current]="i === currentPage" (click)="goToPage(i)">
                        {{ i + 1 }}
                    </a>
                </span>
                <!-- Next Page Button -->
                <a class="paginate_button next nxt-page-btn" [class.disabled]="currentPage === totalPages - 1"
                    (click)="goToPage(currentPage + 1)"
                    [ngClass]="{'disabled-button': currentPage === totalPages - 1 || totalPages === 1}">
                    <img alt="Navigate to next page" src="../../../assets/images/pagination-next-btn.svg" />
                </a>
                <!-- Last Page Button(directly takes us to last page) -->
                <a class="paginate_button last last-page-button" [class.disabled]="currentPage === totalPages - 1"
                    (click)="goToPage(totalPages - 1)" [ngClass]="{'disabled-button': currentPage === totalPages - 1}">
                    <img alt="Navigate to next page"
                        src="../../../assets/images/pagination-double-next-arrow-icon.svg" />
                </a>
            </div>
        </div>
    </div>
</div>



<!-- Resource Tiles -->
<!-- <div class="resourceFilterContainer">
        <table id="resourcefilterTableForNewPage" class="dataTable no-footer" role="grid" *ngIf="details.length">
            <thead>

            </thead>
            <tbody>
              
                <tr *ngFor="let resource of details">
                    <td>
                        <div class="tools_img">
                            <img [alt]="resource.tileDescription" [src]="getImage(resource.tileImage)">
                        </div>
                        <div class="tools_solutiontype">{{ resource.tileType }}</div>
                        <div class="tools_title" [innerHTML]="resource.tileHeadLine"></div>
                        <div class="tools_text">{{ resource.tileDescription }}</div>
                
                        <ng-container *ngIf="resource.tileLinkLabel != 'Order Now'">
                            <a [routerLink]="resource.tileLink">
                                <div class="tools_accessparent">
                                    <div class="tools_accesstext">{{ resource.tileLinkLabel }}</div>
                                    <img class="arrow" alt="arrow" src="../../../assets/images/blackarrow.png"
                                        width="19" />
                                </div>
                            </a>
                        </ng-container>
                        <ng-container *ngIf="resource.tileLinkLabel === 'Order Now'">
                            <a [href]="resource.tileLink" target="_blank">
                                <div class="tools_accessparent">
                                    <div class="tools_accesstext">{{ resource.tileLinkLabel }}</div>
                                    <img class="arrow" alt="arrow" src="../../../assets/images/blackarrow.png"
                                        width="19" />
                                </div>
                            </a>
                        </ng-container>
                    </td>
                </tr>
            </tbody>
        </table>

        <div class="dataTables_paginate paging_full_numbers" *ngIf="totalPages > 1">
            <a class="paginate_button first" [class.disabled]="currentPage === 0" (click)="goToPage(0)">
                <img alt="Navigate to first page" src="../../../assets/images/pagination-prev-btn.svg" />
            </a>
  
            <a class="paginate_button previous" [class.disabled]="currentPage === 0"
                (click)="goToPage(currentPage - 1)">
                <img alt="Navigate to previous page"
                    src="../../../assets/images/pagination-double-prev-arrow-icon.svg" />
            </a>
           
            <span>
                <a *ngFor="let page of [].constructor(totalPages); let i = index" class="paginate_button"
                    [class.current]="i === currentPage" (click)="goToPage(i)">
                    {{ i + 1 }}
                </a>
            </span>
          
            <a class="paginate_button next" [class.disabled]="currentPage === totalPages - 1"
                (click)="goToPage(currentPage + 1)">
                <img alt="Navigate to next page" src="../../../assets/images/pagination-next-btn.svg" />
            </a>
       
            <a class="paginate_button last" [class.disabled]="currentPage === totalPages - 1"
                (click)="goToPage(totalPages - 1)">
                <img alt="Navigate to last page" src="../../../assets/images/pagination-double-next-arrow-icon.svg" />
            </a>
        </div>
    </div> -->