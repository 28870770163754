import { ChangeDetectorRef, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { ContentService } from '../../../spartacus/services/content.service';
import { HttpClient } from '@angular/common/http';
import { DownloadFileService } from '../../../spartacus/services/download-file.service';
import { UserInfoService } from '../../../spartacus/services/user-info.service';

@Component({
  selector: 'app-custom-detailedpage',
  templateUrl: './custom-detailedpage.component.html',
  styleUrl: './custom-detailedpage.component.scss'
})
export class CustomDetailedpageComponent {
  componentdata: any;
  accessresourcedata: any;
  currentRoute: string = "";
  accessResources: any;
  finalArray: any;
  selectedpdfurl: any= [];
  // selectedpdfurl = [""];
  // @ViewChild('videoPlayer', { static: false }) videoPlayer: ElementRef;
  constructor(private cmsService: CmsService,
    protected config: OccConfig,
    public bannerComponent: CmsComponentData<CmsBannerComponent>,
    private contentservice: ContentService,
    private router: Router,
    private http: HttpClient,
    private downloadservice: DownloadFileService,
    private userInfoService: UserInfoService,
    private renderer: Renderer2,
    private cd: ChangeDetectorRef) {
  }
  userLoggedIn: boolean = false;
  userInfo: any;
  webEnabled: any;
  ngOnInit(): void {
    //for splitting the url
    let url = this.router.url.split('/');
    this.currentRoute = url[url.length - 1];
    //to retrive the  flex component page content
    this.contentservice.getdetailedcomponentsdata(this.currentRoute).subscribe((res: any) => {
      this.componentdata = res;
      //to retrieve acess resources
      this.accessresourcedata = res.additionalResourceList;
      this.contentservice.getDetailedAccessResources(this.accessresourcedata).subscribe((data: any) => {
        this.finalArray = data.results;
        this.cd.detectChanges();
      });
    });
    this.userInfoService.userLoggedIn$.subscribe(res=>{
      this.userLoggedIn = res;
      if(this.userLoggedIn){
      // this.userInfoService.getUserData().subscribe(res=>{
      //   console.log(res);
      // if(res){
      //   this.userInfo=res;

      //   this.cd.detectChanges();
      //   }
      // });
      this.webEnabled =  localStorage.getItem('webEnabled');

    }

    })
    if(this.userInfo?.webEnabled == false){
      this.renderer.setStyle(document.querySelector('.SideContent'),'display','none');
     }
    this.cd.detectChanges();
  }

  // playVideo() {
  //   this.videoPlayer.nativeElement.play();
  // }
  getImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image?.url;
  }
  getpdf(pdf: any): string {
    return this.config.backend?.occ?.baseUrl + pdf.url;
  }
  pdfDownload(url: string): void {

    this.downloadservice.downloadFile(url)
      .subscribe(response => {
        let fileName = 'detailed.pdf';
        let blob: Blob = response.body as Blob;
        let a = document.createElement('a');
        a.download = fileName;
        a.href = window.URL.createObjectURL(blob);
        a.target = '_blank';
        a.click();
      },
      );
  }

  downloadselectedpdf(event: Event, url: string) {
    const checkbox = event.target as HTMLInputElement;
    if (checkbox.checked) {
      this.selectedpdfurl.push(url);
    } else {
      var index = this.selectedpdfurl.indexOf(url);
      this.selectedpdfurl.splice(index, 1);
    }
  }

  
  downloadpdfs() {
    // const link=document.createElement('a');
    // link.href= this.selectedpdfurl;
    // link.click();

    if (this.selectedpdfurl.length > 0) {
      // for (let i = 0; i < this.selectedpdfurl.length; i++) {
      //   window.open(this.selectedpdfurl[i], '_blank');
      // }
      this.selectedpdfurl.forEach((url: any) => {       
        window.open(url, '_blank');     
      });
    }
  }

  // downloadSelected() {
  //   const checkboxes = document.querySelectorAll('.download-list__item input[type="checkbox"]:checked');
  //   checkboxes.forEach(checkbox => {
  //     const link = document.createElement('a');
  //     link.href = checkbox.name; // Assuming the name attribute contains the URL of the PDF
  //     link.download = ''; // You can set a filename if needed
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   });
  // }
}
