import { AbstractControl, ValidatorFn } from '@angular/forms'

export function codeValidation(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value as string
    let firstValid = false;
    let secondValid = false;
    const pattern = /^[a-z0-9]+$/i;
    if(value.includes(" ") && value.split(" ").length == 2 && value.split(" ")[0] && value.split(" ")[1]){
        const splitVal = value.split(" ");
        firstValid = pattern.test(splitVal[0]) && splitVal[0].length > 1 && splitVal[0].length < 5;
        secondValid = pattern.test(splitVal[1]) && splitVal[1].length == 3;
    }

    if (firstValid && secondValid) {
     return null; 
    } else {
     return { codeValidation: true };
    }
  }
}