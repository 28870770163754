import { ChangeDetectorRef, Component } from '@angular/core';
import { CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { ContentService } from '../../spartacus/services/content.service';
import { Observable } from 'rxjs';
import { ReturnsUkService } from '../../returns-uk/returns-uk.service';

@Component({
  selector: 'app-custom-picture-resources',
  templateUrl: './custom-picture-resources.component.html',
  styleUrl: './custom-picture-resources.component.scss'
})
export class CustomPictureResourcesComponent {
constructor(public component: CmsComponentData<CmsBannerComponent>, protected config: OccConfig, public accessService: ContentService, public cd: ChangeDetectorRef,private cmsService:CmsService, private ukservice: ReturnsUkService) {
  }
  // pictureresource : Observable<any> = this.component.data$;
  pictureresource : Observable<any> = this.cmsService.getComponentData('seqirusPictureAndResourcesComponent');
  getImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.url;
  }
  onClickRegisterd(){
    this.ukservice.registerReturn().subscribe(res=>{

    });
  }
}
