import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { CmsBannerComponent } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { StartOrderService } from '../../custom-start-order/start-order.service';
import { Router } from '@angular/router';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { ContentService } from '../../spartacus/services/content.service';


@Component({
  selector: 'app-locationpage',
  templateUrl: './locationpage.component.html',
  styleUrl: './locationpage.component.scss'
})
export class LocationpageComponent {
  displayedColumns: string[] = ['checkbox', 'locname', 'state', 'address', 'orderPlaced'];
  dataSource: any;
  selection = new SelectionModel<any>(true, []);
  seasonValue: string = '';
  shippingLocations: any;
  enablePage: any;
  isLoading: boolean = false;
  inseason: string = '';
  preseason: string = '';
  landingapi: any;
  constructor(public component: CmsComponentData<CmsBannerComponent>, private cdr: ChangeDetectorRef, private router: Router, private startOrderService: StartOrderService, private userInfoService: UserInfoService,private contentservice: ContentService) {

  }
  locationData: Observable<any> = this.component.data$;
  exitPopupData: any;
  ngOnInit() {
    this.contentservice.startapi().subscribe(data => {
      this.landingapi=data;
      this.inseason=this.landingapi?.currentInSeason;
      this.preseason=this.landingapi?.prePreSeason;
      this.cdr.detectChanges();
    });
    this.userInfoService.getEnablePage().subscribe((data: any) => {
      this.enablePage = data;
      if (this.enablePage.showLocationPage && !this.enablePage.isProductBackBtnClicked) {
        this.isLoading = true;
        this.scrollToTop();
        this.userInfoService.getstartorderseason().subscribe((seasonValue: any) => {
          this.seasonValue = seasonValue;
          this.startOrderService.getUkShippingLocations(seasonValue).subscribe((data: any) => {
            if(data && this.enablePage.showLocationPage && !this.enablePage.isProductBackBtnClicked) {
            this.shippingLocations = data.shippingData.filter((el: any) => {
              if (el.rbaPermission === 'manage') {
                return el;
              }
            })
            this.shippingLocations.forEach((el: any) => {
              el.checked = el.checked == "true" ? true : false;
              el['rowChecked'] = false;
              if (el.rowChecked) {
                this.selection.toggle(el);
              }
            })
            
            this.dataSource = new MatTableDataSource(this.shippingLocations);
            this.dataSource.filterPredicate = function (data: any, filter: string): boolean {
              return (data.locname && data.locname.toLowerCase().includes(filter)) || (data.address && data.address.toLowerCase().includes(filter)) || (data.state && data.state.toLowerCase().includes(filter));
            };
            this.isLoading = false;
            this.cdr.detectChanges();
          }
          }, (error) => {
            console.log('error: ', error);
            this.isLoading = false;
            this.startOrderService.setApiFailurePopup(true);
            this.cdr.detectChanges();
          })
        }, (error) => {
          console.log('error: ', error);
          this.isLoading = false;
          this.cdr.detectChanges();
        })
      }
    }, (error) => {
      console.log('error: ', error);
      this.isLoading = false;
      this.cdr.detectChanges();
    })
    // const data = [{
    //   "address": "1230 BAY DALE DR",
    //   "checked": "",
    //   "locID": "0060054154",
    //   "locname": "Rediclinic #48489",
    //   "rbaPermission": "manage",
    //   "state": "ARNOLD, MD  21012"
    // }, {
    //   "address": "8701 GEORGIA AVE STE 100",
    //   "checked": "",
    //   "locID": "0060054166",
    //   "locname": "Rediclinic #46761",
    //   "rbaPermission": "manage",
    //   "state": "SILVER SPRING, MD  20910"
    // }];
  }

  handleOnRowClick(row: any) {
    row['rowChecked'] = !row['rowChecked'];
    this.selection.toggle(row);
    this.cdr.detectChanges;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  handleOnSelectAllBtnClick() {
    this.dataSource.data.forEach((row: any) => {
      if (row['rowChecked'] == false && row['checked'] == false) {
        row['rowChecked'] = true;
        this.selection.toggle(row);
      }
    })
  }
  handleOnClearSelection() {
    this.dataSource.data.forEach((row: any) => {
      row['rowChecked'] = false;
    })
    this.selection.clear();
  }
  handleOnOrderLinkClick() {
    this.userInfoService.setPopUpValue(true);
    this.userInfoService.setRouteLink("/orders/my-orders");
    this.userInfoService.setQueryParams({ season: this.seasonValue });
    /* this.router.navigate(
      ['/orders/my-orders'],
      { queryParams: { season: this.seasonValue } }
    ); */
  }
  handleOnExitClick() {
    this.userInfoService.setPopUpValue(true);
    this.userInfoService.setRouteLink("/my-account");
  }
  handleRequestDoseBtnClick() {
    const selectedLoc = this.selection.selected.map((el: any) => {
      return el.locID;
    })
    const locationPayload = {
      selectedLoc: [...selectedLoc],
      season: this.seasonValue
    }
    this.startOrderService.getUkProductDetails(locationPayload);
    const obj = {
      showLandingPage: false,
      showLocationPage: false,
      showProductPage: true,
      isProductBackBtnClicked: false,
      showReviewPage: false
    }
    this.userInfoService.setEnablePage(obj);
  }
  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
