import { ChangeDetectorRef, Component, Renderer2 } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseSiteService, CmsService, RoutingService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { ContentService } from '../../spartacus/services/content.service';
import { HttpParams } from '@angular/common/http';
import { CmsComponentData } from '@spartacus/storefront';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { JoinAccountService  } from '../../shared';


@Component({
  selector: 'app-join-account',
  templateUrl: './join-account.component.html',
  styleUrl: './join-account.component.scss'
})
export class JoinAccountComponent {

  jointaccountFormUS:FormGroup;
  jointaccountFormUK:FormGroup;
  accountName:Observable<string> | undefined;
  requestStatus: Observable<any> | undefined;
  showReqAssistanceSuccess: boolean = false;
  isChecked = false;
  joinAccSubmitStatus: string="";
  submitted: boolean = false;
  currentSite: string="";
  isUserLoggedIn: boolean=false;
  userInfo: any;
  ukJoinAccResp: any;

  constructor(protected service:ContentService,private cdr:ChangeDetectorRef,private fb:FormBuilder,public joinAccComponent: CmsComponentData<any>,
    private router:RoutingService,private baseSiteService:BaseSiteService,private userInfoService:UserInfoService,public renderer:Renderer2,
  private joinAccountService: JoinAccountService) {

    this.jointaccountFormUS = this.fb.group({
      account:["",[Validators.required,Validators.pattern('^[0-9]*$'),Validators.minLength(8)]],
      accessCodeFlag:["",[Validators.required,Validators.pattern('^\\d{5}(-\\d{4})?$')]]
    });
    this.jointaccountFormUK = this.fb.group({
      account:["",[Validators.required,Validators.pattern('^[0-9]+$'),Validators.minLength(8),Validators.maxLength(10)]],
      accessCodeFlag:["",[Validators.required,Validators.maxLength(8),Validators.pattern(/^(([A-Za-z0-9]){2}([ ]){1}([A-Za-z0-9]){3}|([A-Za-z0-9]){3}([ ]){1}([A-Za-z0-9]){3}|([A-Za-z0-9]){4}([ ]){1}([A-Za-z0-9]){3})$/i)]]
    })
  }

  joinAccData: Observable<any> = this.joinAccComponent.data$;

  onCheckboxChange(event:Event){
    const check = event.target as HTMLInputElement;
    this.isChecked = check.checked;
  }

  hasError(controlName:string,errorName:string){
    return this.jointaccountFormUS.controls[controlName].hasError(errorName);
  }

  hasErrorInUK(controlName:string,errorName:string){
    return this.jointaccountFormUK.controls[controlName].hasError(errorName);
  }

  submitForm(){
    this.submitted =true;
    if(this.jointaccountFormUS.valid){
      this.accountName = this.service.getSearchAccount(getJoinAccFormData(this.jointaccountFormUS.value));
      this.cdr.detectChanges();
    }
  }
  searchUKForm(){
    this.submitted =true;
    this.jointaccountFormUK.markAllAsTouched();
    if(this.jointaccountFormUK.valid){
      this.accountName = this.service.getSearchAccount(getJoinAccFormData(this.jointaccountFormUK.value));
      this.cdr.detectChanges();
    }
  }

  submitJoinAccount(orgName:string){
    if(this.currentSite == 'seqirusb2b-flu360'){
      this.isChecked = !this.isChecked;
    this.service.submitJoinAccount(getSubmitJoinAccFormData(this.jointaccountFormUS.value,orgName)).subscribe(response => {
     this.joinAccSubmitStatus = response.result;
     //this.userInfoService.setInPlaceOrder(false);
    if(this.joinAccSubmitStatus === "join-account-success"){
      this.router.go(['/my-account']).then(() => {
        window.location.reload();
        // Add a new state to the history
    history.pushState({}, '', window.location.href);
    // Listen for the popstate event to prevent back navigation
    window.addEventListener('popstate', function(event) {
        if (window.location.pathname === '/register/join-account') {
            history.pushState({}, '', window.location.href);
        }
    });

      });
       // this.router.go('/my-account');
        this.userInfoService.checkUserLoggedIn();

      }
    });
  }
  else{
    this.service.submitJoinAccount(getSubmitJoinAccFormData(this.jointaccountFormUK.value,orgName)).subscribe(response => {
      this.joinAccSubmitStatus = response.result;
      this.ukJoinAccResp = response;
      this.userInfoService.setInPlaceOrder(false);
      this.service.updateJoinAccResponse(response);
      this.cdr.detectChanges();
      if(!this.userInfo.webEnabled && this.joinAccSubmitStatus === "join-account-success"){
        this.userInfoService.updateUserdata();
         this.router.go(['/joinAccountThankYou']);
       }
       if(this.joinAccSubmitStatus === "join-account-success") {
          this.joinAccountService.triggerFormSubmitted();
       }
     });
  }


  }

  raiseAssistanceReq():any{

    this.requestStatus = this.service.getRequestAssistance(getFormDataForReqAss(this.jointaccountFormUS.value));

    this.requestStatus.subscribe(data =>{
      if(data.status === 200){
        this.showReqAssistanceSuccess = true;
        this.cdr.detectChanges();
      }
    })
  }


  ngOnInit(){
    
    this.baseSiteService.getActive().subscribe(site => {
      this.currentSite = site;
    });
    if(this.currentSite ==  'seqirusb2b-uk'){
      const body=<HTMLElement>document.querySelector("body");
    body.classList.remove('modal-open')
      this.userInfoService.setInPlaceOrder(true);
    }
    
    this.userInfoService.userLoggedIn$.subscribe(res=>{
      this.isUserLoggedIn=res;
      if(this.isUserLoggedIn){
        this.userInfoService.getUserData().subscribe(res=>{
          if(res){
          this.userInfo=res;
          this.cdr.detectChanges();
          const element=document?.querySelector('.SideContent') as HTMLElement;
          const bodyslot=document?.querySelector('.BodyContent') as HTMLElement;
          if(this.userInfo?.webEnabled == true){
            element?.setAttribute("style","display:block");
           // this.renderer.setStyle(document?.querySelector('.SideContent'),'display','block');
            this.cdr.detectChanges();
          }
          else{
            element?.setAttribute("style","display:none");
            bodyslot?.setAttribute("style","width:100%");
           // this.renderer?.setStyle(document?.querySelector('.SideContent'),'display','none');
           // this.renderer?.setStyle(document?.querySelector('.BodyContent'),'width','100%');
            this.cdr.detectChanges();
          }

          }
        });
      }
    });


  }
}

function getSubmitJoinAccFormData(value: any,orgName:string): any {
  return {
    accountNumber: value.account,
    zipCode: value.accessCodeFlag,
    orgName: orgName

  };

}

function getJoinAccFormData(value: any): any {
  return {
    account:value.account,
    accessCodeFlag:value.accessCodeFlag,
  };
}
function getFormDataForReqAss(value: any): any {
  return {
    account:value.account,
    zipCode:value.accessCodeFlag,
  };
}

