<div *ngIf="isLoading" class="loading">
    <div class="loading_txt">
        <div class="load_txt">Loading</div>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
    </div>
</div>
<div class="reviewOrderBody_container" id="OM_reviewOrder" *ngIf="enablePage.showReviewPage && reviewPageData">
    <div class="col-xs-6 cart_neworderheader pb-5">New {{seasonValue | seasonspilt}} Order</div>
    <section class="col-xs-12 OM_productDoseSection">
        <button class="OM_backText" (click)="handleOnBackBtnClick()">
            <img class="OM_backArrow" alt="Previous screen" src="../../../assets/images/blackarrow.png">Back to Delivery
            Week Selection</button>
        <div class="col-xs-12 OM_selectline"></div>
        <div class="clearboth"></div>
        <div class="OM_reviewOrderHeader">Review Order</div>
        <div class="OM_reviewOrderSubHeader mt-4">Organisation Name</div>


        <section class="col-xs-12 col-md-12 no-pad costContainerDiv">
            <div class="col-xs-12 col-md-7 no-pad">
                <div class="OM_reviewOrderTableName">Summary</div>
                <!-- Product cost Table starts -->
                <table id="prodCostTable">
                    <tbody>
                        <tr class="border_bottom">
                            <th class="tablebg">Location&nbsp;</th>
                            <ng-container *ngFor="let product of reviewPageData.productList">
                            <ng-container *ngIf="product.baseProductName == 'aQIV - 10 Pack'">
                                <th class="tablebg aqivPrice" >aQIV Doses
                                    <div>({{product.priceData.formattedValue}} per dose)</div>
                                </th>
                            </ng-container>
                            <ng-container *ngIf="product.baseProductName == 'aTIV - 10 Pack'">
                                <th class="tablebg aqivPrice" >aTIV Doses
                                    <div>({{product.priceData.formattedValue}} per dose)</div>
                                </th>
                            </ng-container>
                            <ng-container *ngIf="product.baseProductName == 'QIVc - 10 Pack'">
                                <th class="tablebg aqivPrice">QIVc Doses
                                    <div>({{product.priceData.formattedValue}} per dose)</div>
                                </th>
                            </ng-container>
                            <ng-container *ngIf="product.baseProductName == 'TIVc - 10 Pack'">
                                <th class="tablebg aqivPrice">TIVc Doses
                                    <div>({{product.priceData.formattedValue}} per dose)</div>
                                </th>
                            </ng-container>
                            <!-- <th class="tablebg qivcPrice">QIVc Doses
                                <div class="reviewPriceDetails">
                                    (£<span>10.30</span> per dose)
                                </div>
                            </th> -->
                            </ng-container>
                            <th class="tablebg">Total Cost<div class="reviewPriceDetails">(Excluding VAT)</div>
                            </th>
                        </tr>
                        <ng-container *ngFor="let location of reviewPageData.reviewData.cartEntries">
                        <tr class="border_bottom reviewProdCount" id="prodLocation1">
                            <td class="OM_productLocation reviewPageLoc">{{location.locname}}</td>
                            <ng-container *ngFor="let product of location.products">
                            <td class="aQIVDose">{{product.qty | number}}</td>
                            </ng-container>
                            <!-- code for qivc extra field -->
                            <!-- <td>
                                <div class="reviewDoseField">
                                    <div class="QIVcDose">40</div>
                                    <div class="reviewTableQivc"><span>* </span><span
                                            class="reviewTableQivcSpan"></span></div>
                                </div>
                            </td> -->
                            <td class="totalLocationDose">£{{location.prodCost}}</td>

                        </tr>
                        
                    </ng-container>
                        <tr>
                            <td class="tablebg totalText">Total</td>
                            <td class="tablebg aqivTotal">{{aqivCost}}</td>
                            <td class="tablebg">
                                <!-- code for qivc extra field -->
                                <div class="reviewDoseField">
                                    <div class="qivcTotal">{{qivcCost}}</div>
                                    <div class="qivcTotalExtra" style="display: none;"></div>
                                </div>
                            </td>
                            <td class="tablebg finalCost">£{{totalCost}}</td>
                        </tr>
                    </tbody>
                </table>
                <div class="col-xs-12 astericText" *ngIf="seasonValue == preseason">
                    <div class="astericTextpara1">* Risk-Free Ordering</div>
                    <div class="astericTextpara2">Should new influenza policy guidance be issued that no longer recommends either CSL Seqirus flu vaccines in their respective age group(s), you can cancel that respective part of your order.</div>
                </div>
            </div>
            <div class="col-xs-12 col-md-4">
                <div class="rightText1">Sales Contact Information</div>
                <div class="rightText2">Communications regarding placing this order will be sent to the following
                    person. For each location, you can indicate the best contact to receive delivery updates below.
                </div>
                <div class="rightText3" *ngIf="reviewPageData.reviewData.salesContact">
                    {{reviewPageData.reviewData.salesContact.name}}<br>{{reviewPageData.reviewData.salesContact.email}}<br>{{reviewPageData.reviewData.salesContact.phone}}
                    Ext. {{reviewPageData.reviewData.salesContact.ext}}</div>
                <div class="rightText4">Order Terms</div>
                <div class="rightText5">Sale or Return</div>
                <div class="rightText6">
                    You will be eligible to return up to <span>
                        <ng-container *ngIf="reviewPageData.priceResponse.SOR;else zersor1">{{reviewPageData.priceResponse.SOR | number}}</ng-container>
                        <ng-template #zersor1>0</ng-template>%</span>
                    of your ordered volume, per product</div>
                <div class="rightText7">Credit Days</div>
                <div class="rightText8">
                    Invoices will be issued with <span>
                        {{reviewPageData.priceResponse.creditDays}}</span>
                    credit days</div>
            </div>
        </section>
        <div class="col-xs-12 OM_selectline"></div>
        <div class="clearboth"></div>
        <!-- location details starts -->
        <div class="reviewOrder_locContainer">
            <input type="hidden" name="requestType" value="CR">
            <!-- location 1 starts -->
            <div class="locationDiv">
                <div class="locDiv col-xs-12 no-pad" id="prodLocation1" *ngFor="let location of reviewPageData.reviewData?.cartEntries; let i= index">
                    <div id="deliveylocation">
                        <div class="row">
                            <div class="col-xs-12">
                                <div id="deliveryparent">
                                    <form #weekselectionform="ngForm">

                                        <div class="deliveylocation">
                                            <div class="greyline"></div>
                                            <div class="row">
                                                <div class="col-xs-1 locationNumber">Location
                                                    {{(i+1)}} </div>
                                            </div>
                                            <div class="border_box">
                                                <div class="row">
                                                    <div class="prodTableHeader">Products</div><!-- HARDCORED -->

                                                </div>
                                                <div class="row">
                                                    <div class="seasonvalue">{{reviewdelivery}}</div><!-- HARDCORED -->

                                                </div>
                                                <div class="row">
                                                    <div class="col-xs-12 no-padding">

                                                        <table class="deliverytable">

                                                            <tr id="month">
                                                                <td class="delivery_date deliverycol">Delivery Date</td>
                                                                <td class="deliverycol1"></td>
                                                                <ng-container
                                                                    *ngFor="let deliverydate of deliverySlotArray;let d=index">
                                                                    <ng-container *ngIf="d!= maxDeliverySlots">
                                                                        <td class=" datetext">{{deliverydate.month}}
                                                                        </td>
                                                                    </ng-container>
                                                                    <ng-container *ngIf="d == maxDeliverySlots">
                                                                        <td class="gapbet"></td>
                                                                        <td class=" datetext">{{deliverydate.month}}
                                                                        </td>
                                                                        
                                                                    </ng-container>

                                                                </ng-container>

                                                                <td class="gapdose"></td>
                                                                <td class=" dosestext">Doses</td>
                                                                <td class="greentick"></td>

                                                            </tr>
                                                            <tr class="">
                                                                <td class="deliveyweek deliverycol">(wk. commencing)
                                                                </td>
                                                                <td class="deliverycol1"></td>
                                                                <ng-container
                                                                    *ngFor="let deliverydate of deliverySlotArray;let d=index">
                                                                    <ng-container
                                                                        *ngIf="d!=maxDeliverySlots;else gap">
                                                                        <td class=" datetext">{{deliverydate.date}}</td>
                                                                    </ng-container>
                                                                    <ng-template #gap>
                                                                        <td class="gapbet"></td>
                                                                        <td class=" datetext">{{deliverydate.date}}</td>
                                                                    </ng-template>

                                                                </ng-container>


                                                                <td class="gapdose"></td>
                                                                <td class=" dosestext">Ordered</td>
                                                                <td class="greentick"></td>

                                                            </tr>
                                                            <ng-container
                                                            *ngFor=" let prodData of location.products; let p= index">
                                                                    <ng-container
                                                                        *ngIf="prodData.baseName=='AQIV' && prodData.qty !== 0">
                                                                        <tr id="delivey_aQIV">

                                                                            <ng-container
                                                                                *ngIf="reviewPageData.productList[0].baseProductName  == 'aQIV - 10 Pack';else ativ1">
                                                                                <td class="productheader deliverycol">
                                                                                    aQIV<br /><span
                                                                                    class="tenpack">10-pack</span><br />
                                                                                    <span 
                                                                                    class="delivery_ProductDesc">(65 years
                                                                                    &amp; above)</span>
                                                                                </td>
                                                                                    <!-- <td class="middletext deliverycol "><span
                                                                                        class="tenpack">10-pack</span><br /><span
                                                                                        class="delivery_ProductDesc">(6
                                                                                        months &amp; above)</span></td>
                                                                                <td class="deliverycol1"></td> -->
                                                                            </ng-container>
                                                                            <ng-template #ativ1>
                                                                                <td class="productheader deliverycol">
                                                                                    aTIV<br /><span
                                                                                    class="tenpack">10-pack</span><br /><span
                                                                                    class="delivery_ProductDesc">(65 years & above)</span></td>
                                                                                    <!-- <td class="middletext deliverycol "><span
                                                                                        class="tenpack">10-pack</span><br /><span
                                                                                        class="delivery_ProductDesc">(6
                                                                                        months &amp; above)</span></td>
                                                                                <td class="deliverycol1"></td> -->
                                                                            </ng-template>


                                                                            <td class="deliverycol1"></td>

                                                                            <ng-container
                                                                                *ngFor="let productinput1 of prodData.deliverySlots;let j=index">
                                                                                <ng-container
                                                                                    *ngIf="j!=maxDeliverySlots;else product1gap">
                                                                                    <td class="deliverydate_input">
                                                                                        <input type="text"
                                                                                        value="{{prodData.deliverySlots[j].quantity == 0 ? null : prodData.deliverySlots[j].quantity | number}}"
                                                                                            
                                                                                            autocomplete="off" readonly
                                                                                            class="delivery_data  aqivcount form-control dynvacinedoses"
                                                                                            id="aqivfirst[{{i}}][{{j}}]"
                                                                                            name="createOrderData[{{i}}].createOrderProductData[0].createOrderDeliverySlotData[{{j}}]].quantity" /><span
                                                                                            class="glyphicon form-control-erroricon"></span>
                                                                                    </td>
                                                                                </ng-container>
                                                                                <ng-template #product1gap>
                                                                                    <td class="gapbet"></td>
                                                                                    <td class="deliverydate_input">
                                                                                        <input type="text"
                                                                                            autocomplete="off" readonly
                                                                                            value="{{prodData.deliverySlots[j].quantity == 0 ? null : prodData.deliverySlots[j].quantity | number}}"
                                                                                            
                                                                                            class="delivery_data  aqivcount form-control dynvacinedoses"
                                                                                            id="aqivfirst[{{i}}][{{j}}]"
                                                                                            name="createOrderData[{{i}}].createOrderProductData[0].createOrderDeliverySlotData[{{j}}]].quantity" /><span
                                                                                            class="glyphicon form-control-erroricon"></span>
                                                                                    </td>
                                                                                </ng-template>

                                                                            </ng-container>

                                                                            <td class="gapdose"></td>
                                                                             <td
                                                                                class="deliveryallocated_input dosesbox">
                                                                                <input type="text" autocomplete="off"
                                                                               value="{{prodData.qty | number}}"
                                                                                    class=" delivery_data aqiv_allocated form-control dynallacted"
                                                                                    id="aqivallocated[{{i}}]" readonly
                                                                                    name="deliveryweek.aqivallocated[{{i}}]"
                                                                                    readonly value="" /><span
                                                                                    class="glyphicon form-control-erroricon"></span>
                                                                            </td>
                                                                            <!--<td class="greentick">
                                                                            </td> -->
                                                                        </tr>

                                                                    </ng-container>
                                                                <ng-container *ngIf="prodData.baseName=='QIVC' && prodData.qty !== 0">
                                                                    <tr id="delivey_QIVc">
                        
                                                                        <ng-container *ngIf="reviewPageData.productList[1].baseProductName == 'QIVc - 10 Pack';else tivcone">
                                                                            <td class="productheader deliverycol">
                                                                                QIVC<br /><span
                                                                                class="tenpack">10-pack</span><br /><span
                                                                                class="delivery_ProductDesc">(6
                                                                                months &amp; above)</span></td>
                                                                        </ng-container>
                                                                        <ng-template #tivcone>
                                                                            <td class="productheader deliverycol">
                                                                                TIVc<br /><span
                                                                                class="tenpack">10-pack</span><br /><span
                                                                                class="delivery_ProductDesc">(18 to 64 years in eligible groups)</span></td>
                                                                        </ng-template>
                                                                        <td class="deliverycol1"></td>
                                                                        <ng-container
                                                                            *ngFor="let productinput2 of prodData.deliverySlots;let j=index">
                                                                            <ng-container
                                                                                *ngIf="j!=maxDeliverySlots;else product2gap">
                                                                                <td class="deliverydate_input">
                                                                                    <input type="text"
                                                                                        autocomplete="off" readonly
                                                                                        value="{{prodData.deliverySlots[j].quantity == 0 ? null : prodData.deliverySlots[j].quantity | number}}"
                                                                                       
                                                                                        class="delivery_data qivccount  form-control dynvacinedoses"
                                                                                        id="qivcfirst[{{i}}][{{j}}]"
                                                                                        name="createOrderData[{{i}}].createOrderProductData[1].createOrderDeliverySlotData[{{j}}].quantity"
                                                                                        /><span
                                                                                        class="glyphicon form-control-erroricon"></span>
                                                                                </td>
                                                                            </ng-container>
                                                                            <ng-template #product2gap>
                                                                                <td class="gapbet"></td>
                                                                                <td class="deliverydate_input">
                                                                                    <input type="text"
                                                                                        autocomplete="off" readonly
                                                                                        value="{{prodData.deliverySlots[j].quantity == 0 ? null : prodData.deliverySlots[j].quantity | number}}"
                                                                                       
                                                                                        class="delivery_data qivccount form-control dynvacinedoses"
                                                                                        id="qivcfirst[{{i}}][{{j}}]"
                                                                                        name="createOrderData[{{i}}].createOrderProductData[1].createOrderDeliverySlotData[{{j}}].quantity"
                                                                                        /><span
                                                                                        class="glyphicon form-control-erroricon"></span>
                                                                                </td>
                                                                            </ng-template>
                        
                                                                        </ng-container>
                        
                                                                        <td class="gapdose"></td>
                                                                         <td class="deliveryallocated_input dosesbox"><input
                                                                                type="text" autocomplete="off" readonly
                                                                                value="{{prodData.qty | number}}"
                                                                                class="  delivery_data qivcallocated form-control dynallacted"
                                                                                id="qivcallocated[{{i}}]" 
                                                                                name="deliveryweek.qivcallocated[{{i}}]" readonly /><span
                                                                                class="glyphicon form-control-erroricon"></span>
                                                                        </td>
                                                                        <!--<td class="deliverydate_input dosesbox "><input type="number" 
                                                                                autocomplete="off" class=" delivery_data qivctotal form-control"
                                                                                id="qivctotal[{{i}}]" value={{product.deliverySlots[j].quantity}} readonly /></td>
                                                                        <td class="greentick" >
                                                                        </td> -->
                                                                    </tr>
                                                                    <tr class="qivcyear">
                                                                        <td class="deliverycol1"></td>
                                                                        <td colspan="17" class="errorarea">
                                                                        </td>
                                                                    </tr>
                        
                                                                </ng-container>
                                                                </ng-container>




                                                        </table>




                                                    </div>


                                                </div>
                                            </div>
                                        </div>


                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- User info starts -->

                    <div class="userInfoContainer">
                        <div class="col-xs-4 no-pad col1">
                            <div class="border_box">
                                <div class="userInfoHeader">Delivery Address</div>
                                <div class="userInfoDetails" id="review_address">
                                    {{location.locname}},&nbsp;{{location.address}}&nbsp;{{location.state}}</div>
                            </div>

                            <div class="border_box">
                                <div class="userInfoHeader">Purchase Order Number</div>
                                <div class="inputParent col-xs-8 no-pad">
                                    <label>PO Number (optional)</label> <input type="text"
                                        name="{{i}}" class="form-control" id="" value="" 
                                        maxlength="10" autocomplete="no" [(ngModel)]="location.poNumber">
                                </div>
                                <div class="clearboth"></div>
                            </div>

                            <div class="border_box">
                                <div class="userInfoHeader">NHS Code</div>
                                <div class="inputParent col-xs-8 no-pad">
                                    <label>NHS Code (optional)</label> <input type="text"
                                        name="{{i}}" class="form-control dynnhsreview" [(ngModel)]="location.nhsCode" (ngModelChange)="handleOnInputValueChange($event,'nhs',i)"
                                        maxlength="6" autocomplete="no" value="">
                                        <span class="glyphicon form-control-feedback glyphicon-exclamation-sign" *ngIf="location.nhsErrorMsg"></span>
                                        <label id="{{i}}" class="error help-block" for="{{i}}" *ngIf="location.nhsErrorMsg">{{location.nhsErrorMsg}}</label>
                                </div>
                                <div class="clearboth"></div>
                            </div>
                            <input type="hidden" id="business-org_type" value="GP Medical Practice">

                        </div>

                        <div class="col-xs-8 box_2colparent no-pad" style="display:flex">
                            <div class="col-xs-6 border_box col2">
                                <div class="col-xs-12 userInfoHeader">Delivery Contact Information</div>
                                <div class="col-xs-12 userInfoDetails">Communications regarding this location's
                                    order will be sent to the following person. You can change it if you would like
                                    to designate a different contact for this order.</div>
                                <div class="col-xs-12 inputSection">
                                    <div class="inputParent col-xs-8 no-pad">
                                        <label>First Name*</label> <input type="text" [ngClass]="location.deliveryContact.firstNameErrorMsg ? 'has-error' : ''"
                                            name="{{i}}"
                                            class="dynFirstname col-xs-6 form-control" id="" [(ngModel)]="location.deliveryContact.name" (keyup)="checkForErrrors()" (ngModelChange)="handleOnInputValueChange($event,'firstName',i)"
                                            autocomplete="no">
                                            <span class="glyphicon form-control-feedback glyphicon-exclamation-sign" *ngIf="location.deliveryContact.firstNameErrorMsg"></span>
                                            <label id="{{i}}" class="error help-block" for="{{i}}" *ngIf="location.deliveryContact.firstNameErrorMsg ">{{location.deliveryContact.firstNameErrorMsg}}</label>
                                     <!-- Please enter only letters -->
                                        </div>
                                    <div class="inputParent" [ngClass]="location.deliveryContact.emailErrorMsg ? 'has-error' : ''">
                                        <label>Email Address*</label> <input type="text"
                                            name="{{i}}" [(ngModel)]="location.deliveryContact.email"(keyup)="checkForErrrors()" (ngModelChange)="handleOnInputValueChange($event,'email',i)"
                                            class="dynemail form-control" id="" autocomplete="no">
                                            <span  *ngIf="location.deliveryContact.emailErrorMsg" class="glyphicon form-control-feedback glyphicon-exclamation-sign"></span>
                                            <label id="{{i}}" class="error help-block" for="{{i}}" *ngIf="location.deliveryContact.emailErrorMsg">{{location.deliveryContact.emailErrorMsg}}</label>
                                    </div>
                                    <div class="">
                                        <div class="col-xs-8 no-pad inputParent" [ngClass]="location.deliveryContact.phoneNumberErrorMsg ? 'has-error' : ''">
                                            <label>Phone Number*</label> <input type="text"
                                                name="{{i}}"
                                                class="dynphno form-control" id="" (keyup)="checkForErrrors()" [(ngModel)]="location.deliveryContact.phone" (ngModelChange)="handleOnInputValueChange($event,'phone',i)" maxlength="11"
                                                autocomplete="no">
                                                <span class="glyphicon form-control-feedback glyphicon-exclamation-sign" *ngIf="location.deliveryContact.phoneNumberErrorMsg"></span>
                                                <label id="{{i}}" class="error help-block" for="{{i}}" *ngIf="location.deliveryContact.phoneNumberErrorMsg">
                                                    {{location.deliveryContact.phoneNumberErrorMsg}}</label>
                                                    <!-- Please enter only Numbers -->
                                        </div>
                                        <div class="col-xs-4 no-right-pad inputParent">
                                            <label>Ext.</label> <input type="text"
                                                name="{{i}}"
                                                class="dynphnoextn form-control" id="" [(ngModel)]="location.deliveryContact.ext" maxlength="4"
                                                autocomplete="no" (ngModelChange)="handleOnInputValueChange($event,'ext',i)">
                                                <span class="glyphicon form-control-feedback glyphicon-exclamation-sign" *ngIf="location.deliveryContact.phoneextErrorMsg"></span>
                                                <label id="{{i}}" class="error help-block" for="{{i}}" *ngIf="location.deliveryContact.phoneextErrorMsg">
                                                    {{location.deliveryContact.phoneextErrorMsg}}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-6 border_box">
                                <div class="col-xs-12 userInfoHeader">Licence Information</div>
                                <div class="col-xs-12 userInfoDetails">Please verify the licence information is
                                    correct and current for this location. You can edit these details if they have
                                    changed.</div>
                                <div class="col-xs-12 inputSection">
                                    <div class="inputParent col-xs-9 no-pad" [ngClass]="location.licenseInfo.nameErrorMsg ? 'has-error' : ''">
                                        <label>Licence Name*</label> <input type="text"
                                            name="{{i}}"
                                            class="col-xs-6 form-control liname" id="" (keyup)="checkForErrrors()" [(ngModel)]="location.licenseInfo.name" (ngModelChange)="handleOnInputValueChange($event,'licenseInfoName',i)"
                                            autocomplete="no">
                                            <span class="glyphicon form-control-feedback glyphicon-exclamation-sign" *ngIf="location.licenseInfo.nameErrorMsg" ></span>
                                            <label id="{{i}}" class="error help-block" for="{{i}}" *ngIf="location.licenseInfo.nameErrorMsg">
                                                {{location.licenseInfo.nameErrorMsg}}</label> 
                                                <!-- Please enter only letters -->
                                    </div>
                                    <div class="inputParent positionrel" [ngClass]="location.licenseInfo.numberErrorMsg ? 'has-error' : ''">
                                        <label>Licence Number*</label> <input type="text"
                                            name="{{i}}" class="form-control lino" id=""
                                            [(ngModel)]="location.licenseInfo.number" (keyup)="checkForErrrors()" (ngModelChange)="handleOnInputValueChange($event,'licenseInfoNumber',i)" maxlength="15" autocomplete="no">
                                            <span *ngIf="location.licenseInfo.numberErrorMsg" class="glyphicon form-control-feedback glyphicon-exclamation-sign" ></span>
                                            <label id="{{i}}" class="error help-block" for="{{i}}" *ngIf="location.licenseInfo.numberErrorMsg">
                                                {{location.licenseInfo.numberErrorMsg}}</label> 
                                    </div>
                                    <div class="licence_checkboxparent">
                                        <label class="checkbox-button inputParent" id="license_check"> <input
                                                type="checkbox" name="checkbox[0]" (change)="checkForErrrors()" [(ngModel)]="location.licenseInfo.checked"(click)=locationlevelcheck($event,i) (ngModelChange)="handleOnInputValueChange($event,'checkbox',i);validateForm(i)"
                                                class="review_checkbox licenceCheckbox" id="" > <span
                                                class="checkbox-button_control"></span> 
                                                <label class="checkboxtext">I
                                                confirm that the Licence Information is
                                                accurate.</label>
                                                
                                        </label>
                                        <label id="checkbox[1]-error" class="error help-block" for="checkbox[1]" *ngIf="location.licenseInfo.checkboxErrorMsg">{{location.licenseInfo.checkboxErrorMsg}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <input type="hidden" name="createOrderData[1].sorNumber" value="">
                <input type="hidden" name="createOrderData[1].creditDays" value="120">
                <input type="hidden" name="createOrderData[1].locID" value="60149134">
            </div>
            <div>
                <input type="hidden" name="CSRFToken" value="e21a9d3d-fa32-4dd8-bd3a-3b3731da1b6c">
            </div>
        </div>
        <div class="clearboth"></div>
        <!-- confirm order section starts -->
        <div class="bottomBG" >
            <div class="col-xs-12 OM_tableBottomBtn">
                <div class="col-xs-12 col-md-8" *ngIf="seasonValue == inseason">
                    <div class="bootom_headerText">Confirm your order to secure your&nbsp;<span
                            class="reviewseasontxt">{{seasonValue | seasonspilt}}</span>&nbsp;season order</div>
                    <div class="main_checkboxparent col-md-10">
                        <label class="checkbox-button"> <input type="checkbox" name="" [(ngModel)]="isChecked" (click)="handleOnCommonCheckboxvalueChange($event)"
                                class="review_checkbox mainCheck" id="">
                            <span class="checkbox-button_control"></span>
                        </label> <label class="mainCheckText">By confirming my order, I agree to the <a
                                href="/termsOfSale" target="_blank" class="showPopupclick" style="text-decoration: underline;">Terms of Sale</a>
                            including <ng-container *ngIf="reviewPageData.priceResponse.SOR;else zersormain">{{reviewPageData.priceResponse.SOR}}</ng-container><ng-template #zersormain>0</ng-template>% of SOR</label>
                    </div>
                </div>
                <div class="col-xs-12 col-md-8" *ngIf="seasonValue == preseason">
                    <div class="bootom_headerText">Confirm your order to secure your&nbsp;<span
                            class="reviewseasontxt">{{seasonValue | seasonspilt}}</span>&nbsp;season order</div>
                    <div class="main_checkboxparent col-md-10">
                        <label class="checkbox-button"> <input type="checkbox" name="" [(ngModel)]="isChecked" (click)="handleOnCommonCheckboxvalueChange($event)"
                            class="review_checkbox mainCheck" id="">
                            <span class="checkbox-button_control"></span>
                        </label> <label class="mainCheckText">By confirming my order, I agree to the <a
                                href="/termsOfSale" target="_blank" class="showPopupclick"><b>Terms of Sale</b></a>,
                            including order amendments being permitted until 28th February 2025&nbsp;within 20% of each
                            product volume</label>
                    </div>
                </div>
                <div class="col-xs-12 col-md-4 OM_confirmBtnParent" >
                    <button class="OM_confirmOrderBtn prvntDoubleClick" [disabled]="!isChecked || !isAllFieldsValid" (click)="handleOnOrderClick('confirmOrder')">Confirm Order</button>
                </div>
            </div>
            <div class="col-xs-12 OM_selectline" *ngIf="seasonValue == preseason"></div>
            <div class="clearboth"></div>
            <!-- save order section -->
            <div class="saveParent" *ngIf="seasonValue == preseason">
                <div class="saveOrderSection">
                    <div class="col-xs-12 col-md-10 saveText">If you're not ready to confirm, you can save this order.
                        This will remain in a pending status until confirmed. Whilst pending, your volume, terms and
                        delivery schedule are not guaranteed and may be lost.</div>
                    <button class="OM_saveOrderBtn prvntDoubleClick" [disabled]="!isAllFieldsValid" (click)="handleOnOrderClick('OrderPending')">Save Order</button>
                </div>
            </div>
        </div>

        <!-- provisional order -->
        <div class="clearboth"></div>
        <!-- <div class="provisionalbottomBG">
            <div class="col-xs-12 OM_tableBottomBtn protablebottom">
                <div class="col-xs-12 col-md-8">
                    <div class="bootom_headerText no-margin-bottom">Save your <span
                            class="reviewseasontxt">2024/25</span> provisional order</div>
                    <div class="col-md-10 no-padding margin-T10 ProvisionalReviewSubtext">
                        Once the&nbsp;2024/25&nbsp;flu season ordering opens in August 2024, a final order will be
                        emailed to you outlining full commercial terms</div>
                </div>
                <div class="col-xs-12 col-md-4 saveOrderSection provisionalsection">
                    <button class="OM_saveOrderBtn provisionalsave prvntDoubleClick" disabled="">Save Provisional
                        Order</button>
                </div>
            </div>

        </div> -->

        <!-- In Season Order Changes -->
        <!-- <div class="InseasonbottomBG" style="display: block;">
            <div class="col-xs-12 OM_tableBottomBtn protablebottom">
                <div class="col-xs-12 col-md-8">
                    <div class="bootom_headerText">Confirm your order to secure your&nbsp;<span
                            class="reviewseasontxt">2024/25</span>&nbsp;season order</div>
                    <div class="main_checkboxparent col-md-10">
                        <label class="checkbox-button"> <input type="checkbox" name="" value=""
                                class="review_checkbox mainCheck" id="">
                            <span class="checkbox-button_control"></span>
                        </label> <label class="mainCheckText">By confirming my order I agree to the <a
                                href="/termsOfSale" target="_blank" class="showPopupclick">Terms of Sale</a> including
                                {{reviewPageData.priceResponse.SOR}} SOR </label>
                    </div>
                </div>
                <div class="col-xs-12 col-md-4 inseasonsection pr-0">
                    <button class="inseasonbtn prvntDoubleClick" disabled="">Confirm Order</button>
                </div>
            </div>
        </div> -->
        <!--  Closed -->

    </section>
</div>







