<div
  class="product_image-textSide product_image-textReverse real-world-reverse" *ngIf="fourthnode | async as item"
>
  <div class="container">
    <div class="product_efficacy-header">
      {{item.headLine}}
    </div>
    <div class="product_image-textSide-container">
      <div class="product_image-textSide-Image">
        <div class="simple-banner banner__component--responsive">
          <div class="simple-banner banner__component--responsive" *ngIf="item?.media?.desktop">
            <img class="js-responsive-image"
              [src]="getImage(item.media.desktop)" [attr.alt]="item.media?.desktop.altText" [attr.title]="item.media?.desktop.altText"
            />
          </div>
        </div>
      </div>
      <div class="product_image-textSide-content">
        <div class="product_bold" [innerHTML]="item.text1Content">
        </div>
        <div>
         <div class="bold-text"[innerHTML]="item.text2Content"></div>
          <div [innerHTML]="item.text3Content">

          </div>
        </div>
      </div>
      <div class="clearboth"></div>
    </div>
  </div>
</div>
