<div *ngIf="pictureresource | async as item" class="picture_Resource_Container">
    <span class="picture_Resource_Topheadline">{{item.headLine1}}</span>
    <img [attr.alt]="item?.media?.altText" [src]="getImage(item?.image)">
    <button class="picture_Resource_Btn" data-toggle="modal" data-target="#register_Thankyou_PopUp"
        (click)="onClickRegisterd()" [innerHTML]="item.subText1"></button>
    <span class="email_shortly_text" [innerHTML]="item.textPageContent1"></span>
    <span class="picture_Resource_Topheadline picture_Resource_Free_Headline">{{item.headLine2}}</span>
    <div class="row picture_Resource_Row">
        <div class="col-md-3 picture_Resource_Column">
            <span class="Picture_Resource_Sno">1</span>
            <span [innerHTML]="item.headLine4" class="picture_Resource_Text"></span>
        </div>
        <div class="col-md-3 picture_Resource_Column">
            <span class="Picture_Resource_Sno">2</span>
            <span [innerHTML]="item.textPageContent2" class="picture_Resource_Text"></span>
        </div>
        <div class="col-md-3 picture_Resource_Column">
            <span class="Picture_Resource_Sno">3</span>
            <span [innerHTML]="item.textPageContent3" class="picture_Resource_Text"></span>
        </div>
    </div>
    <button class="picture_Resource_Btn" data-toggle="modal" data-target="#register_Thankyou_PopUp"
        (click)="onClickRegisterd()" [innerHTML]="item.subText1"></button>
    <span class="email_shortly_text" [innerHTML]="item.textPageContent1"></span>
    <div class="return_Picture_Resouce_jobBagCode">{{item.headLine3}}</div>
</div>