import { ChangeDetectorRef, Component, NgZone } from '@angular/core';
import { GigyaRaasComponentData } from '@spartacus/cdc/core';
import { CdcAuthFacade, CdcConfig, CdcJsService } from '@spartacus/cdc/root';
import { AuthService, BaseSiteService, ClientTokenService, LanguageService, OccEndpointsService, WindowRef } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable, distinctUntilChanged, tap, take } from 'rxjs';
import { CdcService } from '../../spartacus/services/cdc.service';
import { Router } from '@angular/router';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { CommonApiService } from '../../spartacus/services/common-api.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-custom-gigya-raas',
  templateUrl: './custom-gigya-raas.component.html',
  styleUrls: ['./custom-gigya-raas.component.scss']
})
export class CustomGigyaRaasComponent {
  protected renderScreenSet = true;
  language$: Observable<string> | undefined;
  jsError$: Observable<boolean> | undefined;
  jsLoaded$: Observable<boolean> | undefined;
  currentRoute: string = '';
  setCurrentScreen: string = '';
  isUserLoggedIn: boolean = false;
  editProfile: boolean = false;
  apiErr: string = '';
  multipleSoldToPopup: boolean = false;
  currentSite: string = '';
  currUrl!: string | null;
  value: any;
  ukSplashPageData: any;
  cookieValue!: string;

  public constructor(
    public component: CmsComponentData<GigyaRaasComponentData>,
    private baseSiteService: BaseSiteService,
    private languageService: LanguageService,
    private cdcConfig: CdcConfig,
    private winRef: WindowRef,
    private cdcJSService: CdcJsService,
    private zone: NgZone,
    protected cdcAuth: CdcAuthFacade,
    private cdc: CdcService,
    private router: Router,
    public userInfoService: UserInfoService,
    private cd: ChangeDetectorRef,
    private authService: AuthService,
    private occEndpointsService: OccEndpointsService,
    private commonService: CommonApiService,
    private clientTokenService: ClientTokenService,
    private cookieService: CookieService,
  ) {
    let url = this.router.url.split('?')[0].split('/');
    this.currentRoute = url[url.length - 1];
  }

  loadGigyaJs() {
    this.jsLoaded$ = this.cdcJSService.didLoad();
    this.jsError$ = this.cdc.didScriptFailToLoad();
    this.language$ = this.languageService.getActive().pipe(
      distinctUntilChanged(),
      // On language change we want to rerender CDC screen with proper translations
      tap(() => (this.renderScreenSet = true))
    );
  }

  ngOnInit(): void {
    this.currentSite = this.getCurrentBaseSite();
    this.userInfoService.userLoggedIn$.subscribe(res => {
      this.isUserLoggedIn = res;
      if (this.isUserLoggedIn) {
        this.userInfoService.fetchMultipleSoldToForUser().subscribe((data: any) => {
          if (data.multipleSoldToData.length > 1) {
            this.multipleSoldToPopup = true;
          }
          const temp = <HTMLElement>document.getElementsByClassName("GigyaLoginPageTemplate")[1]?.querySelector('.BodyContent');
          if (this.isUserLoggedIn && !this.multipleSoldToPopup) {
            if (temp) {
              temp!.style.display = 'none';
            }
          } else {
            if (temp) {
              temp!.style.display = '';
            }
          }
        });
      }
    });
    this.loadGigyaJs();
    this.showCookie();
  }

  ngAfterViewInit() {
    document.getElementById('login-container')?.addEventListener('click', this.handleLoginRedirect);

    setTimeout(() => {
      this.showCookie();
      },
      3000);
  }

  handleLoginRedirect = (event: Event): void => {
    const target = event.target as HTMLElement;
    if (target.matches('.changepasssuccess_loginbtn')) {
      this.router.navigate(['login']);
    }
    if (target.matches('#gigya-checkbox-remember')) {
      this.updateCookie();

    }
    
    if (target.matches('.resendemail_btn')) {
      setTimeout(()=>{
      const profEmail = (window as any).profileEmail;
      localStorage.setItem('email', profEmail);

 
      if (profEmail) {
        this.clientTokenService.getClientToken().subscribe(res => {
          if (res) {
            let token = res.access_token;
            this.passCalltoVerification(token);

          }
        });
      }

    },500);
    }
  }
  updateCookie(): void {
    const remember = this.cookieService.get('remember-me');
    const usernameInput = document.getElementById('username') as HTMLInputElement;
    const username = usernameInput ? usernameInput.value : '';

    if (!remember || remember !== username) {
      const inSixtyMinutes = new Date(new Date().getTime() + 60 * 60 * 1000);
      this.cookieService.set('remember-me', username, inSixtyMinutes, '/', '', true, 'None');
      this.cd.detectChanges();
    }
  }
  getCookie(name:string) {
    return this.cookieService.get(name);
  }
  showCookie() {
    this.cookieValue = this.getCookie('remember-me');
    this.checkRememberMe();
    this. fillUsernameField();
  }
  checkRememberMe() {
    const checkbox = document.getElementById('gigya-checkbox-remember') as HTMLInputElement;
    if (checkbox) {
      checkbox.checked = this.cookieValue !== '';
    }
  }
  fillUsernameField() {
    const usernameField = document.getElementById('username') as HTMLInputElement;
    if (usernameField) {
      usernameField.value = this.cookieValue;
    }
  }
  passCalltoVerification(token: string) {
    const email = localStorage.getItem('email');
    let endpoint = this.occEndpointsService.buildUrl(`Verify/VerifyProfileEmail?emailId=${localStorage.getItem('email')}`);
    let payload = {
      url: endpoint,
      token: token,
      contentType: 'text/xml',
      responseType: 'text',
    }
    this.commonService.getAPICallNoAuth(payload).subscribe({
      next: (res: any) => {
        if (res == 'success') {
          this.router.navigate(['/verificationsent']);
        } else {
          this.apiErr = res;
        }
        this.cd.detectChanges();
      },
      error: (e) => { },
    });
  }



  displayScreenSet(data: GigyaRaasComponentData, lang: string): void {
    if (this.renderScreenSet) {
      this.showScreenSet(data, lang);
    }
    this.renderScreenSet = false;
  }

  showScreenSet(data: GigyaRaasComponentData, lang: string) {
    this.customRegisterEventHandlers();
    let customLangParams={
      invalid_login_or_password: 'The Email Address or Password you entered does not match our records. Please try again or click Forgot Password',
      please_enter_a_valid_code:'Please enter a valid verification code (not your access code).'
      };
    (this.winRef.nativeWindow as { [key: string]: any })?.['gigya']?.accounts?.showScreenSet({
      screenSet: data.screenSet,
      startScreen: data.startScreen,
      lang,
      ...(this.displayInEmbedMode(data) ? { containerID: data.containerID } : {}),
      ...(this.isLoginScreenSet(data) ? { sessionExpiration: this.getSessionExpirationValue(),customLang: customLangParams } : {
        onAfterSubmit: (...params: any[]) => {
          this.zone.run(() => {
            this.cdc.onProfileUpdateEventHandler(...params);
            setTimeout(() => {
              this.handleGigyaProfileEvt(false);
              if(this.currentSite=='seqirusb2b-uk'){
                this.emailtriggerapi();
              }
            }, 300);
          });

        },
      }),
    });
  }

  protected isLoginScreenSet(data: GigyaRaasComponentData): boolean {
    const profileEditScreen: boolean = data.profileEdit === 'true' ? true : false;
    return !profileEditScreen;
  }

  protected getSessionExpirationValue(): number {
    if (this.cdcConfig?.cdc !== undefined) {
      const filteredConfigs: any = this.cdcConfig.cdc.filter(
        (conf) => conf.baseSite === this.getCurrentBaseSite()
      );
      if (filteredConfigs && filteredConfigs.length > 0) {
        return filteredConfigs[0].sessionExpiration;
      }
    }
    return 3600; // Return a default value
  }

  getCurrentBaseSite(): string {
    let baseSite: string = '';
    this.baseSiteService
      .getActive()
      .pipe(take(1))
      .subscribe((data) => (baseSite = data));
    return baseSite;
  }

  displayInEmbedMode(data: GigyaRaasComponentData): boolean {
    const embedValue: boolean = data.embed === 'true';
    return embedValue && !!data.containerID && data.containerID.length > 0;
  }

  customRegisterEventHandlers() {
    let baseSite = this.getCurrentBaseSite();
    if (baseSite) {
      this.cdc.customAddCdcEventHandlers(baseSite);
    }
  }

  handleGigyaProfileEvt(evt: boolean) {
    this.editProfile = evt;
    const temp = <HTMLElement>document.getElementsByClassName("BodyContent")[0];
    if (this.editProfile) {
      if (temp) {
        temp.classList.add('profile_view');
      }
    } else {
      if (temp) {
        temp.classList.remove('profile_view');
      }
    }
    this.cd.detectChanges();
  }

  toggleEditValue(evt: any): void {
    this.handleGigyaProfileEvt(evt);
  }
  emailtriggerapi(){
  this.userInfoService.emailapi().subscribe((res:any)=>{ 
  });
  }
}
