import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsReturnsFormComponent } from './us-returns-form/us-returns-form.component';
import { ConfigModule } from '@spartacus/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { NgSelectModule } from '@ng-select/ng-select';



@NgModule({
  declarations: [
    UsReturnsFormComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule,
    NgSelectModule,
    ConfigModule.withConfig({
      cmsComponents: {
        SeqirusOnlineReturnsComponent:{ //change to BE new comp
          component:UsReturnsFormComponent,
        }
        
      }
    }),
  ]
})
export class ReturnsUsModule { }
