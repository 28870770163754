import { ChangeDetectorRef, Component } from '@angular/core';
import { ContentService } from '../../spartacus/services/content.service';

@Component({
  selector: 'app-sitemap',
  templateUrl: './sitemap.component.html',
  styleUrl: './sitemap.component.scss'
})
export class SitemapComponent {
  sitemapdata:any;
  firsttext= '<?xml version="1.0" encoding="UTF-8"?>';
  sitemap='<sitemapindex xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">'
  secondtext='<sitemap>';
  thirdtext='<loc>';
  fouthtext='</sitemap>';
  fifthtext='</loc>';
  sixthtext='</sitemapindex>';
  testval='<?xml version="1.0" encoding="UTF-8"?> <sitemapindex xmlns="http://www.sitemaps.org/schemas/sitemap/0.9"> <sitemap> <loc>http://www.flu360.com/medias/Custom-en-GBP-14639202867666803035.xml?context=bWFzdGVyfHJvb3R8NzUzOHx0ZXh0L3htbHxhRFUzTDJnd01TODRPREE1TURjeU9EZzFOemt3TDBOMWMzUnZiUzFsYmkxSFFsQXRNVFEyTXpreU1ESTROamMyTmpZNE1ETXdNelV1ZUcxc3w0MTM4ZTI4MDk0ZWZlYzY4NTE2Mzk4ZmNjYmJlZTVjYmQ5MGFkMDgzOGIwYWQ0ZDhhOGM2NDhhYjI1M2Q0MWI1</loc> </sitemap> </sitemapindex>'
  constructor(private accessService : ContentService,private cd:ChangeDetectorRef ) {
  }
  ngOnInit(){
    this.accessService.sitemapdata().subscribe((data:any) => {
      this.sitemapdata=data
      this.cd.detectChanges();
    });
  

  }


}
