<div class="schedule-pay-section">
    <div class="col-xs-12 schpay_tablesection" [ngClass]="{'schedule_data_true': schedulePayData && schedulePayData.length > 0, 'no_schedule_data': !(schedulePayData && schedulePayData.length > 0)}">
    <div *ngIf="schedulePayData && schedulePayData.length > 0 ;else noScheduleData" class="schedule-pay-detail-section">
        <div class="col-7 schpay_header">Scheduled Payments
        <div class="schpay_subheader">Payment Methods<i class="fa-regular fa-circle-question sch_pay_tooltip"><span class="sch_pay_tooltiptext">Payment methods can't be changed after scheduling. Cancel and schedule a new payment to use a different method.<br><br><span>Only the original user who scheduled a payment can reschedule it.</span></span></i></div>
        </div>
        <div class="col-12  all-invoices" style="text-align:right">
            <div style="color: #2A3237; font-size: 16px; margin-top:-36px;font-family: Campton-semibold; font-weight: 600; line-height: 16px; word-wrap: break-word" class="all-invoices-link"><a [routerLink]="['/financial-dashboard/all-invoices']">All Invoices</a></div>
            <div class="global_blackarrow" style="top:-39px;right:-27px"></div>
        </div>
        
        <div class="schpay_table_container">
        <table mat-table [dataSource]="schedulePayment" id="schedulepay" matSort multiTemplateDataRows class="mat-elevation-z8">
            
            <ng-container matColumnDef="scheduledDate">
                <th style="width: 13%;" mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true">Scheduled Date
                    <span *ngIf="schedulePayment.sort?.active === 'scheduledDate'">
                        <img [ngClass]="{'rotated': schedulePayment.sort?.direction === 'desc'}" src="assets/images/sort_grey.png"/>
                    </span>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.scheduledDate}} </td>
            </ng-container>
            <ng-container matColumnDef="dueDate">
                <th style="width: 12%;" mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true">Due Date
                    <span *ngIf="schedulePayment.sort?.active === 'dueDate'">
                        <img [ngClass]="{'rotated': schedulePayment.sort?.direction === 'desc'}" src="assets/images/sort_grey.png"/>
                    </span>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.dueDate}} </td>
            </ng-container>
            <ng-container matColumnDef="invoiceAmount">
                <th style="width: 13%;" mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true">Credit Card Payment
                    <span *ngIf="schedulePayment.sort?.active === 'invoiceAmount'">
                        <img [ngClass]="{'rotated': schedulePayment.sort?.direction === 'desc'}" src="assets/images/sort_grey.png"/>
                    </span>
                </th>
                <td mat-cell *matCellDef="let element"> <ng-container *ngIf="!element.hasCredits">{{element.invoiceAmount | currency:'USD':'symbol'}}</ng-container><ng-container *ngIf="element.hasCredits">{{element.invoiceAmount | currency:'USD':'symbol'}}</ng-container>
                    <i *ngIf="element.hasCredits" class="fa-regular fa-circle-question sch_pay_credit_tooltip"><span class="sch_pay_credit_tooltiptext">{{element.creditsTotal | currency:'USD':'symbol'}} in credits have been applied to pay your remaining balance of {{getActualAmount(element) | currency:'USD':'symbol'}}</span></i>
                 </td>
            </ng-container>
            <ng-container matColumnDef="paymentMethod">
                <th style="width: 13%;" mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true">Payment Method
                    <span *ngIf="schedulePayment.sort?.active === 'paymentMethod'">
                        <img [ngClass]="{'rotated': schedulePayment.sort?.direction === 'desc'}" src="assets/images/sort_grey.png"/>
                    </span>
                </th>
                <td mat-cell *matCellDef="let element">
                    <img width="75" class="image" *ngIf="element.cardType == '1'" src="../../assets/images/Visa_icon.png"
                    alt="Visa">
                  <img width="75" class="image" *ngIf="element.cardType == '2'" src="../../assets/images/amex_icon.png"
                    alt="American Express">
                  <img width="75" class="image" *ngIf="element.cardType == '3'" src="../../assets/images/master_icon.png"
                    alt="MasterCard">
                  <img width="75" class="image" *ngIf="element.cardType == '128'" src="../../assets/images/Discover_icon.png"
                    alt="Discover">
                     **{{element.cardNumber.slice(-4)}}
                 </td>
            </ng-container>
            <ng-container matColumnDef="invoiceNumber">
                <th style="width: 13%;" mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true">Invoice #
                    <span *ngIf="schedulePayment.sort?.active === 'invoiceNumber'">
                        <img [ngClass]="{'rotated': schedulePayment.sort?.direction === 'desc'}" src="assets/images/sort_grey.png"/>
                    </span>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.invoiceNumber}} </td>
            </ng-container>
            <ng-container matColumnDef="reschedule">
                <th mat-header-cell *matHeaderCellDef ></th>
                
                <td mat-cell *matCellDef="let element"><ng-container *ngIf="!element.isExpanded && element.paymentStatus != 'FAILED'"><button class="sch_reschedule_button" [disabled]="!element.modificationAllowed" (click)="openRescheduleSection(element)">Reschedule</button> </ng-container></td>
               
            </ng-container>
            
            <ng-container matColumnDef="cancel">
                <th mat-header-cell *matHeaderCellDef ></th>
                <td mat-cell *matCellDef="let element" [ngClass]="{'save_btn_class':element.isExpanded}"><ng-container *ngIf="!element.isExpanded && element.paymentStatus != 'FAILED'"><button class="sch_cancel_button" data-toggle="modal" data-target="#schpay_cancelmodal" (click)="openCancelPopup(element)">Cancel</button></ng-container><ng-container *ngIf="element.isExpanded"><button [disabled]="!dateInput || !checkValidDate(dateInput,element.dueDate)" (click)="reSchedulePayment(element)" class="schpay_savebtn">Save <div class="schpay_saveicon"></div></button></ng-container></td>
            </ng-container>
            <ng-container matColumnDef="viewInvoice">
                <th mat-header-cell *matHeaderCellDef ></th>
                <td mat-cell *matCellDef="let element" [ngClass]="{'view-details':!element.isExpanded,'close_icon_class': element.isExpanded}"><ng-container *ngIf="element.isExpanded"><div class="reschedule_closeicon" (click)="closeReschedule(element)"></div></ng-container><ng-container *ngIf="!element.isExpanded"><a [routerLink]="['/financial-dashboard/all-invoices']" [queryParams]="{season:element.season,invoice:element.invoiceNumber}">View  Invoice<div class="global_blackarrow"></div></a></ng-container></td>
                
            </ng-container>
          
            <tr mat-row *matRowDef="let row; columns: displayedColumns" class="desktop_row"></tr>
            
            <tr *matRowDef="let row ;columns:[]"  class="expanded-row">
                    <ng-container *ngIf="row.isExpanded">
                        <td [attr.colspan]="8">
                            <div style="align-self: stretch; padding-left: 24px; padding-right: 24px; padding-top: 25px; padding-bottom: 25px; border-radius: 4px; border: 1px black solid; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 16px; display: flex">
                            <div style="align-self: stretch; color: #2A3237; font-size: 18px; font-family: Campton-medium; font-weight: 500; line-height: 27px; word-wrap: break-word">Reschedule Payments</div>
                            <div style="color: #2A3237; font-size: 14px; font-family: Campton-medium; font-weight: 500; line-height: 21px; word-wrap: break-word">New Payment Date 
                                <i class="fa-regular fa-circle-question resch_pay_tooltip"><span class="resch_pay_tooltiptext">You must choose a date before your invoice due date.</span></i></div>   
                            <form #dateForm="ngForm">
                        <mat-form-field class="mat-focused">
                            <input matInput maxlength="10"  (keypress)="allowNumbersOnly($event)" [matDatepicker]="picker" name="schdate" (input)="maskInputDate($event)" [(ngModel)]="dateInput" placeholder="MM/DD/YYYY" [min]="today" [max]="getFormattedDueDate(row.dueDate)" #date="ngModel" [ngClass]="date?.invalid && date?.dirty && !isFieldFocused(date) ? 'invalid-date':''">
                           <mat-datepicker-toggle matIconPrefix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            <span *ngIf="date.invalid && date?.dirty && !isFieldFocused(date)" class="glyphicon glyphicon-exclamation-sign"></span>
                        </mat-form-field>

                        <div class="promptpay_text">Pay on or before <span>{{row.dueDate}}</span> for the 2% Prompt Pay Discount</div>

                        <div *ngIf="date?.invalid && date?.dirty && !isFieldFocused(date)" class="sch-pay-error-text">
                            <p *ngIf="date?.errors">You must select a future date on or before {{row.dueDate}}</p>
                        </div>
                            </form>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container *ngIf="row.paymentStatus === 'FAILED'">
                        <td colspan="8">
                        <div class="payment-failed-section">
            
                            <div class="pay-fail-title"><span class="glyphicon glyphicon-exclamation-sign"></span>Payment Failed</div>
                            <div class="pay_fail_text"><a [routerLink]="['/financial-dashboard/all-invoices']" [queryParams]="{season:row.season,invoice:row.invoiceNumber}">View this invoice</a> to make a new payment. For issues, contact Accounts Receivable <br/> <a href="mailto:usainc.accountsreceivable@seqirus.com" target="_blank">usainc.accountsreceivable&#64;seqirus.com</a> or <a href="tel:866-434-1115">866-434-1115</a>.
                            <button class="global_greybtn new_pay_button" [routerLink]="['/financial-dashboard/all-invoices']" [queryParams]="{season:row.season,invoice:row.invoiceNumber}">Make New Payment</button></div>
                        </div>
                        </td>
                    </ng-container>
            </tr>
                    
            <tr mat-header-row *matHeaderRowDef="displayedColumns" class="desktop_header"></tr>

        </table>
         <!-- Mobile view table-->
        <table mat-table [dataSource]="schedulePayment" id="schedulepay_mobile_table" matSort multiTemplateDataRows class="mat-elevation-z8 d-lg-none">
            <ng-container matColumnDef="combined">
                <th class="schedulepaymentheader d-none" mat-header-cell *matHeaderCellDef> Scheduled payments Data </th>
                <td mat-cell *matCellDef="let element" class="combined_mobile_table">
                <div class="payment_table" [ngClass]="{'pay_failed': element.paymentStatus == 'FAILED'}">
                    <!--Scheduled Date-->
                    <div class="mobile_payment_table">
                        <div class="table_header">Scheduled Date</div>
                        <div class="table_value">{{element.scheduledDate}} </div>
                    </div>
                    <!--Due Date-->
                    <div class="mobile_payment_table">
                        <div class="table_header">Due Date</div>
                        <div class="table_value">{{element.dueDate}} </div>
                    </div>
                    <!--Credit Card Payment-->
                    <div class="mobile_payment_table">
                        <div class="table_header">Credit Card Payment</div>
                        <div class="table_value">{{element.invoiceAmount | currency:'USD':'symbol'}}
                            <i *ngIf="element.hasCredits" class="fa-regular fa-circle-question sch_pay_credit_tooltip"><span class="sch_pay_credit_tooltiptext">{{element.creditsTotal | currency:'USD':'symbol'}} in credits have been applied to pay your remaining balance of {{getActualAmount(element) | currency:'USD':'symbol'}}</span></i>
                        </div>
                    </div>
                    <!--Payment Method-->
                    <div class="mobile_payment_table">
                        <div class="table_header">Payment Method</div>
                        <div class="table_value">
                            <img width="38" class="image" *ngIf="element.cardType == '1'" src="../../assets/images/Visa_icon.png"
                            alt="Visa">
                          <img width="38" class="image" *ngIf="element.cardType == '2'" src="../../assets/images/amex_icon.png"
                            alt="American Express">
                          <img width="38" class="image" *ngIf="element.cardType == '3'" src="../../assets/images/master_icon.png"
                            alt="MasterCard">
                          <img width="38" class="image" *ngIf="element.cardType == '128'" src="../../assets/images/Discover_icon.png"
                            alt="Discover">
                          **{{element.cardNumber.slice(-4)}}
                        </div>
                    </div>
                    <!--Invoice #-->
                    <div class="mobile_payment_table">
                        <div class="table_header">Invoice #</div>
                        <div class="table_value">{{element.invoiceNumber}} </div>
                    </div>
                    <!--Schedule cancel invoice Link outer wrapper-->
                    <div class="outer_wrapper">
                    <!-- Schudule and cancel button container-->
                    <div class="schedule_cancel_wrapper" *ngIf="!element.isExpanded && element.paymentStatus != 'FAILED'">
                    <div class="mobile_payment_table reschedule_btn">
                        <div class="table_value" *ngIf="!element.isExpanded && element.paymentStatus != 'FAILED'"><button class="sch_reschedule_button" [disabled]="!element.modificationAllowed" (click)="openRescheduleSection(element)">Reschedule</button></div>
                    </div>
                    <div class="mobile_payment_table cancel_btn">
                        <div class="table_value" *ngIf="!element.isExpanded && element.paymentStatus != 'FAILED'"><button class="sch_cancel_button" data-toggle="modal" data-target="#schpay_cancelmodal" (click)="openCancelPopup(element)">Cancel</button></div>
                        <div class="table_value" ></div>
                    </div>
                    </div>
                   <!-- View Invoice Link-->
                    <div class="view_invoice_wrapper" *ngIf="element.paymentStatus != 'FAILED'">
                        <div class="table_value" [ngClass]="{'view-details-mobile':!element.isExpanded}">
                             <div *ngIf="!element.isExpanded" class="invoice_Link_isExpanded">
                                <a [routerLink]="['/financial-dashboard/all-invoices']" [queryParams]="{season:element.season,invoice:element.invoiceNumber}">View  Invoice</a>
                                <div class="global_blackarrow"></div>
                             </div>
                        </div>
                    </div>
                     </div>
                    <div class="mobile_payment_table_savebtn">
                        <div class="table_value save_btn" *ngIf="element.isExpanded"><button [disabled]="!dateInput || !checkValidDate(dateInput,element.dueDate)" (click)="reSchedulePayment(element)" class="schpay_savebtn">Save <div class="schpay_saveicon"></div></button></div>
                        <div *ngIf="element.isExpanded">
                            <div class="reschedule_closeicon" (click)="closeReschedule(element)"></div>
                         </div>
                    </div>
                </div>
                </td>
            </ng-container>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsMobile"  class="table-row"></tr>
            <!--Expanded row -->
            <tr *matRowDef="let row ;columns:[]"  class="expanded-row">
                <ng-container *ngIf="row.isExpanded">
                    <td [attr.colspan]="8" class="reschedule_btn_outercontainer">
                        <div style="align-self: stretch; padding-left: 24px; padding-right: 24px; padding-top: 25px; padding-bottom: 25px; border-radius: 4px; border: 1px black solid; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 16px; display: flex">
                        <div style="align-self: stretch; color: #2A3237; font-size: 18px; font-family: Campton-medium; font-weight: 500; line-height: 27px; word-wrap: break-word">Reschedule Payments</div>
                        <div style="color: #2A3237; font-size: 14px; font-family: Campton-medium; font-weight: 500; line-height: 21px; word-wrap: break-word">New Payment Date 
                            <i class="fa-regular fa-circle-question resch_pay_tooltip"><span class="resch_pay_tooltiptext">You must choose a date before your invoice due date.</span></i></div>   
                        <form #dateForm="ngForm">
                    <mat-form-field class="mat-focused">
                       <input matInput maxlength="10"  (keypress)="allowNumbersOnly($event)" [matDatepicker]="picker" name="schdate" (input)="maskInputDate($event)" [(ngModel)]="dateInput" placeholder="MM/DD/YYYY" [min]="today" [max]="getFormattedDueDate(row.dueDate)" #date="ngModel" [ngClass]="date?.invalid && date?.dirty && !isFieldFocused(date) ? 'invalid-date':''">
                        <mat-datepicker-toggle matIconPrefix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <span *ngIf="date.invalid  && date?.dirty && !isFieldFocused(date)" class="glyphicon glyphicon-exclamation-sign"></span>
                    </mat-form-field>

                    <div class="promptpay_text">Pay on or before <span>{{row.dueDate}}</span> for the 2% Prompt Pay Discount</div>

                    <div *ngIf="date?.invalid && date?.dirty && !isFieldFocused(date)" class="sch-pay-error-text">
                        <p *ngIf="date?.errors">You must select a future date on or before {{row.dueDate}}</p>
                    </div>
                        </form>
                        </div>
                    </td>
                </ng-container>
                <ng-container *ngIf="row.paymentStatus === 'FAILED'">
                    <td colspan="8" class="payment_failed_outersection">
                    <div class="payment-failed-section">
        
                        <div class="pay-fail-title"><span class="glyphicon glyphicon-exclamation-sign"></span>Payment Failed</div>
                        <div class="pay_fail_text"><a [routerLink]="['/financial-dashboard/all-invoices']" [queryParams]="{season:row.season,invoice:row.invoiceNumber}">View this invoice</a> to make a new payment. For issues, contact Accounts Receivable <br/> <a href="mailto:usainc.accountsreceivable@seqirus.com" target="_blank" class="pay_fail_maillink">usainc.accountsreceivable&#64;seqirus.com</a> or <a href="tel:866-434-1115" class="pay_fail_mobno.">866-434-1115.</a></div>
                        <button class="global_greybtn new_pay_button" [routerLink]="['/financial-dashboard/all-invoices']" [queryParams]="{season:row.season,invoice:row.invoiceNumber}">Make New Payment</button>
                    </div>
                    </td>
                </ng-container>
           </tr>
           <!--Header row-->
           <tr mat-header-row *matHeaderRowDef="displayedColumnsMobile" class="header-row"></tr> 
        </table>
        </div>
        
    </div>
    

    <ng-template #noScheduleData>
        <div class="noschpay_section">
        <div class="noschpay_subsection">
            <div class="noschpay_text">You have 0 scheduled payments.</div>
            <div class="noschpay_header">
                <div class="noschpay_subtext">To schedule a payment, you must select a future date when paying an invoice.</div>
            </div>
        </div>
        </div>
    </ng-template>
    </div>
    

</div>

<div id="schpay_cancelmodal" class="modal fade" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <h4 class="modal-title schpay_cancelheader col-xs-11">Cancel Scheduled Payment</h4>
                <div class="no-padding pull-right"><span class="schpaycancel_close" data-dismiss="modal">×</span></div>
        
                <div class="col-12" class="schpay_detailsection">
                    <span class="schpay_detail_text">Invoice: <span class="schpay_detail_value">#{{cancelPopupData?.invoiceNumber}}<br/></span></span>
                    <span class="schpay_detail_text">Credit Card Payment: <span class="schpay_detail_value">{{cancelPopupData?.invoiceAmount | currency:'USD':'symbol'}}<br/></span></span>
                    <span class="schpay_detail_text">Payment Method: <span class="schpay_detail_value" >{{cardMap[cancelPopupData?.cardType]}} ending in {{cancelPopupData?.cardNumber.slice(-4)}}<br/></span></span>
                    <span class="schpay_detail_text">Date: <span class="schpay_detail_value">{{cancelPopupData?.scheduledDate}}</span></span>
                </div>
                <div class="schpay_buttonsection">
                    <button class="schpay_cancelYes global_redbtn" (click)="cancelSchedulePayment(cancelPopupData)">Yes, cancel</button>
                    <button class="schpay_cancelNo global_greybtn" data-dismiss="modal">No, go back</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div id="schpay_closeModal" class="modal fade" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <div class="modal-title" style="color: #2A3237; font-size: 24px; font-family: Campton-medium; font-weight: 500; word-wrap: break-word">Discard Changes</div>
                <div class="col-xs-2 col-md-6 schpay_closebtnParent float_left"><span class="schpay_closebtn" data-dismiss="modal">×</span></div>
                <div style="width: 100%">
                    Your date has not been saved. Are you sure you want to stop editing?
                </div>
                <div style="justify-content: flex-start; align-items: center; gap: 16px; display: inline-flex">
                    <div style="width: 125px; padding-left: 20px; padding-right: 20px; padding-top: 8px; padding-bottom: 8px; border-radius: 2px; overflow: hidden; border: 1px #EA181B solid; justify-content: center; align-items: center; gap: 10px; display: flex">
                        <div style="text-align: center; color: #EA181B; font-size: 14px; font-family: Campton-semibold; font-weight: 600; line-height: 16px;cursor:pointer; word-wrap: break-word" data-dismiss="modal" (click)="closeReschedule()">Yes, discard</div>
                    </div>
                    <div style="width: 125px; padding-left: 20px; padding-right: 20px; padding-top: 8px; padding-bottom: 8px; border-radius: 2px; overflow: hidden; border: 1px #5D6F7B solid; justify-content: center; align-items: center; gap: 10px; display: flex">
                        <div style="text-align: center; color: #5D6F7B; font-size: 14px; font-family: Campton-semibold; font-weight: 600; line-height: 16px;cursor:pointer; word-wrap: break-word"  data-dismiss="modal">No, keep editing</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

