import {
  Component,
  OnInit,
  ChangeDetectorRef,
  AfterViewInit,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { CmsService, OccConfig } from '@spartacus/core';
import { ContentService } from '../../spartacus/services/content.service';
import { SelectionModel } from '@angular/cdk/collections';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MaterialsUKService } from '../materialsuk-service';
import { combineLatest, map } from 'rxjs';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { codeValidation } from './code.validator';
import { RegistrationUkService } from '../../registration_uk/registration-uk.service';
declare var clickToAddress:any;
@Component({
  selector: 'app-materialslanding',
  templateUrl: './materialslanding.component.html',
  styleUrl: './materialslanding.component.scss',
})
export class MaterialslandingComponent implements OnInit, AfterViewInit {
  dataSourcemain: any = [];
  showinput: boolean = false;
  showbtnenabled: boolean = true;
  showmateriallanding: boolean = true;
  showreview: boolean = false;
  showthankyou: boolean = false;
  displayedColumnsmain: string[] = ['combined'];
  displayedColumns: string[] = [
    'checkbox',
    'shippingOrganizationName',
    'shippingAddressLine1',
    'col4',
  ];
  dataSource: any;
  submittedDataSource: any;
  selection = new SelectionModel<any>(true, []);
  data: any;
  submittedData: any;
  displayAddressForm = false;
  displayMaterials = true;
  submitted = false;
  materialsGeneralList: any;
  materialsHCPList: any;
  materialsWelshList: any;
  materialReviewList: any[] = [];
  isSubmitted = false;
  enableSubmit = false;
  invalidValue = false;
  showback = false;
  isUserLoggedIn: boolean = false;
  userInfo: any;
  selectedAccount: string = '';
  requestNumber: any;
  cc: any;
  addressForm = new FormGroup({
    organizationName: new FormControl(''),
    address: new FormControl(''),
    line1: new FormControl('', [Validators.required]),
    city: new FormControl('', [
      Validators.required,
      Validators.pattern('^[a-zA-Z]+$'),
    ]),
    code: new FormControl('', [Validators.required, codeValidation()]),
    country: new FormControl('', [
      Validators.required,
      Validators.pattern('^[a-zA-Z ]+$'),
    ]),
  });
  mergeobj: any = [];
  constructor(
    protected config: OccConfig,
    private cmsService: CmsService,
    private cd: ChangeDetectorRef,
    private accessService: ContentService,
    private materialsUKService: MaterialsUKService,
    public userInfoService: UserInfoService,
    public registrationUkService: RegistrationUkService
  ) {}

  ngOnInit(): void {
    this.materialsUKService.checkUserLoggedIn();
    this.cmsService
      .getComponentData('Materials-General-List')
      .subscribe((data) => {
        this.generalList(data);
      });

    this.cmsService.getComponentData('Materials-HCP-List').subscribe((data) => {
      this.hcpList(data);
    });

    this.cmsService
      .getComponentData('Materials-Welsh-List')
      .subscribe((data) => {
        this.welshList(data);

        this.materialsUKService
          .getShippingLocationData()
          .subscribe((data: any) => {
            this.data = data?.materialsShipping;
            this.dataSource = new MatTableDataSource(data?.materialsShipping);
          });

        this.userInfoService.userLoggedIn$.subscribe((res) => {
          this.isUserLoggedIn = res;
          if (this.isUserLoggedIn) {
            this.userInfoService.getUserData().subscribe((res) => {
              if (res) {
                this.userInfo = res;

                localStorage.setItem('webEnabled', this.userInfo.webEnabled);
                this.selectedAccount = this.userInfo.orgUnit.uid;
              }
            });
          }
        });
      });

    /*   this.cmsService.getComponentData('resourcescomponent1-resourceLandingPage').subscribe(data => {
    const dataobj=data
    console.log(dataobj)
  this.mergeobj.push(dataobj);
  this.dataSource=this.mergeobj
   this.cd.detectChanges();

    
 }) */
    /*  this.accessService.allproductsslides("resourcescomponent1-resourceLandingPage,resourcescomponent2-resourceLandingPage,resourcescomponent3-resourceLandingPage").subscribe((res: any) => {
   
    this.mergeobj = res.component;
    console.log(this.mergeobj)
  
 }); */
  }

  generalList(res: any) {
    const generalListData = res.materialListA.split(' ');
    const observables = generalListData.map((el: any) =>
      this.accessService.opeffeaturedResources1(el)
    );

    combineLatest(observables)
      .pipe(map((results: any) => results.flat()))
      .subscribe((mergedResults: any) => {
        this.materialsGeneralList = mergedResults;
        this.materialsGeneralList.forEach((item: any) => {
          item.displayAdd = false;
          item.quantity = '';
        });
        this.cd.detectChanges();
      });
  }

  hcpList(res: any) {
    const hcpListData = res.materialListB.split(' ');
    const observables = hcpListData.map((el: any) =>
      this.accessService.opeffeaturedResources1(el)
    );

    combineLatest(observables)
      .pipe(map((results: any) => results.flat()))
      .subscribe((mergedResults: any) => {
        this.materialsHCPList = mergedResults;
        this.materialsHCPList.forEach((item: any) => {
          item.displayAdd = false;
          item.quantity = '';
        });
        this.cd.detectChanges();
      });
  }

  welshList(res: any) {
    const welshListData = res.materialListC.split(' ');
    const observables = welshListData.map((el: any) =>
      this.accessService.opeffeaturedResources1(el)
    );

    combineLatest(observables)
      .pipe(map((results: any) => results.flat()))
      .subscribe((mergedResults: any) => {
        this.materialsWelshList = mergedResults;
        this.materialsWelshList.forEach((item: any) => {
          item.displayAdd = false;
          item.quantity = '';
        });
        this.cd.detectChanges();
      });
  }

  onQuantityChange(element: any, input: any) {
    input.value = input.value.includes('.')
      ? input.value.replace('.', '')
      : input.value;
    this.showbtnenabled = false;
    const i = this.materialReviewList.findIndex(
      (e: any) => e.title === element.h2content
    );
    if (i > -1) {
      this.materialReviewList.splice(i, 1);
    }
    if (input.value) {
      element.quantity = input.value;
      this.materialReviewList.push({
        image: element.media,
        title: element.h2content,
        quantity: element.quantity,
        materialID: element.materialCode,
      });
    }

    this.materialReviewList.forEach((ele) => {
      if (parseFloat(ele.quantity) > 10) {
        this.showbtnenabled = true;
      }
    });
    this.materialsGeneralList.forEach((ele: any) => {
      if (ele.h2content == element.h2content) {
        ele.quantity = input.value;
        ele.quantityInvalid = ele.quantity > 10 ? true : false;
      }
    });
    this.materialsHCPList.forEach((ele: any) => {
      if (ele.h2content == element.h2content) {
        ele.quantity = input.value;
        ele.quantityInvalid = ele.quantity > 10 ? true : false;
      }
    });
    this.materialsWelshList.forEach((ele: any) => {
      if (ele.h2content == element.h2content) {
        ele.quantity = input.value;
        ele.quantityInvalid = ele.quantity > 10 ? true : false;
      }
    });
  }

  getFormControl(control: string) {
    return this.addressForm!.get(control);
  }

  getImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image?.url;
  }

  getpdf(pdf: any): string {
    return this.config.backend?.occ?.baseUrl + pdf;
  }

  toggleinput(element?: any) {
    //this.showinput=true;
    element.displayAdd = true;
  }

  onfocusout(element: any) {
    element.displayAdd = element.quantity == '' ? false : true;
  }
  movetoreview() {
    //document.body.scrollTop = 500;
    document.documentElement.scrollTop = 500;
    this.showmateriallanding = false;
    this.showreview = true;
    this.showback = true;
    this.showthankyou = false;
    //this.enableSubmit = !this.invalidValue && this.materialReviewList.length > 0 ? true : false;
    this.cd.detectChanges();
  }
  backtolanding() {
    this.showmateriallanding = true;
    this.showreview = false;
    this.showback = false;
    this.showthankyou = false;
    this.showbtnenabled = this.materialReviewList.length > 0 ? false : true;
  }
  displayAddress() {
    this.displayAddressForm = !this.displayAddressForm;
    setTimeout(()=> {
      this.setFetchify();
    },100)
  }

  ngAfterViewInit(): void {
    if(!this.cc){
      //this.cc = this.registrationUkService.fetchifyCallUK();
      this.cc = new clickToAddress({
        accessToken: '7669a-89946-0db5f-06dae',
        countryMatchWith: 'text',
        enabledCountries: ['United Kingdom'],
        domMode: 'id',
        gfxMode: 1,
        style: {
          ambient: 'light', // Use white as main interface color
          accent: 'default', // Use default secondary color for interface
        },
        texts: {
          default_placeholder: 'Start with post/zip code or street',
        },
        showLogo: true,
        disableAutoSearch: false,
      });
    }
  }

  setFetchify(){
    this.cc.attach(
      {
        search: 'address',
        town: 'city',
        postcode: 'code',
        line_1: 'line1',
        country: 'country'
      },
      {
        onResultSelected: (c2a: any, elements: any, address: any) => {
          this.addressForm.controls['line1']?.setValue(address.line_1);
          this.addressForm.get('line1')?.markAsTouched();
          this.addressForm.controls['city']?.setValue(address.locality);
          this.addressForm.get('city')?.markAsTouched();
          this.addressForm.controls['code']?.setValue(address.postal_code);
          this.addressForm.get('code')?.markAsTouched();
          this.addressForm.controls['country']?.setValue(address.country_name);
          this.addressForm.get('country')?.markAsTouched();
          this.addressForm.updateValueAndValidity();
          this.cd.detectChanges();
        },
      }
    );
  }

  removeMaterials() {
    this.materialReviewList = [];
    this.materialsGeneralList.forEach((ele: any) => {
      ele.displayAdd = false;
      ele.quantity = '';
    });
    this.materialsHCPList.forEach((ele: any) => {
      ele.displayAdd = false;
      ele.quantity = '';
    });
    this.materialsWelshList.forEach((ele: any) => {
      ele.displayAdd = false;
      ele.quantity = '';
    });

    this.enableSubmit =
      !this.invalidValue && this.materialReviewList.length > 0 ? true : false;
  }

  resetValues(material: any) {
    this.materialsGeneralList.forEach((ele: any) => {
      if (ele.h2content == material.title) {
        ele.displayAdd = false;
        ele.quantity = '';
      }
    });
    this.materialsHCPList.forEach((ele: any) => {
      if (ele.h2content == material.title) {
        ele.displayAdd = false;
        ele.quantity = '';
      }
    });
    this.materialsWelshList.forEach((ele: any) => {
      if (ele.h2content == material.title) {
        ele.displayAdd = false;
        ele.quantity = '';
      }
    });
  }

  deleteMaterial(material: any) {
    this.materialReviewList = this.materialReviewList.filter(
      (ele: any) => ele.title != material.title
    );
    this.resetValues(material);

    this.enableSubmit =
      !this.invalidValue && this.materialReviewList.length > 0 ? true : false;
  }
  rowSelected(row: any) {
    this.enableSubmit =
      !this.invalidValue && this.materialReviewList.length > 0 ? true : false;
    const data = this.data.map((data: any) => {
      data.checked =
        row.shippingOrganizationName != data.shippingOrganizationName
          ? false
          : true;
      data.selected =
        row.shippingOrganizationName == data.shippingOrganizationName
          ? false
          : true;
      return data;
    });

    this.dataSource.data = [...data];
  }

  submitMaterials() {
    this.showreview = false;
    this.showback = true;
    this.showthankyou = true;
    const selectedAddress = this.dataSource.data.filter(
      (ele: any) => ele.checked
    );
    const productsList = this.materialReviewList.map((ele) => {
      return {
        materialID: ele.materialID,
        qty: ele.quantity,
      };
    });
    const requestData = {
      productDetails: productsList,
      soldToID: this.selectedAccount,
      customerAddress: {
        company: selectedAddress[0].shippingOrganizationName,
        line1: selectedAddress[0].shippingAddressLine1,
        line2: selectedAddress[0].shippingAddressLine2,
        town: selectedAddress[0].town,
        postalCode: selectedAddress[0].postalCode,
        country: '',
      },
    };

    this.requestNumber =
      this.materialsUKService.submitMaterialData(requestData);
    this.dataSource.data = [...selectedAddress];
  }

  onSubmit() {
    this.isSubmitted = true;
    if (this.addressForm.invalid) {
      return;
    }
    this.displayAddress();
    const newAddress = {
      checked: true,
      shippingOrganizationName:
        this.addressForm.controls.organizationName.value,
      shippingAddressLine1: this.addressForm.controls.line1.value,
      town: this.addressForm.controls.city.value,
      postalCode: this.addressForm.controls.code.value,
      selected: true,
    };

    this.data.push(newAddress);
    const data = this.data.map((data: any) => {
      data.checked =
        newAddress.shippingOrganizationName != data.shippingOrganizationName
          ? false
          : true;
      data.selected =
        newAddress.shippingOrganizationName == data.shippingOrganizationName
          ? false
          : true;
      return data;
    });

    this.dataSource.data = [...data];
    this.enableSubmit =
      !this.invalidValue && this.materialReviewList.length > 0 ? true : false;
  }

  reviewQuantityChange(material: any, input: any) {
    input.value = input.value.includes('.')
      ? input.value.replace('.', '')
      : input.value;
    this.materialReviewList = this.materialReviewList
      .map((ele) => {
        if (ele.title == material.title) {
          ele.quantity = input.value;
        }
        ele.quantityInvalid = ele.quantity > 10 ? true : false;
        return ele;
      })
      .filter(
        (ele) => !isNaN(parseInt(ele.quantity)) && parseInt(ele.quantity) > 0
      );

    this.invalidValue = input.value > 10 ? true : false;
    this.resetValues(material);
  }

  getRecord(data: any) {
    console.log(data);
  }
}
