<div *ngIf="!userLoggedIn || (userLoggedIn && (!userInfo?.webEnabled))">
    <a #safetyInfoAnchor id="safetyInfoAnchor"></a>
    <section id="safetyInfo" class="safety-info sbs" *ngIf="safteyinfodata | async as item"
        [ngClass]="{'is-sticky': isSticky}">
        <div class="container stickycont">
            <div class="safety-info__header">
                <div *ngIf="isSticky">
                    <button class="safety-info__btn" (click)="scrollToAnchor()">{{item.headline}}<img
                            role="presentation" alt="Add" class="plussign"
                            src="../../../assets/images/plus.svg"></button>
                </div>
            </div>
            <div class="sticky_container">
                <p [innerHTML]="item.content"></p>
            </div>
            <div class="bottom_footer">
                <!-- <p class="references"><strong>References:</strong></p> -->
                <p [innerHTML]="item.bottomReferenceContent"></p>
            </div>
        </div>
        <div class="bottom_footer_grey d-none">
            <div class="container">
                <p class="gery-references" [innerHTML]="item.bottomReferenceContent"></p>
            </div>
        </div>
    </section>
</div>
<div class="LI_allProduct" *ngIf="userLoggedIn && userInfo?.webEnabled">
    <a #safetyInfoAnchor id="safetyInfoAnchor"></a>
    <section id="safetyInfo" class="safety-info sbs" *ngIf="safteyinfodata | async as item"
        [ngClass]="{'is-sticky': isSticky}">
        <div class="container">
            <div class="safety-info__header">
                <div *ngIf="isSticky">
                    <button class="safety-info__btn" (click)="scrollToAnchor()">{{item.headline}}<img
                            role="presentation" alt="Add" class="plussign"
                            src="../../../assets/images/plus.svg"></button>
                </div>
            </div>
            <div class="sticky_container">
                <p [innerHTML]="item.content"></p>
            </div>
            <div class="bottom_footer">
                <!-- <p><strong class="strong-tag">References:</strong></p> -->
                <p [innerHTML]="item.bottomReferenceContent"></p>
            </div>
        </div>
     
        <div class="bottom_footer_grey d-none">
            <div class="container">
                <p class="gery-references" [innerHTML]="item.bottomReferenceContent"></p>
            </div>
        </div>
    </section>
</div>