import { ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { BaseSiteService, CmsBannerComponent, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-mobile-uk-redirection',
  templateUrl: './mobile-uk-redirection.component.html',
  styleUrl: './mobile-uk-redirection.component.scss'
})
export class MobileUkRedirectionComponent {
  mobileUKData: any;
  customerNotificationForm:FormGroup;
  isSuccess: boolean=false;


  constructor(
    public userInfoService:UserInfoService,public component: CmsComponentData<any>,
    private cdr:ChangeDetectorRef,
    protected config: OccConfig,private baseSiteService:BaseSiteService,private router: Router,private fb:FormBuilder
  ) {
      const emailPattern = new RegExp("^(?=(.{1,64}@.{1,255}))([!#$%&'*+\\-\\/=?\\^_`{|}~a-zA-Z0-9]{1,64}(\\.[!#$%&'*+\\-\\/=?\\^_`{|}~a-zA-Z0-9]{0,}){0,})@((\(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}\)|([a-zA-Z0-9-]{1,63}(\\.[a-zA-Z0-9-]{2,63}){1,}))$");
      this.customerNotificationForm = this.fb.group({
        email:['',[Validators.required,Validators.pattern(emailPattern)]]
      });
  }
  ngOnInit(){
    this.component.data$.subscribe(data=>{
      this.mobileUKData = data;
     
    })
  }

  getImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.url;
  }

  submitNotificationForm(){
    if(this.customerNotificationForm.valid){
      this.userInfoService.sendCustomerNotification(this.customerNotificationForm.get('email')?.value).subscribe(data=>{
        this.isSuccess = true;
        this.cdr.detectChanges();
      })
    }

  }
}
