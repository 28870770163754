<div class="col-sm-12 col-xs-12 verifylink_parent_header">
    <div class="verifylink_right_header"><ol class="breadcrumb">
<li>
<a [routerLink]="'/'">Home</a>
</li>

<li class="bc_bold"><strong>Create Account</strong></li>
</ol>
</div>

        <div class="verifylink_helpheader" *ngIf="firstnode | async as item" >
            <div class="havtrouble_text" [innerHTML]="item.content"></div>
            <a [routerLink]="item.link"><span [innerHTML]="item.h1content" class="createprofile_helpdarktext"></span><div class="global_blackarrow outer_blackarrow"></div></a>
        </div>
</div>