import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-dynamicpage',
  templateUrl: './dynamicpage.component.html',
  styleUrl: './dynamicpage.component.scss'
})
export class DynamicpageComponent {
  constructor(public PageParagraphComponent : CmsComponentData<any>,private sanitizer:DomSanitizer){}
  middlecontent: Observable<any> = this.PageParagraphComponent.data$; 
  middledata:any;
 
  ngOnInit(): void {
   this.middlecontent.subscribe(res => {
     this.middledata=res;
     
   });
   
  }
  tranform(obj:any){
    return this.sanitizer.bypassSecurityTrustHtml(obj);
  }
}
