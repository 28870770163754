import { AfterViewInit, ChangeDetectorRef, Component, Renderer2 } from '@angular/core';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { ContentService } from '../../spartacus/services/content.service';
import { CmsComponentData, ParagraphComponent } from '@spartacus/storefront';
import { BaseSiteService, CmsBannerComponent, CmsParagraphComponent, OccConfig } from '@spartacus/core';
import { combineLatest, map, Observable } from 'rxjs';

@Component({
  selector: 'app-general-support',
  templateUrl: './general-support.component.html',
  styleUrl: './general-support.component.scss'
})
export class GeneralSupportComponent {
  contentContainer: any;
  currentSite: string | undefined;
  userInfo: any;
  constructor(private accessService: ContentService, public bannerComponent: CmsComponentData<any>, protected config: OccConfig, private cd: ChangeDetectorRef,private userInfoService: UserInfoService,private renderer: Renderer2,private baseSiteService:BaseSiteService) { }
  ukcontactnode: Observable<any> = this.bannerComponent.data$;
  mergedObject1: any = [];
  userLoggedIn: boolean = false;
  isModalOpen = false;
  myresponse:any;
  getImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.url;
  }

  ngOnInit() {
    this.ukcontactnode.subscribe(res => {
      this.myresponse=res;
      this.clinicaltabs(res);
    });
    this.cd.detectChanges();
    this.userInfoService.userLoggedIn$.subscribe(res=>{
      this.userLoggedIn = res;
    })
      //for looged, loggedout
      this.userInfoService.userLoggedIn$.subscribe(res=>{
        // console.log('login check',res);
        this.userLoggedIn = res;
        if(this.userLoggedIn){
          this.userInfoService.getUserData().subscribe(res=>{
            if(res){
            this.userInfo=res;
            }
          });
        }
        if(this.userInfo?.webEnabled == false){
          this.renderer.setStyle(document.querySelector('.SideContent'),'display','none');
         }
        this.cd.detectChanges();
      })
      //to get the current basesite 
      this.baseSiteService.getActive().subscribe((siteId) => {
        this.currentSite = siteId;
        // console.log(this.currentSite);
      });
  }

  clinicaltabs(res: any) {
    const tilesList = res.contactUsSlides.split(" ");
    const observables = tilesList.map((el: any) => this.accessService.helpandaq(el));

    combineLatest(observables).pipe(
      map((results:any) => results.flat())
    ).subscribe((mergedResults: any) => {
      this.mergedObject1 = mergedResults;
      this.cd.detectChanges();
      // console.log(this.mergedObject1);
    });
  }
  openModal() {
    document.body.style.overflowY = 'auto';
    document.getElementById('commonleaving_abandonedPop')!.style.display = 'block';
    const continueLink = document.querySelector('.commonleave_popContBtn a');
  if (continueLink) {
    continueLink.addEventListener('click', () => {
      this.closeModal();
    });
  }
  }
  closeModal() {
    document.body.style.overflowY = 'auto';
    document.getElementById('commonleaving_abandonedPop')!.style.display = 'none';
  }
}
