<div class="row orders_rowsection_uk leftviewmob leftbackgradient">
    <div class="row col-xs-12 col-md-12 season_title_uk leftseasontitle">
      {{ordersummaryleft?.leftSectionSeason | seasonspilt}} Season
    </div>
    <!-- new chnages start here -->	
    <div class=" row orders_rowsectionleft_uk">
       <div class=" orders_rowsection">
         <ng-container *ngIf="orderrba?.RBAPermissions==true;else ordernormal">

            <ng-container *ngIf="orderrba?.showDashboardOrderUK==true && ordersummaryleft?.inSeasonEnabled">
          <div class="col-xs-12 orders_newordertitle no-padding">New Order</div>
        
            
                                <div class="col-xs-12 orders_neworderbox2 ordertooms" data-toggle="modal" data-target="#ordersummary_OM_seasonConfirmPopup" (click)="seasonclick(ordersummaryleft?.leftSectionSeason)">
                                         
                                         <div class="orders_boxtext1">Place an order for the</div>
                                         <div class="orders_boxtext2">
                                          {{ordersummaryleft?.leftSectionSeason | seasonspilt}} Season <i class="orders_global_blackarrow pull-right"></i>
                                         </div>
                                      </div>
                                    </ng-container>
          </ng-container>
          <ng-template #ordernormal>
            <ng-container *ngIf="ordersummaryleft?.inSeasonEnabled">
            <div class="col-xs-12 orders_newordertitle no-padding">New Order</div>   
            <div class="col-xs-12 orders_neworderbox2 ordertooms" data-toggle="modal" data-target="#ordersummary_OM_seasonConfirmPopup"  (click)="seasonclick(ordersummaryleft?.leftSectionSeason)">
                                   
               <div class="orders_boxtext1">Place an order for the</div>
               <div class="orders_boxtext2">
                {{ordersummaryleft?.leftSectionSeason | seasonspilt}} Season <i class="orders_global_blackarrow pull-right"></i>
               </div>
            </div>
         </ng-container>
          </ng-template>
                                  </div>
    </div>

    <ng-container *ngIf="ordersummaryleft?.season=='Seqirus_InSeason';else notinseason">
    <div class="form-group col-xs-11 no-padding chartfiltercont">
        <div class="col-md-9 col-lg-9 col-xs-8 no-padding for_tab_view">
           <div class="input-group seach_order">
              <div class="input-group-addon chartsearchicon">
                 <i class="fa fa-search"></i>
              </div>
              <input class="form-control" id="chart_searchinput" name="lookup" type="text" (keyup)="inputsearch($event)">
           </div>
        </div>
        <div class="col-md-1 col-xs-2 chartfilterbox" (click)=" filterclickopen()">
           <span class="select_counter">{{filtercount}}</span> <span class="chartfilter_txt">Filter</span>
        </div>
        <div class="chartfilteropen" [ngClass]="filteropen?'open':''">
           <div class="statussection ">
              <div class="chartfilterheader">
                 By Location
              </div>
              <div class="col-md-12 no-padding dynamicLocations_Chart">
               <label *ngFor="let item of filtertoshow"> 
                  <input type="checkbox"  #checkboxes class="registration_checkbox" name="locationname" value={{item}} (click)="filtercheck($event,item.deliveryLoc)">
                   <span class="Chart_checkbox-button__control"></span>
                   <span class="filterName">{{item.deliveryLoc}}</span>
               </label>
              </div>
              <div class="invoice_reset" id="Chartorder_resetfilter" (click)="reset()">
                 RESET
              </div>
           </div>
        </div>
        <!--  search lookup start here -->
        <div class="chartsearchopen" [ngClass]="searchtoshow?'open':''">
         <div class="searchedtext" *ngFor="let seachedData of searched" (click)="searchedClicked(seachedData.deliveryLoc)"><span class="lookorg">{{seachedData.orgName}}</span>&nbsp;&nbsp; <span class="lookloc">{{seachedData.deliveryLoc}}</span> </div>
        </div>
     </div>
   <!-- chart start here -->
    <div class="row deliverygraph">
   <div class="col-xs-12 orders_graphtitle col-md-8 no-padding-right">Delivery Progress</div>
   <div class="col-xs-12 col-md-4 order_graph_total">
    Total Ordered<br>
    <span>{{totalShipped | number:'1.0'}}</span> doses</div>
    <div id="ordergraphcontainer" [chart]="columnChart"></div>
   </div>
        
     
        <div class="row col-lg-12 no-padding-right">
          <div class="row orders_rowsection leftlatestdelivery col-lg-12">
             <div class="col-xs-12 col-md-6 orders_latesshipment no-padding margin-T30 float_left">
                   Latest Deliveries</div>
                <div class="col-xs-12 col-md-6 orders_vieworder float_left">
                   <a [routerLink]="'/orders/my-orders'" [queryParams]="{ season : ordersummaryleft?.leftSectionSeason }" class="orders_viewalltxt">View All Open Orders<i class="global_blackarrow"></i>
                   </a>
                </div>
             <div class="col-xs-12 margin-T30 orders_tablepadding_uk no-padding">
                <div class="latest_shipment_uk">

                  <table id="deliveryTable" class="tableClass" mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                     <ng-container matColumnDef="orders">
                        <th mat-header-cell *matHeaderCellDef>Order #</th>
                        <td mat-cell class="matRow" *matCellDef="let element">{{element.orders}}</td>
                      </ng-container>
                      <ng-container matColumnDef="qty">
                        <th mat-header-cell *matHeaderCellDef>Delivered Doses</th>
                        <td mat-cell class="matRow" *matCellDef="let element">{{element.qty | number:'1.0'}}</td>
                      </ng-container>

                     <ng-container matColumnDef="location">
                         <th mat-header-cell *matHeaderCellDef>Delivery Location</th>
                         <td mat-cell class="matRow" *matCellDef="let element"> {{element.location}} </td>
                     </ng-container>
                     <ng-container matColumnDef="viewdetails">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell class="matRow" *matCellDef="let element"><a class="orders_viewDetails anchorunderline" [routerLink]="'/orders/my-orders'" [queryParams]="{address: element.orders}">{{element.viewdetails}} <i class="global_blackarrow"></i></a> </td>
                      </ng-container>
      
                     <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                     <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                     <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell no-data" colspan="4">No data available in table</td>
                      </tr>
                  
                 </table>
                 
                  
                   </div>
             </div>
          </div>
       </div> 
       <div class=" row">
          <div class="col-md-8 orders_rightbottomborder orders_leftseperator"></div>
          <div class="col-md-2 "></div>
       </div>
      </ng-container>





       <!-- upcoming order start -->
    <ng-template #notinseason>
       <div class="row orders_rowsection leftupcoming no-padding">
         <div class="">
            <!-- have to add conditon here for reserve -->
            <!-- provisional changes -->
                 <div class="col-xs-12 col-md-6 orders_producttitle no-padding">Upcoming Orders</div>
                     <!-- have to add conditon here for reserve -->
                      <div class="col-xs-12 col-md-6 orders_vieworder upcomingvieworder rightviewall">
                      <a [routerLink]="'/orders/my-orders'" [queryParams]="{ season : ordersummaryleft?.leftSectionSeason }" class="orders_viewalltxt"> View All Open Orders<i class="global_blackarrow"></i>
                          </a>
               </div>
            </div>
            <table mat-table [dataSource]="dataSourceupcoming" matSort class="mat-elevation-z8 filterTable_upcomingOrder">
          
      
              <ng-container matColumnDef="combined" >
          <th class="odertableheader" mat-header-cell *matHeaderCellDef> order Data </th>
               <td mat-cell *matCellDef="let element"> 
                 <div class="tablecolum_order1">
                 <div class="order_season">{{element.season}} Season</div> 
                 <div class="order_id"><span>Order Number :</span><span>{{element.orderId}}</span></div>
                 <div class="order_id"><span>{{element.displayProduct1}}</span><span class="totalorderquantity_Order" [className]=element.ordernum>{{element.aqivTotal | number:'1.0'}}</span></div>
                 <div class="order_id"><span>{{element.displayProduct2}}</span><span class="totalorderquantity_Order" [className]=element.ordernum>{{element.qivcTotal | number:'1.0'}}</span></div>
  
                 <div class="order_id"><span>Delivery Location:</span><span [innerHtml]="element.delLocation"></span></div>
                 </div>
                 <div class="tablecolum_order2">
                  <div class="order_status">{{element.status}}</div></div>
                 </td>
                   
          
               </ng-container>
               
                   <!-- Header and Row Declarations -->
                   <tr mat-header-row *matHeaderRowDef="displayedColumnsupcoming"></tr>
                   <tr mat-row id="{{row.ordernum}}" *matRowDef="let row; columns: displayedColumnsupcoming;"></tr>
                   <tr class="mat-row " *matNoDataRow>
                     <td class="mat-cell noorder">No orders available</td>
                   </tr>
                 </table>
        
        
      </div>



   </ng-template>
       <!-- upcoming orders end -->
    <div class="row" *ngIf="ordersummaryleft?.inSeasonEnabled">
       <div class="  orders_rowsection orders_rowsectionleft_uk leftproductavaiable">
          <div class="row">
                <div class="col-xs-12 col-md-12 orders_producttitle">Product Availability</div>
                <div class="col-xs-12 col-md-12 orders_productyear">{{ordersummaryleft?.leftSectionSeason | seasonspilt}}</div>
             </div>
             <div class="col-xs-12 orders_prodbox no-padding">
                   <div class="orders_prodtext1" id="orderProduct0" >{{product1}} </div>
                   <div class="orders_prodtext2">
                      <span>{{product1}} &nbsp;</span><span class="product_avail_pack">({{pack1}})</span>
                      <ng-container *ngIf="ordersummaryleft?.products[0].sellable;else soldout1">
                        <span class="orders_avilabletxt pull-right">AVAILABLE</span>
                      </ng-container>
                      <ng-template #soldout1> <span class="orders_avilabletxt pull-right product_soldout">
                        <div class="soldouttxt unavailableProd">UNAVAILABLE</div>
                     </span></ng-template>
                         </div>
                </div>
             <div class="col-xs-12 orders_prodbox no-padding">
                   <div class="orders_prodtext1" id="orderProduct1">{{product2}} </div>
                   <div class="orders_prodtext2">
                      <span>{{product2}} &nbsp;</span><span class="product_avail_pack">({{pack2}})</span>
                      <ng-container *ngIf="ordersummaryleft?.products[1].sellable;else soldout">
                        <span class="orders_avilabletxt pull-right">AVAILABLE</span>
                      </ng-container>
                      <ng-template #soldout> <span class="orders_avilabletxt pull-right product_soldout">
                        <div class="soldouttxt unavailableProd">UNAVAILABLE</div>
                     </span></ng-template>
                      
                         </div>
                </div>
             </div>
    </div>
 </div>

