import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AboutSection1Component } from './about-section1/about-section1.component';
import { AboutVaccineComponent } from './about-vaccine/about-vaccine.component';
import { AboutFluWorldComponent } from './about-flu-world/about-flu-world.component';
import { AboutReferencesComponent } from './about-references/about-references.component';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import {MatTabsModule} from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
 
 
@NgModule({
  declarations: [
    AboutSection1Component,
    AboutVaccineComponent,
    AboutFluWorldComponent,
    AboutReferencesComponent,
   
  ],
  schemas:[NO_ERRORS_SCHEMA,CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    RouterModule,
    ConfigModule.withConfig({
     
      MatTabsModule,
      cmsComponents:{
        SeqirusForeFrontBannerComponent:{
         component:AboutSection1Component
        },
        SeqirusAboutUsoptioncomponent:{
          component:AboutVaccineComponent
        },
        SeqirusFluworldvaccinecomponent:{
          component:AboutFluWorldComponent
        },
        SeqirusNewReferenceComponent:{
          component:AboutReferencesComponent
        },
     
      }
    }as CmsConfig)
  ]
})
export class AboutModule { }