import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { OccEndpointsService, AuthConfigService, CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';
import { UserInfoService } from '../spartacus/services/user-info.service';
import { StartOrderService } from './start-order.service';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { Router } from '@angular/router';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

declare var $: any;
export interface LocationDetails {
   "locQty"?: number,
   "locID": string
}
@Component({
   selector: 'app-custom-start-order',
   templateUrl: './custom-start-order.component.html',
   styleUrl: './custom-start-order.component.scss'
})
export class CustomStartOrderComponent {
   orderLists: any;
   membershipDecision: string = '';
   requestedMembership: any;
   fieldsetDisabled: boolean = true;
   showMembershipContent: boolean = false;
   noContent: any;
   notSureContent: any;
   yesContent: any;
   activeMembershipContent: any;
   pendingMembershipContent: any;
   duplicateproductarray:any;
   poNumber: string = '';
   needHelpContent: Observable<any> = this.cmsService.getComponentData('SeqirusStartOrderUserGuidePDFComponent');
   uid: string = '';
   disableStartOrderBtn: boolean = true;
   showLocationContent: boolean = false;
   iframeUrl: any;
   displayedColumns: string[] = ['locID', 'state', 'address', 'orderExists'];
   dataSource: any;
   selection = new SelectionModel<any>(true, []);
   seasonValue: string = '';
   selectedProducts: any[] = [];
   showSelectedProducts: boolean = false;
   showProductList: boolean = false;
   units: string = '';
   shipLocationQty: LocationDetails[] = [];
   deleteduplicateinseaosn:any;
   deleteduplicatepreseaon:any;
   totalDoses: number = 0;
   totalAmount: any;
   totalquantity: number = 0;
   productForm: any = {
      productQty: []
   }
   showProductContent: boolean = false;
   showReviewContent: boolean = false;
   cartListData: any;
   termsCheckbox: boolean = false;
   mobileSeasonValue: any = 'Select New Order Type';
   showErrorMsg: boolean = false;
   showThankYouContent: boolean = false;
   duplicateSeletedArray: any[] = [];
   @ViewChild('closeButton') closeButton!: ElementRef;
   maxFileSize = 5 * 1024 * 1024;
   maxNumFilesError: boolean = false;
   isUploading: boolean = false;
   acceptedFiles = ['image/jpeg', 'image/png', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', '.eml', 'application/pdf', '.msg'];
   bulkOrderExcel: any;
   maxFileSizeError: boolean = false;
   showError1Msg: boolean = false;
   showBulkOrderSection: boolean = false;
   uploadData: string = '';
   orderSubmitContent: string = '';
   showSubmitButton: boolean = false;
   formData = new FormData();
   isLoading: boolean = false;
   progressValue: number = 0;
   showTryAgain: boolean = false;
   afluria: any;
   flaud:any;
   flucevax:any;
   fileSize: any;
   bannerContent: any;
   disablePlaceOrder: boolean = true;
   isCancelClicked: boolean = false;
   @ViewChild("poNumberField") poNumberField !: ElementRef;
   @ViewChild("termsCheckboxField") termsCheckboxField !: ElementRef;
   showPendingMessage: boolean=false;
   
   constructor(
      private cmsService: CmsService, private cdr: ChangeDetectorRef, public component: CmsComponentData<CmsBannerComponent>, private occEndpointsService: OccEndpointsService, private http: HttpClient, private userInfoService: UserInfoService, private authConfigService: AuthConfigService, private startOrderService: StartOrderService, protected config: OccConfig, private router: Router, private sanitizer: DomSanitizer, private occEndPointsService: OccEndpointsService,private gtmService: GoogleTagManagerService,private renderer: Renderer2) {
   }
   ngOnInit(): void {
      this.getOrderDetails(); //created a function to get initial api response
     
      this.cmsService.getComponentData('seqirusStartOrderMemberShipSubmitNotSure').subscribe(data => {
         this.notSureContent = data;
        
         
      })
      this.cmsService.getComponentData('seqirusStartOrderMemberShipSubmitYes').subscribe(data => {
         this.yesContent = data;
        
        
      })
      this.cmsService.getComponentData('seqirusStartOrderMemberShipSubmitNo').subscribe(data => {
         this.noContent = data;
         
        
      })
      this.cmsService.getComponentData('membership-messageComponentActive').subscribe(data => {
         this.activeMembershipContent = data;
      })
      this.cmsService.getComponentData('membership-messageComponentPending').subscribe(data => {
         this.pendingMembershipContent = data;
      })
      this.startOrderService.getAfluriaPrescribingInfoPdf().subscribe((response: any) => {
         const url = this.occEndPointsService.buildUrl(response);
         this.afluria = url.replace("occ/v2/seqirusb2b-flu360/", "");
         this.cdr.detectChanges();
      });
      this.startOrderService.getFlucevaxcPrescribingInfoPdf().subscribe((response: any) => {
         const url = this.occEndPointsService.buildUrl(response);
         this.flucevax = url.replace("occ/v2/seqirusb2b-flu360/", "");
         this.cdr.detectChanges();
      });
      this.startOrderService.getFlaudPrescribingInfoPdf().subscribe((response: any) => {
         const url = this.occEndPointsService.buildUrl(response);
         this.flaud = url.replace("occ/v2/seqirusb2b-flu360/", "");
         this.cdr.detectChanges();
      });

     
      this.cmsService.getComponentData('cartseasonbegincomponent').subscribe(data => {
         this.bannerContent = data;
      })
   }
   getOrderDetails() {
      this.isLoading = true;
      //Initial get api call
      this.startOrderService.getOrderDetailsdata().subscribe(data => {
         this.orderLists = data;
           if( this.orderLists?.orderBlock!=true){
            this.orderLists?.inSeasonProducts.forEach((el:any)=>{
            el['productlistshow']=true
            });
         
        
            this.orderLists?.preBookProducts.forEach((el:any)=>{
               el['productlistshow']=true
               });
            
       
         this.orderLists?.shippingData.forEach((item: any) => {
            item['value'] = ''; // created value label to track the location input field value under Product page
         })
      }
         this.selection = new SelectionModel<any>(true, []); // the selection array has the selected location details
         this.selectedProducts = []; // In product page,if the user clicks on add product then the selected product should be visible above it so to track that i created this array
         this.dataSource = new MatTableDataSource(this.orderLists?.shippingData);
         this.isLoading = false;
         this.cdr.detectChanges();
      },
         (error) => {
            console.log('order error: ', error);
            this.isLoading = false;
            this.cdr.detectChanges();
         })
   }
   handleOnSeasonBtnClick(value: string) {
      //triggeres when user clicks on season tiles
      this.handleOnClearSelection(); // we should clear all the internal state flags when the user switches between the tiles
      this.seasonValue = value === 'mobile' ? this.mobileSeasonValue : value; // for mobile view the season will be shown as dropdown so mainitaining separate field
      this.mobileSeasonValue = this.mobileSeasonValue ? this.mobileSeasonValue : this.seasonValue; 
      if (  this.seasonValue == 'prebook') {
         //if tiles selection was inseason and preseason
         this.isLoading = true;
         this.startOrderService.checkOrderExist(this.seasonValue).subscribe(data => { // initially check for cart exist scenario
            if (data.toLocaleLowerCase() === "yes") {
               this.getReviewData(); //if cart exist show review page directly 
            } else {
               this.getSectionContent(); // is cart is empty based on the tiles selection enable the page label
            }
         },
            (error) => {
               console.log('order api error: ', error);
               this.isLoading = false;
               this.cdr.detectChanges();
            })
      }else if(this.seasonValue == 'inSeason'){

         this.isLoading = true;
         this.startOrderService.checkOrderExist(this.seasonValue).subscribe(data => { // initially check for cart exist scenario
            if (data.toLocaleLowerCase() === "yes") {
               this.getReviewData(); //if cart exist show review page directly 
            } else {
               this.showLocationContent = true;
                  this.showBulkOrderSection = false; // is cart is empty based on the tiles selection enable the page label
                  this.isLoading = false;
                  this.cdr.detectChanges();
            }
         },
            (error) => {
               console.log('order api error: ', error);
               this.isLoading = false;
               this.cdr.detectChanges();
            })

      } 
      else {
         //if tiles selection was bulk order
         this.showBulkOrderSection = true;
      }
   }
   getSectionContent() {
      if (this.cartListData && this.cartListData.length > 0) { // if u have cart data show review page
         this.showReviewContent = true;
         this.cdr.detectChanges();
      } else {
         if (this.orderLists.membershipData.isMembershipDeclared) { // in case of cart empty check the user has membership or not
            if (this.seasonValue === 'prebook' || this.seasonValue === 'inSeason') {
               //if the tiles selection was inseason/preseason
               //show location page if user has membership
               if (!this.showReviewContent) {
                  this.showLocationContent = true;
                  this.showBulkOrderSection = false;
               }
            } else {
               //show tile selection was bulkorder then so buld order page
               this.showBulkOrderSection = true;
               this.showLocationContent = false;
            }
         } else {
            //show membership page if user has no membership, maintaining showMembershipContent flag for it
            this.showMembershipContent = !this.orderLists.membershipData.isMembershipDeclared;
         }
         this.cdr.detectChanges();
      }
      this.isLoading = false;
      this.cdr.detectChanges();
   }
   handleOnFormSubmit(form: any, orderLists: any) {
      // constructed membership payload
      let memberFormData = {
         currentMembership: orderLists.membershipData.currentMembership,
         membershipStatus: '',
         isContractSigned: orderLists.membershipData.isContractSigned,
         requestedMembership: form.requestedMembership || '',
         membershipDecision: form.membershipDecision
      }
      this.isLoading = true;
      this.startOrderService.postMembershipDetails(memberFormData).subscribe(data => {
         this.fieldsetDisabled = false;
         this.isLoading = false;
         if(memberFormData.membershipDecision == 'no'){
            this.showPendingMessage = true;
         }
         this.cdr.detectChanges();
      },
         (error) => {
            console.log('membership api error: ', error);
            this.isLoading = false;
            this.cdr.detectChanges();
         });
   }
   clearMembershipData() {
      this.membershipDecision = '';
   }
   handleOnStartOrderBtnClick() {
      this.showMembershipContent = false;
      this.showLocationContent = true;
   }
   applyFilter(filterValue: string) {
      this.dataSource.filter = filterValue.trim().toLowerCase();
   }
   handleOnSelectAllBtnClick() {
      if (this.selection.selected.length > 0) {
         //toggle entire rows
         this.dataSource.data.forEach((row: any) => {
            if (!row.selected) {
               
               this.selection.toggle(row);
               row['selected'] = true;
               row['value'] = '';
               this.duplicateSeletedArray = [...this.duplicateSeletedArray, row]; // maintaining this array to track in the edit flow
            }
         });
      } else {
         //toggle the unselected rows
         this.dataSource.data.forEach((row: any) => {
            if (!row.orderExists) {
            this.selection.toggle(row);
            row['value'] = "";
            row['selected'] = true;
            this.duplicateSeletedArray = [...this.duplicateSeletedArray, row];
            }
         });
      }
   }
   handleOnRowClick(row: any) {
      if (!row.orderExists) {
      this.duplicateproductarray=JSON.parse(JSON.stringify(this.selectedProducts));
      row['value'] = "";
      row['selected'] = row.selected ? !row.selected : true;
      if (!row.selected) {
         //if row is not selected previously
         this.selection.selected.forEach((el: any, index) => { // this i have written for edit flow if something is already there in selected array then splice it
            if (el.locID === row.locID) {
               this.selection.selected.splice(index, 1); // remove it from selection array if already selected location is reclicked 
            }
         })
         
          this.selectedProducts.forEach((el: any) => { // this too i have written for edit flow if something is already there in selected array then splice it
            el.location.forEach((data: any, index: any) => {
               if (data.locID === row.locID) {
                  el.location.splice(index, 1); // remove it from selectedProducts array if already selected location is reclicked 
               }
            })
         }) 
         this.selection = new SelectionModel<any>(true, this.selection.selected); // if its not an edit flow then just update the selection array
         this.cdr.detectChanges();
      } else {
         this.selection.toggle(row); // if row was selected then toggle it
      }
      if (this.duplicateSeletedArray.length === 0 && row.selected) { // duplicateSeletedArray is for edit flow case
         this.duplicateSeletedArray = [...this.duplicateSeletedArray, row];
      } else if (this.duplicateSeletedArray.length > 0) {
         this.duplicateSeletedArray.forEach((el: any, index) => {
            if (el.locID === row.locID) {
               this.duplicateSeletedArray.splice(index, 1);
            }
         })
         
      }
   }
   }
   handleOnSelectProductClick() {
     
      this.showLocationContent = false;
      this.showProductContent = true;
      this.isLoading = true;
      if (this.seasonValue === 'inSeason' && this.orderLists.inSeasonProducts.length > 0) {
         this.orderLists.inSeasonProducts.forEach((item: any) => {
            item['location'] = [];
            item['location'] = this.selection.selected.map(data => ({ ...data }));
            item['showAngleDownArrow'] = item['showAngleDownArrow'] ? item['showAngleDownArrow'] : false;
         })
      }
      if (this.seasonValue === 'prebook' && this.orderLists.preBookProducts.length > 0) {
         this.orderLists.preBookProducts.forEach((item: any) => {
            item['location'] = [];
            item['location'] = this.selection.selected.map(data => ({ ...data }));
            item['showAngleDownArrow'] = item['showAngleDownArrow'] ? item['showAngleDownArrow'] : false;
         })
      } 
      if (this.selectedProducts && this.selectedProducts.length > 0) {
         this.duplicateproductarray.find((prod:any)=>{
            prod.location.forEach((location:any)=>{
               
               this.selectedProducts.find((realprod:any)=>{
realprod.location.forEach((realocation:any)=>{
   if(realprod.code==prod.code){
   if(realocation.locID==location.locID){
      realocation.value=location.value;
   }
}
})
               })
            })
         })
      }
      /*  if (this.selectedProducts && this.selectedProducts.length > 0) {
         this.selectedProducts.forEach((prod: any) => {
            this.duplicateSeletedArray && this.duplicateSeletedArray.forEach((el: any) => { 
               console.log("loop");// duplicateSeletedArray mainitained for edit flow
               const obj = {
                  "address": el.address,
                  "checked": el.checked,
                  "locID": el.locID,
                  "locname": el.locname,
                  "orderExists": el.orderExists,
                  "state": el.state,
                  "selected": 'true',
                  "value": ""
               }
               prod.location = [...prod.location, obj]
            })
         })
      }  */
      this.isLoading = false;
      this.cdr.detectChanges();

   }

   restriction(event:any,i:any,index:any){
      const input = event.target as HTMLInputElement;
      
      const pattern = /^[0-9]*$/;   
      //let inputChar = String.fromCharCode(event.charCode)
      if (!pattern.test(event.target.value)) {
        event.target.value = event.target.value.replace(/[^0-9]/g, "");
        // invalid character, prevent input
  
      }
   }
   handleOnAddClick(item: any, index: number) {
    
      this.showSelectedProducts = true;


       this.selectedProducts.forEach((products:any,i =index)=>{
         
        // document.getElementById('accordion1'+i)?.addEventListener('click',this.handleOnToggle(products));
          document.getElementById('part'+i)?.classList.remove('show');
          const ele=document.getElementById('accordion'+i) as HTMLElement;
         ele?.firstElementChild?.classList.add("collapsed");  
       
          //$(ele).collapse('toggle');
         
         //const ele=document.getElementById('accordion'+i ) as HTMLElement;
          
 
         //this.handleOnToggle1(products);

       // accordionElement?.classList.remove('show');
         products.showAngleDownArrow=true;
         this.cdr.detectChanges(); 
         /* let crispClient = this.renderer.selectRootElement('#accordion'+i);
         crispClient.click(); */
       
  
        /*  if( this.seasonValue === 'inSeason'){

         this.orderLists.inSeasonProducts.forEach((listproduct:any)=>{
          if(listproduct.code==products.code){
            listproduct.showAngleDownArrow=false;
            this.cdr.detectChanges();
          }
         });
      }else{
         this.orderLists.preBookProducts.forEach((listproduct:any)=>{
            if(listproduct.code==products.code){
               listproduct.showAngleDownArrow=false;
               this.cdr.detectChanges();
             }

         });
      } */

       

      }); 
     
      item['totalquantity'] = this.totalquantity;
      item['totalDoses'] = this.totalDoses;
      item['totalAmount'] = this.totalAmount;
      item['location'] = this.selection.selected.map(data => ({ ...data }));
      item['showAngleDownArrow'] = false;
      if( this.seasonValue === 'inSeason'){

    this.orderLists.inSeasonProducts[index].productlistshow=false
      }else{this.orderLists.preBookProducts[index].productlistshow=false}
     // this.seasonValue === 'inSeason' ? this.orderLists.inSeasonProducts.splice(index, 1) : this.orderLists.preBookProducts.splice(index, 1);
      this.selectedProducts = [...this.selectedProducts, item];
     
   }
   handleOnToggle(item1: any,i:any) {
      item1['showAngleDownArrow'] = !item1['showAngleDownArrow'];
      if(item1.showAngleDownArrow==true){
      document.getElementById('part'+i)?.classList.remove('show');
          const ele=document.getElementById('accordion'+i) as HTMLElement;
         ele?.firstElementChild?.classList.add("collapsed");  
      }else{
         document.getElementById('part'+i)?.classList.add('show');
         const ele=document.getElementById('accordion'+i) as HTMLElement;
        ele?.firstElementChild?.classList.remove("collapsed");  
      }
     
      this.cdr.detectChanges();
   }
   handleOnDeleteBtnClick(item: any, index: number) {
      item.totalAmount = '';
      item.totalAmount = 0;
      item.totalquantity = ''
      const duplicatearraytodelete=JSON.parse(JSON.stringify(this.selectedProducts));
      
      item.location.forEach((data: any) => {
         data.value = ""
      })
 
      if( this.seasonValue === 'inSeason'){
         
         this.orderLists.inSeasonProducts.find((element:any) => {
            if(element.code==item.code){
            element.productlistshow=true
            }
         });
        }else{
         this.orderLists.preBookProducts.find((element:any) => {
            if(element.code==item.code){
            element.productlistshow=true
            }
         });
        
        }
        duplicatearraytodelete.splice(index, 1);
      this.selectedProducts=  duplicatearraytodelete
    //  this.orderLists.inSeasonProducts=this.deleteduplicateinseaosn
    //  this.orderLists.preBookProducts=this.deleteduplicatepreseaon
     
      this.cdr.detectChanges();
      //this.orderLists.inSeasonProducts = this.seasonValue === 'inSeason' ? [...this.orderLists.inSeasonProducts, item] : [...this.orderLists.preBookProducts, item];
      if (this.selectedProducts.length == 0) {
         this.showSelectedProducts = false;
      }
   
   }
   handleOnAddProductBtnClick() {
      this.showProductList = !this.showProductList;
   }
  /*  handleonchangevalue(event:any,productIndex: number, locationIndex: number){

       const stringvalue1=event.target.value
      const entervalue1=Number(stringvalue1.replace(/[^0-9]/g, ''));
      
      this.selectedProducts[productIndex].location[locationIndex].value=entervalue1;
      this.cdr.detectChanges(); 
   } */
    handleOnUnitChange(prodValue: any, productIndex: number, locationIndex: number) {

       const stringvalue=prodValue.target.value.toString();
      const entervalue=Number(stringvalue.replace(/[^0-9]/g, ''));
      
      
    
      const value = Number(entervalue);
      this.selectedProducts[productIndex].location
      const sum = this.selectedProducts[productIndex].location.reduce((accumulator: any, current: any) => accumulator + current.value, 0);
      this.selectedProducts[productIndex].totalDoses = sum ? sum * this.selectedProducts[productIndex].doses : 0;
      this.selectedProducts[productIndex].totalAmount = new Intl.NumberFormat("en",
         { style: 'currency', currency: this.selectedProducts[productIndex].priceData.currencyIso, minimumFractionDigits: 2 }
      ).format(sum * this.selectedProducts[productIndex].priceData.value);
      this.selectedProducts[productIndex].totalquantity = sum ? sum : 0;
      this.productForm.productQty.forEach((product: any) => {
         if (product.productCode === this.selectedProducts[productIndex].code) {
            product.shipLocationQty[locationIndex]['locQty'] = value;
         }
      }) 
      this.selectedProducts[productIndex].location[locationIndex].value=entervalue
      this.cdr.detectChanges();
   } 
  
 
   handleOnPlaceOrderBtnClick() {
      //constructed place order payload
      this.isLoading = true;
      this.duplicateSeletedArray = [];
      this.productForm.productQty = [];
      this.selectedProducts.forEach(item => {
         let shippingData: LocationDetails[] = [];
         item.location && item.location.forEach((data: any) => {
            shippingData.push({ locID: data.locID, locQty: data.value })
         })
         this.productForm.productQty.push({ productCode: item.code, shipLocationQty: shippingData });
      })
      this.startOrderService.addToCart(this.productForm, this.seasonValue).subscribe((data: any) => {
         this.getReviewData(); // you have to hit 2 apis
        //this.userInfoService.setcarttigger("changed");
      },
         (error) => {
            console.log('add to cart api error: ', error);
            this.isLoading = false;
            this.cdr.detectChanges();
         })
   }
   getReviewData() {
      this.startOrderService.getReviewPageData(this.seasonValue).subscribe((reviewData: any) => {
         this.cartListData = reviewData; 
         this.cdr.detectChanges();
         this.startOrderService.getIframeUrl(this.seasonValue).subscribe((iframe: any) => { // to show terms and codition section, I triggerred api call and got the url
            let url = this.occEndPointsService.buildUrl(`${iframe}`); //&embedded=true
            url = url.replace("occ/v2/seqirusb2b-flu360/", "");
            this.iframeUrl = url;
            if (!this.showReviewContent && this.showProductContent) { // this triggers for create flow
               this.showReviewContent = true;
               this.showProductContent = false;
            }
            else {
               //this part you can leave, no where Im using selectedProdListArray, now i dont have time to comment and check the flow so Im leaving as it is
               this.showReviewContent = true;
               const selectedProdListArray = this.cartListData.products.filter((item: any) => {
                  const prodNameArray = item.productCode.split(',');
                  if (this.seasonValue === 'inSeason') {
                     return this.orderLists.inSeasonProducts.find((el: any) => {
                        if (prodNameArray.includes(el.code)) { return el };
                     })
                  } else {
                     return this.orderLists.preBookProducts.find((el: any) => {
                        if (prodNameArray.includes(el.code)) { return el };
                     })
                  }

               })
            }
            this.isLoading = false;
            this.cdr.detectChanges();
         },
            (error) => {
               console.log('iframe api error: ', error);
               this.isLoading = false;
               this.cdr.detectChanges();
            })
      },
         (error) => {
            console.log('review api error: ', error);
            this.isLoading = false;
            this.cdr.detectChanges();
         });
   }
   handleOnBackToLocationBtnClick() {
      //update selection array when u click on back button it handles both edit and create case
      this.duplicateSeletedArray = [];
      this.selection.selected.forEach((row: any) => {
         this.dataSource.data.find((el: any) => {
            if (row.locID === el.locID) {
               el['selected'] = true;
            }
         })
      })

      this.showLocationContent = true;
      this.showProductContent = false;
   }
   getImage(image: any): string {
      return this.config.backend?.occ?.baseUrl + image.url;
   }
   handleOnBackToProductBtnClick() {
      //update selectedProducts array on click of back button. it handles both edit and create flow
      this.showReviewContent = false;
      this.showProductContent = true;
      this.showErrorMsg = false;
      const duplicateselectedarrayinseaosn=JSON.parse(JSON.stringify(this.orderLists.inSeasonProducts));
      const duplicatepreseaon= JSON.parse(JSON.stringify(this.orderLists.preBookProducts));

      if (this.selectedProducts.length<1) {
      if (this.seasonValue === 'inSeason') { 
        
         this.orderLists.inSeasonProducts.forEach((item: any, index: number) => {
  
            this.cartListData.data.find((el: any) => {
        
               if (el.productCode === item.code) {
                 
                  let locationArray: any[] = [];
                  item['totalquantity'] = el.totalquantity;
                  item['totalDoses'] = (Number(el.totalquantity) * item.doses).toString();
                  item['totalAmount'] = el.totalcost.includes('$') ? el.totalcost : new Intl.NumberFormat("en",
                     { style: 'currency', currency: "USD", minimumFractionDigits: 2 }
                  ).format(el.totalcost);
                  item['showAngleDownArrow'] = false;

                  el.locations.forEach((loc: any) => {
                     this.orderLists.shippingData.find((location: any) => {
                        if (location.locID === loc.locID) {
                           const obj = {
                              "address": location.address,
                              "checked": location.checked,
                              "locID": location.locID,
                              "locname": location.locname,
                              "orderExists": location.orderExists,
                              "state": location.state,
                              "value": loc.locqtn,

                           }
                           locationArray = [...locationArray, obj];

                        }
                     })
                  })
                  if (item.location) {
                     item.location.length = 0;
                     item.location = locationArray;
                  } else {
                     item['location'] = locationArray;
                  }
                  this.selectedProducts = [...this.selectedProducts, item];
                  //console.log(this.selectedProducts)

                  // this.selectedProducts.forEach((el: any) => {
                  //    this.selection.select(el)
                  // })
                
                  item.productlistshow=false
             
                 // duplicateselectedarrayinseaosn.splice(index, 1);
               }
            })
         })
         //this.orderLists.inSeasonProducts=duplicateselectedarrayinseaosn;
    
       

      } else {
         this.orderLists.preBookProducts.forEach((item: any, index: number) => {
            this.cartListData.data.find((el: any) => {
               if (el.productCode === item.code) {
                  let locationArray: any[] = [];
                  item['totalquantity'] = el.totalquantity;
                  item['totalDoses'] = (Number(el.totalquantity) * item.doses).toString();
                  item['totalAmount'] = el.totalcost;
                  item['showAngleDownArrow'] = false;

                  el.locations.forEach((loc: any) => {
                     this.orderLists.shippingData.find((location: any) => {
                        if (location.locID === loc.locID) {
                           const obj = {
                              "address": location.address,
                              "checked": location.checked,
                              "locID": location.locID,
                              "locname": location.locname,
                              "orderExists": location.orderExists,
                              "state": location.state,
                              "value": loc.locqtn,

                           }
                           locationArray = [...locationArray, obj];

                        }
                     })
                  })
                  if (item.location) {
                     item.location.length = 0;
                     item.location = locationArray;
                  } else {
                     item['location'] = locationArray;
                  }
                  this.selectedProducts = [...this.selectedProducts, item];

                  // this.selectedProducts.forEach((el: any) => {
                  //    this.selection.select(el)
                  // })
                  item.productlistshow=false
                  //duplicatepreseaon.splice(index, 1);
               }
            })
         })
        // this.orderLists.preBookProducts=duplicatepreseaon;
      }
   }
      if (this.selectedProducts && this.selectedProducts.length > 0) {
         this.selection = new SelectionModel<any>(true, []);
         this.selectedProducts[0].location.forEach((el: any) => {
            this.selection.toggle({
               "address": el.address,
               "checked": el.checked,
               "locID": el.locID,
               "locname": el.locname,
               "orderExists": el.orderExists,
               "state": el.state,
               "selected": 'true'
            });
         })

         this.showSelectedProducts = true;

      }
this.cdr.detectChanges
   }
   handleOnDeleteModelYesBtnClick() {
      this.isLoading = true;
      this.startOrderService.deleteCartData(this.seasonValue).subscribe(data => {
         this.showReviewContent = false;
         this.getOrderDetails();
         this.closeButton.nativeElement.click();
         this.isLoading = false;
         this.seasonValue = '';
         this.cartListData = [];
         this.selection = new SelectionModel<any>(true, []);
         this.duplicateSeletedArray = [];
         this.selectedProducts = [];
         //this.userInfoService.setcarttigger("changed");
         this.cdr.detectChanges();
      },
         (error) => {
            console.log('delete api error: ', error);
            this.isLoading = false;
            this.cdr.detectChanges();
         });
   }
   handleOnPlaceOrder() {
      //constructed review page payload 
      this.isLoading = true;
      this.startOrderService.placeOrderAPI(this.poNumber, this.seasonValue).subscribe(data => {
         if (data) {
            this.showThankYouContent = true;
            this.showReviewContent = false;
            data=JSON.stringify(data)
            this.cdr.detectChanges();
            const gtmTag = {
               event: 'transaction',
               ecommerce: data
           };
            this.gtmService.pushTag(gtmTag)


         }
         this.isLoading = false;
         this.cdr.detectChanges();
      },
         (error) => {
            console.log('place order api error: ', error);
            this.isLoading = false;
            this.cdr.detectChanges();
         });
   }
   handleOnAutoGeneratePoNo() {
      this.startOrderService.getPORefNumber().subscribe(data => {
         this.poNumber = data; 
         this.cdr.detectChanges();
      });
   }
   handleOnViewOrders() {
      this.router.navigate(['/orders/my-orders']);
   }
   cleanURL(): SafeResourceUrl {
      return this.sanitizer.bypassSecurityTrustResourceUrl(this.iframeUrl + `#toolbar=0&navpanes=0&view=FitH`);
   }
   filesValidator(control: any) {
      const files = control.value;
      let totalSize = 0;
      for (const file of files) {
         totalSize += file.size;
      }
      if (totalSize > this.maxFileSize) {
         return { maxFileSize: true };
      }
      return null;
   }
   onFileChange(event: any) {
      this.isUploading = true;
      const fileList: FileList = event.target.files;
      if (fileList.length > 0) {
         const file: File = fileList[0];
         this.bulkOrderExcel = file;
         if (this.bulkOrderExcel.size < 1000000) {
            this.fileSize = Math.floor(this.bulkOrderExcel.size / 1000) + 'KB';
         } else {
            this.fileSize = Math.floor(this.bulkOrderExcel.size / 1000000) + 'MB';
         }
      }
      if (this.bulkOrderExcel.size > this.maxFileSize) {
         this.showError1Msg = true;
      } else {
         this.formData.append('bulkOrderExcel', this.bulkOrderExcel, this.bulkOrderExcel.name);
         this.startOrderService.uploadFile(this.formData).subscribe((data: any) => {
            if (data.type == 0) {
               this.progressValue = 0;
               this.cdr.detectChanges();
               this.progressValue = this.progressValue + 10;
               this.cdr.detectChanges();
            }
            if (data.type == 2) {
               this.progressValue = 40;
               this.cdr.detectChanges();
            }
            if (data.type == 3) {
               this.progressValue = 70;
               this.cdr.detectChanges();
            }
            if (data['body']) {
               this.progressValue = 100;
               this.cdr.detectChanges();
               setTimeout(() => {
                  if(data.body && !this.isCancelClicked) {
                  this.uploadData = data.body;
                  this.cdr.detectChanges();
                  }
               }, 2000);

            }
         },
         (error) => {
            this.uploadData = 'failure';
            this.cdr.detectChanges();
            console.log('error: ', error);
         })
      }

   }
   addFiles(file: any) {
      if (file.size > this.maxFileSize) {
         this.maxNumFilesError = true;
      }
      this.isUploading = false;
      this.cdr.detectChanges();
   }
   onDragOver(event: DragEvent) {
      event.preventDefault();
   }
   onDragLeave(event: DragEvent) {
      event.preventDefault();
   }
   onDrop(event: DragEvent) {
      this.isUploading = true;
      event.preventDefault();
      if (event.dataTransfer?.files) {
          const files = Array.from(event.dataTransfer.files) as File[];
          if (files.length > 0) {
              const file: File = files[0];
              const validTypes = [
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                  'application/vnd.ms-excel'
              ];
              if (!validTypes.includes(file.type)) {
                  this.showError1Msg = true;
                  this.isUploading = false;
                  return;
              }
              this.bulkOrderExcel = file;
              if (this.bulkOrderExcel.size > this.maxFileSize) {
                  this.showError1Msg = true;
              } else {
                  this.formData.append('bulkOrderExcel', this.bulkOrderExcel, this.bulkOrderExcel.name);
                  this.startOrderService.uploadFile(this.formData).subscribe((data: any) => {
                      if (data.type == 0) {
                          this.progressValue = 0;
                          this.cdr.detectChanges();
                          this.progressValue = this.progressValue + 10;
                          this.cdr.detectChanges();
                      }
                      if (data.type == 2) {
                          this.progressValue = 40;
                          this.cdr.detectChanges();
                      }
                      if (data.type == 3) {
                          this.progressValue = 70;
                          this.cdr.detectChanges();
                      }
                      if (data['body']) {
                          this.progressValue = 100;
                          this.cdr.detectChanges();
                          /* setTimeout(() => {
                              this.uploadData = 'success';
                              this.cdr.detectChanges();
                          }, 2000); */
                          setTimeout(() => {
                           if(data.body && !this.isCancelClicked) {
                           this.uploadData = data.body;
                           this.cdr.detectChanges();
                           }
                        }, 2000);
                      }
                  },
                  (error) => {
                     this.uploadData = 'failure';
                     this.cdr.detectChanges();
                     console.log('error: ', error);
                  }
               );
                  this.cdr.detectChanges();
              }
          }
      }
  }
   removeFile() {
      this.bulkOrderExcel = '';
      this.maxNumFilesError = false;
      this.maxFileSizeError = false;
      this.isUploading = false;
      this.uploadData = '';
      this.showTryAgain = false;
      this.isCancelClicked = true;
   }
   handleOnBulkOrderSeasonBtnClick(season: string) {
      this.isLoading = true;
      this.startOrderService.getBulkOrderSeasonDocument(season)
         .subscribe(
            (data: any) => {
               this.isLoading = false;
               this.cdr.detectChanges();
               const contentDisposition = data.headers.get('Content-disposition').split('filename=')[1];
               const file = new Blob([data.body]);
               const fileURL = URL.createObjectURL(file);
               const a = document.createElement('a');
               a.href = fileURL;
               a.download = contentDisposition.replace(/["]+/g, '').trimStart();
               document.body.appendChild(a);
               a.click();
            },
            (error) => {
               console.log('download pdf error: ', error);
               this.isLoading = false;
               this.cdr.detectChanges();
            }
         );


   }
   handleOnTryAgainBtnClick() {
      this.isUploading = false;
      this.showError1Msg = false;
      this.orderSubmitContent = "";
      this.uploadData = "";
      this.progressValue = 0;
      this.showTryAgain = false;
   }
   handleOnUploadSubmit() {
      this.isLoading = true;
      this.startOrderService.submitFile(this.formData).subscribe((data: any) => {
         this.orderSubmitContent = data;
         this.isLoading = false;
         this.cdr.detectChanges();
      },
         (error) => {
            console.log('upload file error: ', error);
            this.orderSubmitContent = 'success';
            this.isLoading = false;
            this.cdr.detectChanges();
         })
   }
   handleOnClearSelection() {
      this.selection.clear();
      this.duplicateSeletedArray = [];
      this.selectedProducts = [];
      this.showReviewContent=false;
      this.showLocationContent = false;
      this.showProductContent = false;
      this.showReviewContent = false;
      this.showMembershipContent = false;
      this.showThankYouContent = false;
     /*  this.showBulkOrderSection = false;
      
    
      
      this.showErrorMsg = false; */
      this.orderLists.shippingData.forEach((el: any) => {
         el.selected = false;
      })
      this.membershipDecision = '';
      this.cdr.detectChanges();
   }
   handleonclearbtnclick(){
      this.selection.clear();
      this.duplicateSeletedArray = [];
      this.selectedProducts = [];
     /*  this.showReviewContent=false;
      this.showLocationContent = false;
      this.showProductContent = false;
      this.showReviewContent = false;
      this.showMembershipContent = false;
      this.showThankYouContent = false; */
     /*  this.showBulkOrderSection = false;
      
    
      
      this.showErrorMsg = false; */
      this.orderLists.shippingData.forEach((el: any) => {
         el.selected = false;
      })
      this.membershipDecision = '';
      this.cdr.detectChanges();
   }
   handleOnToggle1(item: any,i:any) {

      item['showAngleDownArrow'] = item['showAngleDownArrow'] ? !item['showAngleDownArrow'] : false;
      if(item.showAngleDownArrow==true){
         document.getElementById('part'+i)?.classList.remove('show');
             const ele=document.getElementById('accordion'+i) as HTMLElement;
            ele?.firstElementChild?.classList.add("collapsed");  
         }else{
            document.getElementById('part'+i)?.classList.add('show');
            const ele=document.getElementById('accordion'+i) as HTMLElement;
           ele?.firstElementChild?.classList.remove("collapsed");  
         }
      
      this.cdr.detectChanges();
   }
   handleOnReorderClick() {
      this.isLoading = true;
      this.startOrderService.preBookRollOver().subscribe((data: any) => {
         if (data == 'success') {
            this.seasonValue = 'prebook';
            this.cdr.detectChanges();
            this.getReviewData();
         }
      }, (error) => {
         console.log('upload file error: ', error);
         this.isLoading = false;
         this.cdr.detectChanges();
      })
   }
   handleMyOrdersLink(address: string) {
      this.router.navigate(
         ['/orders/my-orders'],
         { queryParams: { season: this.seasonValue == 'inSeason' ? '2024-2025' : '2025-2026', address: address } }
      );
   }
   handleOnLinkClick(event: any) {
      event.preventDefault();
      window.open(this.iframeUrl, '_blank');
   }
   scrollToTop(): void {
      window.scrollTo({ top: 0, behavior: 'smooth' });
   }
   focusOnInputField() {
      if (!this.poNumber) {
         this.poNumberField.nativeElement.focus();
         this.showErrorMsg = true;
      }
      if (!this.termsCheckbox) {
         this.termsCheckboxField.nativeElement.focus();
         this.showErrorMsg = true;
      }
      if (this.poNumber && this.termsCheckbox) {
         this.showErrorMsg = false;
         this.handleOnPlaceOrder();
         this.scrollToTop();
      }
   }
   disablePlaceOrderBtn() {
      if (this.selectedProducts.length > 0) {
         const productArray = this.selectedProducts.find((el: any) => { return el.totalquantity == 0 });
         return productArray != undefined;
      } else {
         return true
      }
   }
}