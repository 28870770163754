
<div class="col-xs-12 col-md-10 col-lg-10 locations_headersection" [ngClass]="currentSite == 'seqirusb2b-uk'?'locations_headersectionuk':''">
    <!--Loader icons-->
    <div *ngIf="isLoading" class="loading">
        <div class="loading_txt">
        <div class="load_txt">{{'orgLocation.loading' | cxTranslate}}</div>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>
    <!----Loader ends here----->
    <div class="col-xs-12 col-md-7 locations_headerleftsection">
        <h1 *ngIf="currentSite == 'seqirusb2b-flu360'" class="col-xs-12 locations_leftheader modified-h1">{{'orgLocation.title' | cxTranslate}}</h1>
        <h1 *ngIf="currentSite == 'seqirusb2b-uk'" class="col-xs-12 locations_leftheader locations_leftheaderuk modified-h1">{{'orgLocation.titleuk' | cxTranslate}}</h1>
        <div class="col-xs-12 locations_leftsubheader">{{'orgLocation.searchtext' | cxTranslate}}</div>
    </div>
    <div class="col-xs-12 col-md-5 locations_headerrightsection">
        <i class="fa fa-search"></i>
        <input type="text" class="locations_searchbar"[formControl]="searchField" (keydown.enter)="applyFilter($event)"/>
    <div class="locations_searchbtn" (click)="applyFilter($event)">{{'orgLocation.search' | cxTranslate}}</div>
    </div>
</div>

<div class="col-xs-12 col-md-10 locations_section main">
    <ng-container *ngIf="currentSite == 'seqirusb2b-flu360';else ukorgLoc">
    <div class="col-xs-12 locations_tablesection">	
        <div [style.display]="isRowClicked?'':'none'"  class="locations_disablebg"></div>	
        <div class="col-xs-12 locations_header">{{'orgLocation.loctext' | cxTranslate}}</div>
        <div class="clearfix"></div>

        <!-- mobile Filter -->
        <div class="locations_filtering">
            <div class="col-xs-12 locations_filterbox" (click)="filterClick()">
                <div class=" locations_filterLabel">{{'orgLocation.filterlabel' | cxTranslate}}</div>
                <span class=" locations_filterarrow glyphicon glyphicon-menu-down"></span>
            </div>
            <div class="clearfix"></div>
            <div [style.display]="isFilterOpen?'':'none'" class="locations_filteropen">
                <div class="location_checkboxparent">
                    <label class="checkbox-button">
                        <input type="checkbox" name="checkbtn" value="Type" class="location_checkbox" (change)="sortTest('type')">
                        <span class="checkbox-button__control"></span>
                    </label>
                    <label>{{'orgLocation.typelabel' | cxTranslate}}</label>
                </div>
                <div class="location_checkboxparent">
                    <label class="checkbox-button">
                        <input type="checkbox" name="checkbtn" value="Name" class="location_checkbox" (change)="sortTest('name')">
                        <span class="checkbox-button__control"></span>
                    </label>
                    <label>{{'orgLocation.namelabel' | cxTranslate}}</label>
                </div>
                <div class="location_checkboxparent">
                    <label class="checkbox-button">
                        <input type="checkbox" name="checkbtn" value="Address" class="location_checkbox" (change)="sortTest('address')">
                        <span class="checkbox-button__control"></span>
                    </label>
                    <label>{{'orgLocation.addresslabel' | cxTranslate}}</label>
                </div>
                <div class="location_checkboxparent">
                    <label class="checkbox-button">
                        <input type="checkbox" name="checkbtn" value="MainContact" class="location_checkbox" (change)="sortTest('mainContact')">
                        <span class="checkbox-button__control"></span>
                    </label>
                    <label>{{'orgLocation.contactlabel' | cxTranslate}}</label>
                </div>
                <div class="location_checkboxparent">
                    <label class="checkbox-button">
                        <input type="checkbox" name="checkbtn" value="PhoneNumber" class="location_checkbox" (change)="sortTest('phoneNumber')">
                        <span class="checkbox-button__control"></span>
                    </label>
                    <label>{{'orgLocation.phnlabel' | cxTranslate}}</label>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
        <div id="locationstable_wrapper" class="dataTables_wrapper no-footer">
            
        <table id="locationstable" class="display nowrap locationstable" width="100%" mat-table multiTemplateDataRows matSort [dataSource]="dataSource">
            
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true" style="width: 6%;">{{'orgLocation.statuslabel' | cxTranslate}}
                <span *ngIf="dataSource.sort?.active === 'status'">
                    <img [ngClass]="{'rotated': dataSource.sort?.direction === 'desc'}" src="assets/images/sort_grey.png"/>
                </span>
                </th>
                <td mat-cell *matCellDef="let row" data-label="status">{{row.status}}</td>
            </ng-container>
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true" style="width: 8%;">{{'orgLocation.typelabel' | cxTranslate}}
                    <span *ngIf="dataSource.sort?.active === 'type'">
                        <img [ngClass]="{'rotated': dataSource.sort?.direction === 'desc'}" src="assets/images/sort_grey.png"/>
                    </span>
                </th>
                <td mat-cell *matCellDef="let row" data-label="type" >{{row.type}}</td>
            </ng-container>
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true" style="width: 11%;">{{'orgLocation.namelabel' | cxTranslate}}
                    <span *ngIf="dataSource.sort?.active === 'name'">
                        <img [ngClass]="{'rotated': dataSource.sort?.direction === 'desc'}" src="assets/images/sort_grey.png"/>
                    </span>
                </th>
                <td mat-cell *matCellDef="let row" data-label="name" >{{row.name}}</td>
            </ng-container>
            <ng-container matColumnDef="address">
                <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true" style="width: 10%;">{{'orgLocation.addresslabel' | cxTranslate}}
                    <span *ngIf="dataSource.sort?.active === 'address'">
                        <img [ngClass]="{'rotated': dataSource.sort?.direction === 'desc'}" src="assets/images/sort_grey.png"/>
                    </span></th>
                <td mat-cell *matCellDef="let row" data-label="address" >{{row.address}}</td>
            </ng-container>
            <ng-container matColumnDef="mainContact">
                <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true" style="width: 12%;">{{'orgLocation.contactlabel' | cxTranslate}}
                    <span *ngIf="dataSource.sort?.active === 'mainContact'">
                        <img [ngClass]="{'rotated': dataSource.sort?.direction === 'desc'}" src="assets/images/sort_grey.png"/>
                    </span>
                </th>
                <td mat-cell *matCellDef="let row" data-label="mainContact" >{{row.mainContact}}</td>
            </ng-container>
            <ng-container matColumnDef="phoneNumber">
                <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true" style="width: 15%;">{{'orgLocation.phnlabel' | cxTranslate}}
                    <span *ngIf="dataSource.sort?.active === 'phoneNumber'">
                        <img [ngClass]="{'rotated': dataSource.sort?.direction === 'desc'}" src="assets/images/sort_grey.png"/>
                    </span>
                </th>
                <td mat-cell *matCellDef="let row" data-label="phoneNumber">{{row.phoneNumber | mask:'(000) 000-0000'}}<ng-container *ngIf="row.ext">&nbsp;ext.{{row.ext}}</ng-container></td>
            </ng-container>
            <ng-container matColumnDef="viewDetails">
                <th mat-header-cell *matHeaderCellDef style="width:12%"></th>
                <td mat-cell *matCellDef="let row" class="details-control">
                   <div (click)="viewDetails(row)">{{isRowExpanded(row) ? 'View Details':'View Details'}}</div> 
                </td>
                
              </ng-container>
            
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr> 
            
            <ng-container *ngIf="isRowExpanded(selectedElement) || !selectedElement">
              <tr #tablemainrow mat-row *matRowDef="let row; columns:displayedColumns;let i=index" [class.row-hide]="!(isRowExpanded(row) || selectedElement===null)" class="main-row1">
            </tr>
            </ng-container>
              <!-- expanded row -->
            <div *ngIf="isRowExpanded(selectedElement)">
        <tr *matRowDef="let row ;columns:[]" [ngClass]="row.type == 'Shipping'?'locations_border':'locations_border billship'" [class.row-hide]="!(isRowExpanded(row) || selectedElement===null)"> 
            <ng-container *ngIf="isRowExpanded(row)">
            <td class="mob-details-td" [attr.colspan]="7">
                
                    <div *ngIf="editElement !== row;else editForm" class="col-xs-12 locations_editsection">
                        <div class="col-xs-12 col-md-2 locations_edit">
           
                                <div *ngIf="row.status !== 'pending'" class="locations_editbtn" (click)="onEdit(row)">Edit <div class="locations_editicon"></div></div>

                        
                            <div class="locations_closeicon" [attr.id]="row.addressId" (click)="close()"></div>
                        </div>
                        <div class="col-xs-12 col-md-5 location_font"><div class="locations_editheader">{{'orgLocation.contactInfo' | cxTranslate}}</div> <br>
                        <div class="col-xs-6" style="padding-left:0px;"><div class="locations_editsubheader">{{'orgLocation.firstNameLabel' | cxTranslate}}</div>{{row.firstName}}</div><div class="col-xs-6" style="padding-left:0px;"><div class="locations_editsubheader">{{'orgLocation.lastNameLabel' | cxTranslate}}</div>{{row.lastName}}</div>
                        <div class="clear"></div><br><br><br><div class="col-xs-12" style="padding-left:0px;"><div class="locations_editsubheader">{{'orgLocation.emailLabel' | cxTranslate}}</div>{{row.email}}</div><br><br><br>
                        <div *ngIf="row.type != 'Shipping'" class="editsubheader_enable col-xs-6 no-padding float_left" style="display: block;"><div class="col-12" style="padding-left:0px;"><div class="locations_editsubheader">{{'orgLocation.phnlabel' | cxTranslate}}</div>{{row.phn}}</div></div>
                        <div *ngIf="row.type != 'Shipping'" class="editsubheader_enable col-xs-6 no-padding float_left" style="display: block;"><div class="col-12" style="padding-left:0px;"><div class="locations_editsubheader">{{'orgLocation.extlabel' | cxTranslate}}</div>{{row.ext}}</div>
                        <div class="clear"></div><br><br></div><div class="locations_editsubheader col-md-12 no-padding float_left">{{'orgLocation.orglabel' | cxTranslate}}</div>{{row.name}}<br><br>
                        <div class="locations_gln" [ngClass]="row.type == 'Shipping'?'locations_optional':'locations_optional test'"><div class="locations_editsubheader">{{'orgLocation.glnLabel' | cxTranslate}}</div><span> {{row.gln}}</span><br><br></div>
                        <div class="locations_editsubheader">{{'orgLocation.addresslabel' | cxTranslate}}</div>{{row.addrLine1}}, {{row.addrLine2}}<br>{{row.city}} {{row.state}}{{row.zipCode}}<br><br>
                        <div class="locations_editsubheader">{{'orgLocation.contactInfoLabel' | cxTranslate}}</div>{{row.mainContact}}<br>{{row.email}}<br>{{row.phoneNumber}}<br><br>
                        
                        <div *ngIf="row.type === 'Paying'" style="display: block;" class="locations_editsubheader_paying"><div class="locations_editsubheader">{{'orgLocation.commPrefLabel' | cxTranslate}}</div>
                            <div *ngIf="checkValidEmail(row.payingEmail)" class="loc_byemail" style="display: block;">{{'orgLocation.recieveEmailLabel' | cxTranslate}}<br>{{row.payingEmail}}<br><br></div>
                            <div *ngIf="!checkValidEmail(row.payingEmail)"class="loc_bymail" style="display: block;">{{'orgLocation.recieveMailLabel' | cxTranslate}}<br>{{row.addrLine1}}, {{row.addrLine2}}<br>{{row.city}} {{row.state}} {{row.zipCode}}<br><br></div></div>
                        
                        <div *ngIf="row.type === 'Billing'" class="locations_editsubheader_billing" style="display: block;"><div class="locations_editsubheader">Communication Preference</div>
                            <div *ngIf="checkValidEmail(row.billingEmail)" class="loc_byemail" style="display: block;">{{'orgLocation.recieveEmailLabel' | cxTranslate}}<br>{{row.billingEmail}}<br><br></div>
                            <div *ngIf="!checkValidEmail(row.billingEmail)" class="loc_bymail" style="display: block;">{{'orgLocation.recieveMailLabel' | cxTranslate}}<br>{{row.addrLine1}}, {{row.addrLine2}}<br>{{row.city}} {{row.state}} {{row.zipCode}}<br><br></div></div></div>
                        
                        <div class="col-xs-12 col-md-5" [ngClass]="row.type == 'Shipping'?'locations_optional':'locations_optional test'"><div class="locations_editheader">{{'orgLocation.licInfo' | cxTranslate}}</div> <br>
                        <div class="locations_editsubheader">{{'orgLocation.licName' | cxTranslate}}</div>{{row.licenseName}} <br><br><div class="locations_editsubheader">{{'orgLocation.licAddress' | cxTranslate}}</div>{{row.licenseAddress}} <br><br><div class="locations_editsubheader">{{'orgLocation.licNum' | cxTranslate}}</div>{{row.licenseNumber}}<br><br></div>
                    </div>
                        
        <ng-template #editForm>
                
            <form id="locations_editform" novalidate="novalidate" [formGroup]="orgLocationsUpdateForm" (ngSubmit)="validateLicenseUpdateLoc()">
                <div>
                <div class="col-xs-12 locations_editsection">
                    <div class="col-xs-12 col-md-2"><div class="locations_edit"><button type="submit" class="locations_savebtn">Save <div class="locations_saveicon"></div></button><div class="locations_closeicon" (click)="close()"></div></div></div>
                
                <div class="col-xs-12 col-md-5">
                        <div class="locations_editheader col-xs-12">{{'orgLocation.contactInfo' | cxTranslate}}</div> <input type="hidden" id="loc_type" name="loc_type" class="form-control"><input type="hidden" id="loc_billingaddressID" name="loc_billingaddressID" value="NA" class="form-control"><input type="hidden" id="loc_addressId" name="loc_addressId" value="{{row.addressId}}" class="form-control">
                        <div class="">
                            <div class="col-xs-6 form-group"><label class="locations_inputheader">{{'orgLocform.fNamelabel' | cxTranslate}}<sup>*</sup></label>
                                <div class="input-group">
                                <input type="text" id="loc_editfname" name="loc_editfname" class="form-control" formControlName="loc_editfname">
                                <span class="input-group-addon" *ngIf="orgLocationsUpdateForm.get('loc_editfname')?.touched && orgLocationsUpdateForm.get('loc_editfname')?.invalid">
                                    <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                                </span>
                                </div>
                                <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editfname')?.touched && orgLocationsUpdateForm.get('loc_editfname')?.hasError('pattern')">{{'orgLocform.errorOnlyLetters' | cxTranslate}}</div>
                                <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editfname')?.touched && orgLocationsUpdateForm.get('loc_editfname')?.hasError('required')">{{'orgLocform.errorFirstName' | cxTranslate}}</div>
                            
                            </div>
                        </div>
                        <div class="">
                            <div class="col-xs-6 form-group location_lastname"><label class="locations_inputheader">{{'orgLocform.lNamelabel' | cxTranslate}}<sup>*</sup></label>
                                <div class="input-group">
                                <input type="text" id="loc_editlname" name="loc_editlname" class="form-control" formControlName="loc_editlname">
                                <span class="input-group-addon" *ngIf="orgLocationsUpdateForm.get('loc_editlname')?.touched && orgLocationsUpdateForm.get('loc_editlname')?.invalid">
                                    <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                                </span>
                                </div>
                                <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editlname')?.touched && orgLocationsUpdateForm.get('loc_editlname')?.hasError('pattern')">{{'orgLocform.errorOnlyLetters' | cxTranslate}}</div>
                                <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editlname')?.touched &&orgLocationsUpdateForm.get('loc_editlname')?.hasError('required')">{{'orgLocform.errorLastName' | cxTranslate}}</div>
                            </div>
                        </div>
                            
                        <div class="">
                            <div class="col-xs-12 form-group"><label class="locations_inputheader">{{'orgLocform.emailAddr' | cxTranslate}}<sup>*</sup></label>
                                <div class="input-group">
                                <input type="text" id="loc_editemail" name="loc_editemail" class="form-control" formControlName="loc_editemail">
                                <span class="input-group-addon" *ngIf="orgLocationsUpdateForm.get('loc_editemail')?.touched && orgLocationsUpdateForm.get('loc_editemail')?.invalid">
                                    <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                                </span>
                                </div>
                                <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editemail')?.touched && orgLocationsUpdateForm.get('loc_editemail')?.hasError('pattern')">{{'orgLocform.errorValidEmail' | cxTranslate}}</div>
                                <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editemail')?.touched && orgLocationsUpdateForm.get('loc_editemail')?.hasError('required')">{{'orgLocform.errorEmail' | cxTranslate}}</div>
                            
                            </div>
                        </div>
                        
                        <div *ngIf="row.type != 'Shipping'" class="editsubheader_enable" style="display: block;"><div class="col-xs-6 form-group"><label class="locations_inputheader">{{'orgLocform.phnNumLabel' | cxTranslate}}<sup>*</sup></label>
                            <div class="input-group">
                            <input type="text" id="loc_editphone" name="loc_editphone" class="form-control" mask="(000) 000-0000" [showMaskTyped]="showMaskPhone" (mouseentr)="onMouseEnterPhn()" (mouseleave)="onMouseLeavePhn()" formControlName="loc_editphone">
                            <span class="input-group-addon" *ngIf="orgLocationsUpdateForm.get('loc_editphone')?.invalid">
                                <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                            </span>
                            </div>
                            <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editphone')?.hasError('pattern')">{{'orgLocform.errorValidPhn' | cxTranslate}}</div>
                            <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editphone')?.hasError('required')">{{'orgLocform.errorPhnNum' | cxTranslate}}</div>
                            </div>
                        </div>
                        <div *ngIf="row.type != 'Shipping'" class="editsubheader_enable" style="display: block;"><div class="col-xs-4 form-group location_ext"><label class="locations_inputheader">{{'orgLocform.extLabel' | cxTranslate}}</label>
                            <div class="input-group">
                            <input type="text" id="loc_editext" name="loc_editext" class="form-control" formControlName="loc_editext">
                            <span class="input-group-addon" *ngIf="orgLocationsUpdateForm.get('loc_editext')?.touched && orgLocationsUpdateForm.get('loc_editext')?.value && orgLocationsUpdateForm.get('loc_editext')?.invalid">
                                <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                            </span>
                            </div>
                            <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editext')?.touched &&  orgLocationsUpdateForm.get('loc_editext')?.value && orgLocationsUpdateForm.get('loc_editext')?.hasError('maxlength') && !orgLocationsUpdateForm.get('loc_editext')?.hasError('pattern')">{{'orgLocform.errorExt' | cxTranslate}}</div>
                            <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editext')?.touched &&  orgLocationsUpdateForm.get('loc_editext')?.value && orgLocationsUpdateForm.get('loc_editext')?.hasError('maxlength') && orgLocationsUpdateForm.get('loc_editext')?.hasError('pattern')">{{'orgLocform.errorValidExt' | cxTranslate}}</div>
                            <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editext')?.touched &&  orgLocationsUpdateForm.get('loc_editext')?.value && !orgLocationsUpdateForm.get('loc_editext')?.hasError('maxlength') && orgLocationsUpdateForm.get('loc_editext')?.hasError('pattern')">{{'orgLocform.errorValidExt' | cxTranslate}}</div>
                            </div>
                    </div>

                <div class="locations_editlocsubheader col-xs-12">{{'orgLocform.mainAddr' | cxTranslate}}</div><div class="col-xs-12 form-group"><label class="locations_inputheader">{{'orgLocform.orgNameLabel' | cxTranslate}}<sup>*</sup></label>
                    <div class="input-group">
                    <input type="text" id="loc_editorgname" name="loc_editorgname" class="form-control" formControlName="loc_editorgname">
                    <span class="input-group-addon" *ngIf="orgLocationsUpdateForm.get('loc_editorgname')?.touched && orgLocationsUpdateForm.get('loc_editorgname')?.invalid">
                        <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                    </span>
                    </div>
                    <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editorgname')?.touched && orgLocationsUpdateForm.get('loc_editorgname')?.hasError('pattern')">{{'orgLocform.errorValidOrgname' | cxTranslate}}</div>
                    <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editorgname')?.touched && orgLocationsUpdateForm.get('loc_editorgname')?.hasError('required')">{{'orgLocform.errorOrgName' | cxTranslate}}</div>
                    </div>
                
                <div [ngClass]="row.type == 'Shipping'?'locations_optional':'locations_optional test'"><div class="col-xs-12 form-group"><label class="locations_inputheader">{{'orgLocation.glnLabel' | cxTranslate}}<i class="fa-regular fa-circle-question locations_glntool locations_tooltip"><span class="locations_tooltiptext">{{'orgLocation.glnTooltipMessage' | cxTranslate}}<br><br>{{'orgLocation.glnMessage' | cxTranslate}}<a href="/help-and-faq" target='_blank'>{{'orgLocation.tooltipPage' | cxTranslate}}</a>{{'orgLocation.pagetext' | cxTranslate}}</span></i></label>
                    <div class="input-group">
                    <input type="text" id="loc_editglobalLocationNumber" name="loc_editglobalLocationNumber" class="form-control" [mask]="mask" [showMaskTyped]="true" [dropSpecialCharacters]="false" (mouseenter)="applyMask()" (focus)="applyMask()" (blur)="removeEditGlnMask()" (mouseleave)="removeEditGlnMask()" formControlName="loc_editglobalLocationNumber">
                    <span class="input-group-addon" *ngIf="orgLocationsUpdateForm.get('loc_editglobalLocationNumber')?.touched && orgLocationsUpdateForm.get('loc_editglobalLocationNumber')?.value && orgLocationsUpdateForm.get('loc_editglobalLocationNumber')?.invalid">
                        <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                    </span>
                    </div>
                    <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editglobalLocationNumber')?.touched && orgLocationsUpdateForm.get('loc_editglobalLocationNumber')?.value &&  orgLocationsUpdateForm.get('loc_editglobalLocationNumber')?.hasError('invalidPattern')">{{'orgLocform.errorgln' | cxTranslate}}</div>
                </div></div>
                
                <div class="col-xs-12 form-group"><label class="locations_inputheader">{{'orgLocform.addrLookup' | cxTranslate}}</label><i class="fa fa-search loc_editsearchicon"></i><input type="text" id="loc_editaddlookup" name="loc_editaddlookup" value="" formControlName="loc_editaddlookup" class="form-control"></div>
                    <div class="col-xs-12 form-group"><label class="locations_inputheader">{{'orgLocform.addrLine1' | cxTranslate}}<sup>*</sup></label>
                        <div class="input-group">
                        <input type="text" id="loc_editadd1" name="loc_editadd1" class="form-control" formControlName="loc_editadd1">
                        <span class="input-group-addon" *ngIf="orgLocationsUpdateForm.get('loc_editadd1')?.invalid">
                            <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                        </span>
                        </div>
                        <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editadd1')?.hasError('required')">{{'orgLocform.errorAddr' | cxTranslate}}</div>
                     </div>
                     <div class="col-xs-12 form-group"><label class="locations_inputheader">{{'orgLocform.addrLine2' | cxTranslate}}</label><input type="text" id="loc_editadd2" name="loc_editadd2" class="form-control" formControlName="loc_editadd2"></div>
                     <div class="col-xs-5 form-group"><label class="locations_inputheader">{{'orgLocform.city' | cxTranslate}}<sup>*</sup></label>
                        <div class="input-group">
                        <input type="text" id="loc_editcity" name="loc_editcity" class="form-control" formControlName="loc_editcity">
                        <span class="input-group-addon" *ngIf="orgLocationsUpdateForm.get('loc_editcity')?.invalid">
                            <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                        </span>
                        </div>
                        <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editcity')?.hasError('pattern')">{{'orgLocform.errorvalidCity' | cxTranslate}}</div>
                        <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editcity')?.hasError('required')">{{'orgLocform.errorCity' | cxTranslate}}</div>
                        
                    </div>
                    <div class="col-xs-3 form-group"><label class="locations_inputheader">{{'orgLocform.state' | cxTranslate}}<sup>*</sup></label><div class="input-group edit-state"><select id="loc_editstate" name="loc_editstate" class="form-control" formControlName="loc_editstate"><option value="AL">AL</option> <option value="AK">AK</option> <option value="AR">AR</option> <option value="AZ">AZ</option><option value="CA">CA</option> <option value="CO">CO</option> <option value="CT">CT</option> <option value="DE">DE</option> <option value="DC">DC</option> <option value="FL">FL</option> <option value="GA">GA</option> <option value="GU">GU</option> <option value="HI">HI</option> <option value="IA">IA</option> <option value="ID">ID</option> <option value="IL">IL</option> <option value="IN">IN</option> <option value="KS">KS</option> <option value="KY">KY</option> <option value="LA">LA</option> <option value="ME">ME</option> <option value="MD">MD</option> <option value="MA">MA</option> <option value="MI">MI</option> <option value="MN">MN</option> <option value="MS">MS</option> <option value="MO">MO</option> <option value="MT">MT</option> <option value="NC">NC</option> <option value="ND">ND</option> <option value="NE">NE</option> <option value="NV">NV</option> <option value="NH">NH</option> <option value="NJ">NJ</option> <option value="NM">NM</option> <option value="NY">NY</option> <option value="OH">OH</option> <option value="OK">OK</option> <option value="OR">OR</option> <option value="PA">PA</option> <option value="PR">PR</option> <option value="RI">RI</option> <option value="SC">SC</option> <option value="SD">SD</option> <option value="TN">TN</option> <option value="TX">TX</option> <option value="UT">UT</option> <option value="VA">VA</option> <option value="VI">VI</option> <option value="VT">VT</option> <option value="WA">WA</option> <option value="WI">WI</option> <option value="WV">WV</option> <option value="WY">WY</option></select>
                        <span class="input-group-addon" *ngIf="orgLocationsUpdateForm.get('loc_editstate')?.touched && orgLocationsUpdateForm.get('loc_editstate')?.invalid">
                            <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                        </span>
                        </div>
                        <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editstate')?.hasError('required')">{{'orgLocform.errorstate' | cxTranslate}}</div>
                    </div>
                    <div class="col-xs-4 form-group location_zip"><label class="locations_inputheader">{{'orgLocform.zip' | cxTranslate}}<sup>*</sup></label><div class="input-group edit-zip"><input type="text" id="loc_editzip" name="loc_editzip" class="form-control" formControlName="loc_editzip">
                        <span class="input-group-addon" *ngIf="orgLocationsUpdateForm.get('loc_editzip')?.invalid">
                            <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                        </span>
                        </div>
                        <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editzip')?.hasError('pattern')">{{'orgLocform.errorzipcode' | cxTranslate}}</div>
                        <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editzip')?.hasError('required')">{{'orgLocform.errorzipcode' | cxTranslate}}</div>
                    </div>
                
                <div *ngIf="row.type != 'Shipping'" class="location_reference_input"><div class="col-xs-12 form-group padding-B20"><label class="locations_editlocsubheader">{{'orgLocation.commPrefLabel' | cxTranslate}}</label></div><br>
                <div class="col-xs-12">
                    <div *ngIf="row.type === 'Paying'" class="locations_textheader_paying" style="display: block;">{{'orgLocform.accstmntLabel' | cxTranslate}}</div>
                    <div *ngIf="row.type === 'Billing'" class="locations_textheader_billing" style="display: block;">{{'orgLocform.invstmntLabel' | cxTranslate}}</div>
                    <div class="padding-R15 ">
                        <div class="col-xs-6 no-padding-left "><label class="radio-button"><input type="radio" id="loc-comm-preference_E" class=" locations_radio" name="loc_commpreference" formControlName ="loc_commpreference" [value]="1" (change)="onCommPrefChange(row)"><span class="radio-button__control"></span></label><label class="locations_checkboxLabel">{{'orgLocform.emailOnlylabel' | cxTranslate}}<div class="clearboth"></div></label></div>
                        <div class="col-xs-6 no-padding-right "><label class="radio-button"><input type="radio" id="loc-comm-preference_M" class="locations_radio" name="loc_commpreference" formControlName ="loc_commpreference" [value]="0" (change)="onCommPrefChange(row)"><span class="radio-button__control"></span></label><label class="locations_checkboxLabel">{{'orgLocform.mailOnlyLabel' | cxTranslate}}</label><div class="clearboth"></div></div></div>
                </div>
                <div *ngIf="row.type === 'Billing' && (comm_pref || checkValidEmail(row.billingEmail))" class="col-xs-12 form-group" id="locations_input_email"><label class="locations_inputheader ">{{'orgLocform.emailAddrLabel' | cxTranslate}}<sup>*</sup></label>
                    <div class="input-group">
                    <input type="text" name="loc_editinvoiceemail" id="location_editpreference" class="form-control" formControlName="loc_editinvoiceemail">
                    <span class="input-group-addon" *ngIf="orgLocationsUpdateForm.get('loc_editinvoiceemail')?.touched && orgLocationsUpdateForm.get('loc_editinvoiceemail')?.invalid">
                        <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                    </span>
                    </div>
                    <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editinvoiceemail')?.touched && orgLocationsUpdateForm.get('loc_editinvoiceemail')?.hasError('pattern')">{{'orgLocform.errorValidEmail' | cxTranslate}}</div>
                    <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editinvoiceemail')?.touched && orgLocationsUpdateForm.get('loc_editinvoiceemail')?.hasError('required')">{{'orgLocform.errorEmail' | cxTranslate}}</div>
                    
                </div>
                <div *ngIf="row.type === 'Paying' && (comm_pref || checkValidEmail(row.payingEmail))" class="col-xs-12 form-group" id="locations_input_email"><label class="locations_inputheader ">{{'orgLocform.emailAddrLabel' | cxTranslate}}<sup>*</sup></label>
                    <div class="input-group">
                    <input type="text" name="loc_editaccstmtemail" id="location_editpreference" class="form-control" formControlName="loc_editaccstmtemail">
                    <span class="input-group-addon" *ngIf="orgLocationsUpdateForm.get('loc_editaccstmtemail')?.touched && orgLocationsUpdateForm.get('loc_editaccstmtemail')?.invalid">
                        <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                    </span>
                    </div>
                    <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editaccstmtemail')?.touched && orgLocationsUpdateForm.get('loc_editaccstmtemail')?.hasError('pattern')">{{'orgLocform.errorValidEmail' | cxTranslate}}</div>
                    <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editaccstmtemail')?.touched &&  orgLocationsUpdateForm.get('loc_editaccstmtemail')?.hasError('required')">{{'orgLocform.errorEmail' | cxTranslate}}</div>
                    
                </div>
                </div>
            </div>
                
            <div class="col-xs-12 col-md-5" [ngClass]="row.type == 'Shipping'?'locations_optional':'locations_optional test'"><div class="locations_editheader col-xs-12">{{'orgLocation.licInfo' | cxTranslate}}</div> <br>
                    <div class="col-xs-12 form-group"><label class="locations_inputheader">{{'orgLocform.licName' | cxTranslate}}<sup>*</sup></label>
                        <div class="input-group"><input type="text" id="loc_editlicname" name="loc_editlicname" class="form-control" formControlName="loc_editlicname">
                            <span class="input-group-addon" *ngIf="orgLocationsUpdateForm.get('loc_editlicname')?.touched && orgLocationsUpdateForm.get('loc_editlicname')?.invalid">
                                <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                            </span>
                        </div>
                        <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editlicname')?.touched && orgLocationsUpdateForm.get('loc_editlicname')?.hasError('required')">{{'orgLocform.errorLicName' | cxTranslate}}</div>
                        <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editlicname')?.touched && orgLocationsUpdateForm.get('loc_editlicname')?.hasError('pattern')">{{'orgLocform.errorValidLicName' | cxTranslate}}</div>
                     </div>
                    <div class="col-xs-12 form-group"><label class="locations_inputheader">{{'orgLocform.licNum' | cxTranslate}}<sup>*</sup></label><div class="input-group"><input type="text" id="loc_editlicnum" maxlength="30" name="loc_editlicnum" class="form-control" formControlName="loc_editlicnum">
                        <span class="input-group-addon" *ngIf="orgLocationsUpdateForm.get('loc_editlicnum')?.touched && orgLocationsUpdateForm.get('loc_editlicnum')?.invalid">
                            <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                        </span>
                        </div>
                        <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editlicnum')?.touched && orgLocationsUpdateForm.get('loc_editlicnum')?.hasError('required')">{{'orgLocform.errorValidLicNum' | cxTranslate}}</div>
                        <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editlicnum')?.touched && orgLocationsUpdateForm.get('loc_editlicnum')?.hasError('pattern')">{{'orgLocform.errorLicNum' | cxTranslate}}</div>
                    </div>
                <div class="locations_editlocsubheader col-xs-12">{{'orgLocform.mainAddr' | cxTranslate}}</div> <br><div class="col-xs-12 form-group"><label class="locations_inputheader">{{'orgLocform.addrLookup' | cxTranslate}}</label><i class="fa fa-search loc_editsearchicon"></i><input type="text" id="loc_editliclookup" name="loc_editliclookup" value="" class="form-control" formControlName="loc_editliclookup"></div>
                <div class="col-xs-12 form-group"><label class="locations_inputheader">{{'orgLocform.addrLine1' | cxTranslate}}<sup>*</sup></label><div class="input-group"><input type="text" id="loc_editlicadd1" name="loc_editlicadd1" class="form-control" formControlName="loc_editlicadd1">
                    <span class="input-group-addon" *ngIf="orgLocationsUpdateForm.get('loc_editlicadd1')?.invalid">
                        <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                    </span>
                    </div>
                    <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editlicadd1')?.hasError('required')">{{'orgLocform.errorAddr' | cxTranslate}}</div>
                </div>
                <div class="col-xs-12 form-group"><label class="locations_inputheader">{{'orgLocform.addrLine2' | cxTranslate}}</label><input type="text" id="loc_editlicadd2" name="loc_editlicadd2" class="form-control" formControlName="loc_editlicadd2"></div>
                <div class="col-xs-6 form-group"><label class="locations_inputheader">{{'orgLocform.city' | cxTranslate}}<sup>*</sup></label><div class="input-group"><input type="text" id="loc_editliccity" name="loc_editliccity" class="form-control" formControlName="loc_editliccity">
                    <span class="input-group-addon" *ngIf="orgLocationsUpdateForm.get('loc_editliccity')?.invalid">
                        <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                    </span>
                    </div>
                    <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editliccity')?.hasError('pattern')">{{'orgLocform.errorvalidCity' | cxTranslate}}</div>
                    <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editliccity')?.hasError('required')">{{'orgLocform.errorCity' | cxTranslate}}</div>
                 </div>
                 <div class="col-xs-3 form-group"><label class="locations_inputheader">{{'orgLocform.state' | cxTranslate}}<sup>*</sup></label><div class="input-group"><select id="loc_editlicstate" name="loc_editlicstate" class="form-control" formControlName="loc_editlicstate"><option value="AL">AL</option> <option value="AK">AK</option> <option value="AR">AR</option> <option value="AZ">AZ</option><option value="CA">CA</option> <option value="CO">CO</option> <option value="CT">CT</option> <option value="DE">DE</option> <option value="DC">DC</option> <option value="FL">FL</option> <option value="GA">GA</option> <option value="GU">GU</option> <option value="HI">HI</option> <option value="IA">IA</option> <option value="ID">ID</option> <option value="IL">IL</option> <option value="IN">IN</option> <option value="KS">KS</option> <option value="KY">KY</option> <option value="LA">LA</option> <option value="ME">ME</option> <option value="MD">MD</option> <option value="MA">MA</option> <option value="MI">MI</option> <option value="MN">MN</option> <option value="MS">MS</option> <option value="MO">MO</option> <option value="MT">MT</option> <option value="NC">NC</option> <option value="ND">ND</option> <option value="NE">NE</option> <option value="NV">NV</option> <option value="NH">NH</option> <option value="NJ">NJ</option> <option value="NM">NM</option> <option value="NY">NY</option> <option value="OH">OH</option> <option value="OK">OK</option> <option value="OR">OR</option> <option value="PA">PA</option> <option value="PR">PR</option> <option value="RI">RI</option> <option value="SC">SC</option> <option value="SD">SD</option> <option value="TN">TN</option> <option value="TX">TX</option> <option value="UT">UT</option> <option value="VA">VA</option> <option value="VI">VI</option> <option value="VT">VT</option> <option value="WA">WA</option> <option value="WI">WI</option> <option value="WV">WV</option> <option value="WY">WY</option></select>
                    <span class="input-group-addon" *ngIf="orgLocationsUpdateForm.get('loc_editlicstate')?.invalid">
                        <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                    </span>
                    </div>
                    <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editlicstate')?.hasError('required')">{{'orgLocform.errorLicState' | cxTranslate}}</div>
                </div>
                <div class="col-xs-3 form-group location_zip"><label class="locations_inputheader">{{'orgLocform.zip' | cxTranslate}}<sup>*</sup></label><div class="input-group"><input type="text" id="loc_editliczip" maxlength="5" name="loc_editliczip" class="form-control" formControlName="loc_editliczip">
                    <span class="input-group-addon" *ngIf="orgLocationsUpdateForm.get('loc_editliczip')?.touched && orgLocationsUpdateForm.get('loc_editliczip')?.invalid">
                        <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                    </span>
                    </div>
                    <div class="error help-block" *ngIf="orgLocationsUpdateForm.get('loc_editliczip')?.touched && (orgLocationsUpdateForm.get('loc_editliczip')?.hasError('pattern') || orgLocationsUpdateForm.get('loc_editliczip')?.hasError('required'))">{{'orgLocform.errorzipcode' | cxTranslate}}</div>
                    </div>
            </div>
            </div>
            </div>
                   
            </form>
       
        </ng-template>
        
            </td>
            </ng-container>
        </tr>
    </div>
    

    </table>
    
    
    <div [hidden]="isTableHasData" class="center-message dataTables_empty">
        No matching records found
    </div>
    <div [hidden]="tableDataLoaded" class="center-message dataTables_empty">
        Loading...
    </div>
     
    <div class="dataTables_length" id="locationstable_length"><label>Showing <select name="locationstable_length" (change)="onPageSizeChange($event)" [value]="pageSizeControl.value">
        <option *ngFor="let size of pageSizeOptions" [value]="size">{{size}}</option>
    </select></label></div>
    <div [ngClass]="isRowClicked?'hide-pagination':''" class="custom-paginator dataTables_paginate paging_full_numbers" id="locationstable_paginate">
        <button class="pagination-buttonOrg paginate_button" (click)="goToFirstPage()" [disabled]="isFirstPage()">
            <img alt="Navigate to previous page" src="assets/images/pagination-double-prev-arrow-icon.svg" class="locations_arrows">
          </button>
          <button class="pagination-buttonOrg paginate_button" (click)="previousPage()" [disabled]="isFirstPage()">
            <img alt="Navigate to previous page" src="assets/images/pagination-prev-btn.svg" class="locations_arrows">
          </button>
          <button [hidden]="!isTableHasData" class="pagination-buttonOrg paginate_button" *ngFor="let pge of getPages()" [class.active-page]="isCurrentPage(pge)" (click)="goToPage(pge)">
            {{pge}}
          </button>
          <button class="pagination-buttonOrg paginate_button" (click)="nextPage()" [disabled]="isLastPage()">
            <img alt="Navigate to next page" src="assets/images/pagination-next-btn.svg" class="locations_arrows">
          </button>
          <button class="pagination-buttonOrg paginate_button" (click)="goToLastPage()" [disabled]="isLastPage()">
            <img alt="Navigate to last page" src="assets/images/pagination-double-next-arrow-icon.svg" class="locations_arrows">
          </button>
        
    </div>
    </div>
    </div>
    </ng-container>
    <ng-template #ukorgLoc>
        <app-org-location-uk [cc]="this.cc"></app-org-location-uk>
    </ng-template>

    <ng-container *ngIf="orgrba?.RBAPermissions==true;else normalorg">

        <ng-container *ngIf="orgrba?.currentUserAccountPermission=='MANAGE'">
    <div class="col-xs-12 locations_addsection" *ngIf="orgLocData | async as data">
        <div class="col-xs-12 locations_leftrightmargin">
            <div class="col-xs-12 col-md-9">
                <div *ngIf="currentSite == 'seqirusb2b-flu360'" class="col-xs-12 locations_addheader">{{'orgLocation.newLoctitle' | cxTranslate}}</div>
                <div *ngIf="currentSite == 'seqirusb2b-uk'"class="col-xs-12 locations_addheader">{{'orgLocation.newLoctitleuk' | cxTranslate}}</div>
                
                <div class="col-xs-12 locations_addsubheader" [innerHTML]="safeContent"></div>
                
            </div>
            <div class="col-xs-12 col-md-3 locations_addsection">
                <div (click)="openModal()" class="locations_addbtn" data-toggle="modal" data-target="#locations_addmodal">{{'orgLocation.createLoctitle' | cxTranslate}}</div>
            </div>	
        </div>
    </div>
    </ng-container>
    </ng-container>
    <ng-template #normalorg>
        <div class="col-xs-12 locations_addsection" *ngIf="orgLocData | async as data">
            <div class="col-xs-12 locations_leftrightmargin">
                <div class="col-xs-12 col-md-9">
                    <div *ngIf="currentSite == 'seqirusb2b-flu360'" class="col-xs-12 locations_addheader">{{'orgLocation.newLoctitle' | cxTranslate}}</div>
                    <div *ngIf="currentSite == 'seqirusb2b-uk'"class="col-xs-12 locations_addheader">{{'orgLocation.newLoctitleuk' | cxTranslate}}</div>
                    
                    <div class="col-xs-12 locations_addsubheader" [innerHTML]="safeContent"></div>
                    
                </div>
                <div class="col-xs-12 col-md-3 locations_addsection">
                    <div (click)="openModal()" class="locations_addbtn" data-toggle="modal" data-target="#locations_addmodal">{{'orgLocation.createLoctitle' | cxTranslate}}</div>
                </div>	
            </div>
        </div>

    </ng-template>

</div>


<div  id="locations_addmodal" class="modal fade" role="dialog" [ngClass]="addLocModal?'show':'hide'">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body" *ngIf="orgLocData | async as data">
                    <h4 class="modal-title locations_addlocheader col-xs-10 col-md-6">{{'orgLocation.addlocationtitle' | cxTranslate}}</h4>
                        <div class="col-xs-2 col-md-6 locations_closebtnParent"><span class="locations_closebtn" data-dismiss="modal">&times;</span></div>
                        
                        <p class="locations_subheader col-xs-12" [innerHTML]="data.bottomContent"></p>
                        
                        <div class="clearfix"></div>
                        <ng-container *ngIf="currentSite == 'seqirusb2b-flu360';else ukaddLocForm">
                        <form class="locations_addform" id="locations_addform" (ngSubmit)="validateLicense()" [formGroup]="addShippingLocForm">
                            <div class="locations_addhead">{{'orgLocation.shippingcontact' | cxTranslate}}
                                <i class="fa-regular fa-circle-question locations_qm locations_tooltip"><span class="locations_tooltiptext">
                                    {{'orgLocation.shipLoctooltip' | cxTranslate}}</span>
                                </i>
                                </div>
                            <div class="row">
                                
                                <div class="col-xs-12 col-md-6 form-group">
                                    <label for="loc_firstname">{{'orgLocform.shipFname' | cxTranslate}}</label>
                                    <div class="input-group">
                                        <input class="form-control" id="loc_firstname" name="loc_firstname"  formControlName="loc_firstname"/>
                                        <span class="input-group-addon" *ngIf=" addShippingLocForm.get('loc_firstname')?.touched &&  addShippingLocForm.get('loc_firstname')?.invalid">
                                            <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                                        </span>
                                    </div>
                                    <div class="error help-block" *ngIf="addShippingLocForm.get('loc_firstname') && addShippingLocForm.get('loc_firstname')?.touched && addShippingLocForm.get('loc_firstname')?.hasError('pattern')">{{'orgLocform.errorOnlyLetters' | cxTranslate}}</div>
                                    <div class="error help-block" *ngIf="addShippingLocForm.get('loc_firstname') && addShippingLocForm.get('loc_firstname')?.touched && addShippingLocForm.get('loc_firstname')?.hasError('required')">{{'orgLocform.errorFirstName' | cxTranslate}}</div>
                                </div>
                                <div class="col-xs-12 col-md-6 form-group">
                                    <label for="loc_lastname">{{'orgLocform.shipLname' | cxTranslate}}</label>
                                    <div class="input-group">
                                        <input class="form-control" id="loc_lastname" name="loc_lastname" formControlName="loc_lastname"/>
                                        <span class="input-group-addon" *ngIf=" addShippingLocForm.get('loc_lastname')?.touched && addShippingLocForm.get('loc_lastname')?.invalid">
                                            <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                                        </span>
                                    </div>
                                    <div class="error help-block" *ngIf="addShippingLocForm.get('loc_lastname')?.touched && addShippingLocForm.get('loc_lastname')?.hasError('pattern')">{{'orgLocform.errorOnlyLetters' | cxTranslate}}</div>
                                    <div class="error help-block" *ngIf="addShippingLocForm.get('loc_lastname')?.touched && addShippingLocForm.get('loc_lastname')?.hasError('required')">{{'orgLocform.errorLastName' | cxTranslate}}</div>
                                </div>
                                <div class="col-xs-12 col-md-6 form-group">
                                    <label for="loc_email">{{'orgLocform.shipemail' | cxTranslate}}</label>
                                    <div class="input-group">
                                        <input class="form-control" id="loc_email" name="loc_email" [ngClass]="{'is-invalid': addShippingLocForm.get('loc_email')?.invalid}" formControlName="loc_email"/>
                                        <span class="input-group-addon" *ngIf=" addShippingLocForm.get('loc_email')?.touched &&  addShippingLocForm.get('loc_email')?.invalid">
                                            <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                                        </span>
                                    </div>
                                    <div class="error help-block" *ngIf="addShippingLocForm.get('loc_email')?.touched && addShippingLocForm.get('loc_email')?.hasError('pattern')">{{'orgLocform.errorValidEmail' | cxTranslate}}</div>
                                    <div class="error help-block" *ngIf="addShippingLocForm.get('loc_email')?.touched && addShippingLocForm.get('loc_email')?.hasError('required')">{{'orgLocform.errorEmail' | cxTranslate}}</div>
                                </div>
                                <div class="col-xs-12 col-md-6 form-group">
                                    <label for="loc_job">{{'orgLocform.job' | cxTranslate}}</label>
                                    <select id="loc_job" name="loc_job" formControlName="loc_job" class="form-control"><option value="Select">Select</option><option value="Office Manager/Administrator">Office Manager/Administrator</option><option value="Pharmacy Manager">Pharmacy Manager</option><option value="Procurement/Purchasing">Procurement/Purchasing</option><option value="Medical Practitioner">Medical Practitioner</option></select>
                                </div>
                                <div class="col-xs-7 col-md-6 form-group">
                                    <label for="loc_phone">{{'orgLocform.shipPhn' | cxTranslate}}</label>
                                    <div class="input-group">
                                        <input class="form-control" id="loc_phone" name="loc_phone" [ngClass]="{'is-invalid': addShippingLocForm.get('loc_phone')?.invalid}" mask="(000) 000-0000" [showMaskTyped]="showMaskPhone" (mouseenter)="onMouseEnterPhn()" (mouseleave)="onMouseLeavePhn()" formControlName="loc_phone"/>
                                        <span class="input-group-addon" *ngIf="addShippingLocForm.get('loc_phone')?.touched &&  addShippingLocForm.get('loc_phone')?.invalid">
                                            <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                                        </span>
                                    </div>
                                    <div class="error help-block" *ngIf="addShippingLocForm.get('loc_phone')?.touched && addShippingLocForm.get('loc_phone')?.hasError('pattern')">{{'orgLocform.errorValidPhn' | cxTranslate}}</div>
                                    <div class="error help-block" *ngIf="addShippingLocForm.get('loc_phone')?.touched && addShippingLocForm.get('loc_phone')?.hasError('required')">{{'orgLocform.errorPhnNum' | cxTranslate}}</div>
                                </div>
                                <div class="col-xs-5 col-md-3 form-group">
                                    <label for="loc_ext">{{'orgLocform.shipExt' | cxTranslate}}</label>
                                    <div class="input-group">
                                        <input class="form-control" id="loc_ext" name="loc_ext" [ngClass]="{'is-invalid': addShippingLocForm.get('loc_ext')?.invalid}" formControlName="loc_ext"/>
                                        <span class="input-group-addon" *ngIf=" addShippingLocForm.get('loc_ext')?.invalid">
                                            <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                                        </span>
                                    </div>
                                    <div class="error help-block" *ngIf="addShippingLocForm.get('loc_ext')?.touched && !addShippingLocForm.get('loc_ext')?.hasError('pattern') && addShippingLocForm.get('loc_ext')?.hasError('maxlength')">{{'orgLocform.errorExt' | cxTranslate}}</div>
                                    <div class="error help-block" *ngIf="addShippingLocForm.get('loc_ext')?.touched  && addShippingLocForm.get('loc_ext')?.hasError('pattern')">{{'orgLocform.errorValidExt' | cxTranslate}}</div>
                                </div>
                            </div>
                            <div class="locations_addhead">{{'orgLocform.shipAddr' | cxTranslate}}</div>
                            <div class="row">
                                <div class="col-xs-12 col-md-6 form-group"><label for="loc_orgname">{{'orgLocform.shipOrgName' | cxTranslate}}</label><div class="input-group"><input type="text" id="loc_orgname" name="loc_orgname" class="form-control" formControlName="loc_orgname"/>
                                    <span class="input-group-addon" *ngIf="addShippingLocForm.get('loc_orgname')?.touched && addShippingLocForm.get('loc_orgname')?.invalid">
                                        <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                                    </span> 
                                    </div>
                                    <div class="error help-block" *ngIf="addShippingLocForm.get('loc_orgname')?.touched && addShippingLocForm.get('loc_orgname')?.hasError('pattern')">{{'orgLocform.errorValidOrgname' | cxTranslate}}</div>
                                    <div class="error help-block" *ngIf="addShippingLocForm.get('loc_orgname')?.touched && addShippingLocForm.get('loc_orgname')?.hasError('required')">{{'orgLocform.errorOrgName' | cxTranslate}}</div>
                                </div>
                                <div class="col-xs-12 col-md-6 form-group"><label for="loc_glnnumber">{{'orgLocation.glnLabel' | cxTranslate}} <i class="fa-regular fa-circle-question locations_glntool locations_tooltip"><span class="locations_tooltiptext">{{'orgLocation.glnTooltipMessage' | cxTranslate}}<br><br>{{'orgLocation.glnMessage' | cxTranslate}}<a href="/help-and-faq" target='_blank'>{{'orgLocation.tooltipPage' | cxTranslate}}</a>{{'orgLocation.pagetext' | cxTranslate}}</span></i></label><div class="input-group"><input type="text" id="loc_glnnumber" name="loc_glnnumber" [mask]="mask" [showMaskTyped]="true" [dropSpecialCharacters]="false" formControlName="loc_glnnumber" (focus)="applyMask()" (blur)="removeMask()" (mouseenter)="applyMask()" (mouseleave)="removeMask()" class="form-control"/>
                                    <span class="input-group-addon" *ngIf="addShippingLocForm.get('loc_glnnumber')?.value && addShippingLocForm.get('loc_glnnumber')?.invalid">
                                        <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                                    </span>
                                    </div>
                                    <div class="error help-block" *ngIf="addShippingLocForm.get('loc_glnnumber') && addShippingLocForm.get('loc_glnnumber')?.hasError('invalidPattern')">{{'orgLocform.errorgln' | cxTranslate}}</div>
                                </div>
                                <div class="col-xs-12"><label for="loc_addlp">{{'orgLocform.shipAddrLookup' | cxTranslate}}</label><i class="fa fa-search loc_addsearchicon"></i><input type="text" id="add_AddressLookup" name="add_AddressLookup" formControlName="add_AddressLookup"/></div>
                                <div class="col-xs-12 col-md-6 form-group"><label for="loc_add1">{{'orgLocform.shipAddrLine1' | cxTranslate}}</label><div class="input-group"><input type="text" id="loc_add1" name="loc_add1" formControlName="loc_add1" class="form-control" />
                                    <span class="input-group-addon" *ngIf="addShippingLocForm.get('loc_add1')?.touched && addShippingLocForm.get('loc_add1')?.invalid">
                                        <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                                    </span>
                                    </div>
                                    <div class="error help-block" *ngIf="addShippingLocForm.get('loc_add1')?.touched && addShippingLocForm.get('loc_add1')?.hasError('required')">{{'orgLocform.errorAddr' | cxTranslate}}</div>
                                </div>
                                <div class="col-xs-12 col-md-6 form-group"><label for="loc_add2">{{'orgLocform.shipAddrLine2' | cxTranslate}}</label><div class="input-group"><input type="text" id="loc_add2" name="loc_add2" formControlName="loc_add2" class="form-control"/></div></div>
                                <div class="col-xs-7 col-md-6 form-group"><label for="loc_city">{{'orgLocform.shipCity' | cxTranslate}}</label><div class="input-group"><input type="text" id="loc_city" name="loc_city" formControlName="loc_city" class="form-control"/>
                                    <span class="input-group-addon" *ngIf="addShippingLocForm.get('loc_city')?.touched && addShippingLocForm.get('loc_city')?.invalid">
                                        <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                                    </span>
                                    </div>
                                    <div class="error help-block" *ngIf="addShippingLocForm.get('loc_city')?.touched && addShippingLocForm.get('loc_city')?.hasError('pattern')">{{'orgLocform.errorvalidCity' | cxTranslate}}</div>
                                    <div class="error help-block" *ngIf="addShippingLocForm.get('loc_city')?.touched && addShippingLocForm.get('loc_city')?.hasError('required')">{{'orgLocform.errorCity' | cxTranslate}}</div>
                                </div>
                                <div class="col-xs-5 col-md-3 form-group"><label for="loc_state">{{'orgLocform.shipState' | cxTranslate}}</label><div class="input-group"><select id="loc_state" name="loc_state" formControlName="loc_state" class="form-control"><option value="" selected="selected"></option>
                                        <option value="AL"> AL </option>
                                        <option value="AK"> AK </option>
                                        <option value="AR"> AR </option>
                                        <option value="AZ"> AZ </option>
                                        <option value="CA"> CA </option>
                                        <option value="CO"> CO </option>
                                        <option value="CT"> CT </option>
                                        <option value="DE"> DE </option>
                                        <option value="DC"> DC </option>
                                        <option value="FL"> FL </option>
                                        <option value="GA"> GA </option>
                                        <option value="GU"> GU </option>
                                        <option value="HI"> HI </option>
                                        <option value="IA"> IA </option>
                                        <option value="ID"> ID </option>
                                        <option value="IL"> IL </option>
                                        <option value="IN"> IN </option>
                                        <option value="KS"> KS </option>
                                        <option value="KY"> KY </option>
                                        <option value="LA"> LA </option>
                                        <option value="ME"> ME </option>
                                        <option value="MD"> MD </option>
                                        <option value="MA"> MA </option>
                                        <option value="MI"> MI </option>
                                        <option value="MN"> MN </option>
                                        <option value="MS"> MS </option>
                                        <option value="MO"> MO </option>
                                        <option value="MT"> MT </option>
                                        <option value="NC"> NC </option>
                                        <option value="ND"> ND </option>
                                        <option value="NE"> NE </option>
                                        <option value="NV"> NV </option>
                                        <option value="NH"> NH </option>
                                        <option value="NJ"> NJ </option>
                                        <option value="NM"> NM </option>
                                        <option value="NY"> NY </option>
                                        <option value="OH"> OH </option>
                                        <option value="OK"> OK </option>
                                        <option value="OR"> OR </option>
                                        <option value="PA"> PA </option>
                                        <option value="PR"> PR </option>
                                        <option value="RI"> RI </option>
                                        <option value="SC"> SC </option>
                                        <option value="SD"> SD </option>
                                        <option value="TN"> TN </option>
                                        <option value="TX"> TX </option>
                                        <option value="UT"> UT </option>
                                        <option value="VA"> VA </option>
                                        <option value="VI"> VI </option>
                                        <option value="VT"> VT </option>
                                        <option value="WA"> WA </option>
                                        <option value="WI"> WI </option>
                                        <option value="WV"> WV </option>
                                        <option value="WY"> WY </option>
                                    </select>
                                    <span class="input-group-addon" *ngIf="addShippingLocForm.get('loc_state')?.touched && addShippingLocForm.get('loc_state')?.invalid">
                                        <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                                    </span>
                                    </div>
                                    <div class="error help-block" *ngIf="addShippingLocForm.get('loc_state')?.touched && addShippingLocForm.get('loc_state')?.hasError('required')">{{'orgLocform.errorLicState' | cxTranslate}}</div>
                                </div>
                                <div class="col-xs-7 col-md-3 form-group"><label for="loc_zip">{{'orgLocform.shipZip' | cxTranslate}}</label><div class="input-group"><input type="text" id="loc_zip" name="loc_zip" class="form-control" formControlName="loc_zip"/>
                                    <span class="input-group-addon" *ngIf="addShippingLocForm.get('loc_zip')?.touched && addShippingLocForm.get('loc_zip')?.invalid">
                                        <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                                    </span>
                                    </div>
                                    <div class="error help-block" *ngIf="addShippingLocForm.get('loc_zip')?.touched && addShippingLocForm.get('loc_zip')?.hasError('pattern')">{{'orgLocform.errorzipcode' | cxTranslate}}</div>
                                    <div class="error help-block" *ngIf="addShippingLocForm.get('loc_zip')?.touched && addShippingLocForm.get('loc_zip')?.hasError('required')">{{'orgLocform.errorzipcode' | cxTranslate}}</div>
                                </div>					
                            </div>
                            <div class="locations_addhead">{{'orgLocation.licInfo' | cxTranslate}}</div>
                            <div class="row">
                                <div class="col-xs-12 col-md-6 form-group"><label for="loc_licname">{{'orgLocform.shipLicName' | cxTranslate}}</label><div class="input-group"><input type="text" id="loc_licname" name="loc_licname" formControlName="loc_licname" class="form-control"/>
                                    <span class="input-group-addon" *ngIf="addShippingLocForm.get('loc_licname')?.touched && addShippingLocForm.get('loc_licname')?.invalid">
                                        <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                                    </span>
                                </div>
                                <div class="error help-block" *ngIf="addShippingLocForm.get('loc_licname')?.touched && addShippingLocForm.get('loc_licname')?.hasError('pattern')">{{'orgLocform.errorValidLicName' | cxTranslate}}</div>
                                <div class="error help-block" *ngIf="addShippingLocForm.get('loc_licname')?.touched && addShippingLocForm.get('loc_licname')?.hasError('required')">{{'orgLocform.errorValidLicName' | cxTranslate}}</div>
                             
                                </div>
                                <div class="col-xs-12 col-md-6 form-group"><label for="loc_licnum">{{'orgLocform.shipLicNum' | cxTranslate}}</label><div class="input-group"><input type="text" id="loc_licnum" maxlength="30" name="loc_licnum" formControlName="loc_licnum" class="form-control"/>
                                    <span class="input-group-addon" *ngIf="addShippingLocForm.get('loc_licnum')?.touched && addShippingLocForm.get('loc_licnum')?.invalid">
                                        <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                                    </span>
                                    </div>
                                    <div class="error help-block" *ngIf="addShippingLocForm.get('loc_licnum')?.touched && addShippingLocForm.get('loc_licnum')?.hasError('pattern')">{{'orgLocform.errorLicNum' | cxTranslate}}</div>
                                    <div class="error help-block" *ngIf="addShippingLocForm.get('loc_licnum')?.touched && addShippingLocForm.get('loc_licnum')?.hasError('required')">{{'orgLocform.errorValidLicNum' | cxTranslate}}</div>
                                
                                </div>
                                <div class="col-xs-12 locations_checkbox"><label class="checkbox-button col-xs-12"><input type="checkbox" class="checkbox-button__input" (change)="sameAsShippingCheckBox($event)"><span class="checkbox-button__control"></span><span class="settings_checktext">{{'orgLocation.sameAddressText' | cxTranslate}}</span></label></div>
                                <div class="col-xs-12 form-group"><label for="loc_liclookup">{{'orgLocform.shipAddrLookup' | cxTranslate}}</label><i class="fa fa-search loc_addsearchicon"></i><input type="text" id="loc_liclookup" name="locliclookup" formControlName="loc_liclookup" class="form-control"/></div>
                                <div class="col-xs-12 col-md-6 form-group"><label for="loc_licadd1">{{'orgLocform.shipAddrLine1' | cxTranslate}}</label><div class="input-group"><input type="text" id="loc_licadd1" name="loc_licadd1" formControlName="loc_licadd1" class="form-control"/>
                                    <span class="input-group-addon" *ngIf="addShippingLocForm.get('loc_licadd1')?.touched && addShippingLocForm.get('loc_licadd1')?.invalid">
                                        <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                                    </span>
                                    </div>
                                    <div class="error help-block" *ngIf="addShippingLocForm.get('loc_licadd1')?.touched && addShippingLocForm.get('loc_licadd1')?.hasError('required')">{{'orgLocform.errorAddr' | cxTranslate}}</div>
                                
                                </div>
                                <div class="col-xs-12 col-md-6 form-group"><label for="loc_licadd2">{{'orgLocform.shipAddrLine2' | cxTranslate}}</label><div class="input-group"><input type="text" id="loc_licadd2" name="loc_licadd2" formControlName="loc_licadd2" class="form-control"/></div></div>
                                <div class="col-xs-7 col-md-6 form-group"><label for="loc_liccity">{{'orgLocform.shipCity' | cxTranslate}}</label><div class="input-group"><input type="text" id="loc_liccity" name="loc_liccity" formControlName="loc_liccity" class="form-control"/>
                                    <span class="input-group-addon" *ngIf="addShippingLocForm.get('loc_liccity')?.touched && addShippingLocForm.get('loc_liccity')?.invalid">
                                        <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                                    </span>
                                    </div>
                                    <div class="error help-block" *ngIf="addShippingLocForm.get('loc_liccity')?.touched && addShippingLocForm.get('loc_liccity')?.hasError('pattern')">{{'orgLocform.errorvalidCity' | cxTranslate}}</div>
                                    <div class="error help-block" *ngIf="addShippingLocForm.get('loc_liccity')?.touched && addShippingLocForm.get('loc_liccity')?.hasError('required')">{{'orgLocform.errorLicCity' | cxTranslate}}</div>
                                </div>
                                <div class="col-xs-5 col-md-3 form-group"><label for="loc_licstate">{{'orgLocform.shipState' | cxTranslate}}</label><div class="input-group"><select id="loc_licstate" formControlName="loc_licstate" name="loc_licstate" class="form-control"><option value="" selected="selected"></option>
                                        <option value="AL"> AL </option>
                                        <option value="AK"> AK </option>
                                        <option value="AR"> AR </option>
                                        <option value="AZ"> AZ </option>
                                        <option value="CA"> CA </option>
                                        <option value="CO"> CO </option>
                                        <option value="CT"> CT </option>
                                        <option value="DE"> DE </option>
                                        <option value="DC"> DC </option>
                                        <option value="FL"> FL </option>
                                        <option value="GA"> GA </option>
                                        <option value="GU"> GU </option>
                                        <option value="HI"> HI </option>
                                        <option value="IA"> IA </option>
                                        <option value="ID"> ID </option>
                                        <option value="IL"> IL </option>
                                        <option value="IN"> IN </option>
                                        <option value="KS"> KS </option>
                                        <option value="KY"> KY </option>
                                        <option value="LA"> LA </option>
                                        <option value="ME"> ME </option>
                                        <option value="MD"> MD </option>
                                        <option value="MA"> MA </option>
                                        <option value="MI"> MI </option>
                                        <option value="MN"> MN </option>
                                        <option value="MS"> MS </option>
                                        <option value="MO"> MO </option>
                                        <option value="MT"> MT </option>
                                        <option value="NC"> NC </option>
                                        <option value="ND"> ND </option>
                                        <option value="NE"> NE </option>
                                        <option value="NV"> NV </option>
                                        <option value="NH"> NH </option>
                                        <option value="NJ"> NJ </option>
                                        <option value="NM"> NM </option>
                                        <option value="NY"> NY </option>
                                        <option value="OH"> OH </option>
                                        <option value="OK"> OK </option>
                                        <option value="OR"> OR </option>
                                        <option value="PA"> PA </option>
                                        <option value="PR"> PR </option>
                                        <option value="RI"> RI </option>
                                        <option value="SC"> SC </option>
                                        <option value="SD"> SD </option>
                                        <option value="TN"> TN </option>
                                        <option value="TX"> TX </option>
                                        <option value="UT"> UT </option>
                                        <option value="VA"> VA </option>
                                        <option value="VI"> VI </option>
                                        <option value="VT"> VT </option>
                                        <option value="WA"> WA </option>
                                        <option value="WI"> WI </option>
                                        <option value="WV"> WV </option>
                                        <option value="WY"> WY </option>
                                    </select>
                                    <span class="input-group-addon" *ngIf="addShippingLocForm.get('loc_licstate')?.touched && addShippingLocForm.get('loc_licstate')?.invalid">
                                        <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                                    </span>
                                    </div>
                                    <div class="error help-block" *ngIf="addShippingLocForm.get('loc_licstate')?.touched && addShippingLocForm.get('loc_licstate')?.hasError('required')">{{'orgLocform.errorLicState' | cxTranslate}}</div>
                                </div>
                                <div class="col-xs-7 col-md-3 form-group"><label for="loc_liczip">{{'orgLocform.shipZip' | cxTranslate}}</label><div class="input-group"><input type="text" id="loc_liczip" formControlName="loc_liczip" maxlength="5" name="loc_liczip" class="form-control"/>
                                    <span class="input-group-addon" *ngIf="addShippingLocForm.get('loc_liczip')?.touched && addShippingLocForm.get('loc_liczip')?.invalid">
                                        <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                                    </span>
                                    </div>
                                    <div class="error help-block" *ngIf="addShippingLocForm.get('loc_liczip')?.touched && addShippingLocForm.get('loc_liczip')?.hasError('pattern')">{{'orgLocform.errorzipcode' | cxTranslate}}</div>
                                    <div class="error help-block" *ngIf="addShippingLocForm.get('loc_liczip')?.touched && addShippingLocForm.get('loc_liczip')?.hasError('required')">{{'orgLocform.errorzipcode' | cxTranslate}}</div>
                                </div>					
                            </div>

                            <div class="row locations_addlocsection">
                                <button type="submit" class="locations_addloc">{{'orgLocation.reqLocLabel' | cxTranslate}}</button>
                            </div>

                        </form>
                    </ng-container>
                    <ng-template #ukaddLocForm>
                        <app-new-location-uk [cc_obj]="this.cc" [businessType]="businessType" (showThankyou)="showThankyouModal($event)" (closeModal)="closeAddLocModal()"></app-new-location-uk>
                    </ng-template>
                </div>
            </div>
        </div>
</div>

<!--Thank you Modal-->

<ng-template #showThankYouModal>
<div id="locations_tymodal">
    <div class="mat-dialog">
        <!-- Modal content-->
        <div class="mat-dialog-content" *ngIf="orgLocData | async as data">
            <div class="modal-body row" mat-dialog-actions>
                
                <h4 class="modal-title locations_tyheader col-xs-9">{{'orgLocation.thankyouMessage' | cxTranslate}}</h4>
                <div class="col-xs-3" mat-dialog-close><span class="locations_closebtn" (click)= "thankYouClose()" data-dismiss="modal">&times;</span></div>
                <p class="locations_tysubheader col-xs-12" [innerHTML]="data.thankYouNote"></p>
                
            </div>
        </div>
    </div>
</div>
</ng-template>
<!--Thank you modal end-->

<div id="locations_tymodaluk" class="modal fade in" role="dialog">
    <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content" *ngIf="orgLocData | async as data">
            <div class="modal-body row">
                
                <h4 class="modal-title locations_tyheader col-xs-9">{{'orgLocation.thankyouMessage' | cxTranslate}}</h4>
                <div class="col-xs-3"><span class="locations_closebtn" (click)= "thankYouClose()" data-dismiss="modal">&times;</span></div>
                <p class="locations_tysubheader col-xs-12" [innerHTML]="data.thankYouNote"></p>
                
            </div>
        </div>
    </div>
</div>


<ng-container *ngIf="showSavelicModal">
<div id="locations_savelicmodal" class="modal fade show" role="dialog">
    <div class="modal-dialog">
        
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-body row">
                <ng-container *ngIf="connectionSuccess;else connectionError">

                    <h4 class="modal-title locations_savelicheader col-xs-9">{{'orgLocation.licValidationtitle' | cxTranslate}}</h4>
                    <div class="col-xs-3"><span class="locations_closebtn" (click)="modifyForm()" data-dismiss="modal">&times;</span></div>
                    <p class="locations_savelicsubheader col-xs-12">
                        {{'orgLocation.licValidationMsg' | cxTranslate}}
                    </p>
                    <div class="locations_savelicbuttons">
                        <button class="save_modifybtn global_redbtn" data-dismiss="modal" (click)="modifyForm()">{{'orgLocation.modifyLabel' | cxTranslate}}</button>
                        <button class="save_continuebtn global_greybtn" (click)="submitUpdateLocForm()">{{'orgLocation.continueLabel' | cxTranslate}}</button>
                    </div>
                </ng-container>
                <ng-template #connectionError>
                    <h4 class="modal-title locations_savelicheader col-xs-9">{{'orgLocation.licValidationErrorTitle' | cxTranslate}}</h4>
                    <div class="col-xs-3"><span class="locations_closebtn" (click)="modifyForm()" data-dismiss="modal">&times;</span></div>
                    <p class="locations_savelicsubheader col-xs-12">
                        {{'orgLocation.licValidationError' | cxTranslate}}
                    </p>
                    <div class="locations_savelicbuttons">
                        <button class="save_modifybtn global_redbtn" style="display:none;" (click)="modifyForm()">{{'orgLocation.modifyLabel' | cxTranslate}}</button>
                        <button class="save_continuebtn global_redbtn" (click)="submitUpdateLocForm()">{{'orgLocation.continueButtonLabel' | cxTranslate}}</button>
                    </div>
                </ng-template>


            </div>
        </div>
    </div>
</div>
</ng-container>
<!--Save button License Validation Modal end-->

<!--Req button License Validation Modal start-->

<ng-container *ngIf="showReqLicModal">
    <div id="locations_reqlicmodal" class="modal fade" data-backdrop="true" role="dialog" [ngClass]="showReqLicModal?'show':'hide'">
        <div class="modal-dialog">
            <!-- Modal content-->
            <div class="modal-content req-lic-content">
                <div class="modal-body row">
                    <ng-container *ngIf="connectionSuccess;else connectionError">

                    <h4 class="modal-title locations_reqlicheader col-9">{{'orgLocation.licValidationtitle' | cxTranslate}}</h4>
                    <div class="col-3"><span class="locations_closebtn" (click)="modifyNewLocForm()" data-dismiss="modal">&times;</span></div>
                    <p class="locations_reqlicsubheader col-xs-12">
                        {{'orgLocation.licValidationMsg' | cxTranslate}}
                    </p>
                    <div class="locations_reqlicbuttons">
                        <button class="req_modifybtn global_redbtn" data-dismiss="modal" (click)="modifyNewLocForm()">{{'orgLocation.modifyLabel' | cxTranslate}}</button>
                        <button class="req_continuebtn global_greybtn" (click)="submitNewLocForm()">{{'orgLocation.continueLabel' | cxTranslate}}</button>
                    </div>
                </ng-container>
                <ng-template #connectionError>
                    <h4 class="modal-title locations_reqlicheader col-xs-9">{{'orgLocation.licValidationErrorTitle' | cxTranslate}}</h4>
                    <div class="col-xs-3"><span class="locations_closebtn" (click)="modifyNewLocForm()" data-dismiss="modal">&times;</span></div>
                    <p class="locations_reqlicsubheader col-xs-12">
                        {{'orgLocation.licValidationError' | cxTranslate}}
                    </p>
                    <div class="locations_reqlicbuttons">
                        <button class="req_modifybtn global_redbtn" style="display:none;" (click)="modifyNewLocForm()">{{'orgLocation.modifyLabel' | cxTranslate}}</button>
                        <button class="req_continuebtn global_redbtn" (click)="submitNewLocForm()">{{'orgLocation.continueButtonLabel' | cxTranslate}}</button>
                    </div>
                </ng-template>

                </div>
            </div>
        </div>
</div>
</ng-container>
    <!--Req button License Validation Modal end-->
