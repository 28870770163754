<pre style="word-wrap: break-word; white-space: pre-wrap;color: #FFF;">
   <ng-container>
    {{firsttext}}
    {{sitemap}}
    <ng-container *ngFor="let item of sitemapdata">
        {{secondtext}}
        {{thirdtext}}{{item}}{{fifthtext}}
		{{fouthtext}}
    </ng-container>
    {{sixthtext}}
</ng-container>
</pre> 





