import { ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { CmsBannerComponent, IndexType } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { StartOrderService } from '../../custom-start-order/start-order.service';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { FormBuilder } from '@angular/forms';
import { ContentService } from '../../spartacus/services/content.service';

@Component({
  selector: 'app-productpage',
  templateUrl: './productpage.component.html',
  styleUrl: './productpage.component.scss'
})
export class ProductpageComponent {
  showClearBtn: boolean = false;
  prodVal: any;
  review:any;
  errorMsg: string = '';
  productData: any;
  seasonValue: string = '';
  deliveryseason:string='';
  totalCost: number = 0.00;
  disableDeliveryBtn :boolean=true;
  enablePage: any;
  isLoading: boolean = false;
  disabledelivery: boolean = false;
  showDeliveryPage: boolean = false;
  disableRepeatDeliveryBtn: boolean = false;
  isDeliveryBtnClicked: boolean = false;
  showErrorMsg: boolean = false;
  showProductPage: boolean = false;
  deliverydata: any;
  maxdeliveryslot:number=5000;
  mindeliveryslot:number=100;
  minproductdose:number=200;
  maxproductdose:number=10000;
  minproductwithcomma="200";
  maxproductwithcomma="10,000";
  maxdeliveryslotwithcomma="5,000";
 
  emailId: string = 'flu.salesuk@seqirus.com';
  inseason: string = '';
  preseason: string = '';
  landingapi: any;
  maxslot:number=4;
  locationdetails:any=[]
  constructor(public component: CmsComponentData<CmsBannerComponent>, private cdr: ChangeDetectorRef, private router: Router, private startOrderService: StartOrderService, private userInfoService: UserInfoService, private fb: FormBuilder, private contentservice: ContentService) {

  }
  ngOnInit() {
   
   
  
    
    this.contentservice.startapi().subscribe(data => {
      
      this.landingapi=data;
      this.inseason=this.landingapi?.currentInSeason;
      this.preseason=this.landingapi?.prePreSeason;
      this.cdr.detectChanges();
      
    });
    this.userInfoService.getEnablePage().subscribe((data: any) => {
      this.enablePage = data;
      if (this.enablePage.showProductPage || this.showProductPage) {
      this.isLoading = true;
    this.userInfoService.getstartorderseason().subscribe((seasonValue: any) => {
    
      this.seasonValue = seasonValue;
      this.deliveryseason=seasonValue?.split("-")[0];
          this.scrollToTop();
          this.startOrderService.getProductData().subscribe((data: any) => {
            //this.isLoading = true;
            this.cdr.detectChanges()
            if (data && (this.enablePage.showProductPage || this.showProductPage)) {
             
              this.productData = data;
              this.isLoading = false;
              if(this.productData.constraintList.PrePreBookMaxLocProdQuantityCreateOrder){
                this.minproductdose=this.productData.constraintList.PrePreBookMinLocProdQuantityCreateOrder;
                this.maxproductdose=this.productData.constraintList.PrePreBookMaxLocProdQuantityCreateOrder;
              }else{
                this.minproductdose=this.productData.constraintList.MinLocProdQuantityCreateInSeasonOrder;
                this.maxproductdose=this.productData.constraintList.MaxLocProdQuantityCreateInSeasonOrder;
              }
              this.minproductwithcomma=this.numberWithCommas(this.minproductdose)
              this.maxproductwithcomma=this.numberWithCommas(this.maxproductdose)
              this.productData.productList.forEach((el: any, index: number) => {
                el.priceData['errorMsg'] = '';
                el.priceData['prodValue'] = null;
              })
              const prodArray = this.productData.productList;
              this.productData.shippingData.forEach((el: any) => {
                el['productList'] = [];
                el['productList'] = JSON.parse(JSON.stringify(prodArray));
                el['disableRemove'] = this.productData.shippingData.length == 1;
              })
              //this.handleOnDisable();
              //this.calculateTotalDose();
              
              this.cdr.detectChanges();
              
              this.startOrderService.setProductData(null);
            }
           /*  else{
             // this.isLoading=false;
            } */
             
          }, (error) => {
            console.log('error: ', error);
            this.isLoading = false;
            this.cdr.detectChanges();
          })

      }, (error) => {
        console.log('error: ', error);
        this.isLoading = false;
        const obj = {
          showLandingPage: false,
          showLocationPage: false,
          showProductPage: false,
          isProductBackBtnClicked: false,
          showReviewPage: false,
          showConfirmThankYouPage: false,
          showSaveThankYouPage :false,
          showSorryPage: true
        }
        this.userInfoService.setInPlaceOrder(false);
        this.userInfoService.setEnablePage(obj);
        this.scrollToTop();
        this.cdr.detectChanges();
      })
    }   if (this.enablePage.isDeliveryBackBtnClicked) {
      this.showDeliveryPage = true;
    }
    })
   
  }
  handleOnExitClick() {
    this.userInfoService.setPopUpValue(true);
  }
  handleOnPrevValueBtnClick(value: string) {
    this.showClearBtn = value === 'clear';
    this.totalCost = 0.00;
    if (this.showClearBtn) {
      this.productData.shippingData.forEach((el: any) => {
        el.productList.forEach((data: any) => {
           if (data.baseName === 'AQIV') {
            data.priceData.prodValue = !el.previousYearAqivQty ? null : el.previousYearAqivQty.toString();
          } else if (data.baseName === 'QIVC') {
            data.priceData.prodValue = !el.previousYearQivcQty ? null : el.previousYearQivcQty.toString();
          } 
         
          if (data.priceData.prodValue == null) {
            data.priceData.errorMsg = '';
          } else if (data.priceData.prodValue != 0 && (data.priceData.prodValue < this.minproductdose || data.priceData.prodValue > this.maxproductdose)) {
            data.priceData.errorMsg = `Please enter between ${this.minproductwithcomma} & ${this.maxproductwithcomma} doses`;
          } else if (data.priceData.prodValue != 0 && data.priceData.prodValue % 10 != 0) {
            data.priceData.errorMsg = 'Please enter a volume to the nearest unit of 10';
          } else {
            data.priceData.errorMsg = '';
          }
        })
      })
      this.handleOnDisable();
    } else {
      this.isDeliveryBtnClicked = false;
      this.disableDeliveryBtn = true;
      this.cdr.detectChanges();
      this.productData.shippingData.forEach((el: any) => {
        el.productList.forEach((data: any) => {
          data.priceData.prodValue = null;
        })
      })
     
    }
   
    this.cdr.detectChanges();
  }
  handleOnProdValueChange(value: string, locIndex: number, prodIndex: number) {
    let productValue :any=value
    if(value){
     productValue = Number(value?.replace(/,/g, ""));
     
    }
      if (productValue == null) {
        this.productData.shippingData[locIndex].productList[prodIndex].priceData.errorMsg = '';
      } else if (productValue != 0 && (productValue < this.minproductdose || productValue > this.maxproductdose)) {
        this.productData.shippingData[locIndex].productList[prodIndex].priceData.errorMsg = `Please enter between ${this.minproductwithcomma} & ${this.maxproductwithcomma} doses`;
      } else if (productValue != 0 && productValue % 10 != 0) {
        this.productData.shippingData[locIndex].productList[prodIndex].priceData.errorMsg = 'Please enter a volume to the nearest unit of 10';
      } else {
        this.productData.shippingData[locIndex].productList[prodIndex].priceData.errorMsg = '';
      }
      if (this.isDeliveryBtnClicked && this.showClearBtn) {
        this.showErrorMsg = true;
      }
      if (this.showClearBtn && this.showErrorMsg) {
        let errorMsg = this.productData.shippingData.find((el: any) => {
          let obj;
          const prodValueArray = el.productList.map((data: any) => {  return data.priceData.prodValue });
          const value = prodValueArray.every((val: any) => {
            if ((val == null) || (val == '') || isNaN(val)) {
              obj = el;
              return obj
            }
          })
          if (obj) {
            return obj;
          }
        })
        this.showErrorMsg = errorMsg == undefined ? false : true;
        this.cdr.detectChanges();
      }
    this.productData.shippingData[locIndex].productList[prodIndex].priceData.prodValue = this.numberWithCommas(productValue);
    this.calculateTotalDose();
    this.handleOnDisable();
    this.cdr.detectChanges();
  }
  calculateTotalDose() {
    this.totalCost = 0.00;
    this.productData.shippingData.forEach((el: any) => {
      el.productList.forEach((el: any) => {
        if (el.priceData.prodValue) {
          const material = this.productData.priceResponse.materials.find((data: any) => data.materialID === el.code)
          this.totalCost += (Number(el.priceData.prodValue?.replace(/,/g, "")) * Number(material.salePrice));
        }
      })
    })
  }
  onSubmit() {
  }
  handleOnDisable() {
    const eachtotal=0;
    const totalloc=[];
    if (!this.showClearBtn) {
      let disable = true;
      let filteredData = this.productData.shippingData.find((el: any) => {
        return el.productList.find((prod: any) => {
          return (prod.priceData.errorMsg != '')
        })
      })
    /*   this.productData.shippingData.forEach((element: any) => {
        eachtotal=element.productList.
      }) */

      if (!filteredData) {
        disable = this.productData.shippingData.find((el: any) => {

          const prodValueArray = el.productList.map((data: any) => { return Number(data.priceData.prodValue?.replace(/,/g, "")) });
          const value = prodValueArray.every((val: any, index: number) => {
            return ((val == null) || (val == '') || (val == 0) || isNaN(val));
          })
          return value ? el : undefined
        })
      }
      this.disableDeliveryBtn = filteredData == undefined ? disable == undefined ? false : true : true;

   /*    if(this.disableDeliveryBtn =false){
        let sumerror:any=[]
    
      this.productData.shippingData.forEach((location1:any)=>{
        let locationsum=0;
         console.log(location1)
       location1.productList.forEach((prodsum:any)=>{
          locationsum =Number(prodsum?.priceData?.prodValue?.replace(/,/g, "")) + locationsum
        
        })
         if(locationsum<=0){
          sumerror.push(true)
         }else{
          sumerror.push(false)
         }
         
        });
        if(sumerror.find(true)){
          this.disableDeliveryBtn=true;
        }else{
          this.disableDeliveryBtn=false;
        }
      }  */
      this.cdr.detectChanges();
    } else {
      this.disableDeliveryBtn = false;
    }
  }
  handleOnRemoveBtn(index: number) {
    this.productData.shippingData.splice(index, 1);
    if (this.productData.shippingData.length == 1) {
      this.productData.shippingData[0].disableRemove = true;
    }
    this.cdr.detectChanges();
  }
  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  handleOnBackbtnClick() {
    
    const obj = {
      showLandingPage: false,
      showLocationPage: true,
      showProductPage: false,
      isProductBackBtnClicked: true,
      showReviewPage: false
    }
    this.scrollToTop();
    this.showProductPage = false;
    this.showClearBtn = false;
    this.showErrorMsg = false;
    
    this.userInfoService.setEnablePage(obj);
    this.cdr.detectChanges();
 
  }
  handleOndeliveryValueChange(deliveryValueString: string, locIndex: number, deliveryIndex: number, materialindex: number) {
    let deliveryValue:any = Number(deliveryValueString.replace(/,/g, ""));
    deliveryValueString=deliveryValueString.trim()
    if(deliveryValueString == ""){
      deliveryValue=null;
    }
   if(!isNaN(deliveryValue)){
    if (deliveryValue != null) {
      if (deliveryValue < this.mindeliveryslot || deliveryValue > this.maxdeliveryslot) {
        this.deliverydata.deliverySlotResponseData.shippingLocations[locIndex].materials[materialindex].deliverySlots[deliveryIndex].producterrorMsg = 'You can enter '+this.mindeliveryslot+ ' - '+this.maxdeliveryslotwithcomma+' doses per selected slot';
      } else if (deliveryValue != 0 && deliveryValue % 10 != 0) {
        this.deliverydata.deliverySlotResponseData.shippingLocations[locIndex].materials[materialindex].deliverySlots[deliveryIndex].producterrorMsg = 'Please enter a volume to the nearest unit of 10';
      } else {

        this.deliverydata.deliverySlotResponseData.shippingLocations[locIndex].materials[materialindex].deliverySlots[deliveryIndex].producterrorMsg = '';
      }
    } else {
      this.deliverydata.deliverySlotResponseData.shippingLocations[locIndex].materials[materialindex].deliverySlots[deliveryIndex].producterrorMsg = '';

    }
    if(deliveryValue != null){
      this.deliverydata.deliverySlotResponseData.shippingLocations[locIndex].materials[materialindex].deliverySlots[deliveryIndex].quantity = this.numberWithCommas(deliveryValue);
    }
   
    this.deliverydata.deliverySlotResponseData.shippingLocations[locIndex].materials[materialindex].allocatedvalue = null;
    this.deliverydata.deliverySlotResponseData.shippingLocations[locIndex].materials[materialindex].deliverySlots.forEach((sum: any) => {
      const numWithoutComma = Number(sum.quantity?.replace(/,/g, ""));
      if(!isNaN(numWithoutComma)){
      this.deliverydata.deliverySlotResponseData.shippingLocations[locIndex].materials[materialindex].allocatedvalue += numWithoutComma;
      }
      
    });
    

    if (this.deliverydata.deliverySlotResponseData.shippingLocations[locIndex].materials[materialindex].allocatedvalue != this.deliverydata.deliverySlotResponseData.shippingLocations[locIndex].materials[materialindex].qty) {
      this.deliverydata.deliverySlotResponseData.shippingLocations[locIndex].materials[materialindex].allocatederrorMsg = "sum not match";
      this.deliverydata.deliverySlotResponseData.shippingLocations[locIndex].materials[materialindex].matchtick = false;
    }
    else {
      this.deliverydata.deliverySlotResponseData.shippingLocations[locIndex].materials[materialindex].allocatederrorMsg = "";
      this.deliverydata.deliverySlotResponseData.shippingLocations[locIndex].materials[materialindex].matchtick = true;
    }
    if (this.deliverydata.deliverySlotResponseData.shippingLocations[locIndex].materials[materialindex].allocatedvalue == 0) {
      this.deliverydata.deliverySlotResponseData.shippingLocations[locIndex].materials[materialindex].allocatedvalue = null;
      this.deliverydata.deliverySlotResponseData.shippingLocations[locIndex].materials[materialindex].allocatederrorMsg = "";
    }
    if(this.deliverydata.deliverySlotResponseData.shippingLocations[locIndex].materials[materialindex].allocatedvalue != null){
      this.deliverydata.deliverySlotResponseData.shippingLocations[locIndex].materials[materialindex].allocatedvalue=this.numberWithCommas(this.deliverydata.deliverySlotResponseData.shippingLocations[locIndex].materials[materialindex].allocatedvalue);
    }
      this.deliverydata.deliverySlotResponseData.shippingLocations[locIndex]?.materials?.find((loop: any) => {
        if (
          loop.deliverySlots.find((check: any) => {

            return check.producterrorMsg == "You can enter "+this.mindeliveryslot+" - "+this.maxdeliveryslotwithcomma+" doses per selected slot";
          })) {
          this.deliverydata.deliverySlotResponseData.shippingLocations[locIndex].locationmsg = "You can enter "+this.mindeliveryslot+" - "+this.maxdeliveryslotwithcomma+" doses per selected slot"
          return this.deliverydata.deliverySlotResponseData.shippingLocations[locIndex].locationlevelerror=true;
        } else if (
          loop.deliverySlots.find((check: any) => {
            return check.producterrorMsg == "Please enter a volume to the nearest unit of 10";
          })) {
          this.deliverydata.deliverySlotResponseData.shippingLocations[locIndex].locationmsg = 'Please enter a volume to the nearest unit of 10'
          return this.deliverydata.deliverySlotResponseData.shippingLocations[locIndex].locationlevelerror=true;
        } else {
          this.deliverydata.deliverySlotResponseData.shippingLocations[locIndex].locationmsg = ''
          return this.deliverydata.deliverySlotResponseData.shippingLocations[locIndex].locationlevelerror=false;
        }
      });

    this.deliverydata.deliverySlotResponseData.shippingLocations[locIndex].locationsumerror =

      this.deliverydata.deliverySlotResponseData.shippingLocations[locIndex].materials.some((sum: any) => {
        return (sum.allocatederrorMsg != '')

      });
   
    this.handledeliverybtn();
   
    this.cdr.detectChanges();
   }
  }
  handlenan(event:Event){
    let value1:any =  (event.target as HTMLInputElement).value;
    value1= Number(value1?.replace(/,/g, ""));
    if(isNaN(value1)){
      (event.target as HTMLInputElement).value=''
    }
  }
  handledeliverybtn() {
    this.disabledelivery =
      this.deliverydata?.deliverySlotResponseData?.shippingLocations.every((ell: any) => {
        if (
          ell.materials.every((check: any) => {
            return (check.matchtick != true);
          })) {
          return false;
        }
        else { 
        
          return (ell.locationsumerror != true && ell.locationlevelerror != true)
        }
        
      });
  }
  businessrule(locationnumber: number, inputnumber: number, event: Event, product: number) {
    const element = (event.target as HTMLInputElement);
    if (product==2) {
      if (element.value.length > 0) {
        for (let i = 0; i < this.deliverydata.deliverySlotConstraintList.maxDeliverySlots; i++) {
         /*  const aqiv = <HTMLElement>document.getElementById("aqivfirst[" + locationnumber + "][" + i + "]");
          const qivc = <HTMLElement>document.getElementById("qivcfirst[" + locationnumber + "][" + i + "]");
          if (i != inputnumber) {
            aqiv?.classList.add("ruleapplied");
            qivc?.classList.add("ruleapplied");
            aqiv?.setAttribute("disabled", "disabled");
            qivc?.setAttribute("disabled", "disabled");
          } */
            if (i != inputnumber) {
         this.deliverydata.deliverySlotResponseData.shippingLocations[locationnumber].materials[0].deliverySlots[i].disablerule=true;
         this.deliverydata.deliverySlotResponseData.shippingLocations[locationnumber].materials[1].deliverySlots[i].disablerule=true;
            }
        }

      } else {
        const currentaqiv = <HTMLInputElement>document.getElementById("aqivfirst[" + locationnumber + "][" + inputnumber + "]");
        const currentqivc = <HTMLInputElement>document.getElementById("qivcfirst[" + locationnumber + "][" + inputnumber + "]");

        if (currentqivc.value.length <= 0 && currentaqiv.value.length <= 0) {
          for (let i = 0; i < this.deliverydata.deliverySlotConstraintList.maxDeliverySlots; i++) {
           /*  const aqiv = <HTMLElement>document.getElementById("aqivfirst[" + locationnumber + "][" + i + "]");
            const qivc = <HTMLElement>document.getElementById("qivcfirst[" + locationnumber + "][" + i + "]");

            aqiv?.classList.remove("ruleapplied");
            qivc?.classList.remove("ruleapplied");
            aqiv?.removeAttribute("disabled");
            qivc?.removeAttribute("disabled"); */
            this.deliverydata.deliverySlotResponseData.shippingLocations[locationnumber].materials[0].deliverySlots[i].disablerule=false;
            this.deliverydata.deliverySlotResponseData.shippingLocations[locationnumber].materials[1].deliverySlots[i].disablerule=false;
          }
        }
      }
    }
    else {
      let inputelement = element.id;
      inputelement = inputelement.split("[")[0];
      if (element.value.length > 0) {
        for (let i = 0; i < this.deliverydata.deliverySlotConstraintList.maxDeliverySlots; i++) {
/*           const product = <HTMLElement>document.getElementById(inputelement + "[" + locationnumber + "][" + i + "]");
 */          if (i != inputnumber) {
  this.deliverydata.deliverySlotResponseData.shippingLocations[locationnumber].materials[0].deliverySlots[i].disablerule=true;

          /*   product?.classList.add("ruleapplied");
            product?.setAttribute("disabled", "disabled"); */

          }
        }
      } else {
        for (let i = 0; i < this.deliverydata.deliverySlotConstraintList.maxDeliverySlots; i++) {
      /*     const product = <HTMLElement>document.getElementById(inputelement + "[" + locationnumber + "][" + i + "]");
          product?.classList.remove("ruleapplied");
          product?.removeAttribute("disabled"); */
          this.deliverydata.deliverySlotResponseData.shippingLocations[locationnumber].materials[0].deliverySlots[i].disablerule=false;

        }
      }
    }
   
  
   

  
  }
  handleOnDeliveryBtnClick() {
    let errorMsg;
    if (!this.showClearBtn) {
      this.isDeliveryBtnClicked = false;
      this.showErrorMsg = false;
      this.constructProductPayload();
    } else {
      this.isDeliveryBtnClicked = true;
      let errorData = this.productData.shippingData.find((el: any) => {
        return el.productList.find((prod: any) => {
          return (prod.priceData.errorMsg != '')
        })
      })
  if(!errorData){
       errorMsg = this.productData.shippingData.find((el: any) => {
        let obj;
        const prodValueArray = el.productList.map((data: any,index:number) => { 
          if(data.priceData.prodValue){
          return Number(data.priceData.prodValue?.replace(/,/g, ""));
          }else{
           return data.priceData.prodValue
       }
       });
        
        const value = prodValueArray.every((val: number) => {
         
          if ((val == null) ) {
 

            obj ="error";
            return obj
          }
          else{
            return 
          }
        })
        if (obj) {
          return obj;
        }
        else{
          if(Number(el.productList[0].priceData.prodValue?.replace(/,/g, ""))    + Number(el.productList[1].priceData.prodValue?.replace(/,/g, "")) <=0){
            return "sumnot match"
          }else{
            return obj
          }
        }
      })
    }

   

      this.showErrorMsg =errorData==undefined? errorMsg == undefined ? false : true:true;
     
      !this.showErrorMsg && this.constructProductPayload();
    }
    this.cdr.detectChanges();
  }
  handleOnBackToProductBtnClick() {
    this.showDeliveryPage = false;
    this.showProductPage = true;
    this.scrollToTop();
  }

  handleOnReviewOrderBtnClick() {
    
    this.showDeliveryPage = false;
    this.showProductPage = false;
    const obj = {
      showLandingPage: false,
      showLocationPage: false,
      showProductPage: false,
      isProductBackBtnClicked: false,
      showReviewPage: true,
    }
    this.userInfoService.setEnablePage(obj);
    let createOrderData = this.deliverydata.deliverySlotResponseData.shippingLocations.map((el: any) => {
      const createOrderProductData = el.materials.map((data: any) => {
        const createOrderDeliverySlotData = data.deliverySlots.map((del: any) => {
          return{
            month: del.month,
            date: del.date,
            deliverySlotWeek: del.week,
            quantity: Number(del.quantity?.replace(/,/g, ""))
          }
        })

        return {
          productCode:data.materialID,
          productQuantity: data.qty,
          createOrderDeliverySlotData:createOrderDeliverySlotData
        }
      })
      return {
       locID: el.shippingLocID,
       qty50to64y: el.qty50to64y,
       createOrderProductData:createOrderProductData
      }
    })

    const delpayload = {
      totalCost: this.totalCost.toString(),
      requestType: this.productData.priceResponse.requestType.includes('CR') ? "CR" : "AM",
      orderType: this.productData.priceResponse.requestType.includes('CR') ? "CREATE" : "AMEND",
      season: this.seasonValue,
      priceResponse: this.productData.priceResponse,
      constraintList:JSON.stringify(this.deliverydata.deliverySlotConstraintList),
      createOrderData: createOrderData
    }
    this.startOrderService.getreviewpage(delpayload);
    this.cdr.detectChanges();
    
    this.scrollToTop();
  }
  constructProductPayload(){
    let createOrderData = this.productData.shippingData.map((el: any) => {
      const createOrderProductData = el.productList.map((data: any) => {
        return {
          productCode: Number(data.code),
          productQuantity: Number(data.priceData.prodValue?.replace(/,/g, ""))
        }
      })
      return {
        creditDays: this.productData.priceResponse.creditDays,
        sorNumber: this.productData.priceResponse.SOR,
        locID: el.locID,
        createOrderProductData: createOrderProductData
      }
    })
    const payload = {
      totalCost: this.totalCost.toString(),
      requestType: this.productData.priceResponse.requestType.includes('CR') ? "CR" : "AM",
      orderType: this.productData.priceResponse.requestType.includes('CR') ? "CREATE" : "AMEND",
      season: this.seasonValue,
      priceResponse: this.productData.priceResponse,
      createOrderData: createOrderData
    }
    this.isLoading = true;
    this.startOrderService.getUkDeliverySlots(payload).subscribe((data: any) => {

      this.deliverydata = data;
      this.scrollToTop();
     
      this.deliverydata.deliverySlotConstraintList=JSON.parse(this.deliverydata?.deliverySlotConstraintList)
      if(this.seasonValue==this.deliverydata.createOrderSeasonData.prePreSeason){
        this.maxdeliveryslot=this.deliverydata.deliverySlotConstraintList.PrePreBookMaxDelSlotQtyforProdCreateOrder;
        this.mindeliveryslot=this.deliverydata.deliverySlotConstraintList.PrePreBookMinDelSlotQtyforProdCreateOrder;
        this.maxslot=this.deliverydata.deliverySlotConstraintList.maxDeliverySlots;
      }else{
        this.maxdeliveryslot=this.deliverydata.deliverySlotConstraintList.InSeasonMaxDelSlotQtyforProdCreateOrder;
        this.mindeliveryslot=this.deliverydata.deliverySlotConstraintList.InSeasonMinDelSlotQtyforProdCreateOrder;
         if(this.deliverydata.deliverySlotConstraintList.maxDeliverySlots==0){
          this.maxslot=4;
         }
         else{
          this.maxslot=this.deliverydata.deliverySlotConstraintList.maxDeliverySlots;
         }
       
      }
      this.maxdeliveryslotwithcomma=this.numberWithCommas(this.maxdeliveryslot)
    
      this.deliverydata?.deliverySlotResponseData?.shippingLocations.forEach((el: any) => {
        el['locationlevelerror'] = false;
        el['locationmsg'] = '';
        el['locationsumerror'] = false;
  
        el.materials.forEach((sel: any) => {
          sel['allocatederrorMsg'] = '';
          sel['allocatedvalue'] = null;
          sel['matchtick'] = false;
  
          /* if(sel.materialID==this.deliverydata?.productList[0]?.code){ */
          sel.deliverySlots.forEach((del: any) => {
            del['producterrorMsg'] = '';
            del['quantity'] = null
            del['disablerule']=false
          });
          /*} else if(sel.materialID==this.deliverydata?.productList[1]?.code){
            sel.deliverySlots.forEach((del:any)=>{
              del['product2errorMsg']='';
              del['product2value']=0
             });
            } */
        });
  
      });
      for(let i=0;i<this.deliverydata?.deliverySlotResponseData.shippingLocations?.length;i++){
        this.deliverydata?.shippingData.forEach((el:any)=>{
          if(el.locID==this.deliverydata.deliverySlotResponseData.shippingLocations[i].shippingLocID){
             if(el.state){
              this.locationdetails.push(''+el.locname+','+el.address+','+el.state+'');
             }
             else{
              this.locationdetails.push(''+el.locname+','+el.address+'');
             }
            
          }
        })
      }
      this.showDeliveryPage = true;
      this.isLoading = false;
      this.cdr.detectChanges();
    }, (error) => {
      console.log('error: ', error);
      this.isLoading = false;
      this.userInfoService.setInPlaceOrder(false);
      this.startOrderService.setApiFailurePopup(true);
      this.isLoading = false;
      this.cdr.detectChanges();
    })
  }
  numberWithCommas(number: number) {
    var parts = number.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }
 
}
