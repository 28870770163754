<div class="influenza-first-component">
    <!-- <div class="container influenza-breadcrumbs">  -->
        <div class="breadcrumbs--page-nav influenza-breadcrumbs">
            <div class="container">
                <p><a [routerLink]="'/'">Home</a><span> &gt; </span><a [routerLink]="'/overview'">flu360® Overview</a>
                    <span> &gt; </span><a [routerLink]="'/clinical-support'">Clinical Support</a> <span> &gt;
                    </span><strong>Influenza Burden 6+ months</strong>
                </p>
            </div>
        </div>
    <!-- </div> -->
    <div id="influenza-burden-65plus" *ngIf="items | async as item">
        <div class="breadcrumbs--page-nav influenza-65breadcrumbs">
            <div class="container">
                <p>
                    <a href="/">Home</a>
                    <span> &gt; </span>
                    <a href="/overview">flu360® Overview</a>
                    <span> &gt; </span><a href="/clinical-support">Clinical Support</a>
                    <span> &gt; </span>
                    <strong>Influenza Burden 65+ years</strong>
                </p>
            </div>
        </div>
        <div class="hero--overview" role="img" aria-label="Healthcare professional facing left"  alt="Healthcare professional facing left"
            [style.backgroundImage]="'url(' + getImage(item.media) + ')'">
            <div class="container">
                <div class="hero--overview-content">
                    <img class="rectangle-down" alt="angled corner" src="../../app/../assets/images/rectangle-down.svg">
                    <h1 class="text-black" [innerHTML]="item.headline"></h1>
                    <p class="text" [innerHTML]="item.content"></p>
                </div>
            </div>
        </div>
        <!-- for influenza mobile -->
        <div class="row-flex center-xs d-flex flex-row justify-content-center influenza-mobile" *ngIf="items | async as item">
            <div class="hero--overview-content-mobile hide-desktop"  [style.backgroundImage]="'url(' + getImage(item.media) + ')'">
                <img class="rectangle-down" alt="angled corner" src="../../app/../assets/images/rectangle-down.svg">
                <div class="new_header" [innerHTML]="item.headline"></div>
                <p class="text1 mobile-text1" [innerHTML]="item.content"></p>
            </div>
        </div>
    </div>
</div>