import { ChangeDetectorRef, Component } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { CommonApiService } from '../../spartacus/services/common-api.service';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomMemberModalComponent } from '../custom-member-modal/custom-member-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-custom-membership',
  templateUrl: './custom-membership.component.html',
  styleUrl: './custom-membership.component.scss'
})
export class CustomMembershipComponent {
  memberDetails: any;
  seqirusMemberShipVisibilityForm: FormGroup | any;
  toggleEdit: boolean = false;
  uid: any;
  constructor(private occEndpointsService: OccEndpointsService, private dialog: MatDialog,
    private commonService: CommonApiService, private fb: FormBuilder,private cd:ChangeDetectorRef,
     private userInfoService: UserInfoService) {
    this.setForm();
  }
  edit() {
    this.toggleEdit = !this.toggleEdit;
    this.seqirusMemberShipVisibilityForm.get('memberinput').setValue('');
    this.seqirusMemberShipVisibilityForm.get('memberinput')?.clearValidators();
  }
  setForm() {
    this.seqirusMemberShipVisibilityForm = this.fb.group({
      membershipDecision: [''],
      memberinput: [''],
      currentMembership: [''],
      requestedMembership: [''],
      membershipStatus: [''],
      orgReferralEmail: [''],
      isMembershipDeclared: [''],
      isContractSigned: ['']
    })
  }
  ngOnInit() {
    this.userInfoService.getUserData().subscribe(res => {
      if(res){
      this.uid = res.uid;
      }
    })
    this.getMemberDetails();
  }
  getForm(data: any) {
    this.seqirusMemberShipVisibilityForm.patchValue({
      currentMembership: data.currentMembership,
      requestedMembership: data.isContractSigned,
      membershipStatus: data.membershipStatus,
      orgReferralEmail: data.currentMembership,
      isMembershipDeclared: data.isMembershipDeclared,
      isContractSigned: data.isContractSigned,
    })
    this.toggleMemberDecision('');

  }
  getFormControl(control: string) {
    return this.seqirusMemberShipVisibilityForm!.get(control);
  }
  toggleMemberDecision(decision: string) {
    if (decision === 'no') {
      this.getFormControl('memberinput').enable();
      this.getFormControl('memberinput').setValidators([Validators.required,Validators.pattern("^[a-zA-Z]+( [a-zA-Z]+)*$")]);
      this.getFormControl('memberinput').updateValueAndValidity();
    }
    else {
      this.getFormControl('memberinput').disable();
      this.getFormControl('memberinput').setValidators(null);
      this.getFormControl('memberinput').setValue('');
      this.getFormControl('memberinput').markAsPristine();
      this.getFormControl('memberinput').markAsUntouched();
      this.getFormControl('memberinput').updateValueAndValidity();
    }

    
  }
  submit() {
    let endpoint = this.occEndpointsService.buildUrl(`/users/${this.commonService.userId}/Start-order/membership/submit`);
    let payload: any = {
      url: endpoint,
      contentType:'text/xml',
      responseType:'text',
      data: {
        isMembershipDeclared: this.seqirusMemberShipVisibilityForm.value.isMembershipDeclared,
        membershipStatus: this.seqirusMemberShipVisibilityForm.value.membershipStatus,
        currentMembership: this.seqirusMemberShipVisibilityForm.value.currentMembership,
        isContractSigned: this.seqirusMemberShipVisibilityForm.value.isContractSigned,
        membershipDecision: this.seqirusMemberShipVisibilityForm.value.membershipDecision,
        requestedMembership: this.seqirusMemberShipVisibilityForm.value.membershipDecision == 'no' ? this.seqirusMemberShipVisibilityForm.value.memberinput : undefined,
        orgReferralEmail: this.uid
      }
    }
    payload = JSON.parse(JSON.stringify(payload));
    this.commonService.postAPICall(payload).subscribe(res => {
      this.toggleEdit=false;
      this.getMemberDetails();
      this.openDialog(this.seqirusMemberShipVisibilityForm.value.membershipDecision);
    });

  }
  getMemberDetails() {
    
    this.userInfoService.getmembershipus().subscribe(
      (data) => {
        if (data) {
         
          this.memberDetails=data;
          this.getForm(data);
          this.cd.detectChanges();
        }
       
      },
      (error) => {
        console.log('error');
       
      }
    );
   /*  let endpoint = this.occEndpointsService.buildUrl(`/users/${this.commonService.userId}/my-profile/membership-status`);
    let payload = {
      url: endpoint,
    }
    this.commonService.getAPICall(payload).subscribe({
      next:((res:any)=>{
        this.memberDetails=res;
        this.getForm(res);
        this.cd.detectChanges();
      }),
      
      error: (e) => {
      },
    }) */
  }
  openDialog(data: any): void {
    let height = data == 'yes' ? '250px' : '420px';
    this.dialog.open(CustomMemberModalComponent, {
      width: '600px',
      height: height,
      data: {type:data},
    });
  }
}
