import { LiveAnnouncer } from '@angular/cdk/a11y';
import { ChangeDetectorRef, Component, ElementRef, inject, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

declare var window:any;

export interface SchedulePaymentData {
  scheduledDate:any;
  dueDate:string;
  invoiceAmount:string;
  cardType:string;
  cardNumber:string;
  invoiceNumber:string;
  hasCredits:boolean;
  paymentStatus:string;
  creditsTotal:string;
  isExpanded:boolean;
}

@Component({
  selector: 'app-scheduled-payments-section',
  templateUrl: './scheduled-payments-section.component.html',
  styleUrl: './scheduled-payments-section.component.scss',
  providers:[DatePipe]
})
export class ScheduledPaymentsSectionComponent {

  
  @ViewChild(MatSort) sort!: MatSort;
  
  displayedColumns: string[] = [
    'scheduledDate',
    'dueDate',
    'invoiceAmount',
    'paymentMethod',
    'invoiceNumber',
    'reschedule',
    'cancel',
    'viewInvoice',
  ];
  displayedColumnsMobile: string[] = ['combined'];
  /* schedulePayData: SchedulePaymentData[] = [
    {
      scheduledDate:'02/24/2026',
      dueDate:'02/24/2026',
      totalAmount:'2450.60',
      cardType:'2',
      cardNumber:'*********2232',
      invoiceNumber:'9000001',
      hasCredits:true,
      paymentStatus:'open',
      creditsUsed:'1800.50'
    },
    {
      scheduledDate:'10/24/2025',
      dueDate:'02/30/2025',
      totalAmount:'1980.60',
      cardType:'1',
      cardNumber:'*********8788',
      invoiceNumber:'9000006',
      hasCredits:false,
      paymentStatus:'failed',
      creditsUsed:'200.50'
    },
    {
      scheduledDate:'11/19/2026',
      dueDate:'10/03/2026',
      totalAmount:'45000.60',
      cardType:'128',
      cardNumber:'*********5885',
      invoiceNumber:'9000003',
      hasCredits:false,
      paymentStatus:'open',
      creditsUsed:'2000.50'
    },
    {
      scheduledDate:'07/20/2025',
      dueDate:'10/24/2026',
      totalAmount:'3500.60',
      cardType:'3',
      cardNumber:'*********5885',
      invoiceNumber:'9000002',
      hasCredits:true,
      paymentStatus:'failed',
      creditsUsed:'100.50'
    }
  ] */
  schedulePayment = new MatTableDataSource<any>();
  
  private _liveAnnouncer = inject(LiveAnnouncer);
  lastSortedCol: string='';

  cardMap:any ={
    1:'Visa',2:'American Express',3:'MasterCard',128:'Discover'
  }
  
  today = new Date();
  schedulePayData: SchedulePaymentData[]=[];
  dateInput: any;
  invoiceData: any;
  cancelPopupData: any;
  currentRow: any;
  

  constructor(private cd:ChangeDetectorRef,private datePipe:DatePipe,private userInfoService:UserInfoService,private router:Router){
    this.today.setDate(this.today.getDate()+1);

  }
  

  ngOnInit(){
    this.userInfoService.getScheduledPayments().subscribe((resp:any) => {
      this.schedulePayData=resp;
      this.cd.detectChanges();
      this.schedulePayment=new MatTableDataSource(this.schedulePayData);
      this.schedulePayment.sort = this.sort;
      this.sort.sortChange.subscribe((sortEvent:Sort) => {
        this.applySortForTable(sortEvent);
      });
      this.updateTableData();
      this.cd.detectChanges();

      this.schedulePayment.sortingDataAccessor = (item,property) => {
      if(property == 'scheduledDate'){
        return new Date(item.scheduledDate).getTime();
      }
      if(property == 'dueDate'){
        return new Date(item.dueDate).getTime();
      }
      if(property == 'paymentMethod'){
        return this.cardMap[item.cardType];
      }
      return item[property];
      }

    });

  }

  applySortForTable(sortEvent:Sort){
    const active = sortEvent.active;
    let direction = sortEvent.direction;
    if(!direction){
      direction = 'asc';
      this.sort.direction = direction;
    }
    if(active !== this.lastSortedCol){
      direction = 'asc';
      this.sort.direction = 'asc';

    }
    this.lastSortedCol = active;
    
      this.schedulePayData = this.schedulePayData.sort((a:any,b:any) => {
        const valueA = a[active];
        const valueB = b[active];
        return (valueA < valueB? -1:1)*(direction === 'asc'? 1:-1);
      });
    
    this.updateTableData();
  }

  updateTableData() {
    this.schedulePayment.data = this.schedulePayData;
  }

  ngAfterViewInit(){
    setTimeout(() => {
    
      this.sort.sort({id:'scheduledDate',start:'asc',disableClear:true});
      
    },2000);
     
    
    this.cd.detectChanges();
    
    
  }
  
  getFormattedDueDate(date:string){
    let maxDate = new Date(date);
    maxDate.setDate(maxDate.getDate()-1);
    return maxDate;
  }

  openRescheduleSection(row:any){
    row.isExpanded = !row.isExpanded;
    this.cd.detectChanges();
  }
  onDateChange(date:Date){
    this.dateInput=this.datePipe.transform(date,'MM/dd/YYYY');
  }
  /* closeRow(row:any){
    if(this.dateInput && this.dateInput > new Date() && this.dateInput < new Date(row.dueDate)){
      this.currentRow=row;
      const modalElement = document.getElementById('schpay_closeModal') as HTMLElement;
      if(modalElement){
        const schPayCloseModal = new window.bootstrap.Modal(modalElement);
        schPayCloseModal.show();
        
      }
    }
    else{
      this.dateInput=null;
      row.isExpanded=false;
    }
  } */
  closeReschedule(row:any){
    
    this.dateInput=null;
    row.isExpanded=false;
   
  }
  
  normalizeDateFormat(dateInput:Date):string{
    
        const month = String(dateInput.getMonth() +1).padStart(2,'0');
        const day = String(dateInput.getDate()).padStart(2,'0');
        const year = dateInput.getFullYear();
        return `${month}/${day}/${year}`;
      
    
  }
  reSchedulePayment(row:any){
    if(this.dateInput){
      const formattedDate = this.normalizeDateFormat(this.dateInput)
    
    this.invoiceData={
      "invoiceNumber":row.invoiceNumber,
      "scheduledDate":formattedDate,
      "cancelled":false
    }
    this.callRescheduleApi(this.invoiceData);
    row.isExpanded = false;
    }
  }
  private callRescheduleApi(invData:any) {
    this.userInfoService.reschedulePayment(invData).subscribe((data: any) => {
      
      const modalElement = document.getElementById('schpay_cancelmodal') as HTMLElement;
      if(modalElement){
        const cancelModal = new window.bootstrap.Modal(modalElement);
        cancelModal.hide();
        document.body.classList.remove('modal-open');
        const backdrop = document.querySelector('.modal-backdrop');
        if(backdrop){
          backdrop.remove();
        }
        //this.cd.detectChanges();
      }
      
      const url = (this.router.url).split('?')[0].split('#')[0];
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate([url.substring(url.lastIndexOf('/') + 1)]).then(() => {
        this.cd.detectChanges();

      });
      this.cd.detectChanges();
    });
  }

  cancelSchedulePayment(data:any){
    this.invoiceData = {
      "invoiceNumber":data.invoiceNumber,
      "scheduledDate":'',
      "cancelled":true
    }
    this.callRescheduleApi(this.invoiceData);

  }

  openCancelPopup(element:any){
    this.cancelPopupData= element;
  }

  getActualAmount(element:any){
    return (Number(element.invoiceAmount) + Number(element.creditsTotal)).toFixed(2);
  }
  
  maskInputDate(event:any){
    var v = event.target.value;
    if (v.match(/^\d{2}$/) !== null) {
      event.target.value = v + '/';
    } else if (v.match(/^\d{2}\/\d{2}$/) !== null) {
      event.target.value = v + '/';
    }
  }

  allowNumbersOnly(event:any){
    
    const charCode = event.keyCode;
    if(charCode < 48 || charCode >57){
      event.preventDefault();
      return false;
    }
    return true;
  }
  
  isFieldFocused(inputref:any):boolean{
    return document.activeElement === inputref.valueAccessor._elementRef.nativeElement;
  }

  checkValidDate(date:Date,dueDate:any){
    return this.dateInput && this.dateInput > new Date() && this.dateInput < new Date(dueDate);
  }
  

}


