import { Component } from '@angular/core';
import { CmsNavigationComponent, AuthService, OccConfig, BaseSiteService } from '@spartacus/core';
import { NavigationNode, CmsComponentData, NavigationService } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { ContentService } from '../../spartacus/services/content.service';

@Component({
  selector: 'app-explicit-footer',
  templateUrl: './explicit-footer.component.html',
  styleUrl: './explicit-footer.component.scss'
})
export class ExplicitFooterComponent {

  explicitFooter$: Observable<NavigationNode> = this.service.getNavigationNode(
    this.componentData.data$
  );
  explicitFooterData!: any;
  logoData : Observable<any>= this.paraService.getNavigation("SeqirusLogoFooterLink");



  constructor(
    protected componentData: CmsComponentData<CmsNavigationComponent>,private compoennt:CmsComponentData<any>,
    protected service: NavigationService,protected paraService:ContentService,private authService:AuthService,
    protected config: OccConfig,private baseSiteService:BaseSiteService
  ) {}

  ngOnInit(){
    this.compoennt.data$.subscribe((data)=>{
      this.explicitFooterData =data;
    })
  }
  getImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.url;
  }
}
