<div class="footer_container" *ngIf="(footernode$ | async) as footernodes">
    <div class="container-fluid">
        <div class="row">
            <ng-container *ngFor="let node of footernodes.children">
            <div *ngIf="node.title == 'Footer Logo'" class="col-md-4">
                <div class="footer-logo">
					<div class="logo_seqirus" *ngIf="(logoData | async) as data">
					<img [src]='getImage(data.media)' alt="seqirus">
                    </div>
                    <div class="fotter_txt" *ngIf="(visitsite | async) as site" [innerHTML]="site.content"></div>
				</div>
                <ng-container *ngIf="(ukFooterData | async) as ukData">
                <div class="footertermsheaderuk" style="color: #fff;">{{ukData.heading1}}</div>
                     <p class="footerpara-1 footermar" style="color: #fff;">{{ukData.text1}}</p>
                     <div class="footertermsheaderuk" style="color: #fff;">{{ukData.heading2}}</div>
                     <div class="footerpara-1" [innerHTML]="ukData.text2"></div>
                     <div class="footertermsheaderuk" style="color: #fff;">{{ukData.heading3}}</div>
                     <div class="footerpara-1" [innerHTML]="ukData.text3"></div>
                </ng-container>
            </div>
            <ng-container *ngIf="(currentSite == 'seqirusb2b-uk' && node.title === 'Navigate') || ((!isLoggedIn || (isLoggedIn && !userInfo?.webEnabled)) && node.title === 'Navigate');else forlogin">
                <div class="mob-footer-md-3">

                        <div class="footerNavPart" [ngClass]="currentSite == 'seqirusb2b-uk'? 'footer_headinguk' : 'footer_heading'">{{node.title}}</div>

                        <ul class="footerNavPart navigate_menu footer_menu_list_nav" [ngClass]="currentSite == 'seqirusb2b-uk'? 'footer_menu_listuk' : 'footer_menu_list'">
                            <ng-container *ngFor="let child of node.children">

                                <li *ngIf="child.title != 'Returns' && child.title != 'Cookie Policy'"><a (click)="handleLinks(child.url, '')" target="_blank">{{child.title}}</a></li>
                                <li *ngIf="child.title === 'Cookie Policy'"><a (click)="gocookies(child.url)" target="_blank">{{child.title}}</a></li>

                                <li *ngIf="child.title === 'Returns'"><a [routerLink]="child.url" id="return_links">{{child.title}}</a></li>
                            </ng-container>

                        </ul>
                </div>
            </ng-container>
                <ng-template #forlogin>
                    <div *ngIf="node.title === 'Navigate'" class="mob-footer-md-3"></div>
                </ng-template>
                <div *ngIf="node.title === 'Website Terms of Use' && currentSite=='seqirusb2b-flu360'" class="mob-footer-md-5">
                        <div class="footer_heading">{{node.title}}</div>
                        <ul class="footer_menu_list">
                        <ng-container *ngFor="let child of termsnConditionNodes">
                            <li *ngIf="child.title == 'Terms and Conditions of Returnability'"><a [routerLink]="child.url" target="_blank" >{{child.title}}</a></li>
                            <li *ngIf="child.title == 'Terms and Conditions of Privacy'"><a href="{{child.url}}" target="_blank" >{{child.title}}</a></li>

                            <li *ngIf="child.title != 'Terms and Conditions of Privacy' && child.title != 'Terms and Conditions of Returnability'"><a href="{{child.url}}" target="_blank" >{{child.title}}</a></li>
                        </ng-container>
                        </ul>
                </div>
                <div *ngIf="node.title === 'Website Terms of Use' && currentSite=='seqirusb2b-uk'" class="col-md-3 col-xs-12 marTop-30">
                    <div class="footer_headinguk">{{node.title}}</div>
                    <ul class="footer_menu_listuk">
                        <ng-container *ngFor="let child of node.children">
                            <li *ngIf="child.title != 'Privacy Policy'"><a [routerLink]="child.url" target="_blank">{{child.title}}</a></li>
                            <li *ngIf="child.title === 'Privacy Policy'"><a [href]="child.url" target="_blank">{{child.title}}</a></li>
                        </ng-container>
                    </ul>
                </div>
            </ng-container>
            <div class="col-md-8 footer_parawidth" *ngIf="(ukFooterData |async) as ukData">
                <div class="footer_paraheadinguk">{{ukData.heading4}}</div>
                <p class="footerpara-2" [innerHTML]="ukData.text4"></p>
                <!-- <p class="footerpara-3">
                    In the case of a product complaint, please notify the CSL Seqirus customer services team on <a href="tel:0845 745 1500" class="footeritem2 doNotShowleavePopUp">0845 745 1500</a> or <a href="mailto:ukptc@seqirus.com" class="footerredlink3 doNotShowleavePopUp">ukptc@seqirus.com</a>
                    and provide a full description of the nature of the complaint, contact details for complaint follow-up, and the batch number(s) of the vaccine(s) concerned.</p> -->
            </div>
        </div>
        <div class="row">
            <div class="mob-footer-md-12">
                <div class="d-lg-none d-block d-sm-block margin-T20 margin-B20">
                <div class="logo_seqirus" *ngIf="(logoData | async) as data">

                <img [src]='getImage(data.media)' alt="seqirus">

                </div>
                <div class="fotter_txt" *ngIf="(visitsite | async) as site" [innerHTML]="site.content"></div>
            </div>
        </div>
        </div>
        <div class="row">
            <ng-container *ngIf="currentSite == 'seqirusb2b-flu360';else ukparacontent">
			    <div class="mob-footer-md-12 footer_txt_disclaimer" *ngIf="(footerPara | async) as para">
                    <div class ="content" [innerHTML]="para.content"></div>
                </div>
            </ng-container>
            <ng-template #ukparacontent>
                <div class="col-md-12 footer_txt_disclaimeruk" *ngIf="(ukFooterData | async) as ukData">
                    <p class="footer_copynote">{{ukData.trademarkText}}</p>
                </div>
            </ng-template>
        </div>

        <div class="row">
            <div class="mob-footer-md-12 fotter_bottom_logo">

            </div>
        </div>

    </div>
</div>
<div class="commonleaving_abandonedPop modal" role="dialog"  data-backdrop="static" [ngClass]="{'show': isModalOpen}"  id="commonleaving_abandonedPop">
    <div class="knowledgeHub_abandonedPopContent">
        <div class="knowledgeHub_abandonedPopHeader">
            <div class="knowledgeHub_abandonedPopTitle">{{popupdata?.headline}}</div>
            <div class="knowledgeHub_abandonedPopClose"(click)="closeModal()">X</div>
            <div class="clearboth"></div>
        </div>
        <div class="knowledgeHub_abandonedPopText">
            <p [innerHTML]="popupdata?.message1"></p>
            <p [innerHTML]="popupdata?.message2"></p>
        </div>
        <div class="knowledgeHub_abandonedPopBtns">
            <button class="knowledgeHub_abandonedPopBtn commonleave_popContBtn" ><a (click)="openUrl(popupdata?.link)">{{popupdata?.label1}}</a></button>
            <button
                class="knowledgeHub_abandonedPopBtn knowledgeHub_popCanceltBtn"(click)="closeModal()">{{popupdata?.label2}}</button>
        </div>
        <div class="knowledgedocnumberUk">{{popupdata?.jobBagCode}}</div>
    </div>
</div>