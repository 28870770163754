import { ChangeDetectorRef, Component } from '@angular/core';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { CmsService } from '@spartacus/core';


@Component({
  selector: 'app-user-section',
  templateUrl: './user-section.component.html',
  styleUrl: './user-section.component.scss'
})
export class UserSectionComponent {
  consentdata:any;
  intialconsent:boolean=true;
  successshow:boolean=false;
  enablePage:any;
  ukConsentPopupData:any;
  constructor(private userInfoService: UserInfoService,private cd:ChangeDetectorRef,private component: CmsService) {
  }
  ngOnInit(): void {
    this.component.getComponentData('ukConsentPopup').subscribe((data)=>{
      this.ukConsentPopupData = data;
      this.cd.detectChanges();
    });
    this.userInfoService.getconsentuk().subscribe(value => {
this.consentdata=value;
this.intialconsent=this.consentdata.isConsentGranted;
    });
    this.userInfoService.gettabdata().subscribe((data: any) => {
      this.enablePage = data;
    });
    this.cd.detectChanges();

  }
  onRadiochange(value:boolean){
   
      this.consentdata.isConsentGranted=value;
    
  
    this.cd.detectChanges();

  }
  submitconsent(){
   if(this.intialconsent!=this.consentdata.isConsentGranted){
    this.userInfoService.setconsentuk(this.consentdata.isConsentGranted).subscribe(
      (data) => {
        this.intialconsent==this.consentdata.isConsentGranted;
        this.successshow=true;
        this.cd.detectChanges();
      },
      (error) => {
        console.log('error');
      }
    );
   

   }
  }

  showStatus() {
    return this.userInfoService.checkAsmUser();
   }
  
}
