import { AfterViewInit, ChangeDetectorRef,QueryList, Component, ElementRef, HostListener,ViewChildren, ViewChild, OnInit  } from '@angular/core';
import { CmsBannerComponent, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { combineLatest, map, Observable } from 'rxjs';
import { ContentService } from '../../spartacus/services/content.service';

@Component({
  selector: 'app-flucelvax-cellbasedanimation',
  templateUrl: './flucelvax-cellbasedanimation.component.html',
  styleUrl: './flucelvax-cellbasedanimation.component.scss'
})
export class FlucelvaxCellbasedanimationComponent implements OnInit{
  constructor(public bannerComponent: CmsComponentData<CmsBannerComponent>, private config: OccConfig, private accessService: ContentService, private cd: ChangeDetectorRef) { }

 
  @ViewChild('sliderWrapper', { static: true }) sliderWrapper!: ElementRef;
  @ViewChildren('slider') sliders!: QueryList<ElementRef>; // Reference to all slider elements
  @ViewChild('arrowImage', { static: true }) arrowImage!: ElementRef; // Reference to the arrow image
  translateX = -window.innerWidth * 2; // Start with the third slide
  slideCount = 3;
  scrollAmount = 100; // Adjust this value to control the scroll amount
  pageScrollAmount = 45; // Smaller scroll amount for page scroll
  private lastScrollTop = 0;
  private sliderActive = false; // Flag to track if the slider is active
  
  ngOnInit(): void {}

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: Event): void {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    // Add or remove the fixed class based on scroll position
    if (scrollTop >= 200 && scrollTop < 1400) {
      this.sliderWrapper.nativeElement.classList.add('fixed');
    } else {
      this.sliderWrapper.nativeElement.classList.remove('fixed');
    }

    // Trigger arrow animation based on scroll direction
    if (scrollTop >= 200 ) {
      if (scrollTop > this.lastScrollTop) {
        this.arrowImage.nativeElement.classList.add('slide-down');
       this.arrowImage.nativeElement.classList.remove('slide-up');
      } 
    }

    // Activate the slider when scrolling down past 200px or up past 1350px
    if ((scrollTop >= 200 && scrollTop < 1400) || (scrollTop < 1350 && this.lastScrollTop > 1400)) {
      this.sliderActive = true;
    } else {
      this.sliderActive = false;
    }

    // Only update translateX if the slider is active
    if (this.sliderActive) {
      const direction = scrollTop > this.lastScrollTop ? 1 : -1; // Scroll down moves right, scroll up moves left
      this.translateX += direction * this.pageScrollAmount;
      this.translateX = Math.max(Math.min(this.translateX, 0), -window.innerWidth * (this.slideCount - 1));
      this.checkIfSliderComplete();
      this.updateOpacity();
    }

    this.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // For Mobile or negative scrolling
  }

  private updateOpacity(): void {
    const viewportWidth = window.innerWidth;
    this.sliders.forEach((slider: ElementRef, index: number) => {
      const sliderPosition = this.translateX + viewportWidth * index;
      const opacity = Math.max(0, Math.min(1, 1 - Math.abs(sliderPosition) / viewportWidth));
      slider.nativeElement.style.opacity = opacity.toString();
    });
  }

  private checkIfSliderComplete(): void {
    if (this.translateX <= -window.innerWidth * (this.slideCount - 1)) {
      this.arrowImage.nativeElement.classList.add('slide-up');
      this.arrowImage.nativeElement.classList.remove('slide-down');
      // alert('Slider ended'); // Alert when slider ends
      // this.translateX = 0; // Reset to the first slide for the next cycle
    }
  }
  

  // animationnode: Observable<any> = this.bannerComponent.data$;
  // mergedObject1: any = [];
  // @ViewChild('lazyGif') lazyGif!: ElementRef;
  // ngOnInit() {
  //   this.animationnode.subscribe(res => {
  //     this.animationlist(res);
  //   });
  // }
  // ngAfterViewInit() {
  //   this.initializeAnimation();
  //   this.lazyLoadGif();
  // }
  // animationlist(res: any) {
  //   const tilesList = res.animationNavList.split(" ");
  //   const observables = tilesList.map((el: any) => this.accessService.opeffeaturedResources(el));

  //   combineLatest(observables).pipe(
  //     map((results: any) => results.flat())
  //   ).subscribe((mergedResults: any) => {
  //     this.mergedObject1 = mergedResults;
  //     this.cd.detectChanges();
  //   });
  // }
  // getImage(image: any): string {
  //   return this.config.backend?.occ?.baseUrl + image.url;
  // }
  // lazyLoadGif() {
  //   const gifElement = this.lazyGif.nativeElement as HTMLImageElement;

  //   const isElementInViewport = (el: HTMLElement) => {
  //     const rect = el.getBoundingClientRect();
  //     return (
  //       rect.top >= 350 &&
  //       rect.left >= 0 &&
  //       rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
  //       rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  //     );
  //   };

  //   const handleScroll = () => {
  //     if (isElementInViewport(gifElement)) {
  //       gifElement.classList.add('is-visible');
  //     }
  //   };

  //   window.addEventListener('scroll', handleScroll);
  //   handleScroll(); // Initial check on page load
  // }
  // initializeAnimation() {
  //   if (screen.width > 1023) {
  //     const horizontal = document.querySelector<HTMLElement>('.animationhorizontal');
  //     const windowWidth: number = horizontal?.offsetWidth || 0;
  //     const windowWidth2: number = windowWidth * 2;
  //     let halfWindowWidth: number = windowWidth / 3;
  //     let animationScrollHeight: number = windowWidth * 3;

  //     if (screen.width > 900 && screen.width < 1250) {
  //       animationScrollHeight = windowWidth * 4;
  //       halfWindowWidth = windowWidth / 2;
  //     }

  //     const spaceHolder = document.querySelector<HTMLElement>('.space-holder');
  //     if (spaceHolder) {
  //       spaceHolder.style.height = `${animationScrollHeight}px`;
  //     }

  //     const opacityWidth: number = windowWidth - 950;

  //     document.addEventListener('scroll', (): void => {
  //       const sticky = document.querySelector<HTMLElement>('.Animationsticky');
  //       const introArrowSymbol = document.querySelector<HTMLElement>('.introarrowsymbol');
  //       const afterIntroText = document.querySelector<HTMLElement>('.afterintotext');
  //       const eggGroup = document.querySelector<HTMLElement>('#egggroup');
  //       const cellGroup = document.querySelector<HTMLElement>('#cellgroup');

  //       if (sticky) {
  //         const stickyOffsetTop: number = sticky.offsetTop;
  //         const opacityValue: number = stickyOffsetTop / 1000;
  //         const opacityValue1: number = (stickyOffsetTop - opacityWidth) / 1000;
  //         const opacityValue2: number = (stickyOffsetTop - (windowWidth + halfWindowWidth + opacityWidth)) / 1000;

  //         if (stickyOffsetTop <= (windowWidth2 + halfWindowWidth)) {
  //           if (stickyOffsetTop <= windowWidth) {
  //             if (introArrowSymbol) {
  //               introArrowSymbol.style.display = 'block';
  //               introArrowSymbol.style.opacity = `${1 - opacityValue}`;
  //               introArrowSymbol.style.transform = `translate(${-stickyOffsetTop}px, ${stickyOffsetTop / 2}px)`;
  //             }
  //             if (horizontal) {
  //               horizontal.style.transform = `translateX(+${stickyOffsetTop}px)`;
  //             }
  //             if (afterIntroText) {
  //               afterIntroText.style.opacity = `${1 - opacityValue}`;
  //             }
  //             if (eggGroup) {
  //               eggGroup.style.opacity = `${opacityValue1}`;
  //             }
  //           } else if (stickyOffsetTop <= (windowWidth + halfWindowWidth)) {
  //             if (introArrowSymbol) {
  //               introArrowSymbol.style.display = 'none';
  //             }
  //             if (horizontal) {
  //               horizontal.style.transform = `translateX(${windowWidth}px)`;
  //             }
  //             if (eggGroup) {
  //               eggGroup.style.opacity = '1';
  //             }
  //             // Add egg text animation logic here if needed
  //           } else {
  //             // Cell main animation
  //             if (cellGroup) {
  //               cellGroup.style.opacity = `${opacityValue2}`;
  //             }
  //             if (eggGroup) {
  //               eggGroup.style.opacity = `${1 - opacityValue2}`;
  //             }
  //             if (horizontal) {
  //               horizontal.style.transform = `translateX(${stickyOffsetTop - halfWindowWidth}px)`;
  //             }
  //           }
  //         } else {
  //           // Cell text animation
  //           if (cellGroup) {
  //             cellGroup.style.opacity = '1';
  //           }
  //           if (horizontal) {
  //             horizontal.style.transform = `translateX(${windowWidth2}px)`;
  //           }
  //         }
  //       }
  //     });
  //   }
  // }
}
