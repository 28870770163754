import { Component ,Renderer2,ElementRef} from '@angular/core';
import { CmsBannerComponent, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-fluad-rwe-third',
  templateUrl: './fluad-rwe-third.component.html',
  styleUrl: './fluad-rwe-third.component.scss'
})
export class FluadRweThirdComponent {

  constructor(public component: CmsComponentData<CmsBannerComponent>,private renderer: Renderer2, private el: ElementRef,protected config: OccConfig){
  }  

  thirdnode: Observable<any> = this.component.data$;
  getpdf(pdf: any): string {
    return this.config.backend?.occ?.baseUrl  + pdf.url + "&attachment=true";
  }
}
