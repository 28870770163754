<div *ngIf="seasonData | async as item">

  <div class="col-12" id="orders_topcontent">
    <div class="row">
    <div class="col-9 col-md-3 col-lg-2 right_border" id="orders_topcontentleftcontent">
      <h1 class="col-xs-12 orders_titeltop modified-h1">Invoices</h1>
    </div>
    <div class="col-3  col-md-4 col-lg-6  no-dis" id="orders_topcontentleftcontent">
      <div class="col-xs-12 orders_titeltop orders_titeltopNo" >{{getCount()}}</div>
    </div>

    <div class="col-12 col-md-5 col-lg-4" id="orders_topcontentleftcontent">


      <div class="invoice_us_dropdown">
        <select  (change)="selectedSeason($event)" class="current_sesson_dropdown" name="session" id="select_season" [(ngModel)]="finalSeason">

        <option  *ngFor="let obj of item.seqirusSeasonList; let i= index" [value]="obj" > {{(obj == item.preSeason)?'Pre-Book Season': ( (obj!= item.inSeason)?'Previous Season': 'Current Season')}}: {{obj}} </option>

                              </select>


                            </div>
      </div>
    </div>
  </div>
  <!-- count of sch payments -->
  <div *ngIf="getScheduledPaymentCount() > 0" class="sch_pay_linksection">
    <div style="flex: 1 1 0"><span class="schpay_linktext">You have {{getScheduledPaymentCount()}} <a class="all_inv_schpaylink" routerLink="/scheduled-payments">Scheduled Payment(s)</a></span></div>
</div>
  </div>
