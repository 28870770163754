import { ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ContentService } from '../../spartacus/services/content.service';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { BaseSiteService } from '@spartacus/core';


@Component({
  selector: 'app-open-credit-invoice-dashboard',
  templateUrl: './open-credit-invoice-dashboard.component.html',
  styleUrl: './open-credit-invoice-dashboard.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class OpenCreditInvoiceDashboardComponent {
  currentSite: string="";
  rbavalue:any;
  
  constructor(public contentService: ContentService,public userInfoService:UserInfoService,private baseSiteService:BaseSiteService,private cdr:ChangeDetectorRef ) {}
  invoiceNode: Observable<any> = this.userInfoService.getInvoiceData();

  ngOnInit(){
    this.baseSiteService.getActive().subscribe(site => {
      this.currentSite = site;
    })

    if(this.currentSite=='seqirusb2b-uk'){
      this.rbavalue=localStorage.getItem('rbapermission');
      this.rbavalue=JSON.parse(this.rbavalue);
      this.cdr.detectChanges(); 
  
      this.userInfoService.getswitchrba().subscribe(value => {
        if(value){
          this.rbavalue=value;
          this.cdr.detectChanges();
        }
      });
    }
  }
}
