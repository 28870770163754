import { ChangeDetectorRef, Component, ElementRef, Output, QueryList, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { Observable } from 'rxjs';
import { CmsComponentData } from '@spartacus/storefront';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { BaseSiteService } from '@spartacus/core';

declare var clickToAddress:any;
declare var window:any;
export function getCustomPaginatorIntl(){
const customPaginator = new MatPaginatorIntl();
customPaginator.itemsPerPageLabel = 'Showing';
return customPaginator;
}

@Component({
  selector: 'app-org-location',
  templateUrl: './org-location.component.html',
  styleUrl: './org-location.component.scss'
})
export class OrgLocationComponent {

  showingLabel:string = "Showing";
  pageSize:number=5;
  pageSizeControl=new FormControl(5);
  totalLength = 0;
  currentPage: number = 1;
  pageSizeOptions:number[]=[5,10,15];
  isLoading:boolean = false;
  isTableHasData:boolean = true;
  searchField:FormControl;
  mask:string='';
  
  displayedColumns: string[] = ['status','type','name','address','mainContact','phoneNumber','viewDetails']; // Define your columns here
  
  dataSource = new MatTableDataSource<any>();

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  
  @ViewChild(MatSort) sort!: MatSort;
  
  selectedElement: any | null = null;

  @ViewChild('dialogTemplate')
  dialogTemplate!: TemplateRef<any>;
  
  @ViewChild('tablemainrow')
  tableRows!:QueryList<ElementRef>;


  editElement: any;
  @Output() cc: any;
  
  orgLocationsUpdateForm: FormGroup;
  addShippingLocForm: FormGroup;
  
  editedFields: string[]=[];
  responseStatus!: Observable<any>;
  
  showSavelicModal:boolean=false;
  showReqLicModal:boolean=false;
  showThankYouModal: boolean=false;
  addLocModal:boolean= false;
  medProFlag: string="";
  comm_pref: boolean =false;

   @ViewChild('locations_savelicmodal')
  saveModalEle!: ElementRef; 
  @ViewChild('showThankYouModal')
  thankYouModalTemplate: any;
  @ViewChild('addLocModal')
  addLocModalTemplate: any;
  
  filteredTableData:any;
  isFilterOpen: boolean = false;
  count: number=0;
  showMaskGln: boolean = false;
  showMaskPhone: boolean = false;
  connectionSuccess: boolean = false;
  isRowClicked: boolean=false;
  safeContent!: SafeHtml;
  tableDataLoaded: boolean = true;
  thankYouDialogRef!: MatDialogRef<any>;
  currentSite?: string;
  @Output() businessType: any;
  showUKThankYouModal: boolean=false;
  
  thankYouModal: any;
  lastSortedCol: string='';
  orgrba:any;
  

constructor(private userService: UserInfoService,private cdr:ChangeDetectorRef,private fb:FormBuilder,public orgLocComponent: CmsComponentData<any>,private dialog:MatDialog,private router:Router,private sanitizer:DomSanitizer,private basesiteService:BaseSiteService,public renderer:Renderer2) {
  const alphanSpacePattern = new RegExp("^[A-Za-z\\s]+$");
  const emailPattern = new RegExp("^(?=(.{1,64}@.{1,255}))([!#$%&'*+\\-\\/=?\\^_`{|}~a-zA-Z0-9]{1,64}(\\.[!#$%&'*+\\-\\/=?\\^_`{|}~a-zA-Z0-9]{0,}){0,})@((\(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}\)|([a-zA-Z0-9-]{1,63}(\\.[a-zA-Z0-9-]{2,63}){1,}))$");
  const alphaNumSpacePattern = new RegExp("^(?!\\s)(?!.*\\s$)[a-zA-Z0-9][a-zA-Z0-9\\-\\,\\s]*$");

  this.searchField=new FormControl('');
  
  this.basesiteService.getActive().subscribe(siteId => {
    this.currentSite = siteId;
  });
  this.addShippingLocForm = this.fb.group({
      loc_firstname:["",[Validators.required,Validators.pattern(alphanSpacePattern)]],
      loc_lastname:["",[Validators.required,Validators.pattern(alphanSpacePattern)]],
      loc_email:["",[Validators.required,Validators.pattern(emailPattern)]],
      loc_job:['Select'],
      loc_phone:["",[Validators.required,Validators.pattern('^[1-9][0-9]{2}[0-9]{3}[0-9]{4}$')]],
      loc_ext:["",[Validators.pattern('^[0-9]*$'),Validators.maxLength(4)]],
      
      loc_orgname:["",[Validators.required,Validators.pattern(alphaNumSpacePattern)]],
      loc_glnnumber:[""],
      add_AddressLookup:[''],
      loc_add1:['',[Validators.required]],
      loc_add2:[''],
      loc_city:["",[Validators.required,Validators.pattern(alphanSpacePattern)]],
      loc_state:['',[Validators.required,Validators.pattern('^[a-zA-Z]+$')]],
      loc_zip:['',[Validators.required,Validators.pattern('^\\d{5}(-\\d{4})?$')]],
      
      loc_licname:['',[Validators.required,Validators.pattern("^(?!\\s)(?!.*\\s$)[A-Za-z0-9][A-Za-z0-9\\!\\=\\$\\%\\?\\+\\'\\*\\\"\\@\\&\\#\\<\\>\\/\\;\\:\\|\\[\\]\\{\\}\\(\\)\\\\\\^\\`\\~\\.\\_\\-\\,\\s]*$")]],
      loc_licnum:['',[Validators.required,Validators.pattern(/^[a-z0-9\-_><={,\]\/\+}\[\|\.\?\\\s]+$/i)]],
      loc_liclookup:[''],
      loc_licadd1:['',Validators.required],
      loc_licadd2:[''],
      loc_liccity:['',[Validators.required,Validators.pattern(alphanSpacePattern)]],
      loc_licstate:['',[Validators.required,Validators.pattern('^[a-zA-Z]+$')]],
      loc_liczip:['',[Validators.required,Validators.pattern('^\\d{5}?$')]],

      

    });

  
    this.orgLocationsUpdateForm = this.fb.group({
      loc_type:[""],
      loc_addressId:[""],
      loc_editfname:["",[Validators.required,Validators.pattern(alphanSpacePattern)]],
      loc_editlname:["",[Validators.required,Validators.pattern(alphanSpacePattern)]],
      loc_editemail:["",[Validators.required,Validators.pattern(emailPattern)]],
      
      loc_editphone:["",[Validators.required,Validators.pattern('^[1-9][0-9]{2}[0-9]{3}[0-9]{4}$')]],
      loc_editext:["",[Validators.pattern('^[0-9]*$'),Validators.maxLength(4)]],
      
      loc_editorgname:["",[Validators.required,Validators.pattern(alphaNumSpacePattern)]],
      loc_editglobalLocationNumber:["",this.glnRegexValidator()],
      loc_editaddlookup:[''],
      loc_editadd1:['',[Validators.required]],
      loc_editadd2:[''],
      loc_editcity:['',[Validators.required,Validators.pattern(alphanSpacePattern)]],
      loc_editstate:['',[Validators.required,Validators.pattern('^[a-zA-Z]+$')]],
      loc_editzip:['',[Validators.required,Validators.pattern('^\\d{5}(-\\d{4})?$')]],

      loc_editinvoiceemail:['',[Validators.required,Validators.pattern(emailPattern)]],
      loc_editaccstmtemail:['',[Validators.required,Validators.pattern(emailPattern)]],
      loc_commpreference:[0],

      loc_editlicname:['',[Validators.required,Validators.pattern("^(?!\\s)(?!.*\\s$)[A-Za-z0-9][A-Za-z0-9\\!\\=\\$\\%\\?\\+\\'\\*\\\"\\@\\&\\#\\<\\>\\/\\;\\:\\|\\[\\]\\{\\}\\(\\)\\\\\\^\\`\\~\\.\\_\\-\\,\\s]*$")]],
      loc_editlicnum:['',[Validators.required,Validators.pattern(/^[a-z0-9\-_><={,\]\/\+}\[\|\.\?\\\s]+$/i)]],
      loc_editliclookup:[''],
      loc_editlicadd1:['',[Validators.required]],
      loc_editlicadd2:[''],
      loc_editliccity:['',[Validators.required,Validators.pattern(alphanSpacePattern)]],
      loc_editlicstate:['',[Validators.required,Validators.pattern('^[a-zA-Z]+$')]],
      loc_editliczip:['',[Validators.required,Validators.pattern('^\\d{5}?$')]],

    });

  }
  applyMask(){
    this.mask='9999999.99999.9';
  }
  removeMask(){
    if(!this.addShippingLocForm.get('loc_glnnumber')?.value)
    this.mask='';
  }
  removeEditGlnMask(){
    if(!this.orgLocationsUpdateForm.get('loc_editglobalLocationNumber')?.value)
    this.mask='';
  }
  
  glnRegexValidator(){
    return (control:AbstractControl):{ [key:string]:any} | null => {
      return /^\d{7}.\d{5}.\d{1}?$/.test(control.value) ? null : {invalidPattern:true}
    }
  }
  
  licNumRegexValidator(){
    return (control:AbstractControl):{ [key:string]:any} | null => {
      return /^[a-z0-9\-_><={,\]\/\+}\[\|\.\?\\\s]+$/i.test(control.value) ? null : {invalidPattern:true}
    }
  }

  orgLocData: Observable<any> = this.orgLocComponent.data$;
  

  ngOnInit() {
    this.selectedElement = null;
    this.cc =null;

    this.orgLocData.subscribe((data)=>{
      this.safeContent = this.sanitizer.bypassSecurityTrustHtml(data.content);
    })
    
    localStorage.removeItem(this.selectedElement);

    this.addGlnValidation();
    
    this.fetchOrgLocationData();
    this.orgrba=localStorage.getItem('rbapermission');
    this.orgrba=JSON.parse(this.orgrba);

  }
  private addGlnValidation() {
    this.addShippingLocForm.get('loc_glnnumber')?.valueChanges.subscribe(() => {
      if (this.addShippingLocForm.get('loc_glnnumber')?.value === "") {
        this.addShippingLocForm.get('loc_glnnumber')?.setValidators([]);
      }
      else {
        this.addShippingLocForm.get('loc_glnnumber')?.setValidators(this.glnRegexValidator());
      }
      this.addShippingLocForm.get('loc_glnnumber')?.updateValueAndValidity({ emitEvent: false });
    });
    this.orgLocationsUpdateForm.get('loc_editglobalLocationNumber')?.valueChanges.subscribe(() => {
      if (this.orgLocationsUpdateForm.get('loc_editglobalLocationNumber')?.value === "") {
        this.orgLocationsUpdateForm.get('loc_editglobalLocationNumber')?.setValidators([]);
      }
      else {
        this.orgLocationsUpdateForm.get('loc_editglobalLocationNumber')?.setValidators(this.glnRegexValidator());
      }
      this.orgLocationsUpdateForm.get('loc_editglobalLocationNumber')?.updateValueAndValidity({ emitEvent: false });
    });
  }

  private fetchOrgLocationData() {
    
    this.addGlnValidation();
    this.tableDataLoaded = false;
    this.userService.getOrgLocData().subscribe((data) => {
      this.userService.updateOrgLocData(data);
      this.businessType = data.tableData[0].businessType;
      this.cdr.detectChanges();
      if(this.currentSite == 'seqirusb2b-flu360'){
      this.filteredTableData = data.tableData.map((item: any) => ({
        type: item.type,
        status: item.status,
        addressId: item.addressId,
        name: item.name,
        mainContact: (item.firstName) + " " + (item.lastName ? item.lastName : " "),
        address: item.address,
        phoneNumber: (item.phoneNumber) + " ext." + (item.phoneExt ? item.phoneExt : " "),
        phn: item.phoneNumber,
        ext: item.phoneExt ? item.phoneExt : "",
        addrLine1: item.line1,
        addrLine2: item.line2,
        city: item.city,
        state: item.state,
        zipCode: item.zip,
        email: item.email,
        firstName: item.firstName, lastName: item.lastName,
        gln: (item.globalLocationNumber == null || item.globalLocationNumber == '9999999999994' || item.globalLocationNumber == '0000000000000') ? '' : this.formatGln(item.globalLocationNumber),

        licenseName: item.licenseName,
        licenseNumber: item.licenseNumber,
        licenseAddress: item.licenseAddress,
        licenseAddrLine1: item.licLine1,
        licenseAddrLine2: item.licLine2,
        licCity: item.licCity,
        licState: item.licState,
        licZipCode: item.licZip,

        billingEmail: item.invoiceEmailId,
        payingEmail: item.acctStmtEmailId,
      }));

      this.totalLength = this.filteredTableData.length;

      this.dataSource = new MatTableDataSource(this.filteredTableData);

      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

      this.sort.sortChange.subscribe((sortEvent:Sort) => {
        this.applySortForTable(sortEvent);
      });
      this.updateTableData();
      this.tableDataLoaded = true;
      this.cdr.detectChanges();
    }

    });
  }
  formatGln(globalLocationNumber: string) {
   return `${globalLocationNumber.substring(0, 7)}.${globalLocationNumber.substring(7, 12)}.${globalLocationNumber.substring(12)}`;
  }

  applySortForTable(sortEvent:Sort){
    const active = sortEvent.active;
    let direction = sortEvent.direction;
    if(!direction){
      direction = 'asc';
      this.sort.direction = direction;
    }
    if(active !== this.lastSortedCol){
      direction = 'desc';
      this.sort.direction = 'desc';

    }
    this.lastSortedCol = active;
    
      this.filteredTableData = this.filteredTableData.sort((a:any,b:any) => {
        const valueA = a[active];
        const valueB = b[active];
        return (valueA < valueB? -1:1)*(direction === 'asc'? 1:-1);
      });
    
    this.updateTableData();
  }

  //Custom Pagination Changes
  updateTableData(){
    const starti= (this.currentPage - 1)*this.pageSize;
    const endi = starti+this.pageSize;
    this.dataSource.data = this.filteredTableData.slice(starti,endi);
  }
  onPageSizeChange(event:any){
    this.pageSize = parseInt(event.target.value,10);
    this.currentPage = 1;
    this.updateTableData();
  }
  goToLastPage(){
    const totalPages = Math.ceil(this.totalLength / this.pageSize);
    this.currentPage=totalPages;
    this.updateTableData();
  }

  goToFirstPage(){
    this.currentPage=1;
    this.updateTableData();
  }
  
  getPages(): any {
      const totalPages = Math.ceil(this.totalLength / this.pageSize);
      const pages: any = [];
      
      if(totalPages <= 5){
        for(let i=1;i<=totalPages;i++){
          pages.push(i);
        }
      return pages;
      }
      if(this.currentPage <= 5){
        for(let i=1;i<=5;i++){
          pages.push(i);
        }
        pages.push('...',totalPages);
      }
      else if(this.currentPage > totalPages-5){
        pages.push(1,'...');
        for(let i=totalPages-4;i<=totalPages;i++){
          pages.push(i);
        }
      }
      else{
        pages.push(1,'...');
        pages.push(this.currentPage -1,this.currentPage,this.currentPage+1);
        pages.push('...',totalPages);
      }
  
      return pages;
    }
    goToPage(page:number){
      this.currentPage = page;
      const starti = (page-1)*this.pageSize;
      this.dataSource.data = this.filteredTableData.slice(starti,starti+this.pageSize);
    }
  previousPage(){
    if(this.currentPage>1){
      this.goToPage(this.currentPage-1);
    }
  }
  nextPage(){
    const totlpages=Math.ceil(this.totalLength/this.pageSize);
    if(this.currentPage<totlpages){
      this.goToPage(this.currentPage+1);
    }
  }
  isFirstPage():boolean{
    return this.currentPage==1;
  }
  isLastPage():boolean{
    return this.currentPage==Math.ceil(this.totalLength/this.pageSize);
  }
  isCurrentPage(page:number):boolean{
    return this.currentPage==page;
  }
//pagination changes end

  checkValidEmail(data:any):boolean{
    return data && data != 'NA' && data != 'na' && data !== 'seqirusinvoicing@ups.com';
  }

  onCommPrefChange(row:any){
    this.comm_pref = this.orgLocationsUpdateForm.get('loc_commpreference')?.value == 1;
    if(!this.comm_pref){
      if(row.type == 'Billing'){
      row.billingEmail = null;
      this.orgLocationsUpdateForm.patchValue({'loc_editinvoiceemail':'NA'});
      this.orgLocationsUpdateForm.get('loc_editinvoiceemail')?.markAsDirty();
      this.orgLocationsUpdateForm.get('loc_editinvoiceemail')?.clearValidators();
      }
      else{
        row.payingEmail = null;
        this.orgLocationsUpdateForm.patchValue({'loc_editaccstmtemail':'NA'});
        this.orgLocationsUpdateForm.get('loc_editaccstmtemail')?.markAsDirty();
        this.orgLocationsUpdateForm.get('loc_editaccstmtemail')?.clearValidators();
      }
      this.orgLocationsUpdateForm.updateValueAndValidity();
      this.cdr.detectChanges();
    }
  }
  
  openModal(){
    this.addLocModal = true;
    if(this.currentSite == 'seqirusb2b-flu360'){
      setTimeout(()=>{
        this.callFetchify();
      },0);
    }
  }

  callFetchify(){
    
    this.cc.attach({
          search:     'add_AddressLookup',
          town:       'loc_city',
          postcode:  'loc_zip',
          line_1:     'loc_add1',
          line_2:     'loc_add2',
          county:    'loc_state'
        },
        {
          onResultSelected: (c2a: any, elements: any, address: any) => {
            this.addShippingLocForm.controls['loc_add1']?.setValue(address.line_1);
            this.addShippingLocForm.get('loc_add1')?.markAsDirty();
            this.addShippingLocForm.controls['loc_add2']?.setValue(address.line_2);
       this.addShippingLocForm.get('loc_add2')?.markAsDirty();
       this.addShippingLocForm.controls['loc_city']?.setValue(address.locality);
       this.addShippingLocForm.get('loc_city')?.markAsDirty();
       this.addShippingLocForm.controls['loc_state']?.setValue(address.province);
       this.addShippingLocForm.get('loc_state')?.markAsDirty();
       this.addShippingLocForm.controls['loc_zip']?.setValue(address.postal_code);
       this.addShippingLocForm.get('loc_zip')?.markAsDirty();

       this.addShippingLocForm.updateValueAndValidity();
       this.cdr.detectChanges();
       
          }
        }); 

      this.cc.attach({
          search:     'loc_liclookup',
          town:       'loc_liccity',
          postcode:  'loc_liczip',
          line_1:     'loc_licadd1',
          line_2:     'loc_licadd2',
          county:    'loc_licstate'
        },
        {
          onResultSelected: (c2a: any, elements: any, address: any) => {
            
            this.addShippingLocForm.controls['loc_licadd1']?.setValue(address.line_1);
            this.addShippingLocForm.get('loc_licadd1')?.markAsDirty();
            this.addShippingLocForm.controls['loc_licadd2']?.setValue(address.line_2);
            this.addShippingLocForm.get('loc_licadd2')?.markAsDirty();
            this.addShippingLocForm.controls['loc_liccity']?.setValue(address.locality);
            this.addShippingLocForm.get('loc_liccity')?.markAsDirty();
            this.addShippingLocForm.controls['loc_licstate']?.setValue(address.province);
            this.addShippingLocForm.get('loc_licstate')?.markAsDirty();
            var newLocal = address.postal_code;
            newLocal= newLocal.substring(0, address.postal_code.indexOf('-'));
            
            this.addShippingLocForm.controls['loc_liczip']?.setValue(newLocal);
            this.addShippingLocForm.get('loc_liczip')?.markAsDirty();

            this.addShippingLocForm.updateValueAndValidity();
            this.cdr.detectChanges();
      
          }
        }); 
        
    
  
  }
  sameAsShippingCheckBox(event:Event){
    const checked = (event.target as HTMLInputElement).checked;

    if(checked){
      this.addShippingLocForm.get('loc_licadd1')?.setValue(this.addShippingLocForm.get('loc_add1')?.value);
      this.addShippingLocForm.get('loc_licadd2')?.setValue(this.addShippingLocForm.get('loc_add2')?.value);
      this.addShippingLocForm.get('loc_liccity')?.setValue(this.addShippingLocForm.get('loc_city')?.value);
      this.addShippingLocForm.get('loc_licstate')?.setValue(this.addShippingLocForm.get('loc_state')?.value);
      var liczip = this.addShippingLocForm.get('loc_zip')?.value;
      liczip = liczip?.substring(0, liczip.indexOf('-'));
      this.addShippingLocForm.get('loc_liczip')?.setValue(liczip);
    }
    else{
      this.addShippingLocForm.get('loc_licadd1')?.reset();
      this.addShippingLocForm.get('loc_licadd2')?.reset();
      this.addShippingLocForm.get('loc_liccity')?.reset();
      this.addShippingLocForm.get('loc_licstate')?.reset();
      this.addShippingLocForm.get('loc_liczip')?.reset();

    }

  }
  
  filterClick(){
    this.isFilterOpen =!this.isFilterOpen;
  }

  applyFilter(event:Event){
    //const filterValue = (event.target as HTMLInputElement).value;
    const filterValue = this.searchField.value;
    if(this.currentSite == 'seqirusb2b-uk'){
      this.userService.sendFilterValue(filterValue);
    }
    this.dataSource.filter = filterValue || ''.trim().toLowerCase();
    if(this.dataSource.filteredData.length > 0){
    this.isTableHasData = true;
    }
    else{
      this.isTableHasData = false;
    }
    if(this.dataSource.paginator){
      this.dataSource.paginator.firstPage()
    }
  }

  viewDetails(row:any){
    this.selectedElement = this.selectedElement === row?null:row;
    this.isRowClicked=!this.isRowClicked;
    this.cdr.detectChanges();
  }

  isRowExpanded(row:any):boolean{
    return this.selectedElement === row;
  }
  onEdit(row:any){
    
    if(this.editElement == row){
      this.editElement = null;
    }
    else{
      this.editElement = row;
    }
    
    this.orgLocationsUpdateForm.setValue({
      loc_type:row.type,
      loc_addressId:row.addressId,
      loc_editfname:row.firstName,
      loc_editlname:row.lastName,
      loc_editemail:row.email,
      loc_editphone:row.phn,
      loc_editext:row.ext || "",
      
      loc_editorgname:row.name,
      loc_editglobalLocationNumber:row.gln?row.gln:"",
      loc_editaddlookup:"",
      loc_editadd1:row.addrLine1,
      loc_editadd2:row.addrLine2 || '',
      loc_editcity:row.city,
      loc_editstate:row.state,
      loc_editzip:row.zipCode,
      
      loc_editinvoiceemail:this.checkValidEmail(row.billingEmail)?row.billingEmail:'',
      loc_editaccstmtemail:this.checkValidEmail(row.payingEmail)?row.payingEmail:'',
      loc_commpreference:this.checkValidEmail(row.billingEmail?row.billingEmail:row.payingEmail)?1:0,

      loc_editlicname:row.licenseName || '',
      loc_editlicnum:row.licenseNumber || '',
      loc_editliclookup:"",
      loc_editlicadd1:row.licenseAddrLine1 || '',
      loc_editlicadd2:row.licenseAddrLine2 || '',
      loc_editliccity:row.licCity || '',
      loc_editlicstate:row.licState || '',
      loc_editliczip:row.licZipCode || '',
    });
    this.orgLocationsUpdateForm.markAsUntouched();

     //save original loc api call
     const originalLocData = this.orgLocationsUpdateForm.value;
     originalLocData.modifiedQualifiers=this.editedFields.join(',') || "";
     this.userService.saveOriginalLocData(originalLocData);

     this.cdr.detectChanges();

    this.cc.attach({
      search:     'loc_editaddlookup',
      town:       'loc_editcity',
      postcode:  'loc_editzip',
      line_1:     'loc_editadd1',
      line_2:     'loc_editadd2',
      county:    'loc_editstate'
    },
    {
      onResultSelected: (c2a: any, elements: any, address: any) => { 
       
       this.orgLocationsUpdateForm.controls['loc_editadd1']?.setValue(address.line_1);
       this.orgLocationsUpdateForm.get('loc_editadd1')?.markAsDirty();
       this.orgLocationsUpdateForm.controls['loc_editadd2']?.setValue(address.line_2);
       this.orgLocationsUpdateForm.get('loc_editadd2')?.markAsDirty();
       this.orgLocationsUpdateForm.controls['loc_editcity']?.setValue(address.locality);
       this.orgLocationsUpdateForm.get('loc_editcity')?.markAsDirty();
       this.orgLocationsUpdateForm.controls['loc_editstate']?.setValue(address.province);
       this.orgLocationsUpdateForm.get('loc_editstate')?.markAsDirty();
       this.orgLocationsUpdateForm.controls['loc_editzip']?.setValue(address.postal_code);
       this.orgLocationsUpdateForm.get('loc_editzip')?.markAsDirty();
       this.cdr.detectChanges();
      }
    
    }); 
    //for license address
    this.cc.attach({
      search:     'loc_editliclookup',
      town:       'loc_editliccity',
      postcode:  'loc_editliczip',
      line_1:     'loc_editlicadd1',
      line_2:     'loc_editlicadd2',
      county:    'loc_editlicstate'
    },
    {
      onResultSelected: (c2a: any, elements: any, address: any) => {
        
        this.orgLocationsUpdateForm.controls['loc_editlicadd1']?.setValue(address.line_1);
        this.orgLocationsUpdateForm.get('loc_editlicadd1')?.markAsDirty();
        this.orgLocationsUpdateForm.controls['loc_editlicadd2']?.setValue(address.line_2);
        this.orgLocationsUpdateForm.get('loc_editlicadd2')?.markAsDirty();
       this.orgLocationsUpdateForm.controls['loc_editliccity']?.setValue(address.locality);
       this.orgLocationsUpdateForm.get('loc_editliccity')?.markAsDirty();
       this.orgLocationsUpdateForm.controls['loc_editlicstate']?.setValue(address.province);
       this.orgLocationsUpdateForm.get('loc_editlicstate')?.markAsDirty();
       var newLocal = address.postal_code;
      newLocal= newLocal.substring(0, address.postal_code.indexOf('-'));
       this.orgLocationsUpdateForm.controls['loc_editliczip']?.setValue(newLocal);
       this.orgLocationsUpdateForm.get('loc_editliczip')?.markAsDirty();

       this.cdr.detectChanges();
      }
    });
  }
  close(){
    this.selectedElement = null;
    this.editElement = null;
    this.isRowClicked=false;
  }

  modifyForm(){
   this.showSavelicModal = false;
   this.renderer.removeStyle(document.querySelector('.mat-typography'),'overflow-y');
   this.cdr.detectChanges(); 

  }
  modifyNewLocForm(){
    this.showReqLicModal=false;
    this.addLocModal = true;
    this.cdr.detectChanges();
    
  }
  
  validateLicense(){
    this.isLoading = true;
    if(this.addShippingLocForm.valid){
    this.userService.validateLicense(getDataforLicense(this.addShippingLocForm.value)).subscribe(
      (data) => {
      
      var validLicense = data;
      if(validLicense == 'Y'){
        this.medProFlag = "Y";
        this.isLoading = false;
        this.submitNewLocForm();
      }
      else if(validLicense == 404 || validLicense == 'N' || validLicense == 'U'){
        
        //this.addLocModal = false;
        this.connectionSuccess = true;
        this.showReqLicModal=true;
        const backdrop = document.querySelector('.modal-backdrop');
    if(backdrop){
      backdrop.addEventListener('click',()=>{
        this.modifyNewLocForm();
      })
    }
        this.isLoading = false;
        this.cdr.detectChanges();
        this.medProFlag = "N";
      }
      else if(validLicense == 400 || validLicense == 'connectionException' || validLicense == ''){
        this.connectionSuccess = false;
        this.showReqLicModal=true;
        this.medProFlag="N";
        this.isLoading = false;
        this.cdr.detectChanges();
      
      }
    },
    (error) => {
      this.connectionSuccess =false;
      this.showReqLicModal=true;
      this.medProFlag="N";
      this.isLoading = false;
      this.cdr.detectChanges();
    }
    );
    }
    else{
      this.addShippingLocForm.markAllAsTouched();
      this.isLoading=false;
    }
  }
  submitNewLocForm(){
    this.isLoading = true;
    this.cdr.detectChanges();
    if(this.addShippingLocForm.valid){
    
    const addLocData = this.addShippingLocForm.value;
    
    addLocData.loc_isMedProLicenseVerified = this.medProFlag;
    addLocData.loc_addressId="";
    addLocData.loc_type="Shipping";
    Object.keys(this.addShippingLocForm.controls).forEach((fieldName => {
      if(this.addShippingLocForm.get(fieldName)?.dirty){
        this.editedFields.push(fieldName.substring(fieldName.indexOf('_')+1));
      }
    }))
    addLocData.modifiedQualifiers=this.editedFields.join(',') || "";
    
    this.userService.addOrgLocation(addLocData).subscribe((resposne) => {
      
      this.dialog.closeAll();
      this.showReqLicModal=false;
      this.isLoading=false;
      this.thankYouDialogRef = this.dialog.open(this.thankYouModalTemplate,{
        hasBackdrop:true,
        panelClass:'thank-you-dialog'
      });
      this.thankYouDialogRef.backdropClick().subscribe(() => {
        this.thankYouClose();
      })
      this.addLocModal=false;
      this.cdr.detectChanges();
    },
    (error) => {
      this.isLoading=false;
      console.log('error');
    }
    
  );
  
  }
  else{

  }

      
  }
  showThankyouModal(data:boolean){
   
    if(data==true){
     // this.showUKThankYouModal=true;
     this.thankYouModal.show();
     this.renderer.setStyle(document.querySelector('.mat-typography'),'overflow-y','auto');
     this.addLocModal=false;
     this.renderer.setStyle(document.querySelector('.modal-backdrop'),'opacity','0');
      
      this.cdr.detectChanges();
      
    }
  }
  closeAddLocModal(){
    this.addLocModal=false;
    
    const url = (this.router.url).split('?')[0].split('#')[0];
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([url.substring(url.lastIndexOf('/')+1)]).then(()=>{
        this.cdr.detectChanges();

        });
  }
  thankYouClose(){
    
    const backdrop = document.querySelector('.modal-backdrop');
      if(backdrop){
        backdrop.remove();
      }
      window.location.reload();
    
  }

  submitUpdateLocForm(){
    this.isLoading = true;
    this.renderer.removeStyle(document.querySelector('.mat-typography'),'overflow-y');
        
    Object.keys(this.orgLocationsUpdateForm.controls).forEach((fieldName => {
      if(this.orgLocationsUpdateForm.get(fieldName)?.dirty){
        this.editedFields.push(fieldName.substring(fieldName.indexOf('_')+1));
      }
    }))
    
    const updateLocData = this.orgLocationsUpdateForm.value;
    updateLocData.modifiedQualifiers=this.editedFields.join(',') || ""; 
    updateLocData.loc_editisMedProLicenseVerified=this.medProFlag;
    this.callUpdateLocApi(updateLocData);
    
  }
  private callUpdateLocApi(updateLocData: any) {
    this.userService.updateLocationData(updateLocData).subscribe(
      (data) => {

        this.isLoading = false;
        this.dialog.closeAll();
        this.cdr.detectChanges();
        const url = (this.router.url).split('?')[0].split('#')[0];
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([url.substring(url.lastIndexOf('/')+1)]).then(()=>{
        this.cdr.detectChanges();

        });
      },
      (error) => {
        console.log('error');
        this.isLoading = false;
      }
    );
  }

  validateLicenseUpdateLoc() {
    
    if(this.orgLocationsUpdateForm.get('loc_type')?.value === 'Shipping'){
      this.orgLocationsUpdateForm.get('loc_editinvoiceemail')?.disable();
      this.orgLocationsUpdateForm.get('loc_editaccstmtemail')?.disable();
      this.orgLocationsUpdateForm.get('loc_editinvoiceemail')?.updateValueAndValidity();
	this.orgLocationsUpdateForm.get('loc_editlicname')?.enable();
      this.orgLocationsUpdateForm.get('loc_editlicnum')?.enable();
      this.orgLocationsUpdateForm.get('loc_editliccity')?.enable();
      this.orgLocationsUpdateForm.get('loc_editlicstate')?.enable();
      this.orgLocationsUpdateForm.get('loc_editliczip')?.enable();
      if(!this.orgLocationsUpdateForm.get('loc_editglobalLocationNumber')?.value){
        this.orgLocationsUpdateForm.get('loc_editglobalLocationNumber')?.clearValidators();
        this.orgLocationsUpdateForm.get('loc_editglobalLocationNumber')?.updateValueAndValidity();
      }
      this.orgLocationsUpdateForm.get('loc_editlicadd1')?.enable();
    }
    else{
      this.orgLocationsUpdateForm.get('loc_editaccstmtemail')?.enable();
      this.orgLocationsUpdateForm.get('loc_editinvoiceemail')?.enable();
    }
    if(this.orgLocationsUpdateForm.get('loc_type')?.value === 'Billing' || this.orgLocationsUpdateForm.get('loc_type')?.value === 'Paying'){
      this.orgLocationsUpdateForm.get('loc_editlicname')?.disable();
      this.orgLocationsUpdateForm.get('loc_editlicnum')?.disable();
      this.orgLocationsUpdateForm.get('loc_editliccity')?.disable();
      this.orgLocationsUpdateForm.get('loc_editlicstate')?.disable();
      this.orgLocationsUpdateForm.get('loc_editliczip')?.disable();
      this.orgLocationsUpdateForm.get('loc_editglobalLocationNumber')?.disable();
      this.orgLocationsUpdateForm.get('loc_editlicadd1')?.disable();

    }
    if(this.orgLocationsUpdateForm.get('loc_type')?.value === 'Billing'){
      this.orgLocationsUpdateForm.get('loc_editaccstmtemail')?.disable();
      
	if(this.orgLocationsUpdateForm.get('loc_commpreference')?.value === 0){
		this.orgLocationsUpdateForm.get('loc_editinvoiceemail')?.clearValidators();
		this.orgLocationsUpdateForm.get('loc_editinvoiceemail')?.updateValueAndValidity();
	}
    }
    if(this.orgLocationsUpdateForm.get('loc_type')?.value === 'Paying'){
      this.orgLocationsUpdateForm.get('loc_editinvoiceemail')?.disable();
      
	if(this.orgLocationsUpdateForm.get('loc_commpreference')?.value === 0){
		this.orgLocationsUpdateForm.get('loc_editaccstmtemail')?.clearValidators();
		this.orgLocationsUpdateForm.get('loc_editaccstmtemail')?.updateValueAndValidity();
	}
    }
    
    const invalidFields:string[]=[]
    Object.keys(this.orgLocationsUpdateForm.controls).forEach((key) => {
      if(this.orgLocationsUpdateForm.get(key) && this.orgLocationsUpdateForm.get(key)?.invalid){
        invalidFields.push(key);
      }
    });
    
   if(this.orgLocationsUpdateForm.valid){
      this.isLoading=true;
      if(this.orgLocationsUpdateForm.get('loc_type')?.value === 'Shipping'){
        
        this.userService.validateLicense(getDataforUpdateLicense(this.orgLocationsUpdateForm.value)).subscribe(
      (data) => {
        
        var validLicense = data;
        if(validLicense == 'Y'){
          this.medProFlag = "Y";
          this.isLoading = false;
          this.submitUpdateLocForm();
        }
      else if(validLicense == 404 || validLicense == 'N'  || validLicense == 'U'){
        this.connectionSuccess=true;
        this.showSavelicModal =true;
        this.renderer.setStyle(document.querySelector('.mat-typography'),'overflow-y','hidden');
        
        this.isLoading = false;
        this.cdr.detectChanges();
        this.medProFlag = "N";
        
      }
      else if(validLicense == 400 || validLicense == 'connectionException' || validLicense == ''){
        this.connectionSuccess=false;
        this.showSavelicModal =true;
        this.renderer.setStyle(document.querySelector('.mat-typography'),'overflow-y','hidden');
        
        this.medProFlag="N";
        this.isLoading = false;
        this.cdr.detectChanges();
      
      }
        
      },
      (error) => {
        this.connectionSuccess=false;
       this.showSavelicModal =true;
       this.renderer.setStyle(document.querySelector('.mat-typography'),'overflow-y','hidden');
        
        this.medProFlag="N";
        this.isLoading = false;
        this.cdr.detectChanges();
      }
    );
      }
      else{
      this.submitUpdateLocForm();
      }
    }
    else{
      
      this.orgLocationsUpdateForm.markAllAsTouched();
      this.orgLocationsUpdateForm.updateValueAndValidity();
    }
  }
  
  sortTest(val:any){
    
    const sortState: Sort = {active: val, direction: 'asc'};    
    this.sort.active = sortState.active;
    this.sort.direction = sortState.direction;
    this.sort.sortChange.emit(sortState);

  }

  ngOnDestroy(){
    
    this.cc = null;
    const ccele = document.getElementById('cc_c2a');
    ccele?.remove();
  }
  ngAfterViewchecked():void{
    const  saveModal = this.saveModalEle.nativeElement;
    saveModal.addEventListener('hidden.bs.modal',(event:any) => {
      if(event.target === saveModal){
        this.modifyNewLocForm();
      }
    })
    
  }

  ngAfterViewInit(){
    if(this.currentSite == 'seqirusb2b-flu360'){
    this.sort.active ='type';
    this.sort.direction='desc';
    }
    const ccele = document.getElementById('cc_c2a');
    
    if(!this.cc && this.currentSite == 'seqirusb2b-flu360'){

      this.cc = new clickToAddress({
      accessToken: '252fa-0e273-cf8ab-03341',
      countryMatchWith: 'text', 
      enabledCountries: ['United States'],
      domMode: 'id',
      gfxMode: 1,
      style: {
        ambient: 'light', // Use white as main interface color
        accent: 'default' // Use default secondary color for interface
      },
      texts: {
      default_placeholder: 'Start with post/zip code or street',
      },
      showLogo: true,
      disableAutoSearch:false,
    
    });
    }
    else if(!this.cc && this.currentSite == 'seqirusb2b-uk'){

      this.cc = new clickToAddress({
      accessToken: '7669a-89946-0db5f-06dae',
      countryMatchWith: 'text', 
      enabledCountries: ['United Kingdom'],
      domMode: 'id',
      gfxMode: 1,
      style: {
        ambient: 'light', // Use white as main interface color
        accent: 'default' // Use default secondary color for interface
      },
      texts: {
      default_placeholder: 'Start with post/zip code or street',
      },
      showLogo: true,
      disableAutoSearch:false,
    
    });
    const thankyouEle = document.getElementById('locations_tymodaluk') as HTMLElement;
    this.thankYouModal = new window.bootstrap.Modal(thankyouEle);
    thankyouEle.addEventListener('click',(event)=>{
      const target = event.target as HTMLElement;
      if(target.classList.contains('modal')){
        this.thankYouClose();
      }
    });
  }
  
    
  }

  onMouseEnterPhn(){
    this.showMaskPhone = true;
  }
  onMouseLeavePhn(){
    this.showMaskPhone = false;
  }
 
}


function getDataforUpdateLicense(value: any): any {
  return {
    licenceName:value.loc_editlicname,
    licenceNumber:value.loc_editlicnum,
    licenceState:value.loc_editlicstate
  }
}  

function getDataforLicense(value: any): any {
  return {
    licenceName:value.loc_licname,
    licenceNumber:value.loc_licnum,
    licenceState:value.loc_licstate
  }
}

