import { Injectable } from '@angular/core';
import { Subject } from 'rxjs'; // Use Subject for simple events

@Injectable({
  providedIn: 'root'
})
export class JoinAccountService {
  private readonly formSubmittedSource = new Subject<void>(); // No data needed
  formSubmitted$ = this.formSubmittedSource.asObservable();

  triggerFormSubmitted() {
    this.formSubmittedSource.next();
  }
}

