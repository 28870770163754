import { ChangeDetectorRef, Component } from '@angular/core';
import { CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { ContentService } from '../../spartacus/services/content.service';
import { ReturnsUkService } from '../returns-uk.service';

@Component({
  selector: 'app-returns-api-failure',
  templateUrl: './returns-api-failure.component.html',
  styleUrl: './returns-api-failure.component.scss'
})
export class ReturnsApiFailureComponent {
constructor(public component: CmsComponentData<CmsBannerComponent>, protected config: OccConfig, public accessService: ContentService, public cd: ChangeDetectorRef,private cmsService:CmsService, private returnsUkService: ReturnsUkService) {
  }
   isReturnFailed: boolean = false;
   sorryMsg : Observable<any> = this.component.data$;
   ngOnInit(){
    this.returnsUkService.getReturnFailurePopup().subscribe(res=>{
      this.isReturnFailed = res;
      this.cd.detectChanges();
    });
   }
}
