<div class="col-xs-12 docnumberbox" id="home_planningcontent">
    <div class="col-xs-12 home_topfirst">
        <div class="col-xs-12 col-md-6 docleftUK" id="home_planningleftcontent" [innerHTML]="middledata?.content">
        </div>
        <div class="col-sm-12 col-md-6" id="home_planningrightcontent">
            <div class="col-sm-12" id="home_planningrighttext" [innerHTML]=middledata?.h2content></div>
            <div class="col-sm-12" id="home_planninglearn" [innerHTML]=middledata?.paragraphcontent></div>
            <div class="col-sm-12 margin-T20" id="home_planningrighttext">{{middledata?.bottomContent}}</div>
        </div>
    </div>
    <div class="col-xs-12 docnumberUk home_planningcontent_jobbag">{{middledata?.jobBagContent}}</div>
</div>