import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-fourth-cell',
  templateUrl: './fourth-cell.component.html',
  styleUrl: './fourth-cell.component.scss'
})
export class FourthCellComponent {
  node1: Observable<any> = this.component.data$;
  constructor(public component: CmsComponentData<CmsBannerComponent>,private http:HttpClient,private cmsService:CmsService, protected config: OccConfig) {
}

downloadPdf(url:string){
  return this.config.backend?.occ?.baseUrl + url + "&attachment=true";
}

}
