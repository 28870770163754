import { ChangeDetectorRef, Component, Renderer2 } from '@angular/core';
import { CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { ContentService } from '../../spartacus/services/content.service';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-custom-returns-thankyoumaterials',
  templateUrl: './custom-returns-thankyoumaterials.component.html',
  styleUrl: './custom-returns-thankyoumaterials.component.scss'
})
export class CustomReturnsThankyoumaterialsComponent {
constructor(public component: CmsComponentData<CmsBannerComponent>, protected config: OccConfig, public accessService: ContentService, public cd: ChangeDetectorRef,private cmsService:CmsService) {
  }
  // materialscomponent : Observable<any> = this.component.data$;
  materialscomponent: Observable<any> = this.cmsService.getComponentData('seqirusReturnsMaterialComponent');
  getImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.url;
  }
  getDesktopImage(image: any): string{
    return this.config.backend?.occ?.baseUrl + image.desktop.url;
  }
}
