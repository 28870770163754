import { Component } from '@angular/core';
import { AsmComponentService, CustomerEmulationComponent } from '@spartacus/asm/components';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { UserAccountFacade } from '@spartacus/user/account/root';

@Component({
  selector: 'app-flu360-asm-cusomer-emulation',
  templateUrl: './flu360-asm-cusomer-emulation.component.html',
  styleUrl: './flu360-asm-cusomer-emulation.component.scss'
})
export class Flu360AsmCusomerEmulationComponent extends CustomerEmulationComponent{


  constructor(public userInfoService: UserInfoService,protected override asmComponentService: AsmComponentService,protected override userAccountFacade: UserAccountFacade){
    super(asmComponentService, userAccountFacade);
  }

  override ngOnInit() {
    this.subscription.add(
      /* this.userService.get().subscribe((user) => {
        if (user) this.customer = user;
      }) */
     this.userInfoService.fetchUserDataForASM().subscribe((user) => {
      if(user)this.customer = user;
     })
    );
    this.isCustomerEmulationSessionInProgress$ =
      this.asmComponentService.isCustomerEmulationSessionInProgress();
  }

  override logoutCustomer() {
    localStorage.removeItem('isSearchByProfile');
    localStorage.removeItem('rbapermission');
    this.asmComponentService.logoutCustomer();
  }

  override ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
