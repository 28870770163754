import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelpWidgetComponent } from './help-widget/help-widget.component';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { BannerModule, OutletPosition, provideOutlet } from '@spartacus/storefront';
import { HomepageSolutionComponent } from './homepage-solution/homepage-solution/homepage-solution.component';
import { AccessResourcesComponent } from './access-resources/access-resources.component';
import { MiddleGreybannerComponent } from './middle-greybanner/middle-greybanner.component';
import { AlertBannerComponent } from './alert-banner/alert-banner.component';
import { CustomfilterPipe } from './Pipes/customfilter.pipe';
import { DateformatPipe } from './Pipes/dateformat.pipe';
import { HomepageReferenceComponent } from './homepage-reference/homepage-reference.component';
import { HomepageUKProductsComponent } from './homepage-uk-products/homepage-uk-products.component';
import { HomepageUKPISlotComponent } from './homepage-uk-pislot/homepage-uk-pislot.component';
import { JobbagcodeUkComponent } from './jobbagcode-uk/jobbagcode-uk.component';
import { RouterModule } from '@angular/router';
import { TopbannerUkComponent } from './topbanner-uk/topbanner-uk.component';
import { HelpnfaqsearchPipe } from './Pipes/helpnfaqsearch.pipe';
import { SeasonspiltPipe } from './Pipes/seasonspilt.pipe';
import { HavingTroubleComponent } from './having-trouble/having-trouble.component';
import { MultipleSoldtoPopupComponent } from './multiple-soldto-popup/multiple-soldto-popup.component';
import { MatTableModule } from '@angular/material/table';
import { MobileUkRedirectionComponent } from './mobile-uk-redirection/mobile-uk-redirection.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UkSplashPageComponent } from './uk-splash-page/uk-splash-page.component';
import { RegJoinAccExitPopupComponent } from './reg-join-acc-exit-popup/reg-join-acc-exit-popup.component';
import { RemovehypenPipe } from './Pipes/removehypen.pipe';

@NgModule({
  declarations: [
    HelpWidgetComponent,
    HomepageSolutionComponent,
    AccessResourcesComponent,
    AlertBannerComponent,
    CustomfilterPipe,
    DateformatPipe,
    HomepageReferenceComponent,
    HomepageUKProductsComponent,
    HomepageUKPISlotComponent,
    JobbagcodeUkComponent,
    TopbannerUkComponent,
    HelpnfaqsearchPipe,
    SeasonspiltPipe,
    HavingTroubleComponent,
    MultipleSoldtoPopupComponent,
    MobileUkRedirectionComponent,
    UkSplashPageComponent,
    RegJoinAccExitPopupComponent,
    RemovehypenPipe,
  ],
  imports: [
    CommonModule,
    BannerModule,
    RouterModule,
    MatTableModule,
    FormsModule,
    ReactiveFormsModule,
    ConfigModule.withConfig({
      cmsComponents: {
        RotatingImagesComponent:{
          component:HomepageSolutionComponent
         },
         SeqirusHomePageParagraphComponent:{
          component:MiddleGreybannerComponent
         },
         SeqirusTilesAccessListComponent:{
          component: AccessResourcesComponent
         },
         SeqirusAlertBoxComponent:{
          component: AlertBannerComponent
         },
         SeqirusReferenceComponent:{
          component: HomepageReferenceComponent
         },
         SeqirusHomePageProductBannerComponent:{
          component: HomepageUKProductsComponent
         },
         SeqirusCMSLinkComponent:{
          component: HomepageUKPISlotComponent
         },
         SeqirusJobBagCodeComponent:{
          component: JobbagcodeUkComponent
         },
         SeqirusRegisterBannerComponent:{
          component:TopbannerUkComponent
         },
         SeqirusHavingTroubleComponent:{
          component:HavingTroubleComponent
         },
         MultipleSoldToPopupFlexComponent:{
          component:MultipleSoldtoPopupComponent
         },
         SeqirusMobileRedirectionComponent:{
          component:MobileUkRedirectionComponent
         },
         SeqirusSplashPageCMSComponent:{
          component:UkSplashPageComponent
         }
      }
  
      } as CmsConfig)
  ],
  exports: [
    HelpWidgetComponent,
    DateformatPipe,
    CustomfilterPipe,
    SeasonspiltPipe,
    RegJoinAccExitPopupComponent,
    RemovehypenPipe
  ],
  providers:[],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CmsCommonModule { }
