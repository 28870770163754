import { Component } from '@angular/core';
import { CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-download-reference',
  templateUrl: './download-reference.component.html',
  styleUrl: './download-reference.component.scss'
})
export class DownloadReferenceComponent {
  node1: Observable<any> = this.component.data$;
  constructor(public component: CmsComponentData<CmsBannerComponent>,private cmsService:CmsService, protected config: OccConfig,){
  }

  getDesktopImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.desktop.url;
  }
  getpdf(pdf: any): string {
    return this.config.backend?.occ?.baseUrl + pdf?.url + "&attachment=true";
  }
}
