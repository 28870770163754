
<section class="col-xs-12 col-sm-12 invoices_titlesection">
    <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 invoices_headerparent">
        <div class="invoices_header">All Invoices</div>
        <div class="invoices_dropdown">
            
    <select id="invoices_dropdownselect" *ngIf="seasonData | async as item" (change)="selectedSeason($event)" [(ngModel)]="finalSeason">
    <option  *ngFor="let obj of item.seqirusSeasonList; let i= index" [value]="obj" id="invoices_dropdownselect-{{i}}"> Viewing Season: {{obj.replace('-20','/')}} </option>
    </select>

        </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 invoices_headerparent invoices_headerparent2">
        <div class="invoices_openInvoices">
            <div class="invoices_openInvoicesNo">{{getOpenInvoiceCount()}}</div>
            <div class="invoices_openInvoiceslabel">Open Invoices</div>
        </div>
        <div class="invoices_paidInvoices">
            <div class="invoices_paidInvoicesNo">{{getPaidInvoiceCount()}}</div>
            <div class="invoices_paidInvoiceslabel">Paid Invoices</div>
        </div>
    </div>
</section>

<div  class="loading">
    <div class="loading_txt">
      <div class="load_txt">Loading</div>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>