import { ChangeDetectorRef, Component, ElementRef, EventEmitter, inject, Input, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AsmComponentService, CustomerSelectionComponent } from '@spartacus/asm/components';
import { AsmService } from '@spartacus/asm/core';
import { CustomerSearchPage, AsmConfig, AsmDeepLinkParameters, AsmAuthStorageService } from '@spartacus/asm/root';
import { Address, AuthStorageService, B2BUnit, BaseSiteService, ClientTokenService, Currency, FeatureConfigService, Language, OccEndpointsService, useFeatureStyles, UserIdService } from '@spartacus/core';
import { DirectionService, LaunchDialogService, LAUNCH_CALLER, DirectionMode } from '@spartacus/storefront';
import { Subscription, Observable, debounceTime, of } from 'rxjs';
import { Asm360Service } from '../../spartacus/services/asm360.service';
import { HttpClient } from '@angular/common/http';
import { UserInfoService } from '../../spartacus/services/user-info.service';

export interface User {
    currency?: Currency;
    customerId?: string;
    deactivationDate?: Date;
    defaultAddress?: Address;
    defaultPointOfServiceName?: string;
    displayUid?: string;
    firstName?: string;
    language?: Language;
    lastName?: string;
    name?: string;
    title?: string;
    titleCode?: string;
    uid?: string;
    roles?: string[];
    orgUnit?: B2BUnit;
    lastCartId?: string;
    hasOrder?: boolean;
    value?: string;
}

@Component({
  selector: 'app-flu360-asm-customer-seletcion',
  templateUrl: './flu360-asm-customer-seletcion.component.html',
  styleUrl: './flu360-asm-customer-seletcion.component.scss',
  host: {
    '(document:click)': 'onDocumentClick($event)',
  },
})
export class Flu360AsmCustomerSeletcionComponent extends CustomerSelectionComponent {
  currentSite :any;
  placeholderValue:string='';
  override selectedCustomer: User | undefined;

  

  // searchByCustomer: boolean = false;
  // searchByOrder: boolean = false;
 // isLoading: boolean = false;
  searchBy: string = 'profile';
  override activeFocusedButtonIndex = -1;
  submitLabel: string = "START SESSION";
  @Output()
  override submitEvent = new EventEmitter<{
    customerId?: any;
    parameters?: AsmDeepLinkParameters;
  }>();
  UIEvent: any;
  @ViewChild('resultList') override resultList: ElementRef = {} as ElementRef;
;
  searchCustomerResults:any = {};
  emeptyresult:any={}
  @ViewChild('searchTerm') override searchTerm: ElementRef = {} as ElementRef;;
  // @ViewChild('searchOrder') searchOrder: ElementRef = {} as ElementRef;;

  @ViewChild('createCustomerLink') override createCustomerLink: ElementRef = {} as ElementRef;;
  // @ViewChildren('searchResultItem') override searchResultItems: QueryList<
  //   ElementRef<HTMLElement>
  // >  = {} as ElementRef;
  // protected featureConfig = inject(FeatureConfigService);
  // isShowSearchingCustomerByOrderInASM = this.featureConfig.isEnabled(
  //   'showSearchingCustomerByOrderInASM'
  // );
  agentLoggedIn: boolean = false;
  
  constructor(
    protected override fb: UntypedFormBuilder,
    protected override asmService: AsmService,
    protected override config: AsmConfig,
    protected override directionService: DirectionService,
    protected override launchDialogService: LaunchDialogService,
    protected asmComponentService: AsmComponentService,
    protected baseSiteService: BaseSiteService,
    private authStorageService: AsmAuthStorageService,
    private userIdService: UserIdService,
    private clientTokenService: ClientTokenService,
    private occEndpointsService: OccEndpointsService,
    private http: HttpClient,
    private cd:ChangeDetectorRef,
     public userInfoService:UserInfoService
  ) {
    super(fb, asmService, config, directionService, launchDialogService);
    // useFeatureStyles('showSearchingCustomerByOrderInASM');
    this.baseSiteService.getActive().subscribe(res => {
          this.currentSite = res;
          if(this.currentSite === 'seqirusb2b-flu360'){
            this.placeholderValue = "Customer Name/Customer Email/Account ID";
          }
          else{
            this.placeholderValue = "Customer name/Email address";

          }
        });

    // this.customerSelectionForm = new FormGroup({
    //   // form controls
    //   // arg1 - intial state/value of this control
    //   // arg2 - single validator or an array of validators
    //   // arg3 - async validators
    //   'searchTerm': new FormControl('ab'),
    // });
  }
  // override ngOnInit(): void {
  //   this.baseSiteService.getActive().subscribe(res => {
  //     this.currentSite = res;
  //   });
  // }
  

  override selectCustomerFromList(event: any, customer: User) {
    this.selectedCustomer = customer;
    this.customerSelectionForm.controls['searchTerm'].setValue(
      this.selectedCustomer.name
    );
    // if (this.isShowSearchingCustomerByOrderInASM) {
    //   this.customerSelectionForm.controls['searchTerm'].setValue(
    //     this.selectedCustomer.name,
    //     {
    //       emitEvent: false,
    //     }
    //   );
    // } else {
    //   this.customerSelectionForm.controls['searchTerm'].setValue(
    //     this.selectedCustomer.name
    //   );
    // }
  
    this.searchResults=new Observable<CustomerSearchPage>();
  //this.searchResults=of(this.emeptyresult)
  this.cd.detectChanges()   
   
    this.asmService.customerSearchReset();
    
   // this.searchResults=
    this.searchTerm.nativeElement.focus();
    // if (!this.isShowSearchingCustomerByOrderInASM) {
    //   this.searchTerm.nativeElement.focus();
    // }
    event.preventDefault();
    event.stopPropagation();
  }
  override onDocumentClick(event: any) {
    if (Boolean(this.resultList)) {
      if (
        this.resultList.nativeElement.contains(event.target) ||
        this.searchTerm.nativeElement.contains(event.target)
      ) {
        return;
      } else {
        this.asmService.customerSearchReset();
      }
    }
  }

  override closeResults(event: any) {
    this.asmService.customerSearchReset();
    // this.searchResults = of({entries:[]});
    this.searchTerm.nativeElement.focus();
    event.preventDefault();
    event.stopPropagation();
  }

  // closeOrderSearchResults(event: any) {
  //   this.asmService.customerSearchReset();
  //   this.searchOrder.nativeElement.focus();
  //   event.preventDefault();
  //   event.stopPropagation();
  // }
  override focusFirstItem(event: any): void {
    event.preventDefault();
    this.activeFocusedButtonIndex = 0;
    this.updateItemIndex(this.activeFocusedButtonIndex);
  }
  override setSelectionEnd(event: any): void {
    event.preventDefault();
    if (this.searchTerm.nativeElement.value?.length) {
      const selectionStart = this.searchTerm.nativeElement.value.length;
      this.searchTerm.nativeElement.selectionStart = selectionStart;
      this.searchTerm.nativeElement.selectionEnd = selectionStart;
    }
  }
  // setOrderSearchSelectionEnd(event: any): void {
  //   event.preventDefault();
  //   if (this.searchOrder.nativeElement.value?.length) {
  //     const selectionStart = this.searchOrder.nativeElement.value.length;
  //     this.searchOrder.nativeElement.selectionStart = selectionStart;
  //     this.searchOrder.nativeElement.selectionEnd = selectionStart;
  //   }
  // }
  override focusPreviousChild(event: any): void {
    event.preventDefault();
    this.activeFocusedButtonIndex--;
    if (this.activeFocusedButtonIndex < 0) {
      this.activeFocusedButtonIndex = this.searchResultItems.length - 1;
    }
    this.updateItemIndex(this.activeFocusedButtonIndex);
  }
  override focusNextChild(event: any): void {
    event.preventDefault();
    this.activeFocusedButtonIndex++;
    if (this.activeFocusedButtonIndex > this.searchResultItems.length - 1) {
      this.activeFocusedButtonIndex = 0;
    }
    this.updateItemIndex(this.activeFocusedButtonIndex);
  }
  override focusInputText(event: any): void {
    event.preventDefault();
    this.activeFocusedButtonIndex = -1;
    this.searchTerm.nativeElement.focus();
    if (this.searchTerm.nativeElement.value?.length) {
      let selectionPos = this.searchTerm.nativeElement.selectionEnd;
      const searchTermLength = this.searchTerm.nativeElement.value.length;

      if (this.isBackNavigation(event)) {
        selectionPos = selectionPos <= 0 ? 0 : selectionPos - 1;
      } else if (this.isForwardsNavigation(event)) {
        selectionPos =
          selectionPos >= searchTermLength
            ? searchTermLength
            : selectionPos + 1;
      } else if (event.code === 'Home') {
        selectionPos = 0;
      } else if (event.code === 'End') {
        selectionPos = searchTermLength;
      }
      this.searchTerm.nativeElement.selectionStart = selectionPos;
      this.searchTerm.nativeElement.selectionEnd = selectionPos;
    }
  }
  override handleSearchTerm(searchTermValue: string) {
      if (
        !!this.selectedCustomer &&
        searchTermValue !== this.selectedCustomer.name
      ) {
        this.selectedCustomer = undefined;
      }
      if (Boolean(this.selectedCustomer)) {
        return;
      }
      this.asmService.customerSearchReset();
      this.activeFocusedButtonIndex = -1;
      if (searchTermValue.trim().length >= 3) {
        if(this.currentSite === 'seqirusb2b-uk' && this.searchBy == 'customer') {
          this.userInfoService.getCustomerSearch(searchTermValue).subscribe({
            next: (response: any) => {
              response = response.map((item: { customerId: any; email: any; displayUid: any; firstName: any; value: any; name: any; uid: any; }) => {
                item.customerId = item.email;
                item.displayUid = item.email;
                item.firstName = item.value;
                item.name = item.value;
                item.uid = item.email;
                return item;
              });
              this.searchCustomerResults.entries = response;
              this.searchResults=this.asmService.getCustomerSearchResults()
              this.searchResults =of(this.searchCustomerResults);
            
             
            },
  
            error: (e) => console.error(e),
          });
        }
        else{
          this.asmService.customerSearch({
            query: searchTermValue,
            pageSize: this.config.asm?.customerSearch?.maxResults,
          });
          this.searchResults=this.asmService.getCustomerSearchResults()
       
        }
      }
    }
  
  // focusOrderSearchInputText(event: any): void {
  //   event.preventDefault();
  //   this.activeFocusedButtonIndex = -1;
  //   this.searchOrder.nativeElement.focus();
  //   if (this.searchOrder.nativeElement.value?.length) {
  //     let selectionPos = this.searchOrder.nativeElement.selectionEnd;
  //     const searchTermLength = this.searchOrder.nativeElement.value.length;

  //     if (this.isBackNavigation(event)) {
  //       selectionPos = selectionPos <= 0 ? 0 : selectionPos - 1;
  //     } else if (this.isForwardsNavigation(event)) {
  //       selectionPos =
  //         selectionPos >= searchTermLength
  //           ? searchTermLength
  //           : selectionPos + 1;
  //     } else if (event.code === 'Home') {
  //       selectionPos = 0;
  //     } else if (event.code === 'End') {
  //       selectionPos = searchTermLength;
  //     }
  //     this.searchOrder.nativeElement.selectionStart = selectionPos;
  //     this.searchOrder.nativeElement.selectionEnd = selectionPos;
  //   }
  // }
  // isNoResultMessageInfoVisible(
  //   results: any,
  //   searchFlag: boolean,
  //   searchElement: HTMLInputElement
  // ): boolean {
  //   const searchTermValid = searchElement.value.length >= 3;
  //   const hasEntries = !!results.entries && results.entries.length > 0;
  //   return !this.isLoading && searchTermValid && searchFlag && !hasEntries;
  // }
  // isSearchResultsVisible(results: any, searchFlag: boolean): boolean {
  //   return !!results.entries && searchFlag && results.entries.length > 0;
  // }
  handleOnSubmit(event: string) {
    this.submitLabel = event;
    this.onSubmit();
    // if (event === 'START SESSION') {
    //   this.selectedCustomer = {};
    //   this.customerSelectionForm.controls['searchTerm'].setValue(
    //     ''
    //   );
    //   this.authStorageService.switchTokenTargetToCSAgent();
    // }
  }
  override onSubmit(): void {
    if (this.customerSelectionForm.valid && !!this.selectedCustomer) { 
      if(!this.agentLoggedIn) {
      this.agentLoggedIn = true;
      
      localStorage.setItem('isSearchByProfile',this.searchBy == 'customer'?'false':'true');
      this.submitLabel = 'END SESSION';
      this.submitEvent.emit({ customerId: this.selectedCustomer.customerId });
      } else {
        this.submitLabel = 'START SESSION';
        //this.asmComponentService.logoutCustomerSupportAgentAndCustomer();
        this.asmComponentService.logoutCustomer();
        this.customerSelectionForm.controls['searchTerm'].setValue('');

      }
    } else {
      this.customerSelectionForm.markAllAsTouched();
    }
  }
  setSearchBy(type: string){
    
      if(type == 'customer'){
        this.placeholderValue = "Account number/Organization name";
      }
      else{
        this.placeholderValue = "Customer name/Email address";
      }
    
    this.searchBy = type;
   /// 
    this.asmService.customerSearchReset();
 
    this.searchResults=new Observable<CustomerSearchPage>();
   
       
    this.searchTerm.nativeElement.value = '';
    this.searchTerm.nativeElement.focus();
  }
  
}
