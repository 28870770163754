import { ChangeDetectorRef, Component, Renderer2 } from '@angular/core';
import { BaseSiteService, CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { UserInfoService } from '../../spartacus/services/user-info.service';

@Component({
  selector: 'app-custom-opefficiency-first',
  templateUrl: './custom-opefficiency-first.component.html',
  styleUrl: './custom-opefficiency-first.component.scss'
})
export class CustomOpefficiencyFirstComponent {
  // firstheadline: Observable<any> = this.cmsService.getComponentData('SeqirusOperationalEfficiencyFirstComponent');
  leftBannerNode: Observable<any> = this.bannerComponent.data$; 
  userLoggedIn: boolean = false;
  currentPage?: string="";
  currentSite: string | undefined;
  helpandfaq:any;
  userInfo: any;
  webEnabled: any;
  constructor(private cmsService:CmsService, protected config: OccConfig,public bannerComponent: CmsComponentData<CmsBannerComponent>,
     private userInfoService:UserInfoService,
     private baseSiteService:BaseSiteService,private cd:ChangeDetectorRef, private renderer: Renderer2) {
      this.cmsService.getCurrentPage().subscribe(page => {
        this.currentPage = page.pageId;
      })
  }

  getImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.url;
  }
 ngOnInit(): void{
    //for looged, loggedout
    // this.userInfoService.userLoggedIn$.subscribe(res=>{
    //   console.log('login check',res);
    //   this.userLoggedIn = res;
    //   if(this.userLoggedIn){
    //     this.userInfoService.getUserData().subscribe(res=>{
    //       if(res){
    //       this.userInfo=res;
    //       }
    //     });
    //   }
    //   this.cd.detectChanges();
    // })
      this.userInfoService.userLoggedIn$.subscribe(res=>{
        this.userLoggedIn = res;
        if(this.userLoggedIn){
        // this.userInfoService.getUserData().subscribe(res=>{
        //   console.log(res);
        // if(res){
        //   this.userInfo=res;

        //   this.cd.detectChanges();
        //   }
        // });
        this.webEnabled =  localStorage.getItem('webEnabled');
      }
      })
      if(this.userInfo?.webEnabled == false){
        this.renderer.setStyle(document.querySelector('.SideContent'),'display','none');
       }
      this.cd.detectChanges();
            //to get the current basesite 
            this.baseSiteService.getActive().subscribe((siteId) => {
              this.currentSite = siteId;
            });
 }
}
  //    ngOnInit(): void {
  //     this.cmsService.getComponentData('SeqirusOperationalEfficiencyFirstComponent').subscribe(res=>{
  //     this.firstheadline=res;
  //    })
  // }
