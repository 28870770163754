import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, switchMap, throwError, of, filter, BehaviorSubject, catchError, map } from 'rxjs';
import { OccEndpointsService, UserIdService } from '@spartacus/core';
import { ApiResponse, GalleryImage, LocationData } from './location-data.model';
import { tap } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class ReturnsUkService {


  private apiNoreturnUrl = this.occ.buildUrl(`${this.fetchUserUrl()}/returnsOrders/noReturnSubmit?fields=DEFAULT"`);
  private apiProductstoreturn = this.occ.buildUrl(`${this.fetchUserUrl()}/returnsOrders/productsToReturn?fields=DEFAULT`);
  private apisubmitReturnsUrl = this.occ.buildUrl(`${this.fetchUserUrl()}/returnsOrders/submitUKReturns?fields=DEFAULT`);
  //private apigetSeasonReturnOrders = this.occ.buildUrl(`${this.fetchUserUrl()}/returnsOrders/getSeasonReturnOrders/${season}?fields=DEFAULT`);
  private apigetReturnbyLocation = this.occ.buildUrl(`${this.fetchUserUrl()}/returnsOrders`)
  private apigetSeason = this.occ.buildUrl(`${this.fetchUserUrl()}/returnsOrders/seasonDropdownReturns/2023-2024?fields=DEFAULT`)
  asmenabled: boolean=false;
  showSorryMsg$= new BehaviorSubject<any>(false);
  fetchUserUrl() {
    this.asmenabled = localStorage.getItem('showASM') === 'true';
    let partUrl = "/users/current";
    this.userIdService.getUserId().subscribe(item => {
      if(item && this.asmenabled){
        partUrl = `/users/${item}`;
      }else{
        localStorage.removeItem('isSearchByProfile')
      }
    });
    return partUrl;
  }
  getIsSearchByProfile(){
    if(localStorage.getItem('isSearchByProfile')  == 'false'){
      return false;
    }
    return true;
  }
  constructor(private http: HttpClient,private occ:OccEndpointsService,protected userIdService:UserIdService) {
    this.asmenabled = localStorage.getItem('showASM') === 'true';
   }
  private showDivSource = new BehaviorSubject<boolean>(false);
  currentShowDiv = this.showDivSource.asObservable();
  private locationImages: { [key: string]: GalleryImage[] } = {};
  locationImageFiles: { [key: string]: File[] } = {};
  changeShowDiv(show: boolean) {
    this.showDivSource.next(show);
  }

  private showNewReturn = new BehaviorSubject<boolean>(false);
  currentShowStatus = this.showNewReturn.asObservable();
  private additionalReturnDataSubject = new BehaviorSubject<any>(null);
  additionalReturnData$ = this.additionalReturnDataSubject.asObservable();
  changeShowStatus(show: boolean) {
    this.showNewReturn.next(show);
  }

  getLocations(season:any): Observable<ApiResponse> {
    const apiLocationreturnUrl = this.occ.buildUrl(`${this.fetchUserUrl()}/returnsOrders/startNewReturn/${season}?fields=DEFAULT&isSearchByProfile=${this.getIsSearchByProfile()}`);

    return this.http.get<ApiResponse>(apiLocationreturnUrl).pipe(
      tap((data: any) => console.log('API Response:', data))
    );
  }

  getSeasonReturnOrders(season: string): Observable<any> {
   const apiUrl = `${this.apigetReturnbyLocation}/getSeasonReturnOrders/${season}?fields=DEFAULT`;
   return this.http.get<any>(apiUrl);
  }

  getReturnEligibility(season: string): Observable<any> {
    const apiUrl = `${this.apigetReturnbyLocation}/getMyReturnsInfo/${season}?fields=DEFAULT&isSearchByProfile=${this.getIsSearchByProfile()}`;
    return this.http.get<any>(apiUrl);
   }

  getAdditionalReturn(returnId: string, season: string): Observable<any> {
    const apiUrl = `${this.apigetReturnbyLocation}/additionalReturn?fields=DEFAULT&returnId=${returnId}&season=${season}`;
    return this.http.get<any>(apiUrl);
  }

  deleteDosesConsent(returnId: string): Observable<any> {
    const url = `${this.apigetReturnbyLocation}/deleteDosesConsent?fields=DEFAULT&returnId=${returnId}`;
    return this.http.get(url);
  }

  setAdditionalReturnData(data: any): void {
    this.additionalReturnDataSubject.next(data);
  }

  getSeasons(): Observable<any> {
    return this.http.get(this.apigetSeason);
  }

  /* getSeasons(): Observable<any> {
    return this.http.get<any>(this.apigetSeason);
  } */

  getReturnDetails(locId: string, orderSeason: string): Observable<any> {
    const url = `${this.apigetReturnbyLocation}/getReturnsByLoc/${orderSeason}?fields=DEFAULT&locId=${locId}&isSearchByProfile=${this.getIsSearchByProfile()}`;
    return this.http.get<any>(url);
  }

  noReturns(soldToID: string, locIDs: string[],defaultseason:string): Observable<any> {
    const payload = {
      soldToID: soldToID,
      season: defaultseason,
      shipToIdList: locIDs.map(locID => ({ shipToId: locID }))
    };
    return this.http.post(this.apiNoreturnUrl, payload, { responseType: 'text' }).pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    console.error('An error occurred:', error.message);
    console.error('Error details:', error); // Log detailed error information
    return throwError('Something went wrong; please try again later.');
  }

  

  submitReturns(payload: any): Observable<any> {
    const url = this.occ.buildUrl(`${this.fetchUserUrl()}/returnsOrders/submitUKReturns?fields=DEFAULT`);
    return this.http.post(url, payload, {
      headers: {
        'Content-Type': 'application/json'
      },
      responseType: 'text' as 'json' // Specify the response type as text
    }).pipe(
      catchError(this.handleError) // Handle errors
    );
  }

  
  postProductsToReturn(locIDs: string[],defaultseason:string): Observable<any> {
    const payload = {
      locations: locIDs.map(locID => ({ locationId: locID })),
      season: defaultseason,
    };
    return this.http.post(this.apiProductstoreturn, payload, { responseType: 'json' }).pipe(
      catchError(this.handleError)
    );
  }

 

  getImages(locationID: string): GalleryImage[] {
    if (!this.locationImages[locationID]) {
      this.locationImages[locationID] = [];
    }
    return this.locationImages[locationID];
  }

  addImage(locationID: string, image: GalleryImage): void {
    if (!this.locationImages[locationID]) {
      this.locationImages[locationID] = [];
    }
    this.locationImages[locationID].push(image);
  }

  
  removeImage(locationID: string, index: number): void {
    if (this.locationImages[locationID]) {
      this.locationImages[locationID].splice(index, 1);
    }
  }

  // New methods for File objects
getImageFiles(locationID: string): File[] {
  if (!this.locationImageFiles[locationID]) {
    this.locationImageFiles[locationID] = [];
  }
  return this.locationImageFiles[locationID];
}

addImageFile(locationID: string, file: File): void {
  if (!this.locationImageFiles[locationID]) {
    this.locationImageFiles[locationID] = [];
  }
  this.locationImageFiles[locationID].push(file);
}

removeImageFile(locationID: string, index: number): void {
  if (this.locationImageFiles[locationID]) {
    this.locationImageFiles[locationID].splice(index, 1);
  }
}

submitImages(formData: FormData): Observable<any> {
  const url = this.occ.buildUrl(`${this.fetchUserUrl()}/returnsOrders/imageUploadReturns?fields=DEFAULT`);
  return this.http.post(url, formData, { responseType: 'text' }).pipe(
    catchError(this.handleError)
  );
  
}


private locnameSource = new BehaviorSubject<string>('');
currentLocname = this.locnameSource.asObservable();

changeLocname(locname: string) {
  this.locnameSource.next(locname);
}
private visibilityState = new BehaviorSubject<string>('A');
  currentVisibility = this.visibilityState.asObservable();
  changeVisibility(component: string) {
    this.visibilityState.next(component);
  }
  setReturnFailurePopup(result: boolean){
    this.showSorryMsg$.next(result);
  }
  getReturnFailurePopup(){
    return this.showSorryMsg$.asObservable();
  }
  registerReturn():Observable<any>{
    const url = this.occ.buildUrl(`${this.fetchUserUrl()}/returnsOrders/submitRegisterInterest`);
    return this.http.post(url,{headers:{ responseType: 'text' }})
  }
  ngOnDestroy(): void {
    this.visibilityState.next("");
    this.visibilityState.complete();
  }
}

