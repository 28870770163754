<div id="OM_exitPopup" class="modal fade in show" role="dialog" data-keyboard="false" tabindex="-1"  style="display:block;padding-right: 10px;" *ngIf="showExitPopUp">
    <div class="modal-dialog">

        <div class="modal-content">
            <div class="modal-body" *ngIf="!showFeedbackContent">
                <h4 class="modal-title OMexit_popHeader col-xs-9">{{exitPopupData.headLine}}</h4>
                <div class="col-xs-3">
                    <span class="OMexit_popClose" data-dismiss="modal" (click)="handleOnCloseButton()">×</span>
                </div>
                <div [innerHTML]="exitPopupData.content"></div>
                <div class="clearboth"></div>
                <div class="OM_exitPopBtnParent">
                    <button class="OM_popExitBtn" (click)="handleOnExitBtnClick()">{{exitPopupData.t1content}}</button>
                    <button class="OM_popBackBtn" data-dismiss="modal" (click)="handleOnCloseButton()">{{exitPopupData.t2content}}</button>
                </div>
            </div>
            <div class="modal-body" *ngIf="showFeedbackContent">

                <div class="popfeedbackheader col-xs-9">How was your experience with the ordering process?</div>
                <div class="col-xs-3">
                    <span class="OMexit_popClose feedbackclose" data-backdrop="" (click)="handleOnSkipBtnClick()">×</span>
                </div>
                <div class="feedback-container" style="font-family: Verdana; font-size: 12px;">
                    <table cellpadding="0" cellspacing="0" border="0" class="feedbacktable feedbackleft no-padding">
                        <tbody><tr>
                            <td class="feedbackbox">
                                <a href="https://app.customerthermometer.com/?template=log_feedback&amp;hash=47f9ace6&amp;embed_data=dGVtcGVyYXR1cmVfaWQ9NCZ0aGVybW9tZXRlcl9pZD0xNzk2NDQmbnBzX3JhdGluZz0=&amp;e=&amp;f=&amp;l=&amp;c=&amp;c1=&amp;c2=&amp;c3=&amp;c4=&amp;c5=&amp;c6=&amp;c7=&amp;c8=&amp;c9=&amp;c10="  target="_blank" class="">
                                    <img style="border: none;" src="https://app.customerthermometer.com/sites/app/images/icon_sets/icon_set_3s/red.png" alt="Red Light" title="Red Light" class="_temp">
                                </a>
                            </td>
                            <td class="feedbackbox">
                                    <a href="https://app.customerthermometer.com/?template=log_feedback&amp;hash=d78ef0aa&amp;embed_data=dGVtcGVyYXR1cmVfaWQ9MyZ0aGVybW9tZXRlcl9pZD0xNzk2NDQmbnBzX3JhdGluZz0=&amp;e=&amp;f=&amp;l=&amp;c=&amp;c1=&amp;c2=&amp;c3=&amp;c4=&amp;c5=&amp;c6=&amp;c7=&amp;c8=&amp;c9=&amp;c10="  target="_blank" class="">
                                        <img style="border: none;" src="https://app.customerthermometer.com/sites/app/images/icon_sets/icon_set_3s/amber.png" alt="Yellow Light" title="Yellow Light" class="_temp">
                                    </a>
                            </td>
                            <td class="feedbackbox">
                                <a href="https://app.customerthermometer.com/?template=log_feedback&amp;hash=5455c372&amp;embed_data=dGVtcGVyYXR1cmVfaWQ9MiZ0aGVybW9tZXRlcl9pZD0xNzk2NDQmbnBzX3JhdGluZz0=&amp;e=&amp;f=&amp;l=&amp;c=&amp;c1=&amp;c2=&amp;c3=&amp;c4=&amp;c5=&amp;c6=&amp;c7=&amp;c8=&amp;c9=&amp;c10="  target="_blank" class="">
                                    <img style="border: none;" src="https://app.customerthermometer.com/sites/app/images/icon_sets/icon_set_3s/green.png" alt="Green Light" title="Green Light" class="_temp">
                                </a>
                            </td>
                            <td class="feedbackbox">
                                <a href="https://app.customerthermometer.com/?template=log_feedback&amp;hash=30884dc1&amp;embed_data=dGVtcGVyYXR1cmVfaWQ9MSZ0aGVybW9tZXRlcl9pZD0xNzk2NDQmbnBzX3JhdGluZz0=&amp;e=&amp;f=&amp;l=&amp;c=&amp;c1=&amp;c2=&amp;c3=&amp;c4=&amp;c5=&amp;c6=&amp;c7=&amp;c8=&amp;c9=&amp;c10="  target="_blank" class="">
                                    <img style="border: none;" src="https://app.customerthermometer.com/sites/app/images/icon_sets/icon_set_3s/gold.png" alt="Gold Star" title="Gold Star" class="_temp">
                                </a>
                            </td>

                        </tr>

                    </tbody></table>
                </div>

                <div class="OM_exitPopBtnParent">
                    <button class="skipbtn feedbackclose" data-backdrop="" (click)="handleOnSkipBtnClick()">Skip</button>
                </div>

        </div>
        </div>
    </div>
</div>
<app-popupdashboard></app-popupdashboard>