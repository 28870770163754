<!-- <div class="container"> -->
    <div class="breadcrumbs--page-nav">
        <div class="container">
            <p>
                <a href="/">Home</a>
                <span> &gt; </span>
                <strong>About</strong>
            </p>
        </div>
    </div>
     
    <div class="hero--overview" role="img" aria-label="Healthcare professional facing left" *ngIf="aboutus | async as item">
        <div class="container">
            <div class="hero--overview-content">
                <img class="rectangle-down" alt="angled corner" src="../../../app/../assets/images/rectangle-down.svg">
                <h1 class="content">{{item.content}}</h1>
                <p class="about-us" [innerHTML]="item.largeparagraphcontent"></p>
                <p class="ready-ref" [innerHTML]="item.headline"></p>
                <a [routerLink]="'/createprofile'">
                    <button class="button--hero-solid-red mt-20">
                        Create Account</button>
                </a>
            </div>
        </div>
    </div>
     
    <!--hide desktop -->
    <div class="row-flex center-xs" *ngIf="aboutus | async as item">
        <div class="hero--overview-content-mobile hide-desktop">
            <img class="rectangle-down" alt="angled corner" src="../../../app/../assets/images/rectangle-down.svg">
            <!-- <div class="mobile_overview_title">At the forefront of flu prevention</div> -->
            <div class="content">{{item.content}}</div>
            <p class="about-us" [innerHTML]="item.largeparagraphcontent"></p>
            <p class="ref" [innerHTML]="item.headline"></p>
        </div>
    </div>