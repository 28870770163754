<div class="invoice_paypopup mobsuccesscontainer" [ngClass]="paymentDetails?.cardNumber?'schpay-successcontainer':''" *ngIf="errorCode == 200">
	<div class="order_panel_header successcontainer">

		<div class="row">

			<div class="col-md-12">
				<div *ngIf="!paymentDetails?.cardNumber;else schedulePayTitle" class="title_data">Thank You for Submitting Your Payment</div>
				<ng-template #schedulePayTitle>
					<div class="title_data">Thank you for scheduling your payment</div>
				</ng-template>
			</div>

		</div>
		<div class="row">
			<div class="col-md-12">
				<div class="msg_data">
					You will receive an email shortly confirming your payment details. If you do not receive an email or if you require support, please contact <ng-container *ngIf="!paymentDetails?.cardNumber">Customer Service at &nbsp;<a href="mailto:customerservice.us@seqirus.com" target="_blank">{{emailId}}</a>.</ng-container><ng-container *ngIf="paymentDetails?.cardNumber"><a routerLink="/contact-us" class="schpay_link">Customer Service</a>.</ng-container>
				</div>

			</div>

		</div>
		<div *ngIf="!paymentDetails?.cardNumber;else schedulePayBanner" class="row">

			<div class="col-md-6 col-xs-10 marginClass">
				<div class="Transactiontext">TRANSACTION ID<div class="tractionid">{{paymentDetails.companyCode}}/{{paymentDetails.invoiceNumber}}</div>
				</div>
			</div>
		</div>

		<ng-template #schedulePayBanner>
			<div class="new_schedule_pay_banner">
			<div class="marginClass">
				<div class="Transactiontext">DATE<div class="tractionid">{{paymentDetails.currentDate}}</div>
				</div>
			</div>
			<div class="marginClass">
				<div class="Transactiontext second_buttontext">Card<div class="tractionid"><img width="38" class="image" *ngIf="paymentDetails.cardType == '1'" src="../../assets/images/Visa_icon.png"
                    alt="Visa">
                  <img width="38" class="image" *ngIf="paymentDetails.cardType == '2'" src="../../assets/images/amex_icon.png"
                    alt="American Express">
                  <img width="38"class="image" *ngIf="paymentDetails.cardType == '3'" src="../../assets/images/master_icon.png"
                    alt="MasterCard">
                  <img width="38" class="image" *ngIf="paymentDetails.cardType == '128'" src="../../assets/images/Discover_icon.png"
                    alt="Discover">
                     **{{paymentDetails.cardNumber.slice(-4)}}</div>
				</div>
			</div>
			</div>
		</ng-template>
		<div *ngIf="!paymentDetails?.cardNumber;else schedulePayButtonSection" class="row">
			<div class="col-md-6 col-xs-6 close_container">
				<a href="/financial-dashboard/all-invoices" target="_parent" class="close_btn_thankyou mobclose_btn">Close</a>
			</div>
		</div>
		<ng-template #schedulePayButtonSection>
			<div class="new_schedule_pay_buttons">
			<div class="close_container">
				<a href="/scheduled-payments" target="_parent" class="close_btn_thankyou view_sch_pay mobclose_btn">View All Schedule Payments</a>
			</div>
			<div class="close_container">
				<a href="/financial-dashboard/all-invoices" target="_parent" class="close_btn_thankyou mobclose_btn">Close</a>
			</div>
			</div>
		</ng-template>

	</div>
</div>
<div class="invoice_paypopup paymentfailed mobpaddingpayment" *ngIf="errorCode==3">
	<div class="order_panel_header successcontainer">
	<div class="row">
			<div class="col-md-12">
				<div class="title_data">Your payment failed</div>
			</div>

		</div>
		<div class="row">
			<div class="col-md-12">
				<div class="msg_title">
					We have not been able to process your payment.</div>
				<div class="msg_data paymentfailtext">Please check your details and try again, or use another payment method.</div>

			</div>

		</div>
		<div class="row">

			<div class="failerbtncont col-xs-12" id="failurebtncont">
				<div class="failerretry mobretry" id="retry" (click)="cancelClicked()">Retry or Change Method</div>
				<div class="failercancelbtn" id="cancel" (click)="cancelTransactionClicked()">Cancel Transaction</div>
			</div>
		</div>
		</div>
</div>
<div class="invoice_paypopup paymentfailed mobpaddingpayment" *ngIf="errorCode==2">
	<div class="order_panel_header successcontainer">
	<div class="row">
			<div class="col-md-12">
				<div class="title_data">Unable to Process Card</div>
			</div>

		</div>
		<div class="row">
			<div class="col-md-12">
				<div class="msg_title">
					Please contact your bank or use another payment method.</div>
				<div class="msg_data paymentfailtext">Your bank was unable to process this card. This may be related to various reasons, including fraud, lack of funds, invaild card, etc.</div>

			</div>

		</div>
		<div class="row">

			<div class="failerbtncont col-xs-12" id="failurebtncont">
				<div class="failerretry mobretry" id="retry" >Retry or Change Method</div>
				<div class="failercancelbtn" id="cancel">Cancel Transaction</div>
			</div>
		</div>
		</div>
</div>
<div class="invoice_paypopup paymentfailed mobpaddingpayment" *ngIf="errorCode==1">
	<div class="order_panel_header successcontainer">
	<div class="row">
			<div class="col-md-12">
				<div class="title_data">Card Authentication Failed</div>
			</div>

		</div>
		<div class="row">
			<div class="col-md-12">
				<div class="msg_title paymentfailtext">Your card was unable to be authenticated by your bank. This may be related to entering a one-time password, SMS code, timeout, invalid CVV, expired card, or other bank challenge.</div>
				<div class="msg_data">
					Please retry the card. If the problem persists, please contact your bank or use another payment method.</div>

			</div>

		</div>
		<div class="row">

			<div class="failerbtncont col-xs-12" id="failurebtncont">
				<div class="failerretry mobretry" id="retry" (click)="cancelClicked()">Retry or Change Method</div>
				<div class="failercancelbtn" id="cancel" (click)="cancelTransactionClicked()">Cancel Transaction</div>
			</div>
		</div>
		</div>
</div>


