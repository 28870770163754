
<ng-container role="main" class="registration col-xs-12 no-padding margin-B20">
  <div class="loading" *ngIf="showloader" >
      <div class="loading_txt">
       <div class="load_txt">Loading</div>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
       </div>
  </div>    

  <!-- <section class="col-xs-12 col-md-12 newresgistrationtitle">
      <div class="col-xs-12 col-md-8">
         <div class="newreg_header">Create a Seqirus Business Account</div>
      </div>
      <div class="col-xs-12 regcart_dateheader">
         <div class="regcaption_1">flu360  |  Support</div>
         <div class="regcaption_email">Email: <a href="mailto:flu.salesuk@seqirus.com" class="showPopupclick removeEmailleave">flu.salesuk&#64;seqirus.com</a></div>
         <div class="regcaption_email">Call: <a href="tel:03450093804" class="showPopupclick removephoneleave">0345 009 3804</a>&nbsp;(8:30 - 17:00)</div>
      </div>
  </section> -->
  <!--login left navigation For Login -->
  <section class="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding log_Reg_Navigatesection">
      <div class="col-md-3 Loginregistration_nagivationPart leftelement" id="Loginregistration_nagivationPart1" [ngClass]="{'active': getActiveSection() === 'business', 'inactive': getActiveSection() !== 'business'}">
         <div class="Loginregistration_nagivationNo">
          <div class="Loginregistration_nagivationNumeric" [ngStyle]="{'display': getActiveSection() === 'business' ? 'block' : 'none'}">1</div>
          <div class="registration_nagivationtick" [ngStyle]="{'display':isContactsActive() || getActiveSection() === 'review' ? 'block' : 'none'}"><img src="assets/images/greentick.png" alt=""></div>
         </div>
         <div class="Loginregistration_nagivationName">Business Details</div>
      </div>
      <div class="col-md-3 Loginregistration_nagivationPart" [ngClass]="{'active': isContactsActive(), 'inactive': !isContactsActive()}" id="Loginregistration_nagivationPart2">
         <div class="Loginregistration_nagivationNo">
          <div class="Loginregistration_nagivationNumeric" [ngStyle]="{'display': isContactsActive() && getActiveSection() !== 'review' ? 'block' : 'none' && getActiveSection() === 'business' ? 'block' : 'none'}">2</div>
          <div class="registration_nagivationtick" [ngStyle]="{'display': getActiveSection() === 'review' ? 'block' : 'none'}"><img src="assets/images/greentick.png" alt=""></div>
         </div>
         <div class="Loginregistration_nagivationName">Contacts &amp; Addresses</div>
      </div>
      <div class="col-md-3 Loginregistration_nagivationPart" id="Loginregistration_nagivationPart3" [ngClass]="{'active': getActiveSection() === 'review', 'inactive': getActiveSection() !== 'review'}">
         <div class="Loginregistration_nagivationNo">
            <div class="Loginregistration_nagivationNumeric">3</div>
            <div class="registration_nagivationtick"><img src="assets/images/greentick.png" alt=""></div>
         </div>
         <div class="Loginregistration_nagivationName">Review &amp; Confirm</div>
      </div>
      <div class="col-md-3 Loginregistration_help">
         <div class="registration_help loginhelp">
            Having trouble?
            &nbsp;
            <a  [routerLink]="'/help-and-faq'" target="_blank" class="showPopupclick">
               <span>We Can Help</span>
               <div class="global_blackarrow"></div>
            </a>
         </div>
      </div>
   </section>
   <section class="col-lg-12 col-md-12 col-sm-12 col-xs-12  contactsubsection"  *ngIf="isContactsActive()"  [ngClass]="{'active': isContactsActive(), 'inactive': !isContactsActive()}">
      <div class="col-md-4 contactsubtxt"  [ngClass]="{'activestate': getActiveSection() === 'invoice', 'inactive': getActiveSection() !== 'invoice'}" id="logininvoice"><span class="activedot"></span>Invoicing Information</div>
      <div class="col-md-4 contactsubtxt" [ngClass]="{'activestate': getActiveSection() === 'paying', 'inactive': getActiveSection() !== 'paying'}" id="loginpaying"><span class="activedot"></span>Payer Information</div>
      <div class="col-md-4 contactsubtxt" [ngClass]="{'activestate': getActiveSection() === 'shipping', 'inactive': getActiveSection() !== 'shipping'}" id="logindeliveryloc"><span class="activedot"></span>Delivery Location(s)</div>
   </section>
 
  <!--login left navigation For Not Login-->
  <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 registration_leftside no-padding-right">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 registration_nagivation no-padding">
        <div class="registration_nagivationPart" id="registration_nagivationPart1" >
          <div class="registration_nagivationNo">
            <div class="registration_nagivationtick" style="display:block;"><img src="assets/images/greentick.png" alt=""></div>
          </div>
          <div class="registration_nagivationName">User Profile</div>
        </div>
        <div class="registration_nagivationPart" >
          <div class="registration_nagivationNo">
            <div class="registration_nagivationNumeric" [ngStyle]="{'display': getActiveSection() === 'business' ? 'block' : 'none'}">2</div>
            <div class="registration_nagivationtick" [ngStyle]="{'display':isContactsActive() || getActiveSection() === 'review' ? 'block' : 'none'}"><img src="assets/images/greentick.png" alt=""></div>
          </div>
          <div class="registration_nagivationName" id="registration_nagivationPart2" [ngClass]="{'active': getActiveSection() === 'business', 'inactive': getActiveSection() !== 'business'}">Business Details</div>
        </div>
        <div class="registration_nagivationPart" id="registration_nagivationPart3" >
          <div class="registration_nagivationNo">
            <div class="registration_nagivationNumeric" [ngStyle]="{'display': isContactsActive() && getActiveSection() !== 'review' ? 'block' : 'none' && getActiveSection() === 'business' ? 'block' : 'none'}">3</div>
<div class="registration_nagivationtick" [ngStyle]="{'display': getActiveSection() === 'review' ? 'block' : 'none'}"><img src="assets/images/greentick.png" alt=""></div>
          </div>
          <div class="registration_nagivationName"  [ngClass]="{'active': isContactsActive(), 'inactive': !isContactsActive()}">Contacts &amp; Addresses
            <ul class="subregitem">
              <li style="display: list-item;" [ngClass]="{'active': getActiveSection() === 'invoice', 'inactive': getActiveSection() !== 'invoice'}">Invoicing Information</li>
              <li style="display: list-item;" [ngClass]="{'active': getActiveSection() === 'paying', 'inactive': getActiveSection() !== 'paying'}">Payer Information</li>
              <li style="display: list-item;" [ngClass]="{'active': getActiveSection() === 'shipping', 'inactive': getActiveSection() !== 'shipping'}">Delivery Location(s)</li>
            </ul>
          </div>
        </div>
        <div class="registration_nagivationPart" id="registration_nagivationPart4"  >
          <div class="registration_nagivationNo">
            <div class="registration_nagivationNumeric" >4</div>
            
          </div>
          <div class="registration_nagivationName" [ngClass]="{'active': getActiveSection() === 'review', 'inactive': getActiveSection() !== 'review'}">Review &amp; Confirm</div>
        </div>
      </div>
    </div>
  <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 registration_rightside no-padding-left no-padding-bottom">
        <div class="registration_help nonweb">
           Having trouble?
           &nbsp;
           <a [routerLink]="'/help-and-faq'" class="showPopupclick">
              <span>We Can Help</span>
              <div class="global_blackarrow"></div>
           </a>
        </div>
        <!--left navigation container -->
        <!--till here it will be hiden for login view -->
        
      <section class="registration_business registrationFlow" id="registration_section1"  [style.display]="showBussinessdetails ? 'block' : 'none'">
      
          <form  [formGroup]="bussinessDetails">
             
              <div class="registration_label padding-B10">Business Details</div>
              <div class="registration_text1 padding-B10">Tell us about your organisation and the lead contact person</div>
              <div class="registration_text3">All fields marked with an asterisk (*) are required.</div>
      
      
              <div class="registration_existing padding-B20"> <span class="registration_question">Is your organisation already registered with CSL Seqirus? &nbsp; &nbsp;</span><a [routerLink]="'/register/join-account'" target="_self" class="showPopupclick">Link your profile to that account instead<div class="global_blackarrow"></div></a></div>
      
              <div class="padding-R15">
                  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding margin-B20 registration_parent form-group">
                      <label class="registration_inputLabel" for="business-orgname">Organisation Name*</label>
                      <input class="registration_input form-control" id="orgName" formControlName="orgName" autocomplete="no">
                      <div class="text-danger" *ngIf="bussinessDetails.controls['orgName'].invalid && (isFormSubmitted_bussiness || bussinessDetails.controls['orgName'].touched || bussinessDetails.controls['orgName'].dirty)">
                          <i class="fa-solid fa-circle-exclamation"></i>  
                          <label *ngIf="bussinessDetails.controls['orgName'].errors?.['required']" class="error help-block">Please enter Organisation Name</label>
                          <label *ngIf="bussinessDetails.controls['orgName'].errors?.['pattern']" class="error help-block">Please enter only letters</label>
                      </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding-right margin-B20 registration_parent form-group">
                      <label class="registration_inputLabel" for="business-regnumber">Company Registration Number</label>
                      <input class="registration_input form-control" maxlength="8" minlength="7" id="orgRegNo" formControlName="orgRegNo" autocomplete="off">
                      
                       <div class="text-danger" *ngIf="bussinessDetails.controls['orgRegNo'].invalid && (isFormSubmitted_bussiness || bussinessDetails.controls['orgRegNo'].touched || bussinessDetails.controls['orgRegNo'].dirty)">
                          <i class="fa-solid fa-circle-exclamation"></i>  
                          <label *ngIf="bussinessDetails.controls['orgRegNo'].errors?.['pattern']" class="error help-block">Please enter only numbers</label>
                          <div *ngIf="!bussinessDetails.controls['orgRegNo'].errors?.['pattern']">
                          <label *ngIf="bussinessDetails.controls['orgRegNo'].errors?.['minlength']" class="error help-block">Should not be less than 7 character</label>
                           </div>   
                      </div>
                      <div class="registration_text3"><i>i.e. Company registration number as registered with companies house</i> 
                      </div>
                  </div>
                  <div class="clearboth"></div>
              </div>
      
              <div class="registration_contact padding-B20">Additional Business Information</div>
              <div class="padding-R15">
                  <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12 no-padding margin-B20 registration_parent form-group">
                      <label class="registration_inputLabel" for="business-vat">VAT Number</label>
                      <p-inputMask class="registration_input form-control" autoClear="false"  mask="GB999999999" pattern="GB\d\d\d\d\d\d\d\d\d" id="vatNo" formControlName="vatNo"   />
                      <div class="text-danger" *ngIf="bussinessDetails.controls['vatNo'].invalid && (isFormSubmitted_bussiness || bussinessDetails.controls['vatNo'].touched || bussinessDetails.controls['vatNo'].dirty)">
                          <i class="fa-solid fa-circle-exclamation"></i>  
                          <label *ngIf="bussinessDetails.controls['vatNo'].errors?.['pattern']" class="error help-block">Please enter in the format GB123456789</label>
                      </div>
                  </div>
                  <div class="col-lg-3 col-md-3 col-sm-6 col-xs-6 no-padding-right margin-B20 registration_parent form-group">
                      <label class="registration_inputLabel " for="business-trade">Trading Since*</label>
                      <select class="registration_state form-control" id="tradingSince" name="tradingSince" formControlName="tradingSince">
                          <option value="" selected="selected">YYYY</option>
                          <option *ngFor="let year of tradingSinceYears" [value]="year">{{ year }}</option>
                      </select>
                      <div class="text-danger" *ngIf="bussinessDetails.controls['tradingSince'].invalid && (isFormSubmitted_bussiness || bussinessDetails.controls['tradingSince'].touched || bussinessDetails.controls['tradingSince'].dirty)">
                          <i class="fa-solid fa-circle-exclamation"></i>  
                          <label *ngIf="bussinessDetails.controls['tradingSince'].errors?.['required']" class="error help-block">Please select Trading year</label>
                      </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-6 col-xs-6 no-padding-right margin-B20  form-group">
                      <label class="registration_inputLabel " for="business-org_type">Organisation Type*</label>
                      <select class="registration_state form-control" (change)="onOrgTypeChange($event)" id="orgType" name="orgType" formControlName="orgType">
                          <option value="" selected="selected">Select</option>
                          <option *ngFor="let type of businessTypes" [value]="type.value">{{ type.display }}</option>
                      </select>
                      <div class="text-danger" *ngIf="bussinessDetails.controls['orgType'].invalid && (isFormSubmitted_bussiness || bussinessDetails.controls['orgType'].touched || bussinessDetails.controls['orgType'].dirty)">
                          <i class="fa-solid fa-circle-exclamation"></i>  
                          <label *ngIf="bussinessDetails.controls['orgType'].errors?.['required']" class="error help-block">Please select Organisation Type</label>
                      </div>
                  </div>
                  <div class="clearboth"></div>
              </div>
              <div class="padding-R15">
                  
                <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 no-padding margin-B20 registration_parent form-group">
                  <label class="registration_inputLabel" for="business-nhs_code">NHS Code</label>
                  <input class="registration_input form-control" [attr.maxlength]="maxLength" maxlength="6" id="nhsCode" name="nhsCode" formControlName="nhsCode" autocomplete="off">
                  
                  <div class="text-danger" *ngIf="bussinessDetails.controls['nhsCode'].invalid && (isFormSubmitted_bussiness || bussinessDetails.controls['nhsCode'].touched || bussinessDetails.controls['nhsCode'].dirty)">
                      <i class="fa-solid fa-circle-exclamation"></i>  
                      <label id="business-nhs_code-error" class="error help-block" for="business-nhs_code">
                          <ng-container *ngIf="bussinessDetails.controls['nhsCode'].errors?.['alphanumeric']">
                              Please enter only letters and numbers.
                          </ng-container>
                          <ng-container *ngIf="!bussinessDetails.controls['nhsCode'].errors?.['alphanumeric'] && bussinessDetails.controls['nhsCode'].errors?.['pattern']">
                              <div *ngIf="bussinessDetails.get('orgType')?.value === 'GENERAL_PRACTICE'">
                                  Please provide a valid NHS code e.g. Y02906
                              </div>   
                              <div *ngIf="bussinessDetails.get('orgType')?.value === 'PHARMACY'">
                                  Please provide a valid NHS code e.g. F1J4D
                              </div>  
                              <div *ngIf="bussinessDetails.get('orgType')?.value === 'HOSPITAL_PUBLIC' || bussinessDetails.get('orgType')?.value === 'HOSPITAL_PRIVATE' || bussinessDetails.get('orgType')?.value === 'NURSING_HOMES' || bussinessDetails.get('orgType')?.value === 'CORRECTIONAL_FACILITY' || bussinessDetails.get('orgType')?.value === 'WHOLESALER'">
                                  Please provide a valid NHS code
                              </div>    
                          </ng-container>
                      </label>
                  </div>
              </div>
                  <div class="clearboth"></div>
              </div>
              <div class="registration_contact padding-B20">Contact Information</div>
              <div class="padding-R15">
                  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding margin-B20 registration_parent form-group">
                      <label class="registration_inputLabel" for="business-firstname">First Name*</label>
                      <input class="registration_input form-control" id="business-firstname" name="firstName" formControlName="firstName" autocomplete="off">
                      <div class="text-danger" *ngIf="bussinessDetails.controls['firstName'].invalid && (isFormSubmitted_bussiness || bussinessDetails.controls['firstName'].touched || bussinessDetails.controls['firstName'].dirty)">
                          <i class="fa-solid fa-circle-exclamation"></i>  
                          <label *ngIf="bussinessDetails.controls['firstName'].errors?.['required']" class="error help-block">Please enter a First Name.</label>
                          <label *ngIf="bussinessDetails.controls['firstName'].errors?.['pattern']" class="error help-block">Please enter only letters</label>
                      </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding-right margin-B20 registration_parent form-group">
                      <label class="registration_inputLabel" for="business-lastname">Last Name*</label>
                      <input class="registration_input form-control" id="business-lastname" name="lastName" autocomplete="off" formControlName="lastName">
                      <div class="text-danger" *ngIf="bussinessDetails.controls['lastName'].invalid && (isFormSubmitted_bussiness || bussinessDetails.controls['lastName'].touched || bussinessDetails.controls['lastName'].dirty)">
                          <i class="fa-solid fa-circle-exclamation"></i>  
                          <label *ngIf="bussinessDetails.controls['lastName'].errors?.['required']" class="error help-block">Please enter a Last Name.</label>
                          <label *ngIf="bussinessDetails.controls['lastName'].errors?.['pattern']" class="error help-block">Please enter only letters</label>
                      </div>
                  </div>
                  <div class="clearboth"></div>
              </div>
               <div class="padding-R15">
                  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding margin-B20 registrationc_parent form-group">
                      <label class="registration_inputLabel " for="business-jobtitle">Job Title*</label> 
                      <select class="registration_state form-control" id="business-jobtitle" name="business-jobtitle" formControlName="jobTitle">
                          <!-- <option value="" selected="selected">Select</option> -->
                          <option selected="selected" *ngFor="let item of jobTitle" [value]="item.value">{{ item.label }}</option>
                      </select>
                      <div class="text-danger" *ngIf="bussinessDetails.controls['jobTitle'].invalid && (isFormSubmitted_bussiness || bussinessDetails.controls['jobTitle'].touched || bussinessDetails.controls['jobTitle'].dirty)">
                        <i class="fa-solid fa-circle-exclamation"></i>  
                        <label *ngIf="bussinessDetails.controls['jobTitle'].errors?.['required']" class="error help-block">Please select the Job Title</label>
                      </div>  
                  </div>
                  <div class="clearboth"></div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding margin-B20 registration_parent form-group">
                  <label class="registration_inputLabel" for="business-email">Email Address*</label>
                  <input class="registration_input form-control" id="business-email" name="email" autocomplete="off" formControlName="emailId">
                  <div class="text-danger" *ngIf="bussinessDetails.controls['emailId'].invalid && (isFormSubmitted_bussiness || bussinessDetails.controls['emailId'].touched || bussinessDetails.controls['emailId'].dirty)">
                      <i class="fa-solid fa-circle-exclamation"></i>  
                      <label *ngIf="bussinessDetails.controls['emailId'].errors?.['required']" class="error help-block">Please enter a valid Email Address.</label>
                      <label *ngIf="bussinessDetails.controls['emailId'].errors?.['pattern']" class="error help-block">Please enter a valid Email Address.</label>
                  </div>
              </div>
              <div class="clearboth"></div>
              <div class="padding-R15">
                  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding margin-B20 registration_parent form-group">
                      <label class="registration_inputLabel" for="business-phoneUK">Phone Number*</label>
                      <input class="registration_input form-control" maxlength="11" id="business-phoneUK" name="phoneNumber" autocomplete="off" formControlName="phoneNo">
                      <div class="text-danger" *ngIf="bussinessDetails.controls['phoneNo'].invalid && (isFormSubmitted_bussiness || bussinessDetails.controls['phoneNo'].touched || bussinessDetails.controls['phoneNo'].dirty)">
                          <i class="fa-solid fa-circle-exclamation"></i>  
                          <label *ngIf="bussinessDetails.controls['phoneNo'].errors?.['required']" class="error help-block">Please enter a valid UK format phone number.</label>
                          <label *ngIf="bussinessDetails.controls['phoneNo'].errors?.['minlength']" class="error help-block">Please enter a valid UK format phone number.</label>
                      </div>
                  </div>
                  <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 no-padding-right margin-B20 registration_parent form-group">
                      <label class="registration_inputLabel" for="business-extn">Ext.</label>
                      <input class="registration_input form-control" maxlength="4" formControlName="phoneExt"  id="business-extn" name="phoneExt" autocomplete="off" >
                      <div class="text-danger" *ngIf="bussinessDetails.controls['phoneExt'].invalid && (isFormSubmitted_bussiness || bussinessDetails.controls['phoneExt'].touched || bussinessDetails.controls['phoneExt'].dirty)">
                        <i class="fa-solid fa-circle-exclamation"></i>  
                        <label *ngIf="bussinessDetails.controls['phoneExt'].errors?.['pattern']" class="error help-block">Please enter only Numbers</label>
                      </div>
                    </div>
                  <div class="clearboth"></div>
              </div>
             <div class="businessAddess">Business Address</div>
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding margin-B20 registration_address form-group">
                  <label class="registration_inputLabel " for="business-lookup">Address Lookup</label>
                  <input class="registration_input form-control" id="businesslookup" name="businesslookup" >
                  <span class="glyphicon glyphicon-search form-control-feedback"></span>
              </div>
              <div class="padding-R15">
                  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding margin-B20 registration_parent form-group">
                      <label class="registration_inputLabel" for="business-address1">Address Line 1*</label>
                      <input class="registration_input form-control" id="line1" name="line1" autocomplete="off" formControlName="line1">
                      <div class="text-danger" *ngIf="bussinessDetails.controls['line1'].invalid && (isFormSubmitted_bussiness || bussinessDetails.controls['line1'].touched || bussinessDetails.controls['line1'].dirty)">
                          <i class="fa-solid fa-circle-exclamation"></i>  
                          <label *ngIf="bussinessDetails.controls['line1'].errors?.['required']" class="error help-block">Please enter an Address.</label>
                      
                      </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding-right margin-B20 registration_parent form-group">
                      <label class="registration_inputLabel" for="business-addressStreet">Additional Street</label>
                      <input class="registration_input form-control" id="line2" name="line2" autocomplete="off" formControlName="line2">
                  </div>
                  <div class="clearboth"></div>
              </div>
              <div class="padding-R15">
                  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding margin-B20 registration_parent form-group">
                      <label class="registration_inputLabel" for="business-city">City*</label>
                      <input class="registration_input form-control" id="city" name="city" autocomplete="off" formControlName="city">
                      <div class="text-danger" *ngIf="bussinessDetails.controls['city'].invalid && (isFormSubmitted_bussiness || bussinessDetails.controls['city'].touched || bussinessDetails.controls['city'].dirty)">
                          <i class="fa-solid fa-circle-exclamation"></i>  
                          <label *ngIf="bussinessDetails.controls['city'].errors?.['required']" class="error help-block">Please enter an City.</label>
                      </div>
                  </div>
                  <div class="col-lg-2 col-md-3 col-sm-5 col-xs-5 no-padding-right margin-B20 registration_parent form-group">
                      <label class="registration_inputLabel " for="business-Post-Code">Post Code*</label>
                      <input class="registration_input form-control" id="postalCode" name="postalCode" autocomplete="off" formControlName="postalCode">
                      <div class="text-danger" *ngIf="bussinessDetails.controls['postalCode'].invalid && (isFormSubmitted_bussiness || bussinessDetails.controls['postalCode'].touched || bussinessDetails.controls['postalCode'].dirty)">
                          <i class="fa-solid fa-circle-exclamation"></i>  
                          <label *ngIf="bussinessDetails.controls['postalCode'].errors?.['required']" class="error help-block">Please enter a valid Post code.</label>
                          <label *ngIf="bussinessDetails.controls['postalCode'].errors?.['pattern']" class="error help-block">Please enter a valid Post code.</label>
                      </div>
                  </div>
                  <div class="col-lg-4 col-md-3 col-sm-7 col-xs-7 no-padding-right margin-B20 registration_parent form-group">
                      <label class="registration_inputLabel " for="business-Country">Country*</label>
                      <input class="registration_input form-control" id="business-country" name="country" autocomplete="off" formControlName="country">
                      <div class="text-danger" *ngIf="bussinessDetails.controls['country'].invalid && (isFormSubmitted_bussiness || bussinessDetails.controls['country'].touched || bussinessDetails.controls['country'].dirty)">
                          <i class="fa-solid fa-circle-exclamation"></i>  
                          <label *ngIf="bussinessDetails.controls['country'].errors?.['required']" class="error help-block">Please enter an Country.</label>
                      </div>
                  </div>
                  <div class="clearboth"></div>
              </div>
              <button type="button" (click)="onSubmit_bussiness()" class="registration_save active" id="registration_save">Save and Continue</button>
          </form>
      </section>
      <!-- business details end here --> 
      <section class="registration_billing registrationFlow" id="registration_section2" [style.display]="showInvoicedetails ? 'block' : 'none'">
          <form [formGroup]="billingDetails">
              <div class="registration_label padding-B10">Invoicing Information</div>
              <div class="registration_text1 padding-B10">Complete this section with the contact information of the person or group who should receive all financial documents from CSL Seqirus.</div>
              <div class="registration_text2">All fields marked with an asterisk (*) are required.</div>
              <div class="padding-R15">
                  <div class="margin-B20 registration_reviewCheckbox registration_parent registration_checkparent">                                              
                  <label class="checkbox-button">
                      <input type="checkbox" (change)="copyBusinessToBilling($event)" class="registration_checkbox" id="busi-invoice_check" name="busi-invoice_check">
                      <span class="checkbox-button__control"></span>
                  </label>
                  <label class="registration_checkboxLabel">Copy Business Contact Information</label>
                  <div class="clearboth"></div>
              </div>
              </div>
              <div class="registration_contact padding-B20">Invoicing Contact</div>
              <div class="padding-R15">
                  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding margin-B20 registration_parent form-group">                        
                      <label class="registration_inputLabel" for="billing-firstname">First Name*</label>
                      <input class="registration_input form-control" id="billing-firstname"  autocomplete="off" formControlName="firstName" >
                         <div class="text-danger" *ngIf="billingDetails.controls['firstName'].invalid && (isFormSubmitted_billing || billingDetails.controls['firstName'].touched || billingDetails.controls['firstName'].dirty)">
                          <i class="fa-solid fa-circle-exclamation"></i>   
                          <label *ngIf="billingDetails.controls['firstName'].errors?.['required']" class="error help-block">Please enter a First Name.</label>
                          <label *ngIf="billingDetails.controls['firstName'].errors?.['pattern']" class="error help-block">Please enter only letters.</label>
                         </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding-right margin-B20 registration_parent form-group">                        
                      <label class="registration_inputLabel" for="billing-lastname">Last Name*</label>
                      <input class="registration_input form-control" id="billing-lastname"  autocomplete="off" formControlName="lastName" >
                         <div class="text-danger" *ngIf="billingDetails.controls['lastName'].invalid && (isFormSubmitted_billing || billingDetails.controls['lastName'].touched || billingDetails.controls['lastName'].dirty)">
                          <i class="fa-solid fa-circle-exclamation"></i>  
                          <label *ngIf="billingDetails.controls['lastName'].errors?.['required']" class="error help-block">Please enter a Last Name.</label>
                             <label *ngIf="billingDetails.controls['lastName'].errors?.['pattern']" class="error help-block">Please enter only letters.</label>
                         </div>
                  </div>
                  <div class="clearboth"></div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding margin-B20 registration_parent form-group">                        
                  <label class="registration_inputLabel" for="billing-email">Email Address*</label>
                  <input class="registration_input form-control" id="billing-email"  autocomplete="off" formControlName="email" >
                  <div class="text-danger" *ngIf="billingDetails.controls['email'].invalid && (isFormSubmitted_billing || billingDetails.controls['email'].touched || billingDetails.controls['email'].dirty)">
                   <i class="fa-solid fa-circle-exclamation"></i>   
                   <label *ngIf="billingDetails.controls['email'].errors?.['required']" class="error help-block">Please enter a valid Email Address.</label>
                      <label *ngIf="billingDetails.controls['email'].errors?.['pattern']" class="error help-block">Please enter a valid Email Address.</label>
                  </div>
              </div>
              <div class="clearboth"></div>
              <div class="padding-R15">
                  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding margin-B20 registration_parent form-group">                        
                      <label class="registration_inputLabel" for="billing-phoneUK">Phone Number*</label>
                      <input class="registration_input form-control" maxlength="11" id="business-phoneUK" name="phoneNumber" autocomplete="off" formControlName="phone">
                      <div class="text-danger" *ngIf="billingDetails.controls['phone'].invalid && (isFormSubmitted_billing || billingDetails.controls['phone'].touched || billingDetails.controls['phone'].dirty)">
                          <i class="fa-solid fa-circle-exclamation"></i>  
                          <label *ngIf="billingDetails.controls['phone'].errors?.['required']" class="error help-block">Please enter a valid UK format phone number.</label>
                          <label *ngIf="billingDetails.controls['phone'].errors?.['minlength']" class="error help-block">Please enter a valid UK format phone number.</label>
                      </div>
                  </div>
                  <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 no-padding-right margin-B20 registration_parent form-group">                        
                      <label class="registration_inputLabel" for="billing-extn">Ext.</label>
                      <input class="registration_input form-control" formControlName="phoneExt"  maxlength="4" autocomplete="off">
                      <div class="text-danger" *ngIf="billingDetails.controls['phoneExt'].invalid && (isFormSubmitted_billing || billingDetails.controls['phoneExt'].touched || billingDetails.controls['phoneExt'].dirty)">
                        <i class="fa-solid fa-circle-exclamation"></i>  
                        <label *ngIf="billingDetails.controls['phoneExt'].errors?.['pattern']" class="error help-block">Please enter only Numbers</label>
                      </div>
                  </div>
                  <div class="clearboth"></div>
              </div>
              <div class="registration_contact padding-B20">Invoicing Address</div>
              <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding margin-B20 registration_parent form-group">                        
                  <label class="registration_inputLabel" for="billing-orgname">Organisation Name</label>
                  <input class="registration_input form-control" id="orgName" formControlName="orgName" autocomplete="no">
                  <div class="text-danger" *ngIf="billingDetails.controls['orgName'].invalid && (isFormSubmitted_billing || billingDetails.controls['orgName'].touched || billingDetails.controls['orgName'].dirty)">
                      <i class="fa-solid fa-circle-exclamation"></i>  
                      <label *ngIf="billingDetails.controls['orgName'].errors?.['required']" class="error help-block">Please enter Organisation Name</label>
                      <label *ngIf="billingDetails.controls['orgName'].errors?.['pattern']" class="error help-block">Please enter only letters</label>
                  </div>
              </div>
              <div class="clearboth"></div>
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding margin-B30 registration_address form-group">                        
                  <label class="registration_inputLabel" for="billing-lookup">Address Lookup</label>
                  <input class="registration_input form-control"  id="billinglookup"   name="billinglookup" >
                  <span class="glyphicon glyphicon-search form-control-feedback"></span>
              </div>
              <div class="padding-R15">
                  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding margin-B20 registration_parent form-group">
                      <label class="registration_inputLabel" for="business-address1">Address Line 1*</label>
                      <input class="registration_input form-control" id="line1" name="line1_billing" autocomplete="off" formControlName="line1">
                      <div class="text-danger" *ngIf="billingDetails.controls['line1'].invalid && (isFormSubmitted_billing || billingDetails.controls['line1'].touched || billingDetails.controls['line1'].dirty)">
                          <i class="fa-solid fa-circle-exclamation"></i>  
                          <label *ngIf="billingDetails.controls['line1'].errors?.['required']" class="error help-block">Please enter an Address.</label>
                      
                      </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding-right margin-B20 registration_parent form-group">
                      <label class="registration_inputLabel" for="business-addressStreet">Additional Street</label>
                      <input class="registration_input form-control" id="line2" name="line2_billing" autocomplete="off" formControlName="line2">
                  </div>
                  <div class="clearboth"></div>
              </div>
              <div class="padding-R15">
                  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding margin-B20 registration_parent form-group">
                      <label class="registration_inputLabel" for="business-city">City*</label>
                      <input class="registration_input form-control" id="city" name="city_billing" autocomplete="off" formControlName="city">
                      <div class="text-danger" *ngIf="billingDetails.controls['city'].invalid && (isFormSubmitted_billing || billingDetails.controls['city'].touched || billingDetails.controls['city'].dirty)">
                          <i class="fa-solid fa-circle-exclamation"></i>  
                          <label *ngIf="billingDetails.controls['city'].errors?.['required']" class="error help-block">Please enter an City.</label>
                      </div>
                  </div>
                  <div class="col-lg-2 col-md-3 col-sm-5 col-xs-5 no-padding-right margin-B20 registration_parent form-group">
                      <label class="registration_inputLabel " for="business-Post-Code">Post Code*</label>
                      <input class="registration_input form-control" id="postalCode" name="postalCode_billing" autocomplete="off" formControlName="postalCode">
                      <div class="text-danger" *ngIf="billingDetails.controls['postalCode'].invalid && (isFormSubmitted_billing || billingDetails.controls['postalCode'].touched || billingDetails.controls['postalCode'].dirty)">
                          <i class="fa-solid fa-circle-exclamation"></i>  
                          <label *ngIf="billingDetails.controls['postalCode'].errors?.['required']" class="error help-block">Please enter a valid Post code.</label>
                          <label *ngIf="billingDetails.controls['postalCode'].errors?.['pattern']" class="error help-block">Please enter a valid Post code.</label>
                      </div>
                  </div>
                  <div class="col-lg-4 col-md-3 col-sm-7 col-xs-7 no-padding-right margin-B20 registration_parent form-group">
                      <label class="registration_inputLabel " for="business-Country">Country*</label>
                      <input class="registration_input form-control" id="business-country" name="country_billing" autocomplete="off" formControlName="country">
                      <div class="text-danger" *ngIf="billingDetails.controls['country'].invalid && (isFormSubmitted_billing || billingDetails.controls['country'].touched || billingDetails.controls['country'].dirty)">
                          <i class="fa-solid fa-circle-exclamation"></i>  
                          <label *ngIf="billingDetails.controls['country'].errors?.['required']" class="error help-block">Please enter an Country.</label>
                      </div>
                  </div>
                  <div class="clearboth"></div>
              </div>
               <div class="registration_contact padding-B20">Communication Preferences</div>
              <div class="padding-R15">
                  <div class="margin-B20 registration_reviewCheckbox registration_parent registration_checkparent">                                              
                  <label class="checkbox-button">
                      <input type="checkbox" class="registration_checkbox" id="alt_email" name="alt_email" (change)="toggleAcctStmtEmail($event)">
                      <span class="checkbox-button__control"></span>
                  </label>
                  <label class="registration_checkboxLabel">Use alternate email for financial statements</label>
                  <div class="clearboth"></div>
              </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 no-padding margin-B20 registration_parent form-group" id="bill-accemail" style="display:none;" >                        
                  <label class="registration_inputLabel " for="billing-alt_email">Email Address*</label>
                  <input class="registration_input form-control" id="billing-alt_email" name="acctStmtEmail" formControlName="acctStmtEmail" autocomplete="off">
                  <div class="text-danger" *ngIf="billingDetails.controls['acctStmtEmail'].invalid && (isFormSubmitted_billing || billingDetails.controls['acctStmtEmail'].touched || billingDetails.controls['acctStmtEmail'].dirty)">
                      <label *ngIf="billingDetails.controls['acctStmtEmail'].errors?.['required']" class="error help-block">Please enter a valid Email Address.</label>
                      <label *ngIf="billingDetails.controls['acctStmtEmail'].errors?.['pattern']" class="error help-block">Please enter a valid Email Address.</label>
                    </div>
              </div>
              <div class="clearboth"></div>
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding margin-T20 margin-B20 registration_button">
                  <div class="registration_back active" (click)="showBussinessSection()">
                      <div class="global_blackarrow"></div>
                      <span>Back</span>
                      <div class="clearboth"></div>
                  </div>
                  <button type="button" class="registration_save active" id="registration_save" (click)="onSubmit_billing()">Save and Continue</button>
                  <div class="clearboth"></div>
              </div>  
                
          </form>
      </section>
      <!--Invoice Information end here-->
      
      <section class="registration_billing registrationFlow" id="registration_section2" [style.display]="showPayingdetails ? 'block' : 'none'">
          <form [formGroup]="payerDetails">
              <div class="registration_label padding-B10">Payer Information</div>
              <div class="registration_text1 padding-B10">Complete this section with the contact information of the person or group who will be paying invoices that come from CSL Seqirus.</div>
              <div class="registration_text2">All fields marked with an asterisk (*) are required.</div>
              <div class="padding-R15">
                  <div class="margin-B20 registration_reviewCheckbox registration_parent registration_checkparent">                                              
                  <label class="checkbox-button">
                      <input type="checkbox" (change)="copyBillingToPaying($event)" class="registration_checkbox" id="busi-invoice_check" name="busi-invoice_check">
                      <span class="checkbox-button__control"></span>
                  </label>
                  <label class="registration_checkboxLabel">Copy Invoicing Contact Information</label>
                  <div class="clearboth"></div>
              </div>
              </div>
              <div class="registration_contact padding-B20">Payer Contact</div>
              <div class="padding-R15">
                  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding margin-B20 registration_parent form-group">                        
                      <label class="registration_inputLabel" for="billing-firstname">First Name*</label>
                      <input class="registration_input form-control" id="billing-firstname"  autocomplete="off" formControlName="firstName" >
                         <div class="text-danger" *ngIf="payerDetails.controls['firstName'].invalid && (isFormSubmitted_paying || payerDetails.controls['firstName'].touched || payerDetails.controls['firstName'].dirty)">
                          <i class="fa-solid fa-circle-exclamation"></i>   
                          <label *ngIf="payerDetails.controls['firstName'].errors?.['required']" class="error help-block">Please enter a First Name.</label>
                          <label *ngIf="payerDetails.controls['firstName'].errors?.['pattern']" class="error help-block">Please enter only letters.</label>
                         </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding-right margin-B20 registration_parent form-group">                        
                      <label class="registration_inputLabel" for="billing-lastname">Last Name*</label>
                      <input class="registration_input form-control" id="billing-lastname"  autocomplete="off" formControlName="lastName" >
                         <div class="text-danger" *ngIf="payerDetails.controls['lastName'].invalid && (isFormSubmitted_paying || payerDetails.controls['lastName'].touched || payerDetails.controls['lastName'].dirty)">
                          <i class="fa-solid fa-circle-exclamation"></i>  
                          <label *ngIf="payerDetails.controls['lastName'].errors?.['required']" class="error help-block">Please enter a Last Name.</label>
                             <label *ngIf="payerDetails.controls['lastName'].errors?.['pattern']" class="error help-block">Please enter only letters.</label>
                         </div>
                  </div>
                  <div class="clearboth"></div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding margin-B20 registration_parent form-group">                        
                  <label class="registration_inputLabel" for="billing-email">Email Address*</label>
                  <input class="registration_input form-control" id="billing-email"  autocomplete="off" formControlName="email" >
                  <div class="text-danger" *ngIf="payerDetails.controls['email'].invalid && (isFormSubmitted_paying || payerDetails.controls['email'].touched || payerDetails.controls['email'].dirty)">
                   <i class="fa-solid fa-circle-exclamation"></i>   
                   <label *ngIf="payerDetails.controls['email'].errors?.['required']" class="error help-block">Please enter a valid Email Address.</label>
                      <label *ngIf="payerDetails.controls['email'].errors?.['pattern']" class="error help-block">Please enter a valid Email Address.</label>
                  </div>
              </div>
              <div class="clearboth"></div>
              <div class="padding-R15">
                  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding margin-B20 registration_parent form-group">                        
                      <label class="registration_inputLabel" for="billing-phoneUK">Phone Number*</label>
                      <input class="registration_input form-control" maxlength="11" id="business-phoneUK" name="phoneNumber" autocomplete="off" formControlName="phone">
                      <div class="text-danger" *ngIf="payerDetails.controls['phone'].invalid && (isFormSubmitted_paying || payerDetails.controls['phone'].touched || payerDetails.controls['phone'].dirty)">
                          <i class="fa-solid fa-circle-exclamation"></i>  
                          <label *ngIf="payerDetails.controls['phone'].errors?.['required']" class="error help-block">Please enter a valid UK format phone number.</label>
                          <label *ngIf="payerDetails.controls['phone'].errors?.['minlength']" class="error help-block">Please enter a valid UK format phone number.</label>
                      </div>
                  </div>
                  <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 no-padding-right margin-B20 registration_parent form-group">                        
                      <label class="registration_inputLabel" for="billing-extn">Ext.</label>
                      <input class="registration_input form-control" formControlName="phoneExt"  maxlength="4" autocomplete="off">
                      <div class="text-danger" *ngIf="payerDetails.controls['phoneExt'].invalid && (isFormSubmitted_paying || payerDetails.controls['phoneExt'].touched || payerDetails.controls['phoneExt'].dirty)">
                        <i class="fa-solid fa-circle-exclamation"></i>  
                        <label *ngIf="payerDetails.controls['phoneExt'].errors?.['pattern']" class="error help-block">Please enter only Numbers</label>
                      </div>
                  </div>
                  <div class="clearboth"></div>
              </div>
              <div class="registration_contact padding-B20">Payer Address</div>
              <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding margin-B20 registration_parent form-group">                        
                  <label class="registration_inputLabel" for="billing-orgname">Organisation Name</label>
                  <input class="registration_input form-control" id="orgName" formControlName="orgName" autocomplete="no">
                  <div class="text-danger" *ngIf="payerDetails.controls['orgName'].invalid && (isFormSubmitted_paying || payerDetails.controls['orgName'].touched || payerDetails.controls['orgName'].dirty)">
                      <i class="fa-solid fa-circle-exclamation"></i>  
                      <label *ngIf="payerDetails.controls['orgName'].errors?.['required']" class="error help-block">Please enter Organisation Name</label>
                      <label *ngIf="payerDetails.controls['orgName'].errors?.['pattern']" class="error help-block">Please enter only letters</label>
                  </div>
              </div>
              <div class="clearboth"></div>
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding margin-B30 registration_address form-group">                        
                  <label class="registration_inputLabel" for="billing-lookup">Address Lookup</label>
                  <input class="registration_input form-control"  id="payinglookup"   name="payinglookup" >
                  <span class="glyphicon glyphicon-search form-control-feedback"></span>
              </div>
              <div class="padding-R15">
                  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding margin-B20 registration_parent form-group">
                      <label class="registration_inputLabel" for="business-address1">Address Line 1*</label>
                      <input class="registration_input form-control" id="line1" name="line1_paying" autocomplete="off" formControlName="line1">
                      <div class="text-danger" *ngIf="payerDetails.controls['line1'].invalid && (isFormSubmitted_paying || payerDetails.controls['line1'].touched || payerDetails.controls['line1'].dirty)">
                          <i class="fa-solid fa-circle-exclamation"></i>  
                          <label *ngIf="payerDetails.controls['line1'].errors?.['required']" class="error help-block">Please enter an Address.</label>
                      
                      </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding-right margin-B20 registration_parent form-group">
                      <label class="registration_inputLabel" for="business-addressStreet">Additional Street</label>
                      <input class="registration_input form-control" id="line2" name="line2_paying" autocomplete="off" formControlName="line2">
                  </div>
                  <div class="clearboth"></div>
              </div>
              <div class="padding-R15">
                  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding margin-B20 registration_parent form-group">
                      <label class="registration_inputLabel" for="business-city">City*</label>
                      <input class="registration_input form-control" id="city" name="city_paying" autocomplete="off" formControlName="city">
                      <div class="text-danger" *ngIf="payerDetails.controls['city'].invalid && (isFormSubmitted_paying || payerDetails.controls['city'].touched || payerDetails.controls['city'].dirty)">
                          <i class="fa-solid fa-circle-exclamation"></i>  
                          <label *ngIf="payerDetails.controls['city'].errors?.['required']" class="error help-block">Please enter an City.</label>
                      </div>
                  </div>
                  <div class="col-lg-2 col-md-3 col-sm-5 col-xs-5 no-padding-right margin-B20 registration_parent form-group">
                      <label class="registration_inputLabel " for="business-Post-Code">Post Code*</label>
                      <input class="registration_input form-control" id="postalCode" name="postalCode_paying" autocomplete="off" formControlName="postalCode">
                      <div class="text-danger" *ngIf="payerDetails.controls['postalCode'].invalid && (isFormSubmitted_paying || payerDetails.controls['postalCode'].touched || payerDetails.controls['postalCode'].dirty)">
                          <i class="fa-solid fa-circle-exclamation"></i>  
                          <label *ngIf="payerDetails.controls['postalCode'].errors?.['required']" class="error help-block">Please enter a valid Post code.</label>
                          <label *ngIf="payerDetails.controls['postalCode'].errors?.['pattern']" class="error help-block">Please enter a valid Post code.</label>
                      </div>
                  </div>
                  <div class="col-lg-4 col-md-3 col-sm-7 col-xs-7 no-padding-right margin-B20 registration_parent form-group">
                      <label class="registration_inputLabel " for="business-Country">Country*</label>
                      <input class="registration_input form-control" id="business-country" name="country_paying" autocomplete="off" formControlName="country">
                      <div class="text-danger" *ngIf="payerDetails.controls['country'].invalid && (isFormSubmitted_paying || payerDetails.controls['country'].touched || payerDetails.controls['country'].dirty)">
                          <i class="fa-solid fa-circle-exclamation"></i>  
                          <label *ngIf="payerDetails.controls['country'].errors?.['required']" class="error help-block">Please enter an Country.</label>
                      </div>
                  </div>
                  <div class="clearboth"></div>
              </div>

              <div class="clearboth"></div>
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding margin-T20 margin-B20 registration_button">
                  <div class="registration_back active" (click)="showBillingSection()">
                      <div class="global_blackarrow"></div>
                      <span>Back</span>
                      <div class="clearboth"></div>
                  </div>
                  <button type="button" class="registration_save active" id="registration_save" (click)="onSubmit_paying()">Save and Continue</button>
                  <div class="clearboth"></div>
              </div>  
                
          </form>
      </section>



      <!--Payer Information end here-->

     


      <section class="registration_shipping registrationFlow" id="registration_section4" [style.display]="showShippingdetails ? 'block' : 'none'">
          <form id="shippingForm" [formGroup]="shippingForm">
          <div formArrayName="shippingList">
                   <div class="registration_label padding-B10">Delivery Locations</div>
                 
               
               <div class="registration_text1 padding-B10">Complete this section with your delivery location(s) used for receiving vaccines. You can always add or amend locations when you need.</div>
               <div class="registration_text2">All fields marked with an asterisk (*) are required. You can add a maximum of 6 delivery locations during registration.</div>
              <div class="padding-R15 org_ship" *ngIf="showCheckbox">
                   <div  class="margin-B20 registration_reviewCheckbox registration_parent registration_checkparent">                                              
                   <label class="checkbox-button">
                       <input type="checkbox" class="registration_checkbox" id="org-shipping_check" name="org-shipping_check" (change)="copyBusinessToShipping($event, 0)">
                       <span class="checkbox-button__control"></span>
                   </label>
                   <label class="registration_checkboxLabel">Copy Organisation Contact Information</label>
                   <div class="clearboth"></div>
               </div>
               </div>
                               
               <div class="print_shipping">
                  
                </div>
                                
                 
                      <div class="shipping-blk"  *ngFor="let shipping of shippingList.controls; let i = index" [formGroupName]="i">
                          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 registration_locations" *ngIf="!shipping.get('isEditing')?.value && shipping.get('submitted')?.value" [style.display]="shipping.get('submitted')?.value ? 'block' : 'none'">
                              <div class="registration_locationparent">
                                <div class="registration_locationLine1">
                                  <div class="registration_locationName">Location <span class="locationno">{{ i + 1 }}</span></div>
                                  <div class="registration_locationOrg">{{ shipping.get('orgName')?.value }}</div>
                                  <div class="clearboth"></div>
                                </div>
                                <div class="registration_edit ship_del" (click)="removeShippingForm(i)">Remove <i class="fa fa-trash" aria-hidden="true"></i></div>
                                <div class="registration_edit only_edit_ship" [class.disabled]="isAnyFormEditing && !shipping.get('isEditing')?.value" (click)="!isAnyFormEditing && toggleShipping(i)">Edit <i class="fa fa-pencil-square-o" aria-hidden="true"></i></div>
                                <div class="clearboth"></div>
                              </div>
                              <div class="registration_locationLine2">
                                <div class="registration_locationcontact">
                                  <div class="registration_locationcontactline1">Contact Info</div>
                                  <div class="registration_locationcontactname">
                                    <span>{{ shipping.get('firstName')?.value }}</span>&nbsp; <span>{{ shipping.get('lastName')?.value }}</span>
                                  </div>
                                  <div class="registration_locationcontactemail">
                                    <span>{{ shipping.get('email')?.value }}</span>
                                  </div>
                                  <div class="registration_locationcontactphn">
                                    <span>{{ shipping.get('phone')?.value }}</span> ext. <span>{{ shipping.get('phoneExt')?.value }}</span>
                                  </div>
                                </div>
                                <div class="registration_locationaddress">
                                  <div class="registration_locationcontactline1">Address</div>
                                  <div class="registration_locationaddress1">
                                    <span>{{ shipping.get('line1')?.value }}</span>
                                  </div>
                                  <div class="registration_locationaddress2">
                                    <span>{{ shipping.get('line2')?.value }}</span>
                                  </div>
                                  <div class="registration_locationaddress4">
                                    <span>{{ shipping.get('city')?.value }}</span>,<span>{{ shipping.get('postalCode')?.value }}</span><br/>
                                    <span>{{ shipping.get('country')?.value }}</span>
                                    
                                  </div>
                                </div>
                                <div class="registration_locationLic">
                                  <div class="registration_locationcontactline1">License</div>
                                  <div class="registration_locationlic1">
                                    <span>{{ shipping.get('licenseDetailsForm.licenseName')?.value }}</span> - <span>{{ shipping.get('licenseDetailsForm.licenseNum')?.value }}</span>
                                  </div>
                                  <div class="registration_locationlic3">
                                    <span>{{ shipping.get('licenseDetailsForm.nhsCode')?.value }}</span>
                                  </div>
                                  
                                </div>
                                <div class="clearboth"></div>
                              </div>
                            </div>
                                  <div class="shipping-block" [ngClass]="{'registration_locations': shipping.get('isEditing')?.value}" *ngIf="!shipping.get('submitted')?.value || shipping.get('isEditing')?.value">
                                      <div class="locationtitel" *ngIf="showLocationcount">Location <span class="locationno1">{{i+1}}</span></div>
                                      <div class="registration_contact padding-B20">
                                      Delivery Location Contact &nbsp;&nbsp;<span tooltip="This Delivery Location Contact information will be used for shipping product. You can add more than one Delivery Location Contact." tooltip-position="buttom" ><i class="fa fa-question-circle-o" ></i></span>
                                      <div class="registration_edit" *ngIf="(shipping.get('isEditing')?.value || isAddingNewForm) && shippingList.length > 1"  (click)="removeShippingForm(i)">Remove<span class="editiconpadd"> <i class="fa fa-trash" aria-hidden="true"></i></span></div>
                                    </div>
                                    <div class="padding-R15">
                                      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding margin-B20 registration_parent form-group">
                                        <label class="registration_inputLabel" for="business-firstname">First Name*</label>
                                        <input class="registration_input form-control" id="business-firstname" formControlName="firstName" autocomplete="off">
                                        <div class="text-danger" *ngIf="shipping.get('firstName')!.invalid && (isFormSubmitted_shipping || shipping.get('firstName')!.touched || shipping.get('firstName')!.dirty)">
                                          <i class="fa-solid fa-circle-exclamation"></i>
                                          <label *ngIf="shipping.get('firstName')!.errors?.['required']" class="error help-block">Please enter a First Name.</label>
                                          <label *ngIf="shipping.get('firstName')!.errors?.['pattern']" class="error help-block">Please enter only letters</label>
                                        </div>
                                      </div>
                                      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding-right margin-B20 registration_parent form-group">
                                        <label class="registration_inputLabel" for="business-lastname">Last Name*</label>
                                        <input class="registration_input form-control" id="business-lastname" formControlName="lastName" autocomplete="off">
                                        <div class="text-danger" *ngIf="shipping.get('lastName')!.invalid && (isFormSubmitted_shipping || shipping.get('lastName')!.touched || shipping.get('lastName')!.dirty)">
                                          <i class="fa-solid fa-circle-exclamation"></i>
                                          <label *ngIf="shipping.get('lastName')!.errors?.['required']" class="error help-block">Please enter a Last Name.</label>
                                          <label *ngIf="shipping.get('lastName')!.errors?.['pattern']" class="error help-block">Please enter only letters</label>
                                      </div>
                                      <div class="clearboth"></div>
                                    </div>
                                    <div class="clearboth"></div>
                                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding-left margin-B20 registration_parent form-group">
                                      <label class="registration_inputLabel" for="shipping-email">Email Address*</label>
                                      <input class="registration_input form-control field_mandatory" id="shipping-email" formControlName="email" autocomplete="no">
                                      <div class="text-danger" *ngIf="shipping.get('email')!.invalid && (isFormSubmitted_shipping || shipping.get('email')!.touched || shipping.get('email')!.dirty)">
                                          <i class="fa-solid fa-circle-exclamation"></i>
                                          <label *ngIf="shipping.get('email')!.errors?.['required']" class="error help-block">Please enter a valid Email.</label>
                                          <label *ngIf="shipping.get('email')!.errors?.['pattern']" class="error help-block">Please enter a valid Email.</label>
                                      </div>
                                    </div>
                                    <div class="clearboth"></div>
                                    <div class="padding-R15">
                                      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding margin-B20 registration_parent form-group">
                                        <label class="registration_inputLabel" for="shipping-phno">Phone Number*</label>
                                        <input class="registration_input form-control field_mandatory" maxlength="11" id="shipping-phno" formControlName="phone" autocomplete="no">
                                        <div class="text-danger" *ngIf="shipping.get('phone')!.invalid && (isFormSubmitted_shipping || shipping.get('phone')!.touched || shipping.get('phone')!.dirty)">
                                          <i class="fa-solid fa-circle-exclamation"></i>

                                          <label *ngIf="shipping.get('phone')!.errors?.['required']" class="error help-block">Please enter a Valid UK Phone Number.</label>
                                          <label *ngIf="shipping.get('phone')!.errors?.['minlength']" class="error help-block">Please enter a Valid UK Phone Number</label>
                                      </div>
                                      </div>
                                      <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 no-padding-right margin-B20 registration_parent form-group">
                                        <label class="registration_inputLabel" for="shipping-extension">Ext.</label>
                                        <input class="registration_input form-control" maxlength="4" id="shipping-extension" formControlName="phoneExt" autocomplete="no">
                                        <div class="text-danger" *ngIf="shipping.get('phoneExt')!.invalid && (isFormSubmitted_shipping || shipping.get('phoneExt')!.touched || shipping.get('phoneExt')!.dirty)">
                                          <i class="fa-solid fa-circle-exclamation"></i>  
                                          <label *ngIf="shipping.get('phoneExt')!.errors?.['pattern']" class="error help-block">Please enter only Numbers</label>
                                        </div>
                                      </div>
                                      <div class="clearboth"></div>
                                    </div>
                                    <div class="registration_contact padding-B20">Delivery Address</div>
                                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding-left margin-B20 registration_parent form-group">
                                      <label class="registration_inputLabel" for="shipping-orgname">Organisation Name</label>
                                      <input class="registration_input form-control field_mandatory" id="shipping-orgname" formControlName="orgName" autocomplete="no">
                                      <div class="text-danger" *ngIf="shipping.get('orgName')!.invalid && (isFormSubmitted_shipping || shipping.get('orgName')!.touched || shipping.get('orgName')!.dirty)">
                                          <i class="fa-solid fa-circle-exclamation"></i>
                                          <label *ngIf="shipping.get('orgName')!.errors?.['required']" class="error help-block">Please enter Organisation Name</label>
                                          <label *ngIf="shipping.get('orgName')!.errors?.['pattern']" class="error help-block">Please enter only letters</label>
                                      </div>
                                  </div>
                                    <div class="clearboth"></div>
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding-left margin-B30 registration_address form-group">
                                      <label class="registration_inputLabel" for="shipping-lookup1">Address Lookup</label>
                                      <input class="registration_input form-control" [id]="'shipping_lookup'+i"  [name]="'shipping_lookup'+i" >
                                      <span class="glyphicon glyphicon-search form-control-feedback"></span>
                                    </div>
                                    <div class="padding-R15">
                                      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding-left margin-B20 registration_parent form-group">
                                        <label class="registration_inputLabel" for="shipping-address1">Address Line 1*</label>
                                        <input class="registration_input form-control field_mandatory" id="shipping-address1" name="line1{{i}}" formControlName="line1" autocomplete="no">
                                        <div class="text-danger" *ngIf="shipping.get('line1')!.invalid && (isFormSubmitted_shipping || shipping.get('line1')!.touched || shipping.get('line1')!.dirty)">
                                          <i class="fa-solid fa-circle-exclamation"></i>
                                          <label *ngIf="shipping.get('line1')!.errors?.['required']" class="error help-block">Please enter an Address</label>
                                        </div>
                                      </div>
                                      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding-right margin-B20 registration_parent form-group">
                                        <label class="registration_inputLabel" for="shipping-addressStreet">Additional Street</label>
                                        <input class="registration_input form-control" id="shipping-addressStreet" formControlName="line2" name="line2{{i}}" autocomplete="no">
                                      </div>
                                      <div class="clearboth"></div>
                                    </div>
                                    <div class="padding-R15">
                                      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding-left margin-B20 registration_parent form-group">
                                        <label class="registration_inputLabel" for="shipping-city1">City*</label>
                                        <input class="registration_input form-control field_mandatory" id="shipping-city1" name="city{{i}}" formControlName="city"  autocomplete="no">
                                        <div class="text-danger" *ngIf="shipping.get('city')!.invalid && (isFormSubmitted_shipping || shipping.get('city')!.touched || shipping.get('city')!.dirty)">
                                          <i class="fa-solid fa-circle-exclamation"></i>
                                          <label *ngIf="shipping.get('city')!.errors?.['required']" class="error help-block">Please enter an City.</label>
                                        </div>
                                      </div>
                                      <div class="col-lg-2 col-md-3 col-sm-5 col-xs-5 no-padding-right margin-B20 registration_parent form-group">
                                        <label class="registration_inputLabel" for="shipping-Post-Code">Post Code*</label>
                                        <input class="registration_input form-control field_mandatory" id="shipping-postcode" name="postalCode{{i}}" formControlName="postalCode" autocomplete="off">
                                        <div class="text-danger" *ngIf="shipping.get('postalCode')!.invalid && (isFormSubmitted_shipping || shipping.get('postalCode')!.touched || shipping.get('postalCode')!.dirty)">
                                          <i class="fa-solid fa-circle-exclamation"></i>
                                          <label *ngIf="shipping.get('postalCode')!.errors?.['required']" class="error help-block">Please enter a valid Post code.</label>
                                        </div>
                                      </div>
                                      <div class="col-lg-4 col-md-3 col-sm-7 col-xs-7 no-padding-right margin-B20 form-group">
                                        <label class="registration_inputLabel" for="shipping-Country">Country*</label>
                                        <input class="registration_input form-control field_mandatory" id="shipping-country" name="country{{i}}"   formControlName="country" autocomplete="off">
                                        <div class="text-danger" *ngIf="shipping.get('country')!.invalid && (isFormSubmitted_shipping || shipping.get('country')!.touched || shipping.get('country')!.dirty)">
                                          <i class="fa-solid fa-circle-exclamation"></i>
                                          <label *ngIf="shipping.get('country')!.errors?.['required']" class="error help-block">Please enter an Country.</label>
                                        </div>
                                      </div>
                                      <div class="clearboth"></div>
                                    </div>
                                    <div class="registration_contact padding-B10">License Information</div>
                                    <div class="registration_text2"><i>This is the licence number for a doctor/pharmacist/nurse with a licence to practise.</i></div>
                                    <div class="padding-R15" [formGroupName]="'licenseDetailsForm'">
                                      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding margin-B20 registration_parent form-group">
                                        <label class="registration_inputLabel" for="shipping-licensename">Licence Name*</label>
                                        <input class="registration_input form-control field_mandatory" id="shipping-licensename" formControlName="licenseName" autocomplete="off">
                                        <div class="text-danger" *ngIf="shipping.get('licenseDetailsForm.licenseName')?.invalid && (isFormSubmitted_shipping || shipping.get('licenseDetailsForm.licenseName')?.touched || shipping.get('licenseDetailsForm.licenseName')?.dirty)">
                                          <i class="fa-solid fa-circle-exclamation"></i>
                                          <label *ngIf="shipping.get('licenseDetailsForm.licenseName')?.errors?.['required']" class="error help-block">Please enter License Name.</label>
                                          <label *ngIf="shipping.get('licenseDetailsForm.licenseName')?.errors?.['pattern']" class="error help-block">Please enter only letters</label>
                                        </div>
                                      
                                      </div>
                                      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding-right margin-B20 registration_parent form-group">
                                        <label class="registration_inputLabel" for="shipping-licenseno">Licence Number*</label>
                                        <input class="registration_input form-control field_mandatory" maxlength="7" id="shipping-licenseno" formControlName="licenseNum" autocomplete="no">
                                        <div class="text-danger" *ngIf="shipping.get('licenseDetailsForm.licenseNum')?.invalid && (isFormSubmitted_shipping || shipping.get('licenseDetailsForm.licenseNum')?.touched || shipping.get('licenseDetailsForm.licenseNum')?.dirty)">
                                          <i class="fa-solid fa-circle-exclamation"></i>
                                          <label *ngIf="shipping.get('licenseDetailsForm.licenseNum')?.errors?.['required']" class="error help-block">Please enter a License Number</label>
                                          <label *ngIf="shipping.get('licenseDetailsForm.licenseNum')?.errors?.['pattern']" class="error help-block">Please enter only Numbers</label>
                                          <div *ngIf="!shipping.get('licenseDetailsForm.licenseNum')?.errors?.['pattern']">
                                          <label *ngIf="shipping.get('licenseDetailsForm.licenseNum')?.errors?.['minlength']" class="error help-block">Please provide your Licence Number</label>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="clearboth"></div>
                                      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding-left margin-B20 registration_parent form-group">
                                        <label class="registration_inputLabel" for="shipping-nhs">NHS Code</label>
                                        <input class="registration_input form-control field_mandatory" maxlength="6" id="shipping-nhs" formControlName="nhsCode" autocomplete="no">

                                        <div class="text-danger" *ngIf="shipping.get('licenseDetailsForm.nhsCode')?.invalid && (isFormSubmitted_shipping || shipping.get('licenseDetailsForm.nhsCode')?.touched || shipping.get('licenseDetailsForm.nhsCode')?.dirty)">
                                          <i class="fa-solid fa-circle-exclamation"></i>
                                          <div *ngIf="shipping.get('licenseDetailsForm.nhsCode')?.errors?.['alphanumeric']">
                                            <label id="shipping-nhs_code-error" class="error help-block" for="shipping-nhs">Please enter only letters, numbers</label>
                                          </div>
                                          <div *ngIf="!shipping.get('licenseDetailsForm.nhsCode')?.errors?.['alphanumeric'] && shipping.get('licenseDetailsForm.nhsCode')?.errors?.['pattern']">
                                            <div *ngIf="bussinessDetails.get('orgType')?.value === 'GENERAL_PRACTICE'">
                                              <label id="shipping-nhs_code-error" class="error help-block" for="shipping-nhs">Please provide a valid NHS code e.g. Y02906</label>
                                            </div>
                                            <div *ngIf="bussinessDetails.get('orgType')?.value === 'PHARMACY'">
                                              <label id="shipping-nhs_code-error" class="error help-block" for="shipping-nhs">Please provide a valid NHS code e.g. F1J4D</label>
                                            </div>
                                            <div *ngIf="bussinessDetails.get('orgType')?.value === 'HOSPITAL_PUBLIC' || bussinessDetails.get('orgType')?.value === 'HOSPITAL_PRIVATE' || bussinessDetails.get('orgType')?.value === 'NURSING_HOMES' || bussinessDetails.get('orgType')?.value === 'CORRECTIONAL_FACILITY' || bussinessDetails.get('orgType')?.value === 'WHOLESALER'">
                                              <label id="shipping-nhs_code-error" class="error help-block" for="shipping-nhs">Please provide a valid NHS code</label>
                                            </div>
                                          </div>
                                        </div>
                                        

                                      </div>
                                      <div class="clearboth"></div>
                                      <div class="col-md-12"><button type="button" class="shipping_edit_save" (click)="modifyForm(i, shipping.value)" *ngIf="shipping.get('isEditing')?.value">Save</button></div>
                                    </div>
                                    <div class="clearboth"></div>
                                  </div>
                                  </div>
                                           
                  <div class="clearboth"></div>
                 
                    
                               
                  <div *ngIf="i === shippingList.controls.length - 1" >
                      <div class="registration_addbutton margin-B20" (click)="addAnotherLocation(i)" [ngClass]="{'registration_addbutton_disable': !checkFormValidity(i)}" *ngIf="!toggleButtonVisibility(i)">Add Another Location &nbsp;<span class="glyphicon glyphicon-remove-circle"></span></div>
                      <div class="margin-B20 only_show_field" (click)="addShippingForm()" *ngIf="toggleButtonVisibility(i) && !isAnyFormEditing" >Add Another Location &nbsp;<span class="glyphicon glyphicon-remove-circle"></span></div>
                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding margin-T20 margin-B20 registration_button">
                       <div class="registration_back active" (click)="showPayingSection()">
                           <div class="global_blackarrow"></div>
                           <span>Back</span>
                           <div class="clearboth"></div>
                       </div>
                       <button type="button" class="registration_save active" id="registration_save"  (click)="onSubmit_shipping(i)">Save and Continue</button>
                       <div class="clearboth"></div>
                   </div>
                   </div>
              
              </div> 
          </div>
          </form>
       </section>
       <!--Shipping Information end here-->


       <section class="registration_review registrationFlow" id="registration_section5"  [style.display]="showReviewdetails ? 'block' : 'none'">
          <form id="reviewForm" novalidate="novalidate" data-gtm-vis-recent-on-screen55327271_215="1160805" data-gtm-vis-first-on-screen55327271_215="1160805" data-gtm-vis-total-visible-time55327271_215="100" data-gtm-vis-has-fired55327271_215="1">
             <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding-left registration_label padding-B10">Review Information</div>
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding-left registration_text1 padding-B20">Please review the information you have submitted. We will verify your account, or contact you if anything looks incomplete or incorrect, within 5 business days. Following verification, you will have access to your new CSL Seqirus and flu360 account.</div>
              <div class="clearboth"></div>
              <div class="registration_reviewContainer">
                  <div class="registration_reviewProfile padding-B20" *ngIf="userData">
                      <div class="registration_reviewlabel1">Profile</div>
                      
                      <div class="clearboth"></div>
                      <div class="registration_reviewlabel2 margin-B10">Login Information</div>
                      <div class="registration_populate registration_reviewemail1">{{userData.email}}</div>
                      <!-- <div class="registration_populate registration_reviewpassword" id="reviewLoginPassword">***********</div> -->
                      <div class="registration_reviewlabel2 margin-T25 margin-B10">Contact Information</div>
                      <div class="registration_populate registration_reviewname" id="reviewName">{{userData.firstName}}&nbsp;{{userData.lastName}}</div>
                      <div class="registration_populate registration_reviewBuyer" id="reviewJob">{{userData.jobTitle}}</div>
                      <div class="registration_populate registration_reviewemail" id="reviewContactEmail">{{userData.email}}</div>
                      <div class="registration_populate registration_reviewPh reviewcdcphonehead">
                        <div id="reviewPhone">{{userData.phone}}</div>
                        <div class="reviewcdcphone">Ext.</div>
                        <div class="reviewcdcphone" id="reviewPhoneext">{{userData.phoneExt}}</div>
                      </div>
                  </div>
                  <div class="registration_reviewBusiness padding-B20" *ngIf="formDataBussiness">
                      <div class="registration_reviewparent">
                          <div class="registration_reviewlabel1">Business Details</div>
                          <div class="registration_edit" id="registration_Businessedit" (click)="showBussinessSection()">Edit <i class="fa fa-pencil-square-o" aria-hidden="true"></i></div>
                          <div class="clearboth"></div>
                      </div>
                      <div class="registration_reviewlabel2 margin-B10">Organisation Information</div>
                      <div class="registration_populate registration_companyName" id="review-orgname">{{ formDataBussiness.orgName}}</div>
                      <div class="registration_populate registration_duns" id="review-reg">Company Registration #: {{ formDataBussiness.orgRegNo}}</div>
                      <div class="registration_populate registration_vat" id="review-vat">VAT #: {{ formDataBussiness.vatNo}}</div>
                      <div class="registration_populate registration_tading" id="review-trade">Trading Since: {{ formDataBussiness.tradingSince}}</div>
                      <div class="registration_populate registration_orgtype" id="review-orgtype">Organisation Type: {{ formattedBusinessType }}</div>
                      <div class="registration_populate registration_nhscode" id="review-nhscode">NHS Code: {{ formDataBussiness.nhsCode}}</div>
                      <div class="registration_reviewlabel2 margin-T25 margin-B10">Contact Information</div>
                      <div class="registration_populate registration_reviewname" id="review-business-name">{{ formDataBussiness.firstName}}&nbsp;{{ formDataBussiness.lastName}} </div>
                      <div class="registration_populate registration_jobtitle" id="review-jobtitle">{{ formDataBussiness.jobTitle}} </div>
                      <div class="registration_populate registration_reviewemail" id="review-business-email">{{ formDataBussiness.emailId}}</div>
                      <div class="registration_populate registration_reviewPh" id="review-business-phone">{{ formDataBussiness.phoneNo}} Ext.{{ formDataBussiness.phoneExt}} </div>
                      <div class="registration_reviewlabel2 margin-T25 margin-B10">Address</div>
                      <div class="registration_populate registration_reviewaddress1" id="review-bussiness-address1-street">{{ formDataBussiness.line1}}&nbsp;{{ formDataBussiness.line2}} </div>
                      <div class="registration_populate registration_reviewaddress2" id="review-bussiness-city-country">{{ formDataBussiness.city}}, {{ formDataBussiness.country}}</div>
                      <div class="registration_populate registration_reviewaddress3" id="review-bussiness-city-post">{{ formDataBussiness.postalCode}}</div>
                  </div>
              </div>
              <div class="registration_reviewContainer">
                <div class="registration_reviewpaying padding-B20" *ngIf="formDataPaying">
                  <div class="registration_reviewparent">
                      <div class="registration_reviewlabel1">Payer Details</div>
                      <div class="registration_edit" id="registration_Payingedit" (click)="showPayingSection()" >Edit <i class="fa fa-pencil-square-o" aria-hidden="true"></i></div>
                      <div class="clearboth"></div>
                  </div>
                  <div class="registration_reviewlabel2 margin-B20">Contact Information</div>
                  <div class="registration_populate registration_reviewname" id="review-paying-name">{{formDataPaying.firstName}}&nbsp;{{formDataPaying.lastName}}</div>
                  <div class="registration_populate registration_reviewemail" id="review-paying-email">{{formDataPaying.email}}</div>
                  <div class="registration_populate registration_reviewPh" id="review-paying-phone">{{formDataPaying.phone}} ext. {{formDataPaying.phoneExt}} </div>
                  <div class="registration_reviewlabel2 margin-T25 margin-B20">Address</div>
                  <div class="registration_populate registration_reviewaddress1" id="review-paying-adrress1">{{formDataPaying.line1}}</div>
                  <div class="registration_populate registration_reviewaddress2" id="review-paying-adrress2">{{formDataPaying.line2}}</div>
                  <div class="registration_populate registration_reviewaddress3" id="review-paying-city-zip">{{formDataPaying.city}}, {{formDataPaying.country}} {{formDataPaying.postalCode}}</div>
              </div>
                  <div class="registration_reviewBilling padding-B20" *ngIf="formDataBilling">
                      <div class="registration_reviewparent">
                          <div class="registration_reviewlabel1">Invoicing Details</div>
                          <div class="registration_edit" id="registration_Billingedit" (click)="showBillingSection()" >Edit <i class="fa fa-pencil-square-o" aria-hidden="true"></i></div>
                          <div class="clearboth"></div>
                      </div>
                      <div class="registration_reviewlabel2 margin-B20">Contact Information</div>
                      <div class="registration_populate registration_reviewname" id="review-billing-name">{{formDataBilling.firstName}}&nbsp;{{formDataBilling.lastName}}</div>
                      <div class="registration_populate registration_reviewemail" id="review-billing-email">{{formDataBilling.email}}</div>
                      <div class="registration_populate registration_reviewPh" id="review-billing-phone">{{formDataBilling.phone}} ext.{{formDataBilling.phoneExt}} </div>
                      <div class="registration_reviewlabel2 margin-T25 margin-B20">Address</div>
                      <div class="registration_populate registration_reviewaddress1" id="review-billing-address1">{{formDataBilling.line1}}</div>
                      <div class="registration_populate registration_reviewaddress2" id="review-billing-address2">{{formDataBilling.line2}}</div>
                      <div class="registration_populate registration_reviewaddress3" id="review-billing-city-zip">{{formDataBilling.city}}, {{formDataBilling.country}} {{formDataBilling.postalCode}}</div>

                      <div class="registration_reviewlabel2 registration_reviewCheckbox margin-T25">
                                   
                      <label class="registration_checkboxLabel">Alternate email for statements</label>
                      <div class="clearboth"></div>
                     </div>
                      

                      <div class="registration_populate registration_reviewemail" id="review-billing-account-email">{{formDataBilling.acctStmtEmail}}</div>
                      
                  </div>
                 
              </div>
              <div class="registration_reviewShipping margin-B20">
                  <div class="registration_reviewparent">
                      <div class="registration_reviewlabel1">Delivery Location(s)</div>
                      <div class="registration_edit" id="registration_Shippingedit" (click)="showShippingSection()">Edit <i class="fa fa-pencil-square-o" aria-hidden="true"></i></div>
                      <div class="clearboth"></div>
                  </div>
                  <div class="registration_reviewlabel2"><span class="total_ship_location">{{ totalShippingCount }}</span> delivery locations</div>
              </div>                      
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding margin-T20 margin-B20 registration_button">
                  <div class="registration_back active" (click)="showShippingSection()">
                      <div class="global_blackarrow"></div>
                      <span>Back</span>
                      <div class="clearboth"></div>
                  </div>
                  <button class="registration_createAccount active" (click)="create_Account()"  type="submit">Create Account</button>
                  <div class="clearboth"></div>
              </div>

          </form>
      </section>                        
      <!-- added job bag codes -->
    
  </div>
 


</ng-container>
<app-reg-join-acc-exit-popup></app-reg-join-acc-exit-popup>