<div *ngIf="isLoading" class="loading">
    <div class="loading_txt">
        <div class="load_txt">Loading</div>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
    </div>
</div>
<!--OM product selection page starts here -->



<div class="main no-pad OM_doseInput" id="contact_request"
    *ngIf="productData && ((enablePage && enablePage.showAmendProductPage) || showAmendProductPage) && !showAmendDeliveryPage">
    <!--OM product selection page body starts here -->
    <form #productData1="ngForm">
        <div class="prodSelectBody_container" id="OM_prodSelect"
            *ngIf="productData && productData.orderSummary.orderedandShippedProducts.orderedProducts && productData.orderSummary.orderedandShippedProducts.orderedProducts.length > 0">
            <!-- add soldout class in below div -->
            <div class="OM_contentHeader pb-3">
                <div class="col-xs-12 col-md-6 no-pad cart_neworderheader">
                    <span class="amendOrderHeader">Amend your&nbsp;</span><span class="editseason">{{seasonValue | seasonspilt}}</span><span class="amendOrderHeader2">&nbsp;Order</span>
                </div>
        
                <!-- <div class="OM_helpText">
                        Need help?<a class="OM_viewText" href="/OM-UserGuide-PDF" target="_blank">View the how-to guide here<img class="OM_viewArrow" alt="Previous screen" src="/_ui/responsive/theme-lambda/cms/assets/images/arrow-right.svg"></a>
                    </div> -->
            </div>
            <div class="col-xs-12 col-md-12 no-pad OM_productContainer">
                <div class="col-xs-12 col-md-5 no-pad OM_productDetails" *ngFor="let item of productData.productList">
                    <div class="OM_productBox" [ngClass]="!item.sellable ? 'disable_product' : ''">
                        <div class="prodSelect_img">
                            <!-- <img alt="aQIV" src="../../../assets/images/aQIV-GB-10-Mockup.png" style="width: 100%;"
                                *ngIf="item.baseName === 'AQIV'">
                                <img alt="aQIV" src="../../../assets/images/QIVc-GB-10-Mockup-2022-23.png" style="width: 93%;"
                                *ngIf="item.baseName === 'QIVC'"> -->
                                <img alt="aTIV" src="../../../assets/images/Adjuvanted-Carton_Mockup_v.png" style="width: 100%;"
                                *ngIf="item.baseName === 'AQIV'">
                            <img alt="TIVc" src="../../../assets/images/Cell-based-Carton_Mockup_v.png" style="width: 100%;"
                                *ngIf="item.baseName === 'QIVC'">
                        </div>
                    </div>

                    <div class="OM_productTitle" [ngClass]="!item.sellable ? 'disable_product' : ''"><span
                            *ngIf="item.baseName === 'AQIV'">Adjuvanted Trivalent Influenza Vaccine (aTIV)</span> <span *ngIf="item.baseName === 'QIVC'">Cell-Based Trivalent
                            Influenza
                            Vaccine
                            (TIVc)</span></div>
                    <div class="OM_descripsionText" [ngClass]="!item.sellable ? 'disable_product' : ''"><span
                            *ngIf="item.baseName === 'AQIV'">For people aged 65 years and over</span>
                            <span *ngIf="item.baseName === 'QIVC'">For adults and children from 6 months of age</span></div>
                    <!-- only for preseason -->
                    <div class="OM_productCost" [ngClass]="!item.sellable ? 'disable_product' : ''">
                        <div>{{item.perPack}} Pack</div>
                        <div class="OM_verticlDivision"></div>
                        <div class="priceDetail">&#163;<span *ngIf="item.code==productData?.priceResponse?.materials[0]?.materialID">{{productData?.priceResponse?.materials[0]?.salePrice}}</span><span *ngIf="item.code==productData?.priceResponse?.materials[1]?.materialID">{{productData?.priceResponse?.materials[1]?.salePrice}}</span> per dose</div>
                    </div>
                    <!-- preseason ends -->
                </div>
                <!-- <div class="col-xs-12 col-md-4 no-pad OM_productDetails" id="qivc">
                <div class="OM_productBox"></div>
                <div class="OM_productTitle">Cell-Based Quadrivalent Influenza Vaccine (QIVc)</div>
                <div class="OM_productSubTitle">(surface antigen, inactivated) Seqirus</div>
                <div class="OM_descripsionText">For adults and children from 2 years of age</div>
                <div class="OM_productCost">
                    <div>10-pack</div>
                    <div class="OM_verticlDivision"></div>
                    <div class="priceDetail">&#163;<span>10.00</span> per dose</div>
                </div>
            </div> -->
            </div>
            <div class="clearboth"></div>


            <section class="col-xs-12 OM_productDoseSection">

                <div class="no-pad" style="display: flex;">
                    <div class="col-md-8 no-pad">
                        <div class="OM_productTableHeader">Amend Products</div>
                        <div class="OM_productTableSubHeader no-pad col-xs-10 mt-0">
                            <ng-container *ngIf="productData.orderSummary.status=='Order Pending Approval' || productData.orderSummary.status=='Amendment Pending Approval';else confirmcontent">
                                <p>On flu360, you can order  200 - {{maxproductwithcomma}} doses per product per location with {{productData.priceResponse.SOR | number}}% Sale or Return and {{productData.priceResponse.creditDays}}&nbsp;credit days.</p>

                                <p>To place an order outside of these volumes<span class="provisionalHelpText"> or explore
                                        alternate terms</span>, please contact our sales team on <a
                                        href="mailto:flu.salesuk@seqirus.com"
                                        class="showPopupclick removeEmailleave" href="mailto:flu.salesuk@seqirus.com" target="_blank">{{emailId}}</a> or <a
                                        href="tel:03450093804" class="showPopupclick removephoneleave">0345 009 3804.</a>
                                </p>

                            </ng-container>
                            <ng-template #confirmcontent>


                                <p>On flu360, you can add 20% additional doses per product to your original order. This will
                                    not impact your terms of &nbsp;{{productData.priceResponse.SOR | number}}% Sale or Return and {{productData.priceResponse.creditDays}}&nbsp;credit days.</p>
                                <p>To place an order outside of these volumes<span class="provisionalHelpText"> or explore
                                        alternate terms</span>, please contact our sales team on <a
                                        href="mailto:flu.salesuk@seqirus.com"
                                        class="showPopupclick removeEmailleave" href="mailto:flu.salesuk@seqirus.com" target="_blank">{{emailId}}</a> or <a
                                        href="tel:03450093804" class="showPopupclick removephoneleave">0345 009 3804.</a>
                                </p>
                            </ng-template>
                           
                        </div>

                        <div class="OM_productTableInst no-pad col-xs-7">Enter your new total doses below.</div>
                        <div class="clearboth"></div>
                    </div>
                    <div class="col-md-4 riskcontent">
                        <div class="OM_productTableHeader">Risk Free Ordering</div>
                        <div class="OM_productTableSubHeader">Should new influenza policy guidance be issued that no
                            longer recommends either CSL Seqirus flu vaccines in their respective age group(s), you can
                            cancel that respective part of your order.</div>
                    </div>
                </div>
                <!-- Table starts -->
                <div class="locationrowall col-md-12 locationrow60149134 no-pad">
                    <div class="tableContainer">
                        <div>
                            <div class="OM_productLocation col-xs-12" id="prodLocation1">

                                <div class="col-xs-12 col-md-5 OM_locParent">
                                    <div class="col-xs-12 OM_locationheader">Location Name</div>
                                    <div class="col-xs-12 col-md-6 OM_locationText pl-2 pr-0">
                                        {{productData.orderSummary.delLocation}}
                                        <!-- <div class="zipcodewrap"> GB HP18 9SA</div> -->
                                    </div>
                                </div>
                                <div class="amendproductbox" *ngFor="let productList of productData.orderSummary.orderedandShippedProducts.orderedProducts; let j=index">
                                    <div class="col-xs-12 allProd no-padding">
                                        <div class="col-xs-12 productheader">

                                            <div class="">
                                                <div class="OM_locationProdName" *ngIf="productList.baseProdName === 'aTIV 10 Pack'">aTIV 10-pack</div>
                                                <div class="OM_locationProdName" *ngIf="productList.baseProdName === 'TIVc 10 Pack'">TIVc 10-pack</div>
                                                <div class="OM_prodDes" *ngIf="productList.baseProdName === 'aTIV 10 Pack'">(65
                                                    years
                                                    &amp; above)</div>
                                                <div class="OM_prodDes" *ngIf="productList.baseProdName === 'TIVc 10 Pack'">(18 to 64 years in eligible groups)</div>
                                                <!-- year data without backet for provisional order -->
                                            </div>
                                            <!-- <div class="col-md-6">
                                <div class="OM_locationProdName">QIVc 10 Pack</div>
                                <div class="OM_prodDes">(6 months &amp; above)</div>
                            </div> -->

                                        </div>
                                        <div class="col-xs-12 prodincontainer">

                                            <div class="col-xs-12 aqivcontainer createproductsize">


                                                <div class="doseQuant aqivinputcont" style="padding-left:25px"
                                                    [ngClass]="productList.errorMsg ? 'has-error' : ''">
                                                    <input *ngIf="productData.orderSummary.status == 'Order Pending Approval' || productData.orderSummary.status == 'Amendment Pending Approval'" id="{{j}}" name="{{j}}" maxlength="6"
                                                        placeholder="0"
                                                        class="form-control aqiv_box dynDoseInput dynplacecolor"
                                                         type="text" value=""
                                                        autocomplete="no" #productValue
                                                        [ngModel]="productList.quantityInDoses"
                                                        (ngModelChange)="handleOnProdValueChange($event,j,'quantityInDoses')">
                                                      <input *ngIf="productData.orderSummary.status != 'Order Pending Approval' && productData.orderSummary.status != 'Amendment Pending Approval'" id="{{j}}" name="{{j}}" maxlength="6"
                                                        placeholder="0"
                                                        class="form-control aqiv_box dynDoseInput dynplacecolor"
                                                        type="text" value=""
                                                        autocomplete="no" #productValue
                                                        [ngModel]="productList.quantityInDoses"
                                                        (ngModelChange)="handleOnProdValueChange($event,j,'confirmedQty')">
                                                    <span
                                                        class="glyphicon form-control-feedback glyphicon-exclamation-sign"
                                                        *ngIf="productList.errorMsg"></span>
                                                    <div class="totaldose pt-0 pl-1">
                                                        Total Doses</div>
                                                </div>
                                                <div style="padding-left: 25px;" class="doseValidationText pb-2" *ngIf="!productList.errorMsg && productData.orderSummary.status != 'Order Pending Approval' && productData.orderSummary.status != 'Amendment Pending Approval' && productList.baseProdName == 'aTIV 10 Pack' && confirmwithzeroaqiv==false">You can increase up to {{aqivtotalValue | number}}</div>
                                                <div style="padding-left: 25px;" class="doseValidationText pb-2" *ngIf="!productList.errorMsg && productData.orderSummary.status != 'Order Pending Approval' && productData.orderSummary.status != 'Amendment Pending Approval' && productList.baseProdName == 'TIVc 10 Pack' && confirmwithzeroqivc==false">You can increase up to {{qivctotalValue | number}}</div>
                                                <div style="padding-left: 25px;" class="doseValidationText pb-2" *ngIf="!productList.errorMsg && productData.orderSummary.status != 'Order Pending Approval' && productData.orderSummary.status != 'Amendment Pending Approval'&& productList.baseProdName == 'aTIV 10 Pack' && confirmwithzeroaqiv==true">You can enter {{productData.amendOrderConstraintList.minLocProdQuantity}} - {{productData.amendOrderConstraintList.maxLocProdQuantity | number}} doses</div>

                                                <div style="padding-left: 25px;" class="doseValidationText pb-2" *ngIf="!productList.errorMsg && productData.orderSummary.status != 'Order Pending Approval' && productData.orderSummary.status != 'Amendment Pending Approval' && productList.baseProdName == 'TIVc 10 Pack' && confirmwithzeroqivc==true">You can enter {{productData.amendOrderConstraintList.minLocProdQuantity}} - {{productData.amendOrderConstraintList.maxLocProdQuantity | number}} doses</div>
                                                <label *ngIf="productList.errorMsg" id="{{j}}" style="padding-left:25px"
                                                    class="error help-block"
                                                    for="{{j}}">{{productList.errorMsg}}</label>
                                                   
                                            </div>
                                            <!-- <div class="col-xs-12 col-md-6 aqivcontainer createproductsize pl-0">
                                <div class="doseQuant aqivinputcont pl-3">
                                    <input id="cdose[0]"
                                        name="createOrderData[0].CreateOrderProductData[1].productQuantity"
                                        placeholder="0" class="form-control qivc_box dynDoseInput onlyQivc" type="text"
                                        value="" autocomplete="no"><input
                                        id="createOrderData0.CreateOrderProductData1.productCode"
                                        name="createOrderData[0].CreateOrderProductData[1].productCode" value="10054364"
                                        type="hidden">
                                    <div class="totaldose">
                                        Total Doses</div>

                                </div>

                            </div> -->
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Table Ends -->
                <button id="popid" class="OM_removedosesBtn" *ngIf="productData.orderSummary.status == 'Order Pending Approval'" data-toggle="modal" data-target="#cart_removemodal"
                data-backdrop="false">
                    Remove All Doses</button>
            </section>
            <div class="col-xs-12 OM_tableBottomBtn">
                <div class="col-xs-12 col-md-6 OM_totalCost pl-0">Total Cost: &nbsp;&nbsp;&nbsp;&#163;<span
                        class="OM_totalProductCost">{{productData.orderSummary.totalCost | number : '.2-2'}}</span></div>
                <div class="col-xs-12 col-md-6 OM_rightBtnParent pr-0">
                        <button class="OM_selectWeekBtn" [disabled]="disableDeliveryBtn"
                        (click)="handleOnDeliveryBtnClick()" >Amend Delivery Weeks</button>
                </div>
            </div>
            <div class="clearboth"></div>
        </div>
    </form>
    <!--OM product selection page body ends here -->

    <!-- external Popup starts -->
    <div class="OM_externalPop modal" data-backdrop="static" id="OM_externalPop">
        <div class="OM_externalPopContent">
            <div class="OM_externalPopHeader">
                <div class="OM_externalPopTitle">Are you sure you want to exit?</div>
                <div class="OM_externalPopClose">X</div>
                <div class="clearboth"></div>
            </div>
            <div class="OM_externalPopText">
                <p>You will no longer have access to your progress for this new order.</p>
            </div>
            <div class="OM_externalPopBtns">
                <button class="OM_externalPopBtn OM_externalPopBackBtn">Go Back To Order</button>
                <button class="OM_externalPopBtn OM_externalPopExitBtn">Exit &amp; Lose Progress</button>
            </div>
        </div>
    </div>
    <!-- external Popup ends -->

</div>

<!-- amend delivery week start here -->
<div class="editdelivery" *ngIf="showAmendDeliveryPage">
    <section class=" col-xs-12 OM_productDetailsSection">
        <div class="col-xs-6 cart_neworderheader">Amend your&nbsp;<span class="editseason">{{seasonValue | seasonspilt}}</span>&nbsp;Order</div>
        </section>

        <section id="Editdeliverpage" class="col-xs-12" style="display: block;" data-gtm-vis-recent-on-screen41137814_331="7747" data-gtm-vis-first-on-screen41137814_331="7747" data-gtm-vis-total-visible-time41137814_331="100" data-gtm-vis-has-fired41137814_331="1">
			<div class="deliverycontainer editDeliverySlots" id="editDeliveryslotJSPPage">

	
	<div class="deliverybox">
		<div>
			<!-- provisional changes -->
			<div class="orders_backText" (click)="handleOnBackToProductBtnClick()"><div class="global_blackarrow"></div><span>Back to Amend Products</span></div>
		</div>
		<div class="greyline"></div>
		<!-- the line code -->
		<div class="delivery_textContainer">
			<div class="deliveryheader">Amend Delivery Weeks</div>
			<div class="deliveytext">Let us know when you would like to receive your doses. Keep the following guidelines in mind when selecting your schedule:</div>
			<ul class="deliveytext">
				<li class="deliverylist">1&nbsp;delivery permitted in the first&nbsp;4
					weeks</li>
				<li class="deliverylist">100 - <span class="editdelMaxSlot">5,000</span>&nbsp;doses permitted per selected slot</li>
				<li class="deliverylist">Original delivery slots may be lost once you confirm this amendment</li>
			</ul>
			<div class="deliveytext margin-B30">We do our best to get your order to you on time, however planned deliveries are subject to change. We will confirm your schedule as delivery approaches.</div>
			<div class="atcap_box">
				<!-- capacity circle code -->
				<div class="capacity_circle"></div>
				<div class="capacity_text">Fully booked</div>
				<div class="middlespace"></div>
				<div class="Unavaible"></div>
				<div class="capacity_text">Unavailable</div>
			</div>
		</div>
		<div id="editDeliveryparent">
			<form #weekselectionform="ngForm" >
			
				<!-- location container start from here -->
				<!-- this code will run in loop to get multiple location -->
				<div class="deliveylocation">
						<div class="greyline"></div>
						<!-- the line code -->
						<!-- Edit location details -->
						<!-- Edit delivery weeks -->
					
							<div class="row">
								<div class="col-xs-1 deliverycol locationtext margin-B30">
									Location&nbsp;<span class="deliverylocno">{{editdeliverydata.deliverySlotResponseData.shippingLocations.length}}</span>
								</div>
								<div class="col-xs-1 deliverycol1 dashsymbol">|</div>
								<div class="col-xs-11  deliverycol2 locationdetail margin-B30 ">{{editdeliverydata.orderSummary.delLocation}}</div>
							</div>
							
							<!-- prepreseason changes -->

								<div class="row">
									<div class="seasonvalue">{{deliveryseason}}</div>

								</div>
							
							<div class="row">
								<div class="col-xs-12 no-padding">
								<input type="hidden" id="weeknumber" value="9">
									<table class="deliverytable">
										<tbody>
                                            <tr id="month">
											<td class="delivery_date deliverycol">Delivery Date</td>
											<td class="deliverycol1"></td>
                                            <ng-container
                                            *ngFor="let deliverydate of editdeliverydata.deliverySlotResponseData.shippingLocations[0].materials[0].deliverySlots;let d=index">
                                            <ng-container
                                                *ngIf="d!=(editdeliverydata.deliverySlotConstraintList.maxDeliverySlots)">
                                                <td class=" datetext">{{deliverydate.month}}</td>
                                            </ng-container>
                                            <ng-container
                                                *ngIf="d==(editdeliverydata.deliverySlotConstraintList.maxDeliverySlots)">
                                                <td class="gapbet"></td>
                                                <td class=" datetext">{{deliverydate.month}}</td>
                                            </ng-container>

                                        </ng-container>
											
											<td class="gapdose"></td>
											<td class="dosestext ">Doses</td>
											<td class=" dosestext">Doses</td>
											<td class="greentick"></td>
										</tr>
										<tr class="">
											<td class="deliveyweek deliverycol">(wk. commencing)</td>
											<td class="deliverycol1"></td>

                                           <ng-container
                                           *ngFor="let deliverydate of editdeliverydata.deliverySlotResponseData.shippingLocations[0].materials[0].deliverySlots;let d=index">
                                            <ng-container
                                                *ngIf="d!=editdeliverydata.deliverySlotConstraintList.maxDeliverySlots;else gap">
                                                <td class=" datetext">{{deliverydate.date}}</td>
                                            </ng-container>
                                            <ng-template #gap>
                                                <td class="gapbet"></td>
                                                <td class=" datetext">{{deliverydate.date}}</td>
                                            </ng-template>

                                        </ng-container> 
											<td class="gapdose"></td>
											<td class="dosestext ">Scheduled</td>
											<td class=" dosestext">Ordered</td>
											<td class="greentick"></td>
										</tr>
										<!-- aqiv week start -->

									         <!-- product week start -->
                                             <ng-container *ngFor="let product of editdeliverydata.deliverySlotResponseData.shippingLocations[0].materials;let k=index">
                                                <!-- aqiv or first product depend the the productlist sequence -->
                                                <ng-container *ngIf="product.materialID==editdeliverydata.productList[0].code">
                                                    <tr id="delivey_aQIV">
        
                                                        <ng-container
                                                            *ngIf="editdeliverydata.productList[0].baseProductName=='aQIV - 10 Pack';else ativ">
                                                            <td class="productheader deliverycol">aQIV</td>
                                                        </ng-container>
                                                        <ng-template #ativ>
                                                            <td class="productheader deliverycol">aTIV</td>
                                                        </ng-template>
        
        
                                                        <td class="deliverycol1"></td>
        
                                                        <ng-container
                                                            *ngFor="let productinput1 of product.deliverySlots;let j=index">
                                                            <ng-container
                                                                *ngIf="j < editdeliverydata.deliverySlotConstraintList.maxDeliverySlots;else product1gap">
                                                                <td class="deliverydate_input"
                                                                    [ngClass]="productinput1?.producterrorMsg ? 'has-error' : ''">
                                                                    <input type="text"
                                                                        [ngClass]="productinput1.available=='N'?'at_capacity':''"
                                                                        [disabled]="productinput1.available=='N' || productinput1?.disablerule==true"
                                                                        [(ngModel)]="productinput1.quantity"
                                                                        (ngModelChange)="amendhandleOndeliveryValueChange($event,j,k)"
                                                                        autocomplete="off"
                                                                        (keyup)="businessrule(j,productinput1.quantity,editdeliverydata.deliverySlotResponseData.shippingLocations[0].materials.length);handlenan($event)"
                                                                        class="delivery_data  aqivcount form-control dynvacinedoses"
                                                                        id="aqivfirst[0][{{j}}]"
                                                                        name="createOrderData[0].createOrderProductData[0].createOrderDeliverySlotData[{{j}}]].quantity"
                                                                        /><span
                                                                        class="glyphicon form-control-erroricon"
                                                                        [ngClass]="productinput1?.producterrorMsg ? 'glyphicon-exclamation-sign' : ''"></span>
                                                                </td>
                                                            </ng-container>
                                                            <ng-template #product1gap>
                                                                <td class="gapbet" *ngIf="j == editdeliverydata.deliverySlotConstraintList.maxDeliverySlots"></td>
                                                                <td class="deliverydate_input"
                                                                    [ngClass]="productinput1?.producterrorMsg ? 'has-error' : ''">
                                                                    <input type="text"
                                                                        [ngClass]="productinput1.available=='N'?'at_capacity':''"
                                                                        [disabled]="productinput1.available=='N'"
                                                                        autocomplete="off"
                                                                        (ngModelChange)="amendhandleOndeliveryValueChange($event,j,k)"
                                                                        [(ngModel)]="productinput1.quantity"
                                                                        class="delivery_data  aqivcount form-control dynvacinedoses"
                                                                        id="aqivfirst[0][{{j}}]"
                                                                        name="createOrderData[0].createOrderProductData[0].createOrderDeliverySlotData[{{j}}]].quantity"
                                                                        (keyup)="handlenan($event)"
                                                                         /><span
                                                                        class="glyphicon form-control-erroricon"
                                                                        [ngClass]="productinput1?.producterrorMsg ? 'glyphicon-exclamation-sign' : ''"></span>
                                                                </td>
                                                            </ng-template>
        
                                                        </ng-container>
        
                                                        <td class="gapdose"></td>
                                                        <td class="deliveryallocated_input dosesbox"
                                                            [ngClass]="product?.allocatederrorMsg ? 'has-error' : ''"><input
                                                                type="text" autocomplete="off"
                                                                class=" delivery_data aqiv_allocated form-control dynallacted"
                                                                id="aqivallocated[0]" [(ngModel)]="product.allocatedvalue"
                                                                name="deliveryweek.aqivallocated[0]" readonly
                                                                value="" /><span class="glyphicon form-control-erroricon"
                                                                [ngClass]="product?.allocatederrorMsg ? 'glyphicon-exclamation-sign' : ''"></span>
                                                        </td>
                                                        <td class="deliverydate_input dosesbox "><input type="text"
                                                                autocomplete="off"
                                                                class=" delivery_data aqivtotal form-control "
                                                                id="aqivtotal[0]" [value]="numberWithCommas(product.qty)" readonly /></td>
                                                        <td class="greentick" [ngClass]="product?.matchtick? 'matchtick':''">
                                                        </td>
                                                    </tr>
                                                    <tr class="aqivyear">
                                                        <ng-container
                                                *ngIf="editdeliverydata?.productList[0].baseProductName=='aTIV - 10 Pack';else aqivpack">
                                                <td class="middletext deliverycol "><span class="tenpack">10-pack</span>
                                                    <br><span class="delivery_ProductDesc">(65 years & above)</span></td>
                                                </ng-container>
                                                    <ng-template #aqivpack>
                                                        <td class="middletext deliverycol "><span class="tenpack">10-pack</span>
                                                            <br><span class="delivery_ProductDesc">(65 years & above)</span></td>
                                                        
                                                    </ng-template>
                                                        <td class="deliverycol1"></td>
                                                        <td colspan="17" class="errorarea">
                                                            <ng-container *ngIf="editdeliverydata.deliverySlotResponseData.shippingLocations[0].materials.length!=2">
                                                                <!-- error handle if only aqiv -->
                                                                <div class="totalcheck" *ngIf="editdeliverydata.deliverySlotResponseData.shippingLocations[0]?.locationsumerror">
                                                                    Scheduled doses must equal ordered doses</div>
                                                                    <div class="allocatedcheck">
                                                                    <ng-container
                                                                    *ngIf="editdeliverydata.deliverySlotResponseData.shippingLocations[0]?.locationlevelerror">{{editdeliverydata.deliverySlotResponseData.shippingLocations[0]?.locationmsg}}</ng-container></div>
                                                                <!-- <div class="allocatedcheck">Please enter a volume to the nearest unit of 10</div> -->
                                                            </ng-container>
        
                                                        </td>
        
        
                                                    </tr>
        
                                                </ng-container>
                                                <ng-container *ngIf="product.materialID==editdeliverydata.productList[1].code">
                                                    <tr id="delivey_QIVc">
        
                                                        <ng-container
                                                            *ngIf="editdeliverydata.productList[1].baseProductName=='QIVc - 10 Pack';else tivc">
                                                            <td class="productheader deliverycol">QIVc</td>
                                                        </ng-container>
                                                        <ng-template #tivc>
                                                            <td class="productheader deliverycol">TIVc</td>
                                                        </ng-template>
                                                        <td class="deliverycol1"></td>
                                                        <ng-container
                                                            *ngFor="let productinput2 of product.deliverySlots;let j=index">
                                                            <ng-container
                                                                *ngIf="j<editdeliverydata.deliverySlotConstraintList.maxDeliverySlots;else product2gap">
                                                                <td class="deliverydate_input"
                                                                    [ngClass]="productinput2.producterrorMsg ? 'has-error' : ''">
                                                                    <input type="text"
                                                                        [ngClass]="productinput2.available=='N'?'at_capacity':''"
                                                                        [disabled]="productinput2.available=='N' || productinput2.disablerule==true"
                                                                        autocomplete="off"
                                                                        class="delivery_data qivccount  form-control dynvacinedoses"
                                                                        id="qivcfirst[0][{{j}}]"
                                                                        [(ngModel)]="productinput2.quantity"
                                                                        (keyup)="businessrule(j,productinput2.quantity,editdeliverydata.deliverySlotResponseData.shippingLocations[0].materials.length);handlenan($event)"
                                                                        (ngModelChange)="amendhandleOndeliveryValueChange($event,j,k)"
                                                                        name="createOrderData[0].createOrderProductData[1].createOrderDeliverySlotData[{{j}}].quantity"
                                                                         /><span
                                                                        class="glyphicon form-control-erroricon"
                                                                        [ngClass]="productinput2.producterrorMsg ? 'glyphicon-exclamation-sign' : ''"></span>
                                                                </td>
                                                            </ng-container>
                                                            <ng-template #product2gap>
                                                                <td class="gapbet" *ngIf="j==editdeliverydata.deliverySlotConstraintList.maxDeliverySlots"></td>
                                                                <td class="deliverydate_input"
                                                                    [ngClass]="productinput2.producterrorMsg ? 'has-error' : ''">
                                                                    <input type="text"
                                                                        [ngClass]="productinput2.available=='N'?'at_capacity':''"
                                                                        [disabled]="productinput2.available=='N'"
                                                                        autocomplete="off"
                                                                        (ngModelChange)="amendhandleOndeliveryValueChange($event,j,k)"
                                                                        class="delivery_data qivccount form-control dynvacinedoses"
                                                                        id="qivcfirst[0][{{j}}]"
                                                                        [(ngModel)]="productinput2.quantity"
                                                                        name="createOrderData[0].createOrderProductData[1].createOrderDeliverySlotData[{{j}}].quantity"
                                                                         (keyup)="handlenan($event)"
                                                                        /><span
                                                                        class="glyphicon form-control-erroricon"
                                                                        [ngClass]="productinput2.producterrorMsg ? 'glyphicon-exclamation-sign' : ''"></span>
                                                                </td>
                                                            </ng-template>
        
                                                        </ng-container>
        
                                                        <td class="gapdose"></td>
                                                        <td class="deliveryallocated_input dosesbox"
                                                            [ngClass]="product.allocatederrorMsg ? 'has-error' : ''"><input
                                                                type="text" autocomplete="off"
                                                                class="  delivery_data qivcallocated form-control dynallacted"
                                                                id="qivcallocated[0]" value=""
                                                                [(ngModel)]="product.allocatedvalue"
                                                                name="deliveryweek.qivcallocated[0]" readonly /><span
                                                                class="glyphicon form-control-erroricon"
                                                                [ngClass]="product.allocatederrorMsg ? 'glyphicon-exclamation-sign' : ''"></span>
                                                        </td>
                                                        <td class="deliverydate_input dosesbox "><input type="text"
                                                                autocomplete="off" class=" delivery_data qivctotal form-control"
                                                                id="qivctotal[0]" [value]="numberWithCommas(product.qty)" readonly /></td>
                                                        <td class="greentick" [ngClass]="product.matchtick? 'matchtick':''">
                                                        </td>
                                                    </tr>
                                                    <tr class="qivcyear">
                                                        <ng-container
                                                    *ngIf="editdeliverydata?.productList[1].baseProductName=='QIVc - 10 Pack';else tivcpack">
                                                    <td class="middletext deliverycol"><span class="tenpack">10-pack</span><br><span class="delivery_ProductDesc">(18 to 64 years in eligible groups)</span></td>
                                                </ng-container>
                                                <ng-template #tivcpack>
                                                    <td class="middletext deliverycol"><span class="tenpack">10-pack</span><br><span class="delivery_ProductDesc">(18 to 64 years in eligible groups)</span></td>
                                                </ng-template>
        
                                                        <td class="deliverycol1"></td>
                                                        <td colspan="17" class="errorarea">
                                                            <div class="totalcheck" *ngIf="editdeliverydata.deliverySlotResponseData.shippingLocations[0]?.locationsumerror">Scheduled
                                                                doses must equal ordered doses</div>
                                                            <div class="allocatedcheck">
                                                                <ng-container
                                                                    *ngIf="editdeliverydata.deliverySlotResponseData.shippingLocations[0]?.locationlevelerror">{{editdeliverydata.deliverySlotResponseData.shippingLocations[0]?.locationmsg}}</ng-container>
                                                            </div>
                                                        </td>
                                                    </tr>
        
                                                </ng-container>
        
        
        
                                            </ng-container>

											
												

												</tbody></table>
								</div>
							</div>
						</div>
				<div>

</div></form></div>
	</div>
	<!-- bottom section Review buttom -->
	<div class="deliveryReview_but margin-B30">
		<div class="bot_section">
			<button class="select_review" id="editReviewOrder" [ngClass]="disabledelivery==false?'reviewbtn_disable':''" (click)="handleOnReviewOrderBtnClick()">Review Amended Order</button>
		</div>
	</div>
</div>
			</section>

</div>



<div class="OM_removePop modal" data-backdrop="static" id="cart_removemodal"  role="dialog" tabindex="-1"
aria-labelledby="forgotModalLabel" aria-hidden="true">
    <div class="OM_externalPopContent">
        <div class="OM_externalPopHeader">
            <div class="OM_externalPopTitle">Are you sure you want to remove all doses from this order?</div>
            <div class="OM_removePopClose" data-dismiss="modal">X</div>
            <div class="clearboth"></div>
        </div>
        <div class="OM_externalPopText">
            <p>You may lose your volumes, terms and delivery schedule, and will need to place a new order should you wish to receive deliveries to this location.</p>
        </div>
        <div class="OM_externalPopBtns">
            <button class="OM_externalPopBtn OM_removePopRemoveBtn" (click)="handleOnRemoveDoses()">Remove All Doses</button>
            <button class="OM_externalPopBtn OM_removePopExitBtn" data-dismiss="modal">Go Back</button>
        </div>
    </div>
</div>

<div id="OM_exitPopup" class="modal fade in show" role="dialog" data-keyboard="false" tabindex="-1"  style="display:block;padding-right: 10px;" *ngIf="showExitPopUp">
    <div class="modal-dialog">

        <div class="modal-content">
            <div class="modal-body">
                <h4 class="modal-title OMexit_popHeader col-xs-9">Are you sure you want to exit?</h4>
                <div class="col-xs-3">
                    <span class="OMexit_popClose" data-dismiss="modal" (click)="handleOnCloseButton()">×</span>
                </div>
                <div class="exitcontent">Please note you will lose your progress for this new order. No volumes, terms or delivery schedules will be saved.</div>
                <div class="clearboth"></div>
                <div class="OM_exitPopBtnParent">
                    <button class="OM_popExitBtn" (click)="handleOnExitBtnClick()">Exit & Lose Progress</button>
                    <button class="OM_popBackBtn" data-dismiss="modal" (click)="handleOnCloseButton()">Go Back To Order</button>
                </div>
            </div>
          
        </div>
    </div>
</div>

<div id="apifailurepopup" class="modal fade in show" role="dialog" data-backdrop="static" data-keyboard="false" tabindex="-1" *ngIf="amendapifailure">
				
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <div class="col-xs-12 returnopenheader">
                    <h4 class="modal-title return_popHeader col-xs-9 no-padding">Important Information</h4>
                    <div class="col-xs-3 no-padding">
                                            <!-- <span class="OMexit_popClose" data-dismiss="modal">&times;</span>  -->
                    </div> 
                </div>
                <p class="returnpopupsub col-xs-12 margin-T20">Page currently under maintenance</p>
                <div class="clearboth"></div>
                
                <div class="OM_exitPopBtnParent">
                    <button class="returnbtn"><a class="exitSkipBtn" [routerLink]="['/my-account']">Return to dashboard</a></button>
                </div>
                <div class="clearboth"></div>
                <div class="returnpopupjobcode">GBR-FLU360-23-0009 May 2024</div>
            </div>
        </div>
    </div>
</div>
