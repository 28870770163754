import { Component, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'app-robots',
  templateUrl: './robots.component.html',
  styleUrl: './robots.component.scss'
})
export class RobotsComponent {
  hosturl:any;
  constructor( ) {

  }
  ngOnInit(){
    this.hosturl =window.location.hostname;

  }
  

}
