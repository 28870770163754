import { ChangeDetectorRef, Component } from '@angular/core';
import { UserInfoService } from '../../spartacus/services/user-info.service';

@Component({
  selector: 'app-sch-pay-top-banner',
  templateUrl: './sch-pay-top-banner.component.html',
  styleUrl: './sch-pay-top-banner.component.scss'
})
export class SchPayTopBannerComponent {
  schPayCount: any;
  scheduledPayments!: [];

  constructor(public userInfoService:UserInfoService,private cd:ChangeDetectorRef) {
  }

  ngOnInit(){
     this.userInfoService.getScheduledPayments().subscribe((resp:any) => {
      this.scheduledPayments=resp;
      this.schPayCount = this.scheduledPayments.length;
      this.cd.detectChanges();
    }) 
  }

}
