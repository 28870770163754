import { ChangeDetectorRef, Component } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { CommonApiService } from '../../spartacus/services/common-api.service';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-custom-payment-info',
  templateUrl: './custom-payment-info.component.html',
  styleUrl: './custom-payment-info.component.scss'
})
export class CustomPaymentInfoComponent {
  paymentDetails: any;
  constructor(private occEndpointsService:OccEndpointsService,
    private commonService:CommonApiService,private cd:ChangeDetectorRef,
    private userInfoService: UserInfoService){

  }
  ngOnInit(){
      this.getPaymentDetails();

  }
getPaymentDetails(){ 
  this.userInfoService.getsavedcard().subscribe(
    (data) => {
      if (data) {
       
        this.paymentDetails=data;
       
        this.cd.detectChanges();
      }
     
    },
    (error) => {
      console.log('error');
     
    }
  );
  
    /* let endpoint = this.occEndpointsService.buildUrl(`/users/${this.commonService.userId}/my-profile/savedCards`);
    let payload = {
      url: endpoint,
    }
    this.paymentDetails$=this.commonService.getAPICall(payload); */
  }
}
