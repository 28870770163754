import { ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserInfoService } from '../../spartacus/services/user-info.service';

declare var window:any;
@Component({
  selector: 'app-updatepass',
  templateUrl: './updatepass.component.html',
  styleUrl: './updatepass.component.scss'
})
export class UpdatepassComponent {
  constructor(
    private activatedRoute: ActivatedRoute,
    private cd: ChangeDetectorRef,private userInfoService: UserInfoService,private router: Router) { }


  newpasswordvalue: string = "";
  comfirmpasswordvalue: string = "";
  submit: boolean = false;
  errorshownewpass: boolean = false;
  errorshowconfimr: boolean = false;
  tokenvalue: any = null;
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params) {
        this.tokenvalue = params
      }
    });
  }

  newpassword(event: Event) {
    const element = (event.target as HTMLInputElement)
    this.newpasswordvalue = element.value
    if (this.newpasswordvalue.length <= 5) {
      this.errorshownewpass = true;

    } else {
      this.errorshownewpass = false;

    }
    this.cd.detectChanges();
  }
  confirminput(event: Event) {
    const element1 = (event.target as HTMLInputElement)
    this.comfirmpasswordvalue = element1.value
    if (this.newpasswordvalue === this.comfirmpasswordvalue) {
      this.errorshowconfimr = false;
    }
    this.cd.detectChanges();
  }
  cancel(){
  //  window.location.reload();
  window.location.href=this.router.url

  }
  update() {
    const password = document.getElementById('password') as HTMLInputElement
    this.newpasswordvalue = password.value
    const confirmpass = document.getElementById('updatePwd.checkPwd') as HTMLInputElement
    this.comfirmpasswordvalue = confirmpass.value

    if (this.newpasswordvalue?.length >= 5) {
      if (this.newpasswordvalue === this.comfirmpasswordvalue) {
        this.submit = true;
        this.errorshowconfimr = false;
      }
      else {
        this.submit = false;
        this.errorshowconfimr = true;
      }
    } else {
      this.submit = false;
      this.errorshownewpass = true;
    }
    this.cd.detectChanges();
    if (this.submit) {
      const resetpayload = {
        pwd: this.newpasswordvalue,
        checkPwd: this.comfirmpasswordvalue,
        token: this.tokenvalue?.token,
      }
      this.userInfoService.resetasmpassword(resetpayload).subscribe(res=>{
        this.router.navigate(['/login/pw/change/success']);
      },
      error=>{
        this.router.navigate(['/login/pw/change/failure']);
      }
        );
    }


  }
}
