import { BaseSiteService, CmsBannerComponent } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { ContentService } from '../../spartacus/services/content.service';
import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { StartOrderService } from '../../custom-start-order/start-order.service';
import { Router } from '@angular/router';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { ReturnsUkService } from '../returns-uk.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { ApiResponse, LocationData, ProductData } from '../location-data.model';
import { MatDialog } from '@angular/material/dialog';
import { ReturnsExitPopupComponent } from '../returns-exit-popup/returns-exit-popup.component';
import { Overlay, NoopScrollStrategy } from '@angular/cdk/overlay';


@Component({
  selector: 'app-returns',
  templateUrl: './returns.component.html',
  styleUrl: './returns.component.scss'
})
export class ReturnsComponent {
  showNoReturn: boolean = false;
  
  bannerNode: Observable<any> = this.component.data$;
  constructor(private overlay: Overlay,public component: CmsComponentData<CmsBannerComponent>,public dialog: MatDialog, private cdr: ChangeDetectorRef, private router: Router, private startOrderService: StartOrderService, private userInfoService: UserInfoService, public contentService:ContentService, private baseSiteService:BaseSiteService,private returnsUkService:ReturnsUkService) {
   
  }

 
  displayedColumns: string[] = ['locname', 'address', 'state','checked',];
  private subscription: Subscription | undefined;
  dataSource = new MatTableDataSource<LocationData>([]);
  returnsProductData: any;
  searchValue: string = '';
  
  selectedAddress: string | undefined;
  inputValueaQiv: string = '';
  inputValueQivc: string = '';
  formattedValue: string | null = '';
  formattedValueQivc: string | null = '';
  errMsg1:boolean = false;
  errMsg2:boolean = false;
  errMsg1qivc:boolean = false;
  errMsg2qivc:boolean = false;
  aqivMaxAllowedQty: any;
  qivcMaxAllowedQty: any;
  checkboxChecked: boolean = false; // Property to hold the checkbox state
  inputErrorMessage: string = ''; // Property to hold the input field error message
  checkboxErrorMessage: string = ''; 
  imgcheckboxChecked: boolean = false;
  imgcheckboxErrorMessage: string = ''; 
   selection = new SelectionModel<LocationData>(true, []);
  selectedRows: LocationData[] = [];
  selectedCount = 0;
  isVisible = false;
  noReturnlanding = true;
  noReturnsetails = false;
  noReturnThanks = false;
  showloader: boolean = false;
  defaultseason:string=""
  ngOnInit() {
    this.dataSource.filterPredicate = (data: LocationData, filter: string) => {
      const dataStr = Object.values(data).join(' ').toLowerCase();
      return dataStr.includes(filter.trim().toLowerCase());
    };
    this.showNoreturnlanding();
  }
 
  ngAfterViewInit(): void {
    this.returnsUkService.getSeasons().subscribe((res:any)=>{
      this.defaultseason=res.defaultReturnSeason
    this.returnsUkService.getLocations(this.defaultseason).pipe(take(1)).subscribe((response: any) => {
      const data = response.data; // Access the data property
      if (Array.isArray(data)) {
        const formattedData = data.map((item: any) => ({
          ...item,
          checked: item.checked ? item.checked.toString() : 'false' // Ensure checked is a string
        }));
        this.dataSource.data = data;
        this.updateSelectedRows();
      } else {
        console.error('Expected an array but got:', data);
      }
    }, error => {
      console.error('Error fetching data:', error); // Debugging: Log any errors
    });
  });

    this.returnsUkService.currentVisibility.subscribe(visibility => {
      this.isVisible = visibility === 'B';
      this.cdr.detectChanges();
    });
  }

  
  
  showNoreturnlanding(){
    this.noReturnlanding = true;
    this.noReturnsetails = false;
    this.noReturnThanks = false;
  }

  shownoReturnsetails(){
    this.noReturnlanding = false;
    this.noReturnsetails = true;
    this.noReturnThanks = false;
  }

  shownoReturnThanks(){
    this.noReturnlanding = false;
    this.noReturnsetails = false;
    this.noReturnThanks = true;
    //To hide job bag code in returns thankyou page
    const bodyslot=document?.querySelector('.Section8-returnsLandingPage') as HTMLElement;
    bodyslot?.setAttribute("style","display:none");
    //To retuns banner in returns thankyou page
    const bodyslot1=document?.querySelector('.Section3-returnsLandingPage') as HTMLElement;
    bodyslot1?.setAttribute("style","display:none");
    // To reduce space between top header alone in returns thankyou page  
    const bodyslot2=document?.querySelector('.UkCreateReturn') as HTMLElement;
    bodyslot2?.setAttribute("style","margin-top:20px");
    //To reduce padding in pi banner in retun thankyou page
    const bodyslot3=document?.querySelector('.PibannerUk') as HTMLElement;
    bodyslot3?.setAttribute("style","padding: 10px 40px;");              
  }
  
  onRowClick(row: LocationData) {    
    this.selection.toggle(row);
    this.updateSelectedRows();
    
  }

  updateSelectedRows() {
    this.selectedRows = this.selection.selected;
    this.selectedCount = this.selectedRows.length; // Update the selected count
  }

  selectAll() {
    this.selection.clear();
    this.dataSource.data.forEach(row => {
      if (row.checked === 'true') {
        this.selection.select(row);
      }
    });
    this.updateSelectedRows();
  }

  clearAll() {
    this.selection.clear();
    this.dataSource.data.forEach(row => {
      if (row.checked === 'true') {
        row.checked = 'true';
      }
    });
    this.updateSelectedRows();
  }

  toggleAllRows(event: any) {
    if (event.checked) {
      this.selectAll();
    } else {
      this.clearAll();
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.updateSelectedRows();
  }

  navigateToReturns(event: Event, row: any) {
    event.stopPropagation(); // Stop the event propagation
    // Set the locname in the shared service
  
    // Change the visibility state to hide this component and show the other component
    this.returnsUkService.changeVisibility('A');
    this.returnsUkService.changeLocname(row.locname);
    this.cdr.detectChanges();
  }
   
  submitSelectedRows() {
    this.shownoReturnsetails();
    this.cdr.detectChanges();
  }

  onCheckboxChange() {
    if (this.checkboxChecked) {
      this.checkboxErrorMessage = ''; // Clear the error message when checkbox is checked
    }
    else{
      this.checkboxErrorMessage = 'Please confirm to proceed';
    }
  }

  validateNoreturnForm() {
    let isValid = true;
    // Validate checkbox
    if (!this.checkboxChecked) {
      this.checkboxErrorMessage = 'Please confirm to proceed';
      isValid = false;
    } else {
      this.checkboxErrorMessage = '';
    }
    if(isValid){
      this.showloader = true;
      const selectedLocIDs = this.selection.selected
      .filter(row => row.checked === 'true')
      .map(row => row.locID);
    if (selectedLocIDs.length > 0) {
      this.returnsUkService.noReturns('', selectedLocIDs,this.defaultseason).subscribe(response => {
        this.shownoReturnThanks();
        this.showloader = false;
        this.cdr.detectChanges();
      }, error => {
        console.error('Error posting data:', error); // Handle the error as needed
        this.shownoReturnThanks();
        this.showloader = false;
        this.cdr.detectChanges();
      });
    } else {
      console.warn('No rows selected'); // Handle the case where no rows are selected
    }
    }
  }

  goToreturn(): void {
    window.location.reload();
  }
  openExistpopup(): void {

    const dialogRef = this.dialog.open(ReturnsExitPopupComponent, {
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      width: '700px',
      disableClose: true,
      hasBackdrop: true,
      autoFocus: true,
      restoreFocus: true,
      backdropClass: 'custom-backdrop'
    });
    
  }
}
