<div *ngIf="errorshowconfimr" class="alert alert-danger alert-dismissable getAccAlert customalert">
    <button class="close closeAccAlert" aria-hidden="true" data-dismiss="alert" type="button">×</button>
    Please correct the errors below.</div>


<div class="col-xs-12 docnumberbox" id="Asmupdatepassword">
    <div class="col-xs-12 account-section-header no-border">Reset Password</div>
    <div class="col-md-6 accountform-content">
    <form>
      
            <div class="form-group">
                <div class="form-group formcont">
                    <label class="control-label " for="password">
                        New Password</label>
                    <input id="password" name="pwd" class="form-control password-strength" type="password" value=""
                        autocomplete="off" data-gtm-form-interact-field-id="0" (keyup)="newpassword($event)" [ngClass]="errorshownewpass?'asmredborder':''">
                        <span class="glyphicon form-control-erroricon" [ngClass]="errorshownewpass ? 'glyphicon-exclamation-sign' : ''"></span>
                    <div class="help-block" id="password_minchar" *ngIf="errorshownewpass">Minimum length is 6 characters</div>
                </div>
            </div>
            <div class="form-group">
                <div class="form-group formcont">
                    <label class="control-label " for="updatePwd.checkPwd">
                        Confirm Password</label>
                    <input id="updatePwd.checkPwd" name="checkPwd" class="form-control" type="password" value="" autocomplete="off" [ngClass]="errorshowconfimr?'asmredborder':''" (keyup)="confirminput($event)">
                        <span class="glyphicon form-control-erroricon" [ngClass]="errorshowconfimr ? 'glyphicon-exclamation-sign' : ''"></span>
                        <div class="help-block" id="password_minchar" *ngIf="errorshowconfimr">Password and password confirmation do not match</div>

                </div>
            </div>
            <div class="row login-form-action">
                <div class="col-sm-6">
                    <button type="button"  class="updatebtn asmbtn" (click)="update()">
                        Update</button>
                </div>
                <div class="col-sm-6">
                    <button type="button" (click)="cancel()" class="cancelbtn asmbtn">
                        Cancel</button>
                        
                </div>
            </div>
       
    </form>
</div>
</div>

