import { BaseSiteService, CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { combineLatest, map, Observable, Subscription } from 'rxjs';
import { ContentService } from '../../spartacus/services/content.service';
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
@Component({
 selector: 'app-about-vaccine',
 templateUrl: './about-vaccine.component.html',
 styleUrls: ['./about-vaccine.component.scss']
})
export class AboutVaccineComponent implements OnInit, AfterViewInit, OnDestroy {
 // Mobile and desktop logic
 isMobile: boolean = false; // Flag to check mobile view
 currentSite: string="";
 leftBannerNode1: Observable<any> = this.bannerComponent.data$;
 activeIndex: number = 0;
 transitioning: boolean = false;
 mergedObject: any[] = [];
 totalSlides: number = 0;
 leftBannerNode: any[] = [];
 private subscription: Subscription = new Subscription();
 constructor(
   private cmsService: CmsService,
   protected config: OccConfig,
   public bannerComponent: CmsComponentData<CmsBannerComponent>,
   public accessService: ContentService,
   public cd: ChangeDetectorRef,
   private baseSiteService:BaseSiteService
 ) {}
 
 getImage(image: any): string {
  return this.config.backend?.occ?.baseUrl + image.url;
}
 ngAfterViewInit(): void {
   this.updateBarLine();
 }
 ngOnInit(): void {
  this.baseSiteService.getActive().subscribe(site => {
    this.currentSite = site;
    
  });

   // Mobile View Detection (Example for viewport width < 768px)
   this.isMobile = window.innerWidth < 768;
   this.subscription.add(
     this.leftBannerNode1.subscribe(res => {
       
       const tilesList = res.aboutInflucarouselOptions.split(" ");
       tilesList.forEach((el: any) => {
         this.accessService.InflucarouselOptions(el).subscribe(res => {
           this.mergedObject = [...this.mergedObject, res];
           this.totalSlides = this.mergedObject.length;
           this.cd.detectChanges();
         
         });
       });
     })
   );
   this.leftBannerNode1.subscribe(res => {
    this.carouselimages(res);
   });
 }
 
 carouselimages(res: any) {
  const tilesList = res.aboutInflucarouselOptions?.split(" ");
  const observables = tilesList?.map((el: any) => this.accessService.opeffeaturedResources1(el));
 
  combineLatest(observables).pipe(
    map((results:any) => results.flat())
  ).subscribe((mergedResults: any) => {
    this.leftBannerNode = mergedResults;
    this.cd.detectChanges();
 
  });
}
 
 ngOnDestroy(): void {
   this.subscription.unsubscribe();
 }

 // Set active index for the carousel on mobile and desktop
 setActiveIndex(index: number): void {
   if (index !== this.activeIndex) {
     const fromIndex = this.activeIndex;
     this.activeIndex = index;
     const direction = index > fromIndex ? 'next' : 'prev';
     if (!this.isMobile) {
       this.updateBarLine();
       this.triggerSlideAnimation(fromIndex, index, direction);
     }
   }
 }
 // Handle dot click for mobile view
 onDotClick(index: number): void {
   this.setActiveIndex(index);
 }
 // Handle next slide (desktop and mobile)
 gotonext(): void {
   if (this.transitioning) return;
   this.transitioning = true;
   const fromIndex = this.activeIndex;
   this.activeIndex = (this.activeIndex < this.mergedObject.length - 1) ? this.activeIndex + 1 : 0;
   if (!this.isMobile) {
     this.updateBarLine();
     this.triggerSlideAnimation(fromIndex, this.activeIndex, 'next');
   }
 }
 // Handle previous slide (desktop and mobile)
 gotoprevious(): void {
   if (this.transitioning) return;
   this.transitioning = true;
   const fromIndex = this.activeIndex;
   this.activeIndex = (this.activeIndex > 0) ? this.activeIndex - 1 : this.mergedObject.length - 1;
   if (!this.isMobile) {
     this.updateBarLine();
     this.triggerSlideAnimation(fromIndex, this.activeIndex, 'prev');
   }
 }
 // Slide transition animation for desktop
 triggerSlideAnimation(fromIndex: number, toIndex: number, direction: 'next' | 'prev'): void {
   const fromSlide = document.querySelectorAll('.carousel-item')[fromIndex] as HTMLElement;
   const toSlide = document.querySelectorAll('.carousel-item')[toIndex] as HTMLElement;
   if (fromSlide && toSlide) {
     toSlide.classList.add(`carousel-item-${direction}-enter`, `carousel-item-${direction}`);
     fromSlide.classList.add(`carousel-item-${direction}-exit`, `carousel-item-${direction}`);
     setTimeout(() => {
       toSlide.classList.add('active');
       toSlide.classList.remove(`carousel-item-${direction}-enter`, `carousel-item-${direction}`);
       fromSlide.classList.remove('active', `carousel-item-${direction}-exit`, `carousel-item-${direction}`);
       this.transitioning = false;
     }, 0); // Adjust the timeout duration as needed
   }
 }
 
 
 updateBarLine(){
  const barLine = document.querySelector('.carousel-bar-line .active-bar') as HTMLElement;
  let position = (this.activeIndex / this.totalSlides) * 100;
 
  if (this.activeIndex === 0) {
    position = -5;
  } else if (this.activeIndex === 1) {
    position = 33.33;
  } else if (this.activeIndex === 2) {
    position = 75;
  }
 
  barLine.style.left = `${position}%`;
}
onBarLineClick(index: number): void {
  const fromIndex = this.activeIndex;
  this.activeIndex = index;
 
  const direction = index > fromIndex ? 'next' : 'prev';
  if (!this.isMobile) {
    this.updateBarLine();
    this.triggerSlideAnimation(fromIndex, index, direction);
  }
  // Ensure content is updated even if the clicked bar is already active
  this.cd.detectChanges();
}
}
//  //bar-line-handler