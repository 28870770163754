<div class="prod-tabs">
  <div class="container">
    <nav class="prod-tabs__tab-nav">
      <ng-container *ngFor="let item of mergedObject1; let i = index">
        <button class="nav-link" [ngClass]="{ active: toggleArray[i] }" [id]="'nav-tab-' + i" data-toggle="tab"
          [attr.data-bs-target]="'#nav-content-' + i" type="button" role="tab" [attr.aria-controls]="'nav-content-' + i"
          [attr.aria-selected]="toggleArray[i]" (click)="toggleNav(i)">
          <span *ngIf="item.Heading" class="nav-tab-content">
            {{ item.Heading }}
            <div class="prod-tabs__arrow">
              <img role="presentation" alt="Next screen" src="../../../assets/images/arrow-right.svg" />
            </div>
          </span>
        </button>
      </ng-container>
    </nav>
    <div class="prod-tabs__tab-content" id="nav-tabContent">
      <ng-container *ngFor="let item of mergedObject1; let i = index">
        <!-- First Component -->
        <div class="prod-tabs__tab tab-pane fade d-none d-lg-block"
          [ngClass]="{ show: toggleArray[i], active: toggleArray[i] }" role="tabpanel"
          [attr.aria-labelledby]="'nav-tab-' + i" [id]="'nav-content-' + i" *ngIf="i === 0 && item.firstCOMP">
          <div class="prod-tabs__body">
            <!-- <div class="image" *ngIf="item?.media">
              <img class="resource-image" alt="Reduced risk of hospitalisation" *ngIf="item?.media"
                [src]="getImage(item?.media)">
            </div> -->
            <h3 *ngIf="item.headLine1" [innerHTML]="item.headLine1"></h3>
            <div class="row first-row">
              <div class="col-md-7" *ngIf="item.firstCOMP?.textPageContent1">
                <p class="aqiv-first" [innerHTML]="item.firstCOMP?.textPageContent1"></p>
              </div>
              <div class="col-md-5 aqiv-image" *ngIf="item.firstCOMP?.Image">
                <img class="resource-image" alt="Reduced risk of hospitalisation"
                [src]="getImage1(item?.firstCOMP?.Image)">
              </div>
              <div class="col-md-5 qivc-image" *ngIf="item.firstCOMP?.media">
                <img class="resource-image" alt="Reduced risk of hospitalisation"
                [src]="getImage(item?.firstCOMP?.media)">
              </div>
            </div>
            <p class="aqiv-second-none" [innerHTML]="item.firstCOMP?.headLine1"></p>
            <div class="row second-row">
              <div class="col-md-5" *ngIf="item.firstCOMP?.Image">
                <img class="resource-image" alt="Reduced risk of hospitalisation"
                    [src]="getImage1(item?.firstCOMP?.Image)">
              </div>
              <div class="col-md-7" *ngIf="item.firstCOMP?.textPageContent1">
                <p class="aqiv-first" [innerHTML]="item.firstCOMP?.textPageContent2"></p>
                <ng-container *ngIf="!item.firstCOMP?.urlLink">
                  <a class="summaryofproductuk" (click)="openModal()">{{item.firstCOMP?.linkText}}</a>
                <span class="linkbtton productextra"><i class="fa fa-external-link" aria-hidden="true"></i></span>
              
                </ng-container>
              </div>
              <ng-container *ngIf="item.firstCOMP?.urlLink">
                <p class="M20" [innerHTML]="item.firstCOMP?.urlLink"></p>
                <a class="summaryofproductuk" (click)="openModal()">{{item.firstCOMP?.linkText}}</a>
                <span class="linkbtton productextra"><i class="fa fa-external-link" aria-hidden="true"></i></span>
              </ng-container>
            </div>
            <!-- <p class="aqiv-first" [innerHTML]="item.firstCOMP?.textPageContent2"></p> -->
            <!-- <p [innerHTML]="item.firstCOMP?.textPageContent3"></p>
            <div class="col-xs-12 col-md-5 no-left-pad tabMidContent" *ngIf="i === 0">
              <img class="resource-vaccine-image" *ngIf="item.firstCOMP?.media" [src]="getImage(item.firstCOMP?.media)">
                           <p class="summaryofproductuk" [innerHTML]="item.firstCOMP?.linkText" a href="item.linkurl"></p> 
            </div> -->
          </div>
        </div>
        <!-- Second Component -->
        <div class="prod-tabs__tab tab-pane fade d-none d-lg-block"
          [ngClass]="{ show: toggleArray[i], active: toggleArray[i] }" role="tabpanel"
          [attr.aria-labelledby]="'nav-tab-' + i" [id]="'nav-content-' + i" *ngIf="i === 1 && item.secondCOMP">
          <div class="prod-tabs__body">
            <p [innerHTML]="item.secondCOMP?.textPageContent1" class="aqivtextPageContent1"></p>
            <p class="aqiv-second text" [innerHTML]="item.secondCOMP?.headLine2"></p>
            <p [innerHTML]="item.secondCOMP?.textPageContent1" class="qivctextPageContent1"></p>
            <p class="aqiv-second green-heading" [innerHTML]="item.secondCOMP?.headLine3"></p>
            <p [innerHTML]="item.secondCOMP?.textPageContent2"></p>
            <div class="row">
              <div *ngIf="i === 1">
                <div class="col-xs-12 col-md-5 no-left-pad">
                  <img class="resource-image" alt="Reduced risk of hospitalisation" *ngIf="item.secondCOMP?.media"
                    [src]="getImage(item.secondCOMP?.media)">
                </div>
                <div class="col-xs-12 col-md-7 no-right-pad">
                  <p class="blueText" [innerHTML]="item.secondCOMP?.headLine4"></p>
                  <p class="sub-text" [innerHTML]="item.secondCOMP?.textPageContent3"></p>
                  <!-- <ul class="M20">
                    <li>Over 30 million doses of adjuvanted flu vaccines delivered in the UK to date.<sup>17</sup></li>
                    <li>Over 80% of patients aged 65yrs+ received a flu vaccine in England for the 2021/22
                      season.<sup>9</sup></li>
                  </ul> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Third Component -->
        <div class="prod-tabs__tab tab-pane prod-aqiv-third fade d-none d-lg-block"
          [ngClass]="{ show: toggleArray[i], active: toggleArray[i] }" role="tabpanel"
          [attr.aria-labelledby]="'nav-tab-' + i" [id]="'nav-content-' + i" *ngIf="i === 2 && item.thirdCOMP">
          <div class="prod-tabs__body">
            <p class="aqiv-second" [innerHTML]="item.thirdCOMP?.headLine2"></p>
            <p [innerHTML]="item.thirdCOMP?.Pagetext1"></p>

            <img *ngIf="i === 2" src="../../../assets/images/aQIV-Immunosenesence-graph.png" alt="Portal map">
            <p [innerHTML]="item.thirdCOMP?.Pagetext2"></p>
            <p [innerHTML]="item.thirdCOMP?.Pagetext3"></p>
            <p [innerHTML]="item.thirdCOMP?.Pagetext4"></p>
            <p class="aqiv-third-italic" [innerHTML]="item.thirdCOMP?.Pagesubtext1"></p>
            <p class="aqiv-second" [innerHTML]="item.thirdCOMP?.headLine3"></p>
            <p class="aqiv-second-block" [innerHTML]="item.thirdCOMP?.subheadline1"></p>
            <div class="col-xs-12 col-md-4 tab3_icons"> <img alt="Immunosenescence"
                src="/assets/images/aQIV-Immunosenescence.png">
              <p class="prod_subHeader">Immunosenescence</p>
              <p class="coltext">(declining immune function in the elderly).<sup class="supportsuper">24,25</sup></p>
            </div>
            <div class="col-xs-12 col-md-4 tab3_icons"> <img alt="Antigenic drift"
                src="../../../app/../assets/images/aQIV-Antigenic-drift.png">
              <p class="prod_subHeader">Antigenic drift</p>
              <p class="coltext">(natural mutations in circulating flu strains).<sup class="supportsuper">22,26</sup>
              </p>
            </div>
            <div class="col-xs-12 col-md-4 tab3_icons"> <img alt="Egg adaptation"
                src="../../../app/../assets/images/aQIV-Egg-adaptation.png">
              <p class="prod_subHeader">Egg adaptation</p>
              <p class="coltext">(mutations that may occur during egg-based vaccine manufacturing).<sup
                  class="supportsuper">22,25,27</sup></p>
            </div>
            <p class="aqiv-sub-thrd" [innerHTML]="item.thirdCOMP?.Pagesubtext2"></p>
          </div>
        </div>
        <!-- Fourth Component -->
        <div class="prod-tabs__tab tab-pane fade d-none d-lg-block"
          [ngClass]="{ show: toggleArray[i], active: toggleArray[i] }" role="tabpanel"
          [attr.aria-labelledby]="'nav-tab-' + i" [id]="'nav-content-' + i" *ngIf="i === 3 && 1!=item.fourthCOMP">
          <div class="prod-tabs__body">
            <div [ngClass]="{ active: toggleArray[i] }" *ngFor="let subItem of subTileData">
              <div class="row">
                <div class="col-md-2" *ngIf="subItem?.media">
                  <img class="resource-image" alt="Reduced risk of hospitalisation" [src]="getImage(subItem.media)">
                </div>
                <div class="col-md-10">
                  <p class="aqiv-second" [innerHTML]="subItem.headline1"></p>
                </div>
              </div>
              <p class="aqiv-fourth-content" [innerHTML]="subItem.PAgeParacontent1"></p>
              <p class="aqiv-fourth-small" [innerHTML]="subItem?.PAGEsubtext"></p>
              <p  class="aqiv-second-subheader" [innerHTML]="subItem?.PAgesubheadline2"></p>
              <p class="aqiv-fourth-content-list" [innerHTML]="subItem.PAgeParacontent2"></p>
              <p  class="aqiv-second-subheader" [innerHTML]="subItem?.PAgesubheadline3"></p>
              <p class="aqiv-fourth-content" [innerHTML]="subItem.PAgeParacontent3"></p>
              <p class="aqiv-fourth-content-italic" [innerHTML]="subItem.PAgeParacontent4"></p>
            </div>
          </div>
        </div>
        <!--qivc third component-->
        <div class="prod-tabs__tab tab-pane prod-qivc-third fade d-none d-lg-block "
          [ngClass]="{ show: toggleArray[i], active: toggleArray[i] }" role="tabpanel"
          [attr.aria-labelledby]="'nav-tab-' + i" [id]="'nav-content-' + i" *ngIf="i === 2 && item.thirdCOMP">
          <div class="prod-tabs__body">
            <!-- <div [ngClass]="{ active: toggleArray[i] }" *ngFor="let subItem of subTileData1">
              <div class="col-md-4 col-xs-12 featured-resources" *ngIf="subItem?.media">
                <img class="resource-image" alt="Reduced risk of hospitalisation" [src]="getImage(subItem.media)">
                <p class="aqiv-second" [innerHTML]="subItem.headline1"></p>
                <p [innerHTML]="subItem.PAgesubtext1"></p>
                <p class="aqiv-fourth-content" [innerHTML]="subItem.PAgeParacontent1"></p>
                <p class="aqiv-fourth-small" [innerHTML]="subItem.PAGEsubtext"></p>
                <p class="aqiv-fourth-content" [innerHTML]="subItem.PAgeParacontent2"></p>
                <p class="aqiv-fourth-content" [innerHTML]="subItem.PAgeParacontent3"></p>
                <p class="aqiv-fourth-content-italic" [innerHTML]="subItem.PAgeParacontent4"></p>
              </div>
            </div> -->
            <p class="greenText" [innerHTML]="item.thirdCOMP.headLine2"></p>
            <div class="row">
              <div [ngClass]="{ active: toggleArray[i] }" class="col-md-4 col-xs-12 featured-resources"
                *ngFor="let subItem of subTileData1">
                <div *ngIf="subItem?.media">
                  <img class="resource-image" alt="Reduced risk of hospitalisation" [src]="getImage(subItem.media)">
                  <p class="aqiv-second-subheader" [innerHTML]="subItem.header1"></p>
                  <p class="aqiv-second-subtext" [innerHTML]="subItem.PAgesubtext1"></p>
                  <p class="aqiv-fourth-content" [innerHTML]="subItem.PAgeParacontent1"></p>
                  <p class="aqiv-fourth-small" [innerHTML]="subItem.PAGEsubtext"></p>
                  <p class="aqiv-fourth-content" [innerHTML]="subItem.PAgeParacontent2"></p>
                  <p class="aqiv-fourth-content" [innerHTML]="subItem.PAgeParacontent3"></p>
                  <p class="aqiv-fourth-content-italic" [innerHTML]="subItem.PAgeParacontent4"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- For pop-up for first tab link -->
          <div class="commonleaving_abandonedPop modal  fade" role="dialog" data-backdrop="static" data-keyboard="false"
            id="commonleaving_abandonedPop" tabindex="-1">
            <div class="modal-body knowledgeHub_abandonedPopContent">
              <div class="knowledgeHub_abandonedPopHeader">
                <div class="knowledgeHub_abandonedPopTitle">{{item.firstCOMP?.header2}}</div>
                <div class="knowledgeHub_abandonedPopClose" (click)="closeModal()">X</div>
                <div class="clearboth"></div>
              </div>
              <div class="knowledgeHub_abandonedPopText">
                <p [innerHTML]="item.firstCOMP?.TextPage2"></p>
                <p [innerHTML]="item.firstCOMP?.TextPage3"></p>
              </div>
              <div class="knowledgeHub_abandonedPopBtns">
                <button class="knowledgeHub_abandonedPopBtn commonleave_popContBtn">
                  <a (click)="openExtLink(item.firstCOMP?.linkurl)">Continue</a>
                </button>
                <!-- <button class="knowledgeHub_abandonedPopBtn iframe_popContBtn">Continue</button> -->
                <button class="knowledgeHub_abandonedPopBtn knowledgeHub_popCanceltBtn"
                  (click)="closeModal()">Cancel</button>
              </div>
              <div class="knowledgedocnumberUk">{{item.firstCOMP?.PageBottomcontent1}}</div>
            </div>
          </div>
      </ng-container>
    </div>
  </div>
</div>