<div *ngIf="showJoinAccExitPopUp" class="registration_helppop modal show" data-backdrop="static" style="display:block;padding-right: 10px;" id="registration_helppop">
    <div class="registration_helpContent">
        <div class="registration_helpheader">
            <div class="registration_shippingUploadtext1">{{joinAccPopupData?.headline}}</div>
            <div class="registration_helpclose" (click)="handleOnCloseButton()" data-dismiss="modal">X</div>
            <div class="clearboth"></div>
        </div>
        <!-- uk with soldtoacc -->
         <ng-container *ngIf="userInfo.webEnabled && currentSite == 'seqirusb2b-uk'">
        <div class="registration_shippingUploadtext2"><p>{{joinAccPopupData?.message1}}</p></div>
        <div class="registration_popcont">
                            <button (click)="handleOnExitBtnClick()" class="registration_helpLeave ukregisterleave">{{joinAccPopupData?.button}}</button>
                            <button *ngIf="joinaccountpage" data-dismiss="modal" (click)="handleOnCloseButton()" class="registration_helpContinue ukregistercontinue">{{joinAccPopupData?.label2}}</button>
                            <button *ngIf="!joinaccountpage" data-dismiss="modal" (click)="handleOnCloseButton()" class="registration_helpContinue ukregistercontinue">Continue Create Account</button>

        </div>
        </ng-container>
        <!-- uk without soldtoacc -->
        <ng-container *ngIf="!userInfo.webEnabled && currentSite == 'seqirusb2b-uk'">
        <div class="registration_shippingUploadtext2"><p>{{joinAccPopupData?.message3}}</p></div>
         <div class="registration_popcont">
                            <button (click)="handleOnExitBtnClick()" class="registration_helpLeave ukregisterleave">{{joinAccPopupData?.content}}</button>
                            <button *ngIf="joinaccountpage" data-dismiss="modal" (click)="handleOnCloseButton()" class="registration_helpContinue ukregistercontinue">{{joinAccPopupData?.label1}}</button>
       
        <button *ngIf="!joinaccountpage" data-dismiss="modal" (click)="handleOnCloseButton()" class="registration_helpContinue ukregistercontinue">Continue Create Account</button>
        </div>
        </ng-container>
        <!-- us popup -->
        <!-- <ng-container *ngIf="currentSite == 'seqirusb2b-flu360'">
        <div class="registration_shippingUploadtext2"><p>{{joinAccPopupData?.message2}}</p></div>
	     <div class="registration_popcont">
		                    <button (click)="handleOnExitBtnClick()" class="registration_helpLeave">{{joinAccPopupData?.content}}</button>
		                    <button data-dismiss="modal" (click)="handleOnCloseButton()" class="registration_helpContinue">{{joinAccPopupData?.label1}}</button>
		</div>
        </ng-container> -->

    </div>

</div>
