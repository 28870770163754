<div *ngIf="materialscomponent | async as item" class="returns_materials_container">
    <div class="row materials_row">
        <span class="materials_topheader">{{item.headLine1}}</span>
    </div>
    <div class="row materials_row">
        <div class="col-lg-6 materials_text_row">
            <div class="returns_Icon_Class">
                <img src="../../../assets/images/returns_thq_star.svg" alt="star-icon" />
                <span [innerHTML]="item.headLine2" class="materials_Headline"></span>
            </div>
            <span [innerHTML]="item.subText1" class="materials_List_Text"></span>
        </div>
        <div class="col-lg-6 materials_img_row">
            <div class="laptop_returns_image_big_container">
                <img [attr.alt]="item?.image?.altText" [src]="getImage(item?.image)" class="laptop_returns_image">
                <span class="returns_material_shadow_class"></span>
            </div>
            <div class="laptop_returns_image_small_container">
                <img [attr.alt]="item?.SeqReturnPdf?.altText" [src]="getImage(item?.SeqReturnPdf)"
                    class="laptop_returns_image_small">
            </div>
        </div>
    </div>

    <div class="materials_row materials_Second_Row">
        <div class="col-lg-6 rotate_Img_Row">
            <!-- <div class="laptop_returns_image_big_container"> -->
            <img [attr.alt]="item?.media?.altText" [src]="getDesktopImage(item?.media)" class="rotate_img_returns1">
            <!-- </div> -->
            <!-- <div class="laptop_Returns_Rotate_Image_Container"> -->
            <img [attr.alt]="item?.SeqReturnMedia?.altText" [src]="getImage(item?.SeqReturnMedia)"
                class="rotate_img_returns">
            <!-- </div> -->
        </div>
        <div class="col-lg-6 materials_text_row">
            <div class="returns_Icon_Class">
                <img src="../../../assets/images/returns_folder_icon.svg" alt="star-icon" />
                <span [innerHTML]="item.headLine3" class="materials_Headline"></span>
            </div>
            <span [innerHTML]="item.subText2" class="materials_List_Text"></span>
        </div>
    </div>
</div>