import { ChangeDetectorRef, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { ContentService } from '../../spartacus/services/content.service';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { Observable } from 'rxjs';
import { StartOrderService } from '../../custom-start-order/start-order.service';
import { ReturnsUkService } from '../../returns-uk/returns-uk.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-custom-returns-thankyouvideo',
  templateUrl: './custom-returns-thankyouvideo.component.html',
  styleUrl: './custom-returns-thankyouvideo.component.scss'
})
export class CustomReturnsThankyouvideoComponent {
  retunsPopupData : any;
  pageLabel : string ="";
  isMuted: boolean = true;
  constructor(public component: CmsComponentData<CmsBannerComponent>, protected config: OccConfig, public accessService: ContentService, public cd: ChangeDetectorRef, private ukservice: ReturnsUkService,private cmsService:CmsService ,private router: Router, private cdr: ChangeDetectorRef){
    this.cmsService.getCurrentPage().subscribe((data)=>{
      this.pageLabel = data.pageId?data.pageId:"";
    })
  }
  // returnsthqvideo : Observable<any> = this.component.data$;
  returnsthqvideo : Observable<any> = this.cmsService.getComponentData('ReturnsThankYouFirstComponent');
  getVideo(video: any): string {
    return this.config.backend?.occ?.baseUrl + video.desktop.url;
  }
  getPosterImage(image: any): string{
    return this.config.backend?.occ?.baseUrl + image.url;
  }
  // goToReturnPage()
  // {
  //   const url = (this.router.url).split('?')[0].split('#')[0];
  //       this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  //       this.router.onSameUrlNavigation = 'reload';
  //       this.router.navigate([url.substring(url.lastIndexOf('/')+1)]).then(()=>{
  //       this.cdr.detectChanges();
 
  //       });
  // }
  goToReturnPage(){
    window.location.reload();
  }
  ngAfterViewInit(){
    this.cmsService.getComponentData('ReturnsThankYouConfirmOrderPopupComponent').subscribe((data)=>{
      this.retunsPopupData = data;
      console.log(this.retunsPopupData);
      this.cd.detectChanges();
    });
    document.getElementById("uk_Pi_Banner")?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
    const videotag =document.getElementById('video-element') as HTMLVideoElement;
    const volumeIcon = document.getElementById('volume-off_icon') as HTMLElement;
    videotag.addEventListener('volumechange', () => {
      if(!videotag.muted){
        volumeIcon.style.display = 'none';
      }
     
   });
  }
  // if(this.pageLabel === 'returnsLandingPage') {
  // openThankYouPopup() {
  // }
  //   document.body.style.overflowY = 'auto';
  //   document.getElementById('register_Thankyou_Pop')!.style.display = 'block';
  // }
  // closeThankyouPopup() {
  //   document.body.style.overflowY = 'auto';
  //   document.getElementById('register_Thankyou_Pop')!.style.display = 'none';
  // }
  onClickRegisterd(){
    this.ukservice.registerReturn().subscribe(res=>{

    });
  }
  ngOnInit(): void {
    setTimeout(()=>{
      document.getElementById("uk_Pi_Banner")?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    },500)
  }
  toggleMute() {
    const video = document.getElementById('video-element') as HTMLVideoElement;
    const volumeIcon = document.getElementById('volume-off_icon');
    this.isMuted = !video.muted;
    video.muted = !video.muted;
    if (volumeIcon) {
      volumeIcon.style.display = 'none'; // Hide the volume-off icon
    }
  }
}