<ng-container *ngIf="currentsite=='seqirusb2b-flu360'">
<div class="col-md-11 col-sm-12 col-xs-12  myprofile_flex_cdc myprofile_flex_items padding-R5" *ngIf="!toggleEdit">
    <div class="myprofile_content_cdc myprofile_flex ">
        <div class="blkfirst col-xs-12">
            <div class="col-md-12 white-background-profile marBottom40" style="padding-bottom: 0px;">
                <div class="col-md-12 no-pad"></div>
                <div *ngIf="profiledata  as item" class="profile-details col-xs-8 myprofile_cdccontent myprofilecdcboxus">
                    <div class="col-xs-12 myprofile_tophybrisheader Org_header">My Profile</div>
                    <div class="col-xs-12 mypro_labels">Name</div>
                    <div class="col-xs-12 mypro_data" id="profile_name">{{item.firstName +' ' +item?.lastName}}</div>
                    <div class="clearfix1"></div>
                    <div class="col-xs-12 mypro_labels">Job Title</div>
                    <div class="col-xs-12 mypro_data" id="profile_job">{{item?.jobTitle}}</div>
                    <div class="clearfix1"></div>

                    <div class="col-xs-12 mypro_labels">Contact Email</div>
                    <div class="col-xs-12 mypro_data" id="profile_email">{{item?.email}}</div>
                    <div class="clearfix1"></div>

                    <div class="col-xs-12 mypro_labels">Phone Number</div>
                    <div class="profileDisplayInline mypro_data" id="profile_phone" [innerHTML]="item.phone"></div>
                    <div class="clearfix1"></div>
                </div>
                <div class="col-xs-4" style="padding:0" (click)="edit()">
                    <div *ngIf="!isAsAgent"  class="myprofile_edit edit-profile profile_edit_section" id="myprofile_cdceditbtn">Edit<img
                            src="assets/images/edit.png" width="20" alt="Edit">
                    </div>
                </div>
            </div>

        </div>
        <div class="clearfix1"></div>
    </div>
</div>
</ng-container>
<ng-container *ngIf="currentsite=='seqirusb2b-uk' && (!isAsAgent || (isSearchByProfile && isAsAgent))">
    <div class="myprofileuk_content_cdc myprofile_flex myprofile_account col-xs-12 col-sm-12"[ngClass]="currentsite=='seqirusb2b-uk'?'myprofileUk':''" *ngIf="!toggleEdit">
        <div class="myprofile_cdccontent profile-details no-padding" *ngIf="profiledata  as item">
<div class="account_flexRow1 col-xs-12 col-sm-12">
    <div class="account-content col-xs-12 col-sm-4 no-left-padding">
        <div class="myprofile_hybrisheader">Name</div>
        <div class="myprofile_hybrisheader_txt_sub" id="profile_name">{{item.firstName +' ' +item?.lastName}}</div>
    </div>
    <div class="account-content col-xs-12 col-sm-6">
        <div class="myprofile_hybrisheader">Contact Email/Username</div>
        <div class="myprofile_hybrisheader_txt_sub" id="profile_email">{{item?.email}}</div>
    </div>

    
    <div class="account-content col-xs-12 col-sm-2 no-right-padding">
        <div *ngIf="!isAsAgent"  class="myprofile_edit edit-profile profile_edit_section" id="myprofile_cdceditbtn" (click)="edit()">Edit<img
            src="assets/images/edit.png" width="20" alt="Edit">
    </div>
        </div>
</div>
<div class="account_flexRow2 col-xs-12 col-sm-12">
    <div class="account-content col-xs-12 col-sm-4 no-left-padding">
        <div class="myprofile_hybrisheader">Job Title</div>
        <div class="myprofile_hybrisheader_txt_sub" id="profile_job">{{item?.jobTitle}}</div>
    </div>
    <div class="account-content col-xs-12 col-sm-4">
        <div class="myprofile_hybrisheader">Phone Number</div>
        <div class="myprofile_hybrisheader_txt_sub phonetextcontainer" id="profile_phone" [innerHTML]="item.phone"></div>
        <div class="myprofile_hybrisheader_txt_sub phoneextcontainer">&nbsp;Ext. <span id="profile_extn"></span></div>
    </div>
</div>
</div>
</div>
</ng-container>