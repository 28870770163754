import { Component, ElementRef, NgZone } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BaseSiteService, CmsBannerComponent, LanguageService, OccConfig, WindowRef } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable, catchError, distinctUntilChanged, take, tap, throwError } from 'rxjs';
import { GigyaService } from '../../spartacus/services/gigya';
import { ContentService } from '../../spartacus/services/content.service';
import { CdcConfig, CdcJsService } from '@spartacus/cdc/root';
import { GigyaRaasComponentData } from '@spartacus/cdc/core';
import { UserInfoService } from '../../spartacus/services/user-info.service';

@Component({
  selector: 'app-homepage-banner',
  templateUrl: './homepage-banner.component.html',
  styleUrl: './homepage-banner.component.scss'
})
export class HomepageBannerComponent {
  // myForm: FormGroup;
  baseSite: any;
  protected renderScreenSet = true;
  language$: Observable<string> | undefined;
  jsError$: Observable<boolean> | undefined;
  jsLoaded$: Observable<boolean> | undefined;
  currentSite:any;


  constructor(public component: CmsComponentData<CmsBannerComponent>,
    private sv:ContentService,
    private gigya:GigyaService,
    private cdcJsService:CdcJsService,
    protected baseSiteService: BaseSiteService,
    protected config: OccConfig,
    private userInfoService:UserInfoService,private el:ElementRef
    ) {
  }
  bannerNode: Observable<any> = this.component.data$;
  userLoggedIn: boolean = false;
  restorevalue:any;
  productpage:any;
  ngOnInit(): void {


    //const body=<HTMLElement>document.querySelector("body");
   this.productpage=<HTMLElement>document.querySelector('.AllProductsPageTemplate');
    
    this.bannerNode.subscribe((res:any)=>{}
      
    )
    this.userInfoService.userLoggedIn$.subscribe(res=>{
     
      this.userLoggedIn = res;
      // if(this.userLoggedIn == true){
      //   this.renderer.setStyle(document.querySelector('.SideContent'),'display','block');
      // }
    })

    this.baseSiteService.getActive().subscribe(site => {
      this.currentSite = site;
     
    });
  }
  getImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.desktop.url;
  }
  getId(item:any){
    return item.uid =='seqirus-overview-hero-component' ? 'overView_loginleftsection':'home_loginleftsection'
  }

 // AllProductsPageTemplate

  
}
