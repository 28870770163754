<section class="col-xs-12 col-md-12 mt-5" id="thanksave" style="display: block;"
  *ngIf="enablePage.showAmendThankYouPage && data">
  <div class="thankorder_box">
    <span  class="thankorder_head" [innerHtml]="data.headLine"></span><span  class="thankorder_head">{{seasonValue | seasonspilt}} season</span>
    <div class="thankordersave mt-4" [innerHtml]="data.content"></div>

  <a class="my-order-anchor showPopupclick" [routerLink]="['/orders/my-orders']" [queryParams]= "{'season': seasonValue}" (click)="handleOnOrdersClick()">
    <div class="viewbutton">View Orders</div>
  </a>
</div>
</section>