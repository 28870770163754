import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicpageComponent } from './dynamicpage/dynamicpage.component';
import { CmsConfig, ConfigModule } from '@spartacus/core';



@NgModule({
  declarations: [
    DynamicpageComponent
  ],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents:{
        SeqirusDynamicPageComponent:{
            component: DynamicpageComponent
        }
      }
    }as CmsConfig)
  ]
})
export class DynamiccontentModule { }
