
  <div class="clinical-flucelvax-first">
    <div class="breadcrumbs--page-nav hidden-xs hidden-sm" *ngIf="!userLoggedIn">
        <div class="container clinical-flucelvax-breadcrumbs">
            <p><a href="/">Home</a><span> &gt; </span><a href="/products">Products</a><span> &gt; </span><strong>Clinical
                Data</strong>
            </p>
        </div>
    </div>
 
    <div class="product_efficacy-box1" *ngIf="fourthnode | async as item">
        <!-- <div class="container"> -->
        <div class="product_efficacy-header">
            {{item.headLine}}
        </div>
        <div class="product_efficacy-text" [innerHTML]="item.h2Content"></div>
        <!-- </div> -->
    </div>
 
 
    <div class="product_efficacy-box2" *ngIf="fourthnode | async as item">
   
        <div class="product_efficacy-header2">{{item.subHeadLine}}</div>
        <div class="product_efficacy-tabContainer" id="product_efficacy-tabContainer1">
            <div class="panel with-nav-tabs panel-primary">
                <div class="panel-heading">
                <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" animationDuration="0ms"
                        class="clinical-flucelvax-tabs">
                        <div *ngFor="let item2 of mergedObject1; let i= index">
                            <mat-tab label="{{ item2.headline }}">
                                <div class="wrapper">
                                    <div class="panel-body-text col-xs-12" [innerHTML]="item2.subHeadLine"></div>
                                    <div class="panel-body-text1 col-xs-12" [innerHTML]="item2.h2content"></div>
                                    <div class="panel-body-studyDetails col-xs-12">
                                    <div class="panel_body-indicator1">
                                        <span class="product_square-icon"></span>
                                        <span class="product_square-text" [innerHTML]="item2.subPoints"></span></div>
                                    <div class="panel_body-indicator2">
                                        <span class="product_square-icon"></span>
                                        <span class="product_square-text">{{item2.h3content}}</span></div>
                                    <div class="panel_details-anchor" id="panel_details-anchor1">
                                        <div class="panel_details-button"
                                             [innerHTML]="item2.t1HeadLine"
                                             (click) = toggleStudy(i)>
                                        </div>
                                    </div>
                                </div>
                                    <div class="panel_body-Image col-xs-12">
                                        <div class="simple-banner banner__component--responsive d-md-block d-lg-block d-none d-sm-none">
                                            <img class="js-responsive-image graph" [src]="getImage(item2.media)" [attr.alt]="item2.media?.altText" [attr.title]="item2.media?.altText">
                                        </div>
                                        <div class="simple-banner-mobile banner__component--responsive-mobile d-none d-sm-block d-md-none d-lg-none d-xl-none">
                                       
                                            <img class="js-responsive-image" [src]="getMobileImage(item2.media)" [attr.alt]="item2.media?.altText" [attr.title]="item2.media?.altText">
                                        </div>
                                       
                                        <div class="panel_body-disclaimer col-xs-12" [innerHTML]="item2.paragraphcontent">
                                        </div>
                                    </div>
                                    <div class="panel_body-bottom col-xs-12">
                                        <div class="panel_body-bottomLeft" [innerHTML]="item2.largeparagraphcontent"></div>
                                        <div class="panel_body-bottomDivider"></div>
                                        <div class="panel_body-bottomRight" [innerHTML]="item2.bottomContent"></div>
                                    </div>
                                </div>
                                <div class="pop-body" [ngClass]="study[i] == true ? 'active' : ' '">
                                    <div class="pop body-tab" [ngClass]="study[i] == true ? 'active' : ' '">
                                        <div class="knowledgeHub_abandonedPopClose" (click) = toggleStudy(i)><span>X</span></div>
                                        <!-- <span> <img class="arrow-up" alt="arrow" src="../../../app/../assets/images/Rectangle_arrow.png" width="19" /> </span> -->
                                        <div class="knowledgeHub_abandonedPopText panel_body-modalContent1" [innerHTML]="item2.content"></div>
                                    </div>
                                </div>
                            </mat-tab>
                        </div>
                    </mat-tab-group>
                </div>
            </div>
        </div>
    </div>  
</div>