import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Renderer2,
} from '@angular/core';
import { UserInfoService } from './spartacus/services/user-info.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService, BaseSiteService } from '@spartacus/core';
import { state } from '@angular/animations';
import { distinctUntilChanged, filter } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { environment } from './../environments/environment';
declare function OptanonWrapper(): void;
declare global {
  interface Window {
    Optanon?: {
      ShowPreferences: () => void;
    };
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'flu360_csf';
  userData: any;
  isMobileWidth: boolean = false;
  currentSite:any;
  currentRoute: any;
  private readonly ukDomainScriptId = environment.CookieUk;
  private readonly usDomainScriptId = environment.CookieUs;

  isCookieSettingsShown: boolean = false;
  showcookiebottonbanner:boolean=true;

  constructor(
    private userInfoService: UserInfoService,
    private cookieService: CookieService,
    private deviceDetectorService: DeviceDetectorService,
    private baseSiteService: BaseSiteService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    private authService:AuthService
  ) {}

  ngOnInit() {


   this.checkUserConsent();
    //this.setCurrentSite();
    
    this.baseSiteService.getActive().subscribe((site) => {
      this.currentSite = site;
      if (site == 'seqirusb2b-uk') {
        this.checkWindowWidth(site);
      }
    });

    this.loadOneTrustScript();
    this.userInfoService.fetchUserData();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes('login')) {
          if (
            this.currentSite != 'seqirusb2b-flu360' &&
            (!this.cookieService.check('visitor_type') ||
              this.cookieService.get('visitor_type') != 'healthcare')
          ) {
            sessionStorage.setItem('navigateToUrl', 'login');
            this.router.navigate(['/']);
          }
        }
        const path = localStorage.getItem('lastVisitedFooterUrl');
        if (path) {
          localStorage.removeItem('lastVisitedFooterUrl');
          this.router.navigate([path]);
        }
      }
    });

    this.authService.isUserLoggedIn().pipe(distinctUntilChanged()).subscribe(isLoggedIn => {
      if(!isLoggedIn){
        sessionStorage.removeItem('returnsFirstPopup');
        sessionStorage.removeItem('returnsSecondPopup');
      }
    })
  }

  private checkUserConsent() {
    if (this.cookieService.check('OptanonAlertBoxClosed')){
      this.showcookiebottonbanner=false;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkWindowWidth(this.currentSite);
  }

  checkWindowWidth(site: String) {
    const width = window.innerWidth;
    this.isMobileWidth = width < 1024;
    if (site == 'seqirusb2b-uk') {
      if (
        this.deviceDetectorService.isMobile() ||
        this.deviceDetectorService.isTablet()
      ) {
        this.router.navigate(['/mobileUKRedirection']);
      } else if (this.deviceDetectorService.isDesktop()) {
        if (this.isMobileWidth) {
          this.renderer.setStyle(
            document.querySelector('cx-storefront'),
            'display',
            'none'
          );
        } else {
          this.renderer.removeStyle(
            document.querySelector('cx-storefront'),
            'display'
          );
        }
      }
    }

    this.cdr.detectChanges();
  }

  /* private setCurrentSite(): void {
    const fullUrl = window.location.href;

    if (fullUrl.includes('uk') || fullUrl.includes('site=uk') || fullUrl.includes('flu360.co.uk')) {
      this.currentSite = 'seqirusb2b-uk';
    } else if (fullUrl.includes('flu360') || fullUrl.includes('site=flu360') || fullUrl.includes('flu360.com')) {
      this.currentSite = 'seqirusb2b-flu360';
    } else {
      console.error('URL path does not match any known sites.');
      this.currentSite = undefined;
    }
  } */

  private loadOneTrustScript(): void {
    const domainScriptId = this.getDomainScriptId();
    if (!domainScriptId) {
      console.error('No domain script ID found for the current site.');
      return;
    }
    const existingScript = document.querySelector('script[data-domain-script]');
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
      script.type = 'text/javascript';
      script.charset = 'UTF-8';
      script.setAttribute('data-domain-script', domainScriptId);
      document.body.appendChild(script);
      script.onload = () => {
        if (typeof OptanonWrapper === 'function') {
          OptanonWrapper();
        }
        this.addAcceptAllCookiesListener();
      };
    } else {
      if (typeof OptanonWrapper === 'function') {
        OptanonWrapper();
      }
      this.addAcceptAllCookiesListener();
    }
  }

  private getDomainScriptId(): string {
    switch (this.currentSite) {
      case 'seqirusb2b-flu360':
        return this.usDomainScriptId;
      case 'seqirusb2b-uk':
        return this.ukDomainScriptId;
      default:
        console.error('Current site does not match any known sites.');
        return '';
    }
  }

  private addAcceptAllCookiesListener(): void {
    const checkBannerExistence = setInterval(() => {
      const acceptButton = document.getElementById('onetrust-accept-btn-handler');
      if (acceptButton) {
        acceptButton.addEventListener('click', () => {
          const banner = document.getElementById('onetrust-banner-sdk');
          if (banner) {
            banner.remove();
          } else {
            console.error('OneTrust banner not found.');
          }
        });
        clearInterval(checkBannerExistence);
      }
    }, 500); // Check every 500ms
  }

  showCookieSettings(): void {
    if (this.isCookieSettingsShown) {
      return;
    }
    if (window.Optanon) {
      this.isCookieSettingsShown = true;
      window.Optanon.ShowPreferences();
    } else {
      console.error('window.Optanon is not defined.');
      this.isCookieSettingsShown = false;
    }
  }

  
  //   ngAfterViewInit(){

  //         //document.getElementById('iframeHolder')?.style.display = none
  //         // setTimeout(() => {
  //         //   console.log(document.getElementById('iframePopup'));
  //         //   if(document.getElementById('iframePopup') as HTMLElement  &&  localStorage.getItem('isCancelled') === 'true'){
  //         //     (document.getElementById('iframePopup') as HTMLElement).style.visibility = 'hidden'
  //         //     }
  //         // }, 5000);
  //         setTimeout(() => {
  //           console.log(document.getElementById('iframeHolder'));
  //           if(document.getElementById('iframeHolder') as HTMLElement  &&  localStorage.getItem('isCancelled') === 'true'){
  //             (document.getElementById('iframeHolder') as HTMLElement).style.visibility = 'hidden'
  //             }
  //         }, 5000);
  //     console.log('ngafterview')
  //   }
  //   ngOnChanges(){
  //     console.log('on changes')
  //   }
  // ngDoCheck(){

  //   console.log('do check')
  // }
  // ngAfterContentInit(){
  //   console.log('after content')
  // }
  // ngAfterContentChecked(){
  //   console.log('content checked')
  // }

  // ngAfterViewChecked(){
  //   console.log('view checked')
  // }
  // ngOnDestroy(){
  //   console.log('on destroy')
  // }
}
