import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlucelvaxReferenceparagraphComponent } from './flucelvax-referenceparagraph/flucelvax-referenceparagraph.component';
import { ConfigModule } from '@spartacus/core';
import { FlucelvaxCellbasedanimationComponent } from './flucelvax-cellbasedanimation/flucelvax-cellbasedanimation.component';
import { FlucelvaxImpactchartComponent } from './flucelvax-impactchart/flucelvax-impactchart.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { ChartModule } from 'angular-highcharts';



@NgModule({
  declarations: [
    FlucelvaxReferenceparagraphComponent,
    FlucelvaxCellbasedanimationComponent,
    FlucelvaxImpactchartComponent
  ],
  imports: [
    CommonModule,
    HighchartsChartModule,
    ConfigModule.withConfig({
      cmsComponents:{
        SeqirusProductAnimationBanner:{
          component: FlucelvaxCellbasedanimationComponent
        },
        SeqirusReferenceParagraphComponent:{
          component: FlucelvaxReferenceparagraphComponent
        },
        SeqirusImpactChartComponent:{
          component: FlucelvaxImpactchartComponent
        }
      }
    })
  ]
})
export class FlucelvaxCellbasedSolutionModule { }
