import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService, UserIdService } from '@spartacus/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StartOrderService {
  prodData$ = new BehaviorSubject<any>(null);
  public asmenabled:boolean = false;
  showApiFailurePopUp$= new BehaviorSubject<any>(false);

  constructor(private http: HttpClient,
    private occEndPointsService: OccEndpointsService,
    private userIdService: UserIdService) {
      this.asmenabled = localStorage.getItem('showASM') === 'true';
  }
  fetchUserUrl() {
    let partUrl = "/users/current";
    this.asmenabled = localStorage.getItem('showASM') === 'true';

    this.userIdService.getUserId().subscribe(item => {

      if(item && this.asmenabled){
        partUrl = `/users/${item}`;
      }else{
        localStorage.removeItem('isSearchByProfile')
      }
    });
    return partUrl;
  }
  getIsSearchByProfile(){
    if(localStorage.getItem('isSearchByProfile')  == 'false'){
      return false;
    }
    return true;
  }
  getOrderDetailsdata() {
    const orderListUrl = this.occEndPointsService.buildUrl(`${this.fetchUserUrl()}/Start-order/order?fields=DEFAULT&isSearchByProfile=${this.getIsSearchByProfile()}`);
    return this.http.get(orderListUrl);
  }
  postMembershipDetails(payload: any) {
    const membershipUrl = this.occEndPointsService.buildUrl(`${this.fetchUserUrl()}/Start-order/membership/submit?fields=DEFAULT`);
    return this.http.post(membershipUrl, payload, { responseType: 'text' });
  }
  addToCart(payload: any, seasonValue: string) {
    const addCartUrl = this.occEndPointsService.buildUrl(`${this.fetchUserUrl()}/cart/addToCart?fields=DEFAULT&season=${seasonValue}`);
    return this.http.post(addCartUrl, payload, { responseType: 'text' })
  }
  getReviewPageData(seasonValue: string) {
    const reviewUrl = this.occEndPointsService.buildUrl(`${this.fetchUserUrl()}/Start-order/order-review?fields=DEFAULT&season=${seasonValue}`);
    return this.http.get(reviewUrl)
  }
  deleteCartData(seasonValue: string) {
    const deleteUrl = this.occEndPointsService.buildUrl(`${this.fetchUserUrl()}/Start-order/deleteCart?fields=DEFAULT&season=${seasonValue}`);
    return this.http.get(deleteUrl, { responseType: 'text' })
  }
  placeOrderAPI(poNumber: string, seasonValue: string) {
    const placeOrderUrl = this.occEndPointsService.buildUrl(`${this.fetchUserUrl()}/checkout/confirmOrder?PORefNumber=${poNumber}&fields=DEFAULT&&season=${seasonValue}`);
    return this.http.get(placeOrderUrl, { responseType: 'text' });
  }
  getPORefNumber() {
    const poRefNumberUrl = this.occEndPointsService.buildUrl(`${this.fetchUserUrl()}/Start-order/generatePO?fields=DEFAULT`);
    return this.http.get(poRefNumberUrl, { responseType: 'text' })
  }

  iframeUrl:any;
  
  getIframeUrl(season: string) {
    const seasonValue = season === 'inSeason' ? '2024-2025' : '2025-2026';

    if( seasonValue === '2024-2025'){
      this.iframeUrl = this.occEndPointsService.buildUrl(`/SeqirusPDF/terms-conditions-sales-april-${seasonValue}?fields=DEFAULT`);
      }
      if( seasonValue === '2025-2026'){
        this.iframeUrl = this.occEndPointsService.buildUrl(`/SeqirusPDF/terms-conditions-sales-${seasonValue}?fields=DEFAULT`);
      }
    
    return this.http.get(this.iframeUrl, { responseType: 'text' })
  }
  checkOrderExist(seasonValue: string) {
    const checkOrderUrl = this.occEndPointsService.buildUrl(`${this.fetchUserUrl()}/Start-order/checkCartExistforSeason?fields=DEFAULT&season=${seasonValue}`);
    return this.http.get(checkOrderUrl, { responseType: 'text' });
  }
  getBulkOrderSeasonDocument(seasonValue: string) {
    let bulkOrderUrl = this.occEndPointsService.buildUrl(`${this.fetchUserUrl()}/Start-order/download-order-excel?fields=DEFAULT&season=${seasonValue}`);
    return this.http.get(bulkOrderUrl, { observe: 'response', responseType: 'arraybuffer' })
  }
  uploadFile(payload: any) {
    let bulkOrderUrl = this.occEndPointsService.buildUrl(`${this.fetchUserUrl()}/Start-order/bulkOrderUpload?fields=DEFAULT`)
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Accept', 'multipart/form-data');
    return this.http.post(bulkOrderUrl, payload, { headers, reportProgress: true, observe: "events", responseType: 'text' });
    // return this.http.post(bulkOrderUrl, payload);
  }
  submitFile(payload: any) {
    let bulkOrderUrl = this.occEndPointsService.buildUrl(`${this.fetchUserUrl()}/Start-order/bulkOrderSubmit`)
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Accept', 'multipart/form-data');
    return this.http.post(bulkOrderUrl, payload, { headers, responseType: 'text' });
  }
  getAfluriaPrescribingInfoPdf() {
    let afluriaUrl = this.occEndPointsService.buildUrl(`/SeqirusPDF/AfluriaPrescribingInfoPDF?fields=DEFAULT`);
    return this.http.get(afluriaUrl, {responseType: 'text'});
  }
  getFlaudPrescribingInfoPdf() {
    let afluriaUrl = this.occEndPointsService.buildUrl(`/SeqirusPDF/FluadPrescribingInfoPDF?fields=DEFAULT`);
    return this.http.get(afluriaUrl, {responseType: 'text'});
  }
  getFlucevaxcPrescribingInfoPdf() {
    let afluriaUrl = this.occEndPointsService.buildUrl(`/SeqirusPDF/FlucelvaxPrescribingInfoPDF?fields=DEFAULT`);
    return this.http.get(afluriaUrl, {responseType: 'text'});
  }

  preBookRollOver() {
    let rolloverUrl = this.occEndPointsService.buildUrl(`${this.fetchUserUrl()}/Start-order/preBookRollOver?fields=DEFAULT`);
    return this.http.get(rolloverUrl, {responseType: 'text'});
  }
  getUkShippingLocations(seasonValue: string) {
    let url = this.occEndPointsService.buildUrl(`${this.fetchUserUrl()}/Start-order/getShippingLocDetails?fields=DEFAULT&season=${seasonValue}&isSearchByProfile=${this.getIsSearchByProfile()}`);
    return this.http.get(url);
  }
  getUkProductDetails(payload: any) {
    let url = this.occEndPointsService.buildUrl(`${this.fetchUserUrl()}/Start-order/getProductPageDetails?fields=DEFAULT`);
    this.http.post(url, payload).subscribe((data:any) => {
      if (data) {
        data.constraintList = JSON.parse(data.constraintList);
        this.setProductData(data);
      }
    },(error) =>{
      this.setApiFailurePopup(true);
    });
  }
  setApiFailurePopup(result: boolean){
    this.showApiFailurePopUp$.next(result);
  }
  getApiFailurePopup(){
    return this.showApiFailurePopUp$.asObservable();
  }
  setProductData(result: any){
    this.prodData$.next(result);
  }
  getProductData(){
    return this.prodData$.asObservable();
  }
  getUkDeliverySlots(payload: any) {
    let url = this.occEndPointsService.buildUrl(`${this.fetchUserUrl()}/Start-order/getDeliverySlots?fields=DEFAULT`);
    return this.http.post(url, payload);
  }
  getreviewpage(payload:any){
    let url = this.occEndPointsService.buildUrl(`${this.fetchUserUrl()}/Start-order/review-order?fields=DEFAULT`);
    this.http.post(url, payload).subscribe((data:any) => {
      if (data) {
        if(data.deliverySlotConstraintList) {
        data.deliverySlotConstraintList = JSON.parse(data.deliverySlotConstraintList);
        }
        this.setProductData(data);
      }
    });
  }
  getAmendProductDetails(payload: any) {
    let url = this.occEndPointsService.buildUrl(`${this.fetchUserUrl()}/Start-order/edit-order?fields=DEFAULT`);
    this.http.post(url, payload).subscribe((data:any) => {
      if (data) {
        data.amendOrderConstraintList = JSON.parse(data.amendOrderConstraintList);
        this.setProductData(data);
      }
    });
  }
  removeAllDoses(orderId: string) {
    let removeAllDosesUrl = this.occEndPointsService.buildUrl(`${this.fetchUserUrl()}/Start-order/cancelOrder?fields=DEFAULT&orderId=${orderId}&selectedSeason=2025-2026`);
    return this.http.get(removeAllDosesUrl, {responseType: 'text'});
  }
  placeOrder(payload:any){
    let url = this.occEndPointsService.buildUrl(`${this.fetchUserUrl()}/checkout/place-confirmOrder?fields=DEFAULT&isSearchByProfile=${this.getIsSearchByProfile()}`);
    return this.http.post(url, payload, { responseType: 'text' });
  }
}
