import { ChangeDetectorRef, Component ,OnInit} from '@angular/core';
import { CmsBannerComponent } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-all-invoices-banner-section',
  templateUrl: './all-invoices-banner-section.component.html',
  styleUrl: './all-invoices-banner-section.component.scss'
})
export class AllInvoicesBannerSectionComponent {
  totalCount: any;
  name: string | undefined;
  loadedFromSummary: any;
  finalSeason:any;
  schPayCount: any;
  constructor(public component: CmsComponentData<CmsBannerComponent>,
    public userInfoService:UserInfoService,private cd:ChangeDetectorRef,private activatedRoute: ActivatedRoute) {
  }
  bannerNode: Observable<any> = this.component.data$;
  seasonData :Observable<any> =  this.userInfoService.getseasonDropdown("2024-2025");
  seasons$ = this.userInfoService.seasonValue$;
  invoiceCountValue$ = this.userInfoService.invoiceCount$;
  scheduledPaymentCountValue$ = this.userInfoService.scheduledPaymentCount$;

  data:any;
  isPreSeason = false;
  hasParam  =false;
  iframeSeason :any
  seasonIframe = false
  ngOnInit():void{
    this.hasParam = false;
    this.seasonIframe = false;
    this.iframeSeason = localStorage.getItem('iframeSeason')

    this.activatedRoute.queryParams.subscribe(params => {
      this.loadedFromSummary = params;

   
      if(this.loadedFromSummary.invoice  && this.loadedFromSummary.season ) {
     
      this.hasParam = true;
      //this.userInfoService.sendDropdownValue(this.loadedFromSummary.season)
      this.selectedSeason(this.loadedFromSummary.season)
      }
    })
    if(this.iframeSeason){
      this.seasonIframe = true
      this.selectedSeason(this.iframeSeason)
    }
    if(this.hasParam == false && this.seasonIframe == false){
    this.seasonData.subscribe((res:any)=>{
      let seasonVar :string =''
      if(res.preSeason != ''){
      this.isPreSeason = true;
      }
      if(this.isPreSeason == true){
        seasonVar = res.inSeason
      }
      else if(this.isPreSeason == false){
        seasonVar = res.inSeason
      }
      this.finalSeason =seasonVar;
      this.userInfoService.sendDropdownValue(this.finalSeason)
    })
  }
  }
  selectedSeason(e :any){
    if(this.loadedFromSummary.invoice != undefined && this.loadedFromSummary.season !=undefined){
      this.finalSeason = e
      this.userInfoService.sendDropdownValue(this.finalSeason)
      this.cd.detectChanges()
    }
    else if(this.seasonIframe){
      this.finalSeason = e
      this.userInfoService.sendDropdownValue(this.iframeSeason)
      this.cd.detectChanges()
      localStorage.removeItem('iframeSeason')
    }
    else{
      this.finalSeason =(e.target as HTMLInputElement).value
    this.userInfoService.sendDropdownValue(this.finalSeason)
  
    this.cd.detectChanges()
    }
  }
  getCount(){
    this.invoiceCountValue$.subscribe(res=>{
      this.totalCount = res;
    })
    return this.totalCount;
  }
  getScheduledPaymentCount(){
    this.scheduledPaymentCountValue$.subscribe((res: any)=>{
      this.schPayCount = res
    })
    return this.schPayCount;
  }


}
