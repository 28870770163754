import { Component, OnInit, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';

import { CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';
import { Router, NavigationStart } from '@angular/router';
import { CmsComponentData } from '@spartacus/storefront';
import { ContentService } from '../../../spartacus/services/content.service';
import { UserInfoService } from '../../../spartacus/services/user-info.service';
@Component({
  selector: 'app-custom-tiles',
  templateUrl: './custom-tiles.component.html',
  styleUrls: ['./custom-tiles.component.scss'],
})
export class CustomTilesComponent implements OnInit {
  details: any[] = [];
  selectedType: string[] = [];
  selectedProduct: string[] = [];
  currentPage = 0;
  pageSize = 9;
  totalPages = 0;
  totalItems = 0;
  solutionSuiteOpen = false;
  productListOpen = false;
  @Output() filterChanged = new EventEmitter<{ filterType: string; value: string; isChecked: boolean }>();
  occ: any;
  productId!: string;
  showProductFilter!: boolean;
  showsolutionFilter!: false;
  showSolutionFilter!: boolean;
  isUserLoggedIn: boolean = false;
  userInfo: any;
  selectedSolutionsCount: number = 0;
  selectedProductsCount: number = 0;
  filterdata: any;
  constructor(
    private cmsService: CmsService,
    protected config: OccConfig,
    public bannerComponent: CmsComponentData<CmsBannerComponent>,
    private contentService: ContentService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    public userInfoService: UserInfoService
  ) { }
  ngOnInit(): void {
    this.productId = this.productId;
    this.loadProductsdetails();
    this.getResourceDetails();
    this.getSpecificContent();
    this.userLogin();
  
    // Initial filter API call to load filter data
    this.contentService.getFilteredResources().subscribe(
      (response: any) => {
        this.filterdata = response;
  
        // Retrieve the checkbox state for solutions from sessionStorage
        const storedSolutionState = sessionStorage.getItem('checkboxSolutionState');
        if (storedSolutionState) {
          this.selectedType = JSON.parse(storedSolutionState);
          this.selectedSolutionsCount = this.selectedType.length;
          // Keep the solution filter open if there are any selections
          if (this.selectedSolutionsCount > 0) {
            this.showSolutionFilter = true;
          }
          // Trigger API call based on stored solution state
          this.selectedType.forEach((solution) => {
            const mockEvent = { target: { checked: true } } as unknown as Event;
            this.onCheckboxChange('solution', solution, mockEvent);
          });
        }
  
        // Retrieve the checkbox state for products from sessionStorage
        const storedProductState = sessionStorage.getItem('checkboxProductState');
        if (storedProductState) {
          this.selectedProduct = JSON.parse(storedProductState);
          this.selectedProductsCount = this.selectedProduct.length;
          // Keep the product filter open if there are any selections
          if (this.selectedProductsCount > 0) {
            this.showProductFilter = true;
          }
          // Trigger API call based on stored product state
          this.selectedProduct.forEach((product) => {
            const mockEvent = { target: { checked: true } } as unknown as Event;
            this.onCheckboxChange('product', product, mockEvent);
          });
        }
  
        this.cdr.detectChanges();
      },
      (error: any) => {
        console.error('Error fetching filter data:', error);
      }
    );
  }
  /**
   * Handles checkbox changes for filtering.
   * @param filterType The type of filter (solution or product).
   * @param value The filter value.
   * @param event The event triggered by checkbox interaction.
   */

  onCheckboxChange(filterType: string, value: string, event: Event): void {
    const isChecked = (event.target as HTMLInputElement).checked;
    if (filterType === 'solution') {
      if (isChecked && !this.selectedType.includes(value)) {
        this.selectedType.push(value);
        this.selectedSolutionsCount++;
      } else if (!isChecked && this.selectedType.includes(value)) {
        this.selectedType = this.selectedType.filter((type) => type !== value);
        this.selectedSolutionsCount--;
      }
      // Store the updated state for solutions in sessionStorage
      sessionStorage.setItem('checkboxSolutionState', JSON.stringify(this.selectedType));
    } else if (filterType === 'product') {
      if (isChecked && !this.selectedProduct.includes(value)) {
        this.selectedProduct.push(value);
        this.selectedProductsCount++;
      } else if (!isChecked && this.selectedProduct.includes(value)) {
        this.selectedProduct = this.selectedProduct.filter((product) => product !== value);
        this.selectedProductsCount--;
      }
      // Store the updated state for products in sessionStorage
      sessionStorage.setItem('checkboxProductState', JSON.stringify(this.selectedProduct));
    }
    this.filterChanged.emit({ filterType, value, isChecked });
  
    // Reset to the first page and load product details
    this.currentPage = 0;
    this.loadProductsdetails();
  }
  /**
   * Toggles the visibility of the solution suite filter options.
   */
  toggleFilter(filterType: string): void {
    if (filterType === 'solution') {
      this.showSolutionFilter = !this.showSolutionFilter;
    }
    else if (filterType === 'product') {
      this.showProductFilter = !this.showProductFilter;
    }
  }

  getResourceDetails(): void {
    if (!this.productId) {
      this.contentService.getResourceDetails(this.productId).subscribe(
        (response: any) => {
          this.cdr.detectChanges();
        },
        (error: any) => {
          console.error('Error fetching resource details:', error);
        }
      );
    }
  }
  /**
   * Fetches specific content for the resources.
   */
  getSpecificContent(): void {
    this.contentService.getSpecificContent(this.productId).subscribe(
      (response: any) => {
        this.cdr.detectChanges();
      },
      (error: any) => {
        console.error('Error fetching specific content:', error);
      }
    );
  }

  goToPage(page: number): void {
    document.body.scrollTop = 500;
    document.documentElement.scrollTop = 500;
    if (page >= 0 && page < this.totalPages) {
      this.currentPage = page;
      this.loadProductsdetails();
    }
  }
    /**
   * Fetches and updates product details based on filters.
   */
    // loadProductsdetails(): void {
    //   const selectedType = this.selectedType.join(',');
    //   const selectedProduct = this.selectedProduct.join(',');
    //   this.contentService
    //     .gettoolsAndResources(this.currentPage, this.pageSize, selectedType, selectedProduct)
    //     .subscribe(
    //       (response: any) => {
    //         if (response?.results && response?.pagination) {
    //           this.details = response.results;
    //           this.totalItems = response.pagination.totalCount;
    //           this.totalPages = Math.ceil(this.totalItems / this.pageSize);
    //         } else {
    //           console.error('Invalid API response:', response);
    //         }
    //         this.cdr.detectChanges();
    //       },
    //       (error: any) => {
    //         console.error('Error fetching product details:', error);
    //       }
    //     );
    // }
    loadProductsdetails(): void {
      const selectedType = this.selectedType.join(',');
      const selectedProduct = this.selectedProduct.join(',');
    
      this.contentService
        .gettoolsAndResources(this.currentPage, this.pageSize, selectedType, selectedProduct)
        .subscribe(
          (response: any) => {
            if (response?.results && response?.pagination) {
              // Log the data before sorting
              // console.log('Data before sorting:', response.results);
    
              // Sort the results based on 'sequenceNumber'
              this.details = response.results.sort((a: { sequenceNumber: number; }, b: { sequenceNumber: number; }) => a.sequenceNumber - b.sequenceNumber);
    
              // Log the data after sorting
              // console.log('Data after sorting:', this.details);
    
              this.totalItems = response.pagination.totalCount;
              this.totalPages = Math.ceil(this.totalItems / this.pageSize);
            } else {
              console.error('Invalid API response:', response);
            }
            this.cdr.detectChanges();
          },
          (error: any) => {
            console.error('Error fetching product details:', error);
          }
        );
    }
  getImage(image: any): string {
    const imageUrl = image?.url ? this.config.backend?.occ?.baseUrl + image.url : '';
    return imageUrl;
  }

  userLogin() {
    this.userInfoService.userLoggedIn$.subscribe((res) => {
      this.isUserLoggedIn = res;
      if (this.isUserLoggedIn) {
        this.userInfoService.getUserData().subscribe((res) => {
          if (res) {
            this.userInfo = res;
            localStorage.setItem('webEnabled', this.userInfo.webEnabled);
          }
        });
      }
    });
  }
}







  //  onCheckboxChange(filterType: string, value: string, event: Event): void {
  //    const isChecked = (event.target as HTMLInputElement).checked;
  //    if (filterType === 'solution') {
  //      if (isChecked) {
  //        this.selectedType.push(value);
  //      } else {
  //        this.selectedType = this.selectedType.filter((type) => type !== value);
  //      }
  //    } else if (filterType === 'product') {
  //      if (isChecked) {
  //        this.selectedProduct.push(value);
  //      } else {
  //        this.selectedProduct = this.selectedProduct.filter((product) => product !== value);
  //      }
  //    }
  //    this.filterChanged.emit({ filterType, value, isChecked });
  //    this.loadProductsdetails();
  //  }
  // onCheckboxChange(filterType: string, value: string, event: Event): void {
  //   const isChecked = (event.target as HTMLInputElement).checked;
  //   if (filterType === 'solution') {
  //     if (isChecked) {
  //       this.selectedType.push(value);
  //       this.selectedSolutionsCount++;
  //     } else {
  //       this.selectedType = this.selectedType.filter((type) => type !== value);
  //       this.selectedSolutionsCount--;
  //     }
  //   } else if (filterType === 'product') {
  //     if (isChecked) {
  //       this.selectedProduct.push(value);
  //       this.selectedProductsCount++;
  //     } else {
  //       this.selectedProduct = this.selectedProduct.filter((product) => product !== value);
  //       this.selectedProductsCount--;
  //     }
  //   }
  //   this.filterChanged.emit({ filterType, value, isChecked });
  //   this.loadProductsdetails();
  // }

  // loadProductsdetails(): void {
  //   const selectedType = this.selectedType.join(',');
  //   const selectedProduct = this.selectedProduct.join(',');
  //   this.contentService
  //     .gettoolsAndResources(this.currentPage, this.pageSize, selectedType, selectedProduct)
  //     .subscribe(
  //       (response: any) => {
  //         if (response?.results && response?.pagination) {
  //           // Sort the results based on 'tilePosition'
  //           this.details = response.results.sort((a: any, b: any) => a.tilePosition - b.tilePosition);
  //           this.totalItems = response.pagination.totalCount;
  //           this.totalPages = Math.ceil(this.totalItems / this.pageSize);
  //         } else {
  //           console.error('Invalid API response:', response);
  //         }
  //         this.cdr.detectChanges();
  //       },
  //       (error: any) => {
  //         console.error('Error fetching product details:', error);
  //       }
  //     );
  // }


//
// toggleFilter(filterType: string): void {
//   if (filterType === 'solution') {
//     this.showSolutionFilter = !this.showSolutionFilter;
//     if (!this.showSolutionFilter) {
//       this.selectedSolutionsCount = 0;
//       this.selectedType = [];
//     }
//   } else if (filterType === 'product') {
//     this.showProductFilter = !this.showProductFilter;
//     if (!this.showProductFilter) {
//       this.selectedProductsCount = 0;
//       this.selectedProduct = [];
//     }
//   }
// }



// }
// viewProductDetails(productId: string): void {
//   // Update the route with the product ID and navigate to the resource detail page
//   this.router.navigate(['/resources/details', productId]).then(() => {
//     // After navigation, fetch product details dynamically
//     this.fetchProductDetails(productId);
//   });
//  }
//  /**
//  * Fetches product details based on the provided product ID.
//  * @param productId The ID of the product to fetch details for.
//  */
//  fetchProductDetails(productId: string): void {
//   this.contentService.getResourceDetails(productId).subscribe(
//     (response: any) => {
//       console.log('Product Details Response:', response);
//       // Update local state with the fetched details
//       this.details = [response]; // Adjust according to your response structure
//       this.cdr.detectChanges();
//     },
//     (error: any) => {
//       console.error('Error fetching product details:', error);
//     }
//   );