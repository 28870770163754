<div id="resources-general" class="tools-and-resources tools-and-resources--downloads">
    <div class="container">
        <!--breadcrumbs-->
        <div class="breadcrumbs--page-nav" *ngIf="!userLoggedIn || (userLoggedIn && (!webEnabled))">
            <div class="container">
                <p>
                    <a [routerLink]="'/'">Home</a><span> &gt; </span>
                    <a [routerLink]="'/flu-vaccine-resources'">Tools &amp; Resources</a><span> &gt; </span>
                    <strong [innerHTML]="componentdata?.tileHeadLine"></strong>
                </p>
            </div>
        </div>
        <div class="coding-billing-sbs coding-billing-sbs--download">
            <div class="container tools-and-resources-container">
                <!--Top Left Container-->
                <div class="coding-billing-sbs__left">
                    <div class="coding-billing-sbs__content">
                        <!--HEADER-->
                        <h1 class="angle-header" *ngIf="componentdata?.tileHeadLine" [innerHTML]="componentdata?.tileHeadLine"></h1>
                        <!--description-->
                        <p *ngIf="componentdata?.headerDescription" [innerHTML]="componentdata?.headerDescription"></p>
                        <!--used href here need clarification-->
                        <!-- <a *ngIf="componentdata?.headerDownloadLabel" class="button--hero-outline-red"
                            [ngClass]="{ 'disable': componentdata?.headerDownloadLabel == 'Coming Soon'}"
                            [attr.disabled]="componentdata?.headerDownloadLabel == 'Coming Soon' ? true : null"
                            [href]="getpdf(componentdata?.headerDownloadMedia)"
                            target="_blank">{{componentdata?.headerDownloadLabel}}
                        </a> -->
                        <a class="button--hero-outline-red"
                            [ngClass]="{ 'disable': componentdata?.headerDownloadLabel == 'Coming Soon'}"
                            [attr.disabled]="componentdata?.headerDownloadLabel == 'Coming Soon' ? true : null"
                            [attr.href]="componentdata?.headerDownloadMedia ? getpdf(componentdata?.headerDownloadMedia) : null"
                            target="_blank">{{componentdata?.headerDownloadLabel}}
                        </a>
                        <!--for CPT Code-->
                        <p class="coding-billing-sbs__footnotes" *ngIf="componentdata?.text1content" [innerHTML]="componentdata?.text1content"></p>
                    </div>
                    <!--pdf download list-->
                    <div class="download-list">
                        <div class="download-list__header" *ngIf="componentdata?.toolkitEntries?.length > 0">
                            <button class="btn--download downloadBtn" (click)="downloadpdfs()">
                                Download Selected
                                <img role="presentation" alt="Download" src="../../../../assets/images/icon-download.svg">
                            </button>
                        </div>
                        <div class="download-list__body" id="download_toolkit">
                            <div class="download-list__item" *ngFor="let item2 of componentdata?.toolkitEntries">
                                <div class="checkbox-container">
                                    <label class="label-container flex-container">
                                        <span class="labelTransformation" [innerHTML]="item2?.toolkitHeader"></span>
                                        <input type="checkbox" class="hide-checkbox" id="download_item_chk_1"
                                            (change)="downloadselectedpdf($event, getpdf(item2?.toolkitMedia))">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <p [innerHTML]="item2?.toolkitDescription"></p>
                            </div>
                        </div>
                    </div>
                </div>
                <!--Top Right side Description-->
                <div class="coding-billing-sbs__right">
                    <div class="coding-billing-sbs__resource-notes">
                        <img [src]="getImage(componentdata?.tileImage)" [alt]="componentdata?.tileImage.altText">
                        <div class="coding-billing-sbs__resource-notes-list">
                            <div class="coding-billing-sbs__resource-notes-list-header">
                                <h3 [innerHTML]="componentdata?.resourceNotesLabel"></h3>
                                <img role="presentation" alt="Attributes Checklist"
                                    src="assets/images/resources-check.png">
                            </div>
                            <div [innerHTML]="componentdata?.resourceNotesDescription"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--Acess Resources Desktop Version-->
    <div class="resources d-none d-md-block">
        <div class="container access-resources-container">
            <div class="row-flex carousel-container-row feat-resources__headline">
                <div class="row-flex access-financial-resources-header--row">
                    <div class="col-sm-12 header--container">
                        <h2>
                            <span>Additional Resources</span>
                            <div class="global_line"></div>
                        </h2>
                    </div>
                </div>
            </div>
            <div id="clinical-resources-financial" class="row-flex carousel--three-card resources-card--row">
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 cards-pl-0-mobile" *ngFor="let data1 of finalArray">
                    <div class="resources--card">
                        <img [src]="getImage(data1?.tileImage)"
                            [alt]="data1?.tileImage.altText"
                            class="card-img">
                        <div class="resources--card-container">
                            <p class="resources--card-eyebrow" [innerHTML]="data1?.tileType"></p>
                            <p class="resources--card-title" [innerHTML]="data1?.tileHeadLine"></p>
                            <p class="resources--card-paragraph" [innerHTML]="data1?.tileDescription"></p>
                        </div>
                        <a [href]="data1?.tileLink">
                            <button class="resources--card-btn">
                                <span [innerHTML]="data1?.tileLinkLabel"></span>
                                <img src="/assets/images/arrow-right.svg">
                            </button>
                        </a>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <!--Acess Resources Mobile Version-->
    <div class="resources d-block d-md-none">
        <div class="container access-resources-container">
            <div class="row-flex carousel-container-row feat-resources__headline">
                <div class="row-flex access-financial-resources-header--row">
                    <div class="col-sm-12 header--container">
                        <h2>
                            <span>Additional Resources</span>
                        </h2>
                    </div>
                </div>
            </div>
            <div id="clinical-resources-financial" class="row-flex carousel--three-card resources-card--row">
                <div id="resourcesCarousel" class="carousel slide" data-ride="false">
                    <ol class="carousel-indicators">
                        <li *ngFor="let data1 of finalArray; let i = index" [attr.data-target]="'#resourcesCarousel'"
                            [attr.data-slide-to]="i" [class.active]="i === 0"></li>
                    </ol>

                    <div class="carousel-inner">
                        <div class="carousel-item" *ngFor="let data1 of finalArray; let i = index"
                            [class.active]="i === 0">
                            <div class="resources--card">
                                <img [src]="getImage(data1?.tileImage)"
                                [alt]="data1?.tileImage.altText"
                                    class="card-img">
                                <div class="resources--card-container">
                                    <p class="resources--card-eyebrow" [innerHTML]="data1?.tileType"></p>
                                    <p class="resources--card-title" [innerHTML]="data1?.tileHeadLine"></p>
                                    <p class="resources--card-paragraph" [innerHTML]="data1?.tileDescription"></p>
                                </div>
                                <a [href]="data1?.tileLink">
                                    <button class="resources--card-btn">
                                        <span [innerHTML]="data1?.tileLinkLabel"></span>
                                        <img src="/assets/images/arrow-right.svg">
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                    <a class="carousel-control-prev d-none" href="#resourcesCarousel" role="button" data-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next d-none" href="#resourcesCarousel" role="button" data-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <!--View All Resources Button-->
    <div class="row-flex ">
        <div class="col-xs-12 text-center view-resources--row">
            <a routerLink="{{componentdata?.viewAllResourcesLink?.url}}">
                <button
                    class="button--view-resources-outline-grey">{{componentdata?.viewAllResourcesLink?.linkName}}</button>
            </a>
        </div>
    </div>
</div>