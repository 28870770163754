import { Component ,Renderer2,ElementRef} from '@angular/core';
import { CmsBannerComponent,CmsService, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { UserInfoService } from '../../spartacus/services/user-info.service';

@Component({
  selector: 'app-fluad-rwe-fifth',
  templateUrl: './fluad-rwe-fifth.component.html',
  styleUrl: './fluad-rwe-fifth.component.scss'
})
export class FluadRweFifthComponent {

fifthnode: Observable<any> = this.bannerComponent.data$;
userLoggedIn: boolean = false;
constructor(private cmsService:CmsService, protected config: OccConfig,public bannerComponent: CmsComponentData<CmsBannerComponent>, private userInfoService:UserInfoService) {
   }

getImage(image: any): string {
  return this.config.backend?.occ?.baseUrl + image.desktop.url;
}
getpdf(pdf: any): string {
  return this.config.backend?.occ?.baseUrl + pdf?.url + "&attachment=true";
}
}
