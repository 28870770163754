<div class="popupCloseParent">
  <span class="imgCarousel_popClose" (click)="closeDialog()">x</span>
</div>
<div class="slider-container">
  <div class="slider-wrapper">
    <div class="slider-item" *ngFor="let image of images; let i = index">
      <img [src]="baseUrl + image.url" [alt]="getFileName(baseUrl + image.url)">
      <span class="image-name">{{ getFileName(baseUrl + image.url) }}</span>
    </div>
  </div>
  <a class="prev" (click)="plusSlides(-1)"><i class="fa-solid fa-chevron-left"></i></a>
  <a class="next" (click)="plusSlides(1)"><i class="fa-solid fa-chevron-right"></i></a>
  <div class="thumbnail-container">
    <div class="thumbnail" *ngFor="let image of images; let i = index" (click)="currentSlide(i)" [class.active]="i === 0">
      <img [src]="baseUrl + image.url" [alt]="getFileName(baseUrl + image.url)">
    </div>
  </div>
</div>
<div class="dialog-footer">
  <button class="imgCarouselDone_btn" (click)="closeDialog()">Done</button>
</div>