
<div class ="row orders_sideRight">
    <div class="col-xs-12 col-md-6 orders_latesshipment">Orders Overview</div>
    <div class="col-xs-12 col-md-6 d-none d-sm-none d-md-none d-lg-block orders_vieworder" *ngIf=" dataSource && dataSource.length != 0">
    <a class="orders_viewalltxt anchorunderline" [routerLink]="'/orders/my-orders'">View all open orders<i class="global_blackarrow"></i></a>
    </div>
  <div class="col-xs-12 overview_info">Get a quick view of confirmed orders, shipping details, and quantities delivered.</div>
  <div class="col-xs-12 col-md-6 d-block d-sm-block d-md-block d-lg-none orders_vieworder" *ngIf="dataSource && dataSource.length != 0">
    <a class="orders_viewalltxt anchorunderline" [routerLink]="'/orders/my-orders'">View all open orders<i class="global_blackarrow"></i></a>
    </div>
  <div class="col-xs-12 d-none d-sm-none d-md-none d-lg-block margin-T30 overview_table">
<table class="tableClass" mat-table [dataSource]="dataSource" class="mat-elevation-z8" *ngIf=" dataSource && dataSource.length != 0">
  
    <ng-container matColumnDef="address">
        <th mat-header-cell *matHeaderCellDef> Shipping Location </th>
        <td mat-cell class="matRow" *matCellDef="let element"> {{element.address}} </td>
    </ng-container>
  
   
    <ng-container matColumnDef="deliveredQty">
      <th mat-header-cell *matHeaderCellDef> Order Quantity Delivered </th>
      
      <td mat-cell class="matRow" *matCellDef="let element"><span class="unitsClass">{{element.deliveredQty | number:'1.0'}} units / {{element.totalQty | number:'1.0'}} total</span><mat-progress-bar mode="determinate" value= {{getProgress(element.deliveredQty,element.totalQty)}}></mat-progress-bar></td>
    </ng-container>
  
    <ng-container matColumnDef="orderNum">
      <th mat-header-cell *matHeaderCellDef>Order</th>
      <td mat-cell class="matRow" *matCellDef="let element"><a class="orders_viewDetails anchorunderline" [routerLink]="'/orders/my-orders'" [queryParams]="{address: element.address}">View order <i class="global_blackarrow"></i></a> </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
 
</table>

<div class="col-xs-12 col-md-6  table_viewOrders" *ngIf=" dataSource?.length>=10">
    <a [routerLink]="'/orders/my-orders'" class="orders_viewalltxt anchorunderline">View all open orders<i class="global_blackarrow"></i>
    </a>
</div>
</div>
<div class="col-xs-12 d-block d-sm-block d-md-block d-lg-none overview_table" >
<table mat-table [dataSource]="dataSource" class="mat-elevation-z8" *ngIf=" dataSource && dataSource.length != 0">
    <ng-container matColumnDef="combined">
      <th class="odertableheader" mat-header-cell *matHeaderCellDef></th>
        <td mat-cell class="matRow" *matCellDef="let element"> 
          <div class="orders_mobileVisible">Shipping Location </div>
          <div class="addressClass">{{element.address}} </div>
          <div class="orders_mobileVisible">Order Quantity Delivered</div>
          <div class="progressClass">{{element.deliveredQty | number:'1.0'}} units / {{element.totalQty | number:'1.0'}} total<mat-progress-bar mode="determinate" value= {{getProgress(element.deliveredQty,element.totalQty)}}></mat-progress-bar></div>
          <div class="orders_mobileVisible">Order</div>
          <div class="viewOrderClass"><a class="orders_viewDetails anchorunderline" [routerLink]="'/orders/my-orders'" [queryParams]="{address: element.address}">View order <i class="global_blackarrow"></i></a></div>
        
        </td>
     
      
           </ng-container>
           <tr  mat-header-row *matHeaderRowDef="displayedColumnsMobile"></tr>
           <tr  mat-row *matRowDef="let row; columns: displayedColumnsMobile;"></tr>
          </table>
          <div class="col-xs-12 col-md-6  table_viewOrders" *ngIf=" dataSource?.length>=10">
            <a [routerLink]="'/orders/my-orders'" class="orders_viewalltxt anchorunderline">View all open orders<i class="global_blackarrow"></i>
            </a>
        </div>
        </div>
        <div class="col-xs-12 overview_orders" *ngIf=" dataSource && dataSource?.length == 0" >
          You have not placed any orders yet for this season.<br>You may view 
          <a class="myorders_link" [routerLink]="'/orders/my-orders'">My Orders</a>&nbsp;for previous seasons.</div>
</div>

<div *ngIf="isLoading" class="loading">
  <div class="loading_txt">
  <div class="load_txt">Loading</div>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
  </div>
</div>
  