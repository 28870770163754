
<ng-container *ngIf="bannerNode | async as item">
<div *ngIf="item.uid === 'comp1-returnsLandingPage'">  
<div *ngIf="isVisible">
  <div class="loading" *ngIf="showloader" >
    <div class="loading_txt">
     <div class="load_txt">Loading</div>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
     </div>
  </div>
  <div class="loading" *ngIf="showloader_download" >
    <div class="loading_txt">
     <div class="load_txt">Downloading</div>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
     </div>
  </div>
<div id="forUKReturns">
    <section class="col-xs-12 col-sm-12 returnsLanding_body">
      
    <div class="col-xs-12 col-sm-12">
        <div class="col-xs-12 col-sm-12 col-md-12 no-padding returnsRed_header margin-B30">{{item.paragraphcontent}}</div>
        <div class="col-xs-12 col-sm-12 col-md-12 no-padding noreturn_subheader margin-B30" *ngIf="!returnEligilibity">Returns are currently closed.</div>  
        <div class="returns_topTextContainer" *ngIf="returnEligilibity">
                       <div class="col-xs-12 col-sm-6 col-md-6 no-left-pad" >
                          <div class="col-xs-12 col-sm-12 col-md-12 no-padding return_subheader">{{item.h2content}}</div>
                          <div class="col-xs-12 col-sm-12 col-md-12 no-padding return_headerpara" [innerHTML]="item.content">
                           
                        </div>
                       </div>

                       <div class="col-xs-12 col-sm-6 col-md-6 topBtnContainer">
                        <ng-container *ngIf="returnrba?.RBAPermissions==true;else retunrnormalright">

                          <ng-container *ngIf="returnrba?.showDashboardOrderUK==true">
                       <div class="flexDiv">
                                          <button id="NewReturn_btn" (click)="showNewReturn()">
                                              Start a New Return</button>
                                          <div class="returns_topText1">Or</div>
                                          <div class="noReturnBtnParent">
                                              <button id="verifyNoReturnBtn" (click)="showNoreturn()">Verify No Returns</button>
                                          </div>
                                      </div>
                                      </ng-container>
                                      </ng-container>
                                      <ng-template #retunrnormalright>
                                        <div class="flexDiv">
                                          <button id="NewReturn_btn" (click)="showNewReturn()">
                                              Start a New Return</button>
                                          <div class="returns_topText1">Or</div>
                                          <div class="noReturnBtnParent">
                                              <button id="verifyNoReturnBtn" (click)="showNoreturn()">Verify No Returns</button>
                                          </div>
                                      </div>

                                      </ng-template>
                                  </div>
                  </div>
             </div> 
             <div class="clearboth"></div>
             <br/>
             <div class="col-xs-12 col-sm-12 financial_returnProcess">
                <div class="clearboth"></div>
                <div class="return_subheader">{{item.text1}}</div>
                    <div class="return_subtxt">{{item.LargeText1}}</div>
                <div class="col-xs-12 col-sm-12 financial_returnSliderparent">
                  <div class="financial_returnSlider">
                    <div class="financial_circlededge"></div>
                    <div class="financial_middleline">
                      <div class="financial_circle1">
                        <div class="financial_outer-circle">
                          <div class="financial_inner-circle"></div>
                        </div>
                      </div>
                      <div class="financial_circle2">
                        <div class="financial_outer-circle">
                          <div class="financial_inner-circle"></div>
                        </div>
                      </div>
                      <div class="financial_circle3">
                        <div class="financial_outer-circle">
                          <div class="financial_inner-circle"></div>
                        </div>
                      </div>
                    </div>
                    <div class="financial_circlededge"></div>
                  </div>
                  <div class="financial_textcontent">
                    <div class="financial_text1" style="left: 37.8333px;">
                        <p>{{item.text2}}</p>
                          <span>{{item.LargeText2}}</span>
                        </div>
                    <div class="financial_text2" >
                      <p>{{item.text3}}</p>
                          <span>{{item.LargeText3}}</span>
                        </div>
                    <div class="financial_text3" >
                      <p>Credit Applied</p>
                          <span>{{item.text4}}</span>
                        </div>
                    <div class="clearboth"></div>
                  </div>
                </div>
              </div>
   


<div class="clearboth"></div>

<div class="returnsBody_textSection">
  <div class="col-xs-12 col-sm-12">
    <div class="col-xs-12 col-sm-12 no-padding returnsRed_header">My Returns</div>
    <div class="col-xs-12 col-sm-12 no-padding returns_subheaderParent">
      <div class="col-xs-12 col-sm-6 no-padding returnsBody_subheader">All returns submitted via flu360 are listed below.</div>
      <div class="col-xs-12 col-sm-6 returnSeason"><div>Return Season:&nbsp;</div>
      <select class="Return_current_sesson_dropdown" name="session" id="Return_select_season" (change)="onSeasonChange($event)">
        <option *ngFor="let season of seasons" [value]="season" [selected]="season === defaultReturnSeason">
          Select Season: {{ season }}
        </option>
      </select>
      </div>
    </div>
  </div>
</div>
<div  class="col-xs-12 col-md-12 noMyReturnText" *ngIf="noReturnsAvailable">
  No returns have been submitted for this season
</div>
<section class="col-xs-12 col-md-12 returns_midsection" *ngIf="returnsAvailble">
  <!-- Table starts -->
  <div class="col-xs-12 col-md-5 no-left-pad returnstablecontainer">
    
    <div class="form-group returns_datasearch">
      <div class="col-xs-10 col-md-10 searchbox">
        <div class="input-group returns_order">
          <div class="input-group-addon">
            <i class="fa fa-search"></i>
          </div>
          <input class="form-control" id="returns_search" name="lookup" type="text" matInput (keyup)="applyFilter($event)"   #searchInput>
          
        </div>
      </div>
          <div class="col-xs-1 col-md-1 filterbox" (click)="toggleFilter($event)">
            <img alt="Filter" src="assets/images/filter.png" width="25">
            <span *ngIf="selectedFilterCount > 0">{{selectedFilterCount}}</span>
            <div class="filterText">Filter</div>
          </div>
          <div class="col-xs-1 col-md-1 sortbox" (click)="toggleSort($event)">
            <img src="assets/images/sort.png" width="25" alt="sort">
            <div class="filterText">Sort</div>
          </div>
        </div>
        <div class="sortopen show" *ngIf="showFilterSort" (click)="onDivClick($event)">
          <div class="locationsection">
            <div class="filterheader">LOCATION</div>
            <div class="returns_radioparent">
              <label class="radio-button">
                <input type="radio" class="invoice_radio" name="radiobtn" [(ngModel)]="selectedSortOrder" value="asc" (change)="sortData('asc')">
                <span class="radio-button__control"></span>
              </label>
              <label>A to Z</label>
            </div>
            <div class="returns_radioparent">
              <label class="radio-button">
                <input type="radio" class="invoice_radio" name="radiobtn" [(ngModel)]="selectedSortOrder" value="dsc" (change)="sortData('desc')">
                <span class="radio-button__control"></span>
              </label>
              <label>Z to A</label>
            </div>
          </div>
          <div class="clearboth"></div>
          <div class="returns_reset" id="returns_resetsort" (click)="resetSort()">RESET</div>
          <div class="clearboth"></div>
        </div>

        <div class="filteropen show" *ngIf="showFilterPanel" (click)="onDivClick($event)">
          <div class="prodsection">
            <div class="filterheader">BY PRODUCT</div>
            <div class="returns_checkboxparent">
              <label class="checkbox-button">
                <input type="checkbox" name="prodname" value="aQIV - 10 Pack" class="returns_checkbox" [(ngModel)]="selectedProducts.aqiv" (change)="applyFilters()">
                <span class="checkbox-button__control"></span>
              </label>
              <label>AQIV - 10 Pack</label>
            </div>
            <div class="returns_checkboxparent">
              <label class="checkbox-button">
                <input type="checkbox" name="prodname" value="QIVc - 10 Pack" class="returns_checkbox" [(ngModel)]="selectedProducts.qivc" (change)="applyFilters()">
                <span class="checkbox-button__control"></span>
              </label>
              <label>QIVC - 10 Pack</label>
            </div>
          </div>
          <div class="statussection">
            <div class="filterheader">BY STATUS</div>
            <div class="returns_checkboxparent">
              <label class="checkbox-button">
                <input type="checkbox" name="status" value="APPROVED" class="returns_checkbox" [(ngModel)]="selectedStatus.approved" (change)="applyFilters()">
                <span class="checkbox-button__control"></span>
              </label>
              <label>APPROVED</label>
            </div>

            <div class="returns_checkboxparent">
              <label class="checkbox-button">
                <input type="checkbox" name="status" value="SUBMITTED" class="returns_checkbox" [(ngModel)]="selectedStatus.submitted" (change)="applyFilters()">
                <span class="checkbox-button__control"></span>
              </label>
              <label>SUBMITTED</label>
            </div>
            <div class="returns_checkboxparent">
              <label class="checkbox-button">
                <input type="checkbox" name="status" value="NO RETURNS" class="returns_checkbox" [(ngModel)]="selectedStatus.noReturns" (change)="applyFilters()">
                <span class="checkbox-button__control"></span>
              </label>
              <label>NO RETURNS</label>
            </div>
          </div>
          <div class="returns_reset" id="returns_resetfilter" (click)="resetFilters()">RESET</div>
          <div class="clearboth"></div>
        </div>

      
         
    <hr>
    <table mat-table [dataSource]="dataSource" class="dataTable no-footer" id="returnfilterTable">

      <!-- Combined Column -->
      <ng-container matColumnDef="combined">
        <th  *matHeaderCellDef class="no-thead"></th>
        <td mat-cell *matCellDef="let element" class="return_table_td" >
          <div class="returns_firstrow">
            <div class="return_season">{{element.orderSeason}} SEASON</div>
            <div class="returns_status" [ngClass]="{
              'submitted': element.status === 'SUBMITTED',
              'notReturns': element.status === 'NO RETURNS',
              'approved': element.status === 'APPROVED'
            }">{{element.status}}</div>
          </div>
          <div class="returnOrg_name">{{element.companyName}}</div>
          <div class="returns_unit" *ngIf="element.aqivDoseReturned !== 0"><span>aQIV Dose Returned:</span><span class="doseValue">{{element.aqivDoseReturned}}</span></div>
          <div class="returns_unit" *ngIf="element.qivcDoseReturned !== 0"><span>QIVc Dose Returned:</span><span class="doseValue">{{element.qivcDoseReturned}}</span></div>
          <div class="returns_location"><span>Delivery Location:</span><span>{{element.loc}}</span></div>
          <!-- <div class="searchorder locID">{{element.locID}}</div> -->
           
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [class.active]="row === selectedRow" (click)="onRowClicked(row)"></tr>
      
    </table>
    <div *ngIf="dataSource.data.length === 0" class="dataTables_empty">No matching records found</div>
    <mat-paginator [length]="dataSource.data.length" [pageSize]="5" [pageSizeOptions]="[1]" showFirstLastButtons ></mat-paginator>
    <div class="dataTables_paginate paging_full_numbers" id="returnfilterTable_paginate">
      <a class="paginate_button first"  [class.disabled]="currentPage === 1"><img alt="Navigate" (click)="goToFirstPage()" to="" previous="" page="" src="assets/images/pagination-double-prev-arrow-icon.svg"></a>

      <a class="paginate_button previous disabled" [class.disabled]="currentPage === 1"><img alt="Navigate" (click)="goToPreviousPage()" to="" previous="" page="" src="assets/images/pagination-prev-btn.svg"></a>
      
      <span *ngFor="let page of pages" (click)="goToPage(page)" [class.active]="page === currentPage"><a class="paginate_button current">{{page}}</a></span>
      
      <a class="paginate_button next" [class.disabled]="currentPage === totalPages"><img alt="Navigate" (click)="goToNextPage()" to="" next="" page="" src="assets/images/pagination-next-btn.svg"></a>
      
      <a class="paginate_button last" [class.disabled]="currentPage === totalPages"><img alt="Navigate" (click)="goToLastPage()" to="" last="" page="" src="assets/images/pagination-double-next-arrow-icon.svg"></a>
    </div>
  </div>
  <!-- Table Ends -->
  <!-- right side -->
  <div class="col-xs-12 col-md-7 returns_details active">
    <div *ngIf="selectedDetails">
      <div class="row returns_details_row">
        <div class="col-md-7 col-xs-12">
          <div class="col-md-12 col-xs-12 returns_rightOrgName">{{selectedDetails.companyName}}</div>
          <div class="col-md-12 col-xs-12 no-left-pad">
            <div class="col-md-3 col-xs-12 no-left-pad">
              <div class="col-md-12 col-xs-12 returns_details_header">SOR</div>
              <div class="col-md-12 col-xs-12 returns_details_subheader">{{selectedDetails.sor}}%</div>
            </div>
            <div class="col-md-9 col-xs-12">
              <div class="col-md-12 col-xs-12 returns_details_header">Delivery Address</div>
              <div class="col-md-12 col-xs-12 returns_details_subheader addess">{{selectedDetails.loc}}</div>
            </div>
          </div>
        </div>
        <div class="col-md-5 col-xs-12 no-pad returnMore_greybtncontainer" *ngIf="selectedDetails.checkForAddRetEligible == true && selectedDetails.addReturnPresent == false && returnEligilibity==true && selectedDetails.rbaPermission=='MANAGE'">
          <div class="return_greybtn addReturnBtn" id="additionalReturn" (click)="showAdditionalReturn()">Return More for This Location</div>
        </div>
        <div class="col-md-5 col-xs-12 no-pad help_txt_return" *ngIf="selectedDetails.checkForAddRetEligible == false || selectedDetails.addReturnPresent == true|| returnEligilibity==false || selectedDetails.rbaPermission!='MANAGE'">
          <div class="helpParent">
            <div class="col-md-12 col-xs-12 helpHeader">Need Help?</div>
            <div class="col-md-12 col-xs-12 helpsubHeader">Contact&nbsp; <a routerLink="/contact-us"  class="returnPopupshow">flu360 | Support</a></div>
          </div>
        </div>
      </div>
  
      <div class="returnDoseBody active" *ngFor="let location of selectedDetails.locations">
      
        <div class="row returns_details_row col-md-12 col-xs-12 top_border">
          <div class="col-md-3 col-xs-3 no-right-pad" *ngIf="location.status !== 'No Returns'">
              <div class="returns_details_header" >Return Reference #</div>
              <div class="returns_details_subheader returnNumber">{{location.returnId}}</div>
            </div>
            <div class="col-md-3 col-xs-3 Returnstatwid no-right-pad">
              <div class="returns_details_header">Return Status</div>
              <div class="returns_details_subheader rightStatusText">{{location.status}}</div>
            </div>
            <div class="col-md-3 col-xs-3 no-left-pad Returnsubmitwid no-right-pad">
              <div class="returns_details_header">Return Submitted On</div>
              <div class="returns_details_subheader">{{location.returnSubmittedOn}}</div>
            </div>
           
             <div class="col-md-3 col-xs-3 Returncredwid no-right-pad credit-issues-cell" *ngIf="location.status !== 'No Returns'">
             <div class="returns_details_header">Credit Issued On</div>
            <div class="returns_details_subheader" *ngIf="location.creditIssued == false">Credit not yet issued</div>
            <div class="returns_details_subheader" *ngIf="location.creditIssued == true">
              {{location.creditIssuedDate}}<br/>
              <a (click)="financialgetDownloadInvoiceData(location.creditNumber)"  class="returnPopupshow" *ngIf="location.creditNumber != null">
                <span>View Credit Memo</span>
                <div class="global_blackarrow"></div>
              </a>

            </div>
              </div>
        </div>

        <div class="row returns_details_row col-md-12 col-xs-12" *ngIf="location.creditIssued == true">
          <div class="lightGreyBox" *ngIf="credit_dest_box">
            <div class="col-md-12 col-xs-12 checkBoxHeader">Your credit has been issued, you may now destroy your returned doses.</div>
            <div class="destroy_checkboxparent col-md-12 col-xs-12">
              <label class="checkbox-button">
                <input type="checkbox" name="destroy_checkbox" id="destroy_checkbox" [(ngModel)]="isCheckboxChecked" (change)="onCheckboxChange($event)" class="destroy_checkbox">
                <span class="checkbox-button__control"></span>
              </label>
              <label class="checkboxtext">I confirm I have destroyed the doses for this return.</label>
            </div>
          </div>
          <div class="col-md-12 col-xs-12 confirmText" *ngIf="confirm_destroy_msg">Thank you for confirming the destruction of your doses.</div>
        </div>

            <div class="row returns_details_row tableParent col-md-12 col-xs-12">
              <div class="col-md-6 col-xs-6 returns_tableConatiner" *ngIf="hasProducts(location, 'aQIV')">
                <div class="col-md-12 col-xs-12 return_prodName">aQIV</div>
                <div class="col-md-12 col-xs-12 returns_details_header">(65 years and above)</div>
                <div class="clearboth"></div>
                <table class="table returns-details-table">
                  <thead>
                    <tr>
                      <th>Batch Number</th>
                      <th>Returned Doses</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr  *ngFor="let product of filterProductsByType(location, 'aQIV')">
                      <td *ngIf="product.selectedQuantity !== 0 || product.additionalQuantity !== 0">{{product.batchNumber}}</td>
                      <td class="add" *ngIf="product.selectedQuantity !== 0 || product.additionalQuantity !== 0">{{getDisplayedQuantity(product)}}</td>
                    </tr>
                  </tbody>
                </table>
                <table class="totalShow">
                  <tbody>
                    <tr class="active">
                      <th class="returns_total_row totalText">Total</th>
                      <th class="dose_counter">
                        <span class="total_ord_qty returns_total_row">{{getTotalDoses(filterProductsByType(location, 'aQIV'))}}</span>
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-md-6 col-xs-6 returns_tableConatiner" *ngIf="hasProducts(location, 'QIVc')">
                <div class="col-md-12 col-xs-12 return_prodName">QIVc</div>
                <div class="col-md-12 col-xs-12 returns_details_header">(6 months and above)</div>
                <div class="clearboth"></div>
                <table class="table returns-details-table qivcTable">
                  <thead>
                    <tr>
                      <th>Batch Number</th>
                      <th>Returned Doses</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let product of filterProductsByType(location, 'QIVc')">
                      <td *ngIf="product.selectedQuantity !== 0 || product.additionalQuantity !== 0">{{product.batchNumber}}</td>
                      <td class="add" *ngIf="product.selectedQuantity !== 0 || product.additionalQuantity !== 0">{{getDisplayedQuantity(product)}}</td>
                    </tr>
                  </tbody>
                </table>
                <table class="totalShow">
                  <tbody>
                    <tr class="active">
                      <th class="returns_total_row totalText">Total</th>
                      <th class="dose_counter">
                        <span class="total_ord_qty returns_total_row">{{getTotalDoses(filterProductsByType(location, 'QIVc'))}}</span>
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="clearboth"></div>
            <div class="col-xs-12 returns_greybtncontainer no-left-pad" *ngIf="location.imageUploads && location.imageUploads.length > 0">
              <div class="return_greybtn imgUploadBtn" style="position:relative" id="viewUpload-RMA000002HI8" (click)="openImageDialog(location)">View Uploaded Images</div>
            </div>  
        
      </div>
    </div>
  </div>
</section>
</section>
<section *ngIf="isCheckboxChecked" >
  <div id="returns_destroyPopup" class="modal fade in" role="dialog" style=" padding-right: 10px;">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <h4 class="modal-title OMexit_popHeader col-xs-9">Confirm Dose Destruction</h4>
          <div class="col-xs-3">
            <span class="OMexit_popClose" data-dismiss="modal" (click)="onButtonClick()">x</span>
          </div>
          <p class="OMexit_popSubHeader col-xs-12">Thank you for confirming the safe destruction of your remaining vaccines.</p>
          <div class="clearboth"></div>
          <div class="OM_exitPopBtnParent">
            <button class="OM_popBackBtn" data-dismiss="modal" (click)="onButtonClick()">Cancel</button>
            <button class="OM_popExitBtn" (click)="callDeleteDosesConsent()">Confirm</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
</div>

</div>
</div>
</ng-container>
