<div class="clinical-flucelvax-second">
    <div class="product_efficacy-header2" *ngIf="thirdnode | async as item">{{item.headLine}}</div>
    <div class="product_efficacy-text" *ngIf="thirdnode | async as item" [innerHTML]="item.content"></div>
    <div class="product_efficacy-header3" *ngIf="selectedTabIndex !== undefined">
        <p>{{ mergedObject2[selectedTabIndex]?.h3content }}</p> 
    </div>
    <div class="product_efficacy-text" *ngIf="selectedTabIndex !== undefined" [innerHTML]="mergedObject2[selectedTabIndex]?.content"></div>


    <div class="product_efficacy-tabContainer" id="product_efficacy-tabContainer2">
        <div class="panel with-nav-tabs panel-primary">
            <div class="panel-heading">
                <mat-tab-group (selectedTabChange)="onTabChange($event)" mat-stretch-tabs="false" mat-align-tabs="start"
                    animationDuration="0ms">
                    <div *ngFor="let item2 of mergedObject2  let i= index">
                        <mat-tab label="{{ item2.headline }}">
                            <div class="wrapper">
                                <div class="panel_details-anchor" id="panel_details-anchor3" [ngClass]="{'study_Class': i === 1, 'study_Class1': i===0}">
                                    <div class="study_Button">
                                    <div class="panel_details-button" 
                                        (click)="toggleStudy(i)"
                                        [innerHTML]="item2.h2content">
                                    </div>
                                    </div>
                                    <div class="panel_details-studyText hidden-xs hidden-sm d-none" id="panel_details-studyText1">Study 5<sup>*</sup></div>
                                    <div class="panel_details-studyText hidden-xs hidden-sm d-none" id="panel_details-studyText2">Study 6<sup>†<sup></sup></sup></div>
                                </div>
                                <!-- <div class="panel_body-modal modal" id="adult_abandonedPop">
                                    <div class="modal-body panel_modalbody1">
                                        <div class="knowledgeHub_abandonedPopClose" (click)="closeModal()"><span>X</span></div>
                                        <div class="knowledgeHub_abandonedPopText panel_body-modalContent1">{{ mergedObject2[selectedTabIndex]?.content}}</div>
                                    </div>
                                </div> -->
                            </div>
                                <div class="panel_body-Image col-xs-12">
                                    <div class="simple-banner banner__component--responsive d-md-block d-lg-block d-none d-sm-none">
                                        <img class="js-responsive-image" [src]="getImage1(item2.media)" [attr.alt]="item2.media?.altText"> 
                                    </div>
                                    <div class="simple-banner banner__component--responsive-mobile d-none d-sm-block d-md-none d-lg-none d-xl-none">
                                        <img class="js-responsive-image" [src]="getImage1(item2.media)" [attr.alt]="item2.media?.altText"> 
                                    </div>
                                </div>
                                <div class="panel_body-disclaimer col-xs-12" [innerHTML]="item2.bottomParaContent"></div>
                                <div class="pop-body" [ngClass]="study[i] == true ? 'active' : ' '">
                                    <div class="pop" [ngClass]="study[i] == true ? 'active' : ' '">
                                        <div class="knowledgeHub_abandonedPopClose" (click) = toggleStudy(i)><span>X</span></div>
                                        <div class="knowledgeHub_abandonedPopText panel_body-modalContent1"[innerHTML]="item2.largeparagraphcontent"></div>
                                    </div>
                                </div>
                        </mat-tab>
                    </div>
                </mat-tab-group>
            </div>
        </div>
    </div>
</div>

