<ng-container *ngIf="isCustomerEmulationSessionInProgress$ | async">
 <div class="col-md-12 sessioncontainer">
  <div class="col-md-5 no-padding float ">
  <span class="input-group-addon ASM_icon-user" id="customerName"></span>
    <input class="startsessioninput"
      formcontrolname="customer"
      type="text"
      disabled="true"
      placeholder="{{ customer?.name }} {{ customer?.uid }}"
    />
  </div>
  <div class="col-md-6 float">
    <button class="ASM-btn-end-session enssessioncustom" (click)="logoutCustomer()">
      {{ 'asm.endSession' | cxTranslate }}
    </button>
  </div>
</div>
  </ng-container>