<section class="col-xs-12 global_createaccsection" id="home_flu360overview" *ngIf="leftBannerNode | async  as item" >
    <div  *ngIf="(item.uid === 'strengthen-banner-Component') || (item.uid === 'broughtyb-banner-Component')">
    <div class="simple-banner banner__component--responsive">
<img class="js-responsive-image" alt="Healthcare professional talking to older adult patient" title="Healthcare professional talking to older adult patient" style="" [src]="getImage(item.media)">
</div><div class="col-xs-12 home_topfirst">
        <div class="offset-lg-6 col-lg-6">
            <div [ngClass]="item.uid === 'strengthen-banner-Component' ? 'header_section1' : 'header_section2'" class="col-xs-12 global_createaccheader">{{item.headline}}</div>
            <div class="col-xs-12 global_createaccsubheader">{{item.h2content}}</div>
            <div class="col-xs-12 global_greyredbtncontainer">
                <div class="global_redbtn" [innerHTML]="item.paragraphcontent"></div>
                <div class="global_greybtn" [innerHTML]="item.content"></div>
            </div>
        </div>
    </div>
</div>


<div class="endHero" *ngIf="(item.uid === 'seqirus-overview-end-hero-component' || item.uid==='seqirus-ukoverview-end-hero-component')" [ngClass]="{'end-hero-uk':item.uid=='seqirus-ukoverview-end-hero-component'}">
    <div class="simple-banner banner__component--responsive">
        <img class="js-responsive-image noHeight" alt="person talking to someone else" title="Healthcare professional talking to older adult patient" style="" [src]="getImage(item.media)">
        </div>
        
            <div class="overview__2x2--cell">
<div class="end-hero-callout">
    <img class="rectangle-down" src="assets/images/rectangle-down.svg">
    <p class="headLine">{{item.headline}}</p>
    <img class="rectangle-up" src="assets/images/rectangle-up.svg">

</div>
<a [routerLink]="'/createprofile'" [ngClass]="{'create-btn':item.uid=='seqirus-ukoverview-end-hero-component'}" class="create_account_link"><button  class="button--solid-red" type="button">
    Create Account</button></a>
</div>

</div>
</section>
<div class="gbr" *ngIf="leftBannerNode | async as item2">
    <div *ngIf="item2.uid=='seqirus-ukoverview-end-hero-component'" class="grbno pt pb">
        GBR-FLU360-23-0010 September 2024</div>
</div>

