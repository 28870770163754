
<div *ngIf="isVisible">
        <div class="loading" *ngIf="showloader" >
          <div class="loading_txt">
           <div class="load_txt">Loading</div>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
           </div>
        </div>
    <div class="col-xs-12 col-md-12 col-lg-12 no-padding UkCreateReturn" *ngIf ="showLocationSelection">
        <section class="col-md-12 no-padding newReturnText" >
            <div class="col-md-6 newReturns_subheader">
                New Return
            </div>
        </section>
        
        <section class="col-xs-12 ReturnSelectLocation" id="newReturn_selectlocation" > 
                        
          <div class="newReturn_selectparent " id="newReturn_selectlocation-1" >
           
            <div class="newReturn_selectbox">
            
            <div class="col-xs-12 col-md-8 no-padding newReturn_selectheader">Select Locations<div class="createSelText">If your account is linked to multiple locations, you may request a return for one or many of these locations at once. Please make sure you have the number of doses by batch you wish to return and photos available for each location. You may start a new return here. Returns you've already submitted can be viewed and actioned on <a href="" class="myreturnlink returnPopupshow">My Returns</a>.</div><div class="noSelText">Please select the location(s) that you are confirming have no returns to submit. Once you have confirmed there are no returns you will be unable to submit a return claim for that location.</div></div>
            <div class="col-xs-12 col-md-4 no-padding newReturn_selectcount">Return to: <span class="newReturn_loccount">{{ selectedCount }}</span>&nbsp;<span>location(s)</span></div>
           
            <div class="col-xs-12 cart_selectline"></div>
            <div class="clearboth"></div>
            <div class="col-xs-12 newreturn_selectoptions">
                        
              <div class="newReturn_customsearch">
                <i class="global_searchicon"></i><input type="search" placeholder="Search for location" id="newreturn_searchbox" (keyup)="applyFilter($event)"><button (click)="applyFilter($event)">Search</button>
              </div>
              <div class="newReturn_selectunselect">
                <div class="newReturn_selectoption" id="newReturn_selectall" (click)="selectAll()">Select All</div><div class="newReturn_selectdivision"></div><div class="newReturn_selectoption" id="newReturn_clearall" (click)="clearAll()">Clear Selections</div>		
              </div>
            
            </div>
           
            <div class="table-container">
              <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                <!-- Checkbox Column -->
                <ng-container matColumnDef="checked">
                  <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="toggleAllRows($event)"></mat-checkbox>
                  </th>
                  <td mat-cell *matCellDef="let element"  >
                      <span *ngIf="element.checked === 'false'" class="return_unavbl">Return Unavailable</span>
                      <span *ngIf="element.checked === 'true'"></span>
                      <span *ngIf="element.checked === 'myReturn'" (click)="navigateToReturns($event, element)" class="return_link">See My Returns</span>
                  </td>
                  
                </ng-container>
              
                <!-- Location Name Column -->
                <ng-container matColumnDef="locname">
          
                  <td mat-cell *matCellDef="let element"> {{element.locname}} </td>
                </ng-container>
              
                <!-- Address Column -->
                <ng-container matColumnDef="address">
          
                  <td mat-cell *matCellDef="let element"> {{element.address}} </td>
                </ng-container>
              
                <!-- State Column -->
                <ng-container matColumnDef="state">
          
                  <td mat-cell *matCellDef="let element"> {{element.state}} </td>
                </ng-container>
              
                
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onRowClick(row)" [ngClass]="{
                  'non_selectable': row.checked === 'false',
                  'selected-row': selection.isSelected(row),
                  'non_selectable_return': row.checked === 'myReturn'
                }"></tr>
              </table>
          </div>
            <div *ngIf="dataSource.filteredData.length === 0" class="norecord">
              No matching records found
            </div>
        
          </div>
          <div class="newReturn_startorderbtnsection">
                        
            <a class="newReturn_exitBtn returnPopupshow" style="text-decoration: underline !important;" (click)="openExistpopup()">Exit</a>
            <button type="submit" class="newReturn_startorderbtn" id="toproductpage" [disabled]="selectedCount === 0" (click)="getProductsToReturn()">Select Products to Return</button>
          
          </div>
        
           
        
        </div>
        </section>
        </div>
        
        <div class="col-xs-12 UkCreateReturn" id="newReturnProduct" *ngIf="showProductReturn" >
        
            <section class="col-md-12 no-padding newReturnText" >
                <div class="col-md-6 newReturns_subheader">
                    New Return
                </div>
            </section>
            <div class="productbox" >
                <div class="backtolocation"  (click)="backTolocation()">
                  <div class="global_blackarrow"></div>
                  Back to Location Selection</div>
                
                <!-- we can repeat from here -->
           
                <div class="greyline"></div>
                      
                      <div class="specificlocation" *ngFor="let returnData of productsToReturn; let i = index">
                        <div *ngFor="let shipData of returnData.returnsProductData.shipData">
                        <div class="newReturn_productheader">Products to Return</div>
                        
                        
                        <div class="newReturn_location">{{ returnData.shippingdata.locname }}</div>
                       
                        <div class="row productrow" > 
                          
                          <div class="col-xs-4 no-padding locationdetail">
                            <div class="col-xs-11 no-padding newReturn_locationdetails" >
                              {{ shipData.addressLine1 }}<br>
                              {{ shipData.town }}<br>
                              {{ shipData.postalCode }}</div>
                          </div>
                         <div class="col-xs-3 no-padding custom-product" *ngFor="let productName of getGroupedProductKeys(shipData.groupedProducts)">
                        <div class="col-xs-12 no-padding Returnaqivbox" *ngIf="productName == 'aQIV'">
                          
                            
                              <div class="productname">{{ productName}}</div>
                              <div class="year">(65 years and above)</div>
                              <div class="enterdosestext">Enter as doses not boxes</div>
                              <table class="prodcutbatch aqivreturn">
                                <tbody><tr class="borderB10">
                                  <th>Batch number</th>
                                  <th>Doses To Return</th>
                                </tr>
                                  <tr *ngFor="let product of shipData.groupedProducts[productName]">
                                    <td>{{ product.batchNumber }}</td>
                                    <td class="aqivReturninput returnparent"><input type="text" (input)="handleInputChange(shipData, productName, product.inputValueaQiv)" autocomplete="no" class="dynbatch form-control returninput" [ngClass]="product.errMsg2?'redhighlight':''" [(ngModel)]="product.inputValueaQiv" (ngModelChange)="formatNumber($event, product, shipData.aqivMaxAllowedQty)" (keypress)="restrictInput($event)" id="" name=""></td>
                                  </tr>
                                
                                <tr class="rowgap">
          
                                </tr>
          
          
          
                              </tbody></table>
                              <table class="Returntotalbox">
                                <tbody>
                                  <tr class="totalrow"  >
                                  <td>Total aQIV Doses</td>
                                  <td class="returnparent" ><input type="text" class="totalReturn form-control blanktotalvalue" [value]="calculateTotalDoses(shipData.groupedProducts[productName], productName,shipData.aqivMaxAllowedQty,shipData)" readonly="" > </td>
                                  </tr>
          
                                <tr >
                                  <td colspan="2" class="batcherror"  >
                                    <ng-container *ngIf="shipData.aqiverrorsor;else checkquantity"><span ><div><span class="glyphiconsign"></span>&nbsp;You have exceeded the maximum doses. Please correct.</div></span></ng-container>
                                    <ng-template #checkquantity>
                                      <span  *ngIf="shipData.aqiverrorquantity"><div><span class="glyphiconsign"></span>&nbsp;You have exceeded the number of doses in this batch. Please correct.</div></span>
                                    </ng-template>
                                    <!-- <span *ngFor="let product of shipData.groupedProducts[productName]">
                                     <div *ngIf="product.errMsg1"><span class="glyphiconsign"></span>&nbsp;You have exceeded the maximum doses. Please correct.</div>
                                     <div *ngIf="product.errMsg2"><span class="glyphiconsign"></span>&nbsp;You have exceeded the number of doses in this batch. Please correct.</div>
                                    </span>  -->
                                  </td>
          
                                </tr>
                              </tbody></table>
                              <div class="clearboth"></div>
          
                            
                        </div> 
                        <div class="col-xs-12 no-padding Returnqivcbox" *ngIf="productName == 'QIVc'">
                          
                            <div class="productname">{{productName}}</div>
                            <div class="year">(6 months and above)</div>
                            <div class="enterdosestext">Enter as doses not boxes</div>
                            <table class="prodcutbatch qivcreturn">
          
          
                              <tbody><tr class="borderB10">
                                <th>Batch number</th>
                                <th>Doses To Return</th>
                              </tr>
                                  <tr *ngFor="let product of shipData.groupedProducts[productName]">
                                    <td>{{ product.batchNumber }}</td>
                                    <td class="QIVcReturninput returnparent"> <input type="text" autocomplete="no" class="dynbatch form-control returninput" [ngClass]="product.errMsg2qivc?'redhighlight':''" [(ngModel)]="product.inputValueQivc" (ngModelChange)="formatNumber1($event, product, shipData.qivcMaxAllowedQty)"  (input)="handleInputChange(shipData, productName, product.inputValueQivc)" (keypress)="restrictInput($event)"> </td>
                                  </tr>
                                  
                              <tr class="rowgap"></tr>
                            </tbody></table>
                            <table class="Returntotalbox">
                              <tbody><tr class="totalrow" >
                                <td>Total QIVc Doses</td>
                                <td class="returnparent" >  <input type="text" class="totalReturn form-control blanktotalvalue" [value]="calculateTotalDoses(shipData.groupedProducts[productName], productName,shipData.qivcMaxAllowedQty,shipData)" readonly=""> </td>
                              </tr>
          
                              <tr>
                                <td colspan="2" class="batcherror" >
                                  <ng-container *ngIf="shipData.qivcerrorsor;else checkquantityqivc"><span ><div><span class="glyphiconsign"></span>&nbsp;You have exceeded the maximum doses. Please correct.</div></span></ng-container>
                                  <ng-template #checkquantityqivc>
                                    <span  *ngIf="shipData.qivcerrorquantity"><div><span class="glyphiconsign"></span>&nbsp;You have exceeded the number of doses in this batch. Please correct.</div></span>
                                  </ng-template>
                                 
                                 <!--  <span *ngFor="let product of shipData.groupedProducts[productName]">
                                    <div *ngIf="product.errMsg1qivc"><span class="glyphiconsign"></span>&nbsp;You have exceeded the maximum doses. Please correct.</div>
                                    <div *ngIf="product.errMsg2qivc"><span class="glyphiconsign"></span>&nbsp;You have exceeded the number of doses in this batch. Please correct.</div>
                                  </span> -->
                                </td>
          
                              </tr>
                            </tbody></table>
          
                            <div class="clearboth"></div>
          
                          
                        </div> 
                         
                        </div>
                        </div>
                        
                        <div class="row blankparent">
                          <div class="col-xs-8 no-padding blankerror" >
                            <div *ngIf="errorMessages[shipData.locationID]" class="error-message"><i class="fa-solid fa-circle-exclamation"></i>&nbsp;{{ errorMessages[shipData.locationID] }}</div>
                            <div *ngIf="shipData.bothproductempty" class="error-message"><i class="fa-solid fa-circle-exclamation"></i>&nbsp;Please click <span class='returnPopupshow errornoreturnlink' (click)="openExistpopup()">here</span> and verify no returns</div>

                          </div>
                        </div>
          
                        <div class="newReturn_location">Upload Documentation</div>
                        <div class="Returnimagebox marginB25">
                          <div class="leftside">
                            <div class="imageuploadsub">Please upload image(s) of your unused vaccines</div>
                            <button class="uploadbtn mappingpop-0" id="uploadbutton-{{shipData.locationID}}" type="button" (click)="openFileUploadModal(shipData.locationID)">Upload &amp; View Images</button>
                            <span class="uploadedquat"><span class="uploadednum" id="uploadednum-{{shipData.locationID}}">{{ getUploadedImageCount(shipData.locationID) }}</span>
                              Image(s) uploaded</span>
                            <div class="imageformattext">The maximum image size to be uploaded is 5mb. Please ensure that the image format is JPEG, or PNG</div>
                            
                          
                            <div class="Returncheckbox">
                              <label class="Retunrcheck-button"> <input type="checkbox" class="Return_check dynimagecheck" id="imagecheck0-{{shipData.locationID}}" name="imagecheck0-{{shipData.locationID}}" [(ngModel)]="checkboxStates[shipData.locationID]" [checked] ="checkboxStates[shipData.locationID]" [disabled]="getUploadedImageCount(shipData.locationID) > 0" (change)="onCheckboxChangeimg(shipData.locationID)"> <span class="Retuncheck-button__control"></span> <label class="Returnlabeltext">I am choosing not to upload an image and I consent to CSL Seqirus contacting me for a manual inspection before approving the return. </label>
                              </label>
                              <label id="imagecheck0-error" class="error help-block" for="imagecheck0-{{shipData.locationID}}" *ngIf="checkboxErrorMessages[shipData.locationID] && !checkboxStates[shipData.locationID] && getUploadedImageCount(shipData.locationID)== 0">
                                <i class="fa-solid fa-circle-exclamation"></i>&nbsp;{{ checkboxErrorMessages[shipData.locationID] }}
                              </label>
          
                              <div class="clearboth"></div>
                            </div>
          
                          </div>
          
                          <div class="rightside">
                            <div class="newReturn_location">Example images:</div>
                            <div class="Returnimageexample">
                              <div class="leftsideexm">
                                <img src="assets/images/ReturnImageExample3.png" class="img-fluid">
                              </div>
                              <div class="rightsideexm">
                                <img src="assets/images/ReturnImageExample2.jpg" class="img-fluid">
                              </div>
                            </div>
                          </div>
                        </div>
        
        
        
        
                      </div>
                      </div>
                    
          
              </div>
        
            
              <div class="btmsec">
                <div class="completetext">Complete Return Request</div>
                <div class="row">
                  <div class="Returnsubmitbox col-md-7 leftside">
                    <label class="Retunrcheck-button"> <input type="checkbox" class="Return_check" id="finalcheck" name="finalcheck" [(ngModel)]="checkboxChecked" (change)="onCheckboxChange()"> <span class="Retuncheck-button__control"></span> <label class="Returnlabeltext">I agree to destroy the unused vaccines in accordance with the Disposal of Unwanted Medicines guidelines and/or other applicable requirements, when instructed by CSL Seqirus. </label>
                    </label>
                    <label id="finalcheck-error" class="error help-block" for="finalcheck" *ngIf="checkboxErrorMessage">
                      <i class="fa-solid fa-circle-exclamation"></i>&nbsp;{{ checkboxErrorMessage }}
                    </label>
                    <div class="clearboth"></div>
                  </div>
          
                  <div class="submitbtnsection col-md-5 no-padding-right">
                    <a class="prodReturn_exitBtn returnPopupshow" (click)="openExistpopup()">Exit</a>
                    <button class="productsumbitbtn" id="productSubmit" type="button" (click)="processAndSendPayloads()">Submit Return Request</button>
                  </div>
                </div>
              </div>
            <div>
        
          </div>
        
        </div>

        <section class="col-xs-12 col-md-12 UkCreateReturn" id="ReturnThank" style="display:block" *ngIf="showFeedback">
					
          <div class="Returnthank_box">
            <div class="thankReturn_head">Thank You for Submitting Your Return Request</div>
            <div class="thankreturn_text">You will receive an email confirmation shortly.</div>
             <div class="returnFeedbackBox" style="display: block;">
               
      <!-- <div class="feedbackheader">How was your experience with the return request process?</div>
      <div style="font-family: Verdana; font-size: 12px; overflow:hidden;">
          <table cellpadding="0" cellspacing="0" border="0" class="feedbacktable no-padding">
              <tbody><tr>
                  <td class="feedbackbox">
                      <a href="https://app.customerthermometer.com/?template=log_feedback&amp;hash=7bc5e051&amp;embed_data=dGVtcGVyYXR1cmVfaWQ9NCZ0aGVybW9tZXRlcl9pZD0xNzk2NDUmbnBzX3JhdGluZz0=&amp;e=&amp;f=&amp;l=&amp;c=&amp;c1=&amp;c2=&amp;c3=&amp;c4=&amp;c5=&amp;c6=&amp;c7=&amp;c8=&amp;c9=&amp;c10=" target="_blank" class="returnPopupshow">
                          <img style="border: none;" src="https://app.customerthermometer.com/sites/app/images/icon_sets/icon_set_3s/red.png" alt="Red Light" title="Red Light" class="_temp">
                      </a>
                  </td>
                  <td class="feedbackbox">
                      <a href="https://app.customerthermometer.com/?template=log_feedback&amp;hash=7be5181c&amp;embed_data=dGVtcGVyYXR1cmVfaWQ9MyZ0aGVybW9tZXRlcl9pZD0xNzk2NDUmbnBzX3JhdGluZz0=&amp;e=&amp;f=&amp;l=&amp;c=&amp;c1=&amp;c2=&amp;c3=&amp;c4=&amp;c5=&amp;c6=&amp;c7=&amp;c8=&amp;c9=&amp;c10=" target="_blank" class="returnPopupshow">
                          <img style="border: none;" src="https://app.customerthermometer.com/sites/app/images/icon_sets/icon_set_3s/amber.png" alt="Yellow Light" title="Yellow Light" class="_temp">
                      </a>
                  </td>
                  <td class="feedbackbox">
                      <a href="https://app.customerthermometer.com/?template=log_feedback&amp;hash=32ff96cc&amp;embed_data=dGVtcGVyYXR1cmVfaWQ9MiZ0aGVybW9tZXRlcl9pZD0xNzk2NDUmbnBzX3JhdGluZz0=&amp;e=&amp;f=&amp;l=&amp;c=&amp;c1=&amp;c2=&amp;c3=&amp;c4=&amp;c5=&amp;c6=&amp;c7=&amp;c8=&amp;c9=&amp;c10=" target="_blank" class="returnPopupshow">
                          <img style="border: none;" src="https://app.customerthermometer.com/sites/app/images/icon_sets/icon_set_3s/green.png" alt="Green Light" title="Green Light" class="_temp">
                      </a>
                  </td>
                  <td class="feedbackbox">
                      <a href="https://app.customerthermometer.com/?template=log_feedback&amp;hash=3a1f4098&amp;embed_data=dGVtcGVyYXR1cmVfaWQ9MSZ0aGVybW9tZXRlcl9pZD0xNzk2NDUmbnBzX3JhdGluZz0=&amp;e=&amp;f=&amp;l=&amp;c=&amp;c1=&amp;c2=&amp;c3=&amp;c4=&amp;c5=&amp;c6=&amp;c7=&amp;c8=&amp;c9=&amp;c10=" target="_blank" class="returnPopupshow">
                          <img style="border: none;" src="https://app.customerthermometer.com/sites/app/images/icon_sets/icon_set_3s/gold.png" alt="Gold Star" title="Gold Star" class="_temp">
                      </a>
                  </td>

              </tr>

          </tbody></table>
      </div> -->


</div>
            <!-- <a class="nopopshow returnPopupshow" (click)="goToreturn()">
            <div class="Returnbutton">View Returns</div>
            </a> -->
          </div>
          <app-custom-returns-thankyouvideo></app-custom-returns-thankyouvideo>
          <app-custom-returns-thankyoumaterials></app-custom-returns-thankyoumaterials>
          <app-custom-picture-resources></app-custom-picture-resources>
      </section>
</div>

